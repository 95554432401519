<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="icones-caracteristicas-inativo far fa-check-circle"></i>
      </div>
      <div class="col-11" v-if="usuarios.length > 0">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid rounded w-75" :src="retornaUsuario('foto')" :alt="retornaUsuario('nome')" />
          </div>
          <div class="col-11 align-self-center">
            <small><strong>{{retornaUsuario('nome')}}</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            {{propItem.text}}
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CompAprovacao",
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      //usuarios: []
    }
  },
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    }
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuarios.length > 0) {
        var user = this.usuarios.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    //this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
</style>
