<template>
  <modal :show="propShow" :showClose="false" size="lg">
    <h6 slot="header" class="modal-title">Adicionar Indicação de Candidato</h6>
    <div class="row">
      <div class="col">
        <label for="indicadoPorNome">Indicado por: <span class="text-danger">*</span></label>
        <input
          id="indicadoPorNome"
          ref="indicadoPorNome"
          type="text"
          class="form-control"
          name="Nome"
          placeholder="Nome"
          v-model="novaIndicacao.nome"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="indicadoPorEmail">E-mail: <span class="text-danger">*</span></label>
        <input
          id="indicadoPorEmail"
          ref="indicadoPorEmail"
          type="text"
          class="form-control"
          name="Nome"
          placeholder="Nome"
          v-model="novaIndicacao.email"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="indicadoPorCargo">Cargo:</label>
        <input
          id="indicadoPorCargo"
          ref="indicadoPorCargo"
          type="text"
          class="form-control"
          name="Nome"
          placeholder="Nome"
          v-model="novaIndicacao.cargo"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="indicadoPorCargo">Observacões:</label>
        <textarea class="form-control" id="indicadoPorCargo" rows="3" v-model="novaIndicacao.obs"></textarea>
      </div>
    </div>
    <div slot="footer">
      <base-button type="secondary" class="ml-3" @click="closeModal">Cancelar</base-button>
      <base-button type="primary" class="mr-3 ml-auto" @click="emitIndicacao" :disabled="validaFormIndicacao()">Adicionar</base-button>
    </div>
  </modal>
</template>

<script>
export default {
  name: "RegistroIndicacao",
  props: {
    //propCandidato: { type: Object }, //Conteudo da timeline do candidato
    //propVaga: { type: String },// String com o flag do tipo da entrevista
    //propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    concluiRegistro: { type: Function },
    closeModal: { type: Function },
    propShow: { type: Boolean },
    propIndex: { type: Number }
  },
  data() {
    return {
      novaIndicacao: {
        index: null,
        nome: "",
        email: "",
        cargo: "",
        obs: ""
      },
    }
  },
  methods: {
    validaFormIndicacao() {
      let count = 0;
      if(this.novaIndicacao.nome !== "") {
        count = count + 1;
      }
      if(this.novaIndicacao.email !== "") {
        count = count + 1;
      }
      if(this.novaIndicacao.cargo !== "") {
        count = count + 1;
      }
      if(count > 2) {
        return false;
      } else {
        return true;
      }
    },
    emitIndicacao() {
      this.novaIndicacao.index = this.propIndex;
      this.$emit('resIndicacao', this.novaIndicacao);
      this.concluiRegistro()
    }
  }
}
</script>

<style>

</style>
