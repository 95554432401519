<template>
  <div>
    <div class="row justify-content-center mt-3">
      <div class="col align-self-center">
        <p class="h4">URL da página de vagas:</p>
        <p>
          <a :href="`https://vagas.matchjob.com.br/${propWebConfig.hl_url_amigavel}`" target="_blank">
            https://vagas.matchjob.com.br/{{propWebConfig.hl_url_amigavel}}
          </a>
        </p>
      </div>
    </div>
    <!-- <pre>{{propWebConfig}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "WebConfig",
  props: {
    propWebConfig: [Array, Object]
  }
}
</script>

<style>

</style>
