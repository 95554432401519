<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <label class="form-control-label">{{propValue}}</label><span v-if="propRequired" class="text-danger"> *</span>
      </div>
    </div>
    <div class="row mt-1 mx-1">
      <div class="col">
        <base-input type="text" v-model="resp" @blur="emitData">
        </base-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextoSimples",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean }
  },
  data() {
    return {
      resp: ""
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    }
  }
}
</script>

<style>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
