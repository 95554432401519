<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Certificações</h5>
      </div>
    </div>
    <div v-for="(cert, index) in propCertificacoes" :key="index">
      <div class="row justify-content-start align-items-start">
        <div class="col-4">
          <small>Tìtulo:</small><br><strong>{{cert.nm_titulo}}</strong>
        </div>
        <div class="col-4">
          <small>Orgão Emissor:</small><br><strong>{{cert.nm_orgao_emissor}}</strong>
        </div>
        <div class="col-4">
          <small>Numero:</small><br><strong>{{cert.nm_numero}}</strong>
        </div>
      </div>
      <div class="row justify-content-start align-items-start">
        <div class="col-4">
          <small>Data da certificação:</small><br><strong>{{cert.dt_certificacao | formatDate}}</strong>
        </div>
        <div class="col-4">
          <small>Link do certificado:</small><br><strong><a :href="cert.hl_link_certificado" target="_blank">{{cert.hl_link_certificado}}</a></strong>
        </div>
      </div>
    </div>
    <div v-if="propCertificacoes.length < 1">
      <div class="row justify-content-start align-items-start">
        <div class="col-12">
          <small>Candidato não possui certificações:</small>
        </div>
      </div>
    </div>
    <!-- <pre>{{propCertificacoes}}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CertificacoesCV",
  props: {
    propCertificacoes: { type: Array }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style>

</style>
