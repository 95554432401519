export default [
  {
      id_nacionalidade: "1",
      nm_pais: "Afeganistão",
      nm_habitante: "Afegão",
      nm_habitante_F: "Afegã"
  },
  {
      id_nacionalidade: "2",
      nm_pais: "África do Sul",
      nm_habitante: "Sul-Africano",
      nm_habitante_F: "Sul-Africana"
  },
  {
      id_nacionalidade: "3",
      nm_pais: "Albânia",
      nm_habitante: "Albanês",
      nm_habitante_F: "Albanêsa"
  },
  {
      id_nacionalidade: "4",
      nm_pais: "Alemanha",
      nm_habitante: "Alemão",
      nm_habitante_F: "Alemã"
  },
  {
      id_nacionalidade: "5",
      nm_pais: "Angola",
      nm_habitante: "Angolano",
      nm_habitante_F: "Angolana"
  },
  {
      id_nacionalidade: "6",
      nm_pais: "Arábia Saudita",
      nm_habitante: "Saudita",
      nm_habitante_F: "Saudita"
  },
  {
      id_nacionalidade: "7",
      nm_pais: "Argélia",
      nm_habitante: "Argelino",
      nm_habitante_F: "Argelina"
  },
  {
      id_nacionalidade: "8",
      nm_pais: "Argentina",
      nm_habitante: "Argentino",
      nm_habitante_F: "Argentina"
  },
  {
      id_nacionalidade: "9",
      nm_pais: "Austrália",
      nm_habitante: "Australiano",
      nm_habitante_F: "Australiana"
  },
  {
      id_nacionalidade: "10",
      nm_pais: "Áustria",
      nm_habitante: "Austríaco",
      nm_habitante_F: "Austríaca"
  },
  {
      id_nacionalidade: "11",
      nm_pais: "Bangladeche",
      nm_habitante: "Bengali",
      nm_habitante_F: "Bengali"
  },
  {
      id_nacionalidade: "12",
      nm_pais: "Bélgica",
      nm_habitante: "Belga",
      nm_habitante_F: "Belga"
  },
  {
      id_nacionalidade: "13",
      nm_pais: "Bielorrússia",
      nm_habitante: "Bielorrusso",
      nm_habitante_F: "Bielorrussa"
  },
  {
      id_nacionalidade: "14",
      nm_pais: "Bolívia",
      nm_habitante: "Boliviano",
      nm_habitante_F: "Boliviana"
  },
  {
      id_nacionalidade: "15",
      nm_pais: "Botsuana",
      nm_habitante: "Botsuano",
      nm_habitante_F: "Botsuana"
  },
  {
      id_nacionalidade: "16",
      nm_pais: "Brasil",
      nm_habitante: "Brasileiro",
      nm_habitante_F: "Brasileira"
  },
  {
      id_nacionalidade: "17",
      nm_pais: "Bulgária",
      nm_habitante: "Búlgaro",
      nm_habitante_F: "Búlgara"
  },
  {
      id_nacionalidade: "18",
      nm_pais: "Camboja",
      nm_habitante: "Cambojano",
      nm_habitante_F: "Cambojana"
  },
  {
      id_nacionalidade: "19",
      nm_pais: "Canadá",
      nm_habitante: "Canadense",
      nm_habitante_F: "Canadense"
  },
  {
      id_nacionalidade: "20",
      nm_pais: "Cazaquistão",
      nm_habitante: "Cazaque",
      nm_habitante_F: "Cazaque"
  },
  {
      id_nacionalidade: "21",
      nm_pais: "Chile",
      nm_habitante: "Chileno",
      nm_habitante_F: "Chilena"
  },
  {
      id_nacionalidade: "22",
      nm_pais: "China",
      nm_habitante: "Chinês",
      nm_habitante_F: "Chinêsa"
  },
  {
      id_nacionalidade: "23",
      nm_pais: "Chipre",
      nm_habitante: "Cipriota",
      nm_habitante_F: "Cipriota"
  },
  {
      id_nacionalidade: "24",
      nm_pais: "Colômbia",
      nm_habitante: "Colombiano",
      nm_habitante_F: "Colombiana"
  },
  {
      id_nacionalidade: "25",
      nm_pais: "Coreia do Norte",
      nm_habitante: "Norte-Coreano",
      nm_habitante_F: "Norte-Coreana"
  },
  {
      id_nacionalidade: "26",
      nm_pais: "Coreia do Sul",
      nm_habitante: "Sul-Coreano",
      nm_habitante_F: "Sul-Coreana"
  },
  {
      id_nacionalidade: "27",
      nm_pais: "Costa do Marfim",
      nm_habitante: "Costa-Marfinense",
      nm_habitante_F: "Costa-Marfinense"
  },
  {
      id_nacionalidade: "28",
      nm_pais: "Croácia",
      nm_habitante: "Croata",
      nm_habitante_F: "Croata"
  },
  {
      id_nacionalidade: "29",
      nm_pais: "Cuba",
      nm_habitante: "Cubano",
      nm_habitante_F: "Cubana"
  },
  {
      id_nacionalidade: "30",
      nm_pais: "Dinamarca",
      nm_habitante: "Dinamarquês",
      nm_habitante_F: "Dinamarquêsa"
  },
  {
      id_nacionalidade: "31",
      nm_pais: "Egito",
      nm_habitante: "Egípcio",
      nm_habitante_F: "Egípcia"
  },
  {
      id_nacionalidade: "32",
      nm_pais: "Equador",
      nm_habitante: "Equatoriano",
      nm_habitante_F: "Equatoriana"
  },
  {
      id_nacionalidade: "33",
      nm_pais: "Escócia",
      nm_habitante: "Escocês",
      nm_habitante_F: "Escocêsa"
  },
  {
      id_nacionalidade: "34",
      nm_pais: "Eslováquia",
      nm_habitante: "Eslovaco",
      nm_habitante_F: "Eslovaca"
  },
  {
      id_nacionalidade: "35",
      nm_pais: "Eslovénia",
      nm_habitante: "Esloveno",
      nm_habitante_F: "Eslovena"
  },
  {
      id_nacionalidade: "36",
      nm_pais: "Espanha",
      nm_habitante: "Espanhol",
      nm_habitante_F: "Espanhola"
  },
  {
      id_nacionalidade: "37",
      nm_pais: "Estados Unidos",
      nm_habitante: "Americano",
      nm_habitante_F: "Americana"
  },
  {
      id_nacionalidade: "38",
      nm_pais: "Estónia",
      nm_habitante: "Estoniano",
      nm_habitante_F: "Estoniana"
  },
  {
      id_nacionalidade: "39",
      nm_pais: "Etiópia",
      nm_habitante: "Etíope",
      nm_habitante_F: "Etíope"
  },
  {
      id_nacionalidade: "40",
      nm_pais: "Fiji",
      nm_habitante: "Fijiano",
      nm_habitante_F: "Fijiana"
  },
  {
      id_nacionalidade: "41",
      nm_pais: "Filipinas",
      nm_habitante: "Filipino",
      nm_habitante_F: "Filipina"
  },
  {
      id_nacionalidade: "42",
      nm_pais: "Finlândia",
      nm_habitante: "Finlandês",
      nm_habitante_F: "Finlandêsa"
  },
  {
      id_nacionalidade: "43",
      nm_pais: "França",
      nm_habitante: "Francês",
      nm_habitante_F: "Francêsa"
  },
  {
      id_nacionalidade: "44",
      nm_pais: "Gana",
      nm_habitante: "Ganês",
      nm_habitante_F: "Ganêsa"
  },
  {
      id_nacionalidade: "45",
      nm_pais: "Geórgia",
      nm_habitante: "Georgiano",
      nm_habitante_F: "Georgiana"
  },
  {
      id_nacionalidade: "46",
      nm_pais: "Grécia",
      nm_habitante: "Grego",
      nm_habitante_F: "Grega"
  },
  {
      id_nacionalidade: "47",
      nm_pais: "Guatemala",
      nm_habitante: "Guatemalteco",
      nm_habitante_F: "Guatemalteca"
  },
  {
      id_nacionalidade: "48",
      nm_pais: "Hungria",
      nm_habitante: "Húngaro",
      nm_habitante_F: "Húngara"
  },
  {
      id_nacionalidade: "49",
      nm_pais: "Iémene",
      nm_habitante: "Iemenita",
      nm_habitante_F: "Iemenita"
  },
  {
      id_nacionalidade: "50",
      nm_pais: "Índia",
      nm_habitante: "Indiano",
      nm_habitante_F: "Indiana"
  },
  {
      id_nacionalidade: "51",
      nm_pais: "Indonésia",
      nm_habitante: "Indonésio",
      nm_habitante_F: "Indonésia"
  },
  {
      id_nacionalidade: "52",
      nm_pais: "Inglaterra",
      nm_habitante: "Inglês",
      nm_habitante_F: "Inglêsa"
  },
  {
      id_nacionalidade: "53",
      nm_pais: "Irão",
      nm_habitante: "Iraniano",
      nm_habitante_F: "Iraniana"
  },
  {
      id_nacionalidade: "54",
      nm_pais: "Iraque",
      nm_habitante: "Iraquiano",
      nm_habitante_F: "Iraquiana"
  },
  {
      id_nacionalidade: "55",
      nm_pais: "Irlanda",
      nm_habitante: "Irlandês",
      nm_habitante_F: "Irlandêsa"
  },
  {
      id_nacionalidade: "56",
      nm_pais: "Irlanda do Norte",
      nm_habitante: "Norte-Irlandês",
      nm_habitante_F: "Norte-Irlandêsa"
  },
  {
      id_nacionalidade: "57",
      nm_pais: "Islândia",
      nm_habitante: "Islandês",
      nm_habitante_F: "Islandêsa"
  },
  {
      id_nacionalidade: "58",
      nm_pais: "Israel",
      nm_habitante: "Israelita",
      nm_habitante_F: "Israelita"
  },
  {
      id_nacionalidade: "59",
      nm_pais: "Itália",
      nm_habitante: "Italiano",
      nm_habitante_F: "Italiana"
  },
  {
      id_nacionalidade: "60",
      nm_pais: "Jamaica",
      nm_habitante: "Jamaicano",
      nm_habitante_F: "Jamaicana"
  },
  {
      id_nacionalidade: "61",
      nm_pais: "Japão",
      nm_habitante: "Japonês",
      nm_habitante_F: "Japonêsa"
  },
  {
      id_nacionalidade: "62",
      nm_pais: "Jordânia",
      nm_habitante: "Jordano",
      nm_habitante_F: "Jordana"
  },
  {
      id_nacionalidade: "63",
      nm_pais: "Kuwait",
      nm_habitante: "Kuwaitiano",
      nm_habitante_F: "Kuwaitiana"
  },
  {
      id_nacionalidade: "64",
      nm_pais: "Laos",
      nm_habitante: "Laosiano",
      nm_habitante_F: "Laosiana"
  },
  {
      id_nacionalidade: "65",
      nm_pais: "Letónia",
      nm_habitante: "Letão",
      nm_habitante_F: "Letã"
  },
  {
      id_nacionalidade: "66",
      nm_pais: "Líbano",
      nm_habitante: "Libanês",
      nm_habitante_F: "Libanêsa"
  },
  {
      id_nacionalidade: "67",
      nm_pais: "Líbia",
      nm_habitante: "Líbio",
      nm_habitante_F: "Líbia"
  },
  {
      id_nacionalidade: "68",
      nm_pais: "Lituânia",
      nm_habitante: "Lituano",
      nm_habitante_F: "Lituana"
  },
  {
      id_nacionalidade: "69",
      nm_pais: "Madagáscar",
      nm_habitante: "Malgaxe",
      nm_habitante_F: "Malgaxe"
  },
  {
      id_nacionalidade: "70",
      nm_pais: "Malásia",
      nm_habitante: "Malaico",
      nm_habitante_F: "Malaica"
  },
  {
      id_nacionalidade: "71",
      nm_pais: "Marrocos",
      nm_habitante: "Marroquino",
      nm_habitante_F: "Marroquina"
  },
  {
      id_nacionalidade: "72",
      nm_pais: "México",
      nm_habitante: "Mexicano",
      nm_habitante_F: "Mexicana"
  },
  {
      id_nacionalidade: "73",
      nm_pais: "Mianmar",
      nm_habitante: "Mianmarense",
      nm_habitante_F: "Mianmarense"
  },
  {
      id_nacionalidade: "74",
      nm_pais: "Moçambique",
      nm_habitante: "Moçambicano",
      nm_habitante_F: "Moçambicana"
  },
  {
      id_nacionalidade: "75",
      nm_pais: "Mongólia",
      nm_habitante: "Mongol",
      nm_habitante_F: "Mongol"
  },
  {
      id_nacionalidade: "76",
      nm_pais: "Namíbia",
      nm_habitante: "Namíbio",
      nm_habitante_F: "Namíbia"
  },
  {
      id_nacionalidade: "77",
      nm_pais: "Nepal",
      nm_habitante: "Nepalês",
      nm_habitante_F: "Nepalêsa"
  },
  {
      id_nacionalidade: "78",
      nm_pais: "Nigéria",
      nm_habitante: "Nigeriano",
      nm_habitante_F: "Nigeriana"
  },
  {
      id_nacionalidade: "79",
      nm_pais: "Noruega",
      nm_habitante: "Norueguês",
      nm_habitante_F: "Norueguêsa"
  },
  {
      id_nacionalidade: "80",
      nm_pais: "Nova Zelândia",
      nm_habitante: "Neozelandês",
      nm_habitante_F: "Neozelandêsa"
  },
  {
      id_nacionalidade: "81",
      nm_pais: "País de Gales",
      nm_habitante: "Galês",
      nm_habitante_F: "Galêsa"
  },
  {
      id_nacionalidade: "82",
      nm_pais: "Países Baixos",
      nm_habitante: "Holandês",
      nm_habitante_F: "Holandêsa"
  },
  {
      id_nacionalidade: "83",
      nm_pais: "Palestina",
      nm_habitante: "Palestiniano",
      nm_habitante_F: "Palestiniana"
  },
  {
      id_nacionalidade: "84",
      nm_pais: "Paquistão",
      nm_habitante: "Paquistanês",
      nm_habitante_F: "Paquistanêsa"
  },
  {
      id_nacionalidade: "85",
      nm_pais: "Paraguai",
      nm_habitante: "Paraguaio",
      nm_habitante_F: "Paraguaia"
  },
  {
      id_nacionalidade: "86",
      nm_pais: "Peru",
      nm_habitante: "Peruano",
      nm_habitante_F: "Peruana"
  },
  {
      id_nacionalidade: "87",
      nm_pais: "Polónia",
      nm_habitante: "Polaco",
      nm_habitante_F: "Polaca"
  },
  {
      id_nacionalidade: "88",
      nm_pais: "Portugal",
      nm_habitante: "Português",
      nm_habitante_F: "Portuguêsa"
  },
  {
      id_nacionalidade: "89",
      nm_pais: "Quénia",
      nm_habitante: "Queniano",
      nm_habitante_F: "Queniana"
  },
  {
      id_nacionalidade: "90",
      nm_pais: "Reino Unido",
      nm_habitante: "Britânico",
      nm_habitante_F: "Britânica"
  },
  {
      id_nacionalidade: "91",
      nm_pais: "República Checa",
      nm_habitante: "Checo",
      nm_habitante_F: "Checa"
  },
  {
      id_nacionalidade: "92",
      nm_pais: "República Democrática do Congo",
      nm_habitante: "Congolês",
      nm_habitante_F: "Congolêsa"
  },
  {
      id_nacionalidade: "93",
      nm_pais: "Roménia",
      nm_habitante: "Romeno",
      nm_habitante_F: "Romena"
  },
  {
      id_nacionalidade: "94",
      nm_pais: "Rússia",
      nm_habitante: "Russo",
      nm_habitante_F: "Russa"
  },
  {
      id_nacionalidade: "95",
      nm_pais: "Sérvia",
      nm_habitante: "Sérvio",
      nm_habitante_F: "Sérvia"
  },
  {
      id_nacionalidade: "96",
      nm_pais: "Singapura",
      nm_habitante: "Singapurense",
      nm_habitante_F: "Singapurense"
  },
  {
      id_nacionalidade: "97",
      nm_pais: "Síria",
      nm_habitante: "Sírio",
      nm_habitante_F: "Síria"
  },
  {
      id_nacionalidade: "98",
      nm_pais: "Somália",
      nm_habitante: "Somali",
      nm_habitante_F: "Somali"
  },
  {
      id_nacionalidade: "99",
      nm_pais: "Sri Lanka",
      nm_habitante: "Cingalês",
      nm_habitante_F: "Cingalês"
  },
  {
      id_nacionalidade: "100",
      nm_pais: "Sudão",
      nm_habitante: "Sudanês",
      nm_habitante_F: "Sudanês"
  },
  {
      id_nacionalidade: "101",
      nm_pais: "Suécia",
      nm_habitante: "Sueco",
      nm_habitante_F: "Sueca"
  },
  {
      id_nacionalidade: "102",
      nm_pais: "Suíça",
      nm_habitante: "Suíço",
      nm_habitante_F: "Suíça"
  },
  {
      id_nacionalidade: "103",
      nm_pais: "Tailândia",
      nm_habitante: "Tailandês",
      nm_habitante_F: "Tailandêsa"
  },
  {
      id_nacionalidade: "104",
      nm_pais: "Taiwan",
      nm_habitante: "Taiwanês",
      nm_habitante_F: "Taiwanêsa"
  },
  {
      id_nacionalidade: "105",
      nm_pais: "Tanzânia",
      nm_habitante: "Tanzaniano",
      nm_habitante_F: "Tanzaniana"
  },
  {
      id_nacionalidade: "106",
      nm_pais: "Tunísia",
      nm_habitante: "Tunisino",
      nm_habitante_F: "Tunisina"
  },
  {
      id_nacionalidade: "107",
      nm_pais: "Turquia",
      nm_habitante: "Turco",
      nm_habitante_F: "Turca"
  },
  {
      id_nacionalidade: "108",
      nm_pais: "Ucrânia",
      nm_habitante: "Ucraniano",
      nm_habitante_F: "Ucraniana"
  },
  {
      id_nacionalidade: "109",
      nm_pais: "Uganda",
      nm_habitante: "Ugandês",
      nm_habitante_F: "Ugandêsa"
  },
  {
      id_nacionalidade: "110",
      nm_pais: "Uruguai",
      nm_habitante: "Uruguaio",
      nm_habitante_F: "Uruguaia"
  },
  {
      id_nacionalidade: "111",
      nm_pais: "Venezuela",
      nm_habitante: "Venezuelano",
      nm_habitante_F: "Venezuelana"
  },
  {
      id_nacionalidade: "112",
      nm_pais: "Vietname",
      nm_habitante: "Vietnamita",
      nm_habitante_F: "Vietnamita"
  },
  {
      id_nacionalidade: "113",
      nm_pais: "Zâmbia",
      nm_habitante: "Zambiano",
      nm_habitante_F: "Zambiana"
  },
  {
      id_nacionalidade: "114",
      nm_pais: "Zimbabué",
      nm_habitante: "Zimbabuense",
      nm_habitante_F: "Zimbabuense"
  }
];
