<template>
  <div v-loading="loading">
    <card>
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <h1 class="display-4">Dados Cadastrais</h1>
        </div>
      </div>
      <validation-observer v-slot="{handleSubmit}" ref="form">
        <form class="needs-validation" enctype="multipart/form-data"
          @submit.prevent="handleSubmit(editFormSubmit)">
          <!-- Razão Social / Numero de Cadastro (CNPJ ou CPF) -->
          <div class="row justify-content-center mt-3">
            <!-- Razão Social -->
            <div class="col-6">
              <label for="razao">Razão Social</label>
              <input
                id="razao"
                ref="razao"
                type="text"
                class="form-control"
                disabled
                name="Razão Social"
                v-model="propConfiguracoesGerais.nm_razao"
              />
            </div>
            <!-- Numero de Cadastro (CNPJ ou CPF) -->
            <div class="col-2">
              <label for="nu_cadastro">{{ propConfiguracoesGerais.fl_cpf_cnpj === "0" ? "CNPJ" : "CPF" }}</label>
              <input
                id="nu_cadastro"
                ref="nu_cadastro"
                type="text"
                class="form-control"
                disabled
                :name="propConfiguracoesGerais.fl_cpf_cnpj === '0' ? 'CNPJ' : 'CPF'"
                v-model="propConfiguracoesGerais.nu_cadastro"
              />
            </div>
          </div>
          <!-- Nome Fantasia / Tipo de Recrutamento -->
          <div class="row justify-content-center mt-3">
            <!-- Nome Fantasia -->
            <div class="col-5">
              <label for="nm_fantasia">Nome Fantasia <span class="text-danger">*</span></label>
              <input
                id="nm_fantasia"
                ref="nm_fantasia"
                type="text"
                maxlength="30"
                class="form-control"
                name="Nome Fantasia"
                placeholder="Nome Fantasia"
                v-model.trim.lazy="propConfiguracoesGerais.nm_fantasia"
                @blur="validaOnBlur('nm_fantasia')"
              />
              <small class="invalid-feedback" style="display: block;" v-if="validate.nm_fantasia">O campo "Nome Fantasia" é obrigatório.</small>
            </div>
            <!-- Tipo de Recrutamento -->
            <div class="col-3">
              <label for="tiporecrutamento">Tipo de Recrutamento</label>
              <el-select
                ref="tiporecrutamento"
                name="Área de Atuação"
                class="inuptAutocomplete"
                id="tiporecrutamento"
                v-model="propConfiguracoesGerais.fl_tipo_recrutamento"
              >
                <el-option
                  v-for="tipo in selectRecrutamento"
                  :key="tipo.value"
                  :value="tipo.value"
                  :label="tipo.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- Setor Empresarial / Porte da Empresa -->
          <div class="row justify-content-center mt-3">
            <!-- Setor Empresarial -->
            <div class="col-4">
              <label for="setor">Setor Empresarial</label>
              <el-select
                ref="setor"
                name="Setor Empresarial"
                class="inuptAutocomplete"
                id="setor"
                v-model="propConfiguracoesGerais.fl_setor"
              >
                <el-option
                  v-for="tipo in selectSetor"
                  :key="tipo.value"
                  :value="tipo.value"
                  :label="tipo.label"
                ></el-option>
              </el-select>
            </div>
            <!-- Porte da Empresa -->
            <div class="col-4">
              <label for="porte">Porte da Empresa</label>
              <el-select
                ref="porte"
                name="Porte da Empresa"
                class="inuptAutocomplete"
                id="porte"
                v-model="propConfiguracoesGerais.fl_porte"
              >
                <el-option
                  v-for="tipo in selectPorte"
                  :key="tipo.value"
                  :value="tipo.value"
                  :label="tipo.label"
                ></el-option>
              </el-select>
            </div>
          </div>
           <!-- E-mail de contato / Telefone de contato -->
          <div class="row justify-content-center mt-3">
            <!-- E-mail de principal -->
            <div class="col-5">
              <label for="hl_email">E-mail de principal</label>
              <input
                id="hl_email"
                ref="hl_email"
                type="email"
                maxlength="100"
                class="form-control"
                name="E-mail de principal"
                placeholder="E-mail de principal"
                v-model.lazy="propConfiguracoesGerais.hl_email"
              />
              <small class="invalid-feedback" style="display: block;" v-if="validate.hl_email">O campo "E-mail de principal" é obrigatório.</small>
            </div>
            <!-- Telefone de contato -->
            <div class="col-3">
              <label for="nu_telefone">Telefone de contato</label>
              <input
                id="nu_telefone"
                ref="nu_telefone"
                type="text"
                maxlength="11"
                class="form-control"
                name="Telefone de contato"
                placeholder="Telefone de contato"
                v-model.number.lazy="propConfiguracoesGerais.nu_telefone"
              />
              <small class="invalid-feedback" style="display: block;" v-if="validate.nu_telefone">O campo "Telefone de contato" é obrigatório.</small>
            </div>
          </div>
          <!-- Site -->
          <div class="row justify-content-center mt-3">
            <div class="col-8">
              <label for="hl_site">Site da empresa</label>
              <input
                id="hl_site"
                ref="hl_site"
                type="url"
                maxlength="100"
                class="form-control"
                name="Site da empresa"
                placeholder="https://...."
                v-model="propConfiguracoesGerais.hl_site"
              />
              <small class="invalid-feedback" style="display: block;" v-if="validate.hl_site">O campo "Site da empresa" é obrigatório.</small>
            </div>
          </div>
        </form>
      </validation-observer>
      <form class="needs-validation" enctype="multipart/form-data">
        <!-- Logo da empresa -->
        <div class="row justify-content-center mt-3">
          <div class="col-4" v-loading="imgChange">
            <label for="hl_logo">Logo da empresa</label><br>
            <span class="text-muted" v-if="propConfiguracoesGerais.hl_logo === '' || propConfiguracoesGerais.hl_logo === null"><em>Não há nenhum logo definido.</em></span>
            <template v-else>
              <img :src="novaFoto === '' ? propConfiguracoesGerais.hl_logo : novaFoto" class="img-fluid my-2" alt="Logo"><br>
              <span class="my-2">
                <base-button type="warning" size="sm" @click="removerLogo">Remover</base-button>
                <!-- <base-button type="success" size="sm" native-type="submit">Salvar</base-button> -->
              </span>
            </template>
            <template v-if="imageError !== ''">
              <p><small class="text-danger">{{imageError}}</small></p>
            </template>
            <div class="custom-file mt-3">
              <label class="custom-file-label" for="customFileLang">
                {{logo}}
              </label>
              <input type="file" class="custom-file-input" accept="image/png, image/jpeg" @change="onChangeLogo($event)" />
            </div>
          </div>
          <div class="col-4"></div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '@/mixins/defaultMatchjob';
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option
} from 'element-ui';

Vue.use(Loading.directive);

export default {
  name: "ConfiguracoesGerais",
  mixins: [defaultMatchjob],
  props: {
    propConfiguracoesGerais: [Array, Object]
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: false,
      imgChange: false,
      empresa: [],
      plano: [],
      validate: {
        nm_fantasia: false,
        hl_email: false,
        nu_telefone: false,
        hl_site: false,
        hl_logo: false
      },
      logo: "Selecione um arquivo...",
      novaFotoFile: "",
      novaFoto: "",
      imageError: "",
      selectRecrutamento: [
        { value: '0', label: 'Interno' },
        { value: '1', label: 'Externo' }
      ],
      selectSetor: [
        { value: '1', label: 'Academia de Esportes / Artes Marciais'},
        { value: '2', label: 'Açúcar e Álcool'},
        { value: '3', label: 'Administração e Participação'},
        { value: '4', label: 'Agências de Turismo / Viagem'},
        { value: '5', label: 'Agricultura / Pecuária / Silvicultura'},
        { value: '6', label: 'Alimentos'},
        { value: '7', label: 'Arquitetura / Paisagismo / Urbanismo'},
        { value: '8', label: 'Assessoria de Imprensa'},
        { value: '9', label: 'Automação'},
        { value: '10', label: 'Automotivo'},
        { value: '11', label: 'Bancário / Financeiro'},
        { value: '12', label: 'Bebidas'},
        { value: '13', label: 'Bens de Capital'},
        { value: '14', label: 'Bens de Consumo (Outros)'},
        { value: '15', label: 'Borracha'},
        { value: '16', label: 'Café'},
        { value: '17', label: 'Calçados'},
        { value: '18', label: 'Comércio Atacadista'},
        { value: '19', label: 'Comércio Varejista'},
        { value: '20', label: 'Comunicação'},
        { value: '21', label: 'Concessionárias / Auto Peças'},
        { value: '22', label: 'Construção Civil'},
        { value: '23', label: 'Consultoria Geral'},
        { value: '24', label: 'Contabilidade/ Auditoria'},
        { value: '25', label: 'Corretagem (Imóveis)'},
        { value: '26', label: 'Corretagem (Seguros)'},
        { value: '27', label: 'Corretagem de Títulos e Valores Imobiliários'},
        { value: '28', label: 'Cosméticos'},
        { value: '29', label: 'Diversão/ Entretenimento'},
        { value: '30', label: 'Educação/ Idiomas'},
        { value: '31', label: 'Eletrônica/ Eletroeletrônica/ Eletrodomésticos'},
        { value: '32', label: 'Embalagens'},
        { value: '33', label: 'Energia/ Eletricidade'},
        { value: '34', label: 'Engenharia'},
        { value: '35', label: 'Equipamentos industriais'},
        { value: '36', label: 'Equipamentos médicos / precisão'},
        { value: '37', label: 'Estética/ Academias'},
        { value: '38', label: 'Eventos'},
        { value: '39', label: 'Farmacêutica/ Veterinária'},
        { value: '40', label: 'Financeiras'},
        { value: '41', label: 'Gráfica/ Editoras'},
        { value: '42', label: 'Imortação/ Exportação'},
        { value: '43', label: 'Incorporadora'},
        { value: '44', label: 'Indústrias'},
        { value: '45', label: 'Informática'},
        { value: '46', label: 'Internet/ Sites'},
        { value: '47', label: 'Jornais'},
        { value: '48', label: 'Jurídica'},
        { value: '49', label: 'Logística / Armazéns'},
        { value: '50', label: 'Madeiras'},
        { value: '51', label: 'Materiais de Construção'},
        { value: '52', label: 'Material de Escritório'},
        { value: '53', label: 'Mecânica/ Manutenção'},
        { value: '54', label: 'Metalúrgica / Siderúrgica'},
        { value: '55', label: 'Mineração'},
        { value: '56', label: 'Móveis e Artefatos de decoração'},
        { value: '57', label: 'Órgãos públicos'},
        { value: '58', label: 'Outros'},
        { value: '59', label: 'Papel e Derivados'},
        { value: '60', label: 'Petroquímico/ Petróleo'},
        { value: '61', label: 'Plásticos'},
        { value: '62', label: 'Prestadora de Serviços'},
        { value: '63', label: 'Publicidade / Propaganda'},
        { value: '64', label: 'Recursos Humanos'},
        { value: '65', label: 'Relações Públicas'},
        { value: '66', label: 'Representação Comercial'},
        { value: '67', label: 'Restaurante/ Industrial/ Fast Food'},
        { value: '68', label: 'Saúde'},
        { value: '69', label: 'Segurança Patrimonial'},
        { value: '70', label: 'Seguros/ Previdência Privada'},
        { value: '71', label: 'Sindicatos / Associações / ONGs'},
        { value: '72', label: 'Supermercado / Hipermercado'},
        { value: '73', label: 'Telecomunicações'},
        { value: '74', label: 'Telemarketing/ Call Center'},
        { value: '75', label: 'Têxtil/ Couro'},
        { value: '76', label: 'Transportes'},
        { value: '77', label: 'Turismo/ Hotelaria'}
      ],
      selectPorte: [
        { value: '1', label: 'Grande (acima de 500 funcionários)' },
        { value: '2', label: 'Médio (entre 100 e 499 funcionários)' },
        { value: '3', label: 'Pequeno (entre 20 e 99 funcionários)' },
        { value: '4', label: 'Micro empresa (até 19 funcionários)' },
      ],
    }
  },
  methods: {
    /* async editFormSubmit() {
      this.loading = true;
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      this.$set(this.empresa, 'valida', data);
      await this.$http.post('empresa/atualizadados', this.empresa).then(res => {
        this.loading = false;
        this.notificacao("Dados atualizados com sucesso!", 'success');
      }).catch(err => {
        this.loading = false;
        this.notificacao("Ops! Não foi possível atualizar os dados.", 'warning');
        console.log(err);
      });
    },
    async carregaEmpresa() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      await this.$http.post('empresa/detalhe', data).then(res => {
        this.empresa = res.data.empresa;
        this.plano = res.data.plano;
      })
    }, */
    validaOnBlur(refName){
      setTimeout(() => {
        if (this.$refs[refName].value === '') {
          this.$set(this.validate, refName, true);
          return false;
        } else {
          this.$set(this.validate, refName, false);
          return true;
        }
      }, 300);
    },
    async onChangeLogo(event) {
      this.imgChange = true;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.logo = "";
        this.novaFoto = "";
        this.novaFotoFile = "";
        this.imageError = "A imagem escolhida é maior que 1 MB. Ajuste ou troque a imagem"
      } else {
        this.logo = file.name;
        this.novaFotoFile = file;
        const form = new FormData();
        form.append("id_empresa", this.propConfiguracoesGerais.id_empresa);
        form.append("hl_logo", this.novaFotoFile);
        await this.$http.post('empresa/atualizalogo', form)
          .then(res => {
            this.notificacao("Logo atualizada com sucesso!", 'success');
            this.propConfiguracoesGerais.hl_logo = res.data;
          })
          .catch(err => {
            this.notificacao("Não foi possível atualizar o Logo.", 'warning');
            console.log(err);
          });
        this.imageError = "";
        this.novaFoto = URL.createObjectURL(file);
      }
      this.imgChange = false;
    },
    async removerLogo() {
      this.imgChange = true;
       const data = {
        id_empresa: this.id.empresa
      }
      await this.$http.post('empresa/removerlogo', data)
        .then(res => {
          this.notificacao("Logo removido com sucesso!", 'success');
        })
        .catch(err => {
          this.notificacao("Não foi possível remover o Logo.", 'warning');
          console.log(err);
        });
      this.logo = "";
      this.novaFoto = "";
      this.novaFotoFile = "";
      this.propConfiguracoesGerais.hl_logo = "";
      this.imgChange = false;
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  mounted() {
    //this.carregaEmpresa();
  }
}
</script>

<style>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
