<template>
  <div class="content" v-loading="loading.full">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6" v-if="!loading.full">
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loading.body">
        <!-- Header - Titulo da Vaga -->
        <template slot="header">
          <div class="row justify-content-between">
            <div class="col-11 align-self-center" v-if="!loading.full">
              <h3 class="mb-0">{{vaga.nm_cargo}}  - <badge :type="retornaTipoTrabalhoBadge(vaga.json_vaga.fl_tipo_trabalho)">{{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}}</badge>
                 - <badge rounded :type="badgeStatusType(vaga.fl_status)">{{ badgeStatus(vaga.fl_status) }}</badge>
                <br>
                <small>{{vaga.json_vaga.nm_empresa}}</small>
              </h3>
            </div>
            <div class="col-1 align-self-center text-right">
              <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
            </div>
          </div>
        </template>
        <div v-loading="loading.body">
          <!-- Linha dos botões -->
          <AcoesRapidas
            :propVaga="vaga"
            :key="makeId()"
            @emitAcoesRapidas="emitido = $event"
            :propEmitido="verificaEmit"
          />
          <div class="row align-self-center">
            <div class="col-2 mt-4 align-middle text-center">
              <h1 class="display-1">{{ vaga.json_candidaturas.length }}</h1>
              <h5>Candidaturas</h5>
            </div>
            <div class="col-3 mt-4 align-middle">
              <p>Solicitante:<br><strong>{{vaga.nm_solicitante}}</strong> <span class="text-muted">({{vaga.nm_depto}})</span><br>
              Criada por:<br><strong>{{criadoPor.nome}}</strong> em: {{criadoPor.date | formatDate}}<br>
              </p>
            </div>
            <template v-if="parseInt(vaga.fl_status) === 4">
              <div class="col-3 mt-4 align-middle">
                Aprovador por:<br><strong>{{aprovador.nome}}</strong> em {{aprovador.date ? formatDate(aprovador.date) : 'N/A'}}<br>
                Vaga publicada em: <strong>{{vagaPublicada ? formatDateTime(vagaPublicada) : 'N/A'}}</strong><br>
                Previsão de fechamento: <strong>{{vaga.dt_prev_fechamento | formatDate}}</strong>
              </div>
              <div class="col-3 text-center align-middle">
                <h5>Dias restantes</h5>
                <GraficoBarrasPieDonutQtd
                  :key="makeId()"
                  :propSeries="diasDoProcesso"
                  propWidth="180"
                  :propLegendas="false"
                />
                <!-- <pre>{{diasDoProcesso}}</pre> -->
              </div>
            </template>
            <template v-if="parseInt(vaga.fl_status) > 4">
              <div class="col-3 mt-4 align-middle">
                Aprovador por:<br><strong>{{aprovador.nome}}</strong> em {{aprovador.date ? formatDate(aprovador.date) : 'N/A'}}<br>
                Vaga publicada em: <strong>{{vagaPublicada ? formatDateTime(vagaPublicada) : 'N/A'}}</strong><br>
                Previsão de fechamento: <strong>{{vaga.dt_prev_fechamento | formatDate}}</strong><br>
                Encerrada em: <strong>{{ vaga.dt_fechamento | formatDate }}</strong>
              </div>
            </template>
          </div>
          <template v-if="vaga.fl_status < 3">
            <div class="row justify-content-center pl-3">
              <div class="col-12 mx-3 mt-2">
                <h3>A vaga está pendente de aprovações</h3>
              </div>
            </div>
            <!-- Linha de aprovação por Admin -->
            <div class="row pl-3 justify-content-center" v-if="id.admin">
              <div class="col-1 text-center align-self-center">
                <base-button type="success" @click="aprovarVaga('admin')">Aprovar</base-button>
              </div>
              <div class="col-1 text-center align-self-center">
                <base-button type="warning" @click="reprovarVaga()">Reprovar</base-button>
              </div>
              <div class="col-10 align-self-center">
                <h4 class="mx-2">Você é um usuário 'admin' e pode sobrepor as aprovações pendentes.<br>Deseja aprovar ou reprovar a vaga?</h4>
              </div>
            </div>
            <!-- Inicio das verificações e listagem de aprovações -->
            <div class="row justify-content-center pl-3 pb-2">
              <div class="col-12">
                <template v-if="vaga.fl_status < 3">
                  <!-- Aprovação do RH -->
                  <template v-if="vaga.json_fases[0].requerAprovacao">
                    <h4>Aprovação do RH: <span
                      :class="vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado ? 'text-success' : 'text-danger'">
                      {{ vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado ? 'Aprovado!' : 'Pendente' }}
                      </span>
                    </h4>
                    <template v-if="!vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado">
                      <p class="font-weight-light mb-0">Pendente aprovação de:</p>
                      <template v-if="vaga.json_fases[0].aprovacoes.aprovacaoRh.tipoRh === 'area'">
                        <p
                          class="font-weight-light"
                          v-for="(user, index) in vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovador" :key="`rh${index}`">
                          <strong>{{user.nm_nome}}</strong> - {{user.hl_email}} <template v-if="id.empresa_user === user.id_empresa_user"><br>
                            <base-button size="sm" type="success" @click="aprovarVaga('aprovacaoRh')">Aprovar</base-button>
                            <base-button size="sm" type="warning" v-if="!user.aprovado" @click="reprovarVaga()">Reprovar</base-button></template>
                        </p>
                      </template>
                      <template v-else>
                        <p
                          class="font-weight-light"
                          v-for="(user, index) in vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovador" :key="`o${index}`">
                          <strong>{{user.nm_nome}}</strong> - {{user.hl_email}} <template v-if="id.empresa_user === user.id_empresa_user"><br>
                            <base-button size="sm" type="success" @click="aprovarVaga('aprovacaoRh')">Aprovar</base-button>
                            <base-button size="sm" type="warning" v-if="!user.aprovado" @click="reprovarVaga()">Reprovar</base-button></template>
                        </p>
                      </template>
                    </template>
                  </template>
                  <!-- Aprovação do Solicitante -->
                  <template v-if="vaga.json_fases[0].solicitanteAprova">
                    <h4>Aprovação do Solicitante da Vaga: <span
                      :class="vaga.json_fases[0].aprovacoes.solicitante.aprovado ? 'text-success' : 'text-danger'">
                      {{ vaga.json_fases[0].aprovacoes.solicitante.aprovado ? 'Aprovado!' : 'Pendente' }}
                      </span>
                    </h4>
                    <template v-if="!vaga.json_fases[0].aprovacoes.solicitante.aprovado">
                      <p class="font-weight-light mb-0">Pendente aprovação de:</p>
                      <p class="font-weight-light">
                        <strong>{{vaga.json_fases[0].aprovacoes.solicitante.nm_nome}}</strong> - {{vaga.json_fases[0].aprovacoes.solicitante.hl_email}}<br>
                        <template v-if="id.empresa_user === vaga.json_fases[0].aprovacoes.solicitante.id_empresa_user">
                          <base-button size="sm" type="success" @click="aprovarVaga('solicitante')">Aprovar</base-button>
                          <base-button size="sm" type="warning" v-if="!vaga.json_fases[0].aprovacoes.solicitante.aprovado" @click="reprovarVaga()">Reprovar</base-button>
                        </template>
                      </p>
                    </template>
                  </template>
                  <!-- Aprovações de Outros aprovadores -->
                  <template v-if="vaga.json_fases[0].flOutrosAprovadores">
                    <h4>Outras Aprovações: </h4>
                    <p class="font-weight-light mb-0">Pendente aprovação de:</p>
                    <p
                      class="font-weight-light"
                      v-for="(user, index) in vaga.json_fases[0].aprovacoes.outros" :key="`b${index}`">
                      <strong>{{user.nm_nome}}</strong> - {{user.hl_email}} - <badge rounded :type="user.aprovado ? 'success' : 'danger'">
                        {{ user.aprovado ? 'Aprovado!' : 'Pendente' }}
                      </badge><br>
                      <template v-if="id.empresa_user === user.id_empresa_user">
                        <base-button size="sm" type="success" v-if="!user.aprovado" @click="aprovarVaga('outros')">Aprovar</base-button>
                        <base-button size="sm" type="warning" v-if="!user.aprovado" @click="reprovarVaga()">Reprovar</base-button>
                      </template>
                    </p>
                  </template>
                </template>
              </div>
            </div>
          </template>
          <template v-if="parseInt(vaga.fl_status) >= 4">
            <div class="row px-2">
              <div class="col-auto ml-3">
                <h4>Informações da Vaga</h4>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-3 text-center">
                <h5>Aquisição</h5>
                <GraficoBarrasPieDonutQtd
                  :key="makeId()"
                  :propSeries="origemCandidaturas"
                  propType="pie"
                />
                <!-- {{origemDasCandidaturas()}} -->
              </div>
              <div class="col-6">
                <h5 class="ml-5">Candidatos por fase</h5>
                <GraficoLinhas
                  :key="makeId()"
                  :propSeries="candidatosNaFase"
                />
                <!-- Var -> <pre>{{candidatosNaFase}}</pre> -->
                <!-- Function -> <pre>{{candidatosPorFase()}}</pre> -->
              </div>
            </div>
            <div class="row px-2">
              <div class="col-auto ml-3">
                <h4>Dados Demográficos</h4>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-3 text-center">
                <h5>Sexo</h5>
                <GraficoBarrasPieDonut
                  :key="makeId()"
                  :propSeries="sexoCandidatos"
                  propType="pie"
                />
                <!-- <pre>{{sexoCandidatos}}</pre> -->
              </div>
              <div class="col-3 text-center">
                <h5>Idade</h5>
                <GraficoBarrasPieDonut
                  :key="makeId()"
                  :propSeries="idadeCandidatos"
                />
              </div>
              <div class="col-6">
                <h5 class="ml-5">Cidades x Candidatos</h5>
                <GraficoLinhas
                  :key="makeId()"
                  :propSeries="cidadesCandidatos"
                />
                <!-- {{cidadesCandidatos}} -->
              </div>
            </div>
            <div class="row px-2">
              <div class="col-6">
                <GraficoLinhas
                  :key="makeId()"
                  :propSeries="formacaoCandidatos"
                />
                <!-- {{formacaoCandidatos}} -->
              </div>
              <div class="col-6">
                <GraficoLinhas
                  :key="makeId()"
                  :propSeries="idiomasCandidatos"
                  propType="pie"
                />
                <!-- idiomasCandidatos -> <pre>{{idiomasCandidatos}}</pre> -->
              </div>
            </div>
          </template>
          <!-- <div v-for="(item, index) in vaga.json_candidaturas" :key="index">
            {{item.curriculo.endereco.nm_cidade}} - {{item.curriculo.endereco.nm_estado}}
          </div> -->
          <!-- <pre>{{ Object.keys(vaga.json_vaga) }}</pre><br>
          <pre>{{vaga.json_vaga}}</pre> -->
        </div>
      </card>
      <el-dialog :visible.sync="modal.linkedin" width="70%" title="Publicação no LinkedIn">
        <div class="row">
          <div class="col">
            <p>
              <strong>Cargo:</strong> {{vaga.nm_cargo}} <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(vaga.nm_cargo)"></i><br>
              <strong>Tipo de Local de Trabalho:</strong> {{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}} <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho))"></i><br>
              <strong>Localidade da Vaga:</strong> {{vaga.json_vaga.nm_cidade}} <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(vaga.json_vaga.nm_cidade)"></i><br>
              <strong>Tipo de vaga:</strong> {{retornaJornadas(vaga.json_vaga.nm_turno_trabalho)}} <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(retornaJornadas(vaga.json_vaga.nm_turno_trabalho))"></i>
            </p>
            <p>
              <strong>Link para receber as candidaturas:</strong> <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(`https://vagas.matchjob.com.br/${empresa.hl_url_amigavel}/vaga/${vaga.hl_link}?utm_source=linkedin`)"></i><br>
              https://vagas.matchjob.com.br/{{empresa.hl_url_amigavel}}/vaga/{{vaga.hl_link}}?utm_source=linkedin
            </p>
            <p>
              <strong>Descrição da Vaga: <i class="far fa-copy but-icone-linha-arquivo" @click="enviarParaClipboard(vaga.json_vaga.tx_descricao.replace(/(<([^>]+)>)/ig, '') + vaga.json_vaga.tx_requisitos.replace(/(<([^>]+)>)/ig, ''))"></i></strong><br>
              Descrição: <span v-html="vaga.json_vaga.tx_descricao" />
              Requisitos: <span v-html="vaga.json_vaga.tx_requisitos" />
            </p>
          </div>
        </div>
        <!-- <pre>{{vaga}}</pre> -->
        <div slot="footer">
          <base-button type="secondary" class="ml-3" @click="modal.linkedin = false">Fechar</base-button>
        </div>
      </el-dialog>
      <collapse>
        <collapse-item name="1" id="1">
          <h2 slot="title">Visualização da Vaga</h2>
            <VisualizarVagaComp
              :propVaga="vaga"
            />
        </collapse-item>
        <collapse-item name="4" id="4">
          <h2 slot="title">Timeline da Vaga</h2>
          <TimelineVaga
            :propTimeline="vaga.json_timeline"
          />
          <!-- <pre>{{timelineVaga}}</pre> -->
        </collapse-item>
      </collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '@/mixins/defaultMatchjob';
import { Loading, Select, Option, Autocomplete, Dialog } from 'element-ui';
import moment from "moment";
import { format } from 'date-fns';
import swal from 'sweetalert2';
import { db } from "../../firebase/config";
import { addDoc, getDoc, collection, getDocs } from "firebase/firestore";

import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

import AcoesRapidas from '../../components/VisualizacaoVaga/DashboadVaga/AcoesRapidas.vue'

import GraficoBarrasPieDonut from '../../components/Graficos/GraficoBarrasPieDonut.vue';
import GraficoBarrasPieDonutQtd from '../../components/Graficos/GraficoBarrasPieDonutQtd.vue';
import GraficoLinhas from '../../components/Graficos/GraficoLinhas.vue';
import GraficoRadial from '../../components/Graficos/GraficoRadial.vue';

import VisualizarVagaComp from "../../components/VisualizacaoVaga/VisualizarVagaComp.vue";
import TimelineVaga from "../../components/Timeline/TimelineVaga.vue";

Vue.use(Loading.directive);

export default {
  name: "DashboardVaga",
  mixins: [defaultMatchjob],
  components: {
    [Dialog.name]: Dialog,
    GraficoBarrasPieDonut,
    GraficoBarrasPieDonutQtd,
    GraficoLinhas,
    //GraficoRadial,
    Collapse,
    CollapseItem,

    AcoesRapidas,

    VisualizarVagaComp,
    TimelineVaga
  },
  data() {
    return {
      loading: {
        full: false,
        body: false
      },
      tipoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Híbrido" },
        { value: "3", text: "Home Office" }
      ],
      sexo: [
        { value:"1", label:"Masculino" },
        { value:"2", label:"Feminino" },
        { value:"3", label:"Prefiro não informar" },
      ],
      sexoCandidatos: null,
      idadeCandidatos: null,
      diasDoProcesso: null,
      cidadesCandidatos: null,
      candidatosNaFase: null,
      origemCandidaturas: null,
      formacaoCandidatos: null,
      idiomasCandidatos: null,
      aprovador: {
        nome: null,
        date: null,
        id_empresa_user: null
      },
      criadoPor: {
        nome: null,
        date: null
      },
      vagaPublicada: null,
      modal: {
        linkedin: false
      },
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
      emitido: null,
      templates: [],
    }
  },
  computed: {
    vaga() {
      return this.$store.state.empresa.vagas.find(v => v.hl_link === this.$route.params.url)
    },
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
  },
  methods: {
    badgeStatus(value) {
      var flStatusVaga = [
        { value: '1', text: "Aberta" },
        { value: '2', text: "Em Aprovação" },
        { value: '3', text: "Aprovada" },
        { value: '4', text: "Publicada" },
        { value: '5', text: "Rascunho" },
        { value: '6', text: "Encerrada" },
        { value: '7', text: "Cancelada" },
        { value: '8', text: "Pausado" },
        { value: '9', text: "Reprovada" }
      ];
      let tipo = flStatusVaga.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n === 2) {
        return "success";
      } else if (n === 3) {
        return "dark text-white";
      } else if (n === 4) {
        return "info"
      } else if (n === 5) {
        return "default text-white"
      } else if (n === 6) {
        return "attention"
      } else if (n === 7 || n === 9) {
        return "warning"
      } else if (n === 8) {
        return "darker text-white"
      }
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoTrabalhoBadge(value) {
      if (value === '1'){
        return "primary";
      } else if(value === '2'){
        return "info";
      } else {
        return "success";
      }
    },
    calcularIdade(dataNasc){
      var today = new Date();
      var birthDate = new Date(dataNasc);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--;
      }
      return age;
    },
    contaFaixaEtaria() {
      var abaixo20 = 0;
      var de20a29 = 0;
      var de30a39 = 0;
      var de40a49 = 0;
      var de50a59 = 0;
      var acima60 = 0;
      if(this.vaga.json_candidaturas.length > 0){
        this.vaga.json_candidaturas.forEach(element => {
          let idade = this.calcularIdade(element.curriculo.dt_nasc);
          if(idade < 20) {
            abaixo20 = abaixo20 +1;
          } else if(idade > 19 && idade < 30) {
            de20a29 = de20a29 +1;
          } else if(idade > 29 && idade < 40) {
            de30a39 = de30a39 +1;
          } else if(idade > 39 && idade < 50) {
            de40a49 = de40a49 +1;
          } else if(idade > 49 && idade < 60) {
            de50a59 = de50a59 +1;
          } else if(idade > 59) {
            acima60 = acima60 +1;
          }
        })
      }
      var result = {
        labels: [],
        series: [],
        seriesQtd: [],
      }
      if(abaixo20 > 0) {
        result.labels.push('Abaixo de 20');
        result.series.push(parseInt((abaixo20/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(abaixo20);
      }
      if(de20a29 > 0) {
        result.labels.push('Faixa dos 20');
        result.series.push(parseInt((de20a29/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(de20a29);
      }
      if(de30a39 > 0) {
        result.labels.push('Faixa dos 30');
        result.series.push(parseInt((de30a39/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(de30a39);
      }
      if(de40a49 > 0) {
        result.labels.push('Faixa dos 40');
        result.series.push(parseInt((de40a49/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(de40a49);
      }
      if(de50a59 > 0) {
        result.labels.push('Faixa dos 50');
        result.series.push(parseInt((de50a59/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(de50a59);
      }
      if(acima60 > 0) {
        result.labels.push('Acima dos 60');
        result.series.push(parseInt((acima60/this.vaga.json_candidaturas.length)*100));
        result.seriesQtd.push(acima60);
      }
      this.idadeCandidatos = result
    },
    contaSexoCandidatos() {
      var masc = 0;
      var mascP = 0;
      var fem = 0;
      var femP = 0;
      var noin = 0;
      var noinP = 0;
      if(this.vaga.json_candidaturas.length > 0){
        this.vaga.json_candidaturas.forEach(element => {
          if(element.curriculo.fl_sexo === '1') {
            masc = masc + 1;
          } else if(element.curriculo.fl_sexo === '2') {
            fem = fem + 1;
          } else if(element.curriculo.fl_sexo === '3') {
            noin = noin + 1;
          }
        });
      }
      var result = {
        labels: ['Homens', 'Mulheres', 'Não Informado'],
        series: [parseInt((masc/this.vaga.json_candidaturas.length)*100), parseInt((fem/this.vaga.json_candidaturas.length)*100),parseInt((noin/this.vaga.json_candidaturas.length)*100)],
        seriesQtd: [masc, fem, noin]
      }
      this.sexoCandidatos = result;
    },
    diasFaltantes() {
      let start = moment(this.vaga.dt_abertura);
      let end = moment(this.vaga.dt_prev_fechamento);
      let now = moment();
      let sobra = moment.duration(now.diff(start)).asDays();
      let duration = moment.duration(end.diff(start));
      let days = duration.asDays();
      let res = days - sobra;
      sobra = Math.ceil(sobra);
      res = Math.ceil(res);
      var result = {
        labels: ["Dias", "Dias faltantes"],
        series: [sobra, res],
        seriesQtd: [sobra, res],
        colors: ['#e94a10', '#e0dfdf'],
        titulo: "Dias restantes"
      }
      this.diasDoProcesso = result;
    },
    contaCidades() {
      var cidades = []
      var cat = []
      if(this.vaga.json_candidaturas.length > 0){
        this.vaga.json_candidaturas.forEach(function callback(element, index) {
          cat.push(index);
          let tempC = element.curriculo.endereco.nm_cidade + " - " + element.curriculo.endereco.nm_estado;
          if(cidades.length < 1) {
            let tempArr = { count: 1, value: tempC }
            cidades.push(tempArr);
          } else {
            let indice = cidades.findIndex(c => c.value === tempC);
            if(indice > -1) {
              cidades[indice].count = cidades[indice].count + 1;
            } else {
              let tempArr = { count: 1, value: tempC }
              cidades.push(tempArr)
            }
          }
        });
      }
      var result = {
        labels: [],
        series: [{name: 'Candidatos', data: []}],
        seriesQtd: [{name: 'Candidatos', data: []}],
        categories: cat,
      }
      cidades.forEach(elem => {
        result.labels.push(elem.value);
        result.seriesQtd[0].data.push(elem.count);
        result.series[0].data.push(elem.count);
      })
      this.cidadesCandidatos = result;
    },
    candidatosPorFase() {
      var arr = []
      var faseNome = []
      for(var f = 0; f < this.vaga.json_fases.length; f++) {
        let indice = f + 1;
        var candidatos = this.vaga.json_candidaturas.filter(c => c.fase_atual === indice);
        var count = candidatos.length
        if(this.vaga.json_fases[f].text) {
          faseNome.push(this.vaga.json_fases[f].text)
        } else {
          faseNome.push(this.vaga.json_fases[f].value)
        }
        arr.push(count)
      }
      var result = {
        labels: [],
        series: [{name: 'Candidatos na fase', data: [] }],
        seriesQtd: [{name: 'Candidatos na fase', data: [] }],
      }
      for(var i = 0; i < arr.length; i++) {
        if(i > 0) {
          result.labels.push(faseNome[i]);
          result.series[0].data.push(arr[i]);
          result.seriesQtd[0].data.push(arr[i]);
        }
      }
      this.candidatosNaFase = result;
    },
    origemDasCandidaturas() {
      var origem = []
      this.vaga.json_candidaturas.forEach(function callback(element, index) {
        if(origem.length < 1) {
          let tempArr = { count: 1, origem: element.fl_origem };
          origem.push(tempArr);
        } else {
          let indice = origem.findIndex(o => o.origem === element.fl_origem)
          if(indice > -1){
            origem[indice].count = origem[indice].count + 1;
          } else {
            let tempArr = { count: 1, origem: element.fl_origem };
            origem.push(tempArr);
          }
        }
      })
      var result = {
        labels: [],
        series: [],
        seriesQtd: [],
      }
      origem.forEach(e => {
        result.labels.push(e.origem);
        result.series.push(e.count);
        result.seriesQtd.push(e.count);
      })
      this.origemCandidaturas = result;
    },
    nivelFormacaoCandidatos() {
      var formacoes = []
      this.vaga.json_candidaturas.forEach(function callback(element, index) {
        if(element.curriculo.json_formacoes.length > 0) {
          element.curriculo.json_formacoes.forEach(el => {
            let formacao = { fl_tipo_formacao: el.fl_tipo_formacao, status:  el.fl_status }
            formacoes.push(formacao)
          })
        }
      })
      var result = {
        labels: [],
        series: [{name: 'Formações por Candidato', data: [] }],
        seriesQtd: [{name: 'Formações por Candidato', data: [] }],
        titulo: "Nível Acadêmico dos Candidatos"
      }
      var formacoesFinal = [];
      formacoes.forEach(elemento => {
        if(elemento.fl_tipo_formacao === 1 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Fundamental Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Fundamental Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 1 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Fundamental Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Fundamental Não Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 3 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Médio (2 grau) Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Médio (2 grau) Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 3 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Médio (2 grau) Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Médio (2 grau) Não Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 5 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Superior Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Superior Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 5 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Ensino Superior Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Ensino Superior Não Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 6 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Pós Graducação / MBA Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Pós Graducação / MBA Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 6 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Pós Graducação / MBA Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Pós Graducação / MBA Não Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 7 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Mestrado Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Mestrado Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 7 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Mestrado Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Mestrado Não Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 8 && elemento.status === true) {
          let indice = formacoesFinal.findIndex(i => i.text === "Doutorado Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Doutorado Concluído" }
            formacoesFinal.push(forma);
          }
        } else if(elemento.fl_tipo_formacao === 8 && elemento.status === false) {
          let indice = formacoesFinal.findIndex(i => i.text === "Doutorado Não Concluído")
          if(indice > -1) {
            formacoesFinal[indice].count = formacoesFinal[indice].count + 1;
          } else {
            let forma = { count: 1, text: "Doutorado Não Concluído" }
            formacoesFinal.push(forma);
          }
        }
      })
      formacoesFinal.forEach(al => {
        result.labels.push(al.text);
        result.series[0].data.push(al.count)
        result.seriesQtd[0].data.push(al.count)
      })
      this.formacaoCandidatos = result;
    },
    nivelIdiomas() {
      var nivelIdioma = [
        { value: 1, text: "Básico" },
        { value: 2, text: "Intermediário" },
        { value: 3, text: "Avançado" },
        { value: 4, text: "Fluente" }
      ]
      var idiomas = [];
      this.vaga.json_candidaturas.forEach(element => {
        if(element.curriculo.json_idiomas.length > 0) {
          element.curriculo.json_idiomas.forEach(el => {
            let indice = idiomas.findIndex(i => i.text === el.nm_idioma && i.nivel === el.fl_nivel)
            if(indice > -1) {
              idiomas[indice].count = idiomas[indice].count +1;
            } else {
              let idioma = { text: el.nm_idioma, nivel: el.fl_nivel, count: 1 }
              idiomas.push(idioma)
            }
          })
        }
      })
      var result = {
        labels: [],
        series: [{name: 'Idiomas por Candidato', data: [] }],
        seriesQtd: [{name: 'Idiomas por Candidato', data: [] }],
        titulo: "Nível de Idioma dos Candidatos"
      }
      idiomas.forEach(elemento => {
        let nivel = nivelIdioma.find(e => e.value === elemento.nivel)
        let text = elemento.text + " - " + nivel.text;
        result.labels.push(text);
        result.series[0].data.push(elemento.count);
        result.seriesQtd[0].data.push(elemento.count);
      })
      this.idiomasCandidatos = result;
    },
    verificaAprovadorVaga() {
      var indice = this.vaga.json_timeline.findIndex(v => v.tipo_registro === 'aprovacao');
      if(indice > -1) {
        this.aprovador.date = this.vaga.json_timeline[indice].date;
        this.aprovador.id_empresa_user = this.vaga.json_timeline[indice].id_empresa_user;
        var nome = this.usuarios.find(u => u.id_empresa_user === this.aprovador.id_empresa_user);
        this.aprovador.nome = nome.nm_nome;
      } else {
        this.aprovador.date = false;
        this.aprovador.nome = 'Não encontrado';
      }
    },
    retornaCriadoPor() {
      var nome = this.usuarios.find(u => u.id_empresa_user === this.vaga.id_empresa_user)
      this.criadoPor.nome = nome.nm_nome;
      this.criadoPor.date = this.vaga.created_at;
    },
    verificaDataPublicacao() {
      var indice = this.vaga.json_timeline.findIndex(v => v.text === 'Vaga aprovada e publicada!');
      if(indice > -1) {
        this.vagaPublicada = this.vaga.json_timeline[indice].date
      } else {
        this.vagaPublicada = false;
      }
    },
    montaGraficoPieDonut(pergunta) {
      var labels =  []
      var dados = []
      var dadosQtd = []
      var cores = []
      pergunta.options.forEach((element, index) => {
        labels.push(element.text)
        let tempNum = this.buscaRespostasDico(pergunta.respostas, element.text)
        dadosQtd.push(tempNum)
        let percentTemp = ((tempNum/pergunta.respostas.length)*100)
        dados.push(parseInt(percentTemp))
        cores.push(this.backgroundColor[index])
      });
      const data = {
        labels: labels,
        series: dados,
        seriesQtd: dadosQtd
      }
      console.log('data -> ', data)
      return data;
    },
    async enviarParaClipboard(valor) {
      try {
        await navigator.clipboard.writeText(valor);
        this.notificacao('Copiado para a área de transferência.', 'success')
      } catch($e) {
        this.notificacao('Ops! Falha para copiar para a área de transferência.', 'warning')
      }
    },
    retornaJornadas(value) {
      var res = this.jornadas.find(i => i.value === value);
      return res.text;
    },
    verificaEmit() {
      //console.log('emitido -> ', this.emitido)
      if(typeof this.emitido === 'string') {
        if(this.emitido === 'linkedin') {
          this.modal.linkedin = true;
        } else if(this.emitido === 'prepTemplate') {
          this.prepTemplate();
        } else if(this.emitido === 'encerrar') {
          this.handleAlteraStatus('encerrar')
        } else if(this.emitido === 'cancelar') {
          this.handleAlteraStatus('cancelar')
        } else if(this.emitido === 'congelar') {
          this.handleAlteraStatus('congelar')
        } else if(this.emitido === 'reativar') {
          this.handleAlteraStatus('reativar')
        } else if(this.emitido === 'editarVaga') {
          this.$router.push({ name: 'Editar Vaga', params: { url: this.vaga.hl_link } });
        } else if(this.emitido === 'editarProcesso') {
          this.$router.push({ name: 'EditarProcesso', params: { url: this.vaga.hl_link } });
        } else if(this.emitido === 'excluir') {
          this.handleDelete();
        }
      } else {
        if(this.emitido.evento === 'handleAlteraData') {
          this.handleAlteraData(this.emitido.value)
        }
      }
    },
    handleAlteraData(date) {
      if (date !== '' && date > this.vaga.dt_abertura) {
        //console.log('date -> ', date)
        this.loading.body = true;
        if(this.alteraStatusVaga('ajustardatafechamento', date)) {
          setTimeout(() => {
              this.loading.body = false;
              this.notificacao(`A vaga foi ajustada com sucesso`, 'success');
              this.$router.push({ name: 'Vagas' });
              //this.goToVagas();
            }, 500);
        } else {
          this.notificacao(`Ops! Não foi possível alterar a vaga`, 'warning');
        }
      } else {
        this.notificacao(`Ops! Não foi possível alterar a vaga`, 'warning');
      }
    },
    async alteraStatusVaga(novoStatus, date) {
      const data = {
        id_empresa: this.id.empresa,
        id_processo_vaga: this.vaga.id_processo_vaga,
        status: novoStatus,
        id_empresa_user: this.id.empresa_user,
        nm_nome: this.id.nome,
        hl_email: this.id.usuario,
        dt_prev_fechamento: date ? date : this.vaga.dt_prev_fechamento
      }
      //console.log('data alteraStatusVaga -> ', data)
      await this.$http
        .post('vagas/alterastatusvaga', data)
        .then(() => {
          var dados = {
            id_empresa: this.id.empresa,
            hl_link: this.vaga.hl_link
          }
          this.$store.dispatch('reLoadVaga', dados);
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    async prepTemplate() {
      const { value: nome } = await swal.fire({
        title: 'Defina o nome do Template',
        input: 'text',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Gravar',
        buttonsStyling: false,
        inputValidator: (value) => {
          if (!value) {
            return 'Você precisa definir um nome!'
          }
        }
      });
      if(nome) {
        //this.newVaga.nomeTemplate = nome;
        if (this.gravaTemplates(nome)) {
          //this.carregaTemplates();
          this.$notify({
            message:
              `Template ${nome} foi gravado com sucesso!`,
            timeout: 8000,
            icon: 'ni ni-bell-55',
            type: 'success'
          });
        } else {
          this.$notify({
            message:
              `Ops! Não foi possível gravar o template ${nome}.`,
            timeout: 8000,
            icon: 'ni ni-bell-55',
            type: 'danger'
          });
        }
      }
    },
    async gravaTemplates(nomeTemplate) {
      const colRefTemplates =
        "empresas/" +
        this.id.empresa +
        "/configuracoes/templates/vagas";
      const resTemplates = collection(db, colRefTemplates);
      var newTemplate = structuredClone(this.vaga.json_vaga);
      var newTemplateJsonTags = structuredClone(this.vaga.json_tags);
      //this.$delete(newTemplate, 'json_timeline');
      //this.$delete(newTemplate 'json_analise_ia');
      //this.$delete(newTemplate 'json_analise_ia');
      this.$set(newTemplate, 'nomeTemplate', nomeTemplate);
      this.$set(newTemplate, 'hl_link', '');
      this.$set(newTemplate, 'dt_abertura', '');
      this.$set(newTemplate, 'dt_prev_fechamento', '');
      this.$set(newTemplate, 'dt_fechamento', '');
      this.$set(newTemplate, 'fl_status', '1');
      this.$set(newTemplate, 'nm_solicitante', '');
      this.$set(newTemplate, 'nm_depto', '');
      this.$set(newTemplate, 'fl_video', false);
      this.$set(newTemplate, 'json_tags', newTemplateJsonTags);
      //console.log("antes de gravar -> ", newTemplate)
      const res = await addDoc(resTemplates, newTemplate)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      //return true;
    },
    handleAlteraStatus(novoStatus) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a ${novoStatus} a vaga:<br> ${this.vaga.nm_cargo}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          this.loading.full = true;
          if (this.alteraStatusVaga(novoStatus)){
            setTimeout(() => {
              this.loading.full = false;
              this.notificacao(`A vaga foi ${novoStatus} com sucesso`, 'success');
              this.$router.push({ name: 'Vagas' });
            }, 300);
          } else {
            this.loading.full = false;
            this.notificacao('Ops! Não foi possível alterar a vaga.', 'warning');
          }
        }
      });
    },
    async aprovarVaga(tipoAprovacao, usuario) {
      this.loading.body = true;
      if (tipoAprovacao === 'admin') {
        var aprovacaoCompleta = true;
        var data = {
          timeline: {
            tipo_registro: "aprovacao",
            text: "Aprovação realizada - Aprovação pelo Admin!",
            date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
            id_empresa_user: this.id.empresa_user,
            nome: this.id.nome + " - " + this.id.usuario
          },
          id_processo_vaga: this.vaga.id_processo_vaga
        }
        this.$store.dispatch('addTimelineVaga', data);
        //this.vaga.json_timeline.push(data.timeline);//REMOVER
        var dados = {
          vaga: this.vaga,
          aprovacaoCompleta: true,
          id_empresa_user: this.id.empresa_user
        }
        this.submitAprovacao(dados);
      } else if(tipoAprovacao === 'aprovacaoRh') {
        //this.vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado = true;//REMOVER
        //var pos = this.vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovador.find(i => i.id_empresa_user === usuario);
        var data = {
          tipoAprovacao: 'aprovacaoRh',
          timeline: {
            tipo_registro: "aprovacao",
            text: "Aprovação realizada - Aprovação do RH!",
            date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
            id_empresa_user: this.id.empresa_user,
            nome: this.id.nome + " - " + this.id.usuario
          },
          id_processo_vaga: this.vaga.id_processo_vaga,
          status: true
        }
        this.$store.dispatch('alteraStatusAprovacao', data);
        this.$store.dispatch('addTimelineVaga', data);
        //this.vaga.json_timeline.push(data.timeline);//REMOVER
        var aprovacaoCompleta = this.verificaStatusAprovacao();
        var dados = {
          vaga: this.vaga,
          aprovacaoCompleta: aprovacaoCompleta,
          id_empresa_user: this.id.empresa_user
        }
        this.submitAprovacao(dados);
      } else if(tipoAprovacao === 'solicitante') {
        //this.vaga.json_fases[0].aprovacoes.solicitante.aprovado = true;//REMOVER
        var data = {
          tipoAprovacao: 'solicitante',
          timeline: {
            tipo_registro: "aprovacao",
            text: "Aprovação realizada - Solicitante!",
            date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
            id_empresa_user: this.id.empresa_user,
            nome: this.vaga.json_fases[0].aprovacoes.solicitante.nm_nome + " - " + this.vaga.json_fases[0].aprovacoes.solicitante.hl_email
          },
          id_processo_vaga: this.vaga.id_processo_vaga,
          status: true
        }
        this.$store.dispatch('alteraStatusAprovacao', data);
        this.$store.dispatch('addTimelineVaga', data);
        var aprovacaoCompleta = this.verificaStatusAprovacao();
        //this.vaga.json_timeline.push(data.timeline);//REMOVER
        var dados = {
          vaga: this.vaga,
          aprovacaoCompleta: aprovacaoCompleta,
          id_empresa_user: this.id.empresa_user
        }
        this.submitAprovacao(dados);
      } else if(tipoAprovacao === 'outros') {
        var pos = this.vaga.json_fases[0].aprovacoes.outros.findIndex(i => i.id_empresa_user === this.id.empresa_user);
        //this.vaga.json_fases[0].aprovacoes.outros[pos].aprovado = true;//REMOVER
        var aprovacaoCompleta = this.verificaStatusAprovacao();
        var data = {
          tipoAprovacao: 'outros',
          timeline: {
            tipo_registro: "aprovacao",
            text: "Aprovação realizada - Outros aprovadores!",
            date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
            id_empresa_user: this.id.empresa_user,
            nome: this.vaga.json_fases[0].aprovacoes.outros[pos].nm_nome + " - " + this.vaga.json_fases[0].aprovacoes.outros[pos].hl_email
          },
          id_processo_vaga: this.vaga.id_processo_vaga,
          status: true,
          posicao: pos
        }
        this.$store.dispatch('alteraStatusAprovacao', data);
        this.$store.dispatch('addTimelineVaga', data);
        //this.vaga.json_timeline.push(data.timeline);//REMOVER
        var dados = {
          vaga: this.vaga,
          aprovacaoCompleta: aprovacaoCompleta,
          id_empresa_user: this.id.empresa_user
        }
        this.submitAprovacao(dados);
      }
    },
    async submitAprovacao(dados) {
      await this.$http
      .post('vagas/aprovarvaga', dados)
      .then(res => {
        if (res.data.fl_status === 2) {
          var data = {
            id_processo_vaga: this.vaga.id_processo_vaga,
            fl_status: "2"
          }
          this.$store.dispatch('alterarStatusVaga', data);
          this.vaga.fl_status = "2";//REMOVER
          this.notificacao('Aprovação realizada com sucesso!', 'success');
          this.loading.body = false;
          this.$forceUpdate();
        } else if (res.data.fl_status === 4) {
          var data = {
            id_processo_vaga: this.vaga.id_processo_vaga,
            fl_status: "4"
          }
          this.$store.dispatch('alterarStatusVaga', data);
          this.vaga.fl_status = "4";//REMOVER
          this.notificacao('Aprovação realizada com sucesso!', 'success');
          this.notificacao('Vaga publicada com sucesso!', 'success');
          this.loading.body = false;
          this.$forceUpdate();
        }
      }).catch(err => {
        console.log('Erro na API -> ', err);
        this.loading.body = false;
        this.notificacao('Ops! Houve uma falha não foi possível aprovar a vaga!', 'warning');
        this.$forceUpdate();
      });
    },
    verificaStatusAprovacao() {
      var count = 0;
      if (this.vaga.json_fases[0].requerAprovacao) {
        count += 1;
        if (this.vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado) {
          count -= 1;
        }
      }
      if (this.vaga.json_fases[0].solicitanteAprova) {
        count += 1;
        if (this.vaga.json_fases[0].aprovacoes.solicitante.aprovado) {
          count -= 1;
        }
      }
      if (this.vaga.json_fases[0].flOutrosAprovadores) {
        let num = this.vaga.json_fases[0].aprovacoes.outros.length
        count += num;
        for (var i = 0; i < num; i++) {
          if (this.vaga.json_fases[0].aprovacoes.outros[i].aprovado) {
            count -= 1;
          }
        }
      }
      //console.log('contador -> ', count)
      if (count === 0) {
        return true;
      } else {
        return false;
      }
    },
    handleDelete() {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir a vaga:<br> ${this.vaga.nm_cargo}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          this.loading = true;
          if (this.deletarVaga(this.vaga.id_processo_vaga)){
            //this.deleteRow(this.vaga.id_processo_vaga); loadVagas
            const data = { id_empresa: this.id.empresa }
            this.$store.dispatch('loadVagas', data);
            setTimeout(() => {
              this.loading = false;
              this.notificacao('Vaga excluída com sucesso', 'success');
              this.$router.push({ name: 'Vagas' });
            }, 1000);
          } else {
            this.loading = false;
            this.notificacao('Ops! Não foi possível excluír a vaga.', 'warning');
          }
        }
      });
    },
    async deletarVaga(id) {
      const data = {
        id_empresa: this.id.empresa,
        id_processo_vaga: id,
        nm_nome: this.id.nome,
        hl_email: this.id.usuario,
        id_empresa_user: this.id.empresa_user
      }
      await this.$http
        .post('vagas/deletar', data)
        .then(() => {
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    /*
    Funções Gerais
     */
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    makeId() {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 8; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
  },
  created() {
    this.loading.full = true;
  },
  mounted() {
    setTimeout(() => {
      // console.log('iniciou o timeout...')
      this.contaSexoCandidatos();
      this.contaFaixaEtaria();
      this.diasFaltantes();
      this.contaCidades();
      this.candidatosPorFase();
      this.origemDasCandidaturas();
      this.nivelFormacaoCandidatos();
      this.nivelIdiomas();
      this.verificaAprovadorVaga();
      this.retornaCriadoPor();
      this.verificaDataPublicacao();
      this.loading.full = false;
    }, 1000);
  }
}
</script>

<style>
.icone-help-bread-crumb {
  font-size: 1.6rem;
  cursor: pointer;
}
.col-background {
  background-color: #e0dfdf;
}
</style>
