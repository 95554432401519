<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Experiências Profissionais</h5>
      </div>
    </div>
    <div v-for="(exp, index) in propExperiencias" :key="index">
      <div class="row justify-content-start align-items-start">
        <div class="col-4">
          <small>Empresa:</small><br><strong>{{exp.nm_empresa}}</strong>
        </div>
        <div class="col-4">
          <small>Cargo:</small><br><strong>{{exp.nm_cargo}}</strong>
        </div>
        <div class="col-4">
          <small>Local de trabalho:</small><br><strong>{{exp.nm_local}}</strong>
        </div>
      </div>
      <div class="row justify-content-start align-items-start">
        <div class="col-4">
          <small>Data de inicio:</small><br><strong>{{exp.dt_inicio | formatDate}}</strong>
        </div>
        <div class="col-4">
          <small>Data de saída:</small><br><strong>{{exp.dt_fim | formatDate}}</strong>
        </div>
        <div class="col-4">
          <small>Emprego atual?</small><br><strong>{{exp.fl_atual ? 'Sim' : 'Não'}}</strong>
        </div>
      </div>
      <div class="row justify-content-start align-items-start">
        <div class="col-12">
          <strong>Atividades Realizadas</strong><br>
          {{exp.tx_descricao}}
        </div>
      </div>
      <hr class="my-2">
    </div>
    <!-- <pre>{{propExperiencias}}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ExperienciasCv",
  props: {
    propExperiencias: { type: Array }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style>

</style>
