<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Idiomas</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <template v-if="propVagaIdiomas.length < 1">
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12">
          <small>A vaga não possui idiomas definidos.</small>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-for="idiomaVaga in propVagaIdiomas">
        <div :key="idiomaVaga.id">
          <template v-if="verificaIdiomaMatch(idiomaVaga.value) >= 0">
            <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
              <div class="col-12">
                Resultado do match: <strong>{{propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].idioma_vaga}}</strong> - <strong><span :class="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].match_nivel ? 'text-success' : 'text-danger'">{{propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].match_nivel ? 'Positivo' : 'Negativo'}}</span></strong> <span
                :class="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].match_nivel ? 'text-success' : 'text-danger'"
                >
                <i class="fas" :class="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].match_nivel ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="flDetalhes">
                <!-- Idioma -->
                <div class="row justify-content-start align-items-start mt-1">
                  <div class="col-3">
                    Idioma da vaga:<br><strong>{{propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].idioma_vaga}}</strong>
                  </div>
                  <div class="col-auto">
                    Idioma do candidato:<br><strong>{{propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].idioma_candidato}}</strong>
                  </div>
                </div>
                <!-- Nivel do Idioma -->
                <div class="row justify-content-start align-items-start mt-1 mb-3">
                  <div class="col-3">
                    Nível do idioma da vaga:<br><strong>{{retornaNivel(propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_vaga)}}</strong>
                  </div>
                  <div class="col-auto">
                    Nível do idioma do candidato:<br><strong>{{retornaNivel(propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_candidato)}}</strong> <span
                    :class="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_candidato >= propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_vaga ? 'text-success' : 'text-danger'"
                    >
                      <i class="fas fa-equals" v-if="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_candidato === propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_vaga"></i>
                      <i class="far fa-arrow-alt-circle-up" v-else-if="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_candidato > propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_vaga"></i>
                      <i class="far fa-arrow-alt-circle-down" v-else-if="propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_candidato < propIdiomas[verificaIdiomaMatch(idiomaVaga.value)].nivel_vaga"></i>
                    </span>
                  </div>
                </div>
              </div>
            </transition>
          </template>
          <template v-else>
              <div class="row justify-content-start align-items-start mt-3">
                <div class="col-12">
                  Resultado do match: <strong>{{idiomaVaga.value}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                  class="text-danger"
                  >
                  <i class="fas fa-times-circle"></i></span>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div v-if="flDetalhes">
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-12">
                      <small>O candidato não deu 'match'com este idioma.</small>
                    </div>
                  </div>
                </div>
              </transition>
          </template>
        </div>
      </template>
    </template>
    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <div class="row justify-content-start align-items-start">
      <div class="col-6">
        propIdiomas -> <pre>{{propIdiomas}}</pre>
      </div>
      <div class="col-6">
        propVagaIdiomas -> <pre>{{propVagaIdiomas}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "IdiomasComp",
  props: {
    propIdiomas: { type: Array },
    propVagaIdiomas: { type: Array }
  },
  data() {
    return {
      nivelIdioma: [
        { value: 1, text: "Básico" },
        { value: 2, text: "Intermediário" },
        { value: 3, text: "Avançado" },
        { value: 4, text: "Fluente" }
      ],
      flDetalhes: false
    }
  },
  methods: {
    retornaNivel(value) {
      var nivel = this.nivelIdioma.find(i => i.value === value);
      return nivel.text;
    },
    verificaIdiomaMatch(value) {
      var idioma = this.propIdiomas.findIndex(i => i.idioma_candidato === value);
      return idioma;
      //console.log('idioma index -> ', idioma)
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
