<template>
    <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Vagas</h3>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="vagas">
            <el-table-column
              label="Vaga"
              min-width="200px"
              sortable>
              <div slot-scope="{ row }">
                <span class="link-ativo-cargo" @click="goToVaga(row.hl_link)">{{row.nm_cargo}}</span>
              </div>
            </el-table-column>
            <el-table-column label="Status" min-width="100px" prop="fl_status">
              <div slot-scope="{ row }">
                <badge rounded :type="badgeStatusType(row.fl_status)" class="text-dark" size="lg">
                  {{ badgeStatus(row.fl_status) }}</badge>
              </div>
            </el-table-column>
            <el-table-column
              label="Aberta em"
              min-width="100px"
              prop="dt_abertura"
              sortable
              :formatter="formatTimestamp">
            </el-table-column>
            <el-table-column
              label="Fechamento"
              min-width="100px"
              prop="dt_prev_fechamento"
              sortable
              :formatter="formatTimestamp">
            </el-table-column>
            <el-table-column label="Candidatos" min-width="100px">
              <div slot-scope="{ row }">
                {{ row.json_candidaturas.length }}
              </div>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import moment from "moment";
export default {
  name: 'light-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    itemLimit: {
      type: Number,
      default: 9
    }
  },
  data() {
    return {
      flStatusVaga: [
        { value: '1', text: "Aberta" },
        { value: '2', text: "Em Aprovação" },
        { value: '3', text: "Aprovada" },
        { value: '4', text: "Publicada" },
        { value: '5', text: "Rascunho" },
        { value: '6', text: "Encerrada" },
        { value: '7', text: "Cancelada" },
        { value: '8', text: "Congelada" },
        { value: '9', text: "Reprovada" }
      ],
      currentPage: 1
    };
  },
  computed: {
    vagas() {
      return this.$store.state.empresa.vagas;
    }
  },
  methods: {
    badgeStatus(value) {
      let tipo = this.flStatusVaga.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n === 2) {
        return "success";
      } else if (n === 3) {
        return "dark text-white";
      } else if (n === 4) {
        return "info"
      } else if (n === 5) {
        return "default text-white"
      } else if (n === 6) {
        return "attention"
      } else if (n === 7 || n === 9) {
        return "warning"
      } else if (n === 8) {
        return "darker text-white"
      }
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY");
    },
    goToVaga(value) {
      this.$router.push({ name: 'dashboardvaga', params: { url: value} });
    },
  }
}
</script>

<style scoped>
  .link-ativo-cargo {
    cursor: pointer;
    color: #5e72e4;
  }
  .link-ativo-cargo:hover {
    text-decoration: underline;
  }
</style>
