<template>
  <div>
    <div class="row my-2" v-if="testePerfil.id_teste === ''">
      <div class="col-5 align-self-center">
        <label>Escolha um teste de perfil para definir como padrão:</label>
        <el-select
          required
          class="inuptAutocomplete"
          v-model="addTestePerfil"
          placeholder="Escolha o Teste"
        >
        <template v-for="teste in testesPerfil">
          <template v-if="plano.testePerfil > 1">
            <el-option
              :key="teste.id_teste"
              :label="teste.nm_nome"
              :value="teste.id_teste">
            </el-option>
          </template>
          <template v-else-if="teste.nm_tipo === 'simples'">
            <el-option
              :key="teste.id_teste"
              :label="teste.nm_nome"
              :value="teste.id_teste">
            </el-option>
          </template>
        </template>
        </el-select>
      </div>
      <div class="col-1 align-self-end">
        <base-button type="default" class="btn-icon-only" @click="definirTestePerfil"><i class="fas fa-plus"></i></base-button>
      </div>
    </div>
    <div class="row my-2" v-else>
      <div class="col-auto">
        Nome do Teste: <strong>{{testeSelecionado.nm_nome}}</strong><br>
        Descrição: <em>{{testeSelecionado.nm_descricao}}</em>
      </div>
      <div class="col-auto">
        <base-button
          type="danger"
          size="sm"
          class="btn-icon-only"
          @click="removeTestePerfil()"
        ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
        </base-button>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-auto">
        <small class="text-muted"><em>O teste selecionado será enviado automáticamente quando um candidato chegar nessa fase.</em></small>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">testePerfil -> <pre>{{testePerfil}}</pre></div>
      <div class="col">testeSelecionado -> <pre>{{testeSelecionado}}</pre></div>
      <div class="col">testesPerfil -> <pre>{{testesPerfil}}</pre></div>
    </div> -->
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
export default {
  name: "CompTestePerfilVaga",
  props: {
    propFase: { type: Object },
    propEmit: { type: Boolean }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      addTestePerfil: "",
      testePerfil: {},
      testeSelecionado: {}
    }
  },
  computed: {
    plano() {
      return this.$store.state.empresa.plano.json_detalhes.ps;
    },
    testesPerfil() {
      return this.$store.state.testes.testesPerfil;
    }
  },
  methods: {
    removeTestePerfil() {
      //this.loading = true;
      setTimeout(() => {
        this.testePerfil.id_teste = "";
        this.testeSelecionado = {};
      }, 500);
    },
    definirTestePerfil() {
      //this.loading = true;
      if (this.addTestePerfil === '') {
        alert('Você precisa escolher um teste antes!');
        //this.loading = false;
      } else {
        this.testePerfil.id_teste = this.addTestePerfil;
        this.testeSelecionado = this.testesPerfil.find(t => t.id_teste === this.addTestePerfil)
        setTimeout(() => {
          this.addTestePerfil = "";
          //this.loading = false;
        }, 500);
      }
    },
    firstFormSubmit() {
      this.$emit('emitTestePerfil', this.testePerfil);
    }
  },
  mounted() {
    this.testePerfil = structuredClone(this.propFase);
    if(this.testePerfil.id_teste !== '') {
      this.testeSelecionado = this.testesPerfil.find(t => t.id_teste === this.testePerfil.id_teste)
    }
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
