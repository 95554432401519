<template>
  <div>
    <div class="row my-3" v-if="propFase.configs.entrevistaRH">
      <div class="col-auto">
        <em>Essa fase de entrevista com o time do RH não possui configurações específicas.</em>
      </div>
    </div>
    <div class="row my-3" v-else-if="propFase.configs.entrevistaSolicitante">
      <div class="col-auto">
        <em>Essa fase de entrevista com o Solicitante da Vaga não possui configurações específicas.</em>
      </div>
    </div>
    <div class="row my-3" v-else>
      <div class="col-auto">
        <em>Essa fase de entrevista não possui configurações específicas.</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompEntrevistaVaga",
  props: {
    propFase: { type: Object }
  }
}
</script>

<style>

</style>
