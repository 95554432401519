<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <label class="form-control-label">{{propValue}}</label><span v-if="propRequired" class="text-danger"> *</span>
      </div>
    </div>
    <div class="row mb-3 mt-1 mx-1">
      <div class="col">
        <div v-for="opt in propOptions" :key="opt.value">

          <!-- <div class="custom-control custom-radio">
            <input
              class="custom-control-input"
              :id="name"

              :name="opt.value"
              v-model="resp"
              @blur="emitData"
            />
            <label class="custom-control-label" :for="name">
              {{ opt.text }}
            </label>
          </div> -->
          <base-radio
            class="mb-3"
            :name="opt.value"
            v-model="resp"
            @input="emitData"
          >
            {{ opt.text }}
          </base-radio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadio from '../Inputs/BaseRadio.vue';
export default {
  components: { BaseRadio },
  name: "RespostaUnica",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean },
    propOptions: { type: Array }
  },
  data() {
    return {
      name: "",
      resp: "",
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    },
    makeName() {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 8; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  created() {
    this.name = this.makeName();
  }
}
</script>

<style>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
