<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="fas fa-file-contract"></i>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid rounded w-75" :src="retornaUsuario('foto')" :alt="retornaUsuario('nome')" />
          </div>
          <div class="col-9 align-self-center">
            <small><strong>{{retornaUsuario('nome')}}</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
          <div class="col-2 align-self-center">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            Proposta de Emprego enviada!<br>
            Data Limite de Aceite: <strong>{{propItem.dt_limite | formatDate}}</strong><br>
            Link da Carta Proposta:<br><small><a :href="propItem.cartaProposta" target="_blank">{{propItem.cartaProposta}}</a></small><br>
            Link para Aceite:<br><small><a :href="`https://vagas.matchjob.com.br/${propItem.url_empresa}/aceite-de-proposta/${propItem.hl_url}`" target="_blank">https://vagas.matchjob.com.br/{{propItem.url_empresa}}/aceite-de-proposta/{{propItem.hl_url}}</a></small><br>
            <!-- <pre>{{propItem}}</pre> -->
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";
export default {
  name: "CompPropostaEnviada",
  mixins: [defaultMatchjob],
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      usuarios: [],
      flDetalhes: false
    }
  },
  computed: {
    usuariosState() {
      return this.$store.state.usuarios.usuarios
    },
    testes() {
      return this.$store.state.testes.testes;
    },
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuariosState.length > 0) {
        var user = this.usuariosState.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    },
    retornaCategoriaTeste(id) {
      var teste = this.testes.find(t => t.id_teste === id)
      return teste.nm_categoria
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    },
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
