<template>
  <div v-loading="loading">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Nova Vaga</h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
              </div>
            </div>
            <div class="row justify-content-center mt-3"  ref="topo">
              <div class="col-auto m-auto">
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep1">
                  <el-tooltip content="Informações Gerais" placement="top" effect="light">
                    <i class="fas fa-address-card"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep2">
                  <el-tooltip content="Contratação e Requisitos" placement="top" effect="light">
                    <i class="fas fa-list"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep3">
                  <el-tooltip content="Vídeo sobre a vaga" placement="top" effect="light">
                    <i class="fas fa-video"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep4">
                  <el-tooltip content="Local da vaga e forma de trabalho" placement="top" effect="light">
                    <i class="fas fa-map-marker-alt"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep5">
                  <el-tooltip content="Benefícios" placement="top" effect="light">
                    <i class="fas fa-gift"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep6">
                  <el-tooltip content="Teste aplicados na Candidatura" placement="top" effect="light">
                    <i class="fas fa-question"></i>
                  </el-tooltip>
                </div>
                <div class="icon icon-shape rounded-circle shadow mx-3"
                  :class="classStep7">
                  <el-tooltip content="Requisitos Implicitos da vaga" placement="top" effect="light">
                    <i class="fas fa-sliders-h"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
          <template class="card-body">
            <base-nav class="navbar-top border-bottom navbar-expand mt--4" container-classes="container" type="quase-branco">
              <div class="col-2"></div>
              <div class="col-3 pr-0">
                <select class="form-control form-control-sm" v-model="selectedTemplate">
                  <option v-if="templates.length < 1">Não existem templates salvos</option>
                  <option v-else value="">Carregar um template</option>
                  <option v-for="temp in templates" :key="temp.id" :value="temp.nomeTemplate">
                    {{ temp.nomeTemplate}}
                  </option>
                </select>
              </div>
              <div class="col-1 pl-1 mr-3">
                <el-tooltip placement="top" content="Carregar Template" effect="light">
                  <base-button
                    type="primary"
                    size="sm"
                    native-type="submit"
                    @click="aplicaTemplate"
                  ><i class="fas fa-download"></i></base-button>
                </el-tooltip>
              </div>
              <div class="col-4 pl-1">
                <el-tooltip placement="top" content="Salvar Rascunho" effect="light">
                  <base-button type="default" size="sm" native-type="submit" class="mr-1"><i class="far fa-save"></i></base-button>
                </el-tooltip>
                <el-tooltip placement="bottom" content="Salvar como Template" effect="light">
                  <base-button
                    type="secondary"
                    size="sm"
                    native-type="submit"
                    class="mr-3"
                    @click="prepTemplate"
                  ><i class="fas fa-upload"></i></base-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ver Usuários" effect="light">
                  <base-button
                    type="info"
                    size="sm"
                    native-type="submit"
                    @click="modal.usuarios = true"
                  ><i class="fas fa-users"></i></base-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ver Endereços" effect="light">
                  <base-button
                    type="info"
                    size="sm"
                    native-type="submit"
                    @click="modal.enderecos = true"
                  ><i class="fas fa-map-marked-alt"></i></base-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ver Testes" effect="light">
                  <base-button
                    type="info"
                    size="sm"
                    native-type="submit"
                    class="mr-3"
                    @click="modal.testes = true"
                  ><i class="fas fa-chart-bar"></i></base-button>
                </el-tooltip>
                <el-tooltip placement="top" content="Ajuda" effect="light">
                  <base-button type="warning" size="sm" native-type="submit"><i class="fas fa-question"></i></base-button>
                </el-tooltip>
              </div>
              <div class="col-2"></div>
            </base-nav>
            <div class="card-body">
              <div class="row mb-5">

              </div>
              <Step1
                v-if="step1"
                @resStep1="dadosStep1 = $event"
                :concluiStep1="validaStep1"
                :propCargo="newVaga.nm_cargo"
                :propLink="newVaga.hl_link"
                :propArea="newVaga.nm_area"
                :propNivel="newVaga.nm_nivel"
                :propSolicitante="newVaga.nm_solicitante"
                :propDepto="newVaga.nm_depto"
                :propAbertura="newVaga.dt_abertura"
                :propFechamento="newVaga.dt_prev_fechamento"
                :propTags="newVaga.json_tags"
                :propSysInfos="sysInfos"
                :propUsuarios="usuarios"
              />
              <Step2
                v-if="step2"
                @resStep2="dadosStep2 = $event"
                :voltarStep2="voltaStep2"
                :concluiStep2="validaStep2"
                :propDeficientes="newVaga.fl_deficientes"
                :propContratacao="newVaga.fl_contratacao"
                :propSalario="newVaga.nu_salario"
                :propOculta_salario="newVaga.fl_oculta_salario"
                :propTurno_trabalho="newVaga.nm_turno_trabalho"
                :propDescricao="newVaga.tx_descricao"
                :propRequisitos="newVaga.tx_requisitos"
                :propCargo="newVaga.nm_cargo"
              />
              <Step3
                v-if="step3"
                @resStep3="dadosStep3 = $event"
                :voltarStep3="voltaStep3"
                :concluiStep3="validaStep3"
                :propFlVideo="newVaga.fl_video"
                :propTipoVideo="newVaga.fl_tipo_video"
                :propVideo="newVaga.hl_video"
              />
              <Step4
                v-if="step4"
                @resStep4="dadosStep4 = $event"
                :voltarStep4="voltaStep4"
                :concluiStep4="validaStep4"
                :propOcultaEmpresa="newVaga.fl_oculta_empresa"
                :propNomeEmpresa="newVaga.nm_empresa"
                :propHomeOffice="newVaga.fl_tipo_trabalho"
                :propIdEmpresaEnder="newVaga.id_empresa_ender"
                :propCidade="newVaga.nm_cidade"
                :propEndereco="newVaga.nm_endereco"
                :propListaEnderecos="enderecos"
              />
              <Step5
                v-if="step5"
                @resStep5="dadosStep5 = $event"
                :voltarStep5="voltaStep5"
                :concluiStep5="validaStep5"
                :propBeneficios="newVaga.json_beneficios"
              />
              <Step6
                v-if="step6"
                @resStep6="dadosStep6 = $event"
                :voltarStep6="voltaStep6"
                :concluiStep6="validaStep6"
                :propIdEmpresa="id"
                :propIdTeste="newVaga.id_teste"
                :propTestes="testes"
              />
              <Step7
                v-if="step7"
                @resStep7="dadosStep7 = $event"
                :voltarStep7="voltaStep7"
                :concluiStep7="validaStep7"
                :propCargo="newVaga.nm_cargo"
                :propArea="newVaga.nm_area"
                :propNivel="newVaga.nm_nivel"
                :propIdiomas="newVaga.json_idiomas"
                :propNivelexperiencia="newVaga.json_nivelexperiencia"
                :propCompetencias="newVaga.json_competencias"
                :propCertificacoes="newVaga.json_certificacao"
                :propFormacoes="newVaga.json_formacao"
                :propSysInfos="sysInfos"
                :propPlano="plano"
              />
              <Step8
                v-if="step8"
                @resStep8="dadosStep8 = $event"
                :voltarStep8="voltaStep8"
                :concluiStep8="validaStep8"
                :propVaga="newVaga"
                :propListaEnderecos="enderecos"
                :propTestes="testes"
                :propSysInfos="sysInfos"
                :propUsuarios="usuarios"
                :propProcessoSelecao="processoSelecao"
                :propPlano="plano"
              />
              <Finalizado
                v-if="stepFinalizado"
              />
            </div>
          </template>
          <template slot="footer">

          </template>
        </card>
        <!-- <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template class="card-body">
            <collapse>
              <collapse-item name="1">
                <h1 slot="title"><small class="text-muted">Processos de Seleção Existentes</small></h1>
                <div class="row">
                  <div class="col-3">
                    <pre>{{processoSelecao[0]}}</pre>
                  </div>
                  <div class="col-3">
                    <pre>{{processoSelecao[1]}}</pre>
                  </div>
                  <div class="col-3">
                    <pre>{{processoSelecao[2]}}</pre>
                  </div>
                  <div class="col-3">
                    <pre>{{processoSelecao[3]}}</pre>
                  </div>
                </div>
              </collapse-item>
              <collapse-item name="2">
                <h1 slot="title"><small class="text-muted">newVaga / novoProcessoVaga / plano</small></h1>
                <div class="row">
                  <div class="col-4">
                    <pre>{{newVaga}}</pre>
                  </div>
                  <div class="col-4">
                    novoProcessoVaga -> <pre>{{novoProcessoVaga}}</pre>
                  </div>
                  <div class="col-4">
                    plano -> <pre>{{plano}}</pre>
                  </div>
                </div>
              </collapse-item>
              <collapse-item name="3">
              <h1 slot="title"><small class="text-muted">enderecos / testes / usuarios / sysInfos</small></h1>
                <div class="row">
                  <div class="col-3">
                    enderecos ({{enderecos.length}}) -> <pre>{{enderecos}}</pre>
                  </div>
                  <div class="col-3">
                    testes ({{testes.length}}) -> <pre>{{testes}}</pre>
                  </div>
                  <div class="col-3">
                    usuarios ({{usuarios.length}}) -> <pre>{{usuarios}}</pre>
                  </div>
                  <div class="col-3">
                    sysInfos ({{Object.keys(sysInfos).length}}) -> <pre>{{sysInfos}}</pre>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </template>
        </card> -->
        <modal :show.sync="modal.enderecos" size="xl">
          <template v-slot:header>
            <h6 class="modal-title">Endereços</h6>
          </template>
          <EnderecosResumo
            v-if="modal.enderecos"
          />
          <div slot="footer">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <base-button type="dark" @click="modal.enderecos = false">Fechar</base-button>
              </div>
            </div>
          </div>
        </modal>
        <modal :show.sync="modal.usuarios" size="xl">
          <template v-slot:header>
            <h6 class="modal-title">Usuários</h6>
          </template>
          <UsuariosResumo
            v-if="modal.usuarios"
          />
          <div slot="footer">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <base-button type="dark" @click="modal.usuarios = false">Fechar</base-button>
              </div>
            </div>
          </div>
        </modal>
        <modal :show.sync="modal.testes" size="xl">
          <template v-slot:header>
            <h6 class="modal-title">Testes</h6>
          </template>
          <ListaTestesResumo
            v-if="modal.testes"
          />
          <div slot="footer">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <base-button type="dark" @click="modal.testes = false">Fechar</base-button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { userKey } from "@/global";
import { db } from "../../firebase/config";
import { addDoc, getDoc, collection, getDocs } from "firebase/firestore";
import { Table, TableColumn, Select, Option, Autocomplete } from 'element-ui';
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import Step6 from "./Step6.vue";
import Step7 from "./Step7.vue";
import Step8 from "./Step8.vue";
import Finalizado from "./Finalizado.vue";
import EnderecosResumo from '../Enderecos/EnderecosResumo.vue';
import UsuariosResumo from '../Usuarios/UsuariosResumo.vue';
import ListaTestesResumo from '../Testes/ListaTestesResumo.vue';
import { Loading } from 'element-ui';
import { format } from 'date-fns';
import swal from 'sweetalert2';
//REMOVER abaixo -->>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

Vue.use(Loading.directive);
export default {
  mixins: [defaultMatchjob],
  name: "NovaVaga",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    EnderecosResumo,
    UsuariosResumo,
    ListaTestesResumo,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Finalizado,
    //Collapse,
    //CollapseItem
  },
  data() {
    return {
      loading: false,
      plano: {},
      processoSelecao: [],
      templates: [],
      enderecos: [],
      testes: [],
      usuarios: [],
      sysInfos: {
        idiomas: [],
        competencias: [],
        certificacoes: [],
        formacoes: [],
        deptos: [],
        cargos: [],
      },
      selectedTemplate: "",
      newVaga: {
        nomeTemplate: "",
        id_empresa: "",
        id_empresa_user: "",
        fl_status: "1",
        nm_cargo: "",
        hl_link: "",
        nm_area: "",
        nm_nivel: "",
        nm_solicitante: "",
        nm_depto: "",
        dt_abertura: "",
        dt_prev_fechamento: "",
        dt_fechamento: "",
        fl_video: false,
        fl_tipo_video: "0",
        hl_video: "",
        tx_descricao: "",
        tx_requisitos: "",
        fl_deficientes: true,
        fl_contratacao: "3",
        nu_salario: "",
        fl_oculta_salario: false,
        nm_turno_trabalho: "1",
        fl_oculta_empresa: false,
        nm_empresa: "",
        fl_tipo_trabalho: "",
        id_empresa_ender: "",
        nm_cidade: "",
        nm_pais: "Brasil",
        nm_endereco: "",
        json_beneficios: [],
        json_tags: [],
        json_nivelexperiencia: {
          nivelCargo: [0, 5],
          nivelArea: [0, 5],
          nivelHierarquico: [0, 5],
        },
        json_competencias: [],
        json_idiomas: [],
        json_formacao: [],
        json_certificacao: [],
        id_teste: ""
      },
      novoProcessoVaga: {
        id_empresa: "",
        id_empresa_user: "",
        fl_status: "1",
        hl_link: "",
        nm_solicitante: "",
        nm_depto: "",
        dt_abertura: "",
        dt_prev_fechamento: "",
        dt_fechamento: "",
        vaga: {},
        tags: [],
        fases: [],
        competencias: [],
        candidaturas: []
      },
      dadosStep1: [],
      dadosStep2: [],
      dadosStep3: [],
      dadosStep4: [],
      dadosStep5: [],
      dadosStep6: [],
      dadosStep7: [],
      dadosStep8: {},
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      step8: false,
      stepFinalizado: false,
      classStep1: 'text-white bg-primary',
      classStep2: 'text-primary bg-white',
      classStep3: 'text-primary bg-white',
      classStep4: 'text-primary bg-white',
      classStep5: 'text-primary bg-white',
      classStep6: 'text-primary bg-white',
      classStep7: 'text-primary bg-white',
      classStep8: 'text-primary bg-white',
      classFaseAtiva: 'text-white bg-primary',
      classFaseConcluida: 'text-white bg-success',
      classFaseFaltante: 'text-primary bg-white',
      modal: {
        enderecos: false,
        usuarios: false,
        testes: false,
      }
    };
  },
  methods: {
    initNewVaga() {
      this.newVaga.nm_empresa = this.nomeFantasia;
      this.newVaga.id_empresa = this.id.empresa;
      this.newVaga.id_empresa_user = this.id.empresa_user;
      this.newVaga.dt_abertura = format(Date.now(), 'yyyy-MM-dd');
      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.processoSelecao = userData.empresa.json_processo_selecao.processo;
      this.plano = userData.plano.json_detalhes;
    },
    async carregaTemplates() {
      const colRefTemplates =
        "empresas/" +
        this.id.empresa +
        "/configuracoes/templates/vagas";
      const resTemplates = collection(db, colRefTemplates);
      await getDocs(resTemplates).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.templates = docs;
      });
    },
    async prepTemplate() {
      const { value: nome } = await swal.fire({
        title: 'Defina o nome do Template',
        input: 'text',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Gravar',
        buttonsStyling: false,
        inputValidator: (value) => {
          if (!value) {
            return 'Você precisa definir um nome!'
          }
        }
      });
      if(nome) {
        this.newVaga.nomeTemplate = nome;
        if (this.gravaTemplates()) {
          this.carregaTemplates();
          this.$notify({
            message:
              `Template ${nome} foi gravado com sucesso!`,
            timeout: 8000,
            icon: 'ni ni-bell-55',
            type: 'success'
          });
        } else {
          this.$notify({
            message:
              `Ops! Não foi possível gravar o template ${nome}.`,
            timeout: 8000,
            icon: 'ni ni-bell-55',
            type: 'danger'
          });
        }
      }
    },
    async gravaTemplates() {
      const colRefTemplates =
        "empresas/" +
        this.id.empresa +
        "/configuracoes/templates/vagas";
      const resTemplates = collection(db, colRefTemplates);
      //console.log("antes de gravar -> ", this.newVaga)
      const res = await addDoc(resTemplates, this.newVaga)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      return res;
    },
    aplicaTemplate() {
      if (this.selectedTemplate === ""){
        swal.fire({
          title: 'Ops!',
          text: 'Você precisa selecionar um template',
          icon: 'info'
        });
      } else {
        this.loading = true;
        this.newVaga = this.templates.find(i => i.nomeTemplate === this.selectedTemplate);
        this.step1 = false;
        this.$delete(this.newVaga, 'id');
        this.$nextTick(() => {
          this.step1 = true;
          swal.fire({
            title: 'Template Carregado!',
            icon: 'info'
          });
          this.loading = false;
        })
      }
    },
    carregaInfos() {
      if (this.$store.state.usuarios.usuarios.length < 1) {
        const data = {
          id_empresa: this.id.empresa,
          id_empresa_user: this.id.empresa_user,
          hl_email: this.id.usuario,
          fl_admin: this.id.admin
        }
        this.$store.dispatch('loadTestesUsuariosEnderecos', data);
      }
      this.testes = this.$store.state.testes.testes.filter(t => t.nm_tipo === 'simples');
      this.usuarios = this.$store.state.usuarios.usuarios;
      this.enderecos = this.$store.state.enderecos.enderecos;
    },
    loadSysInfos() {
      if(this.$store.state.sysinfos.cargos.length < 1) {
        this.$store.dispatch('loadSysInfos');
      }
      this.sysInfos.competencias = this.$store.state.sysinfos.competencias;
      this.sysInfos.idiomas = this.$store.state.sysinfos.idiomas;
      this.sysInfos.certificacoes = this.$store.state.sysinfos.certificacoes;
      this.sysInfos.formacoes = this.$store.state.sysinfos.formacoes;
      this.sysInfos.cargos = this.$store.state.sysinfos.cargos;
      this.sysInfos.deptos = this.$store.state.sysinfos.deptos;
    },
    verificaTesteSimples(value) {
      return value.nm_tipo === 'simples'
    },
    validaStep1() {
      const itens = Object.keys(this.dadosStep1).length;
      if (itens > 0) {
        this.newVaga.nm_cargo = this.dadosStep1.nm_cargo;
        this.newVaga.hl_link = this.dadosStep1.hl_link;
        this.newVaga.nm_area = this.dadosStep1.nm_area;
        this.newVaga.nm_nivel = this.dadosStep1.nm_nivel;
        this.newVaga.nm_solicitante = this.dadosStep1.nm_solicitante;
        this.newVaga.nm_depto = this.dadosStep1.nm_depto;
        this.newVaga.dt_abertura = this.dadosStep1.dt_abertura;
        this.newVaga.dt_prev_fechamento = this.dadosStep1.dt_prev_fechamento;
        this.newVaga.json_tags = this.dadosStep1.json_tags;
        this.step1 = false;
        this.classStep1 = this.classFaseConcluida;
        this.classStep2 = this.classFaseAtiva;
        this.step2 = true;
        this.scrollToTop();
      }
    },
    validaStep2() {
      const itens = Object.keys(this.dadosStep2).length;
      if (itens > 0) {
        this.newVaga.fl_deficientes = this.dadosStep2.fl_deficientes;
        this.newVaga.fl_contratacao = this.dadosStep2.fl_contratacao;
        this.newVaga.nu_salario = this.dadosStep2.nu_salario;
        this.newVaga.fl_oculta_salario = this.dadosStep2.fl_oculta_salario;
        this.newVaga.nm_turno_trabalho = this.dadosStep2.nm_turno_trabalho;
        this.newVaga.tx_descricao = this.dadosStep2.tx_descricao;
        this.newVaga.tx_requisitos = this.dadosStep2.tx_requisitos;
        this.step2 = false;
        this.classStep2 = this.classFaseConcluida;
        this.classStep3 = this.classFaseAtiva;
        this.step3 = true;
        this.scrollToTop();
      }
    },
    validaStep3() {
      const itens = Object.keys(this.dadosStep3).length;
      if (itens > 0) {
        this.newVaga.fl_video = this.dadosStep3.fl_video;
        this.newVaga.fl_tipo_video = this.dadosStep3.fl_tipo_video;
        this.newVaga.hl_video = this.dadosStep3.hl_video;
        this.step3 = false;
        this.classStep3 = this.classFaseConcluida;
        this.classStep4 = this.classFaseAtiva;
        this.step4 = true;
        this.scrollToTop();
      }
    },
    validaStep4() {
      const itens = Object.keys(this.dadosStep4).length;
      if (itens > 0) {
        this.newVaga.fl_oculta_empresa = this.dadosStep4.fl_oculta_empresa;
        this.newVaga.nm_empresa = this.dadosStep4.nm_empresa;
        this.newVaga.fl_tipo_trabalho = this.dadosStep4.fl_tipo_trabalho;
        this.newVaga.id_empresa_ender = this.dadosStep4.id_empresa_ender;
        this.newVaga.nm_cidade = this.dadosStep4.nm_cidade;
        this.newVaga.nm_endereco = this.dadosStep4.nm_endereco;
        this.step4 = false;
        this.classStep4 = this.classFaseConcluida;
        this.classStep5 = this.classFaseAtiva;
        this.step5 = true;
        this.scrollToTop();
      }
    },
    validaStep5() {
      const itens = Object.keys(this.dadosStep5).length;
      if (itens > 0) {
        this.newVaga.json_beneficios = this.dadosStep5.beneficios;
        this.step5 = false;
        this.classStep5 = this.classFaseConcluida;
        this.classStep6 = this.classFaseAtiva;
        this.step6 = true;
        this.scrollToTop();
      }
    },
    validaStep6() {
      const itens = Object.keys(this.dadosStep6).length;
      if (itens > 0) {
        this.newVaga.id_teste = this.dadosStep6.selectedTeste;
        this.step6 = false;
        this.classStep6 = this.classFaseConcluida;
        this.classStep7 = this.classFaseAtiva;
        this.step7 = true;
        this.scrollToTop();
      }
    },
    validaStep7() {
      const itens = Object.keys(this.dadosStep7).length;
      if (itens > 0) {
        this.newVaga.json_idiomas = this.dadosStep7.idiomas;
        this.newVaga.json_nivelexperiencia = this.dadosStep7.nivelexperiencia;
        this.newVaga.json_competencias = this.dadosStep7.competencias;
        this.newVaga.json_certificacao = this.dadosStep7.certificacoes;
        this.newVaga.json_formacao = this.dadosStep7.formacoes;
        this.step7 = false;
        this.classStep7 = this.classFaseConcluida;
        this.classStep8 = this.classFaseAtiva;
        this.step8 = true;
        this.scrollToTop();
      }
    },
    async validaStep8() {
      //console.log('this.dadosStep8 -> ', this.dadosStep8)
      this.loading = true;
      var processoSelecionado = this.processoSelecao.find(proc => proc.id === this.dadosStep8.id);
      var solicitante = [];
      var aprovadorRh = [];
      if(this.dadosStep8.solicitanteAprova) {
        solicitante = this.usuarios.find(u => u.nm_nome === this.newVaga.nm_solicitante);
        if (solicitante.length < 1) {
          this.dadosStep8.solicitanteAprova = false;
        } else {
          this.$set(this.dadosStep8, 'solicitante', solicitante)
        }
      }
      if(this.dadosStep8.requerAprovacao) {
        if (this.dadosStep8.aprovacaoTipo.tipoRh === 'area') {
          aprovadorRh = this.usuarios.filter(user => user.fl_tipo_acesso === this.dadosStep8.aprovacaoTipo.tipoAcesso);
          if (aprovadorRh.length < 1) {
            aprovadorRh = this.usuarios.find(user => user.fl_admin === '1');
          }
        } else if (this.dadosStep8.aprovacaoTipo.tipoRh === 'usuario') {
          aprovadorRh = this.usuarios.find(user => user.id_empresa_user === this.dadosStep8.aprovacaoTipo.usuario);
          if (aprovadorRh.length < 1) {
            aprovadorRh = this.usuarios.find(user => user.fl_admin === '1');
          }
        }
      }
      this.$set(this.dadosStep8, 'aprovadorRh', aprovadorRh)
      var criadoPor = {
        id_empresa_user: this.id.empresa_user,
        nm_nome: this.id.nome,
        hl_email: this.id.usuario
      };
      this.$set(this.dadosStep8, 'criadoPor', criadoPor)
      var dados = {
        vaga: this.newVaga,
        aprovacoes: this.dadosStep8,
        processo: processoSelecionado
      };
      await this.$http
        .post('vagas/salvavaga', dados)
        .then(() => {
          this.step8 = false;
          this.stepFinalizado = true;
          this.loading = false;
          this.reloadVagas();
          setTimeout(() => {
            this.$router.push({ name: 'Editar Vaga', params: { url: this.newVaga.hl_link } });
          }, 1000);
        }).catch(err => {
          console.log('Erro na API -> ', err);
          this.loading = false;
          return false;
        });
    },
    reloadVagas() {
      var dados = {
        id_empresa: this.id.empresa
      }
      this.$store.dispatch('loadVagas', dados);
    },
    voltaStep2() {
      this.step2 = false;
      this.classStep2 = this.classFaseConcluida;
      this.classStep1 = this.classFaseAtiva;
      this.step1 = true;
      this.scrollToTop();
    },
    voltaStep3() {
      this.step3 = false;
      this.classStep3 = this.classFaseConcluida;
      this.classStep2 = this.classFaseAtiva;
      this.step2 = true;
      this.scrollToTop();
    },
    voltaStep4() {
      this.step4 = false;
      this.classStep4 = this.classFaseConcluida;
      this.classStep3 = this.classFaseAtiva;
      this.step3 = true;
      this.scrollToTop();
    },
    voltaStep5() {
      this.step5 = false;
      this.classStep5 = this.classFaseConcluida;
      this.classStep4 = this.classFaseAtiva;
      this.step4 = true;
      this.scrollToTop();
    },
    voltaStep6() {
      this.step6 = false;
      this.classStep6 = this.classFaseConcluida;
      this.classStep5 = this.classFaseAtiva;
      this.step5 = true;
      this.scrollToTop();
    },
    voltaStep7() {
      this.step7 = false;
      this.classStep7 = this.classFaseConcluida;
      this.classStep6 = this.classFaseAtiva;
      this.step6 = true;
    },
    voltaStep8() {
      this.step8 = false;
      this.classStep8 = this.classFaseConcluida;
      this.classStep7 = this.classFaseAtiva;
      this.step7 = true;
    },
    scrollToTop() {
      //window.scrollTo(0,0);
      //console.log('refs topo ->>', this.$refs.topo)
      this.$refs.topo.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    }
  },
  mounted() {
    this.initNewVaga();
    this.carregaTemplates();
    this.carregaInfos();
    this.loadSysInfos();
    /* this.$nextTick(() => {
    }); */
  }
}
</script>

<style>

</style>
