<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5>Timeline do Candidato</h5>
      </div>
    </div>
    <div v-for="(linha, index) in propTimeline" :key="index">
      <CompSistema
        v-if="linha.tipo_registro === 'sistema' || linha.tipo_registro === 'simples'"
        :propItem="linha"
      />
      <CompGenerico
        v-if="linha.tipo_registro === 'comentario'"
        :propItem="linha"
      />
      <CompGenerico
        v-if="linha.tipo_registro === 'score_action'"
        :propItem="linha"
      />
      <CompGenerico
        v-if="linha.tipo_registro === 'mudanca_fase'"
        :propItem="linha"
      />
      <CompTesteVaga
        v-if="linha.tipo_registro === 'teste_respondido'"
        :propItem="linha"
        :propTeste="propResultadoTestes"
      />
      <CompEntrevista
        v-if="linha.tipo_registro === 'entrevista'"
        :propItem="linha"
      />
      <CompEntrevistaRealizada
        v-if="linha.tipo_registro === 'entrevistaRealizada'"
        :propItem="linha"
      />
      <CompTesteEnviado
        v-if="linha.tipo_registro === 'envioTeste'"
        :propItem="linha"
      />
      <CompTestePerfilEnviado
        v-if="linha.tipo_registro === 'envioTestePerfil'"
        :propItem="linha"
      />
      <CompTesteRespondido
        v-if="linha.tipo_registro === 'testeRealizado'"
        :propItem="linha"
      />
      <CompIndicacaoRegistrada
        v-if="linha.tipo_registro === 'indicacao'"
        :propItem="linha"
      />
      <CompReferenciaProfisionalRegistrada
        v-if="linha.tipo_registro === 'novaReferencia'"
        :propItem="linha"
      />
      <CompPropostaEnviada
        v-if="linha.tipo_registro === 'negociacaoEnviada'"
        :propItem="linha"
      />
      <CompPropostaAceita
        v-if="linha.tipo_registro === 'negociacaoAceita'"
        :propItem="linha"
      />
      <!-- {{linha.text}} -->
    </div>
    <!-- <pre>{{propTimeline}}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
import CompSistema from "../Timeline/CompSistema.vue";
import CompGenerico from "../Timeline/CompGenerico.vue";
import CompEntrevista from "../Timeline/CompEntrevista.vue";
import CompEntrevistaRealizada from "../Timeline/CompEntrevistaRealizada.vue";
import CompTesteEnviado from "../Timeline/CompTesteEnviado.vue";
import CompTestePerfilEnviado from "../Timeline/CompTestePerfilEnviado.vue";
import CompTesteVaga from "../Timeline/CompTesteVaga.vue";
import CompTesteRespondido from "../Timeline/CompTesteRespondido.vue";
import CompIndicacaoRegistrada from "../Timeline/CompIndicacaoRegistrada.vue";
import CompReferenciaProfisionalRegistrada from "../Timeline/CompReferenciaProfisionalRegistrada.vue";
import CompPropostaEnviada from "../Timeline/CompPropostaEnviada.vue";
import CompPropostaAceita from "../Timeline/CompPropostaAceita.vue";

export default {
  name: "TimelineCandidato",
  props: {
    propTimeline: { type: Array },
    propUsuarios: { type: Array },
    propResultadoTestes: { type: Array }
  },
  components: {
    CompSistema,
    CompGenerico,
    CompTesteEnviado,
    CompTestePerfilEnviado,
    CompTesteVaga,
    CompEntrevista,
    CompEntrevistaRealizada,
    CompTesteRespondido,
    CompIndicacaoRegistrada,
    CompReferenciaProfisionalRegistrada,
    CompPropostaEnviada,
    CompPropostaAceita
  },
  data() {
    return {
      timeline: []
    }
  },
  methods: {
    retornaUsuario(id, valor) {
      var user = this.propUsuarios.find(i => i.id_empresa_user === id);
      console.log('user -> ', user);
      if(valor === 'nome') {
        return user.nm_nome;
      } else if(valor === 'foto') {
        return user.hl_foto;
      }
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    //this.timeline = this.propTimeline;
    //this.timeline.reverse();
  }
}
</script>

<style>

</style>
