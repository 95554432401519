<template>
  <div class="content" v-loading="loading.full">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6" >
      <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loading.body">
        <!-- Header - Titulo da Vaga -->
        <template slot="header">
          <div class="row justify-content-between">
            <div class="col-11 align-self-center" v-if="!loading.full">
              <h3 class="mb-0">{{vaga.nm_cargo}}  - <badge :type="retornaTipoTrabalhoBadge(vaga.json_vaga.fl_tipo_trabalho)">{{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}}</badge><br>
                <small>{{vaga.json_vaga.nm_empresa}}</small>
              </h3>
            </div>
            <div class="col-1 align-self-center text-right">
              <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
            </div>
          </div>
        </template>
        <template class="card-body" v-loading="loading.body">
          <!-- Linha de boxes de fases -->
          <div class="row justify-content-center pb-2">
            <div class="col-12 text-center">
              <perfect-scrollbar>
                <div class="d-flex flex-row justify-content-center">
                  <template v-for="(fase, index) in vaga.json_fases">
                    <div
                      class="but-fase box-fase align-middle"
                      :class="verificaCandidatosFase(fase.id)"
                      v-if="fase.tipo !== 'publicacaoVaga'"
                      :key="fase.id"
                      @click="filtraCandidatosFase(fase.id)"
                    >
                      <span class="text-muted"><small>{{index}}ª Fase</small></span><br>
                      {{fase.text ? fase.text : fase.value}}
                    </div>
                  </template>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
          <!-- Linha de boxes de fases -->

          <!-- Box de fases ativas -->
          <div class="row justify-content-start align-items-start px-3 pb-2">
            <div :class="showDetalhes ? 'col-3' : 'col-12'">
              <!-- lista de candidaturas -->
              <div class="row justify-content-start align-items-start" v-if="candidatosFase.length === 0">
                <div class="col">
                  Nenhuma candidatura nesta fase
                  <el-tooltip placement="top" content="Recarregar Candidatos" effect="light">
                    <base-button outline icon size="sm" type="success" round class="btn-icon-only" @click="recarregarCandidatos">
                      <i class="fas fa-sync-alt"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
              <div class="row justify-content-start align-items-start" v-else-if="candidatosFase.length > 0">
                <div class="col">
                  Lista de Candidatos
                  <el-tooltip placement="top" content="Recarregar Candidatos" effect="light">
                    <base-button outline icon type="info" round class="btn-icon-only" @click="recarregarCandidatos">
                      <span class="material-symbols-outlined mt-1">refresh</span>
                    </base-button>
                  </el-tooltip>
                  <el-tooltip placement="top" content="Salvar Alterações" effect="light">
                    <base-button icon type="success" round class="btn-icon-only" @click.prevent="atualizaVaga">
                      <span class="material-symbols-outlined mt-1">check_circle</span>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
              <perfect-scrollbar>
              <table class="my-3" style="width:100%">
              <!-- <template v-for="(candidato, index) in vaga.json_candidaturas"> -->
              <template v-for="(candidato, index) in candidatosFase">
                <tr :key="index" class="border rounded my-5">
                  <!-- Coluna do checkbox -->
                  <!-- <td class="pl-3 pr-2 pb-3 text-center align-middle">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="index"
                        v-model="candidatosSelecionados"
                      />
                    </div>
                  </td> -->
                  <!-- Coluna da Foto -->
                  <td class="td-linked p-2 align-middle" @click="mostraDetalhes(index)">
                    <div class="avatar rounded-circle ml-2">
                      <img :src="candidato.curriculo.hl_foto" alt="Table image" />
                    </div>
                  </td>
                  <!-- Coluna do Nome e outras Infos -->
                  <td class="td-linked" @click="mostraDetalhes(index)">
                    <template v-if="candidato.indicacoes.length > 0">
                      <i class="text-success fas fa-share"></i>
                    </template> <strong>{{candidato.curriculo.nm_nome}}</strong> <template v-if="plano.json_detalhes.matchAi.tempoExperiencia">
                      <template v-if="candidato.analise_ia.resultadosAi.tempoExperiencia.resvalidaIdadeTempoTrabalho == 'Tempo de trabalho incompativel com a idade'">
                        <el-tooltip placement="top" content="Tempo de trabalho incompativel com a idade" effect="light"><i class="text-danger fas fa-exclamation-circle"></i></el-tooltip>
                      </template>
                    </template><br>
                    <!-- {{candidato.analise_ia.resultadosAi.tempoExperiencia.resvalidaIdadeTempoTrabalho}} -->
                    <small class="text-muted">via {{ retornaOrigemCandidatura(candidato.fl_origem) }}</small><br>
                    <small>em {{ candidato.dt_candidatura | formatDate }}</small>
                  </td>
                  <!-- Coluna da Pontuação -->
                  <td class="p-2 align-middle" v-if="!showDetalhes">
                    {{candidato.analise_ia.pontuacaoTotal}}
                  </td>
                  <!-- Coluna dos icones -->
                  <td v-if="!showDetalhes">
                    <el-tooltip placement="top" content="PDC" effect="light">
                      <i
                        :class="candidato.curriculo.fl_deficiencia ? 'icones-caracteristicas-ativo' : 'icones-caracteristicas-inativo'"
                        class="fab fa-accessible-icon mx-1"></i>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Reside nas proximidades" effect="light" v-if="candidato.analise_ia.resultadosAi.localizacao.status">
                      <i
                        :class="verificaProximidadeResidenciaVaga(candidato.analise_ia.resultadosAi.localizacao.pontostotal, candidato.analise_ia.resultadosAi.localizacao.tipo_localizacao) ? 'icones-caracteristicas-ativo' : 'icones-caracteristicas-inativo'"
                        class="fas fa-map-marker-alt mx-1"></i>
                    </el-tooltip>
                    <!-- <el-tooltip placement="top" content="Match com a vaga" effect="light">
                      <i class="icones-caracteristicas-inativo fas fa-chart-line mx-1"></i>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Comentários" effect="light">
                      <i class="icones-caracteristicas-inativo fas fa-comment-dots mx-1"></i>
                    </el-tooltip> -->
                    <el-tooltip placement="top" :content="`Curtidas (${candidato.score_card[0].likes.pontos})`" effect="light">
                      <i
                        :class="retornaLikes(candidato.score_card[0].likes.pontos) ? 'icones-caracteristicas-ativo' : 'icones-caracteristicas-inativo'"
                        class="fas fa-thumbs-up mx-1"
                        @click="gravaLike(index, 'like')"
                      ></i>
                    </el-tooltip>
                    <el-tooltip placement="bottom" :content="`Dislikes (${candidato.score_card[0].dislikes.pontos})`" effect="light">
                      <i
                        :class="retornaLikes(candidato.score_card[0].dislikes.pontos) ? 'icones-caracteristicas-dislike' : 'icones-caracteristicas-inativo'"
                        class="fas fa-thumbs-down mx-1"
                        @click="gravaLike(index, 'dislike')"
                      ></i>
                    </el-tooltip>
                    <el-tooltip placement="top" :content="`Marcado como estrela (${candidato.score_card[0].star.pontos})`" effect="light">
                      <i
                        :class="retornaLikes(candidato.score_card[0].star.pontos) ? 'icones-caracteristicas-star' : 'icones-caracteristicas-inativo'"
                        class="fas fa-star mx-1"
                        @click="gravaLike(index, 'star')"
                      ></i>
                    </el-tooltip>
                  </td>
                  <!-- Coluna das tags -->
                  <td class="p-2 align-middle" v-if="!showDetalhes">
                    <tags-input v-model="candidato.tags" placeholder="Tags" type="primary"></tags-input>
                  </td>
                  <!-- Coluna dos botões de Ação -->
                  <!-- <td class="p-2 align-middle" v-if="!showDetalhes">
                    <el-tooltip placement="top" content="Desqualificar" effect="light">
                      <base-button icon size="sm" type="secondary" class="btn-icon-only" @click="fechaDetalhes">
                        <i class="text-danger fas fa-ban"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Mover para proxima fase" effect="light">
                      <base-button icon size="sm" type="success" class="btn-icon-only" @click="fechaDetalhes">
                        <i class="fas fa-angle-double-right"></i>
                      </base-button>
                    </el-tooltip>
                  </td> -->
                </tr>
              </template>
              </table>
              </perfect-scrollbar>
            </div>
            <!-- Box Detalhe -->
            <div class="col-9" v-if="showDetalhes">
              <!-- Linha botão fechar Detalhes -->
              <div class="row mb-2">
                <div class="col text-right">
                  <!--
                    Incluir botão para convidar candidatos
                    -->
                  <!-- Botão de Entrevista de RH -->
                  <template v-if="verificaFase(indexCandidatoDetalhe) === 'entrevistaRH'">
                    <el-tooltip placement="top" content="Adicionar Registro de Entrevista" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-info btn-sm" @click="modalRegistroEntrevista('entrevistaRH')">
                        <span class="btn-inner--icon"><i class="fas fa-comments"></i></span>
                        <span class="btn-inner--text">Registro de Entrevista</span>
                      </button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Agendar Entrevista com RH" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-default btn-sm" @click="handleModalEntrevista('entrevistaRH')">
                        <span class="btn-inner--icon"><i class="far fa-calendar-plus"></i></span>
                        <span class="btn-inner--text">Agendar Entrevista</span>
                      </button>
                    </el-tooltip>
                  </template>
                  <!-- Botão de Entrevista Solicitante -->
                  <template v-if="verificaFase(indexCandidatoDetalhe) === 'entrevistaSolicitante'">
                    <el-tooltip placement="top" content="Adicionar Registro de Entrevista" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-info btn-sm" @click="modalRegistroEntrevista('entrevistaSolicitante')">
                        <span class="btn-inner--icon"><i class="fas fa-comments"></i></span>
                        <span class="btn-inner--text">Registro de Entrevista</span>
                      </button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Agendar Entrevista com o Solicitante da Vaga" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-default btn-sm" @click="handleModalEntrevista('entrevistaSolicitante')">
                        <span class="btn-inner--icon"><i class="far fa-calendar-plus"></i></span>
                        <span class="btn-inner--text">Agendar Entrevista</span>
                      </button>
                    </el-tooltip>
                  </template>
                  <!-- Botão de Entrevista -->
                  <template v-if="verificaFase(indexCandidatoDetalhe) === 'entrevista'">
                    <el-tooltip placement="top" content="Adicionar Registro de Entrevista" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-info btn-sm" @click="modalRegistroEntrevista('entrevistaOutro')">
                        <span class="btn-inner--icon"><i class="fas fa-comments"></i></span>
                        <span class="btn-inner--text">Registro de Entrevista</span>
                      </button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Agendar Entrevista" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-default btn-sm" @click="handleModalEntrevista('entrevistaOutro')">
                        <span class="btn-inner--icon"><i class="far fa-calendar-plus"></i></span>
                        <span class="btn-inner--text">Agendar Entrevista</span>
                      </button>
                    </el-tooltip>
                  </template>
                  <!-- Enviar Proposta de Emprego -->
                  <template v-if="verificaFase(indexCandidatoDetalhe) === 'negociacao'">
                    <el-tooltip placement="top" content="Enviar Proposta de Emprego" effect="light">
                      <base-button icon type="success" class="btn-icon-texto" @click="modal.negociacao = !modal.negociacao">
                        <span class="btn-inner--icon"><i class="fas fa-file-contract"></i></span>
                        <span class="btn-inner--text">Enviar Proposta</span>
                      </base-button>
                    </el-tooltip>
                  </template>
                  <!-- Botão de Comentário -->
                  <el-tooltip placement="top" content="Adicionar Comentário" effect="light">
                    <base-button icon type="primary" class="btn-icon-texto" @click="modal.novoComentario = true">
                      <span class="btn-inner--icon"><i class="fas fa-comment-dots"></i></span>
                      <span class="btn-inner--text" v-show="botoes.comentario">Novo Comentário</span>
                    </base-button>
                  </el-tooltip>
                  <template v-if="verificaFase(indexCandidatoDetalhe) === 'contratacao'">
                    <el-tooltip placement="top" content="Encerra o Processo de Seleção" effect="light">
                      <base-button icon type="success" class="btn-icon-texto">
                        <span class="btn-inner--icon"><i class="far fa-window-close"></i></span>
                        <span class="btn-inner--text">Encerrar</span>
                      </base-button>
                    </el-tooltip>
                  </template>
                  <template v-if="verificaFase(indexCandidatoDetalhe) !== 'contratacao'">
                    <!-- Botão de Indicação -->
                    <el-tooltip placement="top" content="Registrar um Indicaçao" effect="light">
                      <base-button outline icon type="primary" class="btn-icon-texto" @click="modal.indicacao = !modal.indicacao">
                        <span class="btn-inner--icon"><i class="fas fa-share-square"></i></span>
                      </base-button>
                    </el-tooltip>
                    <!-- Botão de Referência Profissional -->
                    <el-tooltip placement="top" content="Registrar uma Referência Profissional" effect="light">
                      <base-button outline icon type="default" class="btn-icon-texto" @click="modal.referencia = !modal.referencia">
                        <span class="btn-inner--icon"><i class="fas fa-address-card"></i></span>
                      </base-button>
                    </el-tooltip>
                    <!-- Botão de Avaliação das Competencias do Processo Seletivo -->
                    <!-- <el-tooltip placement="top" content="Registrar Avaliação das Competências" effect="light">
                      <base-button outline icon type="success" class="btn-icon-texto">
                        <span class="btn-inner--icon"><i class="fas fa-clipboard-check"></i></span>
                      </base-button>
                    </el-tooltip> -->
                    <el-tooltip placement="top" content="Enviar um Teste" effect="light">
                      <base-button outline icon type="primary" class="btn-icon-texto" @click="modal.enviarTeste = true">
                        <span class="btn-inner--icon"><i class="fas fa-chart-bar"></i></span>
                        <span class="btn-inner--text" v-if="verificaFase(indexCandidatoDetalhe) === 'enviaTestes'">Enviar Teste</span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Curtir" effect="light">
                      <base-button outline icon type="success" class="btn-icon-only" @click="gravaLike(indexCandidatoDetalhe, 'like')">
                        <span class="btn-inner--icon"><i class="fas fa-thumbs-up"></i></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Não Curti" effect="light">
                      <base-button outline icon type="danger" class="btn-icon-only" @click="gravaLike(indexCandidatoDetalhe, 'dislike')">
                        <span class="btn-inner--icon"><i class="fas fa-thumbs-down"></i></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Marcar com Estrela" effect="light">
                      <base-button outline icon type="attention" class="btn-icon-only" @click="gravaLike(indexCandidatoDetalhe, 'star')">
                        <span class="btn-inner--icon"><i class="fas fa-star"></i></span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Desqualificar" effect="light">
                      <base-button icon size="sm" type="secondary" class="btn-icon-texto">
                        <span class="btn-inner--icon"><i class="text-danger fas fa-ban"></i></span>
                        <span class="btn-inner--text" v-show="botoes.desqualificar">Desqualificar</span>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip placement="top" content="Mover para proxima fase" effect="light">
                      <button type="button" class="btn base-button btn-icon-texto btn-icon btn-fab btn-success btn-sm"
                        @click="moveProximaFase(indexCandidatoDetalhe)">
                        <span class="btn-inner--icon"><i class="fas fa-angle-double-right"></i></span>
                        <span class="btn-inner--text">Proxima Fase</span>
                      </button>
                    </el-tooltip>
                  </template>
                  <el-tooltip placement="top" content="Fechar Detalhes" effect="light">
                    <base-button outline icon size="sm" type="secondary" round class="btn-icon-only" @click="fechaDetalhes">
                      <i class="text-default fas fa-times"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </div>
              <!-- Card de Visualização de Detalhes do Candidato -->
              <card class="shadow">
                <template slot="header">
                  <div class="row justify-content-start align-items-start mt-3">
                    <div class="col-2">
                      <img class="img-fluid rounded-circle" :src="candidatoDetalhe.curriculo.hl_foto" :alt="candidatoDetalhe.curriculo.nm_nome" />
                    </div>
                    <div class="col-8 ">
                      <div class="row">
                        <div class="col-auto">
                          <template v-if="candidatoDetalhe.indicacoes.length > 0">
                            <el-tooltip placement="top" content="Candidato com Indicação" effect="dark"><i class="text-success fas fa-share"></i></el-tooltip>
                          </template> <strong>{{candidatoDetalhe.curriculo.nm_nome}}</strong>
                        </div>
                        <div class="col-3">
                          <el-tooltip placement="top" content="Enviar um e-mail" effect="dark">
                            <a :href="`mailto:${candidatoDetalhe.curriculo.hl_email}`" target="_blank"><i class="icone-email fas fa-envelope mx-2"></i></a>
                          </el-tooltip>
                          <!-- <i class="icone-sms fas fa-sms mx-2"></i> -->
                          <el-tooltip placement="top" content="Telefonar" effect="dark">
                            <a :href="`tel:+55${candidatoDetalhe.curriculo.nu_celular}`" target="_blank"><i class="icone-sms fas fa-phone-alt"></i></a>
                          </el-tooltip>
                          <el-tooltip placement="top" content="Chamar no Whatsapp" effect="dark">
                            <a :href="`https://wa.me/55${candidatoDetalhe.curriculo.nu_celular}`" target="_blank"><i class="icone-whatsapp fab fa-whatsapp mx-2"></i></a>
                          </el-tooltip>
                        </div>
                      </div>
                      <span class="text-muted">{{candidatoDetalhe.curriculo.json_objetivo.nm_cargo}}</span><br>
                      {{retornaHabitante(candidatoDetalhe.curriculo.nu_nacionalidade, candidatoDetalhe.curriculo.fl_sexo)}} -
                      {{retornaEstadoCivil(candidatoDetalhe.curriculo.fl_estadocivil, candidatoDetalhe.curriculo.fl_sexo)}} -
                      <!-- {{candidatoDetalhe.analise_ia.resultadosAi.tempoExperiencia.numIdade}} anos - -->
                      {{calcularIdade(candidatoDetalhe.curriculo.dt_nasc)}} anos -
                      <span class="text-muted">{{candidatoDetalhe.curriculo.dt_nasc | formatDate}}</span><br>

                      <small>
                        Reside em {{candidatoDetalhe.curriculo.endereco.nm_bairro}} -
                        {{candidatoDetalhe.curriculo.endereco.nm_cidade}}/{{candidatoDetalhe.curriculo.endereco.nm_estado}}<br>
                        <template v-if="candidatoDetalhe.analise_ia.resultadosAi.localizacao.tipo_localizacao === 'google'">
                        Aproximadamente {{candidatoDetalhe.analise_ia.resultadosAi.localizacao.distancia}} ({{candidatoDetalhe.analise_ia.resultadosAi.localizacao.duracao_transito}}) do local da vaga
                        </template>
                      </small><br>
                      <span>
                        <i
                          class="icones-perfil-ativo fas fa-thumbs-up mx-1"
                        ></i> <span class="icones-perfil-ativo mr-3">{{candidatoDetalhe.score_card[0].likes.pontos}}</span>
                        <i
                          class="icones-perfil-dislike fas fa-thumbs-down mx-1"
                        ></i> <span class="icones-perfil-dislike mr-3">{{candidatoDetalhe.score_card[0].dislikes.pontos}}</span>
                        <i
                          class="icones-perfil-star fas fa-star mx-1"
                        ></i> <span class="icones-perfil-star">{{candidatoDetalhe.score_card[0].star.pontos}}</span>
                      </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-3">
                    <div class="col-12">
                      <base-button outline size="sm" type="primary" @click="mudaAba('timeline')">Timeline</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('curriculo')">Currículo</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('contatos')">Contatos</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('matchjobAi')">MatchJob AI</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('resultadoTestes')">Resultado de Testes</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('indicacoes')">Indicações</base-button>
                      <base-button outline size="sm" type="primary" @click="mudaAba('referencias')">Referências</base-button>
                    </div>
                  </div>
                </template>
                <template class="card-body">
                  <div v-loading="loading.detalhes">
                    <!-- Timeline do Candidato -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_timeline">
                      <div class="col-12">
                        <TimelineCandidato
                          :propTimeline="timelineCandidato"
                          :propUsuarios="usuarios"
                          :propResultadoTestes="candidatoDetalhe.resultado_testes"
                        />
                      </div>
                    </div>
                    <!-- Curriculo -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_curriculo">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Currículo do Candidato</h3>
                          </div>
                        </div>
                        <ObjetivosCv
                          :propObjetivo="candidatoDetalhe.curriculo.json_objetivo"
                        />
                        <ResumoInfoExtra
                          propTitulo="Resumo Profissional"
                          :propTexto="candidatoDetalhe.curriculo.tx_resumo"
                        />
                        <ExperienciasCv
                          :propExperiencias="candidatoDetalhe.curriculo.json_experiencias"
                        />
                        <FormacoesCV
                          :propFormacoes="candidatoDetalhe.curriculo.json_formacoes"
                        />
                        <CertificacoesCV
                          :propCertificacoes="candidatoDetalhe.curriculo.json_certificacoes"
                        />
                        <IdiomasCV
                          :propIdiomas="candidatoDetalhe.curriculo.json_idiomas"
                        />
                        <CompetenciasCv
                          :propCompetencias="candidatoDetalhe.curriculo.json_competencias"
                        />
                        <ResumoInfoExtra
                          propTitulo="Informações Extras"
                          :propTexto="candidatoDetalhe.curriculo.tx_info_extra"
                        />
                      </div>
                    </div>
                    <!-- Contatos -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_contatos">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Contatos do Candidato</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <p>
                              Celular: {{candidatoDetalhe.curriculo.nu_celular}} <a :href="`tel:+55${candidatoDetalhe.curriculo.nu_celular}`" target="_blank"><i class="fas fa-phone-alt"></i></a><br>
                              Telefone: {{candidatoDetalhe.curriculo.nu_telefone === '' ? 'Não informado' : candidatoDetalhe.curriculo.nu_telefone}} <a :href="`tel:+55${candidatoDetalhe.curriculo.nu_telefone}`" target="_blank"><i class="fas fa-phone-alt"></i></a><br>
                              E-mail: <a :href="`mailto:${candidatoDetalhe.curriculo.hl_email}`" target="_blank">{{candidatoDetalhe.curriculo.hl_email}}</a> <a :href="`mailto:${candidatoDetalhe.curriculo.hl_email}`" target="_blank"><i class="fas fa-envelope mx-2"></i></a>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <h3>Endereço do Candidato</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <p>{{candidatoDetalhe.curriculo.endereco.nm_endereco}}, {{candidatoDetalhe.curriculo.endereco.nu_numero}} - {{candidatoDetalhe.curriculo.endereco.nm_bairro}} - {{candidatoDetalhe.curriculo.endereco.nm_cidade}}/{{candidatoDetalhe.curriculo.endereco.nm_estado}}</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <h3>Midias Sociais</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            LinkedIn: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_linkedin === '' || candidatoDetalhe.curriculo.midias_sociais.hl_linkedin === null">Não informado</template>
                              <template v-else><a :href="candidatoDetalhe.curriculo.midias_sociais.hl_linkedin" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_linkedin}}</a></template><br>
                            Instagram: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_instagram === '' || candidatoDetalhe.curriculo.midias_sociais.hl_instagram === null">Não informado</template>
                              <template v-else><a :href="candidatoDetalhe.curriculo.midias_sociais.hl_instagram" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_instagram}}</a></template><br>
                            Facebook: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_facebook === '' || candidatoDetalhe.curriculo.midias_sociais.hl_facebook === null">Não informado</template>
                              <template v-else><a :href="candidatoDetalhe.curriculo.midias_sociais.hl_facebook" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_facebook}}</a></template><br>
                            Site pessoal: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_site_pessoal === '' || candidatoDetalhe.curriculo.midias_sociais.hl_site_pessoal === null">Não informado</template>
                              <template v-else><a :href="candidatoDetalhe.curriculo.midias_sociais.hl_site_pessoal" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_site_pessoal}}</a></template><br>
                            Youtube: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_google === '' || candidatoDetalhe.curriculo.midias_sociais.hl_google === null">Não informado</template>
                              <template v-else><a :href="candidatoDetalhe.curriculo.midias_sociais.hl_google" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_google}}</a></template><br>
                            Twitter: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_twitter === '' || candidatoDetalhe.curriculo.midias_sociais.hl_twitter === null">Não informado</template>
                              <template v-else><a :href="`https://twitter.com/${candidatoDetalhe.curriculo.midias_sociais.hl_twitter}`" target="_blank">@{{candidatoDetalhe.curriculo.midias_sociais.hl_twitter}}</a></template><br>
                            Skype: <template v-if="candidatoDetalhe.curriculo.midias_sociais.hl_skype === '' || candidatoDetalhe.curriculo.midias_sociais.hl_skype === null">Não informado</template>
                              <template v-else>
                              <a :href="`skype:[${candidatoDetalhe.curriculo.midias_sociais.hl_skype}]?chat`" target="_blank">{{candidatoDetalhe.curriculo.midias_sociais.hl_skype}}</a>
                              </template><br>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Linha da MatchJobAI -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_matchjobAi">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Resultados da MatchJob AI</h3>
                          </div>
                        </div>
                        <PretensaoSalarialComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.pretensao"
                          :propPretensao="candidatoDetalhe.analise_ia.resultadosAi.pretensaoSalarial"
                        />
                        <CompetenciasComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.competencias"
                          :propCompetencias="candidatoDetalhe.analise_ia.resultadosAi.competencias"
                          :propCompetenciasVaga="vaga.json_vaga.json_competencias"
                        />
                        <IdiomasComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.idiomas"
                          :propIdiomas="candidatoDetalhe.analise_ia.resultadosAi.idiomas"
                          :propVagaIdiomas="vaga.json_vaga.json_idiomas"
                        />
                        <FormacoesComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.formacao"
                          :propFormacoes="candidatoDetalhe.analise_ia.resultadosAi.formacao"
                          :propFormacoesVaga="vaga.json_vaga.json_formacao"
                        />
                        <CertificacoesComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.certificacoes"
                          :propCertificacoes="candidatoDetalhe.analise_ia.resultadosAi.certificacao"
                          :propCertificacoesVaga="vaga.json_vaga.json_certificacao"
                        />
                        <LocalizacaoComp
                          :propLocalizacao="candidatoDetalhe.analise_ia.resultadosAi.localizacao"
                        />
                        <TempoExperienciaComp
                          v-if="$store.state.empresa.plano.json_detalhes.matchAi.tempoExperiencia"
                          :propTempoExperiencia="candidatoDetalhe.analise_ia.resultadosAi.tempoExperiencia"
                          :propVaga="vaga.json_vaga"
                        />
                        <PalavrasChave
                          :propPalavrasChave="candidatoDetalhe.analise_ia.resultadosAi.palavrasChave"
                        />
                      </div>
                    </div>
                    <!-- Linha Resultado de Testes -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_resultadoTestes">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Resultados dos Testes Enviados para o Candidato</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div v-for="(linha, index) in candidatoDetalhe.resultado_testes" :key="index">
                            <CompResultadoTeste
                              :propItem="linha"
                            />
                            </div>
                            <!-- <pre>{{candidatoDetalhe.resultado_testes}}</pre> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Linha de Indicações -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_indicacoes">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Indicações do Candidato</h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <template v-if="candidatoDetalhe.indicacoes.length > 0">
                            <ul v-for="(indicacao, index) in candidatoDetalhe.indicacoes" :key="index">
                              <li>Indicado por: {{indicacao.nome}}</li>
                              <li>E-mail: {{indicacao.email}}</li>
                              <li>Cargo: {{indicacao.cargo}}</li>
                              <li>Observações: {{indicacao.obs}}</li>
                            </ul>
                            </template>
                            <template v-else>
                              Este candidato não possui indicações.
                            </template>
                            <!-- <pre>{{candidatoDetalhe.indicacoes}}</pre> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Linha de Referencias Profissionais -->
                    <div class="row justify-content-start align-items-start mt-3" v-if="fl_referencias">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <h3>Referências Profissionais do Candidato</h3>
                          </div>
                        </div>
                        <div class="row" v-for="(ref, index) in candidatoDetalhe.referencias" :key="index">
                          <div class="col-12">
                            Nome: <strong>{{ref.nome}}</strong> ({{ref.cargo}})<br>
                            E-mail: <a :href="`mailto:${ref.email}`">{{ref.email}}</a><br>
                            Telefone: {{ref.telefone}} <a :href="`tel:+55${ref.telefone}`" target="_blank"><i class="text-info fas fa-phone-alt"></i></a> <a :href="`https://wa.me/55${ref.telefone}`" target="_blank"><i class="text-success fab fa-whatsapp mx-2"></i></a><br>
                            Cargo da Referência: {{ref.refExperiencia.nm_cargo}} na empresa: {{ref.refExperiencia.nm_empresa}}<br>
                            <template v-if="ref.obs !== ''">Observações: {{ref.obs}}<br></template>
                            <span v-if="ref.respostaReferencia.length > 0">Resposta: <br>{{ref.respostaReferencia[0]}}</span>
                            <base-button size="sm" :type="flRetornoReferencia ? 'secondary' : 'primary'" class="mt-2" @click="flRetornoReferencia = !flRetornoReferencia" v-if="ref.respostaReferencia.length < 1">Registar Retorno</base-button><br><br>
                            <span v-if="flRetornoReferencia">
                              <label for="indicadoPorCargo">Retorno da Referência:</label>
                              <textarea class="form-control" id="indicadoPorCargo" rows="3" v-model="retornoReferencia"></textarea>
                              <base-button type="primary" class="mt-2" @click="addRetornoReferenciaProfissional(indexCandidatoDetalhe, index)">Gravar</base-button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- <pre>{{candidatoDetalhe.referencias}}</pre> -->
                    </div>
                    <!-- <pre>{{$store.state.empresa.plano.json_detalhes.matchAi}}</pre> -->
                    <!-- <div class="row">
                      <div class="col-6">
                        <pre>{{Object.keys(candidatoDetalhe.analise_ia)}}</pre>
                        resultadosAi.pontuacaoTotal -> <pre>{{candidatoDetalhe.analise_ia.pontuacaoTotal}}</pre>
                        <pre>{{candidatoDetalhe.analise_ia.resultadosAi.palavrasChave.pontos}}</pre>
                      </div>
                      <div class="col-6">
                        entities.length -> <pre>{{vaga.json_analise_ia.entities.length}}</pre>
                        keyphrases.length -> <pre>{{vaga.json_analise_ia.keyphrases.length}}</pre>
                        <pre>{{vaga.json_analise_ia}}</pre>
                      </div>
                    </div> -->
                  </div>
                </template>
              </card>
            </div>
          </div>
        </template>
      </card>
      <!-- Linha dos Detalhes Adicionais -->
      <div class="row">
        <div class="col-12">
          <collapse>
            <collapse-item name="1" id="1">
              <h2 slot="title">Visualização da Vaga</h2>
                <VisualizarVagaComp
                  :propVaga="vaga"
                />
            </collapse-item>
            <collapse-item name="4" id="4">
              <h2 slot="title">Timeline da Vaga</h2>
              <TimelineVaga
                :propTimeline="timelineVaga"
              />
              <!-- <pre>{{timelineVaga}}</pre> -->
            </collapse-item>
            <!-- Raw Data -->
            <!-- <collapse-item name="30" id="30" v-if="vaga.json_fases">
              <h2 slot="title">Raw Data</h2>
              <div class="row">
                <div class="col-4">
                  Vaga -> <pre>{{ Object.keys(vaga) }}</pre>
                  Vaga -> <pre>{{ vaga }}</pre>
                </div>
                <div class="col-4">
                  <template v-if="vaga.json_candidaturas.length > 0">
                    Vaga.json_candidaturas -> <pre>{{ Object.keys(vaga.json_candidaturas[0]) }}</pre>
                    candidato.score_card[0].dislikes.pontos -> {{vaga.json_candidaturas[0].score_card[0].dislikes.pontos}}
                    Vaga.json_candidaturas.score_card -> <pre>{{ vaga.json_candidaturas[0].score_card }}</pre>

                    Vaga.json_candidaturas.curriculo -> <pre>{{ Object.keys(vaga.json_candidaturas[0].curriculo) }}</pre>
                    Vaga.json_candidaturas.resultado_testes -> <pre>{{ vaga.json_candidaturas[0].resultado_testes }}</pre>

                  </template>
                  json_fases -> <pre>{{ vaga.json_fases }}</pre>
                </div>
                <div class="col-4">
                  candidatosSelecionados -> <pre>{{candidatosSelecionados}}</pre>
                  faseInicial (computed) -> <pre>{{faseInicial}}</pre>
                  <template v-for="(candidato, index) in vaga.json_candidaturas">
                    <div :key="index">
                      {{candidato.curriculo.id_candidato}} -> {{candidato.curriculo.hl_email}}<br>
                      Fase -> {{typeof candidato.fase_atual}} -> {{candidato.fase_atual}}<br>
                    </div>
                  </template>

                  <strong>testes -></strong> <pre>{{testes}}</pre>

                  vaga.json_vaga.json_nivelexperiencia -> <pre>{{vaga.json_vaga.json_nivelexperiencia}}</pre>
                  vaga.json_vaga -> <pre>{{Object.keys(vaga.json_vaga)}}</pre>
                  registroEntrevista -> <pre>{{registroEntrevista}}</pre>
                </div>
              </div>
            </collapse-item> -->
          </collapse>
        </div>
      </div>
      <!-- Modal de Novo Comentário -->
      <modal :show="modal.novoComentario" :showClose="false" size="lg" v-loading.fullsccreen.lock="loading.full">
        <h6 slot="header" class="modal-title">Adicionar Novo Comentário</h6>
        <base-input label="Comentário">
          <textarea class="form-control" id="novoComentario" rows="3" v-model="addComentario"></textarea>
        </base-input>
        <div slot="footer">
          <base-button type="secondary" class="ml-3" @click="modal.novoComentario = false">Cancelar</base-button>
          <base-button type="primary" class="mr-3 ml-auto" @click="addNovoComentario(indexCandidatoDetalhe)">Adicionar</base-button>
        </div>
      </modal>
      <!-- Modal de Registro de Indicação -->
      <RegistroIndicacao
        :propShow="modal.indicacao"
        :propIndex="indexCandidatoDetalhe"
        :key="`indicacao${candidatoDetalhe.id_candidato}`"
        :closeModal="closeModalIndicacao"
        @resIndicacao="novaIndicacao = $event"
        :concluiRegistro="addNovaIndicacao"
      />
      <!-- Modal de Registro de Referência Profissional -->
      <RegistroReferenciaProfissional
        :propShow="modal.referencia"
        :propIndex="indexCandidatoDetalhe"
        :propDetalhes="candidatoDetalhe"
        :key="`referencia${candidatoDetalhe.id_candidato}`"
        :closeModal="closeModalReferencia"
        @resReferencia="novaReferencia = $event"
        :concluiRegistro="addNovaReferenciaProfissional"
      />
      <!-- Modal de envio de testes -->
      <EnviarTeste
        :propShow="modal.enviarTeste"
        :propIndex="indexCandidatoDetalhe"
        :key="`enviarTeste${candidatoDetalhe.id_candidato}`"
        :closeModal="closeModalEnviarTeste"
        @resReferencia="enviaTeste = $event"
        :concluiRegistro="handleAplicarTesteManual"
      />
      <!-- Modal de envio de Invite de Entrevista -->
      <EnviarConviteEntrevista
        :propTipoEntrevista="entrevista.tipo"
        :propShow="modal.entrevista"
        :propCandidato="candidatoDetalhe.curriculo"
        :propVaga="vaga"
        :key="`entrevista${candidatoDetalhe.id_candidato}`"
        :closeModal="closeModalEntrevista"
        @resConviteEntrevista="enviarEntrevista = $event"
        :concluiRegistro="handleEnviarEntrevista"
      />
      <!-- Modal de Registro de Entrevista -->
      <modal :show="modal.registroEntrevista" :showClose="false" size="lg" v-loading.fullsccreen.lock="loading.full">
        <h6 slot="header" class="modal-title">Registro de Entrevista</h6>
        <RegistroEntrevista
          v-if="modal.registroEntrevista"
          @resRegistroEntrevista="registroEntrevista = $event"
          :closeModal="closeModalRegistroEntrevista"
          :propEntrevistas="candidatoDetalhe.timeline"
          :propTipoEntrevista="registroEntrevista.tipo_entrevista"
          :propCompetencias="vaga.json_competencias"
          :concluiRegistro="handleRegistroEntrevista"
          :key="`entrevista${candidatoDetalhe.id_candidato}`"
        />
      </modal>
      <!-- Modal de Proposta de Emprego -->
      <CompNegociacao
        :propShow="modal.negociacao"
        :propCandidato="candidatoDetalhe.curriculo"
        :propVaga="vaga"
        :key="candidatoDetalhe.id_candidato"
        :closeModal="closeModalNegociacao"
        @resNegociacao="novaProposta = $event"
        :concluiRegistro="handleEnviarNegociacao"
      />
      <!-- <modal :show="modal.negociacao" :showClose="false" size="lg" v-loading.fullsccreen.lock="loading.full">
        <h6 slot="header" class="modal-title">Enviar Proposta de Emprego</h6>
      </modal> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { Loading, Select, Option, Autocomplete } from 'element-ui';
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { addMinutes, format } from 'date-fns';
import moment from "moment";
import TagsInput from '@/components/Inputs/TagsInput';
import paises from '../../data/paises';
import swal from 'sweetalert2';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

import ListaCandidatos from '../../components/VisualizacaoVaga/ListaCandidatos.vue';
import TimelineCandidato from '../../components/VisualizacaoVaga/TimelineCandidato.vue';

import ObjetivosCv from '../../components/VisualizacaoVaga/VisualizacaoCV/ObjetivosCv.vue';
import ResumoInfoExtra from '../../components/VisualizacaoVaga/VisualizacaoCV/ResumoInfoExtra.vue';
import ExperienciasCv from '../../components/VisualizacaoVaga/VisualizacaoCV/ExperienciasCv.vue';
import FormacoesCV from '../../components/VisualizacaoVaga/VisualizacaoCV/FormacoesCV.vue';
import CertificacoesCV from '../../components/VisualizacaoVaga/VisualizacaoCV/CertificacoesCV.vue';
import IdiomasCV from '../../components/VisualizacaoVaga/VisualizacaoCV/IdiomasCV.vue';
import CompetenciasCv from '../../components/VisualizacaoVaga/VisualizacaoCV/CompetenciasCv.vue';

import PretensaoSalarialComp from '../../components/VisualizacaoVaga/MatchJobAi/PretensaoSalarialComp.vue';
import IdiomasComp from '../../components/VisualizacaoVaga/MatchJobAi/IdiomasComp.vue';
import CompetenciasComp from '../../components/VisualizacaoVaga/MatchJobAi/CompetenciasComp.vue';
import FormacoesComp from '../../components/VisualizacaoVaga/MatchJobAi/FormacoesComp.vue';
import CertificacoesComp from '../../components/VisualizacaoVaga/MatchJobAi/CertificacoesComp.vue';
import LocalizacaoComp from '../../components/VisualizacaoVaga/MatchJobAi/LocalizacaoComp.vue';
import TempoExperienciaComp from '../../components/VisualizacaoVaga/MatchJobAi/TempoExperienciaComp.vue';
import PalavrasChave from '../../components/VisualizacaoVaga/MatchJobAi/PalavrasChave.vue';
import CompResultadoTeste from '../../components/Testes/CompResultadoTeste.vue';

import EnviarTeste from '../../components/VisualizacaoVaga/EnviarTeste.vue';
import RegistroIndicacao from '../../components/VisualizacaoVaga/RegistroIndicacao.vue';
import RegistroReferenciaProfissional from '../../components/VisualizacaoVaga/RegistroReferenciaProfissional.vue';
import EnviarConviteEntrevista from '../../components/Entrevistas/EnviarConviteEntrevista.vue';
import RegistroEntrevista from '../../components/Entrevistas/RegistroEntrevista.vue';
import CompNegociacao from '../../components/Negociacao/CompNegociacao.vue';

import VisualizarVagaComp from "../../components/VisualizacaoVaga/VisualizarVagaComp.vue";
import TimelineVaga from "../../components/Timeline/TimelineVaga.vue";

Vue.use(Loading.directive);
//Vue.forceUpdate();

export default {
  name: "VisualizacaoVaga",
  mixins: [defaultMatchjob],
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    PerfectScrollbar,
    TagsInput,
    Collapse,
    CollapseItem,
    //ListaCandidatos,
    TimelineCandidato,

    ObjetivosCv,
    ResumoInfoExtra,
    ExperienciasCv,
    FormacoesCV,
    CertificacoesCV,
    IdiomasCV,
    CompetenciasCv,

    PretensaoSalarialComp,
    IdiomasComp,
    CompetenciasComp,
    FormacoesComp,
    CertificacoesComp,
    LocalizacaoComp,
    TempoExperienciaComp,
    PalavrasChave,
    CompResultadoTeste,
    RegistroIndicacao,
    RegistroReferenciaProfissional,
    EnviarTeste,

    EnviarConviteEntrevista,
    RegistroEntrevista,
    CompNegociacao,

    VisualizarVagaComp,
    TimelineVaga
  },
  data() {
    return {
      loading: {
        full: false,
        body: false,
        detalhes: false
      },
      botoes: {
        desqualificar: false,
        comentario: false,
        //proximaFase: false
      },
      modal: {
        novoComentario: false,
        indicacao: false,
        enviarTeste: false,
        entrevista: false,
        registroEntrevista: false,
        referencia: false,
        negociacao: false
      },
      entrevista: {
        tipo: ""
      },
      enviarEntrevista: {},
      vaga: {},
      showDetalhes: false,
      faseAtual: "2",
      origemCandidatura: [
        { id: "1", value: "Matchjob" },
        { id: "matchjob", value: "Matchjob" },
        { id: "google_jobs_apply", value: "Google Jobs" }
      ],
      fl_timeline: true,
      fl_curriculo: false,
      fl_contatos: false,
      fl_matchjobAi: false,
      fl_resultadoTestes: false,
      fl_visualizacaoVaga: false,
      fl_indicacoes: false,
      fl_referencias: false,
      candidatoDetalhe: {},
      indexCandidatoDetalhe: null,
      timelineCandidato: [],
      candidatosSelecionados: [],
      estadocivil: [
        { id: "1", text: "Solteiro", textF: "Solteira" },
        { id: "2", text: "Casado", textF: "Casada" },
        { id: "3", text: "Divorciado", textF: "Divorciada" },
        { id: "4", text: "União Estável", textF: "União Estável" },
        { id: "5", text: "Viúvo", textF: "Viúva" }
      ],
      //usuarios: [],
      //testes: [],
      //enderecos: [],
      timelineVaga: [],
      addComentario: "",
      faseAtiva: null,
      candidatosFase: [],
      novaIndicacao: {},
      novaReferencia: {},
      novaProposta: {},
      flRetornoReferencia: false,
      retornoReferencia: "",
      enviaTeste: {},
      fl_mostraMensagem: false,
      registroEntrevista: [],
      flagEmitRegistroEntrevista: false,
      tipoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Híbrido" },
        { value: "3", text: "Home Office" }
      ],
    }
  },
  computed: {
    testes() {
      return this.$store.state.testes.testes;
    },
    testesPerfil() {
      return this.$store.state.testes.testesPerfil;
    },
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    usuariosRh() {
      return this.$store.state.usuarios.usuarios.filter(r => r.fl_tipo_acesso > 0 && r.fl_tipo_acesso < 5);
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    vagaState() {
      return this.$store.state.empresa.vagas.find(v => v.hl_link === this.$route.params.url)
    },
    faseInicial() {
      if(this.vagaState.json_candidaturas.length > 0) {
        var fase = this.vagaState.json_candidaturas.reduce((a, b) => {
          return a.fase_atual > b.fase_atual ? a : b;
        });
        return fase.fase_atual;
      } else {
        return 1;
      }
    },
    candidatosFaseState: {
      get() {
        var listaCandidatos = this.vagaState.json_candidaturas.filter(c => c.fase_atual === this.faseAtiva);
        return listaCandidatos.sort((a, b) => {
          return b.analise_ia.pontuacaoTotal - a.analise_ia.pontuacaoTotal
        });
      },
      set(faseId) {
        this.faseAtiva = faseId
      }
    },
  },
  methods: {
    async atualizaVaga() {
      this.loading.body = true;
      var dados = {
        id_processo_vaga: this.vaga.id_processo_vaga,
        json_candidaturas: this.vaga.json_candidaturas
      }
      await this.$http
      .post('vagas/atualizaprocesso', dados)
      .then(() => {
        this.notificacao('Vaga atualizada!', 'success');
        this.loading.body = false;
        this.recarregarCandidatos();
        this.$forceUpdate();
        this.loading.body = false;
      }).catch(err => {
        console.log('Erro na API -> ', err);
        this.loading.body = false;
        this.notificacao('Ops! Houve uma falha não foi gravar as alterações!', 'warning');
        this.$forceUpdate();
        this.loading.body = false;
      });
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoTrabalhoBadge(value) {
      if (value === '1'){
        return "primary";
      } else if(value === '2'){
        return "info";
      } else {
        return "success";
      }
    },
    verificaStatusAprovacao() {
      var count = 0;
      if (this.vaga.json_fases[0].requerAprovacao) {
        count += 1;
        if (this.vaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado) {
          count -= 1;
        }
      }
      if (this.vaga.json_fases[0].solicitanteAprova) {
        count += 1;
        if (this.vaga.json_fases[0].aprovacoes.solicitante.aprovado) {
          count -= 1;
        }
      }
      if (this.vaga.json_fases[0].flOutrosAprovadores) {
        let num = this.vaga.json_fases[0].aprovacoes.outros.length
        count += num;
        for (var i = 0; i < num; i++) {
          if (this.vaga.json_fases[0].aprovacoes.outros[i].aprovado) {
            count -= 1;
          }
        }
      }
      //console.log('contador -> ', count)
      if (count === 0) {
        return true;
      } else {
        return false;
      }
    },
    retornaOrigemCandidatura(value) {
      var origem = this.origemCandidatura.find(i => i.id === value);
      if(origem !== undefined){
        return origem.value;
      } else {
        return value
      }
    },
    mostraDetalhes(indexCandidato) {
      this.loading.body = true;
      this.showDetalhes = false;
      this.candidatoDetalhe = {};
      setTimeout(() => {
        this.showDetalhes = true;
        this.indexCandidatoDetalhe = indexCandidato;
        this.candidatoDetalhe = this.candidatosFase[indexCandidato];
        this.timelineCandidato = this.candidatoDetalhe.timeline;
        //this.timelineCandidato = structuredClone(this.candidatoDetalhe.timeline).reverse();
        this.loading.body = false;
      }, 500);
    },
    calcularIdade(dataNasc){
      var today = new Date();
      var birthDate = new Date(dataNasc);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
      {
          age--;
      }
      return age;
    },
    verificaProximidadeResidenciaVaga(analiseLocalizacao, tipoLocalizacao) {
      //console.log('analiseLocalizacao -> ', analiseLocalizacao);
      if (tipoLocalizacao === 'ce') {
        if(analiseLocalizacao > 10) {
          return true;
        } else {
          return false;
        }
      } else if(tipoLocalizacao === 'bce') {
        if(analiseLocalizacao > 20) {
          return true;
        } else {
          return false;
        }
      } else if(tipoLocalizacao === 'google') {
        if (analiseLocalizacao >= 30) {
          return true;
        } else {
          return false;
        }
      }
    },
    retornaHabitante(id, sexo){
      var habitante = paises.find(i => i.id_nacionalidade === id);
      if (sexo === '1') {
        return habitante.nm_habitante;
      } else if(sexo === '2') {
        return habitante.nm_habitante_F;
      } else {
        return habitante.nm_habitante;
      }
    },
    retornaEstadoCivil(id, sexo) {
      var estadoCivil = this.estadocivil.find(i => i.id === id);
      if (sexo === '1') {
        return estadoCivil.text;
      } else if (sexo === '2') {
        return estadoCivil.textF;
      } else {
        return estadoCivil.text;
      }
    },
    retornaLikes(num) {
      if (num > 0) {
        return true;
      } else {
        return false;
      }
    },
    gravaLike(index, tipo) {
      var timeline = {
        tipo_registro: "score_action",
        score_action_tipo: "",
        text: "",
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario
      }
      if(tipo === 'like'){
        var indexLike = this.candidatosFase[index].score_card[0].likes.usuarios.findIndex(i => i.id_empresa_user === this.id.empresa_user);
        //console.log('indexLike -> ', indexLike)
        if (indexLike < 0) {
          timeline.text = "Curtiu esse candidato!"
          timeline.score_action_tipo = "like"
          this.candidatosFase[index].timeline.unshift(timeline);
          this.candidatosFase[index].score_card[0].likes.pontos += 1;
          var usuarioLike = { id_empresa_user: this.id.empresa_user }
          this.candidatosFase[index].score_card[0].likes.usuarios.push(usuarioLike)
          this.notificacaoIcone('Candidato curtido!', 'success', 'fas fa-thumbs-up');
        } else {
          var tmCandidato = this.candidatosFase[index].timeline.findIndex(t => {
            if (t.score_action_tipo === 'like' && t.id_empresa_user === this.id.empresa_user) {
              return t
            }
          })
          //console.log('tmCandidato -> ', tmCandidato)
          this.candidatosFase[index].timeline.splice(tmCandidato, 1);
          this.candidatosFase[index].score_card[0].likes.pontos -= 1;
          this.candidatosFase[index].score_card[0].likes.usuarios.splice(indexLike, 1);
          this.notificacaoIcone('Curtida cancelada!', 'warning', 'fas fa-thumbs-up');
        }
      } else if(tipo === 'dislike') {
        var indexLike = this.candidatosFase[index].score_card[0].dislikes.usuarios.findIndex(i => i.id_empresa_user === this.id.empresa_user);
        if (indexLike < 0) {
          timeline.text = "Não curtiu esse candidato!!"
          timeline.score_action_tipo = "dislike"
          this.candidatosFase[index].timeline.unshift(timeline);
          this.candidatosFase[index].score_card[0].dislikes.pontos += 1;
          var usuarioLike = { id_empresa_user: this.id.empresa_user }
          this.candidatosFase[index].score_card[0].dislikes.usuarios.push(usuarioLike)
          this.notificacaoIcone('Candidato não curtido!', 'danger', 'fas fa-thumbs-down');
        } else {
          var tmCandidato = this.candidatosFase[index].timeline.findIndex(t => {
            if (t.score_action_tipo === 'dislike' && t.id_empresa_user === this.id.empresa_user) {
              return t
            }
          })
          this.candidatosFase[index].timeline.splice(tmCandidato, 1);
          this.candidatosFase[index].score_card[0].dislikes.pontos -= 1;
          this.candidatosFase[index].score_card[0].dislikes.usuarios.splice(indexLike, 1);
          this.notificacaoIcone('Não curtida cancelada!', 'warning', 'fas fa-thumbs-down');
        }
      } else if(tipo === 'star') {
        var indexLike = this.candidatosFase[index].score_card[0].star.usuarios.findIndex(i => i.id_empresa_user === this.id.empresa_user);
        if (indexLike < 0) {
          timeline.text = "Marcou com estrela esse candidato!"
          timeline.score_action_tipo = "star"
          this.candidatosFase[index].timeline.unshift(timeline);
          this.candidatosFase[index].score_card[0].star.pontos += 1;
          var usuarioLike = { id_empresa_user: this.id.empresa_user }
          this.candidatosFase[index].score_card[0].star.usuarios.push(usuarioLike)
          this.notificacaoIcone('Candidato marcado com Estrela!', 'success', 'fas fa-star');
        } else {
          var tmCandidato = this.candidatosFase[index].timeline.findIndex(t => {
            if (t.score_action_tipo === 'star' && t.id_empresa_user === this.id.empresa_user) {
              return t
            }
          })
          this.candidatosFase[index].timeline.splice(tmCandidato, 1);
          this.candidatosFase[index].score_card[0].star.pontos -= 1;
          this.candidatosFase[index].score_card[0].star.usuarios.splice(indexLike, 1);
          this.notificacaoIcone('Marcada com estrela cancelada!', 'warning', 'fas fa-star');
        }
      }
    },
    verificaCandidatosFase(id) {
      var existe = this.vaga.json_candidaturas.findIndex(e => e.fase_atual.toString() === id.toString());
      if (existe > -1) {
        if (this.faseAtiva === id) {
          return 'box-fase-ativa';
        } else {
          return 'box-fase-candidato';
        }
      } else {
        return 'box-fase';
      }
    },
    fechaDetalhes() {
      this.loading.body = true;
      setTimeout(() => {
        this.showDetalhes = false;
        this.candidatoDetalhe = {};
        this.timelineCandidato = [];
        this.indexCandidatoDetalhe = null;
        this.loading.body = false;
      }, 300);
    },
    mudaAba(ativo) {
      if(ativo === 'timeline') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = true;
          this.fl_curriculo = false;
          this.fl_contatos = false;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = false;
          this.fl_referencias = false;
        }, 300);
      } else if (ativo === 'curriculo') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = true;
          this.fl_contatos = false;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = false;
          this.fl_referencias = false;
        }, 300);
      } else if (ativo === 'matchjobAi') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = false;
          this.fl_contatos = false;
          this.fl_matchjobAi = true;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = false;
          this.fl_referencias = false;
        }, 300);
      } else if (ativo === 'resultadoTestes') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = false;
          this.fl_contatos = false;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = true;
          this.fl_indicacoes = false;
          this.fl_referencias = false;
        }, 300);
      } else if(ativo === 'contatos') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = false;
          this.fl_contatos = true;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = false;
          this.fl_referencias = false;
        }, 300);
      } else if(ativo === 'indicacoes') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = false;
          this.fl_contatos = false;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = true;
          this.fl_referencias = false;
        }, 300);
      } else if(ativo === 'referencias') {
        this.loading.detalhes = true;
        setTimeout(() => {
          this.loading.detalhes = false;
          this.fl_timeline = false;
          this.fl_curriculo = false;
          this.fl_contatos = false;
          this.fl_matchjobAi = false;
          this.fl_resultadoTestes = false;
          this.fl_indicacoes = false;
          this.fl_referencias = true;
        }, 300);
      }
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    notificacaoIcone(msg, tipo, icone) {
      this.$notify({
        message: msg,
        timeout: 3000,
        icon: icone,
        type: tipo
      });
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    async carregaInfos() {
      if (this.usuarios.length < 1) {
        const data = {
          id_empresa: this.id.empresa,
          id_empresa_user: this.id.empresa_user,
          hl_email: this.id.usuario,
          fl_admin: this.id.admin
        }
        this.$store.dispatch('loadTestesUsuariosEnderecos', data);
      }
      /* setTimeout(() => {
        //console.log('state Testes -> ', this.$store.state.testes.testes)
        //this.testes = this.$store.state.testes.testes.filter(t => t.nm_tipo === 'simples');
        //console.log('this.testes -> ', this.testes)
        //this.testes = this.$store.state.testes.testes;
        //this.usuarios = this.$store.state.usuarios.usuarios;
        this.enderecos = this.$store.state.enderecos.enderecos;
      }, 500); */
    },
    async recarregarCandidatos() {
      this.loading.full = true;
      const data = {
        id_empresa: this.id.empresa,
        hl_link: this.$route.params.url
      }
      await this.$http.post('vagas/visualizarvaga', data).then(res => {
        this.$store.dispatch('atualizaVaga', res.data);
        this.vaga = res.data;//REMOVER
        this.loading.full = false;
      })
      this.filtraCandidatosFase(this.faseAtiva);
    },
    addNovoComentario(index) {
      var timeline = {
        tipo_registro: "comentario",
        text: this.addComentario,
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario
      }
      this.candidatosFase[index].timeline.unshift(timeline);
      this.addComentario = "";
      this.modal.novoComentario = false;
      this.alertShow("Novo Comentário", "Comentário adcionado com sucesso!", 'info')
    },
    addNovaIndicacao() {
      var timeline = {
        tipo_registro: "indicacao",
        text: this.novaIndicacao.nome,
        email: this.novaIndicacao.nome,
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario
      }
      this.candidatosFase[this.novaIndicacao.index].timeline.unshift(timeline);
      var indicacaoTemp = {
        nome: this.novaIndicacao.nome,
        email: this.novaIndicacao.email,
        cargo: this.novaIndicacao.cargo,
        obs: this.novaIndicacao.obs,
      }
      this.candidatosFase[this.novaIndicacao.index].indicacoes.unshift(indicacaoTemp);
      this.novaIndicacao = {};
      this.modal.indicacao = false;
      this.alertShow("Nova Indicação", "Indicação registrada com sucesso!", 'info')
    },
    addNovaReferenciaProfissional() {
      var exp = this.candidatosFase[this.novaReferencia.index].curriculo.json_experiencias.find(e => e.id === this.novaReferencia.refExperiencia)
      var timeline = {
        tipo_registro: "novaReferencia",
        text: this.novaReferencia.nome,
        expCandidato: exp.nm_cargo,
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario
      }
      this.candidatosFase[this.novaReferencia.index].timeline.unshift(timeline);
      var referenciaTemp = {
        nome: this.novaReferencia.nome,
        email: this.novaReferencia.email,
        telefone: this.novaReferencia.telefone,
        cargo: this.novaReferencia.cargo,
        refExperiencia: exp,
        respostaReferencia: [],
        obs: this.novaReferencia.obs,
      }
      this.candidatosFase[this.novaReferencia.index].referencias.unshift(referenciaTemp);
      this.novaReferencia = {};
      this.modal.referencia = false;
      this.alertShow("Nova Referência Profissional", "Referência Profissional registrada com sucesso!", 'info')
    },
    addRetornoReferenciaProfissional(index, indiceRef) {
      this.candidatosFase[index].referencias[indiceRef].respostaReferencia.push(this.retornoReferencia);
      this.retornoReferencia = "";
      this.flRetornoReferencia = false;
    },
    //....Verifica a Fase Atual
    verificaFase(index) {
      //console.log('Verifica Fase - index -> ', index)
      //console.log('Verifica Fase Atual -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo)
      const indiceFase = this.candidatosFase[index].fase_atual - 1;
      if(this.vaga.json_fases[indiceFase].tipo === 'testePerfil') {
        //console.log('Verifica Fase - Teste de Perfil -> ', this.vaga.json_fases[indiceFase]);
      } else if(this.vaga.json_fases[indiceFase].tipo === 'enviaTestes') {
        return 'enviaTestes';
        //Envio de Testes Personalizados
        //console.log('Verifica Fase - Envio de Testes Tipo -> ', this.vaga.json_fases[indiceFase].tipo);
        //console.log('Verifica Fase - Envio de Testes -> ', this.vaga.json_fases[indiceFase].testesPadrao);
        /* for (var i = 0; i < this.vaga.json_fases[indiceFase].testesPadrao.length; i++) {
          var indice = this.vaga.json_fases[indiceFase].testesPadrao[i].id_teste;
          //console.log('Verifica Fase - Teste Utilizado -> ', this.testes.find(a => a.id_teste === indice))
        } */
      } else if(this.vaga.json_fases[indiceFase].tipo === 'entrevistas') {
        //Entrevista
        if(this.vaga.json_fases[indiceFase].configs.entrevistaRH) {
          //console.log('Verifica Fase - Entrevista com o RH ->>>>')
          return 'entrevistaRH';
        } else if(this.vaga.json_fases[indiceFase].configs.entrevistaSolicitante) {
          //console.log('Verifica Fase - Entrevista com o Solicitante da Vaga ->>>>')
          return 'entrevistaSolicitante';
        } else {
          //console.log('Verifica Fase - Entrevista com qualquer colaborador ->>>>')
          return 'entrevista';
        }
        //console.log('Verifica Fase - Entrevista -> ', this.vaga.json_fases[indiceFase]);
      } else if(this.vaga.json_fases[indiceFase].tipo === 'dinamica') {
        return 'dinamica';
        //console.log('Verifica Fase - Dinâmica -> ', this.vaga.json_fases[indiceFase]);
      } else if(this.vaga.json_fases[indiceFase].tipo === 'validacaoDados') {
        return 'validacaoDados';
        //console.log('Verifica Fase - Validação de Dados -> ', this.vaga.json_fases[indiceFase]);
      } else if(this.vaga.json_fases[indiceFase].tipo === 'negociacao') {
        return 'negociacao';
        //console.log('Verifica Fase - Negocioação -> ', this.vaga.json_fases[indiceFase]);
      } else if(this.vaga.json_fases[indiceFase].tipo === 'contratacao') {
        return 'contratacao';
        //console.log('Verifica Fase - Contratação -> ', this.vaga.json_fases[indiceFase]);
      }
    },
    //....Move o candidato de fase
    moveProximaFase(index) {
      var numFases = this.vaga.json_fases.length;
      if (this.candidatosFase[index].fase_atual < numFases) {
        var timelineTestes = []
        //console.log('fase nova -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo)
        if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'testePerfil') {
          if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].id_teste !== '') {
            let tempTeste = this.testesPerfil.find(a => a.id_teste === this.vaga.json_fases[this.candidatosFase[index].fase_atual].id_teste)
            let testesParaEnviar = []
            let arrTemp = {
              id_processo_vaga: this.vaga.id_processo_vaga,
              id_empresa: this.vaga.id_empresa,
              id_candidato: this.candidatosFase[index].id_candidato,
              nm_nome: this.candidatosFase[index].curriculo.nm_nome,
              hl_email: this.candidatosFase[index].curriculo.hl_email,
              nm_cargo: this.vaga.nm_cargo,
              id_teste: tempTeste.id_teste,
              nomeTeste: tempTeste.nm_nome,
              descricaoTeste: tempTeste.nm_descricao,
              fl_tipo: 'testePerfil',
              fl_origem: 'interno',
              fl_status: 1,
              hl_url: this.makeName(),
              url_empresa: this.empresa.hl_url_amigavel,
              json_resposta: tempTeste.json_questoes.questions
            }
            testesParaEnviar.push(arrTemp);
            let arrTempTimeline = {
              tipo_registro: "envioTestePerfil",
              date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
              id_empresa_user: this.id.empresa_user,
              nome: this.id.nome + " - " + this.id.usuario,
              nomeTeste: tempTeste.nm_nome,
              id_teste: tempTeste.id_teste,
              fl_tipo: 'testePerfil',
              fl_origem: 'interno',
              modoEnvio: 'auto',
              hl_url: arrTemp.hl_url,
              url_empresa: this.empresa.hl_url_amigavel
            }
            var retornoEnvioTestes = this.enviarTeste(testesParaEnviar)
            timelineTestes.push(arrTempTimeline);
          } else {
            console.log('não tem teste de perfil definido...')
          }
          //console.log('Teste de Perfil -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'enviaTestes') {
          //Envio de Testes Personalizados
          //console.log('Envio de Testes Tipo -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo);
          if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].testesPadrao) {
            //console.log('Existe chave -> testesPadrao')
            //console.log('Envio de Testes -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual].testesPadrao);
            let testesParaEnviar = []
            for (var i = 0; i < this.vaga.json_fases[this.candidatosFase[index].fase_atual].testesPadrao.length; i++) {
              var indice = this.vaga.json_fases[this.candidatosFase[index].fase_atual].testesPadrao[i].id_teste;
              let tempTeste = this.testes.find(a => a.id_teste === indice)
              let arrTemp = {
                id_processo_vaga: this.vaga.id_processo_vaga,
                id_empresa: this.vaga.id_empresa,
                id_candidato: this.candidatosFase[index].id_candidato,
                nm_nome: this.candidatosFase[index].curriculo.nm_nome,
                hl_email: this.candidatosFase[index].curriculo.hl_email,
                nm_cargo: this.vaga.nm_cargo,
                id_teste: tempTeste.id_teste,
                nomeTeste: tempTeste.nm_nome,
                descricaoTeste: tempTeste.nm_descricao,
                fl_tipo: 'enviaTestes',
                fl_origem: 'interno',
                fl_status: 1,
                hl_url: this.makeName(),
                url_empresa: this.empresa.hl_url_amigavel,
                json_resposta: tempTeste.json_questoes.questions
              }
              testesParaEnviar.push(arrTemp);
              let arrTempTimeline = {
                tipo_registro: "envioTeste",
                date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
                id_empresa_user: this.id.empresa_user,
                nome: this.id.nome + " - " + this.id.usuario,
                nomeTeste: tempTeste.nm_nome,
                id_teste: tempTeste.id_teste,
                fl_tipo: 'enviaTestes',
                fl_origem: 'interno',
                modoEnvio: 'auto',
                hl_url: arrTemp.hl_url,
                url_empresa: this.empresa.hl_url_amigavel
              }
              timelineTestes.push(arrTempTimeline);
              //console.log('Teste Utilizado -> ', this.testes.find(a => a.id_teste === indice))
            }
            //console.log('testesParaEnviar -> ', testesParaEnviar)
            var retornoEnvioTestes = this.enviarTeste(testesParaEnviar)
            //console.log('retornoEnvioTestes -> ', retornoEnvioTestes)
          }
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'entrevistas') {
          //Entrevista
          if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].configs.entrevistaRH) {
            console.log('Entrevista com o RH ->>>>')
          } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].configs.entrevistaSolicitante) {
            console.log('Entrevista com o Solicitante da Vaga ->>>>')
          } else {
            console.log('Entrevista com qualquer colaborador ->>>>')
          }
          console.log('Entrevista -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'dinamica') {
          console.log('Dinâmica -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'validacaoDados') {
          console.log('Validação de Dados -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'negociacao') {
          console.log('Negocioação -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        } else if(this.vaga.json_fases[this.candidatosFase[index].fase_atual].tipo === 'contratacao') {
          console.log('Contratação -> ', this.vaga.json_fases[this.candidatosFase[index].fase_atual]);
        }
        this.candidatosFase[index].fase_atual += 1;
        var indexProximaFase = this.vaga.json_fases.findIndex(e => e.id === this.candidatosFase[index].fase_atual);
        if (this.vaga.json_fases[indexProximaFase].value === undefined) {
          var nomeNovaFase = this.vaga.json_fases[indexProximaFase].text;
        } else {
          var nomeNovaFase = this.vaga.json_fases[indexProximaFase].value
        }
        var timeline = {
          tipo_registro: "mudanca_fase",
          text: "Candidato movido para a fase " + nomeNovaFase,
          date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
          id_empresa_user: this.id.empresa_user,
          nome: this.id.nome + " - " + this.id.usuario
        }
        this.candidatosFase[index].timeline.unshift(timeline);
        if(timelineTestes.length > 0) {
          timelineTestes.forEach(element => {
            this.candidatosFase[index].timeline.unshift(element);
          })
        }
        this.fechaDetalhes();
        this.notificacao('Candidato movido para a próxima fase!', 'success');
        setTimeout(() => {
          this.candidatosFase = {};
          this.candidatosFase = this.vaga.json_candidaturas.filter(c => c.fase_atual === this.faseAtiva);
          this.candidatosFase.sort((a, b) => {
            return b.analise_ia.pontuacaoTotal - a.analise_ia.pontuacaoTotal
          });
          this.$store.dispatch('atualizaVaga', this.vaga);
        }, 500);
      }
    },
    filtraCandidatosFase(faseId) {
      this.candidatosSelecionados = [];
      this.fechaDetalhes();
      this.loading.full = true;
      this.candidatosFase = [];
      setTimeout(() => {
        this.faseAtiva = faseId;
        this.candidatosFase = this.vaga.json_candidaturas.filter(c => c.fase_atual === faseId);
        this.candidatosFase.sort((a, b) => {
          return b.analise_ia.pontuacaoTotal - a.analise_ia.pontuacaoTotal
        });
        this.loading.full = false;
      }, 300);
    },
    handleEnviarEntrevista() {
      this.loading.body = true;
      this.modal.entrevista = false;
      //Ajuste de entrevistador
      var entrevistador = {
        nome: "",
        email: "",
        id_empresa_user: ""
      };
      //Ajuste de Endereço
      var resEndereco = "";
      if (!this.enviarEntrevista.remota) {
        var endereco = this.enderecos.find(e => e.id_empresa_ender === this.enviarEntrevista.id_endereco);
        resEndereco = endereco.nm_endereco + ', ' + endereco.nu_numero + ' - ' + endereco.nm_bairro + ' - ' + endereco.nm_cidade + '/' + endereco.nm_estado + ' ' + endereco.nm_complemento;
      }
      //ajuste de dataFim
      var dataInicio = new Date(this.enviarEntrevista.datahora);
      var dataFim = addMinutes(dataInicio, this.enviarEntrevista.tempo);
      if(this.entrevista.tipo === 'entrevistaRH') {
        //this.candidatosFase[this.indexCandidatoDetalhe].timeline.unshift(timeline);
        let temp = this.usuarios.find(u => u.id_empresa_user === this.enviarEntrevista.entrevistador.id_empresa_user)
        entrevistador.nome = temp.nm_nome;
        entrevistador.email = temp.hl_email;
        entrevistador.id_empresa_user = this.enviarEntrevista.entrevistador.id_empresa_user;
      } else if(this.entrevista.tipo === 'entrevistaSolicitante') {
        let solicitante = this.usuarios.find(u => u.nm_nome === this.vaga.nm_solicitante && u.nm_depto === this.vaga.nm_depto)
        entrevistador.nome = solicitante.nm_nome;
        entrevistador.email = solicitante.hl_email;
        entrevistador.id_empresa_user = solicitante.id_empresa_user;
        //this.candidatosFase[this.indexCandidatoDetalhe].timeline.unshift(timeline);
      } else if(this.entrevista.tipo === 'entrevistaOutro') {
        if (this.enviarEntrevista.entrevistador.id_empresa_user !== 'outro') {
          var tempEntrevistador = this.usuarios.find(i => i.id_empresa_user === this.enviarEntrevista.entrevistador.id_empresa_user);
          entrevistador.nome = tempEntrevistador.nm_nome;
          entrevistador.email = tempEntrevistador.hl_email;
        } else {
          entrevistador.nome = this.enviarEntrevista.entrevistador.nome;
          entrevistador.email = this.enviarEntrevista.entrevistador.email;
        }
      }
      var timeline = {
        id:  Math.random().toString(16).slice(2),
        tipo_registro: "entrevista",
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario,
        tipo_entrevista: this.entrevista.tipo,
        data_entrevista: this.enviarEntrevista.datahora,
        tempo_entrevista: this.enviarEntrevista.tempo,
        entrevistador: entrevistador,
        remoto: this.enviarEntrevista.remota,
        link: this.enviarEntrevista.link,
        id_endereco: this.enviarEntrevista.id_endereco,
        mensagem: this.enviarEntrevista.mensagem
      }
      var data = {
        to_nome: this.candidatoDetalhe.curriculo.nm_nome,
        to_email: this.candidatoDetalhe.curriculo.hl_email,
        cc_nome: entrevistador.nome,
        cc_email: entrevistador.email,
        vaga: this.vaga.nm_cargo,
        remota: this.enviarEntrevista.remota,
        dataInicio: dataInicio,
        dataFim: dataFim,
        link: this.enviarEntrevista.link,
        endereco: resEndereco,
        mensagem: this.enviarEntrevista.mensagem,
        mensagemFinal: this.enviarEntrevista.mensagemAenviar
      }
      if(this.enviarEntrevista.fl_enviarEmail) {
        this.enviarConviteEntrevista(data);
      }
      setTimeout(() => {
        this.loading.body = false;
        this.alertShow("Agendamento Enviado", "Convite enviado com sucesso!", 'info');
        this.enviarEntrevista = {};
      }, 300);
      this.candidatosFase[this.indexCandidatoDetalhe].timeline.unshift(timeline);
    },
    async enviarConviteEntrevista(data) {
      await this.$http.post('entrevistas/enviarconvite', data).then(res => {
        return true;
      })
    },
    handleAplicarTesteManual() {
      let testesParaEnviar = []
      let tempTeste = this.testes.find(a => a.id_teste === this.enviaTeste.id_teste);
      let arrTemp = {
        id_processo_vaga: this.vaga.id_processo_vaga,
        id_empresa: this.vaga.id_empresa,
        id_candidato: this.candidatosFase[this.enviaTeste.index].id_candidato,
        nm_nome: this.candidatosFase[this.enviaTeste.index].curriculo.nm_nome,
        hl_email: this.candidatosFase[this.enviaTeste.index].curriculo.hl_email,
        nm_cargo: this.vaga.nm_cargo,
        id_teste: tempTeste.id_teste,
        nomeTeste: tempTeste.nm_nome,
        descricaoTeste: tempTeste.nm_descricao,
        fl_tipo: 'enviaTestes',
        fl_origem: 'interno',
        fl_status: 1,
        hl_url: this.makeName(),
        url_empresa: this.empresa.hl_url_amigavel,
        json_resposta: tempTeste.json_questoes.questions
      }
      testesParaEnviar.push(arrTemp);
      let tempTimeline = {
        tipo_registro: "envioTeste",
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario,
        nomeTeste: tempTeste.nm_nome,
        id_teste: tempTeste.id_teste,
        fl_tipo: 'enviaTestes',
        fl_origem: 'interno',
        modoEnvio: 'manual',
        hl_url: arrTemp.hl_url,
        url_empresa: this.empresa.hl_url_amigavel
      }
      var retornoEnvioTestes = null;
      if(retornoEnvioTestes = this.enviarTeste(testesParaEnviar)) {
        this.modal.enviarTeste = false;
        this.candidatosFase[this.enviaTeste.index].timeline.unshift(tempTimeline);
        this.notificacao("Teste enviado com sucesso!", "success");
        this.enviaTeste = {};
      }
    },
    async enviarTeste(data) {
      await this.$http.post('testes/aplicarteste', data).then(res => {
        return res.data;
      }).catch(err => {
        console.log('Erro na API -> ', err);
        this.notificacao('Ops! Houve uma falha não foi gravar as alterações!', 'warning');
      });
    },
    async handleEnviarNegociacao() {
      this.modal.negociacao = false;
      var timeline = {
        tipo_registro: "negociacaoEnviada",
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario,
        cartaProposta: this.novaProposta.linkArquivoEnviado,
        apenasRegistro: this.novaProposta.apenasRegistro,
        mensagemEnviada: this.novaProposta.tx_proposta,
        hl_url: this.novaProposta.url,
        url_empresa: this.empresa.hl_url_amigavel,
        dt_limite: this.novaProposta.dataLimite,
      }
      this.candidatosFase[this.indexCandidatoDetalhe].timeline.unshift(timeline);
      let arrTemp = {
        id_processo_vaga: this.vaga.id_processo_vaga,
        id_empresa: this.vaga.id_empresa,
        id_candidato: this.candidatosFase[this.indexCandidatoDetalhe].id_candidato,
        url_empresa: this.empresa.hl_url_amigavel,
        nm_fantasia: this.empresa.nm_fantasia,
        nm_nome: this.candidatosFase[this.indexCandidatoDetalhe].curriculo.nm_nome,
        hl_email: this.candidatosFase[this.indexCandidatoDetalhe].curriculo.hl_email,
        nm_cargo: this.vaga.nm_cargo,
        apenasRegistro: this.novaProposta.apenasRegistro,
        mensagemEnviada: this.novaProposta.tx_proposta,
        hl_link_carta_proposta: this.novaProposta.linkArquivoEnviado,
        hl_url: this.novaProposta.url,
        dt_limite: this.novaProposta.dataLimite,
      }
      await this.$http.post('vagas/enviarproposta', arrTemp).then(res => {
        if(res.status === 201) {
          this.notificacao('Proposta de Emprego enviada com sucesso!', 'success');
          return true;
        } else {
          this.notificacao('Falha no envio da Proposta de Emprego!', 'danger');
        }
      }).catch(err => {
        console.log('Erro na API -> ', err);
        this.notificacao('Ops! Houve uma falha não foi gravar as alterações!', 'warning');
      });
    },
    modalRegistroEntrevista(tipo) {
      this.registroEntrevista.entrevistasRealizadas = this.candidatosFase[this.indexCandidatoDetalhe].timeline.filter(e => e.tipo_registro === 'entrevista');
      //console.log('entrevistas -> ', entrevistas)
      //console.log('tipo -> ', tipo)
      var tipoEntrevista = this.registroEntrevista.entrevistasRealizadas.find(a => a.tipo_entrevista === tipo);
      //console.log('tipoEntrevista -> ', tipoEntrevista)
      if(this.registroEntrevista.entrevistasRealizadas.length < 1) {
        alert('Nenhum registro de entrevista foi encontrado!')
      } else {
        this.registroEntrevista.tipo_entrevista = tipo;
        this.registroEntrevista.data_entrevista = tipoEntrevista.data_entrevista;
        this.modal.registroEntrevista = true;
      }
    },
    handleRegistroEntrevista() {
      this.modal.registroEntrevista = false
      this.flagEmitRegistroEntrevista = true
      var timeline = {
        tipo_registro: "entrevistaRealizada",
        date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
        id_empresa_user: this.id.empresa_user,
        nome: this.id.nome + " - " + this.id.usuario,
        registroEntrevista: structuredClone(this.registroEntrevista[0])
      }
      this.candidatosFase[this.indexCandidatoDetalhe].timeline.unshift(timeline);
      if(this.registroEntrevista[0].aprovado) {
        if(confirm('Candidato Aprovado!\nDeseja mover para a proxima fase?')) {
          this.moveProximaFase(this.indexCandidatoDetalhe);
        }
      }
    },
    makeName() {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 50; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleModalEntrevista(tipo) {
      if(tipo === 'entrevistaRH') {
        this.entrevista.tipo = 'entrevistaRH'
        this.modal.entrevista = true;
      } else if(tipo === 'entrevistaSolicitante') {
        this.entrevista.tipo = 'entrevistaSolicitante'
        this.modal.entrevista = true;
      } else {
        this.entrevista.tipo = 'entrevistaOutro'
        this.modal.entrevista = true;
      }
    },
    closeModalRegistroEntrevista() {
      this.modal.registroEntrevista = false
    },
    closeModalNegociacao() {
      this.modal.negociacao = false
    },
    closeModalEntrevista() {
      this.modal.entrevista = false
    },
    closeModalIndicacao() {
      this.modal.indicacao = false
    },
    closeModalReferencia() {
      this.modal.referencia = false
    },
    closeModalEnviarTeste() {
      this.modal.enviarTeste = false
    },
  },
  async created() {
    this.loading.full = true;
    const data = {
      id_empresa: this.id.empresa,
      hl_link: this.$route.params.url
    }
    await this.$http.post('vagas/visualizarvaga', data).then(res => {
      this.$store.dispatch('atualizaVaga', res.data);
      this.vaga = res.data;//REMOVER
    })
  },
  mounted() {
    this.faseAtiva = this.faseInicial;
    setTimeout(() => {
      this.loading.full = false;
      this.carregaInfos();
      this.timelineVaga = structuredClone(this.vaga.json_timeline).reverse();
      this.filtraCandidatosFase(this.faseAtiva);
    }, 500);
  },
  beforeDestroy() {
    var dados = {
      id_processo_vaga: this.vaga.id_processo_vaga,
      json_candidaturas: this.vaga.json_candidaturas
    }
    this.$http
    .post('vagas/atualizaprocesso', dados)
    .then(() => {
      console.log('Processo salvo com sucesso.')
    }).catch(err => {
      console.log('Erro na API -> ', err);
    });
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
}
</script>

<style>
[v-cloak] {
  display: none;
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
.icone-help-bread-crumb {
  font-size: 1.6rem;
  cursor: pointer;
}
.but-fase {
  width: 170px;
  height: 90px;
}
.box-fase {
  box-sizing: border-box;
  border: 2px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
.box-fase-candidato {
  box-sizing: border-box;
  border: 2px solid #66d7d1;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
  cursor: pointer;
}
.box-fase-ativa {
  box-sizing: border-box;
  border: 2px solid #66d7d1;
  background-color: rgb(238, 238, 238);
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
  cursor: pointer;
}
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
.icones-caracteristicas-ativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: #2dce89;
}
.icones-caracteristicas-dislike {
  font-size: 1.6rem;
  cursor: pointer;
  color: #f5365c;
}
.icones-caracteristicas-star {
  font-size: 1.6rem;
  cursor: pointer;
  color: #f1d302;
}
.icones-perfil-inativo {
  cursor: pointer;
  color: darkgray;
}
.icones-perfil-ativo {
  cursor: pointer;
  color: #2dce89;
}
.icones-perfil-dislike {
  cursor: pointer;
  color: #f5365c;
}
.icones-perfil-star {
  cursor: pointer;
  color: #f1d302;
}
.td-linked {
  cursor: pointer;
}
.icone-whatsapp {
  font-size: 1.6rem;
  cursor: pointer;
  color: #2dce89;
}
.icone-sms{
  font-size: 1.6rem;
  cursor: pointer;
  color: #11cdef;
}
.icone-email {
  font-size: 1.6rem;
  cursor: pointer;
  color: #5e72e4;
}
.btn-icon-texto {
  height: 2.375rem;
  padding: 0.25rem 0.5rem;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
.list-perguntas-teste {
  font-size: 14px;
}
.linha-clique {
  cursor: pointer;
}
</style>
