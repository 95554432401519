<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="far fa-calendar-plus"></i>
      </div>
      <!-- Bloco de Sistema -->
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid rounded w-75" :src="retornaUsuario('foto')" :alt="retornaUsuario('nome')" />
          </div>
          <div class="col-9 align-self-center">
            <small><strong>{{retornaUsuario('nome')}}</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
          <!-- <div class="col-2 align-self-center">
            <el-tooltip placement="top" content="Cancelar Entrevista" effect="light">
              <base-button outline icon size="sm" type="secondary" class="btn-icon-only" :disabled="verificarBotao()" @click="cancelaEntrevista">
                <i class="text-danger fas fa-trash-alt"></i>
              </base-button>
            </el-tooltip>
            <el-tooltip placement="top" content="Adicionar Registro da Entrevista" effect="light">
              <base-button icon size="sm" type="info" class="btn-icon-only" :disabled="verificarBotao()" @click="cancelaEntrevista">
                <i class="fas fa-comments"></i>
              </base-button>
            </el-tooltip>
          </div> -->
        </div>
        <!--  -->
        <div class="row mt-2">
          <div class="col-12">
            <template v-if="propItem.fl_enviarEmail">Convite de entrevista enviado!<br></template>
            <template v-else>Convite de entrevista registrado!<br></template>
            Entrevista <span v-html="retornaTipoEntrevista(propItem.tipo_entrevista)" /><br>
            Data da entrevista: <strong>{{propItem.data_entrevista | formatDateTime}}</strong><br>
            Entrevistador: <strong>{{propItem.entrevistador.id_empresa_user === '' ? propItem.entrevistador.nome : retornaEntrevistador(propItem.entrevistador.id_empresa_user)}}</strong><br>
            A entrevista será: <strong>{{propItem.remoto ? 'Remota' : 'Presencial'}}</strong><br>
            <template v-if="propItem.remoto">
              Link da entrevista: <a :href="propItem.link" target="_blank">{{propItem.link}}</a>
            </template>
            <template v-else>
              No endereço: <strong>{{retornaEndereco(propItem.id_endereco)}}</strong>
            </template>
            <!-- {{propItem}} -->
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";
export default {
  name: "CompEntrevista",
  mixins: [defaultMatchjob],
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      usuarios: []
    }
  },
  computed: {
    usuariosState() {
      return this.$store.state.usuarios.usuarios
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuariosState.length > 0) {
        var user = this.usuariosState.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    },
    retornaEntrevistador(id) {
      var user = this.usuariosState.find(uS => uS.id_empresa_user === id)
      return user.nm_nome
    },
    retornaEndereco(id) {
      if(id) {
        var endereco = this.enderecos.find(e => e.id_empresa_ender === id);
        var resEndereco = endereco.nm_endereco + ', ' + endereco.nu_numero + ' - ' + endereco.nm_bairro + ' - ' + endereco.nm_cidade + '/' + endereco.nm_estado + ' ' + endereco.nm_complemento;
        return resEndereco;
      }
    },
    verificarBotao() {
      if(this.propItem.id_empresa_user === this.id.empresa_user) {
        return false;
      } else if (this.id.admin) {
        return false;
      } else {
        return true;
      }
    },
    retornaTipoEntrevista(tipo) {
      if(tipo === 'entrevistaRH') {
        return ' com o <strong>RH</strong>';
      } else if(tipo === 'entrevistaSolicitante') {
        return ' com o <strong>Solicitante da Vaga</strong>'
      } else if(tipo === 'entrevistaOutro') {
        return '<strong>Normal</strong>'
      }
    },
    cancelaEntrevista() {
      alert('ok')
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
</style>
