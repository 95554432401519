<template>
  <collapse><!-- :active-index="6" -->
    <!-- Fase - Publicação da Vaga -->
    <collapse-item name="1">
      <h3 slot="title"><span class="text-muted">Fase -</span> Publicação da Vaga <el-tooltip
        content="Fase Obrigatória"
        placement="top"
      ><i class="text-red fas fa-certificate"> </i>
      </el-tooltip></h3>
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Todas as vagas precisam de aprovação?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.publicacaoVaga.requerAprovacao"
            @input="ativaLoading('requerAprovacao')"
          ></base-switch>
        </div>
      </div>
      <div v-loading="loading.requerAprovacao">
        <!-- Linha de seleção de quem aprova as vagas -->
        <div class="row justify-content-center mt-3">
          <template v-if="propConfigFases.publicacaoVaga.requerAprovacao">
            <!-- Coluna dos RADIOS (Tipo de Acesso / Usuários) -->
            <div class="col-4 align-self-center">
              <label>Como será a aprovação de RH?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="area"
                  id="radioTipoAprovacao1" name="flTipoAprovacao"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh"
                  @input="ativaLoading('tipoRh')"
                />
                <label class="form-check-label" for="radioTipoAprovacao1">Por Tipo de Acesso</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="usuario"
                  id="radioTipoAprovacao2" name="flTipoAprovacao"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh"
                  @input="ativaLoading('tipoRh')"
                />
                <label class="form-check-label" for="radioTipoAprovacao2">Por usuário definido</label>
              </div>
            </div>
            <!-- Coluna dos Selects (Tipo de Acesso/Usuários) -->
            <div class="col-4 align-self-center" v-loading="loading.tipoRh">
              <!-- Select de Tipo de Acesso -->
              <template v-if="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoRh === 'area'">
                <label for="areaatuacao">Tipo de Acesso
                  <i class="fas fa-question-circle"></i>
                </label>
                <el-select
                  required
                  class="inuptAutocomplete"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.tipoAcesso"
                  placeholder="Tipo de acesso"
                >
                  <el-option
                    v-for="tipo in tipoAcesso"
                    :key="tipo.value"
                    :label="tipo.text"
                    :value="tipo.value">
                  </el-option>
                </el-select>
              </template>
              <!-- Select de Usuários -->
              <template v-else>
                <label for="areaatuacao">Usuários
                  <i class="fas fa-question-circle"></i>
                </label>
                <el-select
                  required
                  filterable
                  class="inuptAutocomplete"
                  v-model="propConfigFases.publicacaoVaga.aprovacaoTipo.usuario"
                  placeholder="Usuários"
                >
                  <el-option
                    v-for="user in usuariosRh"
                    :key="user.id_empresa_user"
                    :label="user.nm_nome"
                    :value="user.id_empresa_user">
                    <span><strong>{{user.nm_nome}}</strong> -
                    <small>{{user.nm_cargo}}</small></span>
                  </el-option>
                </el-select>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="col-8">
              <small class="text-muted">As vagas serão publicadas automáticamente em todas as integrações ativadas.</small>
            </div>
          </template>
        </div>
        <!-- Flag de aprovação do solicitante -->
        <div class="row justify-content-center mt-4" v-if="propConfigFases.publicacaoVaga.requerAprovacao">
          <div class="col-4 align-self-center text-right">
            <label>O solicitante da vaga deverá aprovar a vaga antes da publicação?</label>
            <small
              v-if="propConfigFases.publicacaoVaga.solicitanteAprova"
              class="text-muted">A vaga só será publicada após todas as aprovações.</small>
          </div>
          <div class="col-4 align-self-center">
            <base-switch
              class="ml-2"
              v-model="propConfigFases.publicacaoVaga.solicitanteAprova"
            ></base-switch>
          </div>
        </div>
      </div><!-- DIV do V-Loading -->
      <!-- Botão Salvar -->
      <!-- <div class="row justify-content-center mt-5">
        <div class="col text-center">
          <base-button type="primary" class="mr-3 ml-auto" @click="editFormSubmit">Atualizar</base-button>
        </div>
      </div> -->
    </collapse-item>
    <!-- Fase - Triagem de Currículos -->
    <collapse-item name="2">
      <h3 slot="title"><span class="text-muted">Fase -</span> Triagem de Currículos <el-tooltip
        content="Fase Obrigatória"
        placement="top"
      ><i class="text-red fas fa-certificate"> </i>
      </el-tooltip></h3>
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <h4>Definição dos parâmetros de match da MatchJobAI</h4>
        </div>
      </div>
      <!-- Pretensão Salarial -->
      <div class="row justify-content-center my-3">
        <div class="col-3 align-self-center">
          <label>Avaliar pretensão salarial?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            id="pretensao"
            class="ml-2"
            v-model="propConfigFases.triagem.pretensaoSalarial.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <label>Faixa de Aceitação</label>
          <el-select
            required
            class="inuptAutocomplete"
            v-model="propConfigFases.triagem.pretensaoSalarial.faixa"
            placeholder="Faixa de Aceitação"
            :disabled="!propConfigFases.triagem.pretensaoSalarial.ativo"
          >
            <el-option
              v-for="tipo in faixaSalarial"
              :key="tipo.value"
              :label="tipo.label"
              :value="tipo.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- Competências -->
      <div class="row justify-content-center my-4">
        <div class="col-3 align-self-center">
          <label>Avaliar Competências?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.competencias.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <small
              v-if="!propConfigFases.triagem.competencias.ativo"
              class="text-muted">Qualquer configuração de requisito de competência incluído nas vagas serão ignorados.</small>
        </div>
      </div>
      <!-- Idiomas -->
      <div class="row justify-content-center my-4">
        <div class="col-3 align-self-center">
          <label>Avaliar Idiomas?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.idiomas.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <small
              v-if="!propConfigFases.triagem.idiomas.ativo"
              class="text-muted">Qualquer configuração de requisito de idioma incluído nas vagas serão ignorados.</small>
        </div>
      </div>
      <!-- Formação -->
      <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.formacao">
        <div class="col-3 align-self-center">
          <label>Avaliar Formações?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.formacao.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <small
              v-if="!propConfigFases.triagem.formacao.ativo"
              class="text-muted"
          >Qualquer configuração de requisito de formação incluído nas vagas serão ignorados.</small>
        </div>
      </div>
      <!-- Certificações -->
      <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.certificacoes">
        <div class="col-3 align-self-center">
          <label>Avaliar Certificações?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.certificacao.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <small
              v-if="!propConfigFases.triagem.certificacao.ativo"
              class="text-muted"
          >Qualquer configuração de requisito de certificação incluído nas vagas serão ignorados.</small>
        </div>
      </div>
      <!-- Localização -->
      <div class="row justify-content-center my-4">
        <div class="col-3 align-self-center">
          <label>Avaliar a Distância?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.localizacao.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <template v-if="propConfigFases.triagem.localizacao.ativo">
            <template v-if="configEmpresa.matchAi.localizacao === '1'">
              <p>Verifica se o candidato reside na mesma Cidade/Estado da localização vaga.</p>
            </template>
            <template v-else-if="configEmpresa.matchAi.localizacao === '2'">
              <p>Verifica se o candidato reside proximo ao bairro da localização vaga.</p>
            </template>
            <template v-else-if="configEmpresa.matchAi.localizacao === '3'">
              <p class="h5">Calcula o tempo do trajeto entre a residencia do candidato e a localização da vaga.<br>
              <small>O calculo é estimado considerando dias úteis e o horário de pico da manhã.</small>
              </p>
            </template>
          </template>
        </div>
      </div>
      <!-- Tempo de Experiência -->
      <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.tempoExperiencia">
        <div class="col-3 align-self-center">
          <label>Avaliar Tempo de Experiência?</label>
        </div>
        <div class="col-1 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.triagem.tempoExperiencia.ativo"
            @input="ativaLoading('pretensaoSalarial.ativo')"
          ></base-switch>
        </div>
        <div class="col-4 align-self-center">
          <small
              v-if="!propConfigFases.triagem.tempoExperiencia.ativo"
              class="text-muted"
          >Qualquer configuração de requisito de tempo de experiência incluído nas vagas serão ignorados.</small>
        </div>
      </div>
    </collapse-item>
    <!-- Fase - Testes de Perfil -->
    <collapse-item name="3" v-if="parseInt(propPlano.ps.testePerfil) > 0">
      <h3 slot="title" :class="!propConfigFases.testePerfil.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Testes de Perfil <el-tooltip
        :content="propConfigFases.testePerfil.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.testePerfil.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação de Testes de Perfil da MatchJob -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a utilização de Testes de Perfil?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.testePerfil.ativo"
            @input="ativaLoading('testePerfil')"
          ></base-switch>
        </div>
      </div>
      <!-- Carregamento de Testes de Perfil -->
      <div v-loading="loading.testesPerfil" v-if="propConfigFases.testePerfil.ativo">
        <template v-if="propPlano.ps.testePerfil > 0">
          <div class="row justify-content-center mt-3">
            <div class="col-5 align-self-center">
              <template v-if="propConfigFases.testePerfil.id_teste !== ''">
                <table v-if="Object.keys(propTestePerfil).length > 0">
                  <tr class="border-bottom">
                    <td class="w-100 pt-3">
                      <span class="h4">{{ retornaNomeTeste(propConfigFases.testePerfil.id_teste, 'nome') }}</span><br>
                      <span class="text-muted">{{ retornaNomeTeste(propConfigFases.testePerfil.id_teste, 'descricao') }}</span>
                    </td>
                    <td class="pt-3">
                      <!-- botao excluir -->
                      <base-button
                        type="danger"
                        size="sm"
                        class="btn-icon-only"
                        @click="removeTestePerfil()"
                      ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
                      </base-button>
                    </td>
                  </tr>
                </table>
              </template>
            </div>
          </div>
          <!-- Adiciona Testes como Padrão -->
          <div class="row justify-content-center mt-2" v-if="propConfigFases.testePerfil.id_teste === ''">
            <div class="col-4 align-self-center">
              <label>Escolha um teste de perfil para definir como padrão:</label>
              <el-select
                required
                class="inuptAutocomplete"
                v-model="addTestePerfil"
                placeholder="Escolha o Teste"
              >
              <template v-for="teste in propTestePerfil">
                <template v-if="propPlano.ps.testePerfil > 1">
                  <el-option
                    :key="teste.id_teste"
                    :label="teste.nm_nome"
                    :value="teste.id_teste">
                  </el-option>
                </template>
                <template v-else-if="teste.nm_tipo === 'simples'">
                  <el-option
                    :key="teste.id_teste"
                    :label="teste.nm_nome"
                    :value="teste.id_teste">
                  </el-option>
                </template>
              </template>
              </el-select>
            </div>
            <div class="col-1 align-self-end">
              <base-button type="default" class="btn-icon-only" @click="definirTestePerfil"><i class="fas fa-plus"></i></base-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row justify-content-center mt-3">
            <div class="col-5 align-self-center text-center">
              <p class="h4">O seu plano não permite a utilização de testes de Perfil.</p>
              <p>Mas você pode contratar um novo plano e habilitar essa funcionalidade.</p>
            </div>
          </div>
        </template>
      </div>
    </collapse-item>
    <!-- Fase - Envio de Testes -->
    <collapse-item name="4">
      <h3 slot="title" :class="!propConfigFases.enviaTestes.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Envio de Testes <el-tooltip
        :content="propConfigFases.enviaTestes.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.enviaTestes.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação da fase -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a fase de envio de Testes?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.enviaTestes.ativo"
            @input="ativaLoading('testes')"
          ></base-switch>
        </div>
      </div>
      <!-- Flag de ativação de testes Externos -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja habilitar a utilização de Testes Externos? <i class="fas fa-question-circle"></i></label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.enviaTestes.testesExternos"
          ></base-switch>
        </div>
      </div>
      <div v-loading="loading.testes">
        <template v-if="propConfigFases.enviaTestes.ativo">
          <template v-if="propTestes.length > 0">
            <div class="row justify-content-center mt-3">
              <div class="col-5 align-self-center">
                <label v-if="propConfigFases.enviaTestes.testesPadrao.length === 1">
                  Existe {{propConfigFases.enviaTestes.testesPadrao.length}} teste definido como padrão.</label>
                <label v-else-if="propConfigFases.enviaTestes.testesPadrao.length > 1">
                  Existes {{propConfigFases.enviaTestes.testesPadrao.length}} testes definidos como padrão.</label>
                <table v-if="propConfigFases.enviaTestes.testesPadrao.length > 0">
                  <tr class="border-bottom" v-for="teste in propConfigFases.enviaTestes.testesPadrao" :key="teste.id">
                    <td class="w-100 pt-3">
                      <span class="h4">{{teste.nome}}</span><br>
                      <span class="text-muted">{{teste.descricao}}</span>
                    </td>
                    <td class="pt-3">
                      <!-- botao excluir -->
                      <base-button
                        type="danger"
                        size="sm"
                        class="btn-icon-only"
                        @click="removeTesteProcesso(teste.id)"
                      ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
                      </base-button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- Adiciona Testes como Padrão -->
            <div class="row justify-content-center my-3">
              <div class="col-4 align-self-center">
                <label>Deseja definir um ou mais testes como padrão?</label>
                <el-select
                  required
                  class="inuptAutocomplete"
                  v-model="addTeste"
                  placeholder="Escolha o Teste"
                >
                  <el-option
                    v-for="teste in propTestes"
                    :key="teste.id_teste"
                    :label="teste.nm_nome"
                    :value="teste.id_teste">
                  </el-option>
                </el-select>
              </div>
              <div class="col-1 align-self-end">
                <base-button type="default" class="btn-icon-only" @click="addTesteProcesso"><i class="fas fa-plus"></i></base-button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-center mt-3">
              <div class="col-5 align-self-center text-center">
                <p class="h4">Você ainda não possui nenhum teste criado.</p>
                <p>A fase está ativada e você poderá escolher um ou mais testes durante a criação do processo de seleção.</p>
              </div>
            </div>
          </template>
        </template>
      </div>
    </collapse-item>
    <!-- Fase - Entrevistas -->
    <collapse-item name="5">
      <h3 slot="title" :class="!propConfigFases.entrevistas.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Entrevistas <el-tooltip
        :content="propConfigFases.entrevistas.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.entrevistas.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação da fase -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a fase Entrevistas?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.entrevistas.ativo"
            @input="ativaLoading('testes')"
          ></base-switch>
        </div>
      </div>
      <div class="row justify-content-center mt-3" v-if="propConfigFases.entrevistas.ativo">
        <div class="col-8 align-self-center text-center">
          <p>A fase está ativada e não possui nenhuma configuração específica.</p>
        </div>
      </div>
    </collapse-item>
    <!-- Fase - Dinâmicas -->
    <collapse-item name="6">
      <h3 slot="title" :class="!propConfigFases.dinamica.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Dinâmicas <el-tooltip
        :content="propConfigFases.dinamica.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.dinamica.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação da fase -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a fase Dinâmicas?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.dinamica.ativo"
            @input="ativaLoading('testes')"
          ></base-switch>
        </div>
      </div>
      <div class="row justify-content-center mt-3" v-if="propConfigFases.dinamica.ativo">
        <div class="col-8 align-self-center text-center">
          <p>A fase está ativada e não possui nenhuma configuração específica.</p>
        </div>
      </div>
    </collapse-item>
    <!-- Fase - Validação de Dados -->
    <collapse-item name="7">
      <h3 slot="title" :class="!propConfigFases.validacaoDados.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Validação de Dados <el-tooltip
        :content="propConfigFases.validacaoDados.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.validacaoDados.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação da fase -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a fase Validação de Dados?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.validacaoDados.ativo"
            @input="ativaLoading('testes')"
          ></base-switch>
        </div>
      </div>
      <div class="row justify-content-center mt-3" v-if="propConfigFases.validacaoDados.ativo">
        <div class="col-8 align-self-center text-center">
          <p>A fase está ativada e não possui nenhuma configuração específica.</p>
          <p>Esta fase sugere a validação dos dados dos últimos candidatos.</p>
        </div>
      </div>
    </collapse-item>
    <!-- Fase - Negociação -->
    <collapse-item name="8">
      <h3 slot="title" :class="!propConfigFases.negociacao.ativo ? 'text-muted' : ''"><span class="text-muted">Fase -</span> Negociação <el-tooltip
        :content="propConfigFases.negociacao.ativo ? 'Fase Ativa' : 'Fase Desativada'"
        placement="top"
      ><i :class="propConfigFases.negociacao.ativo ? 'text-success fas fa-check-circle' : 'text-gray fas fa-ban'"> </i>
      </el-tooltip></h3>
      <!-- Flag de ativação da fase -->
      <div class="row justify-content-center mt-3">
        <div class="col-4 align-self-center text-right">
          <label>Deseja ativar a fase Negociação?</label>
        </div>
        <div class="col-4 align-self-center">
          <base-switch
            class="ml-2"
            v-model="propConfigFases.negociacao.ativo"
            @input="ativaLoading('testes')"
          ></base-switch>
        </div>
      </div>
      <div class="row justify-content-center mt-3" v-if="propConfigFases.negociacao.ativo">
        <div class="col-8 align-self-center text-center">
          <p>A fase está ativada e não possui nenhuma configuração específica.</p>
        </div>
      </div>
    </collapse-item>
    <!-- Fase - Contratação -->
    <collapse-item name="9">
      <h3 slot="title"><span class="text-muted">Fase -</span> Contratação <el-tooltip
        content="Fase Obrigatória"
        placement="top"
      ><i class="text-red fas fa-certificate"> </i>
      </el-tooltip></h3>
      <div class="row justify-content-center mt-3" v-if="propConfigFases.contratacao.ativo">
        <div class="col-8 align-self-center text-center">
          <p>A fase está ativada e não possui nenhuma configuração específica.</p>
          <p>Esta fase sugere o encerramento do processo seletivo.</p>
        </div>
      </div>
    </collapse-item>
  </collapse>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '@/mixins/defaultMatchjob';
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option
} from 'element-ui';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

Vue.use(Loading.directive);

export default {
  name: "ConfiguracoesFases",
  mixins: [defaultMatchjob],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Collapse,
    CollapseItem
  },
  props:{
    propUsuarios: { type: Array },
    propPlano: { type: Object},
    propConfigFases: { type: Object},
    propTestePerfil: { type:  Array },
    propTestes: { type:  Array },
  },
  data() {
    return {
      loading: {
        requerAprovacao: false,
        tipoRh: false,
        testesPerfil: false,
        testes: false
      },
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
      usuariosRh: [],
      faixaSalarial: [
        { value: "1", label: "Menor ou Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ],
      addTestePerfil: "",
      addTeste: "",
    }
  },
  methods: {
    ativaLoading(value) {
      if (value === 'requerAprovacao') {
        this.loading.requerAprovacao = true;
        setTimeout(() => {
          this.loading.requerAprovacao = false;
        }, 500);
      } else if (value === 'tipoRh') {
        this.loading.tipoRh = true;
        setTimeout(() => {
          this.loading.tipoRh = false;
        }, 500);
      } else if (value === 'testes') {
        this.loading.testes = true;
        /* if (this.propConfigFases.enviaTestes.ativo && this.testes.length < 1){
          this.carregaTestes();
        } */
        setTimeout(() => {
          this.loading.testes = false;
        }, 500);
      } else if (value === 'testePerfil') {
        this.loading.testesPerfil = true;
        setTimeout(() => {
          this.loading.testesPerfil = false;
        }, 500);
      }/*  else if (value === 'origemTeste') {
        this.loading.testesPerfil = true;
        if (this.propConfigFases.testePerfil.fl_origem_teste ===  '1'){
          this.carregaTestes();
        } else {
          // ...
        }
        setTimeout(() => {
          this.loading.testesPerfil = false;
        }, 500);
      } */
    },
    filtraUsuariosRh() {
      this.usuariosRh = this.propUsuarios.filter((item) => {
        if (item.fl_tipo_acesso > 0 && item.fl_tipo_acesso < 5) {
          return true;
        } else {
          return false;
        }
      });
    },
    retornaNomeTeste(id, flag){
      var teste = this.propTestePerfil.find(i => i.id_teste === id);
      if (flag === 'nome'){
        return teste.nm_nome;
      } else {
        return teste.nm_descricao;
      }
    },
    removeTestePerfil() {
      this.loading.testesPerfil = true;
      setTimeout(() => {
        this.propConfigFases.testePerfil.id_teste = "";
        this.loading.testesPerfil = false;
      }, 500);
    },
    definirTestePerfil() {
      this.loading.testesPerfil = true;
      if (this.addTestePerfil === '') {
        this.notificacao('Você precisa escolher um teste antes!', 'warning');
        this.loading.testesPerfil = false;
      } else {
        setTimeout(() => {
          this.propConfigFases.testePerfil.id_teste = this.addTestePerfil;
          this.addTestePerfil = "";
          this.loading.testesPerfil = false;
        }, 500);
      }
    },
    removeTesteProcesso(id) {
      this.loading.testes = true;
      var pos = this.propConfigFases.enviaTestes.testesPadrao.findIndex( item => item.id === id);
      setTimeout(() => {
        this.propConfigFases.enviaTestes.testesPadrao.splice(pos, 1);
        this.loading.testes = false;
      }, 500);
    },
    addTesteProcesso() {
      this.loading.testes = true;
      if (this.addTeste === '') {
        this.notificacao('Você precisa escolher um teste antes!', 'warning');
        this.loading.testes = false;
      } else if (this.propConfigFases.enviaTestes.testesPadrao.findIndex(i => i.id === this.addTeste) > -1) {
        setTimeout(() => {
          this.addTeste = "";
          this.loading.testes = false;
          this.notificacao('Você já adicionou esse teste!', 'warning');
        }, 300);
      }else {
        var teste = this.propTestes.find(i => i.id_teste === this.addTeste);
        let tempTeste = {
          id: teste.id_teste,
          nome: teste.nm_nome,
          descricao: teste.nm_descricao
        };
        setTimeout(() => {
          this.propConfigFases.enviaTestes.testesPadrao.push(tempTeste);
          this.addTeste = "";
          this.loading.testes = false;
        }, 500);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.filtraUsuariosRh();
    }, 500);
  }
}
</script>

<style>

</style>
