<template>
  <div v-loading="loading">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
        @submit.prevent="handleSubmit(firstFormSubmit)">
        <div class="row justify-content-center">
          <div class="col-8">
            <h1 class="display-4">Revisão da Vaga</h1>
          </div>
        </div>
        <!-- Informações Gerais -->
        <div class="row justify-content-center mt-3">
          <div class="col-8">
            <!-- Cargo (titulo) / URL -->
            <p>Cargo: <strong>{{propVaga.nm_cargo}}</strong><br>
            URL amigável: https://<strong>vagas</strong>.matchjob.com.br/vagas/<strong>{{propVaga.hl_link}}</strong></p>
            <!-- Area de Atuação / Nível Hierarquico / Solicitante / Departamento -->
            <p>Área: <strong>{{retornaAreaAtuacao(propVaga.nm_area)}}</strong> - Posição: <strong>{{retornaNivelHierarquico(propVaga.nm_nivel)}}</strong><br>
            Solicitante: <strong>{{propVaga.nm_solicitante}}</strong> - Departamento: <strong>{{propVaga.nm_depto}}</strong></p>
            <!-- Data de Abertura / Data de Fechamento / Tags -->
            <p>Data de Abertura: <strong>{{propVaga.dt_abertura | formatDate }}</strong> - Data de Fechamento: <strong>{{propVaga.dt_prev_fechamento | formatDate}}</strong></p>
            <base-button type="info" size="sm" @click="modal.visualizarVaga = true">Visualizar a Vaga</base-button>
          </div>
        </div>
        <!-- Divisor -->
        <div class="row justify-content-center">
          <div class="col-8">
            <hr class="mt-4 mb-1">
          </div>
        </div>
        <!-- Inicio das configs de Workflow -->
        <!-- Verifica a existencia de mais de 1 workflow -->
        <template v-if="propProcessoSelecao.length > 1">
          <!-- Seleção do Workflow da vaga -->
          <div class="row justify-content-center mt-3">
            <div class="col-4">
              <label for="workflow">Selecione o Workflow para esta vaga <span class="text-danger">*</span></label>
              <el-select
                ref="workflow"
                name="Workflow"
                class="inuptAutocomplete"
                id="workflow"
                v-model="processoVaga.id"
                @change="alteraProcesso"
              >
                <el-option
                  v-for="proc in propProcessoSelecao"
                  :key="proc.id"
                  :value="proc.id"
                  :label="proc.nm_nome"
                ></el-option>
              </el-select>
            </div>
            <!-- Valida o plano de aprovação de vagas -> ps.fluxoVagas -->
            <template v-if="propPlano.ps.fluxoVagas">
              <div class="col-4 align-items-end align-self-end text-center">
                <p class="font-weight-normal" v-if="processoVaga.requerAprovacao">
                  <small>Este processo requer aprovação antes da publicação da vaga.</small>
                </p>
                <p class="font-weight-normal" v-else>
                  <small>Este processo não requer aprovação antes da publicação da vaga.</small>
                </p>
              </div>
            </template>
            <template v-else>
              <div class="col-4 align-items-end align-self-end text-center">
                <p class="font-weight-normal">
                  <small>Este processo não requer aprovação antes da publicação da vaga.</small>
                </p>
              </div>
            </template>
          </div>
          <!-- Plano permite o fluxo de vagas -->
          <template v-if="propPlano.ps.fluxoVagas">
            <div class="row justify-content-center mt-3" v-if="processoVaga.requerAprovacao">
              <div class="col-4">
                <p class="font-weight-normal">
                  Aprovação do RH: <br>
                  <strong>
                    <template v-if="processoVaga.aprovacaoTipo.tipoRh === 'area'">
                      {{retornaTipoRh(processoVaga.aprovacaoTipo.tipoAcesso)}}
                    </template>
                    <template v-else>
                      {{retornoNomeUsuarioAprovador(processoVaga.aprovacaoTipo.usuario)}}
                    </template>
                  </strong>
                </p>
              </div>
              <div class="col-4">
                Aprovação do Solicitante:<br>
                <strong>
                  <template v-if="processoVaga.solicitanteAprova">
                    {{propVaga.nm_solicitante}}
                  </template>
                  <template v-else>
                    Não é necessária aprovação do solicitante.
                  </template>
                </strong>
              </div>
            </div>
            <!-- Linha de adição de novos aprovadores da vaga -->
            <div class="row justify-content-center mt-3">
              <!-- Flag de inclusão de novos aprovadores -->
              <div class="col-4 align-items-center">
                <label for="incluiOutrosAprovadores">Deseja incluir aprovadores para esta vaga?</label><br>
                <base-switch
                  id="incluiOutrosAprovadores"
                  :disabled="processoVaga.outrosAprovadores.length > 0"
                  v-model="processoVaga.flOutrosAprovadores"
                ></base-switch>
              </div>
              <!-- Select de usuários -->
              <div class="col-3 align-items-center">
                <label for="outrosAprovadores">Selecione o usuário <span class="text-danger">*</span></label>
                <el-select
                  ref="outrosAprovadores"
                  name="outrosAprovadores"
                  class="inuptAutocomplete"
                  id="outrosAprovadores"
                  :disabled="!processoVaga.flOutrosAprovadores"
                  v-model="addAprovadores"
                >
                  <el-option
                    v-for="user in propUsuarios"
                    :key="user.id_empresa_user"
                    :value="user.id_empresa_user"
                    :label="user.nm_nome"
                  ></el-option>
                </el-select>
              </div>
              <!-- Botão add -->
              <div class="col-1 align-items-end align-self-end px-0">
                <base-button
                  type="default"
                  size="sm"
                  :disabled="!processoVaga.flOutrosAprovadores"
                  @click="addOutroAprovador"><i class="fas fa-plus"></i></base-button>
              </div>
            </div>
            <!-- Linhas de Apresentação dos Aprovadores Adicionados -->
            <div class="row justify-content-center mt-3" v-if="processoVaga.outrosAprovadores.length > 0">
              <div class="col-8 align-items-center">
                <p class="font-weight-normal">Aprovadores adicionais:</p>
                <ul class="list-unstyled">
                  <li v-for="user in processoVaga.outrosAprovadores" :key="user.id_empresa_user">
                    <el-tooltip placement="left" content="Remover" effect="light">
                      <i class="text-danger fas fa-trash-alt" @click="removeOutroAprovador(user.id_empresa_user)"></i>
                    </el-tooltip> -
                    {{user.nm_nome}} -
                    <span class="text-muted"><small>{{user.nm_cargo}}</small></span> -
                    <small>{{user.hl_email}}</small>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </template>
        <!-- Caso exista apenas 1 Workflow -->
        <template v-else>
          <!-- Valida o plano de aprovação de vagas -> ps.fluxoVagas -->
          <template v-if="propPlano.ps.fluxoVagas">
            <div class="row justify-content-center mt-3" v-if="processoVaga.requerAprovacao">
              <div class="col-4">
                <p class="font-weight-normal">
                  Aprovação do RH: <br>
                  <strong>
                    <template v-if="processoVaga.aprovacaoTipo.tipoRh === 'area'">
                      {{retornaTipoRh(processoVaga.aprovacaoTipo.tipoAcesso)}}
                    </template>
                    <template v-else>
                      {{retornoNomeUsuarioAprovador(processoVaga.aprovacaoTipo.usuario)}}
                    </template>
                  </strong>
                </p>
              </div>
              <div class="col-4">
                Aprovação do Solicitante:<br>
                <strong>
                  <template v-if="processoVaga.solicitanteAprova">
                    {{propVaga.nm_solicitante}}
                  </template>
                  <template v-else>
                    Não é necessária aprovação do solicitante.
                  </template>
                </strong>
              </div>
            </div>
            <!-- Linha de adição de novos aprovadores da vaga -->
            <div class="row justify-content-center mt-3">
              <!-- Flag de inclusão de novos aprovadores -->
              <div class="col-4 align-items-center">
                <label for="incluiOutrosAprovadores">Deseja incluir aprovadores para esta vaga?</label><br>
                <base-switch
                  id="incluiOutrosAprovadores"
                  :disabled="processoVaga.outrosAprovadores.length > 0"
                  v-model="processoVaga.flOutrosAprovadores"
                ></base-switch>
              </div>
              <!-- Select de usuários -->
              <div class="col-3 align-items-center">
                <label for="outrosAprovadores">Selecione o usuário <span class="text-danger">*</span></label>
                <el-select
                  ref="outrosAprovadores"
                  name="outrosAprovadores"
                  class="inuptAutocomplete"
                  id="outrosAprovadores"
                  :disabled="!processoVaga.flOutrosAprovadores"
                  v-model="addAprovadores"
                >
                  <el-option
                    v-for="user in propUsuarios"
                    :key="user.id_empresa_user"
                    :value="user.id_empresa_user"
                    :label="user.nm_nome"
                  ></el-option>
                </el-select>
              </div>
              <!-- Botão add -->
              <div class="col-1 align-items-end align-self-end px-0">
                <base-button
                  type="default"
                  :disabled="!processoVaga.flOutrosAprovadores"
                  size="sm"
                  @click="addOutroAprovador"><i class="fas fa-plus"></i></base-button>
              </div>
            </div>
            <!-- Linhas de Apresentação dos Aprovadores Adicionados -->
            <div class="row justify-content-center mt-3" v-if="processoVaga.outrosAprovadores.length > 0">
              <div class="col-8 align-items-center">
                <p class="font-weight-normal">Aprovadores adicionais:</p>
                <ul class="list-unstyled">
                  <li v-for="user in processoVaga.outrosAprovadores" :key="user.id_empresa_user">
                    <el-tooltip placement="left" content="Remover" effect="light">
                      <i class="text-danger fas fa-trash-alt" @click="removeOutroAprovador(user.id_empresa_user)"></i>
                    </el-tooltip> -
                    {{user.nm_nome}} -
                    <span class="text-muted"><small>{{user.nm_cargo}}</small></span> -
                    <small>{{user.hl_email}}</small>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-center mt-3">
              <div class="col-8 align-items-center">
                Este plano não tem fluxo de aprovação de vagas
              </div>
            </div>
          </template>

        </template>
        <div class="row justify-content-center mb-2 mt-5">
          <div class="col-8 text-center">
            <template v-if="processoVaga.requerAprovacao || processoVaga.flOutrosAprovadores">
              <h1 class="display-4">A vaga será enviada para as aprovações.</h1>
            </template>
            <template v-else>
              <h1 class="display-4">A vaga está pronta para ser publicada.</h1>
            </template>
          </div>
        </div>
        <div class="row justify-content-center my-2">
          <div class="col-2 text-center">
            <base-button type="secondary" @click="voltarStep8">Voltar</base-button>
          </div>
          <div class="col-2 text-center">
            <base-button type="success" native-type="submit" :disabled="processoVaga.flOutrosAprovadores && processoVaga.outrosAprovadores.length < 1">Confirmar</base-button>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-3">
            processoVaga -> <pre>{{processoVaga}}</pre>
          </div>
        </div> -->
      </form>
    </validation-observer>
    <!-- testes -> <pre>{{testes}}</pre> -->
    <modal :show.sync="modal.visualizarVaga" size="xl">
      <template v-slot:header>
        <h6 class="modal-title">Pré Visualização da Vaga</h6>
      </template>
      <!-- Informações Gerais -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <!-- Cargo (titulo) / URL -->
          <p>Cargo: <strong>{{propVaga.nm_cargo}}</strong><br>
          URL amigável: https://<strong>vagas</strong>.matchjob.com.br/vagas/<strong>{{propVaga.hl_link}}</strong></p>
          <!-- Area de Atuação / Nível Hierarquico / Solicitante / Departamento -->
          <p>Área: <strong>{{retornaAreaAtuacao(propVaga.nm_area)}}</strong> - Posição: <strong>{{retornaNivelHierarquico(propVaga.nm_nivel)}}</strong><br>
          Solicitante: <strong>{{propVaga.nm_solicitante}}</strong> - Departamento: <strong>{{propVaga.nm_depto}}</strong></p>
          <!-- Data de Abertura / Data de Fechamento / Tags -->
          <p>Data de Abertura: <strong>{{propVaga.dt_abertura | formatDate }}</strong> - Data de Fechamento: <strong>{{propVaga.dt_prev_fechamento | formatDate}}</strong><br>
          Tags: <template v-if="propVaga.json_tags.length > 0">
            <badge
              v-for="tag in propVaga.json_tags"
              :key="tag"
              type="default"
              class="text-white m-1"
              size="sm">{{tag}}</badge>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhuma tag definida</em></small>
          </template>
          </p>
        </div>
      </div>
      <!-- Flag Deficientes / Salario / Tipo Contrato / Jornada -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <p>Esta vaga pode ser preenchida por Pessoa com Deficiência? <strong>{{propVaga.fl_deficientes ? 'Sim' : 'Não'}}</strong><br>
          Salário: <strong>R$ {{propVaga.nu_salario}}</strong> - Oculta o salário? <strong>{{propVaga.fl_oculta_salario ? 'Sim' : 'Não'}}</strong><br>
          Tipo de contrato: <strong>{{retornaTiposContrato(propVaga.fl_contratacao)}}</strong>
            - Período: <strong>{{retornaJornadas(propVaga.nm_turno_trabalho)}}</strong></p>
        </div>
      </div>
      <!-- Descrição da Vaga -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <p>Descrição da Vaga: <br><span v-html="propVaga.tx_descricao"></span></p>
        </div>
      </div>
      <!-- Requisitos da Vaga -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <p>Requisitos da Vaga: <br><span v-html="propVaga.tx_requisitos"></span></p>
        </div>
      </div>
      <!-- Flag Vídeo / Tipo do vídeo / Link do vídeo -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <p>Esta vaga pode possui vídeo explicativo? <strong>{{propVaga.fl_video ? 'Sim' : 'Não'}}</strong> - Origem do vídeo: <strong>{{retornaTipoVideo(propVaga.fl_tipo_video)}}</strong></p>
          <template v-if="showPreYoutube">
            <!-- Youtube Embed -->
            <LazyYoutube
              :src="hl_video_youtube"
              :showTitle="false"
              max-width="720px"
              aspect-ratio="16:9"
              thumbnail-quality="standard"
            />
          </template>
          <template v-else-if="showPreVimeo">
            <!-- Vimeo Embed -->
            <LazyVimeo
              :src="hl_video_vimeo"
              :showTitle="false"
              max-width="720px"
              aspect-ratio="16:9"
              thumbnail-quality="standard"
            />
          </template>
        </div>
      </div>
      <!-- Step 4 - Local da Vaga -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <!-- Empresa Contratante / Flag ocultar empresa / Local da vaga / Cidade/Estado / Modo de trabalho -->
          <p>Empresa Contratante: <strong>{{propVaga.nm_empresa}}</strong>
              - Oculta o nome da empresa? <strong>{{ propVaga.fl_oculta_empresa ? 'Sim' : 'Não' }}</strong><br>
            Local da vaga: <strong>{{retornaLocalVaga(propVaga.id_empresa_ender)}}</strong>
              - Cidade/Estado: <strong>{{propVaga.nm_cidade}} - {{propVaga.nm_pais}} </strong><br>
            Modo de trabalho: <strong>{{retornaModoTrabalho(propVaga.fl_tipo_trabalho)}}</strong>
          </p>
        </div>
      </div>
      <!-- Step 5 - Benefícios -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <!-- Benefícios -->
          <p>Benefícios: <template v-if="propVaga.json_beneficios.length > 0">
            <ul>
              <li v-for="item in propVaga.json_beneficios" :key="item"><strong>{{item}}</strong></li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhum benefício definido para esta vaga</em></small>
            </template>
          </p>
        </div>
      </div>
      <!-- Step 6 - Teste -->
      <div class="row justify-content-center mt-3" v-if="testes.length > 0">
        <div class="col-8">
          <!-- Teste -->
          <p>Teste a ser aplicado na candidatura: <template v-if="propVaga.id_teste !== ''">
            <strong>{{testes.nm_nome}}</strong><br>
            Perguntas:
            <ul>
              <li v-for="item in testes.json_questoes.questions" :key="item.id"><strong>{{item.value}}</strong></li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhum teste definido para esta vaga</em></small>
            </template>
          </p>
        </div>
      </div>
      <!-- Step 7 - Tempo de experiência requerido -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <!-- Tempo de Experiência -->
          <p>Tempo de experiência no cargo: <strong>{{propVaga.nm_cargo}}</strong><br>
            <span v-html="formataExperiencia(propVaga.json_nivelexperiencia.nivelCargo)"></span>
          </p>
          <!-- Tempo de Area -->
          <p>Tempo de experiência na área: <strong>{{retornaAreaAtuacao(propVaga.nm_area)}}</strong><br>
            <span v-html="formataExperiencia(propVaga.json_nivelexperiencia.nivelArea)"></span>
          </p>
          <!-- Tempo de Experiência -->
          <p>Tempo de experiência na posição: <strong>{{retornaNivelHierarquico(propVaga.nm_nivel)}}</strong><br>
            <span v-html="formataExperiencia(propVaga.json_nivelexperiencia.nivelHierarquico)"></span>
          </p>
        </div>
      </div>
      <!-- Step 7 - Requisitos implicitos -->
      <div class="row justify-content-center mt-3">
        <div class="col-8">
          <!-- Idiomas -->
          <p>Idiomas requeridos: <template v-if="propVaga.json_idiomas.length > 0">
            <ul>
              <li v-for="item in propVaga.json_idiomas" :key="item.id"><strong>{{item.value}} - {{retornaNivelIdioma(item.nivel)}}</strong></li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhum idioma definido para esta vaga</em></small>
            </template>
          </p>
          <!-- Competências -->
          <p>Competências requeridas: <template v-if="propVaga.json_competencias.length > 0">
            <ul>
              <li v-for="item in propVaga.json_competencias" :key="item.id"><strong>{{item.value}} - {{retornaNivelCompetencia(item.nivel)}}</strong></li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhuma competência definida para esta vaga</em></small>
            </template>
          </p>
          <!-- Certificações -->
          <p>Certificações requeridas: <template v-if="propVaga.json_certificacao.length > 0">
            <ul>
              <li v-for="item in propVaga.json_certificacao" :key="item.id"><strong>{{item.value}}</strong></li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhuma certificação definida para esta vaga</em></small>
            </template>
          </p>
          <!-- Formações -->
          <p>Formações requeridas: <template v-if="propVaga.json_formacao.length > 0">
            <ul>
              <li v-for="item in propVaga.json_formacao" :key="item.id"><strong>{{item.value}}</strong> - {{retornaTipoFormacao(item.tipoFormacao)}} - Concluído? {{item.status ? 'Sim' : 'Não'}}</li>
            </ul>
            </template>
            <template v-else>
              <small class="text-muted"><em>Nenhuma formação definida para esta vaga</em></small>
            </template>
          </p>
        </div>
      </div>
      <div slot="footer">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <base-button type="dark" @click="modal.visualizarVaga = false">Fechar</base-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { Select, Option, Loading, Autocomplete } from 'element-ui';
import moment from "moment";
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
import swal from 'sweetalert2';

Vue.use(Loading.directive);

export default {
  name: "Step8",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    LazyYoutube,
    LazyVimeo
  },
  props: {
    concluiStep8: { type: Function },
    voltarStep8: { type: Function },
    propVaga: { type: Object },
    propListaEnderecos: { type: Array },
    propTestes: { type: Array },
    propSysInfos: { type: Object },
    propProcessoSelecao: { type: Array },
    propUsuarios: { type: Array },
    propPlano: { type: Object }
  },
  data() {
    return {
      modal: {
        visualizarVaga: false
      },
      processoVaga: {
        id: "",
        requerAprovacao: true,
        solicitanteAprova: true,
        aprovacaoTipo: {},
        flOutrosAprovadores: false,
        outrosAprovadores: [],
        fl_status: "1"
      },
      addAprovadores: "",
      vaga: {},
      loading: false,
      idiomas: [],
      competencias: [],
      certificacoes: [],
      formacoes: [],
      deptos: [],
      cargos: [],
      testes: [],
      nivelIdioma: [
        { value: "1", text: "Básico" },
        { value: "2", text: "Intermediário" },
        { value: "3", text: "Avançado" },
        { value: "4", text: "Fluente" }
      ],
      nivelCompetencia: [
        { value: "1", text: "Júnior" },
        { value: "2", text: "Pleno" },
        { value: "3", text: "Sênior" },
        { value: "4", text: "Especialista" }
      ],
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "0", text: "Nível Hierárquico" },
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
      tiposcontrato: [
        { value: "1", text: "Autônomo" },
        { value: "2", text: "Cooperado" },
        { value: "3", text: "Efetivo (CLT)" },
        { value: "4", text: "Estágio" },
        { value: "5", text: "Outros" },
        { value: "6", text: "Prestador de Serviços (PJ)" },
        { value: "7", text: "Temporário" },
        { value: "8", text: "Trainee" }
      ],
      modoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Hibrido" },
        { value: "3", text: "HomeOffice" },
      ],
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
      showPreYoutube: false,
      showPreVimeo: false,
      hl_video_youtube: '',
      hl_video_vimeo: '',
    }
  },
  methods: {
    async firstFormSubmit() {
      if (this.processoVaga.requerAprovacao || this.processoVaga.flOutrosAprovadores) {
        this.processoVaga.fl_status = "1";
      } else {
        this.processoVaga.fl_status = "3"
      }
      this.$emit('resStep8', this.processoVaga);
      this.concluiStep8();
    },
    initValues() {
      this.loading = true;
      setTimeout(() => {
        if (this.propVaga.fl_video) {
          this.preVisualizaVideo(this.propVaga.fl_tipo_video, this.propVaga.hl_video);
        }
        if (this.propVaga.id_teste !== ''){
          this.carregaTestes(this.propVaga.id_teste);
        }
        this.idiomas = this.propSysInfos.idiomas;
        this.competencias = this.propSysInfos.competencias;
        this.certificacoes = this.propSysInfos.certificacoes;
        this.formacoes = this.propSysInfos.formacoes;
        this.deptos = this.propSysInfos.deptos;
        this.cargos = this.propSysInfos.cargos;
        var idProcessoPadrao = structuredClone(this.propProcessoSelecao.find(i => i.fl_default === true));
        this.processoVaga.id = idProcessoPadrao.id;
        var publicacaoVaga = idProcessoPadrao.fases.find(p => p.tipo === 'publicacaoVaga');
        this.processoVaga.requerAprovacao = publicacaoVaga.configs.requerAprovacao;
        this.processoVaga.solicitanteAprova = publicacaoVaga.configs.solicitanteAprova;
        this.processoVaga.aprovacaoTipo = publicacaoVaga.configs.aprovacaoTipo;
        /* if (this.propPlano.ps.fluxoVagas) {
          this.processoVaga.requerAprovacao = publicacaoVaga.configs.requerAprovacao;
          this.processoVaga.solicitanteAprova = publicacaoVaga.configs.solicitanteAprova;
          this.processoVaga.aprovacaoTipo = publicacaoVaga.configs.aprovacaoTipo;
        } else {
          this.processoVaga.requerAprovacao = false;
          this.processoVaga.solicitanteAprova = false;
          this.processoVaga.aprovacaoTipo = {
            tipoRh: "",
            usuario: "",
            tipoAcesso: ""
          }
        } */
        this.loading = false;
      }, 500)
    },
    alteraProcesso(value) {
      var selected = this.propProcessoSelecao.find(p => p.id === value);
      var fases = selected.fases.find(f => f.tipo === 'publicacaoVaga');
      this.processoVaga.id = value;
      this.processoVaga.requerAprovacao = fases.configs.requerAprovacao;
      this.processoVaga.solicitanteAprova = fases.configs.solicitanteAprova;
      this.processoVaga.aprovacaoTipo = fases.configs.aprovacaoTipo;
    },
    retornoNomeUsuarioAprovador(id) {
      //console.log('id -> ', id)
      //console.log('propUsuarios', this.propUsuarios)
      if (this.propUsuarios.length > 0 && id) {
        var user = this.propUsuarios.find(u => u.id_empresa_user === id)
        return user.nm_nome;
      } else {
        return "";
      }
    },
    addOutroAprovador() {
      this.loading = true;
      var user = this.propUsuarios.find(u => u.id_empresa_user === this.addAprovadores)
      //Valida se o usuário a ser adicionado já é aprovador do RH
      if (this.processoVaga.requerAprovacao === true && this.processoVaga.aprovacaoTipo.tipoRh === 'usuario' && this.processoVaga.aprovacaoTipo.usuario === user.id_empresa_user) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
        this.loading = false;
      } /* else if (this.processoVaga.requerAprovacao === true && this.processoVaga.aprovacaoTipo.tipoRh === 'area' && this.processoVaga.aprovacaoTipo.tipoAcesso === user.fl_tipo_acesso) {
        console.log('entrou no segundo if ->>>>')
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
        this.loading = false;
      } */ else if (this.processoVaga.solicitanteAprova === true && this.propVaga.nm_solicitante === user.nm_nome) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
        this.loading = false;
      } else if (this.processoVaga.outrosAprovadores.findIndex(i => i.id_empresa_user === user.id_empresa_user) > -1) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
        this.loading = false;
      } else {
        var newAprovador = {
          id_empresa_user: user.id_empresa_user,
          nm_nome: user.nm_nome,
          hl_email: user.hl_email,
          nm_cargo: user.nm_cargo
        }
        setTimeout(() => {
          this.processoVaga.outrosAprovadores.push(newAprovador);
          this.addAprovadores = "";
          this.loading = false;
        }, 500);
      }
    },
    removeOutroAprovador(id) {
      this.loading = true;
      var pos = this.processoVaga.outrosAprovadores.findIndex(i => i.id_empresa_user === id);
      this.processoVaga.outrosAprovadores.splice(pos, 1);//Remove o Objeto do Array
      var tam = this.processoVaga.outrosAprovadores.length;//Verifica o tamanho do array após a remoção
      if (tam < 1) {//valida se é o último do array
        this.processoVaga.flOutrosAprovadores = false;//Desativa o flag de outros aprovadores > Último usuário do array
        //this.ajustaAprovacaoByPlano(); //Ajusta o flag requerAprovacao conforme o workflow
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    ajustaAprovacaoByPlano() {
      var selected = this.propProcessoSelecao.find(p => p.id === this.processoVaga.id);
      var fases = selected.fases.find(f => f.tipo === 'publicacaoVaga');
      this.processoVaga.requerAprovacao = fases.configs.requerAprovacao;
    },
    retornaNivelIdioma(value) {
      var res = this.nivelIdioma.find(i => i.value === value);
      return res.text;
    },
    retornaNivelCompetencia(value) {
      var res = this.nivelCompetencia.find(i => i.value === value);
      return res.text;
    },
    retornaAreaAtuacao(value) {
      var res = this.areasatuacao.find(i => i.value === value);
      return res.text;
    },
    retornaNivelHierarquico(value) {
      var res = this.niveishierarquicos.find(i => i.value === value);
      return res.text;
    },
    retornaTiposContrato(value) {
      var res = this.tiposcontrato.find(i => i.value === value);
      return res.text;
    },
    retornaJornadas(value) {
      var res = this.jornadas.find(i => i.value === value);
      return res.text;
    },
    retornaLocalVaga(value) {
      var res = this.propListaEnderecos.find(i => i.id_empresa_ender === value);
      return res.nm_nome_endereco;
    },
    retornaModoTrabalho(value) {
      var res = this.modoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoVideo(value) {
      if (value === '1'){
        return 'YouTube';
      } else if (value === '2'){
        return 'Vimeo';
      } else {
        return 'Nenhum vídeo selecionado'
      }
    },
    retornaTipoRh(value) {
      var res = this.tipoAcesso.find(i => i.value === value);
      return res.text;
    },
    retornaTipoFormacao(value) {
      var res = this.sysformacoes.find(i => i.id === value);
      return res.text;
    },
    preVisualizaVideo(value, link) {
      if (value === '1' && link !== '') {
        this.hl_video_youtube = link;
        this.showPreYoutube = true;
      } else if (value === '2' && link !== '') {
        this.hl_video_vimeo = link;
        this.showPreVimeo = true;
      }
    },
    carregaTestes(id) {
      this.testes = this.propTestes.find(i => i.id_teste === id);
    },
    formataExperiencia(arr) {
      if (arr[1] === 0) {
        return "<strong>Nenhuma experiência requerida</strong>";
      } else if(arr[0] === arr[1]) {
        return `Experiência de <strong>${arr[0]} anos</strong>`;
      } else {
        return `Experiência de <strong>${arr[0]} a ${arr[1]} anos</strong>`;
      }
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.initValues();
    //this.loadSysInfos();
  },
  watch: {}
}
</script>

<style>
p {
  margin-bottom: 0.3rem;
}
</style>
