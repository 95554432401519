<template>
  <div>
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <template slot="header">
        <h3 class="mb-0">Lista de Teste</h3>
      </template>
      <div class="card-body">
        <div>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="form-check form-check-inline">
              <base-input v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Procurar...">
              </base-input>
            </div>
          </div>
          <el-table :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @sort-change="sortChange">
            <el-table-column min-width="300" sortable prop="nm_nome" label="Nome">
              <div slot-scope="{ row }">
                <strong>{{ row.nm_nome}}</strong><br>
                {{ row.nm_descricao }}
              </div>
            </el-table-column>
            <el-table-column
              min-width="150"
              sortable
              prop="nm_tipo"
              label="Tipo"
              :formatter="defineTipoTabela">
            </el-table-column>
            <el-table-column min-width="200" sortable prop="nm_nome_user" label="Criado por">
              <div slot-scope="{ row }">
                {{ row.nm_nome_user}}<br>
                em {{ row.created_at | formatDateTime }}
              </div>
            </el-table-column>
            <el-table-column min-width="200" sortable prop="json_tags" label="Tags">
              <div slot-scope="{ row }">
                <span
                  v-for="tag in row.json_tags.tags" :key="tag"
                  class="el-tag el-tag--info el-tag--mini el-tag--plain m-1">{{tag}}</span>
              </div>
            </el-table-column>
            <el-table-column>
              <div slot-scope="{ row }">
                <base-button
                  type="primary"
                  size="sm"
                  @click="showTesteDetalhes(row)"
                ><i class="ni ni-zoom-split-in"></i></base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
        <div class="">
          <p class="card-category">
            Mostrando {{ from + 1 }} de {{ to }} em {{ total }} testes.<br>
          </p>
        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import testesPaginacao from './testesPaginacao';
import { db } from "../../firebase/config";
import {
  addDoc,
  setDoc,
  doc,
  Timestamp,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  orderBy
} from "firebase/firestore";
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option,
} from 'element-ui';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import { BasePagination } from '@/components';
import moment from "moment";
import swal from 'sweetalert2';

Vue.use(Loading.directive);

export default {
  name: "ListaTestesResumo",
  mixins: [defaultMatchjob, testesPaginacao],
  components: {
    BasePagination,
    BaseInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: false,
      testes: [],
      newTestFinal: {
        id_teste: "",
        id_empresa: "",
        id_empresa_user: "",
        nm_nome_user: "",
        nm_tipo: "",
        nu_tempo: "0",
        nm_nome: "",
        nm_categoria: "",
        nm_descricao: "",
        hl_url: "",
        json_questoes: {},
        json_tags: {tags: []}
      },
      newTest: [],
      newQuestion: {
        id: null,
        type: "",
        value: "",//a pergunta/texto apresentado na pergunta
        defined: '',
        options: [],
        required: true,
        //next: undefined
      },
      questionTypes: [
        { value: 'text', text: 'Texto simples', disabled: false },
        { value: 'dico', text: 'Dicotômica', disabled: false },
        { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
        { value: 'multi', text: 'Múltipla escolha', disabled: false },
        { value: 'likert', text: 'Matriz Likert', disabled: false },
        { value: 'nps', text: 'NPS', disabled: false }
      ],
      dicoSelectType: "1",
      dicoDefinedTypes: [
        { id: "1", name: 'Sim ou Não', textTrue: 'Sim', textFalse: 'Não' },
        { id: "2", name: 'Verdadeiro ou Falso', textTrue: 'Verdadeiro', textFalse: 'Falso' },
        { id: "3", name: 'Concordo ou Discordo', textTrue: 'Concordo', textFalse: 'Discordo' },
      ],
      dicoTemp: {
        textTrue: '',
        textFalse: ''
      },
      ruTemp: [],
      ruAdd: '',
      flCorrect: false,
      likertTemplates: [
        { id: 1, value: 'concordancia', text: 'Concordância' },
        { id: 2, value: 'avaliativa', text: 'Avaliativa' },
        { id: 3, value: 'frenquencia', text: 'Frenquência' },
        { id: 4, value: 'probabilidade', text: 'Probabilidade' },
        { id: 5, value: 'importancia', text: 'Importância' },
        { id: 6, value: 'satisfacao', text: 'Satisfação' },
      ],
      likertSelected: "",
      likertDefined: {
        concordancia: [
          { id: 1, value: 1, text: 'Discordo Totalmente' },
          { id: 2, value: 2, text: 'Discordo' },
          { id: 3, value: 3, text: 'Neutro' },
          { id: 4, value: 4, text: 'Concordo' },
          { id: 5, value: 5, text: 'Concordo Totalmente' },
        ],
        avaliativa: [
          { id: 1, value: 1, text: 'Ruim' },
          { id: 2, value: 2, text: 'Razoável' },
          { id: 3, value: 3, text: 'Bom' },
          { id: 4, value: 4, text: 'Muito bom' },
          { id: 5, value: 5, text: 'Excelente' },
        ],
        frenquencia: [
          { id: 1, value: 1, text: 'Nunca' },
          { id: 2, value: 2, text: 'Raramente' },
          { id: 3, value: 3, text: 'Ocasionalmente' },
          { id: 4, value: 4, text: 'Frequente' },
          { id: 5, value: 5, text: 'Muito frequente' },
        ],
        probabilidade: [
          { id: 1, value: 1, text: 'Quase sempre falso' },
          { id: 2, value: 2, text: 'Geralmente falso' },
          { id: 3, value: 3, text: 'Ocasionalmente verdade' },
          { id: 4, value: 4, text: 'Geralmente verdade' },
          { id: 5, value: 5, text: 'Quase sempre verdade' },
        ],
        importancia: [
          { id: 1, value: 1, text: 'Não é importante' },
          { id: 2, value: 2, text: 'Às vezes importante' },
          { id: 3, value: 3, text: 'Moderado' },
          { id: 4, value: 4, text: 'Importante' },
          { id: 5, value: 5, text: 'Muito importante' },
        ],
        satisfacao: [
          { id: 1, value: 1, text: 'Muito insatisfeito' },
          { id: 2, value: 2, text: 'Insatisfeito' },
          { id: 3, value: 3, text: 'Neutro' },
          { id: 4, value: 4, text: 'Satisfeito' },
          { id: 5, value: 5, text: 'Muito satisfeito' },
        ],
        userDefinied: {
          muitonegativa: "",
          negativa: "",
          neutra: "",
          positiva: "",
          muitopositiva: ""
        }
      },
      nps: [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
      ],
      propsToSearch: ['nome', 'descricao', 'tags'],
      categoriaTestes: [
        { value: "Avaliativo" },
        { value: "Competências" },
        { value: "Comportamental" },
        { value: "Fit Cultural" },
        { value: "Técnico" },
      ],
      tipoTestes: [
        { value: 'simples', text: 'Simples' },
        { value: 'step', text: 'Simples - Step-by-Step' },
        { value: 'avaliacao', text: 'Avaliação Técnica' },
        { value: 'auto', text: 'Avaliação Técnica - Autocorreção' },
        { value: 'tempo', text: 'Aval. Téc. - Autocorreção - Temporizada' },
      ],
      modal: {
        novoteste: false,
        visualizartesteSimples: false,
        visualizarteste: false,
      }
    };
  },
  methods: {
    //Função de carregamento dos testes existentes
    async carregaTestesInt() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      await this.$http.get('testes/listatestes', { params: data
      }).then(res => {
        this.testes = res.data.testes;
      })
    },
    async carregaTestes() {
      const colRefTestes =
        "empresas/" +
        this.id.empresa +
        "/testes";
      const resTestes = collection(db, colRefTestes);
      const q = query(resTestes, orderBy('updated_at', 'desc'));
      await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.testes = docs;
      });
      //console.log('teste carregados do FS ->> ', this.testes);
    },
    /*
    * Inicio das função de gravação e atualização de testes no Firebase
    *
    * ## handleGravar() ##
    *  - Função de Validação dos campos e chamada do botão 'Gravar'
    *
    * ## addTest() ##
    * Função chamada pela 'handleGravar'
    * Ajusta a variável newTestFinal
    * Chama a função para gravar 'novo' ou 'atualiza' o Teste
    *
    * ## gravaTeste() ##
    * Função de gravação de novo teste
    *
    * ## updateTeste() ##
    * Função de atualização de teste
    */
    handleGravar(){
      if (this.newTestFinal.nome === '') {
        this.alertShow("Ops!", "Você precisa definir um nome para o teste!", "error");
        return false;
      } else if (this.newTestFinal.descricao === '') {
        this.alertShow("Ops!", "Você precisa definir uma descrição para o teste!", "error");
        return false;
      } else if (this.newTestFinal.categoria === '') {
        this.alertShow("Ops!", "Você precisa definir uma categoria para o teste!", "error");
        return false;
      } else if (this.newTestFinal.tipo === '') {
        this.alertShow("Ops!", "Você precisa definir um tipo de teste!", "error");
        return false;
      } else if (this.newTestFinal.tipo === 'tempo' && this.newTestFinal.tempo === '0' ) {
        this.alertShow("Ops!", "Você precisa definir o tempo correto para o teste!", "error");
        return false;
      } else if (this.newTest.length < 1) {
        this.alertShow("Ops!", "Você precisa criar as perguntas para o teste!", "error");
        return false;
      } else {
        this.addTest();
      }
    },
    async addTest() {
      this.newTestFinal.id_empresa_user = this.id.empresa_user;
      this.newTestFinal.nm_nome = this.id.nome;
      this.$set(this.newTestFinal, 'questions', this.newTest);
      if(this.newTestFinal.id === ""){
        this.newTestFinal.created_at = Timestamp.now();
        this.newTestFinal.updated_at = Timestamp.now();
        if (this.gravaTeste()) {
          this.modal.novoteste = false;
          this.loading = true;
          setTimeout(() => {
            this.carregaTestes();
            this.resetNewTestFinal();
            this.notificacao('Teste gravado com sucesso!', 'success');
            this.loading = false;
          }, 2000);
        } else {
          this.notificacao('Ops! Não foi possível gravar o teste.', 'danger');
          this.modal.novoteste = false;
        }
      } else {
        this.newTestFinal.updated_at = Timestamp.now();
        if (this.updateTeste()) {
          this.modal.novoteste = false;
          this.loading = true;
          setTimeout(() => {
            this.carregaTestes();
            this.resetNewTestFinal();
            this.notificacao('Teste atualizado com sucesso!', 'success');
            this.loading = false;
          }, 2000);
        } else {
          this.modal.novoteste = false;
          this.notificacao('Ops! Não foi possível gravar o teste.', 'danger');
        }
      }
    },
    async gravaTeste() {
      const colRefTestes =
        "empresas/" +
        this.id.empresa +
        "/testes";
      const resTestes = collection(db, colRefTestes);
      const res = await addDoc(resTestes, this.newTestFinal)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      return res;
    },
    async updateTeste() {
      const colRefTestes =
        "empresas/" +
        this.id.empresa +
        "/testes";
      const docRef = doc(db, colRefTestes, this.newTestFinal.id);
      await setDoc(docRef, this.newTestFinal)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        });
    },
    /*
    * Inicio das função de deleção de testes no Firebase
    *
    * ## handleDelete() ##
    * Função chamada pelo botão 'Deletar'
    * Emite um alerta (swal) e chama a função para deletar o teste no firestore
    *
    * ## deleteTeste() ##
    * Deleta o teste no Firestore
    */
    handleDelete(row) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir o teste:<br> ${row.nome}`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, excluir!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.deleteTeste(row.id)){
            this.loading = true;
            setTimeout(() => {
              this.alertShow('Excluido!', `O teste: ${row.nome} foi excluído!`, 'success');
              this.carregaTestes();
              this.loading = false;
            }, 2000);
          } else {
            this.alertShow('Ops!', 'Desculpe! Houve uma falha no servidor.', 'error');
          }
        }
      });
    },
    async deleteTeste(id) {
      const colRefTestes =
        "empresas/" +
        this.id.empresa +
        "/testes";
      const docRef = doc(db, colRefTestes, id);
      const res = await deleteDoc(docRef)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      return res;
    },
    /*
    * Funções de geração de URL amigável para o teste
    *
    * ## checkURL() ##
    * Verifica se a URL gerada já existe
    *
    * ## makeUrl() ##
    * Transforma o nome do teste em URL amigável
    *
    * ## defineUrl() ##
    * Chama a função de verificação de existencia e define a URL
    */
    async checkURL(url) {
      const colRefTestes =
        "empresas/" +
        this.id.empresa +
        "/testes";
      const resTestes = collection(db, colRefTestes);
      const q = query(resTestes, where('url', '==', url));
      const result = await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        return docs;
      });
      if (result.length > 0) {
        if (this.newTestFinal.id === result[0].id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    makeUrl(valor) {
      var str = valor.toLowerCase();
      var str1 = str.replace(/[áàãâä]/gi, 'a');
      var str2 = str1.replace(/[éèêë]/gi, 'e');
      var str3 = str2.replace(/[íìîï]/gi, 'i');
      var str4 = str3.replace(/[óòõôö]/gi, 'o');
      var str5 = str4.replace(/[úùûü]/gi, 'u');
      var str6 = str5.replace(/[ç]/gi, 'c');
      var result = str6.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '');
      return result;
    },
    defineUrl() {
      var url = this.makeUrl(this.newTestFinal.nome);
      if (this.checkURL(url)) {
        this.newTestFinal.url = url;
      } else {
        var chance1 = url + '-' + this.makeUrl(this.newTestFinal.categoria);
        if (this.checkURL(chance1)) {
          this.newTestFinal.url = chance1;
        } else {
           this.newTestFinal.url = url + '-' + this.newTestFinal.id;
        }
      }
    },
    /*
    * Funções de Edição de testes existente
    *
    * ## editTest(id) ##
    * Carrega o teste selecionado nos arrays 'newTestFinal' e 'newTest'
    * Abre a modal de edição do teste
    */
    editTest(id){
      const edit = this.testes.find(item => item.id === id)
      this.newTestFinal.id = edit.id;
      this.newTestFinal.id_empresa_user = edit.id_empresa_user;
      this.newTestFinal.created_at = edit.created_at;
      this.newTestFinal.descricao = edit.descricao;
      this.newTestFinal.nm_nome = edit.nm_nome;
      this.newTestFinal.nome = edit.nome;
      this.newTestFinal.tags = edit.tags;
      this.newTestFinal.tipo = edit.tipo;
      this.newTestFinal.categoria = edit.categoria;
      this.newTestFinal.url = edit.url;
      this.newTestFinal.tempo = edit.tempo;
      this.newTest = edit.questions;
      this.modal.novoteste = true;
      this.selectTipo(edit.tipo);
    },
    /*
    * Inicio das funções de perguntas
    *
    * ## validateQuestion() ##
    * Valida os campos das perguntas e retorna 'true' ou 'false'
    *
    * ## adjustNewQuestion() ##
    * Ajusta o array tempRes conforme o tipo da pergunta para ser incluido no array de perguntas 'newTest'
    *
    * ## addQuestion() ##
    * Função chamada pelos botões 'Adicionar' das perguntas
    * Define o ID da pergunta
    * Chama a função adjustNewQuestion()
    * Faz um 'push' no array de perguntas 'newTest'
    *
    * ## editQuestion(id) ##
    * Ajusta a pergunta para ser editada
    *
    * ## reAddQuestion() ##
    * Readiciona a pergunta na mesma posição anterior (ID original)
    *
    * ## resetNewQuestion() ##
    * Realiza um reset nos arrays utilizados na inserção/edição de perguntas
    *
    * ## removeQuestion(id) ##
    * Remove a pergunta do array 'newTest'
    *
    * ## addRuTemp() ##
    * Adiciona opções para perguntas Resposta única e Múltipla escolha
    *
    * ## delRuTemp(id) ##
    * Remove opções de perguntas Resposta única e Múltipla escolha
    */
    validateQuestion() {
      if (this.flCorrect === true && this.newQuestion.defined === '') {
        this.alertShow("Ops!", "Você precisa definir a resposta correta!", "error");
        return false;
      } else if (this.newQuestion.id !== null) {
        this.reAddQuestion();
        return false;
      } else if (this.newTest.findIndex( item => item.value === this.newQuestion.value ) > -1) {
        this.alertShow("Ops!", "Esta pergunta já foi incluída!", "error");
        return false;
      } else if (this.newQuestion.value === '') {
        this.alertShow("Ops!", "O Campo da pergunta precisa ser preenchido!", "error");
        return false;
      } else {
        return true
      }
    },
    adjustNewQuestion() {
      let tempRes = new Object;
      let arrOptions = new Array;
      if (this.newQuestion.type === 'text'){
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'dico'){
        if (this.dicoSelectType < 4) {
          const arrSelected = this.dicoDefinedTypes.filter( item => item.id === this.dicoSelectType);
          arrOptions = [
            { text: arrSelected[0].textTrue, value: true },
            { text: arrSelected[0].textFalse, value: false }
          ]
        } else {
          arrOptions = [
            { text: this.dicoTemp.textTrue, value: true },
            { text: this.dicoTemp.textFalse, value: false }
          ]
        }
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: arrOptions,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'ru') {
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: this.ruTemp,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'multi') {
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: this.ruTemp,
          resp: [],
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'likert'){
        if (this.likertSelected === 'concordancia') {
          arrOptions = this.likertDefined.concordancia;
        } else if (this.likertSelected === 'avaliativa') {
          arrOptions = this.likertDefined.avaliativa;
        } else if (this.likertSelected === 'frenquencia') {
          arrOptions = this.likertDefined.frenquencia;
        } else if (this.likertSelected === 'probabilidade') {
          arrOptions = this.likertDefined.probabilidade;
        } else if (this.likertSelected === 'importancia') {
          arrOptions = this.likertDefined.importancia;
        } else if (this.likertSelected === 'satisfacao') {
          arrOptions = this.likertDefined.satisfacao;
        } else if (this.likertSelected === 'personalizado') {
          arrOptions = [
            { id: 1, value: 1, text: this.likertDefined.userDefinied.muitonegativa },
            { id: 2, value: 2, text: this.likertDefined.userDefinied.negativa },
            { id: 3, value: 3, text: this.likertDefined.userDefinied.neutra },
            { id: 4, value: 4, text: this.likertDefined.userDefinied.positiva },
            { id: 5, value: 5, text: this.likertDefined.userDefinied.muitopositiva },
          ]
        }
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: arrOptions,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'nps'){
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          resp: '',
          required: this.newQuestion.required,
        }
      }
      return tempRes;
    },
    addQuestion() {
      if (this.validateQuestion()) {
        if (this.newTest.length > 0) {
          const lastItem = this.newTest.pop();
          let id = lastItem.id;
          this.newTest.push(lastItem);
          this.newQuestion.id = ++id;
        } else {
          this.newQuestion.id = 1;
        }
        let tempRes = this.adjustNewQuestion();
        this.newTest.push(tempRes);
        this.resetNewQuestion();
      }
    },
    editQuestion(id){
      var res = this.newTest.find( item => item.id === id);
      this.newQuestion.id = res.id;
      this.newQuestion.defined = res.defined;
      this.newQuestion.resp = res.resp;
      this.newQuestion.type = res.type;
      this.newQuestion.value = res.value;
      this.ruTemp = res.options;
      this.goto('perguntas');
      //this.modal.novoteste = true;
      //console.log('posicao recebido ->> ', res)
    },
    reAddQuestion() {
      var pos = this.newTest.findIndex( item => item.id === this.newQuestion.id);
      let tempRes = this.adjustNewQuestion();
      this.newTest.splice(pos, 1, tempRes);
      this.resetNewQuestion();
      this.notificacao('Pergunta atualizada!', 'success')
    },
    resetNewQuestion() {
      this.newQuestion.id = null;
      this.newQuestion.type = "";
      this.newQuestion.value = "";
      this.newQuestion.defined = "";
      this.newQuestion.options = [];
      this.newQuestion.required = true,
      this.dicoTemp.textTrue = "";
      this.dicoTemp.textFalse = "";
      this.ruTemp = [];
      this.flCorrect = this.newTestFinal.tipo === 'auto' ||this.newTestFinal.tipo === 'tempo' ? true : false;
      this.likertSelected = "";
      this.likertDefined.userDefinied.muitonegativa = "";
      this.likertDefined.userDefinied.negativa = "";
      this.likertDefined.userDefinied.neutra = "";
      this.likertDefined.userDefinied.positiva = "";
      this.likertDefined.userDefinied.muitopositiva = "";
    },
    removeQuestion(id) {
      var pos = this.newTest.findIndex( item => item.id === id);
      this.newTest.splice(pos, 1);
    },
    addRuTemp() {
      if (this.ruTemp.findIndex( item => item.value === this.ruAdd ) > -1) {
        this.alertShow("Ops!", "Esta opção já foi incluída!", "error");
        return false;
      }
      var size = this.ruTemp.length;
      var arr = { id: ++size, text: this.ruAdd, value: this.ruAdd }
      this.ruTemp.push(arr);
      this.ruAdd = "";
    },
    delRuTemp(id) {
      var pos = this.ruTemp.findIndex( item => item.id === id);
      this.ruTemp.splice(pos, 1);
    },
    /*
    * Funções de visualização de testes
    *
    * ## visualizarTest(id) ##
    * Carrega o teste selecionado nos arrays 'newTestFinal' e 'newTest'
    * Abre a modal de visualização do teste conforme o tipo do teste
    *
    * ## fechaVisualizacao() ##
    * Chama a função de reset dos array 'newTestFinal' e 'newTest'
    * Fecha a modal de visualização
    *
    * ## resetNewTestFinal() ##
    * Realiza um reset nos arrays utilizados na inserção/edição/visualização de testes
    */
    visualizarTest(id){
      const edit = this.testes.find(item => item.id === id)
      this.newTestFinal.id = edit.id;
      this.newTestFinal.id_empresa_user = edit.id_empresa_user;
      this.newTestFinal.created_at = edit.created_at;
      this.newTestFinal.descricao = edit.descricao;
      this.newTestFinal.nm_nome = edit.nm_nome;
      this.newTestFinal.nome = edit.nome;
      this.newTestFinal.tags = edit.tags;
      this.newTestFinal.tipo = edit.tipo;
      this.newTestFinal.categoria = edit.categoria;
      this.newTestFinal.url = edit.url;
      this.newTestFinal.tempo = edit.tempo;
      this.newTest = edit.questions;
      if (edit.tipo === 'simples'){
        this.modal.visualizartesteSimples = true;
      } else {
        this.modal.visualizarteste = true;
      }
    },
    fechaVisualizacao() {
      this.resetNewTestFinal();
      this.modal.visualizarteste = false;
      this.modal.visualizartesteSimples = false;
    },
    resetNewTestFinal() {
      this.newTestFinal.nome = "";
      this.newTestFinal.id = "";
      this.newTestFinal.descricao = "";
      this.newTestFinal.tipo = "";
      this.newTestFinal.categoria = "";
      this.newTestFinal.url = "";
      this.newTestFinal.tempo = "0";
      this.newTestFinal.tags = [];
      this.$delete(this.newTestFinal, 'questions');
      this.newTest = [];
      this.modal.novoteste = false;
    },
     //Função do Autocomplete de Categorias de testes
    querySearchCategoria(queryCategoria, cb) {
      let categoria = this.categoriaTestes;
      var results = queryCategoria ? categoria.filter(this.createFilterCategoria(queryCategoria)) : categoria;
      cb(results)
    },
    createFilterCategoria(queryCategoria) {
      return (categoria) => {
        return (categoria.value.toLowerCase().indexOf(queryCategoria.toLowerCase()) === 0);
      };
    },
    //Define as perguntas liberadas por tipo de teste
    selectTipo(value){
      if (value === 'avaliacao') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: false },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: true },
          { value: 'nps', text: 'NPS', disabled: true }
        ];
      } else if (value === 'auto' || value === 'tempo') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: true },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: true },
          { value: 'nps', text: 'NPS', disabled: true }
        ];
        this.flCorrect = true;
      } else if (value === 'simples' || value === 'step') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: false },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: false },
          { value: 'nps', text: 'NPS', disabled: false }
        ];
      }
    },
    /*
    * Funções da visualização das perguntas no inserção/edição de testes
    *
    * ## butUp(id) ##
    * Verifica se existe pergunta anterior e mostra o botão para subir
    *
    * ## butDown(id) ##
    * Verifica se existe pergunta posterior e mostra o botão para descer
    *
    * ## moveDown(id) ##
    * Desce a posição da pergunta selecionada
    *
    * ## moveUp(id) ##
    * Sobe a posição da pergunta selecionada
    *
    * ## validaMulti(defined, resp) ##
    * Mostra se a resposta esstá correta em perguntas Múltipla escolha
    * -> Só apresenta se marcada a opção correta
    *
    * ## validaRU(defined, resp) ##
    * Mostra se a resposta esstá correta em perguntas Resposta Única
    * -> Só apresenta se marcada a opção correta
    */
    butUp(id){
      var pos = this.newTest.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      //var pos = this.newTest.findIndex( item => item.id === id);
      if (this.newTest.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.newTest.findIndex( item => item.id === idNow);
      var pos1 = this.newTest.findIndex( item => item.id === idNext);
      var arr0 = this.newTest.find( item => item.id === idNow);
      var arr1 = this.newTest.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.newTest.splice(pos0, 1, arr1);
      this.newTest.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.newTest.findIndex( item => item.id === idNow);
      var pos1 = this.newTest.findIndex( item => item.id === idNext);
      var arr0 = this.newTest.find( item => item.id === idNow);
      var arr1 = this.newTest.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.newTest.splice(pos0, 1, arr1);
      this.newTest.splice(pos1, 1, arr0);
    },
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
    /*
    * Funções utilizadas na lista (El-Table) de testes carregados
    *
    * defineTipoTabela() -> Mostra o nome do Tipo do Teste
    * handleCommand() -> Rebece o click dos botões da tabela (Visualizar, Editar e Excluir) e chama as respectivas funções
    */
    defineTipoTabela(row, column){
      var tipo = row[column.property];
      var res = this.tipoTestes.find(i => i.value === tipo);
      return res.text;
    },
    handleCommand(command) {
      if (command[0] === 'visualizar'){
        this.visualizarTest(command[1])
      } else if (command[0] === 'editar') {
        this.editTest(command[1]);
      } else if (command[0] === 'excluir') {
        this.handleDelete(command[1]);
      }
    },
    /*
    * Funções gerais sistêmicas
    *
    * ## alertShow(title, msgError, icon) ##
    * Emite um alerta SweetAlert (swal)
    *
    * ## notificacao(msg, tipo) ##
    * Apresenta uma notificação Toasted
    *
    * ## goto(refName) ##
    * Função de auto-scroll da tela passando a referência do ID da DIV
    */
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    showTesteDetalhes(row) {
      let conteudo = "<h3>Perguntas:</h3>";
      row.json_questoes.questions.forEach(pergunta => {
        let linha = '<p>' + pergunta.value + '</p>';
        conteudo += linha;
      });
      //conteudo += '</ul>';
      swal.fire({
        width: 800,
        title: row.nome,
        html:  conteudo,
      })
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  filters: {
    formatDateUnixTime: function(value) {
      if (value) {
        return moment.unix(value.seconds).format("DD/MM/YYYY");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      }
    }
  },
  mounted() {
    this.carregaTestesInt();
  },
};
</script>
<style>
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
  .removeButton {
    cursor: pointer;
  }
  .icone-help-bread-crumb {
    color: #FFF;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .box-question {
    box-sizing: border-box;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    margin: 10px 5px;
    padding: 5px 5px 5px;
  }
</style>
