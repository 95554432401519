<template>
  <div>
    <!-- Inicio da listagem de usuários cadastrados -->
    <card class="no-border-card">
      <template slot="header">
        <h3 class="mb-0">Lista de Endereços</h3>
        <p class="text-sm mb-0">
          {{ total }} endereços cadastrados na MatchJob Empregos.
        </p>
      </template>
      <div>
        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div class="form-check form-check-inline">
            <base-input v-model="searchQuery"
              prepend-icon="fas fa-search"
              placeholder="Procurar...">
            </base-input>
          </div>
        </div>
        <el-table :data="queriedData"
          row-key="id_empresa_ender"
          header-row-class-name="thead-light"
          @sort-change="sortChange">
          <el-table-column min-width="150" sortable prop="nm_nome_endereco" label="Nome">
          </el-table-column>
          <el-table-column min-width="250" sortable prop="nm_endereco" label="Endereço">
            <div slot-scope="{ row }">
              <span>{{ row.nm_endereco}}, {{ row.nu_numero}}</span><br>
              {{ row.nm_complemento }}
            </div>
          </el-table-column>
          <el-table-column min-width="100" sortable prop="nm_bairro" label="Bairro">
          </el-table-column>
          <el-table-column min-width="100" sortable prop="nm_cidade" label="Cidade">
          </el-table-column>
          <el-table-column min-width="100" sortable prop="nm_estado" label="Estado">
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            Mostrando {{ from + 1 }} de {{ to }} em {{ total }} endereços.</p>

        </div>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import axios from 'axios';
import { userKey } from "@/global";
import { Table, TableColumn, Select, Option, Autocomplete } from 'element-ui';
import moment from "moment";
import { BasePagination } from '@/components';
import clientPaginationMixin from './enderecosPaginacao';
import swal from 'sweetalert2';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

export default {
  mixins: [clientPaginationMixin, defaultMatchjob],
  components: {
    BasePagination,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      loading: false,
      newEndereco: {
        id_empresa: "",
        nm_nome_endereco: "",
        nu_cep: "",
        nm_estado: "",
        nm_cidade: "",
        nm_bairro: "",
        nm_endereco: "",
        nu_numero: "",
        nm_complemento: "",
        fl_tipo_endereco: "3",
        fl_novo: true
      },
      enderecos: [],
      propsToSearch: ['nm_nome_endereco'],
      modal: {
        novoEndereco: false
      }
    };
  },
  methods: {
    async carregaEnderecos() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin,
        fl_tipo_acesso: this.id.tipoAcesso
      }
      await this.$http.post('enderecos', data).then(res => {
        this.enderecos = res.data.enderecos;
      })
    },
    enderecoMatriz(index, row){
      if (row.fl_tipo_endereco === "1"){
        return true;
      }
    },
    atualizaCep() {
      //Auto-preenchimento dos campos a partir do CEP
      const viacep = axios.create();
      viacep.defaults.headers.common = {};
      viacep.defaults.headers.common.accept = "application/json";
      //Verifica se campo cep possui valor informado.
      if (this.newEndereco.nu_cep != "") {
        //Deixa somente os digitos
        this.newEndereco.nu_cep = this.newEndereco.nu_cep.replace(/\D/g, "");
        //Expressão regular para validar somente numeros
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(this.newEndereco.nu_cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          this.newEndereco.nm_endereco = "...";
          this.newEndereco.nm_bairro = "...";
          this.newEndereco.nm_cidade = "...";
          this.newEndereco.nm_estado = "...";
          //Consulta o webservice viacep.com.br/
          viacep
            .get("//viacep.com.br/ws/" + this.newEndereco.nu_cep + "/json/")
            .then((dados) => {
              //Atualiza os campos com os valores da consulta.
              this.newEndereco.nm_endereco = dados.data.logradouro;
              this.newEndereco.nm_bairro = dados.data.bairro;
              this.newEndereco.nm_cidade = dados.data.localidade;
              this.newEndereco.nm_estado = dados.data.uf;
            });
        } else {
          //cep é inválido.
          this.limpa_formulario_cep();
          alert("Formato de CEP inválido.");
        }
      } else {
        //cep sem valor, limpa formulário.
        this.limpa_formulario_cep();
      }
    },
    limpa_formulario_cep() {
      this.newEndereco.nu_cep = "";
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir o local:<br> ${row.nm_nome_endereco}`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, excluir!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.deletar(row.id_empresa_ender)){
            swal.fire({
              title: 'Excluido!',
              text: `O local: ${row.nm_nome_endereco} foi excluído!`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.deleteRow(row);
          } else {
            swal.fire({
              title: 'Excluir!',
              text: 'Desculpe! Houve uma falha no servidor.',
              icon: 'error',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        }
      });
    },
    async deletar(id) {
      const data = {
        id_empresa_ender: id
      }
      await this.$http
        .post('enderecos/deletar', data)
        .then(() => {
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    handleEdit(index, row) {
      this.newEndereco.id_empresa_ender = row.id_empresa_ender;
      this.newEndereco.id_empresa = row.id_empresa;
      this.newEndereco.nm_nome_endereco = row.nm_nome_endereco;
      this.newEndereco.nu_cep = row.nu_cep;
      this.newEndereco.nm_estado = row.nm_estado;
      this.newEndereco.nm_cidade = row.nm_cidade;
      this.newEndereco.nm_bairro = row.nm_bairro;
      this.newEndereco.nm_endereco = row.nm_endereco;
      this.newEndereco.nu_numero = row.nu_numero;
      this.newEndereco.nm_complemento = row.nm_complemento;
      this.newEndereco.fl_tipo_endereco = row.fl_tipo_endereco;
      this.newEndereco.fl_novo = false;
      //Abre a modal de edição
      this.modal.novoEndereco = true;
      console.log("editando - conteudo do newEndereco -> ", this.newEndereco)
    },
    //Função utilizada para remover do Array o usuário apagado
    deleteRow(row) {
      let indexToDelete = this.enderecos.findIndex(
        tableRow => tableRow.id_empresa_ender === row.id_empresa_ender
      );
      if (indexToDelete >= 0) {
        this.enderecos.splice(indexToDelete, 1);
      }
    },
    async newFormSubmit() {
      this.loading = true;
      this.newEndereco.id_empresa = this.id.empresa;
      await this.$http
        .post('enderecos/gravar', this.newEndereco)
        .then(() => {
          swal.fire({
            title: 'Concluído!',
            text: 'Lista de endereços atualizada com sucesso!',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
          this.modal.novoEndereco = false;
          this.resetNovoEndereco();
          this.carregaEnderecos();
          this.loading = false;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          this.loading = false;
          return false;
        });
    },
    resetNovoEndereco() {
      this.newEndereco.id_empresa = "";
      this.newEndereco.nm_nome_endereco = "";
      this.newEndereco.nu_cep = "";
      this.newEndereco.nm_estado = "";
      this.newEndereco.nm_cidade = "";
      this.newEndereco.nm_bairro = "";
      this.newEndereco.nm_endereco = "";
      this.newEndereco.nu_numero = "";
      this.newEndereco.nm_complemento = "";
      this.newEndereco.fl_tipo_endereco = "3";
      this.newEndereco.fl_novo = true;
    },
  },
  computed: {},
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.initVariaveis();
    this.carregaEnderecos();
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
  .icone-ativo {
    font-size: 1.2rem;
    color: red;
  }
  .icone-desabilitado {
    font-size: 1.2rem;
    color: green;
  }
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
</style>
