<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <span class="icones-caracteristicas-inativo material-symbols-outlined m-2">quiz</span>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid w-75" src="https://storagematchjobdefault.blob.core.windows.net/matchjob/icone-50x50.png" alt="MatchJob Empregos" />
          </div>
          <div class="col-11 align-self-center">
            <small><strong>MatchJob Empregos</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <p class="font-weight-normal">Respostas do Teste: <strong>{{testesRealizado.nm_nome}}</strong></p>
            <ul>
              <template v-for="(pergunta, index) in testesRealizado.json_questoes.questions">
                <li :key="index">{{pergunta.value}}
                  <ul v-if="pergunta.type === 'text'">
                    <li>
                      <strong>{{pergunta.resp}}</strong>
                    </li>
                  </ul>
                  <ul v-else-if="pergunta.type === 'dico'">
                    <li><strong>{{pergunta.resp ? 'Sim' : 'Não'}}</strong> <template v-if="pergunta.defined !== ''">
                        <i v-if="pergunta.defined === pergunta.resp" class="text-success far fa-check-circle"></i>
                        <i v-else class="text-danger far fa-times-circle"></i>
                      </template>
                    </li>
                  </ul>
                  <ul v-else-if="pergunta.type === 'ru'">
                    <li><strong>{{pergunta.resp}}</strong> <template v-if="pergunta.defined !== ''">
                        <i v-if="validaRU(pergunta.defined, pergunta.resp)" class="text-success far fa-check-circle"></i>
                        <i v-else class="text-danger far fa-times-circle"></i>
                      </template>
                    </li>
                  </ul>
                  <ul v-else-if="pergunta.type === 'multi'">
                    <li v-for="(resp, index) in pergunta.resp" :key="index">
                      <strong>{{resp}}</strong> <template v-if="pergunta.defined !== ''">
                        <i v-if="validaMultiPorRestosta(pergunta.defined, resp)" class="text-success far fa-check-circle"></i>
                        <i v-else class="text-danger far fa-times-circle"></i>
                      </template>
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-12">
            <pre>{{testesRealizado.json_questoes}}</pre>
          </div>
        </div> -->
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CompTesteVaga",
  props: {
    propItem: { type: Object },
    propTeste: { type: Array }
  },
  data() {
    return {
      //testesRealizado: {}
    }
  },
  computed: {
    testesRealizado() {
      return this.propTeste.find(t => t.id_teste === this.propItem.id_teste);
    }
  },
  methods: {
    filtraTeste() {
      console.log('testesRealizado', this.testesRealizado)
      if (this.propTeste.length > 0) {
        this.testesRealizado = this.propTeste.find(t => t.id_teste === this.propItem.id_teste);
        //console.log('this.testesRealizado -> ', typeof this.testesRealizado)
      }
    },
    validaMultiPorRestosta(defined, resp) {
      var indexOfDefined = defined.findIndex(i => i === resp);
      if (indexOfDefined > -1) {
        return true;
      } else {
        return false;
      }
    },
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    //this.filtraTeste();
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
</style>
