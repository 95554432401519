<template>
  <div class="content" v-loading="loading.full">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Editar Vaga - <span class="text-muted">{{vaga.nm_cargo}}</span></h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
              </div>
            </div>
          </template>
          <div class="card-body pt-0">
            <card v-if="vaga.nm_cargo">
              <div class="row">
                <div class="col-6">
                  Cargo: <strong>{{vaga.nm_cargo}}</strong> <badge rounded :type="badgeStatusType(vaga.fl_status)" class="text-dark" size="lg">
                  {{ badgeStatus(vaga.fl_status) }}</badge>
                </div>
                <div class="col-3">
                  Candidaturas: <strong>{{ vaga.json_candidaturas.length }}</strong>
                </div>
                <div class="col-3 text-right">
                  <base-button outline type="success" size="sm" @click="goToVaga(vaga.hl_link)">
                    <span class="btn-inner--icon"><i class="fas fa-caret-square-left"></i></span>
                    <span class="btn-inner--text">Voltar para a Vaga</span>
                  </base-button>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-auto">
                  Abertura: <strong>{{ vaga.dt_abertura | formatDate }}</strong>
                </div>
                <div class="col-4">
                  Previsão de fechamento: <strong>{{ vaga.dt_prev_fechamento | formatDate }}</strong>
                </div>
              </div>
            </card>
            <card>
              <template slot="header">
              <h2 slot="title">Edição da Vaga</h2>
              </template>
              <collapse>
                <collapse-item name="1">
                  <h3 slot="title"><span class="text-muted">Informações Gerais</span></h3>
                  <InfoGerais
                    @resStep1="dadosStep1 = $event"
                    :propInfoGerais="vaga"
                    :propSysInfos="sysInfos"
                    :propUsuarios="usuarios"
                    :propEmit="flAtualizarVaga"
                  />
                </collapse-item>
                <collapse-item name="2">
                  <h3 slot="title"><span class="text-muted">Contratação e Requisitos</span></h3>
                  <ContratacaoRequisitos
                    @resStep2="dadosStep2 = $event"
                    :propVaga="vaga"
                    :propEmit="flAtualizarVaga"
                  />
                </collapse-item>
                <collapse-item name="3">
                  <h3 slot="title"><span class="text-muted">Vídeo sobre a vaga</span></h3>
                  <VideoVaga
                    @resStep3="dadosStep3 = $event"
                    :propVaga="vaga"
                    :propEmit="flAtualizarVaga"
                  />
                </collapse-item>
                <collapse-item name="4">
                  <h3 slot="title"><span class="text-muted">Local de Trabalho</span></h3>
                  <LocalFormaTrabalho
                    :propVaga="vaga"
                    :propEnderecos="enderecos"
                    @resStep4="dadosStep4 = $event"
                    :propEmit="flAtualizarVaga"
                  />
                </collapse-item>
                <collapse-item name="5">
                  <h3 slot="title"><span class="text-muted">Benefícios</span></h3>
                  <BeneficiosComp
                    @resStep5="dadosStep5 = $event"
                    :propEmit="flAtualizarVaga"
                    :propVaga="vaga.json_vaga.json_beneficios"
                  />
                </collapse-item>
                <collapse-item name="6">
                  <h3 slot="title"><span class="text-muted">Testes Aplicados na Candidatura</span></h3>
                  <TestesAplicados
                    @resStep6="dadosStep6 = $event"
                    :propEmit="flAtualizarVaga"
                    :propVaga="vaga"
                    :propTestes="testes"
                  />
                </collapse-item>
                <collapse-item name="7">
                  <h3 slot="title"><span class="text-muted">Requisitos Implicitos da Vaga</span></h3>
                  <RequisitosImplicitos
                    @resStep7="dadosStep7 = $event"
                    :propEmit="flAtualizarVaga"
                    :propVaga="vaga"
                    :propIdiomas="vaga.json_vaga.json_idiomas"
                    :propNivelexperiencia="vaga.json_vaga.json_nivelexperiencia"
                    :propCompetencias="vaga.json_vaga.json_competencias"
                    :propCertificacoes="vaga.json_vaga.json_certificacao"
                    :propFormacoes="vaga.json_vaga.json_formacao"
                  />
                </collapse-item>
                <collapse-item name="8">
                  <h3 slot="title"><span class="text-muted">Processo de Seleção</span></h3>
                  <ProcessoSeletivoComp
                    @resStep8="dadosStep8 = $event"
                    :propEmit="flAtualizarVaga"
                    :propProcessoSelecao="$store.state.empresa.empresa.json_processo_selecao.processo"
                    :propUsuarios="$store.state.usuarios.usuarios"
                    :propPlano="$store.state.empresa.plano.json_detalhes"
                    :propVaga="vaga"
                  />
                </collapse-item>
              </collapse>
              <div class="row">
                <div class="col text-center">
                  <base-button type="primary" size="lg" @click="atualizaVaga">Atualizar Vaga</base-button>
                </div>
              </div>
            </card>
          </div>
          <template slot="footer">

          </template>
        </card>
      </div>
    </div>
    <el-drawer
      title="Ajuda da MatchJob"
      :visible.sync="drawer"
      direction="rtl"
      size="50%">
      <h3 class="mx-2"><span class="text-muted">Perguntas frequêntes -</span> Edição de Vaga</h3>
      <collapse>
        <collapse-item name="1">
          <h4 class="m-0" slot="title">Quais são as 'Ações Rápidas' existentes?</h4>
          <p class="font-weight-normal">As 'Ações Rápidas' existente são:</p>
          <ol>
            <li><strong>Criar Template</strong> - Cria um novo template de vaga a partir dessa vaga</li>
            <li><strong>Aprovar Vaga</strong> - Aprova a vaga caso a mesma esteja pendente da sua Aprovação</li>
            <li><strong>Reprovar Vaga</strong> - Reprova a vaga caso a mesma esteja pendente da sua Aprovação</li>
            <li><strong>Publicar Vaga</strong> - Realiza a publicação da vaga caso a mesma esteja pendente de publicação</li>
            <li><strong>Encerrar Vaga</strong> - Encerra a vaga atual - define a conclusão ou témino do processo seletivo da vaga</li>
            <li><strong>Cancelar Vaga</strong> - Cancela a vaga atual - A vaga será despublicada não receberá novas candidaturas</li>
            <li><strong>Congelar Vaga</strong> - Congela a vaga atual - A vaga será despublicada e poderá receber novas candidaturas se for reativada</li>
            <li><strong>Reativar Vaga</strong> - Reativa uma vaga congelada - A vaga será reativada e poderá receber novas candidaturas</li>
          </ol>
        </collapse-item>
        <collapse-item name="2">
          <h4 class="m-0" slot="title">Como funciona a 'Nova vaga a partir desse template'?</h4>
        </collapse-item>
        <collapse-item name="3">
          <h4 class="m-0" slot="title">Como reativar uma vaga?</h4>
        </collapse-item>
        <collapse-item name="4">
          <h4 class="m-0" slot="title">Como reativar uma vaga?</h4>
        </collapse-item>
      </collapse>
      <span class="text-center">
        <p class="font-weight-lighter">
          <small>
            Este tópico foi útil?<br>
            <a href="#">Clique aqui</a> se precisar de mais informações.
          </small>
        </p>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { Table, TableColumn, Select, Option, Autocomplete, Loading, Drawer } from 'element-ui';
import { format } from 'date-fns';
import moment from "moment";
import swal from 'sweetalert2';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

import InfoGerais from "../../components/EditarVaga/InfoGerais.vue";
import ContratacaoRequisitos from "../../components/EditarVaga/ContratacaoRequisitos.vue";
import VideoVaga from "../../components/EditarVaga/VideoVaga.vue";
import LocalFormaTrabalho from "../../components/EditarVaga/LocalFormaTrabalho.vue";
import BeneficiosComp from "../../components/EditarVaga/BeneficiosComp.vue";
import TestesAplicados from "../../components/EditarVaga/TestesAplicados.vue";
import RequisitosImplicitos from "../../components/EditarVaga/RequisitosImplicitos.vue";
import ProcessoSeletivoComp from "../../components/EditarVaga/ProcessoSeletivoComp.vue";

Vue.use(Loading.directive);

export default {
  name: "EditarVaga",
  mixins: [defaultMatchjob],
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    Collapse,
    CollapseItem,
    InfoGerais,
    ContratacaoRequisitos,
    VideoVaga,
    LocalFormaTrabalho,
    BeneficiosComp,
    TestesAplicados,
    RequisitosImplicitos,
    ProcessoSeletivoComp,
  },
  data() {
    return {
      dataLoaded: false,
      drawer: false,
      loading: {
        full: false
      },
      modal: {
        linkedin: false
      },
      vaga: {},
      selectedTemplate: "",
      templates: [],
      //enderecos: [],
      //testes: [],
      //usuarios: [],
      sysInfos: {
        idiomas: [],
        competencias: [],
        certificacoes: [],
        formacoes: [],
        deptos: [],
        cargos: [],
      },
      dadosStep1: "",
      dadosStep2: "",
      dadosStep3: "",
      dadosStep4: "",
      dadosStep5: "",
      dadosStep6: "",
      dadosStep7: "",
      dadosStep8: "",
      flStatusVaga: [
        { value: '1', text: "Aberta" },
        { value: '2', text: "Em Aprovação" },
        { value: '3', text: "Aprovada" },
        { value: '4', text: "Publicada" },
        { value: '5', text: "Rascunho" },
        { value: '6', text: "Encerrada" },
        { value: '7', text: "Cancelada" },
        { value: '8', text: "Pausado" },
        { value: '9', text: "Reprovada" }
      ],
      flbutAcoesRapidas: {
        aprovar: false,
        linkedin: false,
        reprovar: false,
        publicar: false,
        reativar: false,
        encerrar: false,
        cancelar: false,
        congelar: false,
        alteraData: false,
        boxEdicao: false
      },
      flTipoAprovador: '',
      flAtualizarVaga: false,
      timelineVaga: [],
      newVaga: {
        id_processo_vaga: "",
        nomeTemplate: "",
        id_empresa: "",
        id_empresa_user: "",
        fl_status: "1",
        nm_cargo: "",
        hl_link: "",
        nm_area: "",
        nm_nivel: "",
        nm_solicitante: "",
        nm_depto: "",
        dt_abertura: "",
        dt_prev_fechamento: "",
        dt_fechamento: "",
        fl_video: false,
        fl_tipo_video: "0",
        hl_video: "",
        tx_descricao: "",
        tx_requisitos: "",
        fl_deficientes: true,
        fl_contratacao: "3",
        nu_salario: "",
        fl_oculta_salario: false,
        nm_turno_trabalho: "1",
        fl_oculta_empresa: false,
        nm_empresa: "",
        fl_tipo_trabalho: "",
        id_empresa_ender: "",
        nm_cidade: "",
        nm_pais: "Brasil",
        nm_endereco: "",
        json_beneficios: [],
        json_tags: [],
        json_nivelexperiencia: {
          nivelCargo: [0, 5],
          nivelArea: [0, 5],
          nivelHierarquico: [0, 5],
        },
        json_competencias: [],
        json_idiomas: [],
        json_formacao: [],
        json_certificacao: [],
        id_teste: ""
      },
      tipoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Híbrido" },
        { value: "3", text: "Home Office" }
      ],
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
    }
  },
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
    testes() {
      return this.$store.state.testes.testes;
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    cargos() {
      return this.$store.state.sysinfos.cargos;
    },
    deptos() {
      return this.$store.state.sysinfos.deptos;
    }
  },
  methods: {
    async loadSysInfos() {
      if(this.$store.state.sysinfos.cargos.length < 1) {
        this.$store.dispatch('loadSysInfos');
      }
      this.sysInfos.competencias = this.$store.state.sysinfos.competencias
      this.sysInfos.idiomas = this.$store.state.sysinfos.idiomas
      this.sysInfos.certificacoes = this.$store.state.sysinfos.certificacoes
      this.sysInfos.formacoes = this.$store.state.sysinfos.formacoes
      this.sysInfos.cargos = this.$store.state.sysinfos.cargos
      this.sysInfos.deptos = this.$store.state.sysinfos.deptos
    },
    badgeStatus(value) {
      let tipo = this.flStatusVaga.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n === 2) {
        return "success";
      } else if (n === 3) {
        return "dark text-white";
      } else if (n === 4) {
        return "info"
      } else if (n === 5) {
        return "default text-white"
      } else if (n === 6) {
        return "attention"
      } else if (n === 7 || n === 9) {
        return "warning"
      } else if (n === 8) {
        return "darker text-white"
      }
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    goToVagas() {
      this.$router.push({ name: 'Vagas' });
    },
    async atualizaVaga() {
      this.loading.full = true;
      this.flAtualizarVaga = true;
      setTimeout(() => {
        if (!this.dadosStep1) {
          this.alertShow('Vaga não atualizada!', 'Verifique os campos da sessão Informações Gerais', 'warning');
        }
        if (!this.dadosStep2) {
          this.alertShow('Vaga não atualizada!', 'Verifique os campos da sessão Contratação e Requisitos', 'warning');
        }
        if (!this.dadosStep3) {
          this.alertShow('Vaga não atualizada!', 'Verifique os campos da sessão Vídeo sobre a vaga', 'warning');
        }
        if (!this.dadosStep4) {
          this.alertShow('Vaga não atualizada!', 'Verifique os campos da sessão Local de Trabalho', 'warning');
        }
        if (!this.dadosStep7) {
          this.alertShow('Vaga não atualizada!', 'Verifique os campos da sessão Requisitos Implicitos da Vaga', 'warning');
        }
        this.ajustaNewVaga();
        var processoSelecionado = this.$store.state.empresa.empresa.json_processo_selecao.processo.find(proc => proc.id === this.dadosStep8.id);
        var solicitante = [];
        var aprovadorRh = [];
        if(this.dadosStep8.solicitanteAprova) {
          solicitante = this.$store.state.usuarios.usuarios.find(u => u.nm_nome === this.newVaga.nm_solicitante);
          if (solicitante.length < 1) {
            this.dadosStep8.solicitanteAprova = false;
          } else {
            this.$set(this.dadosStep8, 'solicitante', solicitante)
          }
        }
        if(this.dadosStep8.requerAprovacao) {
          if (this.dadosStep8.aprovacaoTipo.tipoRh === 'area') {
            aprovadorRh = this.$store.state.usuarios.usuarios.filter(user => user.fl_tipo_acesso === this.dadosStep8.aprovacaoTipo.tipoAcesso);
            if (aprovadorRh.length < 1) {
              aprovadorRh = this.$store.state.usuarios.usuarios.find(user => user.fl_admin === '1');
            }
          } else if (this.dadosStep8.aprovacaoTipo.tipoRh === 'usuario') {
            aprovadorRh = this.$store.state.usuarios.usuarios.find(user => user.id_empresa_user === this.dadosStep8.aprovacaoTipo.usuario);
            if (aprovadorRh.length < 1) {
              aprovadorRh = this.$store.state.usuarios.usuarios.find(user => user.fl_admin === '1');
            }
          }
        }
        this.$set(this.dadosStep8, 'aprovadorRh', aprovadorRh)
        var criadoPor = {
          id_empresa_user: this.id.empresa_user,
          nm_nome: this.id.nome,
          hl_email: this.id.usuario
        };
        this.$set(this.dadosStep8, 'criadoPor', criadoPor)
        var timeline = {
          tipo_registro: "edicao",
          text: "Vaga editada",
          date: format(Date.now(), 'yyyy-MM-dd HH:mm'),
          id_empresa_user: this.id.empresa_user,
          nome: this.id.nome + " - " + this.id.usuario
        }
        this.vaga.json_timeline.push(timeline);
        var dados = {
          vaga: this.newVaga,
          timeline: this.vaga.json_timeline,
          aprovacoes: this.dadosStep8,
          processo: processoSelecionado
        };
        //console.log('dados enviados ->> ', dados)
        if (this.submitAtualizacaoVaga(dados)){
          this.flAtualizarVaga = false;
          setTimeout(() => {
            this.alertShow('Vaga atualizada!', 'A vaga foi atualizada com sucesso!', 'warning');
            this.loading.full = false;
            this.reloadVaga();
            this.$forceUpdate();
          }, 1000);
        } else {
          this.loading.full = false;
          this.alertShow('Vaga não atualizada!', 'Houve um erro e não foi possível atualizar a vaga.', 'warning');
        }
      }, 500);
      this.resetDadosSteps()
    },
    resetDadosSteps() {
      this.dadosStep1 = ""
      this.dadosStep2 = ""
      this.dadosStep3 = ""
      this.dadosStep4 = ""
      this.dadosStep5 = ""
      this.dadosStep6 = ""
      this.dadosStep7 = ""
    },
    ajustaNewVaga() {
      this.newVaga.id_processo_vaga = this.vaga.id_processo_vaga;
      this.newVaga.nm_empresa = this.nomeFantasia;
      this.newVaga.id_empresa = this.id.empresa;
      this.newVaga.id_empresa_user = this.id.empresa_user;
      this.newVaga.nm_cargo = this.dadosStep1.nm_cargo;
      this.newVaga.hl_link = this.dadosStep1.hl_link;
      this.newVaga.nm_area = this.dadosStep1.nm_area;
      this.newVaga.nm_nivel = this.dadosStep1.nm_nivel;
      this.newVaga.nm_solicitante = this.dadosStep1.nm_solicitante;
      this.newVaga.nm_depto = this.dadosStep1.nm_depto;
      this.newVaga.dt_abertura = this.dadosStep1.dt_abertura;
      this.newVaga.dt_prev_fechamento = this.dadosStep1.dt_prev_fechamento;
      this.newVaga.json_tags = this.dadosStep1.json_tags;
      this.newVaga.fl_deficientes = this.dadosStep2.fl_deficientes;
      this.newVaga.fl_contratacao = this.dadosStep2.fl_contratacao;
      this.newVaga.nu_salario = this.dadosStep2.nu_salario;
      this.newVaga.fl_oculta_salario = this.dadosStep2.fl_oculta_salario;
      this.newVaga.nm_turno_trabalho = this.dadosStep2.nm_turno_trabalho;
      this.newVaga.tx_descricao = this.dadosStep2.tx_descricao;
      this.newVaga.tx_requisitos = this.dadosStep2.tx_requisitos;
      this.newVaga.fl_video = this.dadosStep3.fl_video;
      this.newVaga.fl_tipo_video = this.dadosStep3.fl_tipo_video;
      this.newVaga.hl_video = this.dadosStep3.hl_video;
      this.newVaga.fl_oculta_empresa = this.dadosStep4.fl_oculta_empresa;
      this.newVaga.nm_empresa = this.dadosStep4.nm_empresa;
      this.newVaga.fl_tipo_trabalho = this.dadosStep4.fl_tipo_trabalho;
      this.newVaga.id_empresa_ender = this.dadosStep4.id_empresa_ender;
      this.newVaga.nm_cidade = this.dadosStep4.nm_cidade;
      this.newVaga.nm_endereco = this.dadosStep4.nm_endereco;
      this.newVaga.json_beneficios = this.dadosStep5.beneficios;
      this.newVaga.id_teste = this.dadosStep6.selectedTeste;
      this.newVaga.json_idiomas = this.dadosStep7.idiomas;
      this.newVaga.json_nivelexperiencia = this.dadosStep7.nivelexperiencia;
      this.newVaga.json_competencias = this.dadosStep7.competencias;
      this.newVaga.json_certificacao = this.dadosStep7.certificacoes;
      this.newVaga.json_formacao = this.dadosStep7.formacoes;
    },
    async submitAtualizacaoVaga(dados) {
      await this.$http
        .post('vagas/editarvaga', dados)
        .then(() => {
          return true;
        }).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    goToVaga(value) {
      this.$router.push({ name: 'dashboardvaga', params: { url: value} });
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaJornadas(value) {
      var res = this.jornadas.find(i => i.value === value);
      return res.text;
    },
    async reloadVaga() {
      const data = {
        id_empresa: this.id.empresa,
        hl_link: this.$route.params.url
      }
      const vaga = await this.$http.post('vagas/visualizarvaga', data).then(res => {
        return res.data;
      })
      this.$store.dispatch('atualizaVaga', vaga);
    }
  },
  filters:{
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  },
  async created() {
    this.loading.full = true;
    const data = {
      id_empresa: this.id.empresa,
      hl_link: this.$route.params.url
    }
    await this.$http.post('vagas/visualizarvaga', data).then(res => {
      this.vaga = res.data;
      this.dataLoaded = true;
    })
  },
  mounted() {
    setTimeout(() => {
      this.loading.full = false;
      this.loadSysInfos();
    }, 500);
  },
}
</script>

<style scoped>
.icone-help-bread-crumb {
  color: #FFF;
  font-size: 1.6rem;
  cursor: pointer;
}
.but-drop-icone-linha-arquivo {
  font-size: 0.9rem;
  cursor: pointer;
}
</style>
