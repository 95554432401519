<template>
  <ul class="list-group list-group-flush list my--3">
    <template v-for="(teste, index) in testes">
    <li class="list-group-item p-0"
      :key="index"
      v-if="index < 5"
    >
      <div class="row align-items-center">
        <div class="col">
          <small>{{teste.nm_categoria}} - Perguntas: {{teste.json_questoes.questions.length}}</small>
          <h4 class="mb-0"><a href="#"> {{teste.nm_nome}}</a><br><small class="text-muted">{{ teste.nm_descricao}}</small>
          </h4>
          <badge type="default" class="text-white m-1" v-for="(tag, index) in teste.json_tags.tags" :key="index">{{tag}}</badge>
        </div>
      </div>
    </li>
    </template>
  </ul>
</template>
<script>

export default {
  name: 'TestesList',
  computed: {
    testes() {
      return this.$store.state.testes.testes;
    }
  }
};
</script>
<style></style>
