<template>
  <div>
    <card>
      <div class="row">
        <div class="col-12">
          <h4>Plataforma de e-mail utilizada <el-tooltip
            :content="propIntegracoes.text"
            placement="top"
          ><i class="fas fa-info-circle"></i></el-tooltip></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="emailUtilizado" id="emailUtilizado1" value="outlook" v-model="emailUtilizado">
            <label class="form-check-label" for="emailUtilizado1">
              Microsoft Outlook
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="emailUtilizado" id="emailUtilizado2" value="gmail" v-model="emailUtilizado">
            <label class="form-check-label" for="emailUtilizado2">
              Gmail (Google)
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="emailUtilizado" id="emailUtilizado3" value="outro" v-model="emailUtilizado">
            <label class="form-check-label" for="emailUtilizado3">
              Outra solução
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-2">
          <base-button type="primary" @click="salvarAlteracoes">Salvar</base-button>
        </div>
      </div>
      <!-- {{propIntegracoes}} -->
    </card>
  </div>
</template>

<script>
export default {
  name: "EmailUtilizado",
  props: {
    propIntegracoes: { type: Object }
  },
  data() {
    return {
      emailUtilizado: this.propIntegracoes.emailUtilizado
    }
  },
  methods: {
    salvarAlteracoes() {
      this.$emit('resEmailUtilizado', this.emailUtilizado);
    }
  }
}
</script>

<style>

</style>
