<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Palavras-Chave</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique">
      <div class="col-12">
        <h5>Entidades encontradas: ({{entidades.length}})</h5>
        <badge class="mx-1" type="info" v-for="(entitie, index) in entidades" :key="index">{{entitie}}</badge>
      </div>
    </div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique">
      <div class="col-12">
        <h5>Palavras-chave: ({{palavras.length}})</h5>
        <badge class="mx-1" type="default" v-for="(keyphrase, index) in palavras" :key="`a${index}`">{{keyphrase}}</badge>
        <!-- <pre>{{propPalavrasChave}}</pre> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PalavrasChave",
  props: {
    propPalavrasChave: { type: Object }
  },
  data() {
    return {
      flDetalhes: false
    }
  },
  computed: {
    palavras() {
      let itens1 = this.propPalavrasChave.keyphrases1.map((a) => {return a[0]})
      let itens2 = this.propPalavrasChave.keyphrases2.map((a) => {return a[0]})
      let arrItens = [...itens1, ...itens2]
      let itensNovo = [...new Set(arrItens)]
      return itensNovo;
    },
    entidades() {
      let itens = this.propPalavrasChave.entities.map((a) => {return a.vaga})
      let itensNovo = [...new Set(itens)]
      return itensNovo;
    }
  }
}
</script>

<style>

</style>
