<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Idiomas</h5>
      </div>
    </div>
    <div>
      <div class="row justify-content-start align-items-start">
        <div class="col-3" v-for="(idioma, index) in propIdiomas" :key="index">
          <small>Idioma:</small><br><strong>{{idioma.nm_idioma}} - {{retornaNivelIdioma(idioma.fl_nivel)}}</strong>
        </div>
      </div>
    </div>
    <!-- <pre>{{propIdiomas}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "IdiomasCV",
  props: {
    propIdiomas: { type: Array }
  },
  data() {
    return {
      nivelIdioma: [
        { value: 1, text: "Básico" },
        { value: 2, text: "Intermediário" },
        { value: 3, text: "Avançado" },
        { value: 4, text: "Fluente" }
      ]
    }
  },
  methods: {
    retornaNivelIdioma(id) {
      var nivel = this.nivelIdioma.find(i => i.value === id);
      return nivel.text;
    }
  }
}
</script>

<style>

</style>
