<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Competências</h5>
      </div>
    </div>
    <div>
      <div class="row justify-content-start align-items-start">
        <div class="col-4" v-for="(competencia, index) in propCompetencias" :key="index">
          {{competencia.nm_competencia}} - {{retornaNivelCompetencia(competencia.fl_nivel)}}
        </div>
      </div>
    </div>
    <!-- <pre>{{propCompetencias}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "CompetenciasCv",
  props: {
    propCompetencias: { type: Array }
  },
  data() {
    return {
      nivelCompetencia: [
        { value: 1, text: "Básico / Júnior" },
        { value: 2, text: "Intermediário / Pleno" },
        { value: 3, text: "Avançado / Sênior" },
        { value: 4, text: "Especialista" }
      ]
    }
  },
  methods: {
    retornaNivelCompetencia(id) {
      var nivel = this.nivelCompetencia.find(i => i.value === id);
      return nivel.text;
    }
  }
}
</script>

<style>

</style>
