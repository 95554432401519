<template>
  <div v-loading.fullsccreen.lock="loading.full">
    <tabs pills tabNavWrapperClasses="mb-3">
      <tab-pane active id="processos">
        <template slot="label">
          <i class="ni ni-settings"></i> Processos de Seleção
        </template>
        <div class="row mt-3">
          <div class="col-2 align-self-center">
            <h1>Workflows</h1>
          </div>
        </div>
        <div class="row my-2" v-if="propPlano.ps.criacaoPs">
          <div class="col align-self-center">
            <base-button type="info" size="sm" class="mr-3 ml-auto" @click="abreModalNovoPS">Novo Workflow</base-button>
          </div>
        </div>
        <template v-for="ps in propProcessoSeletivo.processo">
          <card :key="ps.id" :class="ps.fl_default ? 'order-first' : ''">
            <!-- Linha do título -->
            <div class="row">
              <div class="col-3 align-self-center">
                <p class="font-weight-normal mb-0"><small>Nome:</small> <strong>{{ps.nm_nome}}</strong> <el-tooltip
                  content="Workflow padrão"
                  placement="top"
                ><i class="text-indigo fas fa-certificate" v-if="ps.fl_default"> </i>
                </el-tooltip></p>
              </div>
              <div class="col-2 align-self-center">
                <small>Modo: <strong>{{retornaTipoPs(ps.fl_tipo)}}</strong></small>
              </div>
              <div class="col-1 align-self-center">
                <p class="font-weight-normal mb-0"><small>Fases:</small> <strong>{{ps.fases.length}}</strong></p>
              </div>
              <div class="col-2 align-self-center">
                <p class="font-weight-normal mb-0"><small>Competências Avaliadas:</small> <strong>{{ps.competencias.length}}</strong></p>
              </div>
              <!-- Data -->
              <div class="col-2 align-self-center">
                <p class="font-weight-light mb-0">
                  <small>
                    <!-- Criado em: <strong>{{ps.created_at | formatDateTime}}</strong><br> -->
                    <!-- Última atualização: <strong>{{ps.updated_at | formatDateTime}}</strong> -->
                    Atualizado em: <strong>{{ps.updated_at | formatDateTime}}</strong>
                  </small>
                </p>
              </div>
              <!-- Coluna dos botões de ação -->
              <div class="col-2 align-self-center text-right">
                <el-tooltip content="Tornar padrão" placement="top" effect="light">
                  <base-button :disabled="ps.fl_default" class="edit" type="secondary" size="sm" icon @click="alteraProcessoPadrao(ps.id)">
                    <i class="text-indigo fas fa-certificate"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip content="Editar" placement="top" effect="light">
                <base-button class="edit" type="info" size="sm" icon @click="editarProcesso(ps.id)">
                  <i class="text-white fas fa-pen"></i>
                </base-button>
                </el-tooltip>
                <el-tooltip content="Remover Workflow" placement="top" effect="light">
                  <base-button :disabled="ps.fl_default" class="edit" type="danger" size="sm" icon @click="removeProcesso(ps.id)">
                    <i class="text-white fas fa-trash-alt"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </div>
            <!-- Linha de fases -->
            <div class="row my-1">
              <div class="col-12 text-nowrap">
                <perfect-scrollbar>
                <p class="font-weight-normal mb-0">Fases:</p>
                <template v-for="fase in ps.fases">
                  <badge rounded :type="badgeStatusType(fase.tipo)" class="text-dark m-1" size="lg" :key="fase.id">
                      {{ fase.value }}</badge>
                </template>
                </perfect-scrollbar>
              </div>
            </div>
            <!-- Linha de competencias -->
            <div class="row mt-1">
              <div class="col-12 text-nowrap">
                <perfect-scrollbar>
                <p class="font-weight-normal mb-0">Competências:</p>
                <template v-for="(comp, index) in ps.competencias">
                  <badge type="default" class="text-white m-1" :key="index">
                      {{ comp }}</badge>
                </template>
                </perfect-scrollbar>
              </div>
            </div>
          </card>
        </template>
      </tab-pane>
      <tab-pane id="configuracoes">
        <template slot="label">
          <i class="ni ni-settings"></i> Configurações de Fases
        </template>
        <h3>Configurações de Fases</h3>
        <ConfiguracoesFases
          :propUsuarios="propUsuarios"
          :propPlano="propPlano"
          :propConfigFases="propConfigFases"
          :propTestePerfil="testesPerfil"
          :propTestes="testes"
        />
      </tab-pane>
    </tabs>
    <!-- <div class="row"><div class="col"><h1>ProcessoSelecao.vue</h1></div></div>
    <div class="row">
      <div class="col-6">
        propProcessoSeletivo.processo
        <pre>{{propProcessoSeletivo.processo}}</pre>
        propConfigFases ->> <pre>{{propConfigFases}}</pre>
        fasesIniciais -> <pre>{{fasesIniciais}}</pre>
      </div>
      <div class="col-6">
        competenciasIniciais ->> <pre>{{competenciasIniciais}}</pre>
        novoProcesso ->> <pre>{{novoProcesso}}</pre>
        propPlano ->> <pre>{{propPlano}}</pre>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        novoProcesso ->> <pre>{{novoProcesso}}</pre>
      </div>
      <div class="col-6">
        fasesPs ->> <pre>{{fasesPs}}</pre>
      </div>
    </div> -->
    <modal :show="modal.novoProcesso"
      :showClose="false"
      size="xl"
      v-loading.fullsccreen.lock="loading.novoProcesso"
    >
      <h6 slot="header" class="modal-title">
        Novo Processo Seletivo
        <template v-if="dadosStep1.fl_tipo !== ''">
          <template v-if="dadosStep1.fl_tipo === 'simples'"> - Simples</template>
          <template v-else-if="dadosStep1.fl_tipo === 'auto'"> - Automatizado</template>
          <template v-else-if="dadosStep1.fl_tipo === 'custom'"> - Personalizado</template>
        </template>
        <template v-if="step2"> - Competências</template>
        <template v-if="step3"> - Configuração das Fases</template>
        <template v-if="step4"> - Resumo</template>
      </h6>
      <div ref="topo"></div>
      <TipoProcessoStep1
        v-if="step1"
        :propTipoNome="dadosStep1"
        :concluiStep1="validaStep1"
      />
      <!-- Comp CompetenciasAvaliadas -->
      <CompetenciasAvaliadas
        v-if="step2"
        @resStep1="dadosStep2 = $event"
        :propCompetencias="competenciasIniciais"
        :concluiStep1="validaStep2"
        :voltarStep2="voltaStep2"
      />
      <FasesProcesso
        v-if="step3"
        :propTipo="dadosStep1"
        :propFases="fasesIniciais"
        :propFasesConfig="propConfigFases"
        :propUsuarios="propUsuarios"
        :propTestes="testes"
        :propTestesPerfil="testesPerfil"
        :concluiStep3="validaStep3"
        :voltarStep3="voltaStep3"
      />
      <ResumoNovoPs
        v-if="step4"
        :propResumo="novoProcesso"
        :voltarStep4="voltaStep4"
        :concluiStep4="validaStep4"
      />
      <div slot="footer">
        <base-button type="warning" class="ml-3" @click="cancelNovoProcesso">Cancelar</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '@/mixins/defaultMatchjob';
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option
} from 'element-ui';
import { format } from 'date-fns';
import swal from 'sweetalert2';
import moment from "moment";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Tabs from '@/components/Tabs/Tabs.vue';
import TabPane from '@/components/Tabs/Tab.vue';
import ConfiguracoesFases from './ConfigFases/ConfiguracoesFases.vue';
import TipoProcessoStep1 from './NovoProcesso/TipoProcessoStep1.vue';
import CompetenciasAvaliadas from './NovoProcesso/CompetenciasAvaliadas.vue';
import FasesProcesso from './NovoProcesso/FasesProcesso.vue';
import ResumoNovoPs from './NovoProcesso/ResumoNovoPs.vue';

Vue.use(Loading.directive);

export default {
  name: "ProcessoSelecao",
  mixins: [defaultMatchjob],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    PerfectScrollbar,
    Tabs,
    TabPane,
    ConfiguracoesFases,
    TipoProcessoStep1,
    CompetenciasAvaliadas,
    FasesProcesso,
    ResumoNovoPs
  },
  props:{
    propUsuarios: { type: Array },
    propPlano: { type: Object},
    propConfigFases: { type: Object},
    propProcessoSeletivo: { type: Object }
  },
  data() {
    return {
      modal: {
        novoProcesso: false
      },
      loading: {
        full: false,
        novoProcesso: false
      },
      testes: [],
      testesPerfil: [],
      competenciasIniciais: [
        "Conhecimento",
        "Habilidades",
        "Atitude",
        "Valores",
        "Engajamento"
      ],
      novoProcesso: {},
      funcoesPs: [
        { id: 1, text: "Aprovação de Vagas" },
        { id: 1, text: "Aprovação de Currículos" },
      ],
      fasesSimples: [
        {
          id: 1,
          tipo: "publicacaoVaga",
          value: "Publicação da Vaga",
          required: true,
          tab: false,
          configs: {}
        },
        {
          id: 2,
          tipo: "triagem",
          value: "Triagem de Currículos",
          required: true,
          tab: false,
          configs: {}
        },
        {
          id: 3,
          tipo: "entrevistas",
          value: "Entrevista com o RH",
          required: false,
          tab: false,
          configs: {
            entrevistaRH: true,
            entrevistaSolicitante: false,
          }
        },
        {
          id: 4,
          tipo: "entrevistas",
          value: "Entrevista com o Gestor",
          required: false,
          tab: false,
          configs: {
            entrevistaRH: false,
            entrevistaSolicitante: false,
          }
        },
        { id: 5, tipo: "negociacao", value: "Negociação", required: false, tab: false },
        { id: 6, tipo: "contratacao", value: "Contratação", required: true, tab: false }
      ],
      fasesAuto: [
        { id: 1, tipo: "publicacaoVaga", value: "Publicação da Vaga", required: true, tab: false, configs: {} },
        { id: 2, tipo: "triagem", value: "Triagem de Currículos", required: true, tab: false, configs: {} },
        { id: 3, tipo: "testePerfil", value: "Teste de Perfil", required: false, tab: false, configs: {} },
        {
          id: 4,
          tipo: "entrevistas",
          value: "Entrevista com o RH",
          required: false,
          tab: false,
          configs: {
            entrevistaRH: true,
            entrevistaSolicitante: false,
          }
        },
        {
          id: 5,
          tipo: "entrevistas",
          value: "Entrevista com o Gestor",
          required: false,
          tab: false,
          configs: {
            entrevistaRH: false,
            entrevistaSolicitante: false,
          }
        },
        { id: 6, tipo: "negociacao", value: "Negociação", required: false, tab: false },
        { id: 7, tipo: "contratacao", value: "Contratação", required: true, tab: false }
      ],
      fasesCustom: [
        { id: 1, tipo: "publicacaoVaga", value: "Publicação da Vaga", required: true, tab: false, configs: {} },
        { id: 2, tipo: "triagem", value: "Triagem de Currículos", required: true, tab: false, configs: {} },
        { id: 3, tipo: "contratacao", value: "Contratação", required: true, tab: false }
      ],
      compIniciaisSimples: [
        'Conhecimento Técnico',
        'Formações',
        'Certificações',
        'Idiomas',
        'Experiência'
      ],
      fasesIniciais: [],
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      dadosStep1: {
        nm_nome: "",
        fl_tipo: ""
      },
      dadosStep2: [],
    }
  },
  methods: {
    async editFormSubmit() {
      /* this.loading = true;
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      this.$set(this.empresa, 'valida', data);
      await this.$http.post('empresa/atualizadados', this.empresa).then(res => {
        this.loading = false;
        this.notificacao("Dados atualizados com sucesso!", 'success');
      }).catch(err => {
        this.loading = false;
        this.notificacao("Ops! Não foi possível atualizar os dados.", 'warning');
        console.log(err);
      }); */
    },
    badgeStatusType(value) {
      if(value === 'publicacaoVaga' || value === 'triagem') {
        return 'primary';
      } else if(value === 'testePerfil' || value === 'enviaTestes') {
        return 'info';
      } else if(value === 'entrevistas' || value === 'dinamica') {
        return 'attention';
      } else if(value === 'negociacao' || value === 'contratacao') {
        return 'success';
      } else {
        return 'warning';
      }

    },
    retornaTipoPs(value) {
      if (value === 'simples') {
        return "Simples"
      } else if (value === 'auto') {
        return "Automático"
      } else if (value === 'custom') {
        return "Customizado"
      }
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    validaStep1() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      if (this.dadosStep1.fl_tipo === 'simples') {
        this.fasesIniciais = structuredClone(this.fasesSimples);
        this.fasesIniciais[0].configs = structuredClone(this.propConfigFases.publicacaoVaga);
        this.fasesIniciais[1].configs = structuredClone(this.propConfigFases.triagem);
        setTimeout(() => {
          this.step1 = false;
          this.step3 = true;
          this.loading.novoProcesso = false;
        }, 500);
      } else if (this.dadosStep1.fl_tipo === 'auto') {
        this.fasesIniciais = structuredClone(this.fasesAuto);
        this.fasesIniciais[0].configs = structuredClone(this.propConfigFases.publicacaoVaga);
        this.fasesIniciais[1].configs = structuredClone(this.propConfigFases.triagem);
        this.fasesIniciais[2].configs = structuredClone(this.propConfigFases.testePerfil);
        setTimeout(() => {
          this.step1 = false;
          this.step3 = true;
          this.loading.novoProcesso = false;
        }, 500);
      } else if (this.dadosStep1.fl_tipo === 'custom') {
        this.fasesIniciais = structuredClone(this.fasesCustom);
        this.fasesIniciais[0].configs = structuredClone(this.propConfigFases.publicacaoVaga);
        this.fasesIniciais[1].configs = structuredClone(this.propConfigFases.triagem);
        setTimeout(() => {
          this.step1 = false;
          this.step2 = true;
          this.loading.novoProcesso = false;
        }, 500);
      }
    },
    validaStep2() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      setTimeout(() => {
        this.step2 = false;
        this.step3 = true;
        this.loading.novoProcesso = false;
      }, 500);
    },
    validaStep3() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      if (this.novoProcesso.id) {
        setTimeout(() => {
          this.step3 = false;
          this.step4 = true;
          this.loading.novoProcesso = false;
        }, 500);
      } else {
        this.$set(this.novoProcesso, 'nm_nome', "");
        this.$set(this.novoProcesso, 'fases', []);
        this.$set(this.novoProcesso, 'fases', []);
        this.$set(this.novoProcesso, 'competencias', []);
        this.novoProcesso.fases = structuredClone(this.fasesIniciais);
        if (this.dadosStep1.fl_tipo === 'simples') {
          this.$set(this.novoProcesso, 'fl_tipo', 'simples');
          this.novoProcesso.competencias = structuredClone(this.compIniciaisSimples);
        } else if (this.dadosStep1.fl_tipo === 'auto') {
          this.$set(this.novoProcesso, 'fl_tipo', 'auto');
          this.novoProcesso.competencias = structuredClone(this.compIniciaisSimples);
        } else if (this.dadosStep1.fl_tipo === 'custom') {
          this.$set(this.novoProcesso, 'fl_tipo', 'custom');
          this.novoProcesso.competencias = structuredClone(this.competenciasIniciais);
        }
        setTimeout(() => {
          this.step3 = false;
          this.step4 = true;
          this.loading.novoProcesso = false;
        }, 500);
      }
    },
    validaStep4() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      if (this.novoProcesso.id) {
        this.novoProcesso.updated_at = format(Date.now(), 'yyyy-MM-dd HH:mm');
        setTimeout(() => {
          this.loading.novoProcesso = false;
          this.modal.novoProcesso = false;
          this.step1 = true;
          this.step4 = false;
        }, 500);
      } else {
        let tam = Object.keys(this.propProcessoSeletivo.processo).length;
        let idTemp = tam + 1;
        this.$set(this.novoProcesso, 'id', idTemp);
        this.$set(this.novoProcesso, 'fl_default', false);
        this.$set(this.novoProcesso, 'created_at', format(Date.now(), 'yyyy-MM-dd HH:mm'));
        this.$set(this.novoProcesso, 'updated_at', format(Date.now(), 'yyyy-MM-dd HH:mm'));
        this.propProcessoSeletivo.processo.push(this.novoProcesso);
        setTimeout(() => {
          this.loading.novoProcesso = false;
          this.modal.novoProcesso = false;
          this.step1 = true;
          this.step4 = false;
          this.novoProcesso = {};
          this.fasesIniciais = [];
          this.dadosStep1.fl_tipo = "";
          this.dadosStep1.nm_nome = "";
        }, 500);
      }
    },
    voltaStep2() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      setTimeout(() => {
        this.step2 = false;
        this.step1 = true;
        this.loading.novoProcesso = false;
      }, 500);
    },
    voltaStep3() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      if (this.dadosStep1.fl_tipo === 'simples' || this.dadosStep1.fl_tipo === 'auto') {
        setTimeout(() => {
          this.step3 = false;
          this.step1 = true;
          this.loading.novoProcesso = false;
        }, 500);
      } else {
        setTimeout(() => {
          this.step3 = false;
          this.step2 = true;
          this.loading.novoProcesso = false;
        }, 500);
      }
    },
    voltaStep4() {
      this.loading.novoProcesso = true;
      this.scrollToTop();
      setTimeout(() => {
        this.step4 = false;
        this.step3 = true;
        this.loading.novoProcesso = false;
      }, 500);
    },
    abreModalNovoPS() {
      this.modal.novoProcesso = true;
      setTimeout(() => {
        this.scrollToTop();
      }, 500);
    },
    cancelNovoProcesso() {
      if (this.step1 === true) {
        this.modal.novoProcesso = false;
      } else{
        swal.fire({
         title: 'ATENÇÃO!',
          html: 'Você realmente cancelar a criação deste novo Processo Seletivo?<br>Toda a configuração até aqui será perdida.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, cancelar!',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        })
        .then(result => {
          if (result.value) {
            this.loading.full = true;
            this.scrollToTop();
            setTimeout(() => {
              this.novoProcesso = {};
              this.fasesIniciais = [];
              this.dadosStep1.fl_tipo = "";
              this.dadosStep1.nm_nome = "";
              this.step1 = true;
              this.step2 = false;
              this.step3 = false;
              this.step4 = false;
              this.modal.novoProcesso = false;
              this.loading.full = false;
            }, 500);
          }
        });
      }
    },
    removeProcesso(id) {
      var pos = this.propProcessoSeletivo.processo.findIndex( item => item.id === id);
      var tam = this.propProcessoSeletivo.processo.length;
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja excluir esse Workflow?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          if (tam === (pos + 1)){//valida se é o ÚLTIMO possivel
            this.propProcessoSeletivo.processo.splice(pos, 1);
          } else {
            for (var i = pos+1; i < tam; i++){
              this.propProcessoSeletivo.processo[i].id = this.propProcessoSeletivo.processo[i].id-1;
            }
            this.propProcessoSeletivo.processo.splice(pos, 1);
          }
        }
      });
    },
    alteraProcessoPadrao(id) {
      this.loading.full = true;
      var novo = this.propProcessoSeletivo.processo.findIndex( item => item.id === id);
      var atual = this.propProcessoSeletivo.processo.findIndex( item => item.fl_default === true);
      setTimeout(() => {
        this.propProcessoSeletivo.processo[novo].fl_default = true;
        this.propProcessoSeletivo.processo[atual].fl_default = false;
        this.notificacao('Workflow padrão alterado', 'success')
        this.loading.full = false;
      }, 500);
    },
    editarProcesso(id) {
      this.modal.novoProcesso = true;
      this.loading.novoProcesso = true;
      var pos = this.propProcessoSeletivo.processo.findIndex( item => item.id === id);
      this.scrollToTop();
      this.fasesIniciais = this.propProcessoSeletivo.processo[pos].fases;
      this.competenciasIniciais = [];
      this.competenciasIniciais = this.propProcessoSeletivo.processo[pos].competencias;
      this.novoProcesso = this.propProcessoSeletivo.processo[pos];
      setTimeout(() => {
        this.step1 = false;
        this.step2 = true;
        this.loading.novoProcesso = false;
      }, 500);
    },
    scrollToTop() {
      //window.scrollTo(0,0);
      //console.log('refs topo ->>', this.$refs)
      this.$refs.topo.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    },
    makeName() {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 12; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  async created() {
    const data = {
      id_empresa: this.id.empresa,
      id_empresa_user: this.id.empresa_user,
      hl_email: this.id.usuario,
      fl_admin: this.id.admin
    }
    await this.$http.get('testes/listatestes', { params: data
    }).then(res => {
      this.testes = res.data.testes;
    })
    await this.$http.get('testes/testesperfil', { params: data
    }).then(res => {
      this.testesPerfil = res.data;
    })
  },
  mounted() {
  },
  watch: {
  }
}
</script>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
<style scoped>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
.removeButton {
  cursor: pointer;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #172b4d;
}
</style>
