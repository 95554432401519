<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="fas fa-calendar-check"></i>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid rounded w-75" :src="retornaUsuario('foto')" :alt="retornaUsuario('nome')" />
          </div>
          <div class="col-9 align-self-center">
            <small><strong>{{retornaUsuario('nome')}}</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
          <div class="col-2 align-self-center">
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            Registro de Entrevista <span v-html="retornaTipoEntrevista(propItem.registroEntrevista.tipo_entrevista)" /><br>
            Data da entrevista: <strong>{{propItem.registroEntrevista.data_entrevista | formatDateTime}}</strong><br>
            Entrevistador: <strong>{{propItem.registroEntrevista.entrevistador.id_empresa_user === '' ? propItem.registroEntrevista.entrevistador.nome : retornaEntrevistador(propItem.registroEntrevista.entrevistador.id_empresa_user)}}</strong><br>
            Resumo: <strong><span :class="propItem.registroEntrevista.aprovado ? 'text-success' : 'text-danger'">{{propItem.registroEntrevista.aprovado ? 'Aprovado' : 'Reprovado'}}</span></strong> <span
              :class="propItem.registroEntrevista.aprovado ? 'text-success' : 'text-danger'"> <i class="fas" :class="propItem.registroEntrevista.aprovado ? 'fa-check-circle' : 'fa-times-circle'"></i></span><br>
            Anotações:<br>
            <template v-if="flDetalhes"></template>
            <span class="text-dark">{{flDetalhes ? propItem.registroEntrevista.comentarios : `${propItem.registroEntrevista.comentarios.substr(0, 180)}...` }}</span><br>
            <div class="linha-clique" @click="flDetalhes = !flDetalhes">
              <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="flDetalhes">
                Preenche os requisitos mínimos da vaga?<br>
                <strong><span :class="propItem.registroEntrevista.req_minimos ? 'text-success' : 'text-danger'">{{propItem.registroEntrevista.req_minimos ? 'Sim' : 'Não'}}</span></strong> <span
                  :class="propItem.registroEntrevista.req_minimos ? 'text-success' : 'text-danger'"> <i class="fas" :class="propItem.registroEntrevista.req_minimos ? 'fa-check-circle' : 'fa-times-circle'"></i></span><br>
                Disponibilidade imediata para começar a trabalhar?<br>
                <strong><span :class="propItem.registroEntrevista.inicio_imediato ? 'text-success' : 'text-danger'">{{propItem.registroEntrevista.inicio_imediato ? 'Sim' : 'Não'}}</span></strong> <span
                  :class="propItem.registroEntrevista.inicio_imediato ? 'text-success' : 'text-danger'"> <i class="fas" :class="propItem.registroEntrevista.inicio_imediato ? 'fa-check-circle' : 'fa-times-circle'"></i></span><br>
                Possui perfil compatível com a equipe?<br>
                <strong><span :class="propItem.registroEntrevista.estilo_compativel ? 'text-success' : 'text-danger'">{{propItem.registroEntrevista.estilo_compativel ? 'Sim' : 'Não'}}</span></strong> <span
                  :class="propItem.registroEntrevista.estilo_compativel ? 'text-success' : 'text-danger'"> <i class="fas" :class="propItem.registroEntrevista.estilo_compativel ? 'fa-check-circle' : 'fa-times-circle'"></i></span><br><br>
                <template v-if="propItem.registroEntrevista.result_competencias.length > 0">
                  <strong>Análise das competências</strong><br>
                  <ul class="list-unstyled">
                    <li class="my-2" v-for="(competencia, index) in propItem.registroEntrevista.result_competencias" :key="index">
                      <em><strong>{{competencia.comp}}:</strong></em> <span class="text-dark">{{competencia.text}}</span>
                    </li>
                  </ul>
                </template>
              </div>
            </transition>
            <!-- <pre>{{propItem}}</pre> -->
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";
export default {
  name: "CompEntrevistaRealizada",
  mixins: [defaultMatchjob],
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      usuarios: [],
      flDetalhes: false
    }
  },
  computed: {
    usuariosState() {
      return this.$store.state.usuarios.usuarios
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuariosState.length > 0) {
        var user = this.usuariosState.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    },
    retornaEntrevistador(id) {
      var user = this.usuariosState.find(uS => uS.id_empresa_user === id)
      return user.nm_nome
    },
    retornaEndereco(id) {
      if(id) {
        var endereco = this.enderecos.find(e => e.id_empresa_ender === id);
        var resEndereco = endereco.nm_endereco + ', ' + endereco.nu_numero + ' - ' + endereco.nm_bairro + ' - ' + endereco.nm_cidade + '/' + endereco.nm_estado + ' ' + endereco.nm_complemento;
        return resEndereco;
      }
    },
    verificarBotao() {
      if(this.propItem.id_empresa_user === this.id.empresa_user) {
        return false;
      } else if (this.id.admin) {
        return false;
      } else {
        return true;
      }
    },
    retornaTipoEntrevista(tipo) {
      if(tipo === 'entrevistaRH') {
        return ' com o <strong>RH</strong>';
      } else if(tipo === 'entrevistaSolicitante') {
        return ' com o <strong>Solicitante da Vaga</strong>'
      } else if(tipo === 'entrevistaOutro') {
        return '<strong>Normal</strong>'
      }
    },
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
