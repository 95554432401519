<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Pretensão Salarial</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <!-- Resultado Final do Match -->
    <div class="row align-items-center mt-1 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10 align-middle">
        Resultado do match: <strong><span :class="retornaMatch() ? 'text-success' : 'text-danger'">{{retornaMatch() ? 'Postivo' : 'Negativo'}}</span></strong> <span
          :class="retornaMatch() ? 'text-success' : 'text-danger'"
          >
          <i class="fas" :class="retornaMatch() ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="flDetalhes">
        <div class="row justify-content-start align-items-start mt-1">
          <div class="col-12">
            Faixa salarial definida: <strong>{{retornaNomeFaixa(propPretensao.faixa)}}</strong>
          </div>
        </div>
        <div class="row justify-content-start align-items-start mt-1">
          <div class="col-12">
            Análise da faixa salarial: <strong>{{propPretensao.match_faixa}}</strong>
          </div>
        </div>
        <!-- Comparação de Salário da Vaga e Pretensão do Candidato -->
        <div class="row justify-content-start align-items-start mt-3">
          <div class="col-2">
            Salário da vaga:<br><strong>{{propPretensao.salario_vaga.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong>
          </div>
          <div class="col-auto">
            Pretensão do candidato:<br><strong>{{propPretensao.salario_candidato.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong> <span
                :class="propPretensao.salario_vaga >= propPretensao.salario_candidato ? 'text-success' : 'text-danger'"
                >
                  <i class="fas fa-equals" v-if="propPretensao.salario_vaga === propPretensao.salario_candidato"></i>
                  <i class="far fa-arrow-alt-circle-up" v-else-if="propPretensao.salario_vaga < propPretensao.salario_candidato"></i>
                  <i class="far fa-arrow-alt-circle-down" v-else-if="propPretensao.salario_vaga > propPretensao.salario_candidato"></i>
                </span>
          </div>
        </div>
        <div class="row justify-content-start align-items-start mt-1">
          <div class="col-12">
            Resultado: <strong>{{propPretensao.salario_pretensao}}</strong>
          </div>
        </div>
      </div>
    </transition>
    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <pre>{{propPretensao}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "PretensaoSalarialComp",
  props: {
    propPretensao: { type: Object }
  },
  data() {
    return {
      faixaSalarial: [
        { value: "1", label: "Menor ou Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ],
      flDetalhes: false
    }
  },
  methods: {
    retornaNomeFaixa(value) {
      var faixa = this.faixaSalarial.find(i => i.value === value.toString());
      return faixa.label;
    },
    retornaMatch() {
      if (this.propPretensao.match_faixa === 'Fora da faixa salarial') {
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
