<template>
  <div class="card">
    <div role="tab" class="card-header d-flex justify-content-between" :aria-expanded="activeFlag">
      <div>
        <a
          data-toggle="collapse"
          data-parent="#accordion"
          :href="`#${itemId}`"
          @click.prevent="activate"
          :aria-controls="`content-${itemId}`"
        >
          <slot name="title"> {{ title }} </slot>
        </a>
      </div>
      <div>
        <slot name="buts"></slot>
      </div>
    </div>
    <collapse-transition :duration="animationDuration">
      <div
        v-show="activeFlag"
        :id="`content-${itemId}`"
        role="tabpanel"
        :aria-labelledby="title"
        class="collapsed"
      >
        <div class="card-body"><slot></slot></div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
  name: 'collapse-item-custom',
  components: {
    CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: '',
      description: 'Collapse item title'
    },
    id: [String, Number],
    active: Boolean
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: () => {}
    },
    removeItem: {
      default: () => {}
    },
    deactivateAll: {
      default: () => {}
    }
  },
  computed: {
    itemId() {
      return this.id || this.title;
    }
  },
  data() {
    return {
      activeFlag: false
    };
  },
  methods: {
    activate() {
      //let wasActive = this.active;
      let wasActive = this.activeFlag;
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      //this.active = !wasActive;
      this.activeFlag = !wasActive;
    }
  },
  mounted() {
    this.addItem(this);
    this.activeFlag = this.active;
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  },
  watch: {
    active: {
      handler(value) {
        this.activeFlag = value;
      }
    },
    activeFlag: {
      handler(value) {
        this.$emit('active', value)
      }
    }
  }
};
</script>
<style scoped>
.accordion .card-header {
  position: relative;
  cursor: default;
}
.accordion .card-header:after {
  content: none;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font: normal normal normal 14px/1 NucleoIcons;
  line-height: 0;
  -webkit-transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
