<template>
  <div>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
            @submit.prevent="handleSubmit(firstFormSubmit)">
        <div class="row justify-content-center mb-3">
          <div class="col-4">
            <label for="areaatuacao">Deseja incluir um vídeo explicativo desta vaga?</label>
          </div>
          <div class="col-4">
            <base-switch
              ref="flagvideo"
              v-model="vaga.fl_video"
              @input="changeFlagVideo()"
            ></base-switch>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-2">
            <label for="nivel">Origem do Vídeo</label>
            <el-select
              required
              name="Origem do Vídeo"
              class="inuptAutocomplete"
              v-model="vaga.fl_tipo_video"
              :disabled="!vaga.fl_video"
            >
              <el-option
                v-for="tipo in tipovideo"
                :key="tipo.value"
                :value="tipo.value"
                :label="tipo.text"
              ></el-option>
            </el-select>
            <small class="invalid-feedback" style="display: block;" v-if="validate.flTipo">O campo "Origem do Vídeo" é obrigatório.</small>
          </div>
          <div class="col-6">
            <label for="nivel">Link do vídeo</label>
            <input
              name="Link do vídeo"
              placeholder="Link do vídeo"
              class="form-control"
              v-model.trim="vaga.hl_video"
              :disabled="!vaga.fl_video"
              @blur="preVisualizaVideo"
            />
            <small class="invalid-feedback" style="display: block;" v-if="validate.link">{{msgError}}</small>
          </div>
        </div>
        <div class="row justify-content-center my-3">
          <div class="col-6 m-auto">
            <template v-if="showPreYoutube">
              <h3>Pré-visualização:</h3>
              <!-- Youtube Embed -->
              <LazyYoutube
                :src="hl_video_youtube"
                :showTitle="false"
                max-width="720px"
                aspect-ratio="16:9"
                thumbnail-quality="standard"
              />
            </template>
            <template v-else-if="showPreVimeo">
              <h3>Pré-visualização:</h3>
              <!-- Vimeo Embed -->
              <LazyVimeo
                :src="hl_video_vimeo"
                :showTitle="false"
                max-width="720px"
                aspect-ratio="16:9"
                thumbnail-quality="standard"
              />
            </template>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { Select, Option, Autocomplete } from 'element-ui';
import { LazyYoutube, LazyVimeo } from "vue-lazytube";

export default {
  name: "VideoVaga",
  props: {
    propVaga: { type: Object },
    propEmit: { type: Boolean }
  },
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    LazyYoutube,
    LazyVimeo
  },
  data() {
    return {
      validated: false,
      vaga: {
        fl_video: false,
        fl_tipo_video: '',
        hl_video: '',
      },
      showPreYoutube: false,
      showPreVimeo: false,
      hl_video_youtube: 'https://www.youtube.com/watch?v=Id_Ijthgijs',
      hl_video_vimeo: 'https://player.vimeo.com/video/701800257',
      tipovideo: [
        { value: "0", text: "Selecione" },
        { value: "1", text: "YouTube" },
        { value: "2", text: "Vimeo" },
      ],
      validate: {
        flTipo: false,
        link: false
      },
      msgError: 'Link não confere com a origem definida do vídeo ou não está definido.'
    }
  },
  methods: {
    firstFormSubmit() {
      if (this.validaCampos()){
        this.$emit('resStep3', this.vaga);
      } else {
        this.$emit('resStep3', false);
      }
    },
    preVisualizaVideo() {
      if (this.validaUrlVideo()) {
        if (this.vaga.fl_tipo_video === '1' && this.vaga.hl_video !== '') {
          this.hl_video_youtube = this.vaga.hl_video;
          this.showPreYoutube = true;
        } else if (this.vaga.fl_tipo_video === '2' && this.vaga.hl_video !== '') {
          this.hl_video_vimeo = this.vaga.hl_video;
          this.showPreVimeo = true;
        }
        this.$nextTick(() => {
          this.$refs['step3Topo'].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        });
      } else {
        this.validate.link = true;
      }
    },
    validaUrlVideo() {
      if (this.vaga.fl_tipo_video === '1') {
        if (this.vaga.hl_video.includes('https://www.youtube.com/watch?')) {
          this.validate.flTipo = false;
          this.validate.link = false;
          return true;
        } else {
          this.validate.flTipo = false;
          this.validate.link = true;
          return false;
        }
      } else if (this.vaga.fl_tipo_video === '2') {
        if (this.vaga.hl_video.includes('https://player.vimeo.com/video/')) {
          this.validate.flTipo = false;
          this.validate.link = false;
          return true;
        } else {
          this.validate.flTipo = false;
          this.validate.link = true;
          return false;
        }
      } else {
        this.validate.flTipo = true;
        return false;
      }
    },
    changeFlagVideo() {
      if (this.vaga.fl_video === false) {
        //console.log('ref flagvideo ->> ', this.vaga.fl_video);
        this.showPreYoutube = false;
        this.showPreVimeo = false;
        this.vaga.fl_tipo_video = '';
        this.vaga.hl_video = '';
      }
    },
    validaCampos() {
      if (this.vaga.fl_video) {
        if (this.validaUrlVideo()){
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    initValues() {
      setTimeout(() => {
        this.vaga.fl_video = this.propVaga.json_vaga.fl_video;
        this.vaga.fl_tipo_video= this.propVaga.json_vaga.fl_tipo_video;
        this.vaga.hl_video = this.propVaga.json_vaga.hl_video;
      }, 500)
    },
  },
  mounted() {
    this.initValues();
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
</style>
