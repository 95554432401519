<template>
      <div>
        <!-- Inicio da listagem de usuários cadastrados -->
        <card class="no-border-card">
          <template slot="header">
            <h3 class="mb-0">Lista de Usuários</h3>
            <p class="text-sm mb-0">
              {{ total }} usuários cadastrados na MatchJob Empregos.
            </p>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="form-check form-check-inline">
                <base-input v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Procurar...">
                </base-input>
              </div>
            </div>
            <el-table :data="queriedData"
              row-key="id_empresa_user"
              header-row-class-name="thead-light"
              @sort-change="sortChange">
              <el-table-column width="100" label="#" align="center">
                <div class="avatar rounded-circle" slot-scope="{ row }">
                  <img :src="row.hl_foto" alt="Table image" />
                </div>
              </el-table-column>
              <el-table-column min-width="200" sortable prop="nm_nome" label="Nome">
                <div slot-scope="{ row }">
                  <strong>{{ row.nm_nome}}</strong><br>
                  {{ row.hl_email }}
                </div>
              </el-table-column>
              <el-table-column min-width="200" sortable prop="nm_depto" label="Departamento">
                <div slot-scope="{ row }">
                  {{ row.nm_cargo}}<br>
                  {{ row.nm_depto }}
                </div>
              </el-table-column>
              <el-table-column
                min-width="150"
                prop="fl_tipo_acesso"
                label="Tipo de Acesso"
                header-align="center"
                align="center"
                sortable
              >
                <div slot-scope="{ row }">
                  <badge rounded :type="badgeStatusType(row.fl_tipo_acesso)" class="text-dark" size="lg">
                    {{ badgeStatus(row.fl_tipo_acesso) }}</badge>
                </div>
              </el-table-column>
              <el-table-column
                min-width="100"
                prop="fl_status"
                label="Status"
                header-align="center"
                align="center"
                sortable
              >
                <div slot-scope="{ row }">
                  <el-tooltip class="item" effect="dark" content="Usuário Desabilitado!" placement="top"  v-if="row.fl_status > '2'">
                    <i class="icone-ativo fas fa-ban"></i>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Usuário Ativo!" placement="top"  v-else>
                    <i class="icone-desabilitado far fa-check-circle"></i>
                  </el-tooltip>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} em {{ total }} usuarios.</p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { userKey } from "@/global";
import { Table, TableColumn, Select, Option, Autocomplete } from 'element-ui';
import moment from "moment";
import { BasePagination } from '@/components';
import clientPaginationMixin from './clientPaginationMixin';
import swal from 'sweetalert2';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

export default {
  mixins: [clientPaginationMixin, defaultMatchjob],
  components: {
    BasePagination,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      loading: false,
      newUser: {
        id_empresa: "",
        nm_nome: "",
        hl_email: "",
        pw_auto: true,
        pw_senha: "",
        nm_depto: "",
        nm_cargo: "",
        fl_tipo_acesso: "",
        fl_admin: false,
        alteraSenha: false,
        fl_novo: true
      },
      editUser: {
        id_empresa_user: "",
        id_empresa: "",
        nm_nome: "",
        hl_email: "",
        pw_auto: true,
        pw_senha: "",
        nm_depto: "",
        nm_cargo: "",
        fl_tipo_acesso: "",
        fl_admin: false,
        alteraSenha: false,
        fl_novo: false
      },
      usuarios: [],
      propsToSearch: ['nm_nome', 'hl_email'],
      tipoAcesso: [
        { value: '1', text: "Administrador", disabled: true },
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" },
        { value: '5', text: "Gerência de áreas" },
        { value: '6', text: "Coordenação de áreas" },
        { value: '7', text: "Avaliador Técnico" },
        { value: '8', text: "Outras funções" },
        { value: '9', text: "Sem acesso" },
      ],
      cargos: [],
      deptos: [],
      modal: {
        editarUsuario: false,
        novoUsuario: false
      }
    };
  },
  methods: {
    async carregaUsuarios() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin,
        fl_tipo_acesso: this.id.tipoAcesso
      }
      await this.$http.post('usuarios', data).then(res => {
        this.usuarios = res.data.usuarios;
      })
    },
    tiposAcessoAdm() {
      if (this.id.admin){
        this.tipoAcesso = [
          { value: '1', text: 'Administrador', disabled: false },
          { value: '2', text: "Gerência de RH" },
          { value: '3', text: "Coordenação de RH" },
          { value: '4', text: "Recrutadora de RH" },
          { value: '5', text: "Gerência de áreas" },
          { value: '6', text: "Coordenação de áreas" },
          { value: '7', text: "Avaliador Técnico" },
          { value: '8', text: "Outras funções" },
          { value: '9', text: "Sem acesso" },
        ]
      }
    },
    defineDisableNonAdmin(index, row) {
      if (row.fl_admin === '1' && !this.id.admin){
        return true;
      } else if (row.id_empresa_user === this.id.empresa_user) {
        return true;
      }
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
    async mudaStatus(index, row) {
      const data = {
        fl_status: row.fl_status < '3' ? '3' : '2',
        id_empresa_user: row.id_empresa_user
      }
      await this.$http
        .post('usuarios/bloquear', data)
        .then(() => {
          row.fl_status = data.fl_status;
          this.$notify({
            message:
              `Usuário ${data.fl_status < 3 ? '<strong>habilitado</strong>' : '<strong>desabilitado</strong>'} com sucesso!`,
            timeout: 5000,
            icon: 'ni ni-bell-55',
            type: data.fl_status < 3 ? 'success' : 'warning'
          });
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    handleDelete(index, row) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir o usuário:<br> ${row.nm_nome} - ${row.hl_email}`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, excluir!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.deletar(row.id_empresa_user)){
            swal.fire({
              title: 'Excluido!',
              text: `O usuario: ${row.nm_nome} - ${row.hl_email} foi excluído!`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            this.deleteRow(row);
          } else {
            swal.fire({
              title: 'Excluir!',
              text: 'Desculpe! Houve uma falha no servidor.',
              icon: 'error',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          }
        }
      });
    },
    async deletar(id) {
      const data = {
        id_empresa_user: id
      }
      await this.$http
        .post('usuarios/deletar', data)
        .then(() => {
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    handleEdit(index, row) {
      this.editUser.id_empresa_user = row.id_empresa_user,
      this.editUser.id_empresa = this.id.empresa,
      this.editUser.nm_nome = row.nm_nome,
      this.editUser.hl_email = row.hl_email,
      this.editUser.hl_foto = row.hl_foto,
      this.editUser.nm_depto = row.nm_depto,
      this.editUser.nm_cargo = row.nm_cargo,
      this.editUser.fl_tipo_acesso = row.fl_tipo_acesso,
      this.editUser.fl_admin = row.fl_admin === '1' ? true : false,
      this.editUser.alteraSenha = false;
      this.editUser.pw_auto = true;
      this.editUser.pw_senha = "";
      this.editUser.fl_novo = false;
      //Abre a modal de edição
      this.modal.editarUsuario = true;
    },
    //Função utilizada para remover do Array o usuário apagado
    deleteRow(row) {
      let indexToDelete = this.usuarios.findIndex(
        tableRow => tableRow.id_empresa_user === row.id_empresa_user
      );
      if (indexToDelete >= 0) {
        this.usuarios.splice(indexToDelete, 1);
      }
    },
    badgeStatus(value) {
      let tipo = this.tipoAcesso.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n > 0 && n < 5) {
        return "success";
      } else if (n > 4 && n <9) {
        return "primary";
      } else {
        return "warning"
      }
    },
    async validaEmail() {
      await this.$http.get('/cadastro/validaemail', {
        params: {
          email: this.newUser.hl_email
        }
      }).then( res => {
        if (res.data.existente) {
          this.$refs.form.setErrors({
            email: ['Este e-mail já se encontra cadastrado na MatchJob Empregos']
          });
        }
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
    },
    //Função do Autocomplete de Departamentos
    querySearchDepto(queryString, cb) {
      let deptos = this.deptos;
      var results = queryString ? deptos.filter(this.createFilterDepto(queryString)) : deptos;
      cb(results)
    },
    createFilterDepto(queryString) {
      return (depto) => {
        return (depto.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Cargos
    querySearchCargo(queryString, cb) {
      let cargos = this.cargos;
      var results = queryString ? cargos.filter(this.createFilterCargo(queryString)) : cargos;
      cb(results)
    },
    createFilterCargo(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    async loadSysInfos() {
      await this.$http.get('sysinfos/cargos', )
      .then( res => {
        this.cargos = res.data
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
      await this.$http.get('sysinfos/deptos', )
      .then( res => {
        this.deptos = res.data
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
    },
    async editFormSubmit() {
      this.loading = true;
      await this.$http
        .post('usuarios/gravar', this.editUser)
        .then(() => {
          swal.fire({
              title: 'Atualizado!',
              text: `O usuario: ${this.editUser.nm_nome} foi atualizado!`,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
          this.modal.editarUsuario = false;
          this.resetEditUsuario();
          this.carregaUsuarios();
          this.loading = false;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          this.loading = false;
          return false;
        });
    },
    async newFormSubmit() {
      this.loading = true;
      this.newUser.id_empresa = this.id.empresa;
      await this.$http
        .post('usuarios/gravar', this.newUser)
        .then(() => {
          swal.fire({
            title: 'Concluído!',
            text: `O usuario: ${this.newUser.nm_nome} - ${this.newUser.hl_email} foi criado com sucesso!`,
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
          this.modal.novoUsuario = false;
          this.resetNovoUsuario();
          this.carregaUsuarios();
          this.loading = false;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          this.loading = false;
          return false;
        });
    },
    resetEditUsuario() {
      this.editUser.id_empresa_user = null;
      this.editUser.id_empresa = null;
      this.editUser.nm_nome = null;
      this.editUser.hl_email = null;
      this.editUser.hl_foto = null;
      this.editUser.nm_depto = null;
      this.editUser.nm_cargo = null;
      this.editUser.fl_tipo_acesso = null;
      this.editUser.fl_admin = null;
      this.editUser.alteraSenha = null;
      this.editUser.pw_auto = null;
      this.editUser.pw_senha = null;
      this.editUser.fl_novo = null;
    },
    resetNovoUsuario() {
      this.newUser.id_empresa_user = null;
      this.newUser.id_empresa = null;
      this.newUser.nm_nome = null;
      this.newUser.hl_email = null;
      this.newUser.pw_auto = true;
      this.newUser.pw_senha = null;
      this.newUser.nm_depto = null;
      this.newUser.nm_cargo = null;
      this.newUser.fl_tipo_acesso = null;
      this.newUser.fl_admin = false;
      this.newUser.alteraSenha = false;
      this.newUser.fl_novo = true;
    },
    helpTiposAcesso() {
      swal.fire({
        title: 'Tipos de Acesso',
        icon: 'question',
        html: "<p><strong>Administrador</strong><br>" +
              "Realiza qualquer ação, tem controle total.</p>" +
              "<p><strong>RH - Gerencia</strong><br>" +
              "Faz abertura de vagas<br>" +
              "Aprova vagas para publicação<br>" +
              "Aprova candidatos para vagas pulicadas<br>" +
              "Busca candidatos para vagas pulicadas<br>" +
              "Gerencia os usuários</p>" +
              "<p><strong>RH - Coordenação</strong><br>" +
              "Faz abertura de vagas<br>" +
              "Aprova vagas para publicação<br>" +
              "Aprova candidatos para vagas pulicadas</p>" +
              "<p><strong>RH - Recrutadora</strong><br>" +
              "Faz abertura de vagas<br>" +
              "Busca candidatos para vagas pulicadas</p>" +
              "<p><strong>Gerencia de áreas</strong><br>" +
              "Solicita abertura de vagas<br>" +
              "Aprova vagas para publicação<br>" +
              "Aprova candidatos para vagas pulicadas</p>" +
              "<p><strong>Coordenação de áreas</strong><br>" +
              "Aprova vagas para publicação<br>" +
              "Aprova candidatos para vagas pulicadas</p>" +
              "<p><strong>Avaliador Técnico</strong><br>" +
              "Aprova candidatos para vagas pulicadas</p>" +
              "<p><strong>Outras funções</strong><br>" +
              "Nenhuma função **reservado p/ futuro</p>" +
              "<p><strong>Sem acesso</strong><br>" +
              "Nenhuma função</p>",
        confirmButtonClass: 'btn btn-success btn-fill',
        buttonsStyling: false
      });
    }
  },
  computed: {},
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.initVariaveis();
    this.carregaUsuarios();
    this.loadSysInfos();
    this.tiposAcessoAdm();
  }
};
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
  .icone-ativo {
    font-size: 1.2rem;
    color: red;
  }
  .icone-desabilitado {
    font-size: 1.2rem;
    color: green;
  }
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
</style>
