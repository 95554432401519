<template>
  <div>
    <template v-if="entrevistas.length > 1">
      <div class="row mt-1">
        <div class="col">
          <label for="entrevistaRealizada">Selecione a entrevista <span class="text-danger">*</span></label>
          <el-select
            ref="entrevistaRealizada"
            name="Selecione a entrevista"
            class="inuptAutocomplete"
            label="Selecione a entrevista"
            id="entrevistaRealizada"
            v-model="idEntrevistaSelecionada"
          >
            <el-option
              v-for="(entrevista, index) in entrevistas"
              :key="index"
              :value="index"
              :label="entrevista.entrevistador.nome === '' ? retornaEntrevistador(entrevista.entrevistador.id_empresa_user) : entrevista.entrevistador.nome"
            >
              <span style="float: left">{{ entrevista.entrevistador.nome === "" ? retornaEntrevistador(entrevista.entrevistador.id_empresa_user) : entrevista.entrevistador.nome}} </span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ entrevista.data_entrevista | formatDateTime }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- Flag de entrevista realizada -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.entrevistaRealizada"
          ></base-switch>
        </div>
        <div class="col-auto">
          <label for="entrevistaRealizada">A entrevista foi realizada conforme o agendamento?<br>
            <strong> {{entrevistas[idEntrevistaSelecionada].data_entrevista | formatDateTime}}</strong></label><br>
        </div>
      </div>
      <!-- Flag Reqs Minimos -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.req_minimos"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato preenche os requisitos mínimos da vaga, incluindo formação acadêmica, experiência e habilidades técnicas?</label><br>
        </div>
      </div>
      <!-- Flag Disponibilidade -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.inicio_imediato"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato tem disponibilidade imediata para começar a trabalhar?</label>
        </div>
      </div>
      <!-- Flag Disponibilidade -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.estilo_compativel"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">A metodologia ou estilo de trabalho do candidato é compatível com o seu e com o de sua equipe?</label>
        </div>
      </div>
      <!-- Flag Aprovado -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.aprovado"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato está aprovado?</label>
        </div>
      </div>
      <!-- Flag Avaliar competências -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="flAvaliarComps"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">Deseja avaliar as competências incluídas no Processo Seletivo?</label>
        </div>
      </div>
      <!-- Comentários sobre as competencias incluídas no processo seletivo -->
      <template v-if="flAvaliarComps">
      <div class="row" v-for="(comp, index) in propCompetencias" :key="index">
        <div class="col">
          <base-input :label="comp">
            <textarea class="form-control" :id="`anotacoesCompetencias${index}`" rows="5" v-model="registroEntrevista.result_competencias[index]"></textarea>
          </base-input>
        </div>
      </div>
      </template>
      <!-- Anotações Gerais -->
      <div class="row">
        <div class="col">
          <base-input label="Anotações Gerais">
            <textarea class="form-control" id="anotacoesEntrevista" rows="10" v-model="registroEntrevista.comentarios"></textarea>
          </base-input>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Flag de entrevista realizada -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.entrevistaRealizada"
          ></base-switch>
        </div>
        <div class="col-auto">
          <label for="entrevistaRealizada">A entrevista foi realizada conforme o agendamento?<br>
            <strong> {{entrevistas[0].data_entrevista | formatDateTime}}</strong></label><br>
        </div>
      </div>
      <!-- Flag Reqs Minimos -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.req_minimos"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato preenche os requisitos mínimos da vaga, incluindo formação acadêmica, experiência e habilidades técnicas?</label><br>
        </div>
      </div>
      <!-- Flag Disponibilidade -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.inicio_imediato"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato tem disponibilidade imediata para começar a trabalhar?</label>
        </div>
      </div>
      <!-- Flag Perfil Compativel -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.estilo_compativel"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">A metodologia ou estilo de trabalho do candidato é compatível com o seu e com o de sua equipe?</label>
        </div>
      </div>
      <!-- Flag Aprovado -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="registroEntrevista.aprovado"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">O candidato está aprovado?</label>
        </div>
      </div>
      <!-- Flag Avaliar competências -->
      <div class="row mt-1">
        <div class="col-auto align-self-center">
          <base-switch
            ref="entrevistaRealizada"
            v-model="flAvaliarComps"
          ></base-switch>
        </div>
        <div class="col-10">
          <label for="entrevistaRealizada">Deseja avaliar as competências incluídas no Processo Seletivo?</label>
        </div>
      </div>
      <!-- Comentários sobre as competencias incluídas no processo seletivo -->
      <template v-if="flAvaliarComps">
      <div class="row" v-for="(comp, index) in propCompetencias" :key="index">
        <div class="col">
          <base-input :label="comp">
            <textarea class="form-control" :id="`anotacoesCompetencias${index}`" rows="5" v-model="registroEntrevista.result_competencias[index]"></textarea>
          </base-input>
        </div>
      </div>
      </template>
      <!-- Anotações Gerais -->
      <div class="row">
        <div class="col">
          <base-input label="Anotações Gerais">
            <textarea class="form-control" id="anotacoesEntrevista" rows="10" v-model="registroEntrevista.comentarios"></textarea>
          </base-input>
        </div>
      </div>
    </template>
   <!--  idEntrevistaSelecionada -> {{idEntrevistaSelecionada}}
    {{registroEntrevista}}
    {{propCompetencias}}
    entrevistasRealizadas -> {{entrevistasRealizadas}}
    <pre>{{entrevistas}}</pre> -->
    <div slot="footer">
      <base-button type="secondary" class="ml-3" @click="fecharModal()">Cancelar</base-button>
      <base-button type="primary" class="mr-3 ml-auto" @click="salvaRegistro()">Salvar</base-button>
    </div>
  </div>
</template>

<script>
import { Loading, Select, Option, Autocomplete } from 'element-ui';
import moment from "moment";

export default {
  name: "RegistroEntrevista",
  props: {
    propEntrevistas: { type: Array }, //Conteudo da timeline do candidato
    propTipoEntrevista: { type: String },// String com o flag do tipo da entrevista
    propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    concluiRegistro: { type: Function },
    closeModal: { type: Function },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      registroEntrevista: {
        id: "",
        tipo_entrevista: "",
        data_entrevista: "",
        entrevistador: {
          nome: "",
          email: "",
          id_empresa_user: ""
        },
        entrevistaRealizada: true,
        req_minimos: false, //Pergunta - O candidato preenche os requisitos mínimos da vaga, incluindo formação acadêmica, experiência e habilidades técnicas?
        inicio_imediato: false, //Pergunta - O candidato tem disponibilidade imediata para começar a trabalhar?
        estilo_compativel: false, //Pergunta - A metodologia ou estilo de trabalho do candidato é compatível com o seu e com o de sua equipe?
        aprovado: false,
        comentarios: "",
        result_competencias: [],
      },
      entrevistasRealizadas: [],
      idEntrevistaSelecionada: 0,
      flAvaliarComps: false
    }
  },
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    entrevistas() {
      let tipoEntrevista = this.propEntrevistas.filter(e => e.tipo_registro === 'entrevista');
      return tipoEntrevista.filter(a => a.tipo_entrevista === this.propTipoEntrevista);
    }
  },
  methods: {
    retornaEntrevistador(id) {
      if(id){
        var user = this.usuarios.find(i => i.id_empresa_user === id);
        return user.nm_nome;
      }
    },
    salvaRegistro() {
      this.registroEntrevista.tipo_entrevista = this.propTipoEntrevista;
      this.registroEntrevista.id = this.entrevistas[this.idEntrevistaSelecionada].id;
      this.registroEntrevista.data_entrevista = this.entrevistas[this.idEntrevistaSelecionada].data_entrevista;
      this.registroEntrevista.entrevistador.nome = this.entrevistas[this.idEntrevistaSelecionada].entrevistador.nome;
      this.registroEntrevista.entrevistador.email = this.entrevistas[this.idEntrevistaSelecionada].entrevistador.email;
      this.registroEntrevista.entrevistador.id_empresa_user = this.entrevistas[this.idEntrevistaSelecionada].entrevistador.id_empresa_user;
      let temp_result_competencias = []
      this.registroEntrevista.result_competencias.forEach((element, index) => {
        let tempArr = {
          comp: this.propCompetencias[index],
          text: element
        }
        temp_result_competencias.push(tempArr)
      })
      this.registroEntrevista.result_competencias = temp_result_competencias;
      this.registroEntrevista.result_competencias
      this.entrevistasRealizadas.push(this.registroEntrevista)
      setTimeout(() => {
        this.$emit('resRegistroEntrevista', this.entrevistasRealizadas);
        this.concluiRegistro()
      }, 500);
    },
    fecharModal() {
      this.closeModal();
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style>

</style>
