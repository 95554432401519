<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="fas fa-tasks"></i>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid w-75" src="https://storagematchjobdefault.blob.core.windows.net/matchjob/icone-50x50.png" alt="MatchJob Empregos" />
          </div>
          <div class="col-11 align-self-center">
            <small><strong>MatchJob Empregos</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            Teste {{propItem.fl_tipo === 'enviaTestes' ? '' : 'de perfil '}}respondido pelo candidato!<br>
            Teste Enviado: <strong>{{retornaNomeTeste(propItem.fl_tipo, propItem.id_teste)}}</strong><br>
            <!-- Detalhes do teste: <em>{{retornaDescricaoTeste(propItem.id_teste)}}</em><br>
            Categoria do Teste: <strong>{{retornaCategoriaTeste(propItem.id_teste)}}</strong><br>
            Tipo do Teste: <strong>{{propItem.fl_origem === 'interno' ? 'Interno' : 'Externo'}}</strong><br>
            Link: <small>https://vagas.matchjob.com.br/{{propItem.url_empresa}}/teste/{{propItem.hl_url}}</small><br> -->
            <!-- <pre>{{propItem}}</pre> -->
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";
export default {
  name: "CompTesteRespondido",
  mixins: [defaultMatchjob],
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      usuarios: [],
      flDetalhes: false
    }
  },
  computed: {
    usuariosState() {
      return this.$store.state.usuarios.usuarios
    },
    testesPerfil() {
      return this.$store.state.testes.testesPerfil;
    },
    testes() {
      return this.$store.state.testes.testes;
    },
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuariosState.length > 0) {
        var user = this.usuariosState.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    },
    retornaCategoriaTeste(tipo, id) {
      if(tipo === 'enviaTestes') {
        var teste = this.testes.find(t => t.id_teste === id)
      } else {
        var teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_categoria
    },
    retornaDescricaoTeste(tipo, id) {
      if(tipo === 'enviaTestes') {
        var teste = this.testes.find(t => t.id_teste === id)
      } else {
        var teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_descricao
    },
    retornaNomeTeste(tipo, id) {
      var teste = []
      if(tipo === 'enviaTestes') {
        teste = this.testes.find(t => t.id_teste === id)
      } else {
        teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_nome;
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
