<template>
  <div v-loading="loading">
    <div class="row" ref="topo" >
      <div class="col">
        Nome do teste: <strong>{{ propNewTesteFinal.nm_nome }}</strong><br>
        <small>Descrição: <em>{{ propNewTesteFinal.nm_descricao }}</em></small>
      </div>
      <div class="col mr-auto">
        Tipo do teste: <strong>{{ retornaNomeTipoTeste(propNewTesteFinal.nm_tipo) }}</strong><br>
        <template class="col" v-if="propNewTesteFinal.nm_tipo === 'tempo'">
          Tempo de teste: <small><strong>{{ propNewTesteFinal.nu_tempo }} minuto{{ parseInt(propNewTesteFinal.nu_tempo) > 1 ? 's' : '' }}</strong></small><br>
        </template>
        <small>Categoria: <em>{{ propNewTesteFinal.nm_categoria }}</em></small>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><hr></div>
    </div>
    <transition name="fade" mode="out-in">
    <div v-if="!flRespondido">
      <div v-for="test in propNewTest" :key="test.id" :ref="test.id">
        <template v-if="test.type === 'text'">
          <TextoSimples
            :propValue="test.value"
            :propRequired="test.required"
            @resp="test.resp = $event"
          />
        </template>
        <template v-if="test.type === 'dico' || test.type === 'ru'">
          <RespostaUnica
            :propValue="test.value"
            :propRequired="test.required"
            :propOptions="test.options"
            @resp="test.resp = $event"
          />
        </template>
        <template v-if="test.type === 'multi'">
          <MultiplaEscolha
            :propValue="test.value"
            :propRequired="test.required"
            :propOptions="test.options"
            @resp="test.resp = $event"
          />
        </template>
        <template v-if="test.type === 'likert'">
          <MatrizLikertNps
            :propValue="test.value"
            :propRequired="test.required"
            :propOptions="test.options"
            @resp="test.resp = $event"
          />
        </template>
        <template v-if="test.type === 'nps'">
          <MatrizLikertNps
            :propValue="test.value"
            :propRequired="test.required"
            :propOptions="nps"
            @resp="test.resp = $event"
          />
        </template>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="col-2">
          <base-button type="primary" class="mr-3 ml-auto" @click="submitQuestionario">Enviar</base-button>
        </div>
      </div>
    </div>
    <div v-else>
      <ApresentaResultadosSimples
        :propPerguntas="propNewTest"
      />
    </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'element-ui';
import moment from "moment";
import TextoSimples from '../../components/Testes/TextoSimples.vue';
import RespostaUnica from '../../components/Testes/RespostaUnica.vue';
import MultiplaEscolha from '../../components/Testes/MultiplaEscolha.vue';
import MatrizLikertNps from '../../components/Testes/MatrizLikertNps.vue';
import ApresentaResultadosSimples from '../../components/Testes/ApresentaResultadosSimples.vue';

Vue.use(Loading.directive);

export default {
  name: "VisualizaTesteSimples",
  components: {
    TextoSimples,
    RespostaUnica,
    MultiplaEscolha,
    MatrizLikertNps,
    ApresentaResultadosSimples
  },
  props: {
    propNewTesteFinal: { type: Object },
    propNewTest: { type: Array }
  },
  data() {
    return {
      loading: false,
      flRespondido: false,
      tipoTestes: [
        { value: 'simples', text: 'Simples' },
        { value: 'step', text: 'Simples - Step-by-Step' },
        { value: 'avaliacao', text: 'Avaliação Técnica' },
        { value: 'auto', text: 'Avaliação Técnica - Autocorreção' },
        { value: 'tempo', text: 'Aval. Téc. - Autocorreção - Temporizada' },
      ],
      nps: [
        { id: 1, value: 1, text: '1' },
        { id: 2, value: 2, text: '2' },
        { id: 3, value: 3, text: '3' },
        { id: 4, value: 4, text: '4' },
        { id: 5, value: 5, text: '5' },
        { id: 6, value: 6, text: '6' },
        { id: 7, value: 7, text: '7' },
        { id: 8, value: 8, text: '8' },
        { id: 9, value: 9, text: '9' },
        { id: 10, value: 10, text: '10' },
      ],
    }
  },
  methods: {
    initPage(){
      this.loading = true;
      setTimeout(() => {
        this.goto('topo');
        this.loading = false;
      }, 500);
    },
    retornaNomeTipoTeste(value){
      var res = this.tipoTestes.find(i => i.value === value);
      return res.text;
    },
    validaRespostas() {
      const res = this.propNewTest.every(teste => {
        if (teste.required){
          if (teste.type === 'multi'){
            if (teste.resp.length < 1) {
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              this.goto(teste.id);
              return false;
            } else {
              return true;
            }
          } else {
            if (teste.resp === '') {
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              this.goto(teste.id);
              return false;
            } else {
              return true;
            }
          }
        } else {
          return true;
        }
      });
      return res;
    },
    submitQuestionario(){
      if (this.validaRespostas()){
        this.loading = true;
        setTimeout(() => {
          this.goto('topo');
          this.loading = false;
          this.flRespondido = true;
        }, 500);
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  filters: {
    formatDateUnixTime: function(value) {
      if (value) {
        return moment.unix(value.seconds).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.initPage();
  }
}
</script>

<style>
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
</style>
