<template>
  <div>
    <!-- Verifica a existencia de mais de 1 workflow -->
    <template v-if="propProcessoSelecao.length > 1">
      <!-- Seleção do Workflow da vaga -->
      <div class="row justify-content-center mt-3">
        <div class="col-4">
          <label for="workflow">Selecione o Workflow para esta vaga <span class="text-danger">*</span></label>
          <el-select
            ref="workflow"
            name="Workflow"
            class="inuptAutocomplete"
            id="workflow"
            v-model="processoVaga.id"
            @change="alteraProcesso"
          >
            <el-option
              v-for="proc in propProcessoSelecao"
              :key="proc.id"
              :value="proc.id"
              :label="proc.nm_nome"
            ></el-option>
          </el-select>
        </div>
        <!-- Valida o plano de aprovação de vagas -> ps.fluxoVagas -->
        <template v-if="propPlano.ps.fluxoVagas">
          <div class="col-4 align-items-end align-self-end text-center">
            <p class="font-weight-normal" v-if="processoVaga.requerAprovacao">
              <small>Este processo requer aprovação antes da publicação da vaga.</small>
            </p>
            <p class="font-weight-normal" v-else>
              <small>Este processo não requer aprovação antes da publicação da vaga.</small>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="col-4 align-items-end align-self-end text-center">
            <p class="font-weight-normal">
              <small>Este processo não requer aprovação antes da publicação da vaga.</small>
            </p>
          </div>
        </template>
      </div>
      <!-- Plano permite o fluxo de vagas -->
      <template v-if="propPlano.ps.fluxoVagas">
        <div class="row justify-content-center mt-3" v-if="processoVaga.requerAprovacao">
          <div class="col-4">
            <p class="font-weight-normal">
              Aprovação do RH: <br>
              <strong>
                <template v-if="processoVaga.aprovacaoTipo.tipoRh === 'area'">
                  {{retornaTipoRh(processoVaga.aprovacaoTipo.tipoAcesso)}}
                </template>
                <template v-else>
                  {{retornoNomeUsuarioAprovador(processoVaga.aprovacaoTipo.usuario)}}
                </template>
              </strong>
            </p>
          </div>
          <div class="col-4">
            Aprovação do Solicitante:<br>
            <strong>
              <template v-if="processoVaga.solicitanteAprova">
                {{propVaga.nm_solicitante}}
              </template>
              <template v-else>
                Não é necessária aprovação do solicitante.
              </template>
            </strong>
          </div>
        </div>
        <!-- Linha de adição de novos aprovadores da vaga -->
        <div class="row justify-content-center mt-3">
          <!-- Flag de inclusão de novos aprovadores -->
          <div class="col-4 align-items-center">
            <label for="incluiOutrosAprovadores">Deseja incluir aprovadores para esta vaga?</label><br>
            <base-switch
              id="incluiOutrosAprovadores"
              :disabled="processoVaga.outrosAprovadores.length > 0"
              v-model="processoVaga.flOutrosAprovadores"
            ></base-switch>
          </div>
          <!-- Select de usuários -->
          <div class="col-3 align-items-center">
            <label for="outrosAprovadores">Selecione o usuário <span class="text-danger">*</span></label>
            <el-select
              ref="outrosAprovadores"
              name="outrosAprovadores"
              class="inuptAutocomplete"
              id="outrosAprovadores"
              :disabled="!processoVaga.flOutrosAprovadores"
              v-model="addAprovadores"
            >
              <el-option
                v-for="user in propUsuarios"
                :key="user.id_empresa_user"
                :value="user.id_empresa_user"
                :label="user.nm_nome"
              ></el-option>
            </el-select>
          </div>
          <!-- Botão add -->
          <div class="col-1 align-items-end align-self-end px-0">
            <base-button
              type="default"
              size="sm"
              :disabled="!processoVaga.flOutrosAprovadores"
              @click="addOutroAprovador"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Linhas de Apresentação dos Aprovadores Adicionados -->
        <div class="row justify-content-center mt-3" v-if="processoVaga.outrosAprovadores.length > 0">
          <div class="col-8 align-items-center">
            <p class="font-weight-normal">Aprovadores adicionais:</p>
            <ul class="list-unstyled">
              <li v-for="user in processoVaga.outrosAprovadores" :key="user.id_empresa_user">
                <el-tooltip placement="left" content="Remover" effect="light">
                  <i class="text-danger fas fa-trash-alt" @click="removeOutroAprovador(user.id_empresa_user)"></i>
                </el-tooltip> -
                {{user.nm_nome}} -
                <span class="text-muted"><small>{{user.nm_cargo}}</small></span> -
                <small>{{user.hl_email}}</small>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </template>
    <!-- Caso exista apenas 1 Workflow -->
    <template v-else>
      <!-- Valida o plano de aprovação de vagas -> ps.fluxoVagas -->
      <template v-if="propPlano.ps.fluxoVagas">
        <div class="row justify-content-center mt-3" v-if="processoVaga.requerAprovacao">
          <div class="col-4">
            <p class="font-weight-normal">
              Aprovação do RH: <br>
              <strong>
                <template v-if="processoVaga.aprovacaoTipo.tipoRh === 'area'">
                  {{retornaTipoRh(processoVaga.aprovacaoTipo.tipoAcesso)}}
                </template>
                <template v-else>
                  {{retornoNomeUsuarioAprovador(processoVaga.aprovacaoTipo.usuario)}}
                </template>
              </strong>
            </p>
          </div>
          <div class="col-4">
            Aprovação do Solicitante:<br>
            <strong>
              <template v-if="processoVaga.solicitanteAprova">
                {{propVaga.nm_solicitante}}
              </template>
              <template v-else>
                Não é necessária aprovação do solicitante.
              </template>
            </strong>
          </div>
        </div>
        <!-- Linha de adição de novos aprovadores da vaga -->
        <div class="row justify-content-center mt-3">
          <!-- Flag de inclusão de novos aprovadores -->
          <div class="col-4 align-items-center">
            <label for="incluiOutrosAprovadores">Deseja incluir aprovadores para esta vaga?</label><br>
            <base-switch
              id="incluiOutrosAprovadores"
              :disabled="processoVaga.outrosAprovadores.length > 0"
              v-model="processoVaga.flOutrosAprovadores"
            ></base-switch>
          </div>
          <!-- Select de usuários -->
          <div class="col-3 align-items-center">
            <label for="outrosAprovadores">Selecione o usuário <span class="text-danger">*</span></label>
            <el-select
              ref="outrosAprovadores"
              name="outrosAprovadores"
              class="inuptAutocomplete"
              id="outrosAprovadores"
              :disabled="!processoVaga.flOutrosAprovadores"
              v-model="addAprovadores"
            >
              <el-option
                v-for="user in propUsuarios"
                :key="user.id_empresa_user"
                :value="user.id_empresa_user"
                :label="user.nm_nome"
              ></el-option>
            </el-select>
          </div>
          <!-- Botão add -->
          <div class="col-1 align-items-end align-self-end px-0">
            <base-button
              type="default"
              :disabled="!processoVaga.flOutrosAprovadores"
              size="sm"
              @click="addOutroAprovador"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Linhas de Apresentação dos Aprovadores Adicionados -->
        <div class="row justify-content-center mt-3" v-if="processoVaga.outrosAprovadores.length > 0">
          <div class="col-8 align-items-center">
            <p class="font-weight-normal">Aprovadores adicionais:</p>
            <ul class="list-unstyled">
              <li v-for="user in processoVaga.outrosAprovadores" :key="user.id_empresa_user">
                <el-tooltip placement="left" content="Remover" effect="light">
                  <i class="text-danger fas fa-trash-alt" @click="removeOutroAprovador(user.id_empresa_user)"></i>
                </el-tooltip> -
                {{user.nm_nome}} -
                <span class="text-muted"><small>{{user.nm_cargo}}</small></span> -
                <small>{{user.hl_email}}</small>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row justify-content-center mt-3">
          <div class="col-8 align-items-center">
            Este plano não tem fluxo de aprovação de vagas
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  name: "ProcessoSeletivoComp",
  props: {
    propProcessoSelecao: { type: Array },
    propUsuarios: { type: Array },
    propPlano: { type: Object },
    propVaga: { type: Object },
    propEmit: { type: Boolean }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      processoVaga: {
        id: "",
        requerAprovacao: true,
        solicitanteAprova: true,
        aprovacaoTipo: {},
        flOutrosAprovadores: false,
        outrosAprovadores: [],
        fl_status: "1"
      },
      addAprovadores: "",
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
    }
  },
  methods: {
    firstFormSubmit() {
      this.$emit('resStep8', this.processoVaga);
    },
    initValues() {
      setTimeout(() => {
        var idProcessoPadrao = structuredClone(this.propProcessoSelecao.find(i => i.fl_default === true));
        this.processoVaga.id = idProcessoPadrao.id;
        var publicacaoVaga = idProcessoPadrao.fases.find(p => p.tipo === 'publicacaoVaga');
        if (this.propPlano.ps.fluxoVagas) {
          this.processoVaga.requerAprovacao = publicacaoVaga.configs.requerAprovacao;
          this.processoVaga.solicitanteAprova = publicacaoVaga.configs.solicitanteAprova;
          this.processoVaga.aprovacaoTipo = publicacaoVaga.configs.aprovacaoTipo;
        } else {
          this.processoVaga.requerAprovacao = false;
          this.processoVaga.solicitanteAprova = false;
          this.processoVaga.aprovacaoTipo = {
            tipoRh: "",
            usuario: "",
            tipoAcesso: ""
          }
        }
      }, 500)
    },
    alteraProcesso(value) {
      var selected = this.propProcessoSelecao.find(p => p.id === value);
      var fases = selected.fases.find(f => f.tipo === 'publicacaoVaga');
      this.processoVaga.id = value;
      this.processoVaga.requerAprovacao = fases.configs.requerAprovacao;
      this.processoVaga.solicitanteAprova = fases.configs.solicitanteAprova;
      this.processoVaga.aprovacaoTipo = fases.configs.aprovacaoTipo;
    },
    retornoNomeUsuarioAprovador(id) {
      if (this.propUsuarios.length > 0 && id) {
        var user = this.propUsuarios.find(u => u.id_empresa_user === id)
        return user.nm_nome;
      } else {
        return "";
      }
    },
    addOutroAprovador() {
      var user = this.propUsuarios.find(u => u.id_empresa_user === this.addAprovadores)
      //Valida se o usuário a ser adicionado já é aprovador do RH
      if (this.processoVaga.requerAprovacao === true && this.processoVaga.aprovacaoTipo.tipoRh === 'usuario' && this.processoVaga.aprovacaoTipo.usuario === user.id_empresa_user) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
      } /* else if (this.processoVaga.requerAprovacao === true && this.processoVaga.aprovacaoTipo.tipoRh === 'area' && this.processoVaga.aprovacaoTipo.tipoAcesso === user.fl_tipo_acesso) {
        console.log('entrou no segundo if ->>>>')
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
        this.loading = false;
      } */ else if (this.processoVaga.solicitanteAprova === true && this.propVaga.nm_solicitante === user.nm_nome) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
      } else if (this.processoVaga.outrosAprovadores.findIndex(i => i.id_empresa_user === user.id_empresa_user) > -1) {
        this.alertShow("Ops!", "Este usuário já é aprovador dessa vaga!", "error");
      } else {
        var newAprovador = {
          id_empresa_user: user.id_empresa_user,
          nm_nome: user.nm_nome,
          hl_email: user.hl_email,
          nm_cargo: user.nm_cargo
        }
        setTimeout(() => {
          this.processoVaga.outrosAprovadores.push(newAprovador);
          this.addAprovadores = "";
        }, 500);
      }
    },
    removeOutroAprovador(id) {
      var pos = this.processoVaga.outrosAprovadores.findIndex(i => i.id_empresa_user === id);
      this.processoVaga.outrosAprovadores.splice(pos, 1);//Remove o Objeto do Array
      var tam = this.processoVaga.outrosAprovadores.length;//Verifica o tamanho do array após a remoção
      if (tam < 1) {//valida se é o último do array
        this.processoVaga.flOutrosAprovadores = false;//Desativa o flag de outros aprovadores > Último usuário do array
        //this.ajustaAprovacaoByPlano(); //Ajusta o flag requerAprovacao conforme o workflow
      }
      setTimeout(() => {
      }, 500);
    },
    ajustaAprovacaoByPlano() {
      var selected = this.propProcessoSelecao.find(p => p.id === this.processoVaga.id);
      var fases = selected.fases.find(f => f.tipo === 'publicacaoVaga');
      this.processoVaga.requerAprovacao = fases.configs.requerAprovacao;
    },
    retornaTipoRh(value) {
      var res = this.tipoAcesso.find(i => i.value === value);
      return res.text;
    },
  },
  mounted() {
    this.initValues();
    //this.loadSysInfos();
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
