<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-10 text-center">
        <h1 class="display-4">Resumo do Processo Seletivo</h1>
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-10 align-self-center">
        <label for="nomePs">Nome do Processo Seletivo</label>
        <input
          id="nomePs"
          ref="nomePs"
          type="text"
          class="form-control"
          name="Nome do Processo Seletivo"
          placeholder="Defina um nome para este processo seletivo"
          v-model.trim.lazy="propResumo.nm_nome"
          @blur="validaOnBlur()"
        />
      </div>
    </div>
    <div class="row justify-content-center mb-3">
      <div class="col-5">
        <p class="font-weight-normal mb-0">Fases definidas:</p>
        <ol>
          <li v-for="fase in propResumo.fases" :key="fase.id">{{fase.value}}</li>
        </ol>
      </div>
      <div class="col-5">
        <p class="font-weight-normal mb-0">Competências definidas:</p>
        <ol>
          <li v-for="(competencia, index) in propResumo.competencias" :key="index">{{competencia}}</li>
        </ol>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        propResumo -> <pre>{{propResumo}}</pre>
      </div>
    </div> -->
    <div class="row justify-content-center mt-5">
      <div class="col text-center">
        <base-button type="secondary" class="mr-3 ml-auto" @click="voltarStep4">Voltar</base-button>
        <base-button
          type="primary"
          class="mr-3 ml-auto"
          :disabled="propResumo.nm_nome === ''"
          @click="concluiStep4">Salvar</base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumoNovoPs",
  props: {
    propResumo: { type: Object },
    voltarStep4: { type:  Function },
    concluiStep4: { type: Function }
  },
  methods: {
    validaOnBlur(){
      if (this.propResumo.nm_nome === '') {
        this.propResumo.nm_nome = 'Novo Processo'
      }
    },
  }
}
</script>

<style>

</style>
