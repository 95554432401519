<template>
  <div>
    <collapse>
      <collapse-item name="1">
        <h4 class="m-0" slot="title">Para que servem os Usuários?</h4>
        <p class="font-weight-normal">A sessão "Usuários" é utilizada para gerenciar os usuários utilizadores do sistema MatchJob Empresas.</p>
        <p class="font-weight-normal">Além do acesso ao sistema, os usuários são definidos como "Usuários de RH" que gerenciam o sistema e os processos seletivos e "Usuários Comuns" que podem ser solicitante de vagas.</p>
      </collapse-item>
      <collapse-item name="2">
        <h4 class="m-0" slot="title">O que são Usuários de RH?</h4>
        <p class="font-weight-normal">Os usuários de RH gerenciam o sistema como um todo.<br />Podem realizar a criação de usuários do sistema, endereços (locais), testes, vagas e gerenciar os processos seletivos.</p>
      </collapse-item>
      <collapse-item name="3">
        <h4 class="m-0" slot="title">O que são Usuários Comuns?</h4>
        <p class="font-weight-normal">Os usuários comuns podem acessar o sistema para solicitar a criação de uma vaga, aprovar uma vaga para a publicação e participar dos processos de seleção.</p>
      </collapse-item>
      <collapse-item name="4">
        <h4 class="m-0" slot="title">Quais são os tipos de acesso?</h4>
        <p><strong>Administrador</strong><br>
        Realiza qualquer ação, tem controle total.</p>
        <p><strong>RH - Gerencia</strong><br>
        Faz abertura de vagas<br>
        Aprova vagas para publicação<br>
        Aprova candidatos para vagas pulicadas<br>
        Busca candidatos para vagas pulicadas<br>
        Gerencia os usuários</p>
        <p><strong>RH - Coordenação</strong><br>
        Faz abertura de vagas<br>
        Aprova vagas para publicação<br>
        Aprova candidatos para vagas pulicadas</p>
        <p><strong>RH - Recrutadora</strong><br>
        Faz abertura de vagas<br>
        Busca candidatos para vagas pulicadas</p>
        <p><strong>Gerencia de áreas</strong><br>
        Solicita abertura de vagas<br>
        Aprova vagas para publicação<br>
        Aprova candidatos para vagas pulicadas</p>
        <p><strong>Coordenação de áreas</strong><br>
        Aprova vagas para publicação<br>
        Aprova candidatos para vagas pulicadas</p>
        <p><strong>Avaliador Técnico</strong><br>
        Aprova candidatos para vagas pulicadas</p>
        <p><strong>Outras funções</strong><br>
        Nenhuma função **reservado p/ futuro</p>
        <p><strong>Sem acesso</strong><br>
        Nenhuma função</p>
        <p class="font-weight-normal">
        </p>
      </collapse-item>
       <collapse-item name="5">
        <h4 class="m-0" slot="title">O que é um usuário "Full Admin"?</h4>
        <p class="font-weight-normal">A flag "Full Admin" sobrepõe qualquer tipo de acesso e concede controle total no sistema.</p>
      </collapse-item>
    </collapse>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

export default {
  name: "HelpUsuarios",
  components: {
    Collapse,
    CollapseItem,
  }
}
</script>

<style>

</style>
