<template>
  <div v-loading="loading.full">
    <!-- <div class="row justify-content-center mt-2">
      <div class="col text-center">
        <h1 class="display-2">Competências</h1>
      </div>
    </div> -->
    <div class="row justify-content-center mt-2">
      <div class="col-8 text-center">
        <h1 class="display-4">Defina as competências que serão avaliadas neste processo seletivo.</h1>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-4">
        <div class="row">
          <div class="col">
            <label for="competencias">Quais competências você deseja avaliar?</label>
          </div>
        </div>
        <div class="row">
          <div class="col pr-1">
            <el-autocomplete
              class="inuptAutocomplete"
              v-model="competenciasTemp"
              :fetch-suggestions="querySearchComp"
              placeholder="Competências"
              :trigger-on-focus="false"
              @select="addCompetenciaAvaliacao"
            ></el-autocomplete>
          </div>
          <div class="col-2 align-self-center px-1">
            <base-button
              type="default"
              size="sm"
              class="btn-icon-only"
              @click="addCompetenciaAvaliacao()"
            ><span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            </base-button>
          </div>
        </div>
      </div>
      <div class="col-4" v-loading="loading.compTemp">
        <table class="w-100" v-if="competencias.length > 0">
          <tr class="border-bottom" v-for="comp in competencias" :key="comp">
            <td class="w-75 pt-3">{{comp}}</td>
            <td class="pt-3 w-25 text-right">
              <span class="text-default mx-1" v-if="butUp(comp)">
                <i class="removeButton fas fa-arrow-up" @click="moveUp(comp)"></i>
              </span>
              <span class="text-default mx-1" v-if="butDown(comp)">
                <i class="removeButton fas fa-arrow-down" @click="moveDown(comp)"></i>
              </span>
              <span class="text-danger mx-1">
                <i class="removeButton fas fa-trash-alt" @click="removeCompetencia(comp)"></i>
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col text-center">
        <base-button type="secondary" class="mr-3 ml-auto" @click="voltarStep2">Voltar</base-button>
        <base-button
          type="primary"
          class="mr-3 ml-auto"
          :disabled="competencias.length < 1"
          @click="submitComps"
        >Proximo</base-button>
      </div>
    </div>
    <!-- <pre>{{competencias}}</pre> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading, Autocomplete } from 'element-ui';

Vue.use(Loading.directive);

export default {
  name: "CompetenciasAvaliadas",
  components: {
    [Autocomplete.name]: Autocomplete
  },
  props: {
    propCompetencias: { type: Array },
    concluiStep1: { type: Function },
    voltarStep2: { type: Function }
  },
  data() {
    return {
      loading: {
        compTemp: false,
      },
      competenciasTemp: "",
      competencias: [],
      competenciasCarregadas: []
    }
  },
  methods: {
    submitComps() {
      if (this.competencias.length > 0) {
        this.$emit('resStep1', this.competencias);
        this.concluiStep1();
      } else { return false; }
    },
    async CompetenciasProcesso() {
      await this.$http.get('sysinfos/compprocessoseletivo').then(res => {
        this.competenciasCarregadas = res.data.competencias;
      }).catch((err) => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
    },
    querySearchComp(queryComp, cb) {
      let comp = this.competenciasCarregadas;
      var results = queryComp ? comp.filter(this.createFilterComp(queryComp)) : comp;
      cb(results)
    },
    createFilterComp(queryComp) {
      return (comp) => {
        return (comp.value.toLowerCase().indexOf(queryComp.toLowerCase()) === 0);
      };
    },
    addCompetenciaAvaliacao() {
      this.loading.compTemp = true;
      if (this.competenciasTemp === '') {
        this.notificacao('Você precisa definir uma competência antes!', 'warning');
        this.loading.compTemp = false;
      } else if (this.competencias.findIndex(i => i === this.competenciasTemp) > -1) {
        setTimeout(() => {
          this.competenciasTemp = "";
          this.loading.compTemp = false;
          this.notificacao('Você já adicionou essa competência!', 'warning');
        }, 300);
      }else {
        setTimeout(() => {
          this.competencias.push(this.competenciasTemp);
          this.competenciasTemp = "";
          this.loading.compTemp = false;
        }, 300);
      }
    },
    removeCompetencia(value) {
      this.loading.compTemp = true;
      var pos = this.competencias.findIndex( item => item === value);
      setTimeout(() => {
        this.competencias.splice(pos, 1);
        this.loading.compTemp = false;
      }, 300);
    },
    butUp(id){
      var pos = this.competencias.findIndex( item => item === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      var pos = 1
      pos += this.competencias.findIndex( item => item === id);
      if (this.competencias.length === pos){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = this.competencias.findIndex( item => item === id);;
      var idNext = idNow + 1;
      var arr0 = this.competencias[idNow];
      var arr1 = this.competencias[idNext];
      this.competencias.splice(idNow, 1, arr1);
      this.competencias.splice(idNext, 1, arr0);
    },
    moveUp(id){
      var idNow = this.competencias.findIndex( item => item === id);
      var idNext = idNow - 1;
      var arr0 = this.competencias[idNow];
      var arr1 = this.competencias[idNext];
      this.competencias.splice(idNow, 1, arr1);
      this.competencias.splice(idNext, 1, arr0);
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  mounted() {
    this.CompetenciasProcesso();
    this.competencias = this.propCompetencias;
  }
}
</script>

<style scoped>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
