<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Formações</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <template v-if="propFormacoesVaga.length < 1">
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12">
          <small>A vaga não possui formações definidas.</small>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-for="(formacao, index) in propFormacoes">
        <template v-if="formacao.status">
          <template v-if="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato">
            <div v-if="formacao.tipo_match === 'exato'" :key="index">
              <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
                <div class="col-12">
                  Resultado do match: <strong>{{formacao.curso_vaga}}</strong> - <strong><span :class="formacao.match_concluido ? 'text-success' : 'text-danger'">{{formacao.match_concluido ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="formacao.match_concluido ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="formacao.match_concluido ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div v-if="flDetalhes">
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-12">
                      Tipo da formação: <strong>{{retornaTipoFormacao(formacao.tipo_formacao_vaga)}}</strong> <span
                        :class="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-auto">
                      Curso: <strong>{{formacao.curso_vaga}}</strong> <span
                        :class="formacao.curso_vaga === formacao.curso_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.curso_vaga === formacao.curso_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-12">
                      Concluído: <strong>{{formacao.candidato_concluido ? 'Sim' : 'Não'}}</strong> <span
                        :class="formacao.vaga_concluido === formacao.candidato_concluido ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.vaga_concluido === formacao.candidato_concluido"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div v-else-if="formacao.tipo_match === 'semelhante'" :key="index">
              <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
                <div class="col-12">
                  Resultado do match: <strong>{{formacao.curso_vaga}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                  class="text-danger"
                  >
                  <i class="fas fa-times-circle"></i></span>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div v-if="flDetalhes">
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-12">
                      Tipo da formação:<br><strong>{{retornaTipoFormacao(formacao.tipo_formacao_vaga)}}</strong> <span
                        :class="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-4">
                      Curso da vaga:<br><strong>{{formacao.curso_vaga}}</strong>
                    </div>
                    <div class="col-4">
                      Curso do candidato:<br><strong>{{formacao.curso_candidato}}</strong> <span
                        :class="formacao.curso_vaga === formacao.curso_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.curso_vaga === formacao.curso_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-4">
                      Vaga requer concluído: <strong>{{formacao.candidato_concluido ? 'Sim' : 'Não'}}</strong>
                    </div>
                    <div class="col-4">
                      Candidato concluíu: <strong>{{formacao.candidato_concluido ? 'Sim' : 'Não'}}</strong> <span
                        :class="formacao.vaga_concluido === formacao.candidato_concluido ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.vaga_concluido === formacao.candidato_concluido"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div v-else-if="formacao.tipo_match === 'inexistente'" :key="index">
              <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
                <div class="col-12">
                  Resultado do match: <strong>{{formacao.curso_vaga}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                  class="text-danger"
                  >
                  <i class="fas fa-times-circle"></i></span>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div v-if="flDetalhes">
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-4">
                      Tipo da formação da vaga:<br><strong>{{retornaTipoFormacao(formacao.tipo_formacao_vaga)}}</strong>
                    </div>
                    <div class="col-4">
                      Tipo da formação do candidato:<br><strong>{{retornaTipoFormacao(formacao.tipo_formacao_candidato)}}</strong> <span
                        :class="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.tipo_formacao_vaga === formacao.tipo_formacao_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-4">
                      Curso da vaga:<br><strong>{{formacao.curso_vaga}}</strong>
                    </div>
                    <div class="col-4">
                      Curso do candidato:<br><strong>{{formacao.curso_candidato}}</strong> <span
                        :class="formacao.curso_vaga === formacao.curso_candidato ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.curso_vaga === formacao.curso_candidato"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                  <div class="row justify-content-start align-items-start mt-1">
                    <div class="col-4">
                      Vaga requer concluído:<br><strong>{{formacao.candidato_concluido ? 'Sim' : 'Não'}}</strong>
                    </div>
                    <div class="col-4">
                      Candidato concluiu:<br><strong>{{formacao.candidato_concluido ? 'Sim' : 'Não'}}</strong> <span
                        :class="formacao.vaga_concluido === formacao.candidato_concluido ? 'text-success' : 'text-danger'"
                        >
                          <i class="fas fa-equals" v-if="formacao.vaga_concluido === formacao.candidato_concluido"></i>
                          <i class="fas fa-not-equal" v-else></i>
                        </span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </template>
          <template v-else>
            <div :key="index+2+index">
              <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
                <div class="col-12">
                  Resultado do match: <strong><span class="text-danger">Negativo</span></strong> <span
                  class="text-danger"
                  >
                  <i class="fas fa-times-circle"></i></span>
                </div>
              </div>
              <transition name="fade" mode="out-in">
                <div v-if="flDetalhes">
                  <div class="row justify-content-start align-items-start mt-1" :key="index">
                    <div class="col-12">
                      <small>O candidato não deu 'match'com as formações da vaga.</small>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </template>
          <!-- <div v-else :key="index+1+index">
            <div class="row justify-content-start align-items-start mt-2">
              <div class="col-12">
                Resultado do match: <strong>{{formacao.competencia}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                class="text-danger"
                >
                <i class="fas fa-times-circle"></i></span>
              </div>
            </div>
            <div class="row justify-content-start align-items-start mt-2">
              <div class="col-12">
                O candidato não possui a competência.
              </div>
            </div>
          </div> -->
        </template>
        <template v-else>
          <div :key="index+2+index">
            <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
              <div class="col-12">
                Resultado do match: <strong><span class="text-danger">Negativo</span></strong> <span
                class="text-danger"
                >
                <i class="fas fa-times-circle"></i></span>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="flDetalhes">
                <div class="row justify-content-start align-items-start mt-1" :key="index">
                  <div class="col-12">
                    <small>{{formacao.text}}</small>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </template>
    </template>

    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <div class="row justify-content-start align-items-start">
      <div class="col-6">
        propFormacoes -> <pre>{{propFormacoes}}</pre>
      </div>
      <div class="col-6">
        propFormacoesVaga -> <pre>{{propFormacoesVaga}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "FormacoesComp",
  props: {
    propFormacoes: { type: Array },
    propFormacoesVaga: { type: Array }
  },
  data() {
    return {
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
      flDetalhes: false,
      arrForms: []
    }
  },
  methods: {
    retornaTipoFormacao(id) {
      var tipo = this.sysformacoes.find(i => i.id === id);
      return tipo.text;
    },
    verificaFormacaoMatch(value, index) {
      var forms = this.propFormacoes.findIndex(i => i.competencia === value);
      if (forms > -1){
        this.arrForms[index] = forms;
        return true;
      } else {
        return false;
      }
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
