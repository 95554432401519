<template>
  <div v-loading="loading">
    <!-- Linha de alteração do nome da fase -->
    <div class="row justify-content-center mb-3">
      <div class="col-6 align-self-center">
        <label for="nomefase">Nome da fase</label>
        <input
          id="nomefase"
          ref="nomefase"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model.trim.lazy="propEntrevistaConfig.value"
          @blur="validaOnBlur()"
        />
      </div>
    </div>
    <!-- Flag - Entrevista com o RH -->
    <div class="row justify-content-center mt-3">
      <div class="col-6 align-self-center text-right">
        <label>Essa entrevista será com o RH?</label>
      </div>
      <div class="col-6 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propEntrevistaConfig.configs.entrevistaRH"
          @input="ativaLoading('entrevistaRh')"
        ></base-switch>
      </div>
    </div>
    <!-- Flag - Entrevista com o Solicitante -->
    <div class="row justify-content-center mt-3" v-if="!propEntrevistaConfig.configs.entrevistaRH">
      <div class="col-6 align-self-center text-right">
        <label>Essa entrevista será com o solicitante da vaga?</label>
      </div>
      <div class="col-6 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propEntrevistaConfig.configs.entrevistaSolicitante"
          @input="ativaLoading('entrevistaSolicitante')"
        ></base-switch>
      </div>
    </div>
    <div class="row justify-content-center my-3">
      <div class="col-12 align-self-center text-center">
        <p class="font-weight-bold" v-if="propEntrevistaConfig.configs.entrevistaRH">Essa fase será direcionada apenas para usuários do RH</p>
        <p class="font-weight-bold" v-else-if="propEntrevistaConfig.configs.entrevistaSolicitante">Essa fase será direcionada apenas para o solicitante da vaga</p>
        <p class="font-weight-bold" v-else>O entrevistador dessa fase poderá ser indicado durante o agendamento da entrevista.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../../mixins/defaultMatchjob';
import {
  Loading,
  Select,
  Option
} from 'element-ui';
Vue.use(Loading.directive);
export default {
  name: "EntrevistaConfig",
  mixins: [defaultMatchjob],
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    propEntrevistaConfig: { type: Object }
  },
  data() {
    return {
      loading: false,
      linhaSolicitante: false, //REMOVER!!!!
    }
  },
  methods: {
    validaOnBlur(){
      if (this.propEntrevistaConfig.value === '') {
        this.propEntrevistaConfig.value = 'Entrevista'
      }
    },
    ativaLoading(value) {
      if (value === 'entrevistaRh') {
        this.loading = true;
        if (!this.propEntrevistaConfig.configs.entrevistaRH) {
          this.propEntrevistaConfig.configs.entrevistaSolicitante = true;
        } else {
          this.propEntrevistaConfig.configs.entrevistaSolicitante = false;
        }
        setTimeout(() => {
          this.loading = false;
        }, 300);
      } else if (value === 'entrevistaSolicitante') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
  }
}
</script>

<style>

</style>
