<template>
  <div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propVagasPgConfig.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Nome da Empresa"
          v-model.lazy="propVagasPgConfig.titulo"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VagasPgConfig",
  props:{
    propVagasPgConfig: Object
  },
}
</script>

<style>

</style>
