<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <label class="form-control-label">{{propValue}}</label><span v-if="propRequired" class="text-danger"> *</span>
      </div>
    </div>
    <div class="row mb-3 mt-1 mx-1">
      <div class="col">
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th
                scope="col"
                class="text-center"
                v-for="item in propOptions"
                :key="item.id"
              >{{ item.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="item in propOptions"
                :key="item.id"
                class="text-center"
              >
                <base-radio
                  class="ml-2"
                  :name="item.value"
                  v-model="resp"
                  @input="emitData"
                >
                </base-radio>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatrizLikertNps",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean },
    propOptions: { type: Array }
  },
  data() {
    return {
      name: "",
      resp: "",
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    },
    makeName() {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 8; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  created() {
    this.name = this.makeName();
  }
}
</script>

<style>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
