var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Lista de Teste")])]),_c('div',{staticClass:"card-body"},[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',{staticClass:"form-check form-check-inline"},[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Procurar..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"id","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"min-width":"300","sortable":"","prop":"nm_nome","label":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.nm_nome))]),_c('br'),_vm._v(" "+_vm._s(row.nm_descricao)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"150","sortable":"","prop":"nm_tipo","label":"Tipo","formatter":_vm.defineTipoTabela}}),_c('el-table-column',{attrs:{"min-width":"200","sortable":"","prop":"nm_nome_user","label":"Criado por"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.nm_nome_user)),_c('br'),_vm._v(" em "+_vm._s(_vm._f("formatDateTime")(row.created_at))+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"200","sortable":"","prop":"json_tags","label":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},_vm._l((row.json_tags.tags),function(tag){return _c('span',{key:tag,staticClass:"el-tag el-tag--info el-tag--mini el-tag--plain m-1"},[_vm._v(_vm._s(tag))])}),0)}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('base-button',{attrs:{"type":"primary","size":"sm"},on:{"click":function($event){return _vm.showTesteDetalhes(row)}}},[_c('i',{staticClass:"ni ni-zoom-split-in"})])],1)}}])})],1)],1)]),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Mostrando "+_vm._s(_vm.from + 1)+" de "+_vm._s(_vm.to)+" em "+_vm._s(_vm.total)+" testes."),_c('br')])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }