<template>
  <div v-if="dataLoaded">
    <!-- Informações Gerais -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Cargo (titulo) / URL -->
        <p>Cargo: <strong>{{propVaga.json_vaga.nm_cargo}}</strong><br>
        URL da vaga: <a :href="`https://vagas.matchjob.com.br/${empresa.hl_url_amigavel}/${propVaga.hl_link}`" target="_blank">https://vagas.matchjob.com.br/{{empresa.hl_url_amigavel}}/{{propVaga.hl_link}}</a></p>
        <!-- Area de Atuação / Nível Hierarquico / Solicitante / Departamento -->
        <p>Área: <strong>{{retornaAreaAtuacao(propVaga.json_vaga.nm_area)}}</strong> - Posição: <strong>{{retornaNivelHierarquico(propVaga.json_vaga.nm_nivel)}}</strong><br>
        Solicitante: <strong>{{propVaga.nm_solicitante}}</strong> - Departamento: <strong>{{propVaga.nm_depto}}</strong></p>
        <!-- Data de Abertura / Data de Fechamento / Tags -->
        <p>Data de Abertura: <strong>{{propVaga.dt_abertura | formatDate }}</strong> - Data de Fechamento: <strong>{{propVaga.dt_prev_fechamento | formatDate}}</strong><br>
        Tags: <template v-if="propVaga.json_tags.length > 0">
          <badge
            v-for="tag in propVaga.json_tags"
            :key="tag"
            type="default"
            class="text-white m-1"
            size="sm">{{tag}}</badge>
        </template>
        <template v-else>
          <small class="text-muted"><em>Nenhuma tag definida</em></small>
        </template>
        </p>
      </div>
    </div>
    <!-- Flag Deficientes / Salario / Tipo Contrato / Jornada -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <p>Esta vaga pode ser preenchida por Pessoa com Deficiência? <strong>{{propVaga.json_vaga.fl_deficientes ? 'Sim' : 'Não'}}</strong><br>
        Salário: <strong>{{propVaga.json_vaga.nu_salario.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong> - Oculta o salário? <strong>{{propVaga.json_vaga.fl_oculta_salario ? 'Sim' : 'Não'}}</strong><br>
        Tipo de contrato: <strong>{{retornaTiposContrato(propVaga.json_vaga.fl_contratacao)}}</strong>
          - Período: <strong>{{retornaJornadas(propVaga.json_vaga.nm_turno_trabalho)}}</strong></p>
      </div>
    </div>
    <!-- Descrição da Vaga -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <p>Descrição da Vaga: <br><span v-html="propVaga.json_vaga.tx_descricao"></span></p>
      </div>
    </div>
    <!-- Requisitos da Vaga -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <p>Requisitos da Vaga: <br><span v-html="propVaga.json_vaga.tx_requisitos"></span></p>
      </div>
    </div>
    <!-- Flag Vídeo / Tipo do vídeo / Link do vídeo -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <p>Esta vaga pode possui vídeo explicativo? <strong>{{propVaga.json_vaga.fl_video ? 'Sim' : 'Não'}}</strong> - Origem do vídeo: <strong>{{retornaTipoVideo(propVaga.json_vaga.fl_tipo_video)}}</strong></p>
        <template v-if="showPreYoutube">
          <!-- Youtube Embed -->
          <LazyYoutube
            :src="hl_video_youtube"
            :showTitle="false"
            max-width="720px"
            aspect-ratio="16:9"
            thumbnail-quality="standard"
          />
        </template>
        <template v-else-if="showPreVimeo">
          <!-- Vimeo Embed -->
          <LazyVimeo
            :src="hl_video_vimeo"
            :showTitle="false"
            max-width="720px"
            aspect-ratio="16:9"
            thumbnail-quality="standard"
          />
        </template>
      </div>
    </div>
    <!-- Step 4 - Local da Vaga -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Empresa Contratante / Flag ocultar empresa / Local da vaga / Cidade/Estado / Modo de trabalho -->
        <p>Empresa Contratante: <strong>{{propVaga.json_vaga.nm_empresa}}</strong>
            - Oculta o nome da empresa? <strong>{{ propVaga.json_vaga.fl_oculta_empresa ? 'Sim' : 'Não' }}</strong><br>
          Local da vaga: <strong>{{retornaLocalVaga(propVaga.json_vaga.id_empresa_ender)}}</strong>
            - Cidade/Estado: <strong>{{propVaga.json_vaga.nm_cidade}} - {{propVaga.json_vaga.nm_pais}} </strong><br>
          Modo de trabalho: <strong>{{retornaModoTrabalho(propVaga.json_vaga.fl_tipo_trabalho)}}</strong>
        </p>
      </div>
    </div>
    <!-- Step 5 - Benefícios -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Benefícios -->
        <p>Benefícios: <template v-if="propVaga.json_vaga.json_beneficios.length > 0">
          <ul>
            <li v-for="item in propVaga.json_vaga.json_beneficios" :key="item"><strong>{{item}}</strong></li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhum benefício definido para esta vaga</em></small>
          </template>
        </p>
      </div>
    </div>
    <!-- Step 6 - Teste -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Teste -->
        <p>Teste a ser aplicado na candidatura: <template v-if="propVaga.json_vaga.id_teste !== ''">
          <strong>{{testes.nm_nome}}</strong><br>
          Perguntas:
          <ul>
            <li v-for="item in testes.json_questoes.questions" :key="item.id"><strong>{{item.value}}</strong></li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhum teste definido para esta vaga</em></small>
          </template>
        </p>
        <!-- {{testes}} -->
      </div>
    </div>
    <!-- Step 7 - Tempo de experiência requerido -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Tempo de Experiência -->
        <p>Tempo de experiência no cargo: <strong>{{propVaga.nm_cargo}}</strong><br>
          <span v-html="formataExperiencia(propVaga.json_vaga.json_nivelexperiencia.nivelCargo)"></span>
        </p>
        <!-- Tempo de Area -->
        <p>Tempo de experiência na área: <strong>{{retornaAreaAtuacao(propVaga.json_vaga.nm_area)}}</strong><br>
          <span v-html="formataExperiencia(propVaga.json_vaga.json_nivelexperiencia.nivelArea)"></span>
        </p>
        <!-- Tempo de Experiência -->
        <p>Tempo de experiência na posição: <strong>{{retornaNivelHierarquico(propVaga.json_vaga.nm_nivel)}}</strong><br>
          <span v-html="formataExperiencia(propVaga.json_vaga.json_nivelexperiencia.nivelHierarquico)"></span>
        </p>
      </div>
    </div>
    <!-- Step 7 - Requisitos implicitos -->
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <!-- Idiomas -->
        <p>Idiomas requeridos: <template v-if="propVaga.json_vaga.json_idiomas.length > 0">
          <ul>
            <li v-for="item in propVaga.json_vaga.json_idiomas" :key="item.id"><strong>{{item.value}} - {{retornaNivelIdioma(item.nivel)}}</strong></li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhum idioma definido para esta vaga</em></small>
          </template>
        </p>
        <!-- Competências -->
        <p>Competências requeridas: <template v-if="propVaga.json_vaga.json_competencias.length > 0">
          <ul>
            <li v-for="item in propVaga.json_vaga.json_competencias" :key="item.id"><strong>{{item.value}} - {{retornaNivelCompetencia(item.nivel)}}</strong></li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhuma competência definida para esta vaga</em></small>
          </template>
        </p>
        <!-- Certificações -->
        <p>Certificações requeridas: <template v-if="propVaga.json_vaga.json_certificacao.length > 0">
          <ul>
            <li v-for="item in propVaga.json_vaga.json_certificacao" :key="item.id"><strong>{{item.value}}</strong></li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhuma certificação definida para esta vaga</em></small>
          </template>
        </p>
        <!-- Formações -->
        <p>Formações requeridas: <template v-if="propVaga.json_vaga.json_formacao.length > 0">
          <ul>
            <li v-for="item in propVaga.json_vaga.json_formacao" :key="item.id"><strong>{{item.value}}</strong> - {{retornaTipoFormacao(item.tipoFormacao)}} - Concluído? {{item.status ? 'Sim' : 'Não'}}</li>
          </ul>
          </template>
          <template v-else>
            <small class="text-muted"><em>Nenhuma formação definida para esta vaga</em></small>
          </template>
        </p>
      </div>
    </div>
    <!-- testes typeof -> {{typeof testes}}
    testes -> {{testes}} -->
  </div>
</template>

<script>
import moment from "moment";
import { LazyYoutube, LazyVimeo } from "vue-lazytube";

export default {
  name: "VisualizarVagaComp",
  props: {
    propVaga: { type: Object }
  },
  components: {
    LazyYoutube,
    LazyVimeo
  },
  data() {
    return {
      dataLoaded: false,
      nivelIdioma: [
        { value: "1", text: "Básico" },
        { value: "2", text: "Intermediário" },
        { value: "3", text: "Avançado" },
        { value: "4", text: "Fluente" }
      ],
      nivelCompetencia: [
        { value: "1", text: "Júnior" },
        { value: "2", text: "Pleno" },
        { value: "3", text: "Sênior" },
        { value: "4", text: "Especialista" }
      ],
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "0", text: "Nível Hierárquico" },
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
      tiposcontrato: [
        { value: "1", text: "Autônomo" },
        { value: "2", text: "Cooperado" },
        { value: "3", text: "Efetivo (CLT)" },
        { value: "4", text: "Estágio" },
        { value: "5", text: "Outros" },
        { value: "6", text: "Prestador de Serviços (PJ)" },
        { value: "7", text: "Temporário" },
        { value: "8", text: "Trainee" }
      ],
      modoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Hibrido" },
        { value: "3", text: "HomeOffice" },
      ],
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
      showPreYoutube: false,
      showPreVimeo: false,
      hl_video_youtube: '',
      hl_video_vimeo: '',
      //testes: {}
    }
  },
  computed: {
    testes() {
      return this.$store.state.testes.testes.find(i => i.id_teste === this.propVaga.json_vaga.id_teste);
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
  },
  methods: {
    initValues() {
      setTimeout(() => {
        if (this.propVaga.json_vaga.fl_video) {
          this.preVisualizaVideo(this.propVaga.json_vaga.fl_tipo_video, this.propVaga.json_vaga.hl_video);
        }
        /*
        if (this.propVaga.json_vaga.id_teste !== ''){
          this.carregaTestes(this.propVaga.json_vaga.id_teste);
        }
        this.idiomas = this.propSysInfos.idiomas;
        this.competencias = this.propSysInfos.competencias;
        this.certificacoes = this.propSysInfos.certificacoes;
        this.formacoes = this.propSysInfos.formacoes;
        this.deptos = this.propSysInfos.deptos;
        this.cargos = this.propSysInfos.cargos;
        var idProcessoPadrao = structuredClone(this.propProcessoSelecao.find(i => i.fl_default === true));
        this.processoVaga.id = idProcessoPadrao.id;
        var publicacaoVaga = idProcessoPadrao.fases.find(p => p.tipo === 'publicacaoVaga');
        if (this.propPlano.ps.fluxoVagas) {
          this.processoVaga.requerAprovacao = publicacaoVaga.configs.requerAprovacao;
          this.processoVaga.solicitanteAprova = publicacaoVaga.configs.solicitanteAprova;
          this.processoVaga.aprovacaoTipo = publicacaoVaga.configs.aprovacaoTipo;
        } else {
          this.processoVaga.requerAprovacao = false;
          this.processoVaga.solicitanteAprova = false;
          this.processoVaga.aprovacaoTipo = {
            tipoRh: "",
            usuario: "",
            tipoAcesso: ""
          }
        } */
      }, 500)
    },
    retornaNivelIdioma(value) {
      var res = this.nivelIdioma.find(i => i.value === value);
      return res.text;
    },
    retornaNivelCompetencia(value) {
      var res = this.nivelCompetencia.find(i => i.value === value);
      return res.text;
    },
    retornaAreaAtuacao(value) {
      var res = this.areasatuacao.find(i => i.value === value);
      return res.text;
    },
    retornaNivelHierarquico(value) {
      var res = this.niveishierarquicos.find(i => i.value === value);
      return res.text;
    },
    retornaTiposContrato(value) {
      var res = this.tiposcontrato.find(i => i.value === value);
      return res.text;
    },
    retornaJornadas(value) {
      var res = this.jornadas.find(i => i.value === value);
      return res.text;
    },
    retornaLocalVaga(value) {
      if (value !== null || value !== undefined) {
        if (this.enderecos.length > 0) {
          var res = this.enderecos.find(i => i.id_empresa_ender === value);
          return res.nm_nome_endereco;
        }
      }
    },
    retornaModoTrabalho(value) {
      var res = this.modoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoVideo(value) {
      if (value === '1'){
        return 'YouTube';
      } else if (value === '2'){
        return 'Vimeo';
      } else {
        return 'Nenhum vídeo selecionado'
      }
    },
    /* retornaTipoRh(value) {
      var res = this.tipoAcesso.find(i => i.value === value);
      return res.text;
    }, */
    retornaTipoFormacao(value) {
      var res = this.sysformacoes.find(i => i.id === value);
      return res.text;
    },
    preVisualizaVideo(value, link) {
      if (value === '1' && link !== '') {
        this.hl_video_youtube = link;
        this.showPreYoutube = true;
      } else if (value === '2' && link !== '') {
        this.hl_video_vimeo = link;
        this.showPreVimeo = true;
      }
    },
    formataExperiencia(arr) {
      if (arr[1] === 0) {
        return "<strong>Nenhuma experiência requerida</strong>";
      } else if(arr[0] === arr[1]) {
        return `Experiência de <strong>${arr[0]} anos</strong>`;
      } else {
        return `Experiência de <strong>${arr[0]} a ${arr[1]} anos</strong>`;
      }
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.initValues();
    setTimeout(() => {
      this.dataLoaded = true;
    }, 1000);
  }
}
</script>

<style>

</style>
