<template>
  <div>
    propCandidatos -> {{propCandidatos}}
  </div>
</template>

<script>
export default {
  name: "ListaCandidatos",
  props: {
    propCandidatos: { type: Array }
  }
}
</script>

<style>

</style>
