<template>
  <div>
    <!-- Site da Empresa -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fas fa-globe"></i> Site da empresa:</label>
        <input
          id="hl_site"
          ref="hl_site"
          type="url"
          maxlength="100"
          class="form-control"
          name="Site da empresa"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.site"
        />
      </div>
    </div>
    <!-- Twitter -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fab fa-twitter"></i> Twitter:</label>
        <input
          id="twitter"
          ref="twitter"
          type="url"
          maxlength="100"
          class="form-control"
          name="Twitter"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.twitter"
        />
      </div>
    </div>
    <!-- Instagram -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fab fa-instagram"></i> Instagram:</label>
        <input
          id="instagram"
          ref="instagram"
          type="url"
          maxlength="100"
          class="form-control"
          name="Instagram"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.instagram"
        />
      </div>
    </div>
    <!-- Facebook -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fab fa-facebook-square"></i> Facebook:</label>
        <input
          id="facebook"
          ref="facebook"
          type="url"
          maxlength="100"
          class="form-control"
          name="Facebook"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.facebook"
        />
      </div>
    </div>
    <!-- Linkedin -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fab fa-linkedin"></i> Linkedin:</label>
        <input
          id="linkedin"
          ref="linkedin"
          type="url"
          maxlength="100"
          class="form-control"
          name="Linkedin"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.linkedin"
        />
      </div>
    </div>
    <!-- Youtube -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="hl_site"><i class="fab fa-youtube"></i> Youtube:</label>
        <input
          id="youtube"
          ref="youtube"
          type="url"
          maxlength="100"
          class="form-control"
          name="Youtube"
          placeholder="https://...."
          v-model.lazy="propMidiasSociais.youtube"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MidiasSociais",
  props: {
    propMidiasSociais: Object
  }
}
</script>

<style>

</style>
