/* eslint-disable vue/no-parsing-error */
/* eslint-disable vue/no-parsing-error */
<template>
  <div>
    <!-- Ativo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco estará ativo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propNoticiasConfig.ativo"
        ></base-switch>
      </div>
    </div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propNoticiasConfig.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Ultimas Notícias"
          v-model.lazy="propNoticiasConfig.titulo"
        />
      </div>
    </div>
    <!-- Tipo / ID do componente -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Modelo do bloco:</label>
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/noticias-component-1.PNG" class="img-fluid my-2" alt="Bloco de Noticias">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="1"
            id="radioTipoNoticias1" name="flTipoNoticias"
            v-model="propNoticiasConfig.compId"
          />
          <label class="form-check-label" for="radioTipoNoticias1">Bloco 1</label>
        </div>
      </div>
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/noticias-component-2.PNG" class="img-fluid my-2" alt="Bloco de Noticias">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="2"
            id="radioTipoNoticias2" name="flTipoNoticias"
            v-model="propNoticiasConfig.compId"
          />
          <label class="form-check-label" for="radioTipoNoticias2">Bloco 2</label>
        </div>
      </div>
    </div>
    <!-- Noticias -->
    <!-- Botao de adicionar -->
    <div class="row justify-content-center my-4">
      <div class="col align-self-center">
        <label for="subtitulo">Notícias:</label><br>
        <base-button
          type="primary"
          size="sm"
          @click="handleAddNoticia"
        >Adicionar Notícia</base-button>
      </div>
    </div>
    <!-- Linhas das noticias -->
    <div class="row justify-content-center mt-3 mb-2 border-bottom" v-for="noticia in propNoticiasConfig.noticias" :key="noticia.id">
      <div class="col-2 align-self-center">
        <img :src="noticia.imagem" class="img-fluid" alt="Foto de perfil">
      </div>
      <div class="col align-self-center">
        <h5>
          <span class="text-muted">Título:</span> {{noticia.titulo}}<br>
          <span class="text-muted">Resumo:</span> <div v-html="noticia.conteudo.substring(0, 100)"></div>
          <span class="text-muted">Link: </span> <a :href="noticia.link" target="_blank">{{noticia.link}}</a>
        </h5>
      </div>
      <!-- Coluna dos botões de ação -->
      <div class="col-2 align-self-center">
        <div class="row">
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butUp(noticia.id)"
          @click="moveUp(noticia.id)"
        ><i class="fas fa-arrow-up"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butDown(noticia.id)"
          @click="moveDown(noticia.id)"
        ><i class="fas fa-arrow-down"></i>
        </base-button>
        </div>
        <div class="row">
        <base-button
          @click="handleEditarNoticia(noticia)"
          class="edit btn-link mr-1 my-1"
          type="info"
          size="sm"
          icon
        ><i class="text-white fas fa-pen"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="danger"
          size="sm"
          icon
          @click="handleDelete(noticia)"
        ><i class="text-white fas fa-trash-alt"></i>
        </base-button>
        </div>

      </div>
    </div>
    <!-- Modal de edição / novo usuário -->
    <modal
      :show="modal.noticia"
      :showClose="true"
      bodyClasses="py-1"
      @close="modal.noticia = false"
      size="lg"
      v-loading="loading">
      <h6 slot="header" class="modal-title">Adicionar / Editar notícia</h6>
      <div class="row justify-content-center">
        <!-- Foto Atual -->
        <div class="col-4 align-self-center text-center">
          <img :src="editarNoticia.imagem" class="img-fluid" alt="Foto de perfil">
        </div>
        <div class="col align-self-center">
          <label for="foto">Informe o link da imagem da notícia:</label>
          <input
            id="foto"
            ref="foto"
            type="url"
            maxlength="100"
            class="form-control"
            name="Link da Imagem"
            placeholder="http://..."
            v-model.lazy="editarNoticia.imagem"
          />
        </div>
      </div>
      <!-- Titulo -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <label for="titulo">Título:</label>
          <input
            id="titulo"
            ref="titulo"
            type="text"
            maxlength="100"
            class="form-control"
            name="Titulo"
            placeholder="Título da Notício"
            v-model.lazy="editarNoticia.titulo"
          />
        </div>
      </div>
      <!-- Conteúdo -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <vue-editor v-model.lazy="editarNoticia.conteudo"/>
        </div>
      </div>
      <!-- Link -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <label for="link">Link:</label>
          <input
            id="link"
            ref="link"
            type="url"
            maxlength="100"
            class="form-control"
            name="Link"
            placeholder="http://..."
            v-model.lazy="editarNoticia.link"
          />
        </div>
      </div>
      <!-- Botões -->
      <div class="row justify-content-between my-3">
        <div class="col-2 align-self-center">
          <base-button
            type="default"
            @click="modal.noticia = false"
          >Fechar</base-button>
        </div>
        <div class="col-2 align-self-center text-right">
          <base-button
            type="primary"
            @click="handleSalvarNoticia"
          >Salvar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import { Loading } from 'element-ui';
import swal from 'sweetalert2';
import { VueEditor } from "vue2-editor";
Vue.use(Loading.directive);
export default {
  name: "NoticiasConfig",
  props:{
    propNoticiasConfig: Object
  },
  components: {
    VueEditor
  },
  data() {
    return {
      loading: false,
      modal: {
        noticia: false
      },
      editarNoticia: {},
      novaNoticia: {
        id: "",
        link: "",
        imagem: "",
        titulo: "",
        conteudo: ""
      }
    }
  },
  methods: {
    handleDelete(noticia) {
      var pos = this.propNoticiasConfig.noticias.findIndex( item => item.id === noticia.id);
      var tam = this.propNoticiasConfig.noticias.length;
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja remover essa notíca?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          if (tam === (pos + 1)){
            this.propNoticiasConfig.noticias.splice(pos, 1);
          } else {
            for (var i = pos+1; i < tam; i++){
              this.propNoticiasConfig.noticias[i].id = this.propNoticiasConfig.noticias[i].id-1;
              this.propNoticiasConfig.noticias[i].id = this.propNoticiasConfig.noticias[i].id.toString();
            }
            this.propNoticiasConfig.noticias.splice(pos, 1);
          }
        }
      });
    },
    handleEditarNoticia(noticia) {
      this.editarNoticia = {}
      _.merge(this.editarNoticia, noticia);
      this.modal.noticia = true;
    },
    handleAddNoticia() {
      this.editarNoticia = {}
      _.merge(this.editarNoticia, this.novaNoticia);
      this.modal.noticia = true;
    },
    handleSalvarNoticia() {
      if (this.editarNoticia.id === '') {
        this.addNoticia();
      } else {
        this.atualizaNoticia();
      }
    },
    atualizaNoticia() {
      this.loading = true;
      var pos = this.propNoticiasConfig.noticias.findIndex( item => item.id === this.editarNoticia.id);
      this.propNoticiasConfig.noticias.splice(pos, 1, this.editarNoticia);
      setTimeout(() => {
        this.modal.noticia = false;
        this.loading = false;
        this.editarNoticia = {}
      }, 300);
    },
    addNoticia() {
      if (this.propNoticiasConfig.noticias.length > 0) {
        const lastItem = this.propNoticiasConfig.noticias.pop();
        let id = lastItem.id;
        this.propNoticiasConfig.noticias.push(lastItem);
        this.editarNoticia.id = ++id;
        this.editarNoticia.id = this.editarNoticia.id.toString();
      } else {
        this.editarNoticia.id = "1";
      }
      this.propNoticiasConfig.noticias.push(this.editarNoticia);
      setTimeout(() => {
        this.modal.noticia = false;
        this.loading = false;
        this.editarNoticia = {}
      }, 300);
    },
    butUp(id){
      var pos = this.propNoticiasConfig.noticias.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      id = parseInt(id, 10)
      if (this.propNoticiasConfig.noticias.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      idNext = idNext.toString();
      var pos0 = this.propNoticiasConfig.noticias.findIndex( item => item.id === idNow);
      var pos1 = this.propNoticiasConfig.noticias.findIndex( item => item.id === idNext);
      var arr0 = this.propNoticiasConfig.noticias.find( item => item.id === idNow);
      var arr1 = this.propNoticiasConfig.noticias.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propNoticiasConfig.noticias.splice(pos0, 1, arr1);
      this.propNoticiasConfig.noticias.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      idNext = idNext.toString();
      var pos0 = this.propNoticiasConfig.noticias.findIndex( item => item.id === idNow);
      var pos1 = this.propNoticiasConfig.noticias.findIndex( item => item.id === idNext);
      var arr0 = this.propNoticiasConfig.noticias.find( item => item.id === idNow);
      var arr1 = this.propNoticiasConfig.noticias.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propNoticiasConfig.noticias.splice(pos0, 1, arr1);
      this.propNoticiasConfig.noticias.splice(pos1, 1, arr0);
    },
  }
}
</script>

<style>

</style>
