<template>
  <div>
    <div class="row justify-content-center mt-3">
      <div class="col-6 align-self-center text-right">
        <label>Todas as vagas precisam de aprovação?</label>
      </div>
      <div class="col-6 align-self-center">
        <base-switch
          class="ml-2"
          v-model="publicacaoVaga.requerAprovacao"
          @input="ativaLoading('requerAprovacao')"
        ></base-switch>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-loading="loading.requerAprovacao">
      <template v-if="publicacaoVaga.requerAprovacao">
        <!-- Coluna dos RADIOS (Tipo de Acesso / Usuários) -->
        <div class="col-6 align-self-center text-right">
          <label>Como será a aprovação de RH?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="area"
              id="radioAprovacaoRhArea1" name="flTipoAprovacao"
              v-model="publicacaoVaga.aprovacaoTipo.tipoRh"
              @input="ativaLoading('tipoRh')"
            />
            <label class="form-check-label" for="radioAprovacaoRhArea1">Por Tipo de Acesso</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="usuario"
              id="radioAprovacaoRhArea2" name="flTipoAprovacao"
              v-model="publicacaoVaga.aprovacaoTipo.tipoRh"
              @input="ativaLoading('tipoRh')"
            />
            <label class="form-check-label" for="radioAprovacaoRhArea2">Por usuário definido</label>
          </div>
        </div>
        <!-- Coluna dos Selects (Tipo de Acesso/Usuários) -->
        <div class="col-6 align-self-center" v-loading="loading.tipoRh">
          <!-- Select de Tipo de Acesso -->
          <template v-if="publicacaoVaga.aprovacaoTipo.tipoRh === 'area'">
            <label>Tipo de Acesso <i class="fas fa-question-circle"></i>
            </label>
            <el-select
              class="inuptAutocomplete"
              v-model="publicacaoVaga.aprovacaoTipo.tipoAcesso"
              placeholder="Tipo de acesso"
            >
              <el-option
                v-for="tipo in tipoAcesso"
                :key="tipo.value"
                :label="tipo.text"
                :value="tipo.value">
              </el-option>
            </el-select>
          </template>
          <!-- Select de Usuários -->
          <template v-else>
            <label for="areaatuacao">Usuários
              <i class="fas fa-question-circle"></i>
            </label>
            <el-select
              required
              filterable
              class="inuptAutocomplete"
              v-model="publicacaoVaga.aprovacaoTipo.usuario"
              placeholder="Usuários"
            >
              <el-option
                v-for="user in usuariosRh"
                :key="user.id_empresa_user"
                :label="user.nm_nome"
                :value="user.id_empresa_user">
                <span><strong>{{user.nm_nome}}</strong> -
                <small>{{user.nm_cargo}}</small></span>
              </el-option>
            </el-select>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="col-12 text-center">
          <small class="text-muted">As vagas serão publicadas automáticamente em todas as integrações ativadas.</small>
        </div>
      </template>
    </div>
    <!-- Flag de aprovação do solicitante -->
    <div class="row justify-content-center mt-4" v-if="publicacaoVaga.requerAprovacao">
      <div class="col-6 align-self-center text-right">
        <label>O solicitante da vaga deverá aprovar a vaga antes da publicação?</label>
        <small
          v-if="publicacaoVaga.solicitanteAprova"
          class="text-muted">A vaga só será publicada após todas as aprovações.</small>
      </div>
      <div class="col align-self-center">
        <base-switch
          class="ml-2"
          v-model="publicacaoVaga.solicitanteAprova"
        ></base-switch>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../../mixins/defaultMatchjob';
import {
  Loading,
  Select,
  Option
} from 'element-ui';

Vue.use(Loading.directive);

export default {
  name: "PublicacaoVagaConfig",
  mixins: [defaultMatchjob],
  props:{
    propPublicacaoVaga: { type: Object },
  },
  components:{
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: {
        requerAprovacao: false,
        tipoRh: false
      },
      publicacaoVaga: {},
      usuariosRh: [],
      usuarios: [],
      tipoAcesso: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
    }
  },
  methods: {
    ativaLoading(value) {
      if (value === 'requerAprovacao') {
        this.loading.requerAprovacao = true;
        setTimeout(() => {
          this.loading.requerAprovacao = false;
        }, 300);
      } else if (value === 'tipoRh') {
        this.loading.tipoRh = true;
        setTimeout(() => {
          this.loading.tipoRh = false;
        }, 300);
      }
    },
    initValues() {
      this.publicacaoVaga = this.propPublicacaoVaga;
    },
    async carregaUsuarios() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin,
        fl_tipo_acesso: this.id.tipoAcesso
      }
      await this.$http.post('usuarios', data).then(res => {
        this.usuarios = res.data.usuarios;
      });
      setTimeout(() => {
        this.filtraUsuariosRh();
      }, 300);

    },
    filtraUsuariosRh() {
      this.usuariosRh = this.usuarios.filter((item) => {
        if (item.fl_tipo_acesso > 0 && item.fl_tipo_acesso < 5) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  async mounted() {
    setTimeout(() => {
      this.initValues();
      this.carregaUsuarios();
    }, 500);
  }
}
</script>

<style>

</style>
