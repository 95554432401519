<template>
  <div>
    <!-- <div class="row justify-content-center mt-2">
      <div class="col text-center">
        <h1 class="display-2">Novo Processo Seletivo</h1>
      </div>
    </div> -->
    <div class="row justify-content-center">
      <div class="col-8 text-center">
        <h1 class="display-4">Como você deseja criar esse processo de seleção?</h1>
      </div>
    </div>
    <div class="row justify-content-center mt-2">
      <div class="col-4">
        <card
          header-classes="bg-info text-center"
          footer-classes="text-center">
          <h4 slot="header" class="text-uppercase ls-1 text-white py-3 mb-0">Padrão</h4>
          <p class="font-weight-normal mb-0">Fases iniciais:</p>
          <ul>
            <li>Publicação da Vaga</li>
            <li>Triagem de Currículos</li>
            <li>Entrevista com o RH</li>
            <li>Entrevista com o Gestor</li>
            <li>Negociação</li>
            <li>Contratação</li>
          </ul>
          <ul class="list-unstyled">
            <li class="text-muted font-weight-light"><small>* Pode incluir testes de perfil</small></li>
            <li class="text-muted font-weight-light"><small>** Pode alterar as fases</small></li>
            <li class="text-muted font-weight-light"><small>*** Pode incluir novas fases</small></li>
          </ul>
          <br>
          <template slot="footer">
            <base-button type="info" @click="editFormSubmit('simples')">Padrão</base-button>
          </template>
        </card>
      </div>
      <div class="col-4">
        <card header-classes="bg-primary text-center" footer-classes="text-center">
          <h4 slot="header" class="text-uppercase ls-1 text-white py-3 mb-0">Automatizado</h4>
          <p class="font-weight-normal mb-0">Fases iniciais:</p>
        <ul>
          <li>Publicação da Vaga</li>
          <li>Triagem de Currículos</li>
          <li>Teste de Perfil</li>
          <li>Entrevista com o RH</li>
          <li>Entrevista com o Gestor</li>
          <li>Negociação</li>
          <li>Contratação</li>
        </ul>
        <ul class="list-unstyled">
          <li class="text-muted font-weight-light"><small>* Inclui teste de perfil</small></li>
          <li class="text-muted font-weight-light"><small>** Pode alterar as fases</small></li>
          <li class="text-muted font-weight-light"><small>*** Pode incluir novas fases</small></li>
        </ul>
          <template slot="footer">
            <base-button type="primary" @click="editFormSubmit('auto')">Automatizado</base-button>
          </template>
        </card>
      </div>
      <div class="col-4">
        <card header-classes="bg-default text-center" footer-classes="text-center ">
          <h4 slot="header" class="text-uppercase ls-1 text-white py-3 mb-0">Personalizado</h4>
          <p class="font-weight-normal mb-0">Fases iniciais:</p>
          <ul>
            <li>Publicação da Vaga</li>
            <li>Triagem de Currículos</li>
            <li>Contratação</li>
          </ul>
          <ul class="list-unstyled">
            <li class="text-muted font-weight-light"><small>* Pode incluir teste de perfil</small></li>
            <li class="text-muted font-weight-light"><small>** Pode alterar as fases</small></li>
            <li class="text-muted font-weight-light"><small>*** Pode incluir novas fases</small></li>
          </ul>
          <p>É possível definir quais competências serão avaliadas e como cada competência será avaliada.</p>
          <template slot="footer">
            <base-button type="default" @click="editFormSubmit('custom')">Personalizado</base-button>
          </template>
        </card>
      </div>
    </div>
    <!-- <div class="row justify-content-center mt-5">
      <div class="col text-center">
        <base-button type="primary" class="mr-3 ml-auto" @click="editFormSubmit">Proximo</base-button>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TipoProcessoStep1",
  props: {
    propTipoNome: { type: Object },
    concluiStep1: { type: Function }
  },
  data() {
    return {
      tipoProcesso: "",
      nome: ""
    }
  },
  methods: {
    editFormSubmit(value) {
      if (value === 'simples') {
        this.propTipoNome.fl_tipo = 'simples';
        this.concluiStep1();
      } else if (value === 'auto') {
        this.propTipoNome.fl_tipo = 'auto';
        this.concluiStep1();
      } else if (value === 'custom') {
        this.propTipoNome.fl_tipo = 'custom';
        this.concluiStep1();
      }
    }
  }
}
</script>

<style>

</style>
