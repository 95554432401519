<template>
  <ul class="list-group list-group-flush list my--3">
    <template v-for="(ender, index) in enderecos">
    <li class="list-group-item p-0"
      :key="index"
      v-if="index < 5"
    >
      <div class="row align-items-center my-1">
        <div class="col">
          <h4 class="mb-0">
            <a href="#"> {{ender.nm_nome_endereco}}</a><br>
            <small class="text-muted">{{ender.nm_cidade}} - {{ender.nm_estado}} - {{ender.nm_pais}}</small>
          </h4>
          <small>{{ender.nm_endereco}}, {{ender.nu_numero}}{{ender.nm_complemento !== '' ? ` - ${ender.nm_complemento}` : '' }} - {{ender.nm_bairro}} - {{ender.nu_cep}}</small>
        </div>
      </div>
    </li>
    </template>
  </ul>
</template>
<script>

export default {
  name: 'EnderecoList',
  computed: {
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    }
  }
};
</script>
<style></style>
