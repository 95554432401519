<template>
  <div v-loading="loading">
    <!-- Linha do nome da fase -->
    <div class="row justify-content-center mb-3">
      <div class="col-6 align-self-center">
        <label for="nomefase">Nome da fase</label>
        <input
          id="nomefase"
          ref="nomefase"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model.trim.lazy="propTestesPerfil.value"
          @blur="validaOnBlur()"
        />
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-if="Object.keys(propTestesPerfil.configs).length > 0">
      <div class="col-5 align-self-center">
        <template v-if="propTestesPerfil.configs.id_teste !== ''">
          <table>
            <tr class="border-bottom">
              <td class="w-100 pt-3">
                <span class="h4">{{ retonaNomeTeste(propTestesPerfil.configs.id_teste, 'nome') }}</span><br>
                <span class="text-muted">{{ retonaNomeTeste(propTestesPerfil.configs.id_teste, 'descricao') }}</span>
              </td>
              <td class="pt-3">
                <!-- botao excluir -->
                <base-button
                  type="danger"
                  size="sm"
                  class="btn-icon-only"
                  @click="removeTestePerfil()"
                ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
                </base-button>
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
    <!-- Adiciona Testes como Padrão -->
    <div class="row justify-content-center mt-2" v-if="propTestesPerfil.configs.id_teste === ''">
      <div class="col-4 align-self-center">
        <label>Escolha um teste de perfil para definir como padrão:</label>
        <el-select
          required
          class="inuptAutocomplete"
          v-model="addTestePerfil"
          placeholder="Escolha o Teste"
        >
        <template v-for="teste in propTestes">
          <template v-if="configEmpresa.ps.testePerfil > 1">
            <el-option
              :key="teste.id_teste"
              :label="teste.nm_nome"
              :value="teste.id_teste">
            </el-option>
          </template>
          <template v-else-if="teste.nm_tipo === 'simples'">
            <el-option
              :key="teste.id_teste"
              :label="teste.nm_nome"
              :value="teste.id_teste">
            </el-option>
          </template>
        </template>
        </el-select>
      </div>
      <div class="col-1 align-self-end">
        <base-button type="default" class="btn-icon-only" @click="definirTestePerfil"><i class="fas fa-plus"></i></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../../mixins/defaultMatchjob';
import {
  Loading,
  Select,
  Option
} from 'element-ui';

Vue.use(Loading.directive);
export default {
  name: "TestesPerfil",
  mixins: [defaultMatchjob],
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    propTestesPerfil: { type: Object },
    propTestes: { type: Array }
  },
  data() {
    return {
      loading: false,
      addTestePerfil: "",
      enviaTestes: {}
    }
  },
  methods: {
    definirTestePerfil() {
      this.loading = true;
      if (this.addTestePerfil === '') {
        this.notificacao('Você precisa escolher um teste antes!', 'warning');
        this.loading = false;
      } else {
        setTimeout(() => {
          this.propTestesPerfil.configs.id_teste = this.addTestePerfil;
          this.addTestePerfil = "";
          this.loading = false;
        }, 500);
      }
    },
    removeTestePerfil() {
      this.loading = true;
      setTimeout(() => {
        this.propTestesPerfil.configs.id_teste = "";
        this.loading = false;
      }, 500);
    },
    validaOnBlur(){
      if (this.propTestesPerfil.value === '') {
        this.propTestesPerfil.value = 'Envio de Testes'
      }
    },
    retonaNomeTeste(id, flag){
      var teste = this.propTestes.find(i => i.id_teste === id);
      if (flag === 'nome'){
        return teste.nm_nome;
      } else {
        return teste.nm_descricao;
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.select-testes {
  width: 200px;
}
</style>
