<template>
  <div>
    <div class="row justify-content-center mt-3">
      <div class="col-8">
        <h4>Definição dos parâmetros de match da MatchJobAI</h4>
      </div>
    </div>
    <!-- Pretensão Salarial -->
    <div class="row justify-content-center my-3">
      <div class="col-3 align-self-center">
        <label>Avaliar pretensão salarial?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          id="pretensao"
          class="ml-2"
          v-model="propTriagem.pretensaoSalarial.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <label>Faixa de Aceitação</label>
        <el-select
          required
          class="inuptAutocomplete"
          v-model="propTriagem.pretensaoSalarial.faixa"
          placeholder="Faixa de Aceitação"
          :disabled="!propTriagem.pretensaoSalarial.ativo"
        >
          <el-option
            v-for="tipo in faixaSalarial"
            :key="tipo.value"
            :label="tipo.label"
            :value="tipo.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- Competências -->
    <div class="row justify-content-center my-4">
      <div class="col-3 align-self-center">
        <label>Avaliar Competências?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.competencias.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <small
            v-if="!propTriagem.competencias.ativo"
            class="text-muted">Qualquer configuração de requisito de competência incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Idiomas -->
    <div class="row justify-content-center my-4">
      <div class="col-3 align-self-center">
        <label>Avaliar Idiomas?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.idiomas.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <small
            v-if="!propTriagem.idiomas.ativo"
            class="text-muted">Qualquer configuração de requisito de idioma incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Formação -->
    <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.formacao">
      <div class="col-3 align-self-center">
        <label>Avaliar Formações?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.formacao.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <small
            v-if="!propTriagem.formacao.ativo"
            class="text-muted"
        >Qualquer configuração de requisito de formação incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Certificações -->
    <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.certificacoes">
      <div class="col-3 align-self-center">
        <label>Avaliar Certificações?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.certificacao.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <small
            v-if="!propTriagem.certificacao.ativo"
            class="text-muted"
        >Qualquer configuração de requisito de certificação incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Localização -->
    <div class="row justify-content-center my-4">
      <div class="col-3 align-self-center">
        <label>Avaliar a Distância?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.localizacao.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <template v-if="propTriagem.localizacao.ativo">
          <template v-if="configEmpresa.matchAi.localizacao === '1'">
            <p>Verifica se o candidato reside na mesma Cidade/Estado da localização vaga.</p>
          </template>
          <template v-else-if="configEmpresa.matchAi.localizacao === '2'">
            <p>Verifica se o candidato reside proximo ao bairro da localização vaga.</p>
          </template>
          <template v-else-if="configEmpresa.matchAi.localizacao === '3'">
            <p class="h5">Calcula o tempo do trajeto entre a residencia do candidato e a localização da vaga.<br>
            <small>O calculo é estimado considerando dias úteis e o horário de pico da manhã.</small>
            </p>
          </template>
        </template>
      </div>
    </div>
    <!-- Tempo de Experiência -->
    <div class="row justify-content-center my-4" v-if="configEmpresa.matchAi.tempoExperiencia">
      <div class="col-3 align-self-center">
        <label>Avaliar Tempo de Experiência?</label>
      </div>
      <div class="col-1 align-self-center">
        <base-switch
          class="ml-2"
          v-model="propTriagem.tempoExperiencia.ativo"
        ></base-switch>
      </div>
      <div class="col-4 align-self-center">
        <small
            v-if="!propTriagem.tempoExperiencia.ativo"
            class="text-muted"
        >Qualquer configuração de requisito de tempo de experiência incluído nas vagas serão ignorados.</small>
      </div>
    </div>
  </div>
</template>

<script>
import defaultMatchjob from '../../../mixins/defaultMatchjob';
import {
  Select,
  Option
} from 'element-ui';
export default {
  name: "TriagemConfig",
  mixins: [defaultMatchjob],
  props: {
    propTriagem: { type: Object }
  },
  components:{
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      triagem: {},
      faixaSalarial: [
        { value: "1", label: "Menor ou Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ],
    }
  },
  methods: {
    initValues() {
      //....
    }
  },
  mounted() {
    setTimeout(() => {//this.initValues();
      this.triagem = this.propTriagem;
    }, 300);
  }
}
</script>

<style>

</style>
