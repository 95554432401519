<template>
  <div>
    <div class="row justify-content-center my-2 pb-2 border-bottom">
      <div class="col align-self-center">
        <label>A barra de menu deverá ser sempre fixa (visível) no topo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propNavbar.fixa_topo"
        ></base-switch>
      </div>
    </div>
    <!-- Transparente - DESABILITADA - SEMPRE FALSE -->
    <!-- <div class="row justify-content-center my-2 pb-2 border-bottom">
      <div class="col align-self-center">
        <label>A barra de menu deverá ser 'transparente'?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propNavbar.transparente"
        ></base-switch>
      </div>
    </div> -->
    <!-- Cor padrão da barra de menu -->
    <div class="row justify-content-center my-2 pb-2 border-bottom">
      <div class="col align-self-center">
        <label>Defina a cor padrão da 'barra de menu':</label><br>
        <p>Cor atual: <i class="fas fa-square-full img-thumbnail mx-1" :class="`nav-pg-${propNavbar.cor_padrao}`"></i></p>
        <i class="fas fa-square-full img-thumbnail nav-pg-black mx-1" @click="selectColor('black')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-white mx-1" @click="selectColor('white')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-primary mx-1" @click="selectColor('primary')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-success mx-1" @click="selectColor('success')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-info mx-1" @click="selectColor('info')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-warning mx-1" @click="selectColor('warning')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-danger mx-1" @click="selectColor('danger')"></i>
        <i class="fas fa-square-full img-thumbnail nav-pg-default mx-1" @click="selectColor('default')"></i>
      </div>
    </div>
    <!-- Flag de utilização do logo -->
    <div class="row justify-content-center my-2 pb-2 border-bottom">
      <div class="col align-self-center">
        <label>O logo deve ser mostrado?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propNavbar.logoAtivo"
          @input="configuraLogo()"
        ></base-switch>
      </div>
    </div>
    <!-- Apresenta o logo definido -->
    <div class="row justify-content-center my-2 pb-2 border-bottom" v-if="propNavbar.logoAtivo">
      <div class="col align-self-center">
        <label>O logo atual:</label><br>
        <img :src="propNavbar.logo" class="img-fluid my-2" alt="Logo">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarConfig",
  props: {
    propNavbar: Object,
    propLogoPadrao: String
  },
  methods: {
    selectColor(value) {
      this.propNavbar.cor_padrao = value;
    },
    configuraLogo() {
      if (this.propLogoPadrao !== '') {
        this.propNavbar.logo = this.propLogoPadrao;
      } else {
        this.$notify({
          message: "Não há logo definido.",
          timeout: 4000,
          icon: 'ni ni-bell-55',
          type: 'warning'
        });
      }
    }
  }
}
</script>

<style>
.nav-pg-black {
  color: #000000;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-white {
  color: #FFFFFF;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-primary {
  color: #5e72e4;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-success {
  color: #18ce0f;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-info {
  color: #2CA8FF;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-warning {
  color: #FFB236;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-danger {
  color: #FF3636;
  font-size: 2em;
  cursor: pointer;
}
.nav-pg-default {
  color: #888888;
  font-size: 2em;
  cursor: pointer;
}
</style>
