<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Formações acadêmicas</h5>
      </div>
    </div>
    <div v-for="(formacao, index) in propFormacoes" :key="index">
      <div class="row justify-content-start align-items-start">
        <div class="col-3">
          <small>Tipo da Formação:</small><br><strong>{{retornaTipoFormacao(formacao.fl_tipo_formacao)}}</strong>
        </div>
        <div class="col-4">
          <small>Instituição:</small><br><strong>{{formacao.nm_instituicao}}</strong>
        </div>
        <div class="col-5">
          <small>Curso:</small><br><strong>{{formacao.nm_curso}}</strong>
        </div>
      </div>
      <div class="row justify-content-start align-items-start">
        <div class="col-3">
          <small>Data de inicio:</small><br><strong>{{formacao.dt_inicio | formatDate}}</strong>
        </div>
        <div class="col-4">
          <small>Data de conclusão:</small><br><strong>{{formacao.dt_fim | formatDate}}</strong>
        </div>
        <div class="col-5">
          <small>Concluído?</small><br><strong>{{formacao.fl_status ? 'Sim' : 'Não'}}</strong>
        </div>
      </div>
      <div class="row justify-content-start align-items-start" v-if="formacao.tx_observacoes">
        <div class="col-12">
          <small>Resumo das atividades:</small><br><strong>{{formacao.tx_observacoes}}</strong>
        </div>
      </div>
      <hr class="my-2">
    </div>
    <!-- <pre>{{propFormacoes}}</pre> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "FormacoesCV",
  props: {
    propFormacoes: { type: Array }
  },
  data() {
    return {
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
    }
  },
  methods: {
    retornaTipoFormacao(id) {
      var tipo = this.sysformacoes.find(i => i.id === id);
      return tipo.text;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  }
}
</script>

<style>

</style>
