<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Competências</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <template v-if="propCompetenciasVaga.length < 1">
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12">
          <small><strong>A vaga não possui competências definidas.</strong></small>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-for="(comp, index) in propCompetenciasVaga">
        <template v-if="verificaCompetenciaMatch(comp.value, index)">
          <div :key="comp.id">
            <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
              <div class="col-12">
                Resultado do match: <strong>{{comp.value}}</strong> - <strong><span :class="propCompetencias[arrComps[index]].match_nivel ? 'text-success' : 'text-danger'">{{propCompetencias[arrComps[index]].match_nivel ? 'Positivo' : 'Negativo'}}</span></strong> <span
                :class="propCompetencias[arrComps[index]].match_nivel ? 'text-success' : 'text-danger'"
                >
                <i class="fas" :class="propCompetencias[arrComps[index]].match_nivel ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="flDetalhes">
                <div class="row justify-content-start align-items-start mt-1 mb-3">
                  <div class="col-4">
                    Nível da competência da vaga:<br><strong>{{retornaNivelCompetencia(propCompetencias[arrComps[index]].nivel_vaga)}}</strong>
                  </div>
                  <div class="col-auto">
                    Nível da competência do candidato:<br><strong>{{retornaNivelCompetencia(propCompetencias[arrComps[index]].nivel_candidato)}}</strong> <span
                    :class="propCompetencias[arrComps[index]].nivel_candidato >= propCompetencias[arrComps[index]].nivel_vaga ? 'text-success' : 'text-danger'"
                    >
                      <i class="fas fa-equals" v-if="propCompetencias[arrComps[index]].nivel_candidato === propCompetencias[arrComps[index]].nivel_vaga"></i>
                      <i class="far fa-arrow-alt-circle-up" v-else-if="propCompetencias[arrComps[index]].nivel_candidato > propCompetencias[arrComps[index]].nivel_vaga"></i>
                      <i class="far fa-arrow-alt-circle-down" v-else-if="propCompetencias[arrComps[index]].nivel_candidato < propCompetencias[arrComps[index]].nivel_vaga"></i>
                    </span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>
        <template v-else>
          <div :key="comp.id">
            <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
              <div class="col-12">
                Resultado do match: <strong>{{comp.value}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                class="text-danger"
                >
                <i class="fas fa-times-circle"></i></span>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="flDetalhes">
                <div class="row justify-content-start align-items-start mt-1" :key="index">
                  <div class="col-12">
                    O candidato não deu 'match'com essa competência.
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </template>
      </template>
    </template>
    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <div class="row justify-content-start align-items-start">
      <div class="col-16">
        propCompetencias -> <pre>{{propCompetencias}}</pre>
      </div>
      <div class="col-16">
        propCompetenciasVaga -> <pre>{{propCompetenciasVaga}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "CompetenciasComp",
  props: {
    propCompetencias: { type: Array },
    propCompetenciasVaga: { type: Array }
  },
  data() {
    return {
      nivelCompetencia: [
        { value: 1, text: "Básico / Júnior" },
        { value: 2, text: "Intermediário / Pleno" },
        { value: 3, text: "Avançado / Sênior" },
        { value: 4, text: "Especialista" }
      ],
      flDetalhes: false,
      arrComps: []
    }
  },
  methods: {
    retornaNivelCompetencia(id) {
      var nivel = this.nivelCompetencia.find(i => i.value === parseInt(id));
      return nivel.text;
    },
    verificaCompetenciaMatch(value, index) {
      var comp = this.propCompetencias.findIndex(i => i.competencia === value);
      if (comp > -1){
        this.arrComps[index] = comp;
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
