<template>
  <div>
    <!-- Ativo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco estará ativo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propVideoSimples.ativo"
        ></base-switch>
      </div>
    </div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propVideoSimples.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Nome da Empresa"
          v-model.lazy="propVideoSimples.titulo"
        />
      </div>
    </div>
    <!-- Youtube/Vimeo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Origem do Vídeo:</label>
        <el-select
          required
          class="inuptAutocomplete"
          v-model="propVideoSimples.tipoVideo"
          placeholder="Origem do Vídeo"
        >
          <el-option label="YouTube" value="youtube"></el-option>
          <el-option label="Vímeo" value="vimeo"></el-option>
        </el-select>
      </div>
    </div>
    <!-- URL do Vídeo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="link">Link:</label>
        <input
          id="link"
          ref="link"
          type="url"
          maxlength="100"
          class="form-control"
          name="Link"
          :placeholder="propVideoSimples.tipoVideo === 'youtube' ? 'https://www.youtube.com/embed/...' : 'https://player.vimeo.com/video/...'"
          v-model.lazy="propVideoSimples.link"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option
} from 'element-ui';
export default {
  name: "VideoSimples",
  props: {
    propVideoSimples: Object
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  }
}
</script>

<style>

</style>
