<template>
  <div v-loading="loading">
    <!-- Titulo da vaga -->
    <div class="row justify-content-center mb-3">
      <div class="col-8">
        <label for="titulo">Título da vaga (cargo) <span class="text-danger">*</span></label>
        <el-autocomplete
          ref="titulo"
          id="titulo"
          class="inuptAutocomplete"
          :maxlength="255"
          v-model="vaga.nm_cargo"
          :fetch-suggestions="querySearchCargo"
          placeholder="Cargo"
          :trigger-on-focus="false"
          @input="debounceDefineUrl"
          @blur="validaOnBlur('titulo')"
        ></el-autocomplete>
        <small class="invalid-feedback" style="display: block;" v-if="validate.titulo">O campo "Título da Vaga (cargo)" é obrigatório.</small>
      </div>
    </div>
    <!-- URL da vaga -->
    <div class="row justify-content-center mb-3" v-loading="loadingUrl">
      <div class="col-8">
        <label for="url">URL amigável da vaga</label><br>
        <small class="text-muted">https://vagas.matchjob.com.br/{{ makeUrl(nomeFantasia) }}/<strong>{{ vaga.hl_link }}</strong></small>
      </div>
    </div>
    <!-- Area de atuacao e nivel hirarquico -->
    <div class="row justify-content-center">
      <div class="col-4">
        <div class="form-group">
        <label for="areaatuacao">Área de Atuação <span class="text-danger">*</span></label>
          <el-select
            ref="areaatuacao"
            name="Área de Atuação"
            class="inuptAutocomplete"
            id="areaatuacao"
            v-model="vaga.nm_area"
            @blur="validaOnBlur('areaatuacao')"
          >
            <el-option
              v-for="areas in areasatuacao"
              :key="areas.value"
              :value="areas.value"
              :label="areas.text"
            ></el-option>
          </el-select>
          <small class="invalid-feedback" style="display: block;" v-if="validate.areaatuacao">O campo "Área de Atuação" é obrigatório.</small>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="nivel">Nível Hieráquico <span class="text-danger">*</span></label>
          <el-select
            ref="nivel"
            name="Nível Hieráquico"
            class="inuptAutocomplete"
            label="Nível Hieráquico"
            id="nivel"
            v-model="vaga.nm_nivel"
            @blur="validaOnBlur('nivel')"
          >
            <el-option
              v-for="nivel in niveishierarquicos"
              :key="nivel.value"
              :value="nivel.value"
              :label="nivel.text"
            ></el-option>
          </el-select>
          <small class="invalid-feedback" style="display: block;" v-if="validate.nivel">O campo "Nível Hieráquico" é obrigatório.</small>
        </div>
      </div>
    </div>
    <!-- Solicitante e Depto -->
    <div class="row justify-content-center mb-3">
      <div class="col-4">
        <label for="solicitante">Solicitante <span class="text-danger">*</span></label>
        <el-autocomplete
          ref="solicitante"
          popper-class="my-autocomplete"
          class="inuptAutocomplete"
          :maxlength="100"
          v-model="vaga.nm_solicitante"
          :fetch-suggestions="querySearchUsuarios"
          placeholder="Solicitante"
          :trigger-on-focus="false"
          @select="handleSelect"
          @blur="validaOnBlur('solicitante')"
        >
          <template slot-scope="{ item }">
            <div class="value mb--3">{{ item.value }}</div>
            <small class="text-muted">{{ item.cargo }}</small>
          </template>
        </el-autocomplete>
        <small class="invalid-feedback" style="display: block;" v-if="validate.solicitante">O campo "Solicitante" é obrigatório.</small>
      </div>
      <div class="col-4">
        <label for="depto">Departamento <span class="text-danger">*</span></label>
        <el-autocomplete
          id="depto"
          ref="depto"
          class="inuptAutocomplete"
          :maxlength="30"
          v-model.trim="vaga.nm_depto"
          :fetch-suggestions="querySearchDepto"
          placeholder="Departamento"
          :trigger-on-focus="false"
          @blur="validaOnBlur('depto')"
        >
        </el-autocomplete>
        <small class="invalid-feedback" style="display: block;" v-if="validate.depto">O campo "Departamento" é obrigatório.</small>
      </div>
    </div>
    <!-- Data de abertura e fechamento -->
    <div class="row justify-content-center mb-3">
      <div class="col-4">
        <label for="dataabertura">Data de abertura <span class="text-danger">*</span></label>
        <input
          id="dataabertura"
          ref="dataabertura"
          type="date"
          class="form-control"
          name="Data de abertura"
          placeholder="Data de abertura"
          v-model="vaga.dt_abertura"
          @blur="validaOnBlur('dataabertura')"
        />
        <small class="invalid-feedback" style="display: block;" v-if="validate.dataabertura">O campo "Data de abertura" é obrigatório.</small>
      </div>
      <div class="col-4">
        <label for="datafechamento">Previsão de Fechamento <span class="text-danger">*</span></label>
        <input
          id="datafechamento"
          ref="dataprevfechamento"
          type="date"
          class="form-control"
          name="Previsão de Fechamento"
          placeholder="Previsão de Fechamento"
          :min="vaga.dt_abertura"
          v-model="vaga.dt_prev_fechamento"
          @blur="validaOnBlur('dataprevfechamento')"
        />
        <small class="invalid-feedback" style="display: block;" v-if="validate.datafechamento">O campo "Previsão de Fechamento" é obrigatório.</small>
      </div>
    </div>
    <!-- Tags da Vaga -->
    <div class="row justify-content-center">
      <div class="col-8">
        <label class="form-control-label">Tags</label>
        <tags-input v-model="vaga.json_tags" placeholder="Enter para adicionar" type="primary"></tags-input>
      </div>
    </div>
    <!-- Botão avançar -->
    <div class="row justify-content-center mt-5 mb-2">
      <div class="col-1">
        <base-button type="primary" @click="firstFormSubmit">Proximo</base-button>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-3">
        propSysInfos -> <pre>{{Object.keys(propSysInfos)}}</pre>
      </div>
      <div class="col-3">
        cargos -> <pre>{{cargos}}</pre>
      </div>
      <div class="col-3">
        usuarios-> <pre>{{propUsuarios}}</pre>
      </div>
    </div> -->
    <!-- <pre>{{propSysInfos.cargos}}</pre> -->
  </div>
</template>
<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { userKey } from "@/global";
import _ from "lodash";
import { Loading, Select, Option, Autocomplete } from 'element-ui';
import moment from "moment";
import TagsInput from '@/components/Inputs/TagsInput'

Vue.use(Loading.directive);

export default {
  name: "Step1",
  mixins: [defaultMatchjob],
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    TagsInput
  },
  props: {
    concluiStep1: { type: Function },
    propCargo: { type: String },
    propLink: { type: String },
    propArea: { type: String },
    propNivel: { type: String },
    propSolicitante: { type: String },
    propDepto: { type: String },
    propAbertura: { type: String },
    propFechamento: { type: String },
    propTags: { type: Array },
    propSysInfos: { type: Object },
    propUsuarios: { type: Array }
  },
  data() {
    return {
      validated: false,
      id: {
        empresa: "",
        empresa_user: "",
        usuario: "",
        admin: "",
        tipoAcesso: "",
        plano: []
      },
      vaga: {
        nm_cargo: '',
        hl_link: '',
        nm_area: '',
        nm_nivel: '',
        nm_solicitante: '',
        nm_depto: '',
        dt_abertura: '',
        dt_prev_fechamento: '',
        json_tags: []
      },
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      usuarios: [],
      cargos: [],
      deptos: [],
      validate: {
        titulo: false,
        areaatuacao: false,
        nivel: false,
        solicitante: false,
        depto: false,
        dataabertura: false,
        datafechamento: false,
      },
      loading: false,
      loadingUrl: false
    }
  },
  methods: {
    async firstFormSubmit() {
      if (this.validaCampos()) {
        this.loading = true;
        setTimeout(() => {
          this.$emit('resStep1', this.vaga);
          this.concluiStep1();
        }, 500);
      } else { return false; }
    },
    validaCampos() {
      if ( this.vaga.nm_cargo === '') {
        this.validate.titulo = true;
        this.$refs.titulo.focus();
        return false;
      } else if ( this.vaga.nm_area === '') {
        this.validate.areaatuacao = true;
        this.$refs.areaatuacao.focus();
        return false;
      } else if ( this.vaga.nm_nivel === '') {
        this.validate.nivel = true;
        this.$refs.nivel.focus();
        return false;
      } else if ( this.vaga.nm_solicitante === '') {
        this.validate.solicitante = true;
        this.$refs.solicitante.focus();
        return false;
      } else if ( this.vaga.nm_depto === '') {
        this.validate.depto = true;
        this.$refs.depto.focus();
        return false;
      } else if ( this.vaga.dt_abertura === '') {
        this.validate.dataabertura = true;
        this.$refs.dataabertura.focused = true;
        return false;
      } else if ( this.vaga.dt_prev_fechamento === '') {
        this.validate.datafechamento = true;
        this.$refs.dataprevfechamento.focused = true;
        return false;
      } else {
        return true;
      }
    },
    validaOnBlur(refName){
      setTimeout(() => {
        if (this.$refs[refName].value === '') {
          this.$set(this.validate, refName, true);
          return false;
        } else {
          this.$set(this.validate, refName, false);
          return true;
        }
      }, 300);
    },
    initValues() {
      this.loading = true;
      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.id.empresa = userData.id_empresa;
      this.id.empresa_user = userData.id_empresa_user;
      this.id.usuario = userData.hl_email;
      this.id.admin = userData.fl_admin === "1" ? true : false;
      this.id.tipoAcesso = userData.fl_tipo_acesso;
      this.id.plano = userData.plano;
      setTimeout(() => {
        this.vaga.nm_cargo = this.propCargo;
        this.vaga.hl_link = this.propLink;
        this.vaga.nm_area = this.propArea;
        this.vaga.nm_nivel = this.propNivel;
        this.vaga.nm_solicitante = this.propSolicitante;
        this.vaga.nm_depto = this.propDepto;
        this.vaga.dt_abertura = this.propAbertura;
        this.vaga.dt_prev_fechamento = this.propFechamento;
        this.vaga.json_tags = this.propTags;
        this.cargos = this.propSysInfos.cargos;
        this.deptos = this.propSysInfos.deptos;
        this.usuarios = this.propUsuarios;
        this.loading = false;
      }, 500)
    },
    //Função do Autocomplete de Departamentos
    querySearchDepto(queryString, cb) {
      let deptos = this.deptos;
      var results = queryString ? deptos.filter(this.createFilterDepto(queryString)) : deptos;
      cb(results)
    },
    createFilterDepto(queryString) {
      return (depto) => {
        return (depto.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Cargos
    querySearchCargo(queryString, cb) {
      let cargos = this.cargos;
      var results = queryString ? cargos.filter(this.createFilterCargo(queryString)) : cargos;
      cb(results)
    },
    createFilterCargo(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Usuarios
    querySearchUsuarios(queryString, cb) {
      let usuarios = this.usuarios.map(a => {
        return { value: a.nm_nome, cargo: a.nm_cargo, depto: a.nm_depto }
      });
      //console.log("usuarios depois do map -> ", usuarios);
      var results = queryString ? usuarios.filter(this.createFilterCargo(queryString)) : usuarios;
      cb(results)
    },
    createFilterUsuarios(queryString) {
      return (usuario) => {
        return (usuario.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.vaga.nm_depto = item.depto;
      this.$nextTick(() => {
        this.$refs.dataprevfechamento.focused = true;
      });
    },
    async checkURL(url) {
      const data = {
        id_empresa: this.id.empresa,
        hl_link: url
      }
      const result = await this.$http.post('vagas/checkurl', data).then(res => {
        return res.data;
      });
      return result;
    },
    makeUrl(valor) {
      var str = valor.toLowerCase();
      var str1 = str.replace(/[áàãâä]/gi, 'a');
      var str2 = str1.replace(/[éèêë]/gi, 'e');
      var str3 = str2.replace(/[íìîï]/gi, 'i');
      var str4 = str3.replace(/[óòõôö]/gi, 'o');
      var str5 = str4.replace(/[úùûü]/gi, 'u');
      var str6 = str5.replace(/[ç]/gi, 'c');
      var result = str6.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '');
      return result;
    },
    async defineUrl() {
      this.loadingUrl = true;
      var url = this.makeUrl(this.vaga.nm_cargo);
      var urlFinal = "";
      if (await this.checkURL(url)) {
        urlFinal = url;
      } else {
        moment.locale('pt-br');
        var mesAno = moment().format("MMMM-YYYY");
        var chance1 = url + '-' + mesAno;
        if (await this.checkURL(chance1)) {
          urlFinal = chance1;
        } else {
          var id = Math.random().toString(16).slice(2);
          urlFinal = url + '-' + mesAno + '-' + id;
        }
      }
      setTimeout(() => {
        this.vaga.hl_link = urlFinal;
        this.loadingUrl = false;
      }, 500);
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  async created() {
    this.debounceDefineUrl = _.debounce(() => {this.defineUrl()}, 5000)
  },
  beforeUnmount() {
    this.debounceDefineUrl.cancel();
  },
  mounted() {
    this.initValues();
    this.$refs.titulo.focus();
  }
}
</script>
<style>
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
</style>
