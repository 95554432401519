<template>
  <div>
    <div v-for="(item, index) in propTimeline" :key="index">
      <CompSistema
        v-if="item.tipo_registro === 'sistema'"
        :propItem="item"
      />
      <CompAprovacao
        v-if="item.tipo_registro === 'aprovacao'"
        :propItem="item"
      />
      <CompCandidatura
        v-if="item.tipo_registro === 'candidatura'"
        :propItem="item"
      />
      <CompEdicao
        v-if="item.tipo_registro === 'edicao'"
        :propItem="item"
      />
      <CompEdicao
        v-if="item.tipo_registro === 'publicacao'"
        :propItem="item"
      />
    </div>
    <!-- <pre>{{propTimeline}}</pre> -->
  </div>
</template>

<script>
import CompSistema from "./CompSistema.vue";
import CompAprovacao from "./CompAprovacao.vue";
import CompCandidatura from "./CompCandidatura.vue";
import CompEdicao from "./CompEdicao.vue";

export default {
  name: "TimelineVaga",
  props: {
    propTimeline: { type: Array }
  },
  components: {
    CompSistema,
    CompAprovacao,
    CompCandidatura,
    CompEdicao
  }
}
</script>

<style>

</style>
