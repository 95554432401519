<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="icones-caracteristicas-inativo fas fa-comment-dots m-2" v-if="propItem.tipo_registro === 'comentario'"></i>
        <i class="icones-caracteristicas-inativo far fa-thumbs-up m-2" v-else-if="propItem.tipo_registro === 'score_action'"></i>
        <span class="icones-caracteristicas-inativo material-symbols-outlined" v-else-if="propItem.tipo_registro === 'mudanca_fase'">keyboard_double_arrow_right</span>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid rounded w-75" :src="retornaUsuario('foto')" :alt="retornaUsuario('nome')" />
          </div>
          <div class="col-11 align-self-center">
            <small><strong>{{retornaUsuario('nome')}}</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            {{propItem.text}}
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CompGenerico",
  props: {
    propItem: { type: Object }
  },
  data(){
    return {
      usuarios: []
    }
  },
  methods: {
    retornaUsuario(value) {
      if(this.$store.state.usuarios.usuarios.length > 0) {
        var user = this.$store.state.usuarios.usuarios.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(user !== undefined) {
          if(value === 'nome'){
            return user.nm_nome;
          } else if(value === 'foto'){
            return user.hl_foto;
          }
        } else {
          if(value === 'nome'){
            return "MatchJob Empresas";
          } else if(value === 'foto'){
            return "https://storagematchjobdefault.blob.core.windows.net/matchjob/icone-50x50.png";
          }
        }
      }
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
</style>
