<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>Objetivos</h5>
      </div>
    </div>
    <div class="row justify-content-start align-items-start">
      <div class="col-4">
        <small>Cargo:</small><br><strong>{{propObjetivo.nm_cargo}}</strong>
      </div>
      <div class="col-4">
        <small>Local de trabalho:</small><br><strong>{{propObjetivo.nm_cidade}}</strong>
      </div>
      <div class="col-4">
        <small>Salário desejado:</small><br><strong>{{propObjetivo.nu_salario}}</strong>
      </div>
    </div>
    <div class="row justify-content-start align-items-start">
      <div class="col-4">
        <small>Área de atuação:</small><br><strong>{{retornaAreaAtuacao(propObjetivo.nm_area)}}</strong>
      </div>
      <div class="col-4">
        <small>Nível Hieráquico:</small><br><strong>{{retornaNivelHierarquico(propObjetivo.nm_nivel)}}</strong>
      </div>
      <div class="col-4">
        <small>Período:</small><br><strong>{{retornaJornada(propObjetivo.fl_jornada)}}</strong>
      </div>
    </div>
    <div class="row justify-content-start align-items-start">
      <div class="col-4">
        <small>Tipo de Contratação:</small><br><strong>{{retornaTipoContratacao(propObjetivo.fl_tipo_contrato)}}</strong>
      </div>
      <div class="col-4">
        <small>Aceita contrato PJ?</small><br><strong>{{propObjetivo.fl_pj ? 'Sim' : 'Não'}}</strong>
      </div>
      <div class="col-4">
        <small>Primeiro emprego?</small><br><strong>{{propObjetivo.fl_primeiro_emprego ? 'Sim' : 'Não'}}</strong>
      </div>
    </div>
    <!-- <pre>{{propObjetivo}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "ObjetivosCv",
  props: {
    propObjetivo: { type: Object }
  },
  data() {
    return {
      areasatuacao: [
        { id: 51, text: "Administração" },
        { id: 52, text: "Agricultura, Pecuária, Veterinária" },
        { id: 92, text: "Alimentação / Gastronomia" },
        { id: 55, text: "Arquitetura, Decoração, Design" },
        { id: 56, text: "Artes" },
        { id: 57, text: "Auditoria" },
        { id: 58, text: "Ciências, Pesquisa " },
        { id: 59, text: "Comercial, Vendas" },
        { id: 60, text: "Comércio Exterior, Importação, Exportação" },
        { id: 62, text: "Compras" },
        { id: 63, text: "Comunicação, TV, Cinema" },
        { id: 64, text: "Construção, Manutenção" },
        { id: 66, text: "Contábil, Finanças, Economia" },
        { id: 67, text: "Cultura, Lazer, Entretenimento" },
        { id: 69, text: "Educação, Ensino, Idiomas " },
        { id: 70, text: "Engenharia" },
        { id: 71, text: "Estética" },
        { id: 72, text: "Hotelaria, Turismo" },
        { id: 73, text: "Industrial, Produção, Fábrica" },
        { id: 74, text: "Informática, TI, Telecomunicações" },
        { id: 75, text: "Jurídica" },
        { id: 54, text: "Logística" },
        { id: 77, text: "Marketing" },
        { id: 78, text: "Meio Ambiente, Ecologia" },
        { id: 93, text: "Moda" },
        { id: 79, text: "Qualidade " },
        { id: 80, text: "Química, Petroquímica" },
        { id: 81, text: "Recursos Humanos" },
        { id: 82, text: "Saúde" },
        { id: 83, text: "Segurança" },
        { id: 76, text: "Serviços Gerais" },
        { id: 85, text: "Serviço Social e Comunitário" },
        { id: 86, text: "Telemarketing" },
        { id: 87, text: "Transportes" }
      ],
      niveishierarquicos: [
        { id: 1, text: "Estagiário" },
        { id: 2, text: "Operacional" },
        { id: 3, text: "Auxiliar" },
        { id: 4, text: "Assistente" },
        { id: 5, text: "Trainee" },
        { id: 6, text: "Analista" },
        { id: 7, text: "Encarregado" },
        { id: 8, text: "Supervisor" },
        { id: 9, text: "Consultor" },
        { id: 10, text: "Especialista" },
        { id: 11, text: "Coordenador" },
        { id: 12, text: "Gerente" },
        { id: 13, text: "Diretor" }
      ],
      jornadas: [
        { id: 1, text: "Período Integral" },
        { id: 2, text: "Parcial manhãs" },
        { id: 5, text: "Parcial tardes" },
        { id: 6, text: "Parcial noites" },
        { id: 12, text: "Noturno" }
      ],
      tiposcontrato: [
        { id: 1, text: "Autônomo" },
        { id: 2, text: "Cooperado" },
        { id: 3, text: "Efetivo & CLT" },
        { id: 4, text: "Estágio" },
        { id: 5, text: "Outros" },
        { id: 6, text: "Prestador de Serviços (PJ)" },
        { id: 7, text: "Temporário" },
        { id: 8, text: "Trainee" }
      ],
    }
  },
  methods: {
    retornaAreaAtuacao(id) {
      var area = this.areasatuacao.find(i => i.id === id);
      return area.text;
    },
    retornaNivelHierarquico(id) {
      var area = this.niveishierarquicos.find(i => i.id === id);
      return area.text;
    },
    retornaJornada(id) {
      var area = this.jornadas.find(i => i.id === id);
      return area.text;
    },
    retornaTipoContratacao(id) {
      var area = this.tiposcontrato.find(i => i.id === id);
      return area.text;
    }
  }
}
</script>

<style>

</style>
