<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Localização</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <template v-if="propLocalizacao.status">
      <template v-if="propLocalizacao.tipo_localizacao === 'ce'">
        <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
          <div class="col-12">
            Resultado do match: <strong><span :class="retornaCE() ? 'text-success' : 'text-danger'">{{retornaCE() ? 'Positivo' : 'Negativo'}}</span></strong> <span
              :class="retornaCE() ? 'text-success' : 'text-danger'"
              >
              <i class="fas" :class="retornaCE() ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="flDetalhes">
            <div class="row justify-content-start align-items-start mt-1">
              <div class="col-3">
                Match para a cidade: <strong><span :class="propLocalizacao.match_cidade ? 'text-success' : 'text-danger'">{{propLocalizacao.match_cidade ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="propLocalizacao.match_cidade ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="propLocalizacao.match_cidade ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
              <div class="col-3">
                Match para o estado: <strong><span :class="propLocalizacao.match_estado ? 'text-success' : 'text-danger'">{{propLocalizacao.match_estado ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="propLocalizacao.match_estado ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="propLocalizacao.match_estado ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
            </div>
          </div>
        </transition>
      </template>
      <template v-if="propLocalizacao.tipo_localizacao === 'bce'">
        <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
          <div class="col-12">
            Resultado do match: <strong><span :class="retornaBCE() ? 'text-success' : 'text-danger'">{{retornaBCE() ? 'Positivo' : 'Negativo'}}</span></strong> <span
              :class="retornaBCE() ? 'text-success' : 'text-danger'"
              >
              <i class="fas" :class="retornaBCE() ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="flDetalhes">
            <div class="row justify-content-start align-items-start mt-1">
              <div class="col-3">
                Match para o bairro: <strong><span :class="propLocalizacao.match_bairro ? 'text-success' : 'text-danger'">{{propLocalizacao.match_bairro ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="propLocalizacao.match_bairro ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="propLocalizacao.match_bairro ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
              <div class="col-3">
                Match para a cidade: <strong><span :class="propLocalizacao.match_cidade ? 'text-success' : 'text-danger'">{{propLocalizacao.match_cidade ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="propLocalizacao.match_cidade ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="propLocalizacao.match_cidade ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
              <div class="col-3">
                Match para o estado: <strong><span :class="propLocalizacao.match_estado ? 'text-success' : 'text-danger'">{{propLocalizacao.match_estado ? 'Positivo' : 'Negativo'}}</span></strong> <span
                  :class="propLocalizacao.match_estado ? 'text-success' : 'text-danger'"
                  >
                  <i class="fas" :class="propLocalizacao.match_estado ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
              </div>
            </div>
          </div>
        </transition>
      </template>
      <template v-if="propLocalizacao.tipo_localizacao === 'google'">
        <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
          <div class="col-12">
            Resultado do match: <strong><span :class="retornaMatchGoogle() ? 'text-success' : 'text-danger'">{{retornaMatchGoogle() ? 'Positivo' : 'Negativo'}}</span></strong> <span
              :class="retornaMatchGoogle() ? 'text-success' : 'text-danger'"
              >
              <i class="fas" :class="retornaMatchGoogle() ? 'fa-check-circle' : 'fa-times-circle'"></i></span>
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div v-if="flDetalhes">
            <div class="row justify-content-start align-items-start mt-1">
              <div class="col-12">
                Distância entre residência e local da Vaga: <strong>{{propLocalizacao.distancia}}</strong> <span
                    :class="retornaMatchGoogle() ? 'text-success' : 'text-danger'"
                    >
                      <i class="far fa-arrow-alt-circle-up" v-if="retornaMatchGoogle()"></i>
                      <i class="far fa-arrow-alt-circle-down" v-else></i>
                    </span>
              </div>
            </div>
            <div class="row justify-content-start align-items-start mt-1">
              <div class="col-12">
                Tempo de deslocamento com trânsito: <strong>{{propLocalizacao.duracao_transito}}</strong> <span
                    :class="retornaMatchGoogle() ? 'text-success' : 'text-danger'"
                    >
                      <i class="far fa-arrow-alt-circle-up" v-if="retornaMatchGoogle()"></i>
                      <i class="far fa-arrow-alt-circle-down" v-else></i>
                    </span>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </template>

    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <pre>{{propLocalizacao}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "LocalizacaoComp",
  props: {
    propLocalizacao: { type: Object }
  },
  data() {
    return {
      flDetalhes: false
    }
  },
  methods: {
    retornaCE() {
      if (this.propLocalizacao.match_estado && this.propLocalizacao.match_cidade) {
        return true;
      } else {
        return false;
      }
    },
    retornaBCE() {
      if (this.propLocalizacao.match_estado && this.propLocalizacao.match_cidade && this.propLocalizacao.match_bairro) {
        return true;
      } else {
        return false;
      }
    },
    retornaMatchGoogle() {
      var mins = this.propLocalizacao.duracao_transito_sec / 60;
      if (mins < 45) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
