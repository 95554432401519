<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3 linha-clique" @click="flDetalhes = !flDetalhes">
      <div class="col-10">
        <h5>Tempo de Experiência</h5>
      </div>
      <div class="col-2 text-right">
        <small class="text-primary">Detalhes</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
      </div>
    </div>
    <template v-if="propTempoExperiencia.status">
      <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
        <div class="col-12">
          Tempo total de trabalho: {{propTempoExperiencia.tempototalExpArr.anos}} anos, {{propTempoExperiencia.tempototalExpArr.meses}} meses e {{propTempoExperiencia.tempototalExpArr.dias}} dias.<br>
          <template v-if="propTempoExperiencia.resvalidaIdadeTempoTrabalho == 'Tempo de trabalho incompativel com a idade'">
            <span class="text-danger"><strong>{{propTempoExperiencia.resvalidaIdadeTempoTrabalho}}</strong></span>
          </template>
          <template v-else>
            <strong>{{propTempoExperiencia.resvalidaIdadeTempoTrabalho}}</strong>
          </template>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
        <div class="col-12">
          Resultado do match:<br>
          Cargo: "<strong>{{propVaga.nm_cargo}}</strong>" (de {{propVaga.json_nivelexperiencia.nivelCargo[0]}} a {{propVaga.json_nivelexperiencia.nivelCargo[1]}} anos) -
          <strong><span :class="propTempoExperiencia.nivel_definidos.cargo ? 'text-success' : 'text-danger'">{{propTempoExperiencia.nivel_definidos.cargo ? 'Positivo' : 'Negativo'}}</span></strong> <span
            :class="propTempoExperiencia.nivel_definidos.cargo ? 'text-success' : 'text-danger'">
            <i class="fas" :class="propTempoExperiencia.nivel_definidos.cargo ? 'fa-check-circle' : 'fa-times-circle'"></i>
          </span>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="flDetalhes">
          <template v-if="propTempoExperiencia.nivel_definidos.cargo">
            <template v-if="verificaCargoMatch()">
              <div class="row justify-content-start align-items-start pl-2 mt-1">
                <div class="col-12">
                  <small><strong>{{cargoMatch.nm_cargo}} ({{cargoMatch.qtd_exp}} experiências)</strong> - {{cargoMatch.anos}} anos e {{cargoMatch.meses}} meses</small>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="row justify-content-start align-items-start pl-2 mt-1">
                <div class="col-12">
                  <small>O candidato deu 'match'com este cargo e tempo requerido por <strong>cargo semelhante</strong>.</small>
                </div>
              </div>
              <div class="row justify-content-start align-items-start pl-2 mt-1">
                <template v-for="(cargo, index) in propTempoExperiencia.cargos">
                <div class="col-6" :key="index" v-if="validaTempo(propVaga.json_nivelexperiencia.nivelCargo[0], propVaga.json_nivelexperiencia.nivelCargo[1], cargo.anos)">
                  <small><strong>{{cargo.nm_cargo}} ({{cargo.qtd_exp}} experiências)</strong><br>{{cargo.anos}} anos e {{cargo.meses}} meses</small>
                </div>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="row justify-content-start align-items-start pl-2 mt-1">
              <div class="col-12">
                <small>O candidato não deu 'match'com este cargo ou tempo requerido.</small>
              </div>
            </div>
          </template>
        </div>
      </transition>
      <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
        <div class="col-12">
          Área "<strong>{{retornaAreaAtuacao(propVaga.nm_area)}}</strong>" (de {{propVaga.json_nivelexperiencia.nivelArea[0]}} a {{propVaga.json_nivelexperiencia.nivelArea[1]}} anos) -
          <strong><span :class="propTempoExperiencia.nivel_definidos.area ? 'text-success' : 'text-danger'">{{propTempoExperiencia.nivel_definidos.area ? 'Positivo' : 'Negativo'}}</span></strong> <span
            :class="propTempoExperiencia.nivel_definidos.area ? 'text-success' : 'text-danger'">
            <i class="fas" :class="propTempoExperiencia.nivel_definidos.area ? 'fa-check-circle' : 'fa-times-circle'"></i>
          </span>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="flDetalhes">
          <template v-if="propTempoExperiencia.nivel_definidos.area">
            <div class="row justify-content-start align-items-start pl-2 mt-1" v-if="verificaAreaMatch()">
              <div class="col-12">
                <small><strong>{{areaMatch.text}} ({{areaMatch.qtd_exp}} experiências)</strong> - {{areaMatch.anos}} anos e {{areaMatch.meses}} meses</small>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-start align-items-start pl-2 mt-1">
              <div class="col-12">
                <small>O candidato não deu 'match'com este área de atuação ou tempo requerido.</small>
              </div>
            </div>
          </template>
        </div>
      </transition>
      <div class="row justify-content-start align-items-start mt-2 linha-clique" @click="flDetalhes = !flDetalhes">
        <div class="col-12">
          Posição "<strong>{{retornaNivelHierarquico(propVaga.nm_nivel)}}</strong>" (de {{propVaga.json_nivelexperiencia.nivelHierarquico[0]}} a {{propVaga.json_nivelexperiencia.nivelHierarquico[1]}} anos) -
          <strong><span :class="propTempoExperiencia.nivel_definidos.nivel ? 'text-success' : 'text-danger'">{{propTempoExperiencia.nivel_definidos.nivel ? 'Positivo' : 'Negativo'}}</span></strong> <span
            :class="propTempoExperiencia.nivel_definidos.nivel ? 'text-success' : 'text-danger'">
            <i class="fas" :class="propTempoExperiencia.nivel_definidos.nivel ? 'fa-check-circle' : 'fa-times-circle'"></i>
          </span>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="flDetalhes">
          <template v-if="propTempoExperiencia.nivel_definidos.nivel">
            <div class="row justify-content-start align-items-start pl-2 mt-1" v-if="verificaNivelMatch()">
              <div class="col-12">
                <small><strong>{{nivelMatch.text}} ({{nivelMatch.qtd_exp}} experiências)</strong> - {{nivelMatch.anos}} anos e {{nivelMatch.meses}} meses</small>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-start align-items-start pl-2 mt-1">
              <div class="col-12">
                <small>O candidato não deu 'match'com esta posição ou tempo requerido.</small>
              </div>
            </div>
          </template>
        </div>
      </transition>
    </template>

    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <div class="row justify-content-start align-items-start">
      <div class="col-6">
        <pre>{{Object.keys(propTempoExperiencia)}}</pre>
        tempototalExpArr -> <pre>{{propTempoExperiencia.tempototalExpArr}}</pre>
        nivel_definidos -> <pre>{{propTempoExperiencia.nivel_definidos}}</pre>
        nivel -> <pre>{{propTempoExperiencia.nivel}}</pre>
      </div>
      <div class="col-6">
        <pre>{{propTempoExperiencia}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TempoExperienciaComp",
  props: {
    propTempoExperiencia: { type: Object },
    propVaga: { type: Object }
  },
  data() {
    return {
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "0", text: "Nível Hierárquico" },
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      flDetalhes: false,
      cargoMatch: {},
      areaMatch: {},
      nivelMatch: {}
    }
  },
  methods: {
    retornaAreaAtuacao(value) {
      var res = this.areasatuacao.find(i => i.value === value);
      return res.text;
    },
    retornaNivelHierarquico(value) {
      var res = this.niveishierarquicos.find(i => i.value === value);
      return res.text;
    },
    verificaCargoMatch() {
      var res = this.propTempoExperiencia.cargos.find(c => c.nm_cargo === this.propVaga.nm_cargo)
      if (res === undefined) {
        return false;
      } else {
        this.cargoMatch = res;
        return true;
      }
    },
    verificaAreaMatch() {
      var res = this.propTempoExperiencia.area.find(c => c.id === parseInt(this.propVaga.nm_area))
      if (res === undefined) {
        return false;
      } else {
        this.areaMatch = res;
        return true;
      }
    },
    verificaNivelMatch() {
      var res = this.propTempoExperiencia.nivel.find(c => c.id === parseInt(this.propVaga.nm_nivel))
      if (res === undefined) {
        return false;
      } else {
        this.nivelMatch = res;
        return true;
      }
    },
    validaTempo(min, max, anos) {
      if (min <= anos) {
        if (anos <= max) {
          return true;
        } else if(anos > max) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
}
</script>

<style>

</style>
