<template>
  <div v-if="propVaga.nm_cargo">
    <h3 class="card-title">{{propVaga.nm_cargo}}</h3>
    <h5>Status: <badge rounded :type="badgeStatusType(propVaga.fl_status)">{{ badgeStatus(propVaga.fl_status) }}</badge></h5>
    <h5>Fase do processo: <badge rounded type="success">{{ verificaFase() }}</badge></h5>
    <p class="font-weight-light">
      Abertura da vaga: <strong>{{ propVaga.dt_abertura | formatDateTime }}</strong><br>
      Dias restantes: <span :class="diasFaltantes(propVaga.dt_abertura, propVaga.dt_prev_fechamento, propVaga.fl_status, 'classe')">
        <strong>{{diasFaltantes(propVaga.dt_abertura, propVaga.dt_prev_fechamento, propVaga.fl_status, 'valor')}}</strong>
      </span><br>
      Previsão de fechamento: <strong>{{ propVaga.dt_prev_fechamento | formatDateTime }}</strong><br />
    </p>
    <div class="avatar-group">
      <h3>
        Candidaturas: <small class="text-muted">{{propVaga.json_candidaturas.length}}</small>
        <template v-if="propVaga.json_candidaturas.length > 2">
          <template v-for="(candidato, index) in propVaga.json_candidaturas">
            <a href="#!" class="avatar avatar-xs rounded-circle" v-if="index <= 6" :key="index">
              <img :src="candidato.curriculo.hl_foto" />
            </a>
          </template>
        </template>
      </h3>
      <base-button size="sm" type="primary" @click="goToVagaDashboard(propVaga.hl_link)"> Dashboard </base-button>
      <base-button size="sm" type="info" @click="goToVaga(propVaga.hl_link)"> Candidatos </base-button>
    </div>
    <!-- <pre>{{propVaga}}</pre> -->
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "CardProcesso",
  props: {
    propVaga: { type: Object }
  },
  data() {
    return {
      flStatusVaga: [
        { value: '1', text: "Aberta" },
        { value: '2', text: "Em Aprovação" },
        { value: '3', text: "Aprovada" },
        { value: '4', text: "Publicada" },
        { value: '5', text: "Rascunho" },
        { value: '6', text: "Encerrada" },
        { value: '7', text: "Cancelada" },
        { value: '8', text: "Pausado" },
        { value: '9', text: "Reprovada" }
      ],
    };
  },
  methods: {
    badgeStatus(value) {
      let tipo = this.flStatusVaga.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n === 2) {
        return "success";
      } else if (n === 3) {
        return "dark text-white";
      } else if (n === 4) {
        return "info"
      } else if (n === 5) {
        return "default text-white"
      } else if (n === 6) {
        return "attention"
      } else if (n === 7 || n === 9) {
        return "warning"
      } else if (n === 8) {
        return "darker text-white"
      }
    },
    diasFaltantes(dt_abertura, dt_prev_fechamento, fl_status, value) {
      let start = moment(dt_abertura);
      let end = moment(dt_prev_fechamento);
      let now = moment();
      let sobra = moment.duration(now.diff(start)).asDays();
      let duration = moment.duration(end.diff(start));
      let days = duration.asDays();
      let res = days - sobra;
      res = Math.ceil(res);
      let valor = "";
      let classe = "";
      if (fl_status < 5) {
        if (value === 'valor'){
          if (res > 0 && res < 2) {
            return res + ' dia restante';
          } else if (res > 1) {
            return res + ' dias restantes';
          } else if (res === 0) {
            return 'Encerra hoje';
          } else if (res < 0 && res > -2) {
            return Math.abs(res) + ' dia atrado';
          } else if (res < -1) {
            return Math.abs(res) + ' dias atrado';
          }
        } else {
          if (res > 0 && res < 2) {
            return 'text-success';
          } else if (res > 1) {
            return 'text-success';
          } else if (res === 0) {
            return 'text-warning';
          } else if (res < 0 && res > -2) {
            return 'text-danger';
          } else if (res < -1) {
            return 'text-danger';
          }
        }
      } else {
        return '-'
      }
    },
    verificaFase() {
      var qtdFases = this.propVaga.json_fases.length;
      var faseAtual = 0;
      for(var i = 0; i <= qtdFases; i++) {
        var existe = this.propVaga.json_candidaturas.findIndex(e => e.fase_atual.toString() === i.toString());
        if (existe > -1) {
          faseAtual = i - 1;
        }
      }
      if (this.propVaga.json_fases[faseAtual].value === undefined) {
        return this.propVaga.json_fases[faseAtual].text;
      } else {
        return this.propVaga.json_fases[faseAtual].value;
      }
    },
    goToVaga(value) {
      this.$router.push({ name: 'visualizarvaga', params: { url: value} });
    },
    goToVagaDashboard(value) {
      this.$router.push({ name: 'dashboardvaga', params: { url: value} });
    },
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  },
};
</script>
<style>
.text-card-vaga {
  font-size: 15px;
}
</style>
