<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-6 text-right">
        <label for="areaatuacao">Deseja incluir um teste no ato da candidatura desta vaga?</label>
      </div>
      <div class="col-6">
        <base-switch
          v-model="fl_teste"
        ></base-switch>
      </div>
    </div>
    <div class="row justify-content-center mt-4" v-if="fl_teste">
      <div class="col-4">
        <select class="form-control" v-model="vaga.selectedTeste">
          <option v-if="propTestes.length < 1">Não existem templates salvos</option>
          <option v-else value="">Escolha o teste</option>
          <option v-for="temp in testes" :key="temp.id_teste" :value="temp.id_teste">
            {{ temp.nm_nome}}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
export default {
  name: "TestesAplicados",
  props: {
    propVaga: { type: Object },
    propTestes: { type: Array },
    propEmit: { type: Boolean }
  },
  data() {
    return {
      fl_teste: false,
      vaga: {
        selectedTeste: ""
      },
      testes: []
    }
  },
  methods: {
    firstFormSubmit() {
      this.$emit('resStep6', this.vaga);
    },
    initValues() {
      setTimeout(() => {
        this.testes = this.propTestes.filter(t => t.nm_tipo === 'simples');
        if (this.propVaga.json_vaga.id_teste !== "") {
          this.vaga.selectedTeste = this.propVaga.json_vaga.id_teste;
          this.fl_teste = true;
        }
      }, 500)
    },
    showTestes() {
      if (this.propTestes.length < 1) {
        swal.fire({
          title: 'Ops!',
          html: "Você não possui nenhum teste apto para vagas!<br><br>Apenas testes do tipo 'Simples' podem ser aplicados em vagas.",
          icon: 'info'
        });
        this.fl_teste = false;
      }
    }
  },
  mounted() {
    this.initValues();
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
