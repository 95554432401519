<template>
  <div>
    <!-- <div class="row justify-content-center mt-3">
      <div class="col align-self-center">
        <h1 class="display-2">Página de Carreiras</h1>
      </div>
    </div> -->
    <div class="row justify-content-center mt-3">
      <div :class="showPreview ? 'col-6' : 'col-12'" class="align-self-center">
        <div class="row mt-3">
          <div class="col align-self-center">
            <p class="h4">URL fixa da página de carreiras:</p>
            <p>
              <a :href="`https://vagas.matchjob.com.br/${propPgVagas.hl_url_amigavel}`" target="_blank">
                https://vagas.matchjob.com.br/{{propPgVagas.hl_url_amigavel}}
              </a>
            </p>
          </div>
          <div class="col-3 align-self-center text-center">
            <base-button
              type="info"
              class="my-1"
              size="sm"
              @click="showPreview = !showPreview"
            >{{ showPreview ? 'Fechar' : 'Pré visualizar'}}</base-button>
            <base-button
              type="default"
              tag="a"
              size="sm"
              class="my-1"
              icon
              :href="`https://vagas.matchjob.com.br/${propPgVagas.hl_url_amigavel}`"
              target="_blank"
            >Visitar <i class="fas fa-external-link-alt"></i></base-button>
          </div>
        </div>
        <div class="row my-3">
          <div class="col align-self-center">
            <base-button
              type="primary"
              size="sm"
              :disabled="propPlano.pgCarreiras.customizavel < '2'"
              @click="modal.addBloco = true"
            >Adicionar blocos</base-button>
            <span
              v-if="propPlano.pgCarreiras.customizavel < '2'"
              class="text-danger font-weight-light"
            >Seu plano não permite adicionar novos blocos.</span>
          </div>
        </div>
        <collapse>
          <collapse-item name="navbar">
            <h2 class="m-0" slot="title">Barra de Menu</h2>
            <NavbarConfig
              :propNavbar="propPgVagas.json_config_pg_vagas.navbar"
              :propLogoPadrao="propPgVagas.hl_logo"
            />
          </collapse-item>
          <collapse-item name="midias_sociais">
            <h2 class="m-0" slot="title">Midias Sociais</h2>
            <MidiasSociais
              :propMidiasSociais="propPgVagas.json_config_pg_vagas.midias_sociais"
            />
          </collapse-item>
          <collapse-item name="midias_sociais">
            <h2 class="m-0" slot="title">Cabeçalho</h2>
            <Cabecalho
                :propCabecalho="propPgVagas.json_config_pg_vagas.header"
              />
          </collapse-item>
          <template v-if="itensConteudo.length > 0">
          <template v-for="(item, index) in propPgVagas.json_config_pg_vagas.conteudo">
            <collapse-item-custom :id="item.id" :ref="`tab${item.id}`" :key="item.id" :active="itensConteudo[index].tab" @active="itensConteudo[index].tab = $event">
              <div slot="title">
                <h2 class="m-0" slot="title">{{retornaTipoConteudo(item.tipo)}}</h2>
              </div>
              <div slot="buts">
                <!-- botao subir -->
                <el-tooltip content="Subir" placement="top" effect="light">
                  <base-button
                    type="secondary"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    v-if="butUp(item.id)"
                    @click="moveUp(item.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-arrow-up"></i>
                    </span>
                  </base-button>
                </el-tooltip>
                <!-- botao descer -->
                <el-tooltip content="Descer" placement="top" effect="light">
                  <base-button
                    type="secondary"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    v-if="butDown(item.id)"
                    @click="moveDown(item.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-arrow-down"></i>
                    </span>
                  </base-button>
                </el-tooltip>
                <!-- botao excluir -->
                <el-tooltip :content="item.tipo === 'vagas_simples' ? 'Não é possível remover este bloco' : 'Remover'" placement="top" effect="light">
                  <base-button
                    type="danger"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    :disabled="item.tipo === 'vagas_simples'"
                    @click="removeItem(item.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </base-button>
                </el-tooltip>
                <!-- botao Abrir -->
                <el-tooltip :content="!itensConteudo[index].tab ? 'Abrir' : 'Fechar'" placement="top" effect="light">
                  <base-button
                    :type="!itensConteudo[index].tab ? 'info' : 'default'"
                    size="sm"
                    tag="a"
                    class="btn-icon-only rounded-circle"
                    @click="itensConteudo[index].tab = !itensConteudo[index].tab"
                  >
                    <span class="btn-inner--icon">
                      <i :class="!itensConteudo[index].tab ? 'fas fa-folder-open' : 'fas fa-folder'"></i>
                    </span>
                  </base-button>
                </el-tooltip>
              </div>
              <VideoSimples
                v-if="item.tipo === 'video'"
                :propVideoSimples="item"
              />
              <TextoSimples
                v-if="item.tipo === 'texto_simples'"
                :propTextoSimples="item"
              />
              <VagasPgConfig
                v-if="item.tipo === 'vagas_simples'"
                :propVagasPgConfig="item"
              />
              <TimeConfig
                v-if="item.tipo === 'equipe'"
                :propTimeConfig="item"
              />
              <NoticiasConfig
                v-if="item.tipo === 'noticias'"
                :propNoticiasConfig="item"
              />
              <TestemunhosConfig
                v-if="item.tipo === 'testemunhos'"
                :propTestemunhosConfig="item"
              />
            </collapse-item-custom>
          </template>
          </template>
        </collapse>
      </div>
      <div class="col-6 embed-responsive" v-if="showPreview">
        <iframe class="embed-responsive-item" :src="`http://localhost:3000/${propPgVagas.hl_url_amigavel}`" width="100%" style="border:1px solid black;"></iframe>
      </div>
    </div>
    <!-- Modal de Add Blocos -->
    <modal :show="modal.addBloco" :showClose="true" size="xl" @close="modal.addBloco = false">
      <h6 slot="header" class="modal-title">Adicionar blocos</h6>
      <template v-for="bloco in tipoConteudo">
        <template v-if="!bloco.unico">
        <div
          class="row justify-content-center my-2"
          v-if="bloco.value === 'texto_simples' || propPlano.pgCarreiras.customizavel > '2'"
          :key="bloco.id"
        >
          <!-- Bloco de Vídeo -->
          <template v-if="bloco.value === 'video'">
            <div class="col-2 align-self-center text-center">
              {{bloco.text}}
            </div>
            <div class="col-2 align-self-center text-center">
              <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/video-default.PNG" class="img-fluid img-thumbnail my-2" alt="Vídeo">
              <base-button
                type="primary"
                size="sm"
                @click="addBloco('video')"
              >Selecionar</base-button>
            </div>
            <div class="col mr-auto align-self-center">
              <p class="font-weight-normal">Bloco de vídeo - Tela cheia.<br>
                <small>Apresenta vídeos do YouTube ou Vímeo.</small></p>
            </div>
          </template>
          <!-- Bloco de Texto -->
          <template v-if="bloco.value === 'texto_simples'">
            <div class="col-2 align-self-center text-center">
              {{bloco.text}}
            </div>
            <div class="col-2 align-self-center text-center">
              <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/texto_simples.PNG" class="img-fluid img-thumbnail my-2" alt="Vídeo">
              <base-button
                type="primary"
                size="sm"
                @click="addBloco('texto_simples')"
              >Selecionar</base-button>
            </div>
            <div class="col mr-auto align-self-center">
              <p class="font-weight-normal">Bloco de Texto Simples.<br>
                <small>Pode ser utilizados como apresentação da empresa, visão, valores e etc.</small></p>
            </div>
          </template>
          <!-- Bloco de Equipe Interna -->
          <template v-if="bloco.value === 'equipe'">
            <div class="col-2 align-self-center text-center">
              {{bloco.text}}
            </div>
            <div class="col-2 align-self-center text-center">
              <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/equipe-component-1-bg-image.PNG" class="img-fluid img-thumbnail my-2" alt="Equipe Interna">
              <base-button
                type="primary"
                size="sm"
                @click="addBloco('equipe')"
              >Selecionar</base-button>
            </div>
            <div class="col mr-auto align-self-center">
              <p class="font-weight-normal">Bloco de Equipe Interna.<br>
                <small>Pode ser utilizado para apresentar os principais líderes da empresa.</small>
              </p>
            </div>
          </template>
          <!-- Bloco de Noticias -->
          <template v-if="bloco.value === 'noticias'">
            <div class="col-2 align-self-center text-center">
              {{bloco.text}}
            </div>
            <div class="col-2 align-self-center text-center">
              <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/noticias-component-2.PNG" class="img-fluid img-thumbnail my-2" alt="Noticias">
              <base-button
                type="primary"
                size="sm"
                @click="addBloco('noticias')"
              >Selecionar</base-button>
            </div>
            <div class="col mr-auto align-self-center">
              <p class="font-weight-normal">Bloco de Notícias.<br>
                <small>Pode ser utilizados para apresentar notícias relacionadas à empresa.</small>
              </p>
            </div>
          </template>
          <!-- Bloco de Testemunhos -->
          <template v-if="bloco.value === 'testemunhos'">
            <div class="col-2 align-self-center text-center">
              {{bloco.text}}
            </div>
            <div class="col-2 align-self-center text-center">
              <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/testemunhos-component-1.PNG" class="img-fluid img-thumbnail my-2" alt="Noticias">
              <base-button
                type="primary"
                size="sm"
                @click="addBloco('testemunhos')"
              >Selecionar</base-button>
            </div>
            <div class="col mr-auto align-self-center">
              <p class="font-weight-normal">Bloco de Testemunhos.<br>
                <small>Normalmente utilizado para apresentar testemunhos dos ultimos contratados.</small>
              </p>
            </div>
          </template>
        </div>
        </template>
      </template>
      <!-- Botão Fechar -->
      <div class="row justify-content-end my-2">
        <div class="col-2 align-self-center text-right">
          <base-button
            type="default"
            @click="modal.addBloco = false"
          >Fechar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem.vue';
import CollapseItemCustom from '@/components/Collapse/CollapseItemCustom.vue';
import swal from 'sweetalert2';

import NavbarConfig from './components/NavbarConfig.vue';
import MidiasSociais from './components/MidiasSociais.vue';
import Cabecalho from './components/Cabecalho.vue';
import VideoSimples from './components/VideoSimples.vue';
import TextoSimples from './components/TextoSimples.vue';
import VagasPgConfig from './components/VagasPgConfig.vue';
import TimeConfig from './components/TimeConfig.vue';
import NoticiasConfig from './components/NoticiasConfig.vue';
import TestemunhosConfig from './components/TestemunhosConfig.vue';

export default {
  name: "PaginaCarreiras",
  props: {
    propPgVagas: [Array, Object],
    propPlano: Object
  },
  components: {
    Collapse,
    CollapseItem,
    CollapseItemCustom,
    NavbarConfig,
    MidiasSociais,
    Cabecalho,
    VideoSimples,
    TextoSimples,
    VagasPgConfig,
    TimeConfig,
    NoticiasConfig,
    TestemunhosConfig
  },
  data() {
    return {
      showPreview: false,
      tipoConteudo: [
        { id: "1", value: "header", text: "Cabeçalho", unico: true },
        { id: "2", value: "video", text: "Vídeo", unico: false },
        { id: "3", value: "texto_simples", text: "Texto", unico: false },
        { id: "4", value: "vagas_simples", text: "Lista de Vagas", unico: true },
        { id: "5", value: "equipe", text: "Equipe Interna", unico: false },
        { id: "6", value: "noticias", text: "Notícias", unico: false },
        { id: "7", value: "testemunhos", text: "Testemunhos", unico: false }
      ],
      modal: {
        addBloco: false
      },
      itensConteudo: [],
      conteudoPadrao: {
        videoPadrao: {
          id: "",
          link: "",
          menu: false,
          tipo: "video",
          ativo: true,
          compId: "1",
          titulo: "Video",
          tipoVideo: "youtube"
        },
        textoSimplesPadrao: {
          id: "",
          menu: true,
          tipo: "texto_simples",
          ativo: true,
          compId: "1",
          titulo: "Texto",
          conteudo: ""
        },
        equipeInternaPadrao: {
          id: "",
          menu: false,
          time: [],
          tipo: "equipe",
          ativo: true,
          compId: "1",
          titulo: "Equipe Interna",
          subtitulo: "",
          background: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/austin.jpg"
        },
        noticiasPadrao: {
          id: "",
          menu: false,
          tipo: "noticias",
          ativo: true,
          compId: "1",
          titulo: "Notícias",
          noticias: []
        },
        testemunhosPadrao: {
          id: "",
          menu: false,
          tipo: "testemunhos",
          ativo: true,
          compId: "1",
          titulo: "O que nossos colaboradores dizem",
          testemunhos: []
        }
      },
    }
  },
  methods: {
    initValues() {
      //this.tempFases = this.propFases;
      setTimeout(() => {
        let num = 1
        Object.keys(this.propPgVagas.json_config_pg_vagas.conteudo).forEach(element => {
          var temp = {
            id: num++, tipo: this.propPgVagas.json_config_pg_vagas.conteudo[element].tipo, tab: false
          }
          this.itensConteudo.push(temp);
        });
      }, 500);
    },
    retornaTipoConteudo(value) {
      var res = this.tipoConteudo.find(i => i.value === value);
      return res.text;
    },
    butUp(id){
      var pos = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      var pos = 1
      pos += this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === id);
      //id = parseInt(id, 10)
      if (this.propPgVagas.json_config_pg_vagas.conteudo.length === pos){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      idNext = idNext.toString();
      var pos0 = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === idNow);
      var pos1 = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === idNext);
      var arr0 = this.propPgVagas.json_config_pg_vagas.conteudo.find( item => item.id === idNow);
      var arr1 = this.propPgVagas.json_config_pg_vagas.conteudo.find( item => item.id === idNext)
      arr0.id = idNext;
      arr1.id = idNow;
      this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos0, 1, arr1);
      this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      idNext = idNext.toString();
      var pos0 = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === idNow);
      var pos1 = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === idNext);
      var arr0 = this.propPgVagas.json_config_pg_vagas.conteudo.find( item => item.id === idNow);
      var arr1 = this.propPgVagas.json_config_pg_vagas.conteudo.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos0, 1, arr1);
      this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos1, 1, arr0);
    },
    removeItem(id) {
      var pos = this.propPgVagas.json_config_pg_vagas.conteudo.findIndex( item => item.id === id);
      var tam = this.propPgVagas.json_config_pg_vagas.conteudo.length;
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja excluir esse bloco??',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          if (tam === (pos + 1)){//Valida se está na ÚLTIMA posição do array
            this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos, 1);
            this.itensConteudo.splice(pos, 1);
          } else {
            for (var i = pos+1; i < tam; i++){
              this.propPgVagas.json_config_pg_vagas.conteudo[i].id = this.propPgVagas.json_config_pg_vagas.conteudo[i].id-1;
              this.propPgVagas.json_config_pg_vagas.conteudo[i].id = this.propPgVagas.json_config_pg_vagas.conteudo[i].id.toString();
              this.itensConteudo[i].id = this.itensConteudo[i].id-1;
            }
            this.propPgVagas.json_config_pg_vagas.conteudo.splice(pos, 1);
            this.itensConteudo.splice(pos, 1);
          }
        }
      });
    },
    addBloco(value) {//propPgVagas.json_config_pg_vagas.
      var novobloco = {}
      if (value === 'texto_simples') {
        _.merge(novobloco, this.conteudoPadrao.textoSimplesPadrao);
      } else if (value === 'video') {
        _.merge(novobloco, this.conteudoPadrao.videoPadrao);
      } else if (value === 'equipe') {
        _.merge(novobloco, this.conteudoPadrao.equipeInternaPadrao);
      } else if (value === 'noticias') {
        _.merge(novobloco, this.conteudoPadrao.noticiasPadrao);
      } else if (value === 'testemunhos') {
        _.merge(novobloco, this.conteudoPadrao.testemunhosPadrao);
      }
      const lastItem = this.propPgVagas.json_config_pg_vagas.conteudo.pop();
      let id = lastItem.id;
      this.propPgVagas.json_config_pg_vagas.conteudo.push(lastItem);
      novobloco.id = ++id;
      novobloco.id = novobloco.id.toString();
      var temp = {
        id: novobloco.id, tipo: novobloco.tipo, tab: false
      }
      this.itensConteudo.push(temp);
      this.propPgVagas.json_config_pg_vagas.conteudo.push(novobloco);
      this.modal.addBloco = false;
    }
  },
  mounted() {
    this.initValues();
  },
  watch: {
    /* propPgVagas: {
      handler(value){
        this.butAtualiza = true;
        console.log('watch captou atualizacao ->>>>', value)
      },
      deep: true
    } */
  }
}
</script>

<style>

</style>
