<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-1 text-center">
        <i class="icones-caracteristicas-inativo fas fa-server m-2"></i>
      </div>
      <div class="col-11">
        <div class="row">
          <div class="col-1 align-self-center">
            <img class="img-fluid w-75" src="https://storagematchjobdefault.blob.core.windows.net/matchjob/icone-50x50.png" alt="MatchJob Empregos" />
          </div>
          <div class="col-11 align-self-center">
            <small><strong>MatchJob Empregos</strong><br><span class="text-muted">{{propItem.date | formatDateTime}}</span></small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            {{propItem.text}}
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "CompSistema",
  props: {
    propItem: { type: Object }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
</style>
