<template>
  <modal :show="propShow" :showClose="false" size="lg">
    <h6 slot="header" class="modal-title">Agendar Entrevista<span v-html="retornaTipoEntrevista(propTipoEntrevista)" /></h6>
    <div class="row">
      <!-- Select do Entrevistador -->
      <div class="col">
        <template v-if="propTipoEntrevista === 'entrevistaRH'">
          <label for="entrevistador">Selecionar Entrevistador <span class="text-danger">*</span></label>
          <el-select
            ref="entrevistador"
            name="Selecionar Entrevistador"
            class="inuptAutocomplete"
            label="Selecionar Entrevistador"
            id="entrevistador"
            v-model="enviarEntrevista.entrevistador.id_empresa_user"
            @change="onSelectEntrevistador"
          >
            <el-option
              v-for="(user, index) in usuariosRh"
              :key="index"
              :value="user.id_empresa_user"
              :label="user.nm_nome"
            >
              <span style="float: left">{{ user.nm_nome }} </span> <span style="color: #8492a6; font-size: 11px"> - {{ user.nm_cargo }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ user.hl_email }}</span>
            </el-option>
          </el-select>
        </template>
        <template v-else-if="propTipoEntrevista === 'entrevistaOutro'">
          <label for="entrevistador">Selecionar Entrevistador <span class="text-danger">*</span></label>
          <el-select
            ref="entrevistador"
            name="Selecionar Entrevistador"
            class="inuptAutocomplete"
            label="Selecionar Entrevistador"
            id="entrevistador"
            v-model="enviarEntrevista.entrevistador.id_empresa_user"
            @change="onSelectEntrevistador"
          >
            <el-option
              v-for="(user, index) in usuarios"
              :key="index"
              :value="user.id_empresa_user"
              :label="user.nm_nome"
            >
              <span style="float: left">{{ user.nm_nome }} </span> <span style="color: #8492a6; font-size: 11px"> - {{ user.nm_cargo }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ user.hl_email }}</span>
            </el-option>
            <el-option value="outro" label="Outro"></el-option>
          </el-select>
        </template>
        <template v-else>
          <label for="entrevistador">Entrevistador:</label><br>
          Nome: <strong>{{propVaga.nm_solicitante}}</strong><br>
          Departamento: <strong>{{propVaga.nm_depto}}</strong>
        </template>
      </div>
      <div class="col">
        <label for="entrevistaremota">A entrevista será remota?</label><br>
        <base-switch
          ref="entrevistaremota"
          v-model="enviarEntrevista.remota"
        ></base-switch>
      </div>
    </div>
    <div class="row mt-1" v-if="enviarEntrevista.entrevistador.id_empresa_user === 'outro'">
      <div class="col">
        <label for="nomeEntrevistador">Nome <span class="text-danger">*</span></label>
        <input
          id="nomeEntrevistador"
          ref="nomeEntrevistador"
          type="text"
          class="form-control"
          name="Nome"
          placeholder="Nome"
          v-model="enviarEntrevista.entrevistador.nome"
        />
      </div>
      <div class="col">
        <label for="emailEntrevistador">E-mail <span class="text-danger">*</span></label>
        <input
          id="emailEntrevistador"
          ref="emailEntrevistador"
          type="email"
          class="form-control"
          name="E-mail"
          placeholder="E-mail"
          v-model="enviarEntrevista.entrevistador.email"
        />
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label for="datahorario">Data e Horário <span class="text-danger">*</span></label>
        <input
          id="datahorario"
          ref="datahorario"
          type="datetime-local"
          class="form-control"
          name="Data e Horário"
          placeholder="Data e Horário"
          v-model="enviarEntrevista.datahora"
        />
      </div>
      <div class="col">
        <label for="tempoEntrevistaRh">Tempo de entrevista <span class="text-danger">*</span></label>
        <el-select
          ref="tempoEntrevistaRh"
          name="Tempo de entrevista"
          class="inuptAutocomplete"
          label="Tempo de entrevista"
          id="tempoEntrevistaRh"
          v-model="enviarEntrevista.tempo"
        >
          <el-option value="15" label="15 minutos"></el-option>
          <el-option value="30" label="30 minutos"></el-option>
          <el-option value="45" label="45 minutos"></el-option>
          <el-option value="60" label="1 hora"></el-option>
          <el-option value="120" label="2 horas"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row mt-1" v-if="enviarEntrevista.remota">
      <div class="col">
        <label for="linkEntrevista">Link da entrevista <span class="text-danger">*</span></label>
        <input
          id="linkEntrevista"
          ref="linkEntrevista"
          type="text"
          class="form-control"
          name="Link da entrevista"
          placeholder="http://"
          v-model="enviarEntrevista.link"
        />
      </div>
    </div>
    <div class="row mt-1" v-else>
      <div class="col">
        <label for="localEntrevista">Local da Entrevista <span class="text-danger">*</span></label>
        <el-select
          ref="localEntrevista"
          name="Local da Entrevista"
          class="inuptAutocomplete"
          label="Local da Entrevista"
          id="localEntrevista"
          v-model="enviarEntrevista.id_endereco"
        >
          <el-option
            v-for="(local, index) in enderecos"
            :key="index"
            :value="local.id_empresa_ender"
            :label="local.nm_nome_endereco"
          >
            <span style="float: left">{{ local.nm_nome_endereco }}</span>
            <span style="float: right; color: #8492a6; font-size: 11px">{{ local.nm_endereco }}, {{ local.nu_numero }} - {{ local.nm_bairro }} - {{ local.nm_cidade }}/{{ local.nm_estado }}  </span>
          </el-option>
          <el-option value="outro" label="Outro"></el-option>
        </el-select>
      </div>
    </div>
    <!-- Flag de envio de e-mail -->
    <div class="row mt-1">
      <div class="col">
        <label for="enviarEmailEntrevista">Enviar convite de entrevista por e-mail?</label><br>
        <base-switch
          ref="enviarEmailEntrevista"
          v-model="enviarEntrevista.fl_enviarEmail"
        ></base-switch>
      </div>
    </div>
    <!-- Mensagem Adicional -->
    <div class="row mt-1" v-if="enviarEntrevista.fl_enviarEmail">
      <div class="col">
        <label for="mensagemAdicional">Mensagem Adicional</label>
        <input
          id="mensagemAdicional"
          ref="mensagemAdicional"
          type="text"
          class="form-control"
          name="Mensagem Adicional"
          v-model="enviarEntrevista.mensagem"
        />
      </div>
    </div>
    <!-- Templata da Mensagem -->
    <div class="row mt-1">
      <div class="col">
        <label for="templateMensagem">Selecionar Mensagem <span class="text-danger">*</span></label>
        <el-select
          ref="templateMensagem"
          name="Template da Mensagem"
          class="inuptAutocomplete"
          label="Template da Mensagem"
          id="templateMensagem"
          v-model="mensagemId"
          @change="handleAjustaMensagem"
        >
          <el-option
            v-for="(msg, index) in mensagens"
            :key="index"
            :value="msg.id_empresa_msg_templates"
            :label="msg.nm_titulo"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- Mensagem Enviada ao candidato -->
    <div class="row mt-1" v-if="enviarEntrevista.fl_enviarEmail">
      <div class="col">
        <h4>Mensagem enviada ao candidato <i class="fas fa-eye" v-if="!fl_mostraMensagem" @click="fl_mostraMensagem = !fl_mostraMensagem"></i><i class="fas fa-eye-slash" v-if="fl_mostraMensagem" @click="fl_mostraMensagem = !fl_mostraMensagem"></i></h4>
        <template v-if="fl_mostraMensagem">
          <span v-html="enviarEntrevista.mensagemAenviar" />
        </template>
      </div>
    </div>
    <!-- {{enviarEntrevista}} -->
    <div slot="footer">
      <base-button type="secondary" class="ml-3" @click="closeModal">Cancelar</base-button>
      <base-button type="primary" class="mr-3 ml-auto" @click="enviarConvite">Enviar</base-button>
    </div>
  </modal>
</template>

<script>
import { Select, Option } from 'element-ui';
import moment from "moment";

export default {
  name: "EnviarConviteEntrevista",
  props: {
    propTipoEntrevista: { type: String },
    propCandidato: { type: Object }, //Conteudo da timeline do candidato
    propVaga: { type: Object },// String com o flag do tipo da entrevista
    //propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    concluiRegistro: { type: Function },
    closeModal: { type: Function },
    propShow: { type: Boolean }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    usuariosRh() {
      return this.$store.state.usuarios.usuarios.filter(r => r.fl_tipo_acesso > 0 && r.fl_tipo_acesso < 5);
      /* return this.$store.state.usuarios.usuarios.filter(r => {
        if(r.fl_tipo_acesso === 2) { return true }
        else if(r.fl_tipo_acesso === 3) { return true }
        else if(r.fl_tipo_acesso === 4) { return true }
      }); */
    },
    enderecos() {
      return this.$store.state.enderecos.enderecos;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    mensagens() {
      var tempMsg = this.$store.state.empresa.mensagens.filter(stMsg => stMsg.fl_tipo_mensagem === 'entrevista');
      return tempMsg
    },
  },
  data() {
    return {
      fl_mostraMensagem: false,
      mensagemId: "",
      enviarEntrevista: {
        fl_enviarEmail: true,
        tipo: '',
        entrevistador: {
          nome: "",
          email: "",
          id_empresa_user: ""
        },
        datahora: '',
        tempo: '',
        remota: true,
        link: '',
        id_endereco: '',
        mensagem: "",
        mensagemAenviar: ""
      },
    }
  },
  methods: {
    retornaTipoEntrevista(tipo) {
      if(tipo === 'entrevistaRH') {
        return ' com o <strong>RH</strong>';
      } else if(tipo === 'entrevistaSolicitante') {
        return ' com o <strong>Solicitante da Vaga</strong>'
      } else if(tipo === 'entrevistaOutro') {
        return ' <strong>Normal</strong>'
      }
    },
    retornaEntrevistador(id) {
      if(id){
        var user = this.usuarios.find(i => i.id_empresa_user === id);
        return user.nm_nome;
      }
    },
    retornaEndereco(id) {
      if(id) {
        var endereco = this.enderecos.find(e => e.id_empresa_ender === id);
        var resEndereco = endereco.nm_endereco + ', ' + endereco.nu_numero + ' - ' + endereco.nm_bairro + ' - ' + endereco.nm_cidade + '/' + endereco.nm_estado + ' ' + endereco.nm_complemento;
        return resEndereco;
      }
    },
    trocaPalavras(texto) {
      var listaVariaveis = [
        { nome: "CandidatoNome", valor: this.propCandidato.nm_nome },
        { nome: "TituloVaga",  valor: this.propVaga.nm_cargo },
        { nome: "NomeEmpresa", valor: this.empresa.nm_fantasia },
        { nome: "EmpresaSite", valor: this.empresa.hl_site },
        { nome: "TipoEntrevista", valor: this.enviarEntrevista.remota ? 'Remota' : 'Presencial' },
        { nome: "DataEntrevista", valor: this.formatDateTime(this.enviarEntrevista.datahora) },
        { nome: "EntrevistadorNome", valor: this.enviarEntrevista.entrevistador.nome },
        { nome: "MensagemAdicional", valor: this.enviarEntrevista.mensagem },
        { nome: "LocalEntrevista", valor: this.retornaTextoLocalEntrevista(this.enviarEntrevista.remota) },
      ];
      // Expressão regular para identificar variáveis
      const regex = /%(.+?)%/g;
      // Loop para iterar sobre as variáveis no texto
      while (true) {
        // Encontrar a próxima variável
        const match = regex.exec(texto);
        // Se não encontrar mais variáveis, sair do loop
        if (!match) {
          break;
        }
        // Obter o nome da variável
        const nomeVariavel = match[1];
        // Encontrar a variável na lista
        const variavelEncontrada = listaVariaveis.find(
          (variavel) => variavel.nome === nomeVariavel
        );
        // Se a variável não for encontrada, pular para a próxima
        if (!variavelEncontrada) {
          continue;
        }
        // Substituir a variável pelo valor
        texto = texto.replace(match[0], variavelEncontrada.valor);
      }
      // Retornar o texto com as variáveis substituídas
      return texto;
    },
    handleAjustaMensagem() {
      var mensagem = this.mensagens.find(m => m.id_empresa_msg_templates === this.mensagemId);
      this.enviarEntrevista.mensagemAenviar = this.trocaPalavras(mensagem.tx_mensagem);
      //this.fl_mostraMensagem = true;
    },
    formatDateTime(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    },
    onSelectEntrevistador(id) {
      var nome = this.retornaEntrevistador(id);
      this.enviarEntrevista.entrevistador.nome = nome
    },
    retornaTextoLocalEntrevista(remota) {
      if(remota) {
        return "Na data e horário agendado, você deverá acessar o seguinte link para entrar na entrevista:<br><a href=\"" + this.enviarEntrevista.link + "\" target=\"_blank\">" + this.enviarEntrevista.link + "</a>";
      } else {
        return "A entrevista será realizada no seguinte endereço:<br><strong>" + this.retornaEndereco(this.enviarEntrevista.id_endereco) + "</strong><br><small>Solicitamos que você chegue com 10 minutos de antecedência.</small>";
      }
    },
    enviarConvite() {
      this.$emit('resConviteEntrevista', this.enviarEntrevista);
      this.concluiRegistro()
    }
  }
}
</script>

<style>

</style>
