<template>
  <div v-loading="loading">
    <card v-if="Object.keys(propPlano).length > 0">
      <div class="row justify-content-center mt-3">
        <div class="vol-8">
          <h1 class="display-3">Plano Atual - <small class="text-muted">{{retornaNomePlano(propPlano.nm_plano)}}</small></h1>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-2 text-center">
          <h4>Data de inicio</h4>
          <p><strong>{{propPlano.dt_inicio | formatDate}}</strong></p>
        </div>
        <div class="col-2 text-center">
          <h4>Data de término</h4>
          <p><strong>{{propPlano.dt_fim | formatDate}}</strong></p>
        </div>
      </div>
      <collapse :active-index="2">
        <collapse-item name="1">
          <h1 slot="title"><small class="text-muted">Funcionalidades</small></h1>
          <div class="row justify-content-center mt-3">
            <div class="col-5">
              <table  class="tableplano">
                <tr><td colspan="2" class="text-center"><span class="display-4">Vagas</span></td></tr>
                <tr
                  v-for="(valor, chave) in propPlano.vagas"
                  :key="chave"
                >
                  <td style="width:350px">{{retornaTituloDetalhe(chave, 'vagas')}}:</td><td class="text-center"><span v-html="formataValor(valor, chave)"></span></td>
                </tr>
                <tr><td colspan="2" class="text-center"><span class="display-4">Usuários</span></td></tr>
                <tr
                  v-for="(valor, chave) in propPlano.usuarios"
                  :key="chave"
                >
                  <td>{{retornaTituloDetalhe(chave, 'usuarios')}}:</td><td class="text-center"><span v-html="formataValor(valor, chave)"></span></td>
                </tr>
                <tr><td colspan="2" class="text-center"><span class="display-4">Página de Carreira</span></td></tr>
                <tr
                  v-for="(valor, chave) in propPlano.pgCarreiras"
                  :key="chave"
                >
                  <td>{{retornaTituloDetalhe(chave, 'pgCarreiras')}}:</td><td class="text-center"><span v-html="formataValor(valor, chave)"></span></td>
                </tr>
              </table>
            </div>
            <div class="col-5">
              <table  class="tableplano">
                <tr><td colspan="2" class="text-center"><span class="display-4">Processo de Seleção</span></td></tr>
                <tr
                  v-for="(valor, chave) in propPlano.ps"
                  :key="chave"
                >
                  <td style="width:350px">{{retornaTituloDetalhe(chave, 'ps')}}:</td><td class="text-center"><span v-html="formataValor(valor, chave)"></span></td>
                </tr>
                <tr><td colspan="2" class="text-center"><span class="display-4">AI de Match</span></td></tr>
                <tr
                  v-for="(valor, chave) in propPlano.matchAi"
                  :key="chave"
                >
                  <td>{{retornaTituloDetalhe(chave, 'matchAi')}}:</td><td class="text-center"><span v-html="formataValor(valor, chave)"></span></td>
                </tr>
              </table>
            </div>
          </div>
        </collapse-item>
        <collapse-item name="2">
          <h1 slot="title"><small class="text-muted">Financeiro</small></h1>
          <validation-observer v-slot="{handleSubmit}" ref="form">
            <form class="needs-validation" enctype="multipart/form-data"
              @submit.prevent="handleSubmit(editFormSubmit)">
              <div class="row justify-content-center mt-3">
                <div class="col-2">
                  Nada registrado.
                </div>
              </div>
              <!-- Botão atualizar -->
              <div class="row justify-content-center mt-5">
                <div class="col text-center">
                  <base-button type="primary" class="mr-3 ml-auto">Atualizar</base-button>
                </div>
              </div>
            </form>
          </validation-observer>
        </collapse-item>
        <collapse-item name="3">
          <h1 slot="title"><small class="text-muted">Contratos</small></h1>
          <div class="row mt-3" v-for="(contrato, index) in contratoState" :key="index">
            <div class="col-4">
              Numero do Contrato: <strong>{{contrato.id_contrato}}</strong> - <strong v-html="retornaStatusContrato(contrato.fl_status)"></strong><br>
              Inicio: {{contrato.dt_inicio | formatDate}} - Término: {{contrato.dt_fim | formatDate}}<br>
              Link do Contrato: <template v-if="contrato.hl_link_contrato !== 'A enviar'"><a href="contrato.hl_link_contrato" target="_blank">Ver Contrato</a></template>
              <template v-else>
                {{contrato.hl_link_contrato}}
              </template>
            </div>
            <div class="col-3">
              Tipo da Contratação: <strong>{{retornaTipoContrato(contrato.fl_tipo_contrato)}}</strong><br>
              Modo de Pagamento: <strong>{{retornaTipoPagamento(contrato.fl_tipo_contrato, contrato.fl_tipo_pagamento)}}</strong><br>
            </div>
          </div>
          <div class="row">
            <div class="col">
            </div>
          </div>
          <!-- <pre>contrato ->> {{contratoState}}</pre> -->
        </collapse-item>
      </collapse>
    </card>
    <!-- <pre>propPlano ->> {{propPlano}}</pre> -->
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option
} from 'element-ui';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

Vue.use(Loading.directive);

export default {
  name: "PlanoFinanceiro",
  mixins: [defaultMatchjob],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Collapse,
    CollapseItem
  },
  props: {
    propPlano: { type: Object }
  },
  data() {
    return {
      loading: false,
      //plano: {},
      planosEnv: [],
      jsonDetalhesTitulo: {
        vagas: [
          { value: 'qtd', text: 'Quantidades de Vagas/mês' },
          { value: 'vagaHO', text: 'Vagas de HomeOffice' },
          { value: 'anuncio', text: 'Anúnciar/Publicar a vaga' },
          { value: 'muiltinacional', text: 'Vagas Multinacionais' },
          { value: 'multinacional', text: 'Vagas Multinacionais' },
          { value: 'exportApi', text: 'Exportar vagas via API' },
          { value: 'importApi', text: 'Importar vagas via API' },
          { value: 'vagasGeracaoIa', text: 'Gerar Descrição e Requisitos da Vaga por IA' }
        ],
        pgCarreiras: [
          { value: 'dominio', text: 'Domínio' },
          { value: 'respMobile', text: 'Responsividade para Mobile' },
          { value: 'multiIdioma', text: 'Multi-idiomas' },
          { value: 'customizavel', text: 'Customizável' }
        ],
        usuarios: [
          { value: 'qtdRh', text: 'Usuários de RH' },
          { value: 'qtdArea', text: 'Usuários de áreas' },
          { value: 'authMs', text: 'Autenticação MS Office365' }
        ],
        ps: [
          { value: 'criacaoPs', text: 'Criação de Processo de R&S' },
          { value: 'fluxoVagas', text: 'Fluxo de Aproção de Vagas' },
          { value: 'fluxoCvs', text: 'Fluxo de Aprovação de CVs' },
          { value: 'fluxoSolitacaoVaga', text: 'Fluxo de Solicitação de abertura de Vagas' },
          { value: 'fluxoSolicitacaoVaga', text: 'Fluxo de Solicitação de abertura de Vagas' },
          { value: 'avaliacaoAi', text: 'AI na avaliação de CVs' },
          { value: 'enviarAvisosPublicacao', text: 'Enviar aviso de publicação de vagas' },
          { value: 'salvarCvs', text: 'Salvar CVs' },
          { value: 'testePerfil', text: 'Teste de Perfil Comportamental' },
          { value: 'enviarTeste', text: 'Envio de testes' },
          { value: 'gravacaoVideoEntrevista', text: 'Gravação de vídeo entrevista' },
          { value: 'reValidacaoMatch', text: 'Revalidação de Match Candidato/Vaga' }
        ],
        matchAi: [
          { value: 'pretensao', text: 'Pretensão salarial' },
          { value: 'competencias', text: 'Competências' },
          { value: 'idiomas', text: 'Idiomas' },
          { value: 'formacao', text: 'Formação' },
          { value: 'certificacoes', text: 'Certificações' },
          { value: 'localizacao', text: 'Localização' },
          { value: 'tempoExperiencia', text: 'Experiência no cargo da vaga - Análise de AI' },
        ]
      }
    }
  },
  computed: {
    planoState() {
      return this.$store.state.empresa.plano;
    },
    empresaState() {
      return this.$store.state.empresa.empresa;
    },
    contratoState() {
      return this.$store.state.empresa.contrato;
    }
  },
  methods: {
    editFormSubmit() {
      //....
    },
    initValores() {
      this.planosEnv = [
        { value: process.env.VUE_APP_MATCHJOB_FREE, text: "Free" },
        { value: process.env.VUE_APP_MATCHJOB_BASICO, text: "Básico" },
        { value: process.env.VUE_APP_MATCHJOB_PROFISSIONAL, text: "Profissional" },
        { value: process.env.VUE_APP_MATCHJOB_ENTERPRISE, text: "Enterprise" }
      ]
      //this.plano = this.propPlano;
    },
    retornaNomePlano(value) {
      if (value === undefined) {
        return '';
      } else {
        var plano = this.planosEnv.find(i => i.value === value);
        return plano.text;
      }
    },
    retornaTituloDetalhe(value, chave) {
      /* console.log('value -> ', value);
      console.log('chave -> ', chave); */
      if (chave === 'vagas') {
        let res = this.jsonDetalhesTitulo.vagas.find(i => i.value === value);
        return res.text;
      } else if (chave === 'pgCarreiras') {
        let res = this.jsonDetalhesTitulo.pgCarreiras.find(i => i.value === value);
        return res.text;
      } else if (chave === 'usuarios') {
        let res = this.jsonDetalhesTitulo.usuarios.find(i => i.value === value);
        return res.text;
      } else if (chave === 'ps') {
        let res = this.jsonDetalhesTitulo.ps.find(i => i.value === value);
        return res.text;
      } else if (chave === 'matchAi') {
        let res = this.jsonDetalhesTitulo.matchAi.find(i => i.value === value);
        return res.text;
      } else {
        return null;
      }
    },
    formataValor(value, chave) {
      if (value === "99999") {
        return "<strong>Ilimitado</strong>"
      } else if (value === true) {
        return "<i class='icone-ativo fas fa-check-circle'></i>";
      } else if (value === false) {
        return "<i class='icone-desabilitado fas fa-ban'></i>"
      } else if (chave === 'testePerfil') {
        if (value === '0') {
          return "<i class='icone-desabilitado fas fa-ban'></i>"
        } else if (value === '1') {
          return "<strong>Simples</strong>"
        } else if (value === '2') {
          return "<strong>Todos</strong>"
        } else {
          return "<i class='icone-desabilitado fas fa-ban'></i>"
        }
      } else if (chave === 'localizacao') {
        if (value === '1') {
          return "<strong>Cidade e Estado</strong>"
        } else if (value === '2') {
          return "<strong>B/C/E</strong>"
        } else if (value === '3') {
          return "<strong>Google Maps</strong>"
        } else {
          return "<i class='icone-desabilitado fas fa-ban'></i>"
        }
      } else if (chave === 'customizavel') {
        if (value === '1') {
          return "<strong>Mínimo</strong>"
        } else if (value === '2') {
          return "<strong>Parcial</strong>"
        } else if (value === '3') {
          return "<strong>Total</strong>"
        } else {
          return "<i class='icone-desabilitado fas fa-ban'></i>"
        }
      } else {
        return `<strong>${value}</strong>`;
      }
    },
    //Funções de Contratos
    retornaStatusContrato(status) {
      if(status === '1') {
        return "<span class=\"text-success\">Ativo</span>"
      }
    },
    retornaTipoContrato(value) {
      if(value === '1') {
        return "Free"
      } else if(value === '2') {
        return "Mensal"
      } else if(value === '3') {
        return "Anual"
      } else if(value === '4') {
        return "Anual - 3 anos"
      }
    },
    retornaTipoPagamento(contrato, pagamento) {
      if(contrato === '1') {
        return "Não se aplica"
      } else {
        if(pagamento === '1') {
          return "Mensal"
        } else {
          return "Anual"
        }
      }
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(value).format("DD/MM/YYYY");
      }
    },
  },
  created() {
    this.initValores();
  },
  watch: {
    /* propPlano: {
      handler(value) {
        if (value) {
          this.plano = this.propPlano;
        }
      }
    } */
  }
}
</script>

<style>
  .icone-ativo {
    font-size: 1.2rem;
    color: green;
  }
  .icone-desabilitado {
    font-size: 1.2rem;
    color: red;
  }
  .tableplano td {
    padding: 0.5rem;
  }
  .tableplano tr {
    border-bottom-style: solid;
    border-bottom-color: rgba(131, 131, 131, 0.658);
    border-bottom-width: 1px;
  }
</style>
