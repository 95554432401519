<template>
  <div v-loading="loading">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
            @submit.prevent="handleSubmit(firstFormSubmit)">
        <!-- Flag de PCD -->
        <div class="row justify-content-center mb-3">
          <div class="col-5">
            <label for="pcd">Esta vaga pode ser preenchida por Pessoa com Deficiência?</label>
          </div>
          <div class="col-3">
            <base-switch
              id="pcd"
              v-model="vaga.fl_deficientes"
            ></base-switch>
          </div>
        </div>
        <!-- Salário / Tipo de contrato / Perído -->
        <div class="row justify-content-center mb-3">
          <div class="col-2">

              <label for="salario">Salário <span class="text-danger">*</span></label>
              <input
                id="salario"
                ref="salario"
                type="number"
                class="form-control"
                name="Salário"
                placeholder="Salário"
                v-model.number="vaga.nu_salario"

                />
              <small class="invalid-feedback" style="display: block;" v-if="validate.salario">O campo "Salário" é obrigatório.</small>

          </div>
          <div class="col-3">
            <div class="form-group">
            <label for="tipocontrato">Tipo de contrato <span class="text-danger">*</span></label>
              <el-select
                id="tipocontrato"
                ref="tipocontrato"
                required
                name="Tipo de contrato"
                class="inuptAutocomplete"
                v-model="vaga.fl_contratacao"
              >
                <el-option
                  v-for="tipo in tiposcontrato"
                  :key="tipo.value"
                  :value="tipo.value"
                  :label="tipo.text"
                ></el-option>
              </el-select>
              <small class="invalid-feedback" style="display: block;" v-if="validate.tipocontrato">O campo "Tipo de contrato" é obrigatório.</small>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label for="periodo">Período <span class="text-danger">*</span></label>
              <el-select
                required
                id="periodo"
                ref="periodo"
                name="Período"
                class="inuptAutocomplete"
                label="Período"
                v-model="vaga.nm_turno_trabalho"
              >
                <el-option
                  v-for="periodo in jornadas"
                  :key="periodo.value"
                  :value="periodo.value"
                  :label="periodo.text"
                ></el-option>
              </el-select>
              <small class="invalid-feedback" style="display: block;" v-if="validate.periodo">O campo "Período" é obrigatório.</small>
            </div>
          </div>
        </div>
        <!-- Ocultar salário -->
        <div class="row justify-content-center mb-3">
          <div class="col-2">
            <label for="ocultarsalario">Deseja ocultar o salário?</label>
          </div>
          <div class="col-6">
            <base-switch
              id="ocultarsalario"
              v-model="vaga.fl_oculta_salario"
            ></base-switch>
          </div>
        </div>
        <div class="row justify-content-center" v-if="plano.nm_plano === '09300df44b9d20cc219b25abddc3346e'">
          <div class="col-8">
            Precisa de Ajuda para gerar a Descrição e Requisitos?<br>
            <base-button
              v-if="plano.nm_plano === '09300df44b9d20cc219b25abddc3346e'"
              class="ml-3 mb-4"
              type="success"
              @click="geraDescricaoRequisitos"
            ><i class="fas fa-hat-wizard"></i>Gerar Automático</base-button>
          </div>
        </div>
        <!-- Descrição da vaga -->
        <div class="row justify-content-center mb-3">
          <div class="col-8">
            <label for="descricao">Descrição da Vaga <span class="text-danger">*</span></label>
            <trumbowyg v-model="vaga.tx_descricao" ref="editorDescricao" :config="config" class="form-control" name="editorDescricao"></trumbowyg>
              <small class="invalid-feedback" style="display: block;" v-if="validate.descricao">O campo "Descrição da Vaga" é obrigatório.</small>
          </div>
        </div>
        <!-- Requisitos da vaga -->
        <div class="row justify-content-center mb-3">
          <div class="col-8">
            <label for="requisitos">Requisitos da Vaga <span class="text-danger">*</span></label>
            <trumbowyg v-model="vaga.tx_requisitos" ref="editorRequisitos" :config="config" class="form-control" name="editorRequisitos"></trumbowyg>
              <small class="invalid-feedback" style="display: block;" v-if="validate.requisitos">O campo "Requisitos da Vaga" é obrigatório.</small>
          </div>
        </div>
        <div class="row justify-content-center mt-5 mb-2">
          <div class="col-2">
            <base-button type="secondary" @click="voltarStep2">Voltar</base-button>
          </div>
          <div class="col-1">
            <base-button type="primary" native-type="submit">Proximo</base-button>
          </div>
        </div>
      </form>
    </validation-observer>
    <!-- <pre>{{vaga}}</pre> -->
  </div>
</template>
<script>
import Vue from 'vue';
import { Select, Option, Autocomplete } from 'element-ui';
import { Loading } from 'element-ui';

//Editor
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
// Plugins are optional
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/langs/pt_br.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/mention/trumbowyg.mention.js'

Vue.use(Loading.directive);

export default {
  name: "Step2",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    Trumbowyg
  },
  props: {
    voltarStep2: { type: Function },
    concluiStep2: { type: Function },
    propDeficientes: { type: Boolean },
    propContratacao: { type: String },
    propSalario: { type: Number },
    propOculta_salario: { type: Boolean },
    propTurno_trabalho: { type: String },
    propDescricao: { type: String },
    propRequisitos: { type: String },
    propCargo: { type: String }
  },
  data() {
    return {
      validated: false,
      vaga: {
        fl_deficientes: true,
        fl_contratacao: '',
        nu_salario: '0,00',
        fl_oculta_salario: false,
        nm_turno_trabalho: '',
        tx_descricao: '',
        tx_requisitos: ''
      },
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
      tiposcontrato: [
        { value: "1", text: "Autônomo" },
        { value: "2", text: "Cooperado" },
        { value: "3", text: "Efetivo (CLT)" },
        { value: "4", text: "Estágio" },
        { value: "5", text: "Outros" },
        { value: "6", text: "Prestador de Serviços (PJ)" },
        { value: "7", text: "Temporário" },
        { value: "8", text: "Trainee" }
      ],
      loading: false,
      validate: {
        salario: false,
        tipocontrato: false,
        periodo: false,
        descricao: false,
        requisitos: false
      },
      config: {
        lang: 'pt-br',
        autogrow: true,
        removeformatPasted: true,
        tagsToRemove: ['script', 'link'],
        linkTargets: ['_blank'],
        // Adding color plugin button
        btnsAdd: ['foreColor', 'backColor'],
        // Limit toolbar buttons
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['bold', 'italic'], ['formatting'], ['link'],
          ['foreColor'], ['backColor'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat']
        ]
      }
    }
  },
  computed: {
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
  },
  methods: {
    async firstFormSubmit() {
      if (this.validaCampos()) {
        this.loading = true;
        setTimeout(() => {
          this.$emit('resStep2', this.vaga);
          this.concluiStep2();
        }, 500);
      }
    },
    validaCampos() {
      if ( this.vaga.nu_salario === '' ) {
        this.validate.salario = true;
        this.$refs.salario.focus();
        return false;
      } else if ( this.vaga.fl_contratacao === '') {
        this.validate.tipocontrato = true;
        this.$refs.tipocontrato.focus();
        return false;
      } else if ( this.vaga.nm_turno_trabalho === '') {
        this.validate.periodo = true;
        this.$refs.periodo.focus();
        return false;
      } else if ( this.vaga.tx_descricao === '') {
        this.validate.descricao = true;
        this.$refs.descricao.focus();
        return false;
      } else if ( this.vaga.tx_requisitos === '') {
        this.validate.requisitos = true;
        console.log('refs ->> ', this.$refs.requisitos)
        this.$refs.requisitos.focus();
        return false;
      } else {
        return true;
      }
    },
    validaOnBlur(refName){
      setTimeout(() => {
        if (this.$refs[refName].value === '') {
          this.$set(this.validate, refName, true);
          return false;
        } else {
          this.$set(this.validate, refName, false);
          return true;
        }
      }, 300);
    },
    formataValor() {
      if (this.vaga.nu_salario === '') {
        this.validaOnBlur('salario');
      } else {
        if (typeof this.vaga.nu_salario === 'string'){
          console.log('entrou em string ->>')
          this.vaga.nu_salario = this.vaga.nu_salario.toLowerCase().replace(/[a-z]/gi, '').trim();
          this.vaga.nu_salario = parseInt(this.vaga.nu_salario, 10);
          if (typeof this.vaga.nu_salario === 'string') {
            this.vaga.nu_salario = '';
            this.validaOnBlur('salario');
          } else if (typeof this.vaga.nu_salario === 'number' && !isNaN(this.vaga.nu_salario)) {
            this.formataValor();
          } else {
            this.vaga.nu_salario = '';
            this.validaOnBlur('salario');
          }
        } else if (typeof this.vaga.nu_salario === 'number') {
          console.log('entrou em numeber ->>')
          //this.vaga.nu_salario = this.vaga.nu_salario.replace(/./g, '');
          ///this.vaga.nu_salario = this.vaga.nu_salario.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
          this.vaga.nu_salario = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(this.vaga.nu_salario);
          this.validaOnBlur('salario');
        }
      }
    },
    initValues() {
      this.loading = true;
      setTimeout(() => {
        this.vaga.fl_deficientes= this.propDeficientes;
        this.vaga.fl_contratacao = this.propContratacao;
        this.vaga.nu_salario = this.propSalario;
        this.vaga.fl_oculta_salario = this.propOculta_salario;
        this.vaga.nm_turno_trabalho  = this.propTurno_trabalho;
        this.vaga.tx_descricao = this.propDescricao;
        this.vaga.tx_requisitos = this.propRequisitos;
        this.loading = false;
      }, 500)
    },
    async geraDescricaoRequisitos() {
      //console.log('geraDescricaoRequisitos...')
      //this.loadingGeraTeste = true;
      this.loading = true;
      //this.resetNewTestFinal();
      const data = {
        id_empresa: this.empresa.id_empresa,
        vaga: "Preciso que você gere a descrição e os requisitos de uma vaga de emprego na empresa " + this.empresa.nm_fantasia + " para o cargo " + this.propCargo
      }
      await this.$http
        .post('vagas/geradescricao', data)
        .then(res => {
          //console.log('res.data -> ',  res.data);
          var result = this.preparaJson(res.data);
          this.vaga.tx_descricao = result.description;
          this.vaga.tx_requisitos = result.requirements;
          this.loading = false;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          this.loading = false;
          return false;
        });
    },
    preparaJson(json) {
      if(typeof json === 'object') {
        return json;
      } else {
        var str1 = json.replace("```json", '');
        var str2 = str1.replace("```", '');
        var resJson = JSON.parse(str2);
        return resJson;
      }
    },
  },
  mounted() {
    this.initValues();
  }
}
</script>
<style>
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
</style>
