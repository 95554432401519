<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>Validação das respostas:</h4>
        <p>Respostas corretas: <strong>{{corretas}}</strong> de <strong>{{total}}</strong> perguntas<br>
        Percentual de acertos: {{ (100 * (corretas / total)).toFixed(0) }}%</p>
      </div>
    </div>
    <div class="row my-3" v-for="question in propPerguntas" :key="question.id">
      <div class="col">
        Pergunta: <em>{{ question.value }}</em><br>
        <template v-if="question.type === 'text'">
          <template v-if="question.resp !== ''">
            Resposta: <strong><em>{{ question.resp }}</em></strong><br>
            <label class="form-check-label" :for="cbId + question.id">Marcar como correta</label>
            <input
              :id="cbId + question.id"
              class="form-check-input ml-2"
              type="checkbox"
              :value="true"
              @change="setCorreta($event.target.checked)"
            />
          </template>
          <template v-else>
            Resposta: <em>Não respondida</em>
          </template>
        </template>
        <template v-else-if="question.type === 'dico' || question.type === 'ru'">
          <template v-if="question.resp !== ''">
            Resposta: <strong><em>{{ retornaNomeOpcao(question.options, question.resp) }}</em></strong>
          </template>
          <template v-else>
            Resposta: <em>Não respondida</em>
          </template>
          <template v-if="question.defined !== ''">
            <span
              :class="validaRU(question.defined, question.resp) ? 'text-success' : 'text-danger'"
            >{{ validaRU(question.defined, question.resp) ? ' - Correta!' : ' - Errada!' }}</span>
          </template>
          <template v-else>
            <br>
            <label class="form-check-label" :for="cbId + question.id">Marcar como correta</label>
            <input
              :id="cbId + question.id"
              class="form-check-input ml-2"
              type="checkbox"
              :value="true"
              @change="setCorreta($event.target.checked)"
            />
          </template>
        </template>
        <template v-else-if="question.type === 'multi'">
          <template v-if="question.resp.length > 1">
            Respostas:
            <template v-if="question.defined !== ''">
              <span
                :class="validaMulti(question.defined, question.resp) ? 'text-success' : 'text-danger'"
              >{{ validaMulti(question.defined, question.resp) ? ' - Correta!' : ' - Errada!' }}</span>
            </template>
            <template v-else>
              <label class="form-check-label" :for="cbId + question.id"> - Marcar como correta</label>
              <input
                :id="cbId + question.id"
                class="form-check-input ml-2"
                type="checkbox"
                :value="true"
                @change="setCorreta($event.target.checked)"
              />
            </template>
            <ul>
              <li v-for=" resp in question.resp" :key="resp">
                <strong><em>{{ resp }}</em></strong>
              </li>
            </ul>
          </template>
          <template v-else-if="question.resp.length === 1">
            Resposta:
            <template v-if="question.defined !== ''">
              <span
                :class="validaMulti(question.defined, question.resp) ? 'text-success' : 'text-danger'"
              >{{ validaMulti(question.defined, question.resp) ? ' - Correta!' : ' - Errada!' }}</span>
            </template>
            <template v-else>
              <label class="form-check-label" :for="cbId + question.id"> - Marcar como correta</label>
              <input
                :id="cbId + question.id"
                class="form-check-input ml-2"
                type="checkbox"
                :value="true"
                @change="setCorreta($event.target.checked)"
              />
            </template>
            <ul>
              <li v-for=" resp in question.resp" :key="resp">
                <strong><em>{{ resp }}</em></strong>
              </li>
            </ul>
          </template>
          <template v-else>
            Resposta: <em>Não respondida</em>
            <template v-if="question.defined !== ''">
              <span
                :class="validaMulti(question.defined, question.resp) ? 'text-success' : 'text-danger'"
              >{{ validaMulti(question.defined, question.resp) ? ' - Correta!' : ' - Errada!' }}</span>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ApresentaResultados",
  props: {
    propPerguntas: { type: Array }
  },
  data() {
    return {
      corretas: "",
      total: "",
      cbId: ""
    }
  },
  methods: {
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
    retornaNomeOpcao(options, value){
      if (value === ''){
        return "Não respondida";
      } else {
        var res = options.find(i => i.value === value);
        return res.text;
      }
    },
    contaCorretas() {
      let count = 0;
      setTimeout(() => {
        this.propPerguntas.forEach(c => {
          if (c.type === 'multi') {
            if (this.validaMulti(c.defined, c.resp)){
              count += 1;
            }
          } else if (c.type === 'dico' || c.type === 'ru') {
            if (this.validaRU(c.defined, c.resp)){
              count += 1;
            }
          }
        });
        this.total = this.propPerguntas.length;
        this.corretas = count;
      }, 100);
    },
    setCorreta(value) {
      if (value) {
        this.corretas += 1;
      } else {
        this.corretas -= 1;
      }
    }
  },
  mounted() {
    this.contaCorretas();
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
}
</script>

<style>

</style>
