<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ dadosEmpresa.nomeFantasia }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <i class="icone-help-bread-crumb fas fa-question-circle"></i>
        </div>
      </div>
      <!-- Card stats -->
      <!-- <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Total traffic"
            type="gradient-red"
            sub-title="350,897"
            icon="ni ni-active-40"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 3.48%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Total traffic"
            type="gradient-orange"
            sub-title="2,356"
            icon="ni ni-chart-pie-35"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Sales"
            type="gradient-green"
            sub-title="924"
            icon="ni ni-money-coins"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2"
                ><i class="fa fa-arrow-down"></i> 5.72%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Performance"
            type="gradient-info"
            sub-title="49,65%"
            icon="ni ni-chart-bar-32"
          >
            <template v-slot:footer>
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 54.8%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
      </div> -->
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Meus Atalhos</h5>
            </template>
            <div class="row">
              <div class="col-auto">
                <base-button size="sm" outline type="primary" @click="goTo('novaVaga')">Abrir uma nova Vaga <i class="fas fa-angle-double-right"></i></base-button>
              </div>
              <!-- <div class="col-auto">
                <base-button size="sm" outline type="primary">Buscar um Candidato <i class="fas fa-angle-double-right"></i></base-button>
              </div> -->
              <div class="col-auto">
                <base-button size="sm" outline type="primary" @click="goTo('listaVaga')">Editar uma Vaga <i class="fas fa-angle-double-right"></i></base-button>
              </div>
              <div class="col-auto">
                <base-button size="sm" outline type="primary" @click="goTo('testes')">Criar um novo Teste <i class="fas fa-angle-double-right"></i></base-button>
              </div>
              <div class="col-auto">
                <base-button size="sm" outline type="primary" @click="goTo('enderecos')">Criar um novo Local <i class="fas fa-angle-double-right"></i></base-button>
              </div>
              <div class="col-auto">
                <base-button size="sm" outline type="primary" @click="goTo('usuarios')">Adicionar um novo usuário <i class="fas fa-angle-double-right"></i></base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!--Lists-->
      <div class="row">
        <template  v-for="(vaga, index) in vagas">
        <div class="col-xl-4 col-md-4" v-if="index <= 5" :key="index">
          <card>
            <CardProcesso
              :propVaga="vaga"
            />
          </card>
        </div>
        </template>
      </div>
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <card>
            <ListaVagas
            />
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-4">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Usuários</h5>
            </template>
            <user-list></user-list>
            <template v-slot:footer>
              <div class="text-center text-primary font-weight-bold">
                <router-link to="/usuarios">Ver Todos</router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-xl-4 col-md-4">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Testes</h5>
            </template>
            <TestesList />
            <template v-slot:footer>
              <div class="text-center text-primary font-weight-bold">
                <router-link to="/testes">Ver Todos</router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-xl-4 col-md-4">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Endereços</h5>
            </template>
            <EnderecoList />
            <template v-slot:footer>
              <div class="text-center text-primary font-weight-bold">
                <router-link to="/enderecos">Ver Todas</router-link>
              </div>
            </template>
          </card>
        </div>
      </div>
      <!--End lists-->
      <!-- <div class="row">
        <div class="col-xl-12 col-md-12">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">Configs do Firestore</h5>
            </template>
            <div class="row">
              <div class="col-3">
                <strong>Firestore - perfilUsuario -> </strong><pre>{{ dadosEmpresa }}</pre>
              </div>
              <div class="col-3">
                <strong>empresa Api -> </strong><pre>{{ empresa }}</pre>
              </div>
              <div class="col-3">
                <strong>usuários Api -> </strong><pre>{{ usuarios }}</pre>
              </div>
              <div class="col-3">
                <strong>vagas Api -> </strong><pre>{{ vagas }}</pre>
              </div>
            </div>
          </card>
        </div>
      </div> -->
      <!--Widgets-->
      <!-- <div class="row">
        <div class="col-xl-5">
          <activity-feed></activity-feed>
        </div>
        <div class="col-xl-7">
          <light-table></light-table>
          <div class="card-deck">
            <card gradient="default" no-body="">
              <div class="card-body">
                <div class="mb-2">
                  <sup class="text-white">$</sup>
                  <span class="h2 text-white">3,300</span>
                  <div class="text-light mt-2 text-sm">
                    Your current balance
                  </div>
                  <div>
                    <span class="text-success font-weight-600">+ 15%</span>
                    <span class="text-light">($250)</span>
                  </div>
                </div>
                <button class="btn btn-sm btn-block btn-neutral">
                  Add credit
                </button>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <small class="text-light">Orders: 60%</small>
                    <base-progress
                      :value="60"
                      size="xs"
                      progress-classes="my-2"
                      type="success"
                    />
                  </div>
                  <div class="col">
                    <small class="text-light">Sales: 40%</small>
                    <base-progress
                      :value="40"
                      size="xs"
                      progress-classes="my-2"
                      type="warning"
                    />
                  </div>
                </div>
              </div>
            </card>

            <card gradient="danger">
              <div class="row justify-content-between align-items-center">
                <div class="col">
                  <img
                    src="img/icons/cards/bitcoin.png"
                    alt="Image placeholder"
                  />
                </div>
                <div class="col-auto">
                  <span class="badge badge-lg badge-success">Active</span>
                </div>
              </div>
              <div class="my-4">
                <span class="h6 surtitle text-light"> Username </span>
                <div class="h1 text-white">@johnsnow</div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="h6 surtitle text-light">Name</span>
                  <span class="d-block h3 text-white">John Snow</span>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div> -->
      <!--End Widgets-->

      <!--Tables-->
      <!-- <div class="row">
        <div class="col-xl-8">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div> -->
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import router from '../../routes/router';
import { db } from "../../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import { userKey } from "@/global";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
// Lists
import NotesList from "./NotesList.vue";
import UserList from "./UserList";
import ListaVagas from "./ListaVagas.vue";
import TestesList from "./TestesList.vue";
import EnderecoList from "./EnderecoList.vue";

//Cards MatchJob
import CardProcesso from "./CardProcesso.vue";

export default {
  mixins: [defaultMatchjob],
  components: {
    //NotesList,
    UserList,
    ListaVagas,
    RouteBreadCrumb,
    CardProcesso,
    TestesList,
    EnderecoList
  },
  data() {
    return {
      dadosEmpresa: [],
      empresa: [],
      usuarios: [],
      //vagas: [],
    };
  },
  computed: {
    vagas() {
      return this.$store.state.empresa.vagas.filter(v => v.fl_status === "4");
    }
  },
  methods: {
    async carregaFirestore() {
      const resConfig = doc(db, "empresas", this.id.empresa);
      //console.log("instanciando .doc -> ", resConfig);
      await getDoc(resConfig).then((snapshot) => {
        let docs = { ...snapshot.data(), id: snapshot.id };
        this.dadosEmpresa = docs;
        //console.log("retorno do getDoc ->", this.dadosEmpresa);
        //this.dadosEmpresa = dadosEmpresa;
      });
      // Fim - Bloco perfil do usuário
    },
    goTo(value) {
      if(value === 'novaVaga') {
        this.$router.push({ name: 'Nova Vaga' });
      } else if(value === 'listaVaga') {
        this.$router.push({ path: '/vagas' });
      } else if(value === 'testes') {
        this.$router.push({ path: '/testes' });
      } else if(value === 'enderecos') {
        this.$router.push({ path: '/enderecos' });
      } else if(value === 'usuarios') {
        this.$router.push({ path: '/usuarios' });
      }
    }
  },
  mounted() {
    this.carregaFirestore();
    //this.carregaEmpresa();
  }
};
</script>

<style>
.icone-help-bread-crumb {
  color: #FFF;
  font-size: 1.6rem;
  cursor: pointer;
}
</style>
