<template>
  <div class="content" v-loading="loading">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <!-- Inicio da listagem de vagas cadastradas -->
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Lista de Vagas</h3>
                <p class="text-sm mb-0">
                  {{ total }} vagas cadastradas na MatchJob Empregos.
                </p>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle" @click="drawer = !drawer"></i>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="form-check form-check-inline">
                <base-input v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Título da vaga...">
                </base-input>
                <base-button
                  class="ml-3 mb-4"
                  type="primary"
                  v-if="filtraUsuariosRh()"
                  @click="goToNovaVaga"
                >Nova Vaga</base-button>
              </div>
            </div>
            <el-table :data="queriedData"
              row-key="id_processo_vaga"
              header-row-class-name="thead-light"
              @sort-change="sortChange">
              <el-table-column min-width="200" sortable prop="nm_cargo" label="Título da Vaga" class-name="px-3">
                <div slot-scope="{ row }">
                  <!-- <span class="link-ativo-cargo" @click="goToVaga(row.hl_link)">{{row.nm_cargo}}</span>
                   - <span class="link-ativo-cargo" @click="goToVagaDashboard(row.hl_link)">Dashboard</span> -->
                  <span class="link-ativo-cargo" @click="goToVagaDashboard(row.hl_link)">{{row.nm_cargo}}</span>
                </div>
              </el-table-column>
              <el-table-column min-width="80" label="Candidaturas" align="center">
                <div slot-scope="{ row }">
                  {{row.json_candidaturas.length}}
                </div>
              </el-table-column>
              <el-table-column
                min-width="80"
                prop="fl_status"
                label="Status"
                column-key="fl_status"
                class-name="p-0"
                :filters="this.flStatusVaga"
                :filter-method="filterHandler"
              >
                <div slot-scope="{ row }">
                  <badge rounded :type="badgeStatusType(row.fl_status)" class="text-dark" size="lg">
                    {{ badgeStatus(row.fl_status) }}</badge>
                </div>
              </el-table-column>
              <el-table-column
                min-width="80"
                label="Fase Atual"
                class-name="p-0"
              >
                <div slot-scope="{ row }">
                  <small>{{verificaFase(row)}}</small>
                </div>
              </el-table-column>
              <!-- <el-table-column
                min-width="150"
                prop="dt_abertura"
                label="Abertura"
                sortable
                :formatter="formatTimestamp">
              </el-table-column> -->
              <el-table-column
                min-width="50"
                prop="dt_prev_fechamento"
                label="Fechamento"
                class-name="p-0"
                :formatter="formatTimestamp">
              </el-table-column>
              <el-table-column
                min-width="80"
                label="Dias restantes"
                class-name="p-0"
              >
                <div slot-scope="{ $index, row }">
                  <span :class="diasFaltantes($index, row, 'classe')">{{ diasFaltantes($index, row, 'valor') }}</span>
                </div>
              </el-table-column>
               <!-- <el-table-column min-width="100" label="Ações" class-name="p-0">
                <div slot-scope="{ row }">
                 <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item v-if="verificaUsuarioLogado(row.json_fases)" class="text-default" :command="['goToVaga', row.hl_link]"><i class="fas fa-file-alt"></i>Visualizar</el-dropdown-item>
                      <el-dropdown-item v-if="filtraUsuariosRh()" class="text-info" :command="['goToEditarVaga', row.hl_link]"><i class="fas fa-pen"></i>Editar</el-dropdown-item>
                      <el-dropdown-item v-if="filtraUsuariosRh()" class="text-danger" :command="['handleDelete', row]"><i class="fas fa-trash-alt"></i>Excluir</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <base-button
                    class="edit"
                    type="secondary"
                    size="sm"
                    icon
                    v-if="verificaUsuarioLogado(row.json_fases)"
                    @click="goToVaga(row.hl_link)"
                  >
                    <i class="fas fa-eye"></i>
                  </base-button>
                  <base-button
                    class="edit"
                    type="info"
                    size="sm"
                    icon
                    v-if="filtraUsuariosRh()"
                    @click="goToEditarVaga(row.hl_link)"
                  >
                    <i class="text-white fas fa-pen"></i>
                  </base-button>
                  <base-button
                    class="edit"
                    type="danger"
                    size="sm"
                    icon
                    v-if="filtraUsuariosRh()"
                    @click="handleDelete(row)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </base-button>
                </div>
              </el-table-column> -->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} em {{ total }} vagas.</p>

            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        <!-- <pre>{{Object.keys(vagas[0])}}</pre> -->
      </div>
    </div>
    <el-drawer
      title="Ajuda da MatchJob"
      :visible.sync="drawer"
      direction="rtl"
      size="50%">
      <h3 class="mx-2"><span class="text-muted">Perguntas frequêntes -</span> Vagas</h3>
      <collapse>
        <collapse-item name="1">
          <h4 class="m-0" slot="title">Quais são as 'Ações Rápidas' existentes?</h4>
          <p class="font-weight-normal">As 'Ações Rápidas' existente são:</p>
          <ol>
            <li><strong>Criar Template</strong> - Cria um novo template de vaga a partir dessa vaga</li>
            <li><strong>Aprovar Vaga</strong> - Aprova a vaga caso a mesma esteja pendente da sua Aprovação</li>
            <li><strong>Reprovar Vaga</strong> - Reprova a vaga caso a mesma esteja pendente da sua Aprovação</li>
            <li><strong>Publicar Vaga</strong> - Realiza a publicação da vaga caso a mesma esteja pendente de publicação</li>
            <li><strong>Encerrar Vaga</strong> - Encerra a vaga atual - define a conclusão ou témino do processo seletivo da vaga</li>
            <li><strong>Cancelar Vaga</strong> - Cancela a vaga atual - A vaga será despublicada não receberá novas candidaturas</li>
            <li><strong>Congelar Vaga</strong> - Congela a vaga atual - A vaga será despublicada e poderá receber novas candidaturas se for reativada</li>
            <li><strong>Reativar Vaga</strong> - Reativa uma vaga congelada - A vaga será reativada e poderá receber novas candidaturas</li>
          </ol>
        </collapse-item>
        <collapse-item name="2">
          <h4 class="m-0" slot="title">Como funciona a 'Nova vaga a partir desse template'?</h4>
        </collapse-item>
        <collapse-item name="3">
          <h4 class="m-0" slot="title">Como reativar uma vaga?</h4>
        </collapse-item>
        <collapse-item name="4">
          <h4 class="m-0" slot="title">Como reativar uma vaga?</h4>
        </collapse-item>
      </collapse>
      <span class="text-center">
        <p class="font-weight-lighter">
          <small>
            Este tópico foi útil?<br>
            <a href="#">Clique aqui</a> se precisar de mais informações.
          </small>
        </p>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { userKey } from "@/global";
import { Table,
  TableColumn,
  Select,
  Option,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Drawer } from 'element-ui';
import moment from "moment";
import { BasePagination } from '@/components';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

import clientPaginationMixin from './vagasPaginacao';
import swal from 'sweetalert2';
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

export default {
  mixins: [clientPaginationMixin, defaultMatchjob],
  components: {
    BasePagination,
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Drawer.name]: Drawer,
    Collapse,
    CollapseItem,
  },
  data() {
    return {
      drawer: false,
      loading: false,
      //vagas: [],
      flStatusVaga: [
        { value: '1', text: "Aberta" },
        { value: '2', text: "Em Aprovação" },
        { value: '3', text: "Aprovada" },
        { value: '4', text: "Publicada" },
        { value: '5', text: "Rascunho" },
        { value: '6', text: "Encerrada" },
        { value: '7', text: "Cancelada" },
        { value: '8', text: "Congelada" },
        { value: '9', text: "Reprovada" }
      ],
      propsToSearch: ['nm_cargo'],
      modal: {
        editarUsuario: false,
        novoUsuario: false
      }
    };
  },
  computed: {
    vagas() {
      return this.$store.state.empresa.vagas;
    }
  },
  methods: {
    async carregaVagas() {
      this.vagas = await this.$store.state.empresa.vagas;
      /*
      this.$http.defaults.headers.common[
            "Authorization"
          ] = `bearer ${this.access_token}`;
      await this.$http.get('vagas', {
        params: { id_empresa: this.id.empresa }
      }).then(res => {
        this.vagas = res.data.vagas;
      }) */
    },
    formatTimestamp: function(row, column) {
      var date = row[column.property]
      if (date == undefined) {
        return "";
      }
      return moment(date).format("DD/MM/YYYY");
    },
    diasFaltantes(index, row, value) {
      let start = moment(row.dt_abertura);
      let end = moment(row.dt_prev_fechamento);
      let now = moment();
      let sobra = moment.duration(now.diff(start)).asDays();
      let duration = moment.duration(end.diff(start));
      let days = duration.asDays();
      let res = days - sobra;
      res = Math.ceil(res);
      let valor = "";
      let classe = "";
      if (row.fl_status < 5) {
        if (value === 'valor'){
          if (res > 0 && res < 2) {
            return res + ' dia restante';
          } else if (res > 1) {
            return res + ' dias restantes';
          } else if (res === 0) {
            return 'Encerra hoje';
          } else if (res < 0 && res > -2) {
            return Math.abs(res) + ' dia atrado';
          } else if (res < -1) {
            return Math.abs(res) + ' dias atrado';
          }
        } else {
          if (res > 0 && res < 2) {
            return 'text-success';
          } else if (res > 1) {
            return 'text-success';
          } else if (res === 0) {
            return 'text-warning';
          } else if (res < 0 && res > -2) {
            return 'text-danger';
          } else if (res < -1) {
            return 'text-danger';
          }
        }
      } else {
        return '-'
      }
    },
    badgeStatus(value) {
      let tipo = this.flStatusVaga.find(i => i.value === value);
      return tipo.text;
    },
    badgeStatusType(value) {
      let n = parseInt(value);
      if (n === 1) {
        return "secondary";
      } else if (n === 2) {
        return "success";
      } else if (n === 3) {
        return "dark text-white";
      } else if (n === 4) {
        return "info"
      } else if (n === 5) {
        return "default text-white"
      } else if (n === 6) {
        return "attention"
      } else if (n === 7 || n === 9) {
        return "warning"
      } else if (n === 8) {
        return "darker text-white"
      }
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    //Excluir
    handleDelete(row) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir a vaga:<br> ${row.nm_cargo}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          this.loading = true;
          if (this.deletarVaga(row.id_processo_vaga)){
            this.deleteRow(row.id_processo_vaga);
            setTimeout(() => {
              this.loading = false;
              this.notificacao('Vaga excluída com sucesso', 'success');
            }, 500);
          } else {
            this.loading = false;
            this.notificacao('Ops! Não foi possível excluír a vaga.', 'warning');
          }
        }
      });
    },
    async deletarVaga(id) {
      const data = {
        id_empresa: this.id.empresa,
        id_processo_vaga: id,
        nm_nome: this.id.nome,
        hl_email: this.id.usuario,
        id_empresa_user: this.id.empresa_user
      }
      await this.$http
        .post('vagas/deletar', data)
        .then(() => {
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err)
          return false;
        });
    },
    deleteRow(id) {
      let indexToDelete = this.vagas.findIndex(
        tableRow => tableRow.id_processo_vaga === id
      );
      if (indexToDelete >= 0) {
        this.vagas.splice(indexToDelete, 1);
      }
    },
    goToNovaVaga() {
      this.$router.push({ name: 'Nova Vaga' });
    },
    goToVaga(value) {
      this.$router.push({ name: 'visualizarvaga', params: { url: value} });
    },
    goToVagaDashboard(value) {
      this.$router.push({ name: 'dashboardvaga', params: { url: value} });
    },
    goToEditarVaga(value) {
      this.$router.push({ name: 'Editar Vaga', params: { url: value} });
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    filtraUsuariosRh() {
      //console.log('id -> ', this.id)
      if (this.id.admin) {
        //console.log('filtraUsuariosRh Admin ->>')
        return true;
      } else if (parseInt(this.id.tipoAcesso) > 0) {
        //console.log('filtraUsuariosRh NÃO Admin ->>')
        if (parseInt(this.id.tipoAcesso) < 5){
          //console.log('filtraUsuariosRh É RH ->>')
          return true;
        } else {
          //console.log('filtraUsuariosRh Não é RH ->>')
          return false;
        }
      } else {
        //console.log('filtraUsuariosRh Não é NADA!! ->>')
        return false;
      }
    },
    verificaUsuarioLogado(vaga) {
      //console.log('dados -> ', vaga)
      if (this.id.admin) {
        return true;
      } else {
        if (this.id.tipoAcesso < 5){
          return true;
        } else {
          return false;
        }
      }
    },
    verificaFase(row) {
      var qtdFases = row.json_fases.length;
      var faseAtual = 0;
      for(var i = 0; i <= qtdFases; i++) {
        var existe = row.json_candidaturas.findIndex(e => e.fase_atual.toString() === i.toString());
        if (existe > -1) {
          faseAtual = i - 1;
        }
      }
      if (row.json_fases[faseAtual].value === undefined) {
        return row.json_fases[faseAtual].text;
      } else {
        return row.json_fases[faseAtual].value;
      }
    },
    handleCommand(command) {
      if (command[0] === 'goToVaga'){
        this.goToVaga(command[1])
      } else if (command[0] === 'goToEditarVaga') {
        this.goToEditarVaga(command[1]);
      } else if (command[0] === 'handleDelete') {
        this.handleDelete(command[1]);
      }
    },
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    //this.initVariaveis();
    //this.carregaVagas();
  }
};
</script>
<style>
.icone-help-bread-crumb {
  color: #FFF;
  font-size: 1.6rem;
  cursor: pointer;
}
  .no-border-card .card-footer{
    border-top: 0;
  }
  .icone-ativo {
    font-size: 1.2rem;
    color: red;
  }
  .icone-desabilitado {
    font-size: 1.2rem;
    color: green;
  }
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
  .link-ativo-cargo {
    cursor: pointer;
    color: #5e72e4;
  }
  .link-ativo-cargo:hover {
    text-decoration: underline;
  }
</style>
