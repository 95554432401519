<template>
  <div class="content">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div v-loading="loading">
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Lista de Testes</h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
              </div>
            </div>
          </template>
          <div class="card-body">
            <div>
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <div class="form-check form-check-inline">
                  <base-input v-model="searchQuery"
                    prepend-icon="fas fa-search"
                    placeholder="Procurar...">
                  </base-input>
                  <base-button
                    v-if="plano.nm_plano === '09300df44b9d20cc219b25abddc3346e'"
                    class="ml-3 mb-4"
                    type="success"
                    @click="modal.gerarTeste = true"
                  ><i class="fas fa-hat-wizard"></i>Gerar Teste</base-button>
                  <base-button
                    class="ml-3 mb-4"
                    type="primary"
                    @click="modal.novoteste = true"
                  >Adicionar Teste</base-button>
                </div>
              </div>
              <el-table :data="queriedData"
                row-key="id"
                header-row-class-name="thead-light"
                @sort-change="sortChange">
                <el-table-column min-width="300" sortable prop="nm_nome" label="Nome" class-name="p-2">
                  <div slot-scope="{ row }">
                    <strong>{{ row.nm_nome}}</strong><br>
                    {{ row.nm_descricao }}
                  </div>
                </el-table-column>
                <el-table-column
                  min-width="160"
                  sortable
                  prop="nm_tipo"
                  label="Tipo"
                  class-name="p-1"
                  :formatter="defineTipoTabela">
                </el-table-column>
                <el-table-column min-width="200" sortable prop="nm_nome_user" label="Criado por" class-name="p-1">
                  <div slot-scope="{ row }">
                    {{ row.nm_nome_user}}<br>
                    em {{ row.created_at | formatDateTime }}
                  </div>
                </el-table-column>
                <el-table-column min-width="200" sortable prop="json_tags" label="Tags" class-name="p-0">
                  <div slot-scope="{ row }">
                    <span
                      v-for="tag in row.json_tags.tags" :key="tag"
                      class="el-tag el-tag--info el-tag--mini el-tag--plain m-1">{{tag}}</span>
                  </div>
                </el-table-column>
                <el-table-column align="center" label="Ações" class-name="p-0">
                  <div slot-scope="{ row }">
                    <el-dropdown trigger="click" @command="handleCommand">
                      <span class="el-dropdown-link">
                        <i class="fas fa-ellipsis-v mt-2"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="text-default" :command="['visualizar', row.id_teste]"><i class="fas fa-file-alt"></i>Visualizar</el-dropdown-item>
                        <el-dropdown-item class="text-info" :command="['editar', row.id_teste]"><i class="fas fa-pen"></i>Editar</el-dropdown-item>
                        <el-dropdown-item class="text-danger" :command="['excluir', row]"><i class="fas fa-trash-alt"></i>Excluir</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} em {{ total }} testes.<br>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
        <modal
          :show="modal.novoteste"
          :showClose="false"
          size="xl"
          v-loading.fullsccreen.lock="loading"
        >
          <h6 slot="header" class="modal-title">{{ newTestFinal.id_teste === '' ? 'Novo Teste' : `Ediçao de Teste - ${newTestFinal.nm_nome}` }}</h6>
          <div class="row">
            <!-- Show/presenting Questions -->
            <div class="col-12">
              <!-- Nome e Descrição do teste -->
              <div class="row" ref="topo">
                <!-- Nome do teste -->
                <div class="col-6">
                  <label class="form-control-label">Nome do Teste</label>
                  <input
                    id="nomeTeste"
                    ref="nomeTeste"
                    type="text"
                    maxlength="255"
                    class="form-control"
                    name="Nome do Teste"
                    placeholder="Nome do Teste"
                    v-model="newTestFinal.nm_nome"
                    @blur="defineUrl"
                  />
                </div>
                <!-- Descrição do teste -->
                <div class="col-6">
                  <label class="form-control-label">Descrição</label>
                  <input
                    id="descricaoTeste"
                    ref="descricaoTeste"
                    type="text"
                    maxlength="255"
                    class="form-control"
                    name="Descrição"
                    placeholder="Descrição"
                    v-model="newTestFinal.nm_descricao"
                  />
                </div>
              </div>
              <!-- URL e Categoria do teste -->
              <div class="row mb-3">
                <!-- URL do teste -->
                <div class="col-6">
                  <label class="form-control-label">URL amigável do teste</label><br>
                  <small class="text-muted">https://vagas.matchjob.com.br/{{empresa.hl_url_amigavel}}/testes/<strong>{{ newTestFinal.hl_url }}</strong></small>
                </div>
                <!-- Categoria do teste -->
                <div class="col-6">
                  <label class="form-control-label mr-1">Categoria do Teste</label>
                  <i class="fas fa-question-circle"></i>
                  <el-autocomplete
                    class="inuptAutocomplete"
                    v-model="newTestFinal.nm_categoria"
                    :fetch-suggestions="querySearchCategoria"
                    placeholder="Categoria do teste"
                    :trigger-on-focus="true"
                  ></el-autocomplete>
                </div>
              </div>
              <!-- Tipo (tempo) e Tempo do teste -->
              <div class="row mb-2">
                <!-- Tipo do teste -->
                <div class="col-6">
                  <label class="form-control-label mr-1">Tipo do Teste</label>
                  <i class="fas fa-question-circle"></i>
                  <select
                    class="form-control"
                    v-model="newTestFinal.nm_tipo"
                    :disabled="newTest.length > 0"
                    @blur="selectTipo($event.target.value)"
                  >
                    <option
                      v-for="types in tipoTestes"
                      :key="types.value"
                      :value="types.value"
                    >{{ types.text }}</option>
                  </select>
                </div>
                <!-- Tempo do teste -->
                <div class="col-2">
                  <label class="form-control-label mr-1">Tempo</label>
                  <el-tooltip content="Tempo em minutos" placement="top">
                    <i class="fas fa-question-circle"></i>
                  </el-tooltip>
                  <input
                    id="tempoTeste"
                    ref="tempoTeste"
                    class="form-control"
                    name="Tempo"
                    type="number"
                    v-model="newTestFinal.nu_tempo"
                    :disabled="newTestFinal.nm_tipo !== 'tempo'"
                  />
                </div>
              </div>
              <!-- Tags do Teste -->
              <div class="row">
                <div class="col-6">
                  <label class="form-control-label">Tags</label>
                  <tags-input v-model="newTestFinal.json_tags.tags" placeholder="Adicionar tags" type="primary"></tags-input>
                </div>
              </div>
            </div>
          </div>
          <!-- divisor -->
          <div class="row">
            <div class="col-12">
              <hr class="my-1">
            </div>
          </div>
          <!-- Criação de Perguntas -->
          <div class="row">
            <div class="col-6">
              <!-- Select do tipo da pergunta -->
              <div class="row" ref="perguntas">
                <div class="col-6">
                  <h4>Adicionar nova questão</h4>
                  <base-input label="Tipo da Questão">
                    <el-select
                      v-model="newQuestion.type"
                      :disabled="newTestFinal.nm_tipo === '' || newTestFinal.nm_nome === '' || newTestFinal.nm_descricao === '' || newTestFinal.nm_categoria === ''"
                    >
                      <el-option
                        v-for="type in questionTypes"
                        :key="type.value"
                        :value="type.value"
                        :label="type.text"
                        :disabled="type.disabled"
                      >{{ type.text }}</el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <!-- Flag de obrigatoriedade -->
              <div class="row mb-2">
                <div class="col">
                  <template v-if="newTestFinal.nm_tipo === 'auto' || newTestFinal.nm_tipo === 'tempo'">
                    <label class="form-control-label mr-1">Obrigatória! <small>O tipo do teste impõe a obrigatoriedade da resposta</small></label>
                  </template>
                  <template v-else>
                    <base-input label="Obrigatória?" class="form-inline">
                      <base-switch
                        class="ml-2"
                        v-model="newQuestion.required"
                      ></base-switch>
                    </base-input>
                  </template>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <!-- Pergunta de Texto Simples -->
                  <template v-if="newQuestion.type === 'text'">
                    <base-input label="Escreva a pergunta" type="text" v-model="newQuestion.value">
                    </base-input>
                    <base-button type="primary" @click="addQuestion">Adicionar</base-button>
                  </template>
                  <!-- Pergunta Dicotômica -->
                  <template v-else-if="newQuestion.type === 'dico'">
                    <!-- Input de Entrada da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Escreva a pergunta" type="text" v-model="newQuestion.value">
                        </base-input>
                      </div>
                    </div>
                    <!-- Select de Templates pré-definidos -->
                    <div class="row">
                      <div class="col-4">
                        <base-input label="Tipo da Pergunta">
                          <select class="form-control"  v-model="dicoSelectType">
                            <option
                              v-for="types in dicoDefinedTypes"
                              :key="types.id"
                              :value="types.id"
                            >{{ types.name }}</option>
                            <option value="4">Personalizar...</option>
                          </select>
                        </base-input>
                      </div>
                    </div>
                    <!-- Input de adição de Opções -->
                    <div class="row" v-if="dicoSelectType === '4'">
                      <div class="col-4">
                        <base-input label="Valor para positivo" type="text" v-model="dicoTemp.textTrue">
                        </base-input>
                      </div>
                      <div class="col-4">
                        <base-input label="Valor para negativo" type="text" v-model="dicoTemp.textFalse">
                        </base-input>
                      </div>
                    </div>
                    <!-- Flag de resposta correta -->
                    <div class="row my-3">
                      <template v-if="newTestFinal.tipo === 'auto' || newTestFinal.tipo === 'tempo'">
                        <div class="col form-inline">
                          <label class="form-control-label">Defina a resposta correta</label>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col form-inline">
                          <label class="form-control-label">Possui resposta correta?</label>
                          <base-switch
                            class="ml-2 mb-1"
                            v-model="flCorrect"
                          ></base-switch>
                        </div>
                      </template>
                    </div>
                    <!-- Select - Marção de resposta correta -->
                    <div class="row pb-3" v-if="flCorrect">
                      <div class="col-4">
                        <select class="form-control" placeholder="Selecione se houver..." v-model="newQuestion.defined">
                          <template v-if="dicoSelectType === '1'">
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                          </template>
                          <template v-else-if="dicoSelectType === '2'">
                            <option value="Verdadeira">Verdadeira</option>
                            <option value="Falso">Falso</option>
                          </template>
                          <template v-else-if="dicoSelectType === '3'">
                            <option value="Concordo">Concordo</option>
                            <option value="Discordo">Discordo</option>
                          </template>
                          <template v-else-if="dicoSelectType === '4'">
                            <option :value="dicoTemp.textTrue">{{dicoTemp.textTrue}}</option>
                            <option :value="dicoTemp.textFalse">{{dicoTemp.textFalse}}</option>
                          </template>
                        </select>
                      </div>
                    </div>
                    <!-- Botão de adição da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-button type="primary" @click="addQuestion">Adicionar</base-button>
                      </div>
                    </div>
                  </template>
                  <!-- Pergunta RU - Múltiplas Opções -->
                  <template v-else-if="newQuestion.type === 'ru'">
                    <!-- Input de Entrada da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Escreva a pergunta" type="text" v-model="newQuestion.value">
                        </base-input>
                      </div>
                    </div>
                    <!-- Lista de Opções já cadastradas -->
                    <div class="row">
                      <div class="col">
                        <p>Opções adicionadas: {{ ruTemp.length }}</p>
                        <ul>
                          <li class="mb-2" v-for="opt in ruTemp" :key="opt.id">{{ opt.text }} -
                            <base-button size="sm" type="danger" @click="delRuTemp(opt.id)">
                              <i class="fas fa-trash-alt"></i>
                            </base-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- Input de adição de Opções -->
                    <div class="row">
                      <div class="col form-inline">
                        <label class="mr-2">Adicione uma opção por vez: </label>
                        <base-input type="text" class="mr-2" v-model="ruAdd">
                        </base-input>
                        <base-button type="primary" @click="addRuTemp">Add</base-button>
                      </div>
                    </div>
                    <!-- Flag de resposta correta -->
                    <div class="row my-3">
                      <template v-if="newTestFinal.tipo === 'auto' || newTestFinal.tipo === 'tempo'">
                        <div class="col form-inline">
                          <label class="form-control-label">Defina a resposta correta</label>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col form-inline">
                          <label class="form-control-label">Possui resposta correta?</label>
                          <base-switch
                            class="ml-2 mb-1"
                            v-model="flCorrect"
                          ></base-switch>
                        </div>
                      </template>
                    </div>
                    <!-- Select - Marção de resposta correta -->
                    <div class="row pb-3" v-if="flCorrect">
                      <div class="col">
                        <select class="form-control" placeholder="Selecione se houver..." v-model="newQuestion.defined" :disabled="ruTemp.length < 3">
                          <option v-for="opt in ruTemp" :key="opt.id" :value="opt.value">{{ opt.text }}</option>
                        </select>
                      </div>
                    </div>
                    <!-- Botão de adição da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-button type="primary" @click="addQuestion" :disabled="ruTemp.length < 3">Adicionar</base-button>
                      </div>
                    </div>
                  </template>
                  <!-- Pergunta Múltipla Escolha -->
                  <template v-else-if="newQuestion.type === 'multi'">
                    <!-- Input de Entrada da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Escreva a pergunta" type="text" v-model="newQuestion.value">
                        </base-input>
                      </div>
                    </div>
                    <!-- Lista de Opções já cadastradas -->
                    <div class="row">
                      <div class="col">
                        <p>Opções adicionadas: {{ ruTemp.length }}</p>
                        <ul>
                          <li class="mb-2" v-for="opt in ruTemp" :key="opt.id">{{ opt.text }} -
                            <base-button size="sm" type="danger" @click="delRuTemp(opt.id)">
                              <i class="fas fa-trash-alt"></i>
                            </base-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- Input de adição de Opções -->
                    <div class="row">
                      <div class="col form-inline">
                        <label class="mr-2">Adicione uma opção por vez: </label>
                        <base-input type="text" class="mr-2" v-model="ruAdd">
                        </base-input>
                        <base-button type="primary" @click="addRuTemp">Add</base-button>
                      </div>
                    </div>
                    <!-- Flag de resposta correta -->
                    <div class="row my-3">
                      <template v-if="newTestFinal.tipo === 'auto' || newTestFinal.tipo === 'tempo'">
                        <div class="col form-inline">
                          <label class="form-control-label">Defina a resposta correta</label>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col form-inline">
                          <label class="form-control-label">Possui resposta correta?</label>
                          <base-switch
                            class="ml-2 mb-1"
                            v-model="flCorrect"
                          ></base-switch>
                        </div>
                      </template>
                    </div>
                    <!-- Select Multiple - Marção de resposta correta -->
                    <div class="row pb-3" v-if="flCorrect">
                      <div class="col">
                        <select
                          class="form-control"
                          placeholder="Selecione se houver..."
                          v-model="newQuestion.defined"
                          :disabled="ruTemp.length < 1"
                          multiple
                        >
                          <option
                            v-for="opt in ruTemp"
                            :key="opt.id"
                            :value="opt.value"
                          >{{ opt.text }}</option>
                        </select>
                        <small class="form-text text-muted">
                          Segure a tecla 'Ctrl' para selecionar mais de uma resposta correta.
                        </small>
                      </div>
                    </div>
                    <!-- Botão de adição da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-button
                          type="primary"
                          @click="addQuestion"
                          :disabled="ruTemp.length < 2"
                        >Adicionar</base-button>
                      </div>
                    </div>
                  </template>
                  <!-- Pergunta Likert -->
                  <template v-else-if="newQuestion.type === 'likert'">
                    <!-- Input de Entrada da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Escreva a afirmação a ser respondida" type="text" v-model="newQuestion.value">
                        </base-input>
                      </div>
                    </div>
                    <!-- Select de Templates pré-definidos -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Tipo da matriz">
                          <select class="form-control"  v-model="likertSelected">
                            <option
                              v-for="types in likertTemplates"
                              :key="types.id"
                              :value="types.value"
                            >{{ types.text }}</option>
                            <option value="personalizado">Personalizar...</option>
                          </select>
                        </base-input>
                      </div>
                    </div>
                    <!-- Input de adição de Opções -->
                    <template v-if="likertSelected === 'personalizado'">
                      <div class="row mb-2">
                        <div class="col-3 form-inline">
                          <label class="mr-2">Mais negativa: </label>
                        </div>
                        <div class="col form-inline">
                          <base-input type="text" v-model="likertDefined.userDefinied.muitonegativa">
                          </base-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-3 form-inline">
                          <label class="mr-2">Negativa: </label>
                        </div>
                        <div class="col form-inline">
                          <base-input type="text" v-model="likertDefined.userDefinied.negativa">
                          </base-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-3 form-inline">
                          <label class="mr-2">Neutra: </label>
                        </div>
                        <div class="col form-inline">
                          <base-input type="text" v-model="likertDefined.userDefinied.neutra">
                          </base-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-3 form-inline">
                          <label class="mr-2">Positiva: </label>
                        </div>
                        <div class="col form-inline">
                          <base-input type="text" v-model="likertDefined.userDefinied.positiva">
                          </base-input>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-3 form-inline">
                          <label class="mr-2">Mais positiva: </label>
                        </div>
                        <div class="col form-inline">
                          <base-input type="text" v-model="likertDefined.userDefinied.muitopositiva">
                          </base-input>
                        </div>
                      </div>
                    </template>
                    <!-- Botão de adição da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-button type="primary" @click="addQuestion">Adicionar</base-button>
                      </div>
                    </div>
                  </template>
                  <!-- Pergunta NPS -->
                  <template v-else-if="newQuestion.type === 'nps'">
                    <!-- Input de Entrada da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-input label="Escreva a pergunta ou a afirmação a ser respondida" type="text" v-model="newQuestion.value">
                        </base-input>
                      </div>
                    </div>
                    <!-- Botão de adição da pergunta -->
                    <div class="row">
                      <div class="col">
                        <base-button type="primary" @click="addQuestion">Adicionar</base-button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- Linha de apresentação das perguntas -->
          <div class="row mt-4">
            <div class="col-12">
              <p v-if="newTest.length <= 1">Existe <strong>{{ newTest.length }}</strong> pergunta definida.</p>
              <p v-else>Existem <strong>{{ newTest.length }}</strong> perguntas definidas.</p>
              <div v-for="question in newTest" :key="question.id" class="box-question">
                <!-- Mostra a pergunta com os switch de obrigatoriedade -->
                <div class="row my-2 mx-1">
                  <!-- Mostra a pergunta -->
                  <div class="col-7">
                    <label class="form-control-label">{{ question.id}} - {{question.value}}</label>
                  </div>
                  <!-- Switch de obrigatoriedade -->
                  <div class="col-2 mt-1">
                    <template v-if="newTestFinal.nm_tipo === 'auto' || newTestFinal.nm_tipo === 'tempo'">
                      <div class="col form-inline">
                        <h5>Obrigatória? - <span class="text-success">Sim</span></h5>
                      </div>
                    </template>
                    <template v-else>
                      <base-input label="Obrigatória?" class="form-inline">
                        <base-switch
                          class="ml-2"
                          v-model="question.required"
                        ></base-switch>
                      </base-input>
                    </template>
                  </div>
                  <!-- Botões - Subir, Descer, Editar e Excluir -->
                  <div class="col-3 text-right">
                    <!-- botao subir -->
                    <base-button
                      v-if="butUp(question.id)"
                      type="secondary"
                      size="sm"
                      class="btn-icon-only rounded-circle"
                      @click="moveUp(question.id)"
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-arrow-up"></i>
                      </span>
                    </base-button>
                    <!-- botao descer -->
                    <base-button
                      v-if="butDown(question.id)"
                      type="secondary"
                      size="sm"
                      class="btn-icon-only rounded-circle"
                      @click="moveDown(question.id)"
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-arrow-down"></i>
                      </span>
                    </base-button>
                    <!-- botao editar -->
                    <base-button
                      type="info"
                      size="sm"
                      class="btn-icon-only rounded-circle"
                      @click="editQuestion(question.id)"
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-pen"></i>
                      </span>
                    </base-button>
                    <!-- botao excluir -->
                    <base-button
                      type="danger"
                      size="sm"
                      class="btn-icon-only rounded-circle"
                      @click="removeQuestion(question.id)"
                    >
                      <span class="btn-inner--icon">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </base-button>
                  </div>
                </div>
                <!-- Perguntas Text -->
                <template v-if="question.type === 'text'">
                  <div class="row mt-1 mx-1">
                    <div class="col">
                      <base-input type="text" v-model="question.resp">
                      </base-input>
                    </div>
                  </div>
                </template>
                <!-- Perguntas DICO -->
                <template v-else-if="question.type === 'dico'">
                  <div class="row mb-3 mt-1 mx-1">
                    <div class="col">
                      <div class="form-check" v-for="opt in question.options" :key="opt.value">
                        <input
                          class="form-check-input"
                          type="radio"
                          :id="opt.text"
                          :value="opt.value"
                          :name="question.id"
                          v-model="question.resp"
                        />
                        <label class="form-check-label" :for="opt.text">
                          {{ opt.text }}
                        </label>
                      </div>
                      <template v-if="validaRU(question.defined, question.resp)">
                        <h5>A resposta está <strong>Correta!</strong></h5>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Perguntas RU -->
                <template v-else-if="question.type === 'ru'">
                  <div class="row mb-3 mt-1 mx-1">
                    <div class="col">
                      <div class="form-check" v-for="opt in question.options" :key="opt.value">
                        <input
                          class="form-check-input"
                          type="radio"
                          :id="opt.text"
                          :value="opt.value"
                          :name="question.id"
                          v-model="question.resp"
                        />
                        <label class="form-check-label" :for="opt.text">
                          {{ opt.text }}
                        </label>
                      </div>
                      <template v-if="validaRU(question.defined, question.resp)">
                        <h5>A resposta está <strong><span class="text-succcess">Correta!</span></strong></h5>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Perguntas Multi -->
                <template v-else-if="question.type === 'multi'">
                  <div class="row mb-3 mt-1 mx-1">
                    <div class="col">
                      <div class="form-check" v-for="opt in question.options" :key="opt.id">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="opt.value"
                          v-model="question.resp"
                          :id="opt.value"
                        />
                        <label class="form-check-label" :for="opt.text">
                          {{ opt.text }}
                        </label>
                      </div>
                      <template v-if="validaMulti(question.defined, question.resp)">
                        <h5>A resposta está <strong>Correta!</strong></h5>
                      </template>
                    </div>
                  </div>
                </template>
                <!-- Matriz Likert -->
                <template v-else-if="question.type === 'likert'">
                  <div class="row mb-3 mt-1 mx-1">
                    <div class="col">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                          <tr>
                            <th
                              scope="col"
                              class="text-center"
                              v-for="item in question.options"
                              :key="item.id"
                            >{{ item.text }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              v-for="item in question.options"
                              :key="item.id"
                              class="text-center"
                            >
                              <div class="form-check pb-3 mt--1">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  :id="item.text"
                                  :value="item.value"
                                  :name="question.id"
                                  v-model="question.resp"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
                <!-- Matriz Likert -->
                <template v-else-if="question.type === 'nps'">
                  <div class="row mb-3 mt-1 mx-1">
                    <div class="col">
                      <table class="table table-bordered">
                        <thead class="thead-light">
                          <tr>
                            <th
                              scope="col"
                              class="text-center"
                              v-for="item in nps"
                              :key="item.id"
                            >{{ item.value }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              v-for="item in nps"
                              :key="item.id"
                              class="text-center"
                            >
                              <div class="form-check pb-3 mt--1">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  :id="item.id"
                                  :value="item.value"
                                  :name="question.id"
                                  v-model="question.resp"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- APAGAR row abaixo -->
          <!-- <div class="row">
            <div class="col-12">
              <hr>
              <pre>{{newTest}}</pre>
              <pre>{{newTestFinal}}</pre>
            </div>
          </div> -->
          <div class="row mt-1 mx-1">
            <base-button type="secondary" class="ml-3" @click="resetNewTestFinal">Cancelar</base-button>
            <base-button type="primary" class="mr-3 ml-auto" @click="handleGravar">Gravar</base-button>
          </div>
        </modal>
        <!-- Modal de testes simples -->
        <modal
          :show="modal.visualizartesteSimples"
          :showClose="false"
          size="xl"
          v-loading.fullsccreen.lock="loading"
        >
          <h6 slot="header" class="modal-title">Visualização do Teste - {{ newTestFinal.nm_nome }}</h6>
            <VisualizaTesteSimples
              v-if="modal.visualizartesteSimples"
              :propNewTesteFinal="newTestFinal"
              :propNewTest="newTest"
            />
          <div slot="footer">
            <base-button type="secondary" class="ml-3" @click="fechaVisualizacao">Fechar</base-button>
          </div>
        </modal>
        <!-- Modal Geração de Teste Automático -->
        <modal
          :show="modal.gerarTeste"
          :showClose="false"
          size="lg"
          v-loading="loadingGeraTeste"
          element-loading-text="Aguarde..."
        >
          <h6 slot="header" class="modal-title">Geração Automática de Testes</h6>
          <div class="row">
            <div class="col">
              <label for="modeloTeste">O teste será aplicado para:</label>
              <input type="text" class="form-control" id="modeloTeste" placeholder="Ex. Avaliação de profissionais Programadores em PHP" v-model="geraTeste.modelo">
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label for="qtdPerguntas">Quantidade de Perguntas</label>
              <input type="number" min="1" max="10" class="form-control" id="qtdPerguntas" placeholder="10" v-model="geraTeste.qtdPerguntas">
            </div>
            <div class="col-6">
              <label for="nivelTeste">Nível do Teste</label>
              <select class="form-control" id="nivelTeste" v-model="geraTeste.nivelTeste">
                <option value="Iniciante / Júnior">Iniciante / Júnior</option>
                <option value="Intermediário / Pleno">Intermediário / Pleno</option>
                <option value="Avançado / Sênior">Avançado / Sênior</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                <strong>Dicas:</strong><br>
                <em>
                  Avaliação de profissionais Programadores em PHP<br>
                  Avaliação de profissionais em Marketing Digital<br>
                  Avaliação de profissionais Assistentes Administrativos<br>
                  ou<br>
                  Avaliação de conhecimentos em Mysql<br>
                  Avaliação de conhecimentos em Excel<br>
                  Avaliação de conhecimentos em SAP<br>
                </em>
              </p>
              <!-- <pre>{{geraTeste}}</pre> -->
              <!-- <p>Preciso de um questionário com {{geraTeste.qtdPerguntas}} perguntas de múltiplas escolha e de resposta única, para uma {{geraTeste.modelo}} de nível {{geraTeste.nivelTeste}}. Indique a resposta correta para cada pergunta.</p> -->
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <base-button
                class="ml-3 mb-4"
                type="primary"
                @click="geraTesteAuto"
                :disabled="validaGeraTesteAuto()"
              >Gerar Teste</base-button>
            </div>
          </div>
          <div slot="footer">
            <base-button type="secondary" class="ml-3" @click="modal.gerarTeste = false">Fechar</base-button>
          </div>
        </modal>
        <modal
          :show="modal.visualizarteste"
          :showClose="false"
          @close="fechaVisualizacao"
          size="xl"
          v-loading.fullsccreen.lock="loading"
        >
          <h6 slot="header" class="modal-title">Visualização do Teste - {{ newTestFinal.nm_nome }}</h6>
            <VisualizaTeste
              v-if="modal.visualizarteste"
              :propNewTesteFinal="newTestFinal"
              :propNewTest="newTest"
            />
          <div slot="footer">
            <base-button type="secondary" class="ml-3" @click="fechaVisualizacao">Fechar</base-button>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import testesPaginacao from './testesPaginacao';
import {
  Loading,
  Table,
  TableColumn,
  Select,
  Option,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import TagsInput from '@/components/Inputs/TagsInput'
import { BasePagination } from '@/components';
import moment from "moment";
import swal from 'sweetalert2';
import VisualizaTesteSimples from './VisualizaTesteSimples.vue';
import VisualizaTeste from './VisualizaTeste.vue';

Vue.use(Loading.directive);

export default {
  mixins: [defaultMatchjob, testesPaginacao],
  components: {
    BasePagination,
    BaseInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    TagsInput,
    VisualizaTeste,
    VisualizaTesteSimples
  },
  data() {
    return {
      loading: false,
      loadingGeraTeste: false,
      geraTeste: {
        modelo: "",
        qtdPerguntas: 10,
        nivelTeste: ""
      },
      geraTesteResult: null,
      //testes: [],
      testeInt: [],
      newTestFinal: {
        id_teste: "",
        id_empresa: "",
        id_empresa_user: "",
        nm_nome_user: "",
        nm_tipo: "",
        nu_tempo: "0",
        nm_nome: "",
        nm_categoria: "",
        nm_descricao: "",
        hl_url: "",
        json_questoes: {},
        json_tags: {tags: []}
      },
      newTest: [],
      newQuestion: {
        id: null,
        type: "",
        value: "",//a pergunta/texto apresentado na pergunta
        defined: '',
        options: [],
        required: true,
        //next: undefined
      },
      questionTypes: [
        { value: 'text', text: 'Texto simples', disabled: false },
        { value: 'dico', text: 'Dicotômica', disabled: false },
        { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
        { value: 'multi', text: 'Múltipla escolha', disabled: false },
        { value: 'likert', text: 'Matriz Likert', disabled: false },
        { value: 'nps', text: 'NPS', disabled: false }
      ],
      dicoSelectType: "1",
      dicoDefinedTypes: [
        { id: "1", name: 'Sim ou Não', textTrue: 'Sim', textFalse: 'Não' },
        { id: "2", name: 'Verdadeiro ou Falso', textTrue: 'Verdadeiro', textFalse: 'Falso' },
        { id: "3", name: 'Concordo ou Discordo', textTrue: 'Concordo', textFalse: 'Discordo' },
      ],
      dicoTemp: {
        textTrue: '',
        textFalse: ''
      },
      ruTemp: [],
      ruAdd: '',
      flCorrect: false,
      likertTemplates: [
        { id: 1, value: 'concordancia', text: 'Concordância' },
        { id: 2, value: 'avaliativa', text: 'Avaliativa' },
        { id: 3, value: 'frenquencia', text: 'Frenquência' },
        { id: 4, value: 'probabilidade', text: 'Probabilidade' },
        { id: 5, value: 'importancia', text: 'Importância' },
        { id: 6, value: 'satisfacao', text: 'Satisfação' },
      ],
      likertSelected: "",
      likertDefined: {
        concordancia: [
          { id: 1, value: 'Discordo Totalmente', text: 'Discordo Totalmente' },
          { id: 2, value: 'Discordo', text: 'Discordo' },
          { id: 3, value: 'Neutro', text: 'Neutro' },
          { id: 4, value: 'Concordo', text: 'Concordo' },
          { id: 5, value: 'Concordo Totalmente', text: 'Concordo Totalmente' },
        ],
        avaliativa: [
          { id: 1, value: 'Ruim', text: 'Ruim' },
          { id: 2, value: 'Razoável', text: 'Razoável' },
          { id: 3, value: 'Bom', text: 'Bom' },
          { id: 4, value: 'Muito bom', text: 'Muito bom' },
          { id: 5, value: 'Excelente', text: 'Excelente' },
        ],
        frenquencia: [
          { id: 1, value: 'Nunca', text: 'Nunca' },
          { id: 2, value: 'Raramente', text: 'Raramente' },
          { id: 3, value: 'Ocasionalmente', text: 'Ocasionalmente' },
          { id: 4, value: 'Frequente', text: 'Frequente' },
          { id: 5, value: 'Muito frequente', text: 'Muito frequente' },
        ],
        probabilidade: [
          { id: 1, value: 'Quase sempre falso', text: 'Quase sempre falso' },
          { id: 2, value: 'Geralmente falso', text: 'Geralmente falso' },
          { id: 3, value: 'Ocasionalmente verdade', text: 'Ocasionalmente verdade' },
          { id: 4, value: 'Geralmente verdade', text: 'Geralmente verdade' },
          { id: 5, value: 'Quase sempre verdade', text: 'Quase sempre verdade' },
        ],
        importancia: [
          { id: 1, value: 'Não é importante', text: 'Não é importante' },
          { id: 2, value: 'Às vezes importante', text: 'Às vezes importante' },
          { id: 3, value: 'Moderado', text: 'Moderado' },
          { id: 4, value: 'Importante', text: 'Importante' },
          { id: 5, value: 'Muito importante', text: 'Muito importante' },
        ],
        satisfacao: [
          { id: 1, value: 'Muito insatisfeito', text: 'Muito insatisfeito' },
          { id: 2, value: 'Insatisfeito', text: 'Insatisfeito' },
          { id: 3, value: 'Neutro', text: 'Neutro' },
          { id: 4, value: 'Satisfeito', text: 'Satisfeito' },
          { id: 5, value: 'Muito satisfeito', text: 'Muito satisfeito' },
        ],
        userDefinied: {
          muitonegativa: "",
          negativa: "",
          neutra: "",
          positiva: "",
          muitopositiva: ""
        }
      },
      nps: [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
      ],
      propsToSearch: ['nome', 'descricao', 'tags'],
      categoriaTestes: [
        { value: "Avaliativo" },
        { value: "Pesquisa" },
        { value: "Competências" },
        { value: "Comportamental" },
        { value: "Fit Cultural" },
        { value: "Técnico" },
      ],
      tipoTestes: [
        { value: 'simples', text: 'Simples / Pesquisa' },
        { value: 'step', text: 'Simples / Pesquisa - Step-by-Step' },
        { value: 'avaliacao', text: 'Avaliação Técnica' },
        { value: 'auto', text: 'Avaliação Técnica - Autocorreção' },
        { value: 'tempo', text: 'Aval. Téc. - Autocorreção - Temporizada' },
      ],
      modal: {
        novoteste: false,
        visualizartesteSimples: false,
        visualizarteste: false,
        gerarTeste: false
      }
    };
  },
  computed: {
    testes() {
      return this.$store.state.testes.testes;
    },
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
  },
  methods: {
    /* Funções de Geração Automáticas de Testes */
    async geraTesteAuto() {
      this.loadingGeraTeste = true;
      this.resetNewTestFinal();
      const data = {
        id_empresa: this.empresa.id_empresa,
        teste: "Preciso de um questionário com " + this.geraTeste.qtdPerguntas + " perguntas de múltiplas escolha e de resposta única, para uma " + this.geraTeste.modelo + " de nível " + this.geraTeste.nivelTeste + ". Indique a resposta correta para cada pergunta."
      }
      await this.$http
        .post('testes/gerarteste', data)
        .then(res => {
          //return res.data;
          this.newTest = this.preparaJson(res.data)
          this.newTestFinal.nm_nome = this.geraTeste.modelo;
          this.newTestFinal.nm_descricao = "Teste gerado automaticamente para " + this.geraTeste.modelo + " de nível" + this.geraTeste.nivelTeste + " com " + this.geraTeste.qtdPerguntas + "perguntas.";
          this.newTestFinal.nm_tipo = "avaliacao";
          this.newTestFinal.nm_categoria = "Avaliativo";
          this.newTestFinal.hl_url = this.defineUrl();
          this.modal.gerarTeste = false;
          setTimeout(() => {
            this.loadingGeraTeste = false;
            this.modal.novoteste = true;
          }, 200);
        }
        ).catch(err => {
          this.notificacao('Ops! Não foi possível carregar o teste.', 'danger');
          console.log('Erro na API -> ', err);
          this.loadingGeraTeste = false;
          return false;
        });
    },
    preparaJson(json) {
      var str1 = json.replace("```json", '');
      var str2 = str1.replace("```", '');
      var resJson = JSON.parse(str2);
      return resJson;
    },
    validaGeraTesteAuto() {
      var count = 0;
      if(this.geraTeste.qtdPerguntas > 0 && this.geraTeste.qtdPerguntas < 11) {
        count = count + 1;
      }
      if(this.geraTeste.nivelTeste !== "") {
        count = count + 1;
      }
      if(this.geraTeste.modelo !== "") {
        count = count + 1;
      }
      if(count === 3) {
        return false;
      } else {
        return true;
      }
    },
    async carregaTestesInt() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      this.$store.dispatch('loadTestes', data);
    },
    /*
    * Inicio das função de gravação e atualização de testes no Firebase
    *
    * ## handleGravar() ##
    *  - Função de Validação dos campos e chamada do botão 'Gravar'
    *
    * ## addTest() ##
    * Função chamada pela 'handleGravar'
    * Ajusta a variável newTestFinal
    * Chama a função para gravar 'novo' ou 'atualiza' o Teste
    *
    * ## salvaTeste() ##
    * Função de gravação de novo teste e atualização de teste existente
    *
    * ## updateTeste() ##
    * Função de atualização de teste
    */
    handleGravar(){
      if (this.newTestFinal.nm_nome === '') {
        this.alertShow("Ops!", "Você precisa definir um nome para o teste!", "error");
        return false;
      } else if (this.newTestFinal.nm_descricao === '') {
        this.alertShow("Ops!", "Você precisa definir uma descrição para o teste!", "error");
        return false;
      } else if (this.newTestFinal.nm_categoria === '') {
        this.alertShow("Ops!", "Você precisa definir uma categoria para o teste!", "error");
        return false;
      } else if (this.newTestFinal.nm_tipo === '') {
        this.alertShow("Ops!", "Você precisa definir um tipo de teste!", "error");
        return false;
      } else if (this.newTestFinal.nm_tipo === 'tempo' && this.newTestFinal.nu_tempo === '0' ) {
        this.alertShow("Ops!", "Você precisa definir o tempo correto para o teste!", "error");
        return false;
      } else if (this.newTest.length < 1) {
        this.alertShow("Ops!", "Você precisa criar as perguntas para o teste!", "error");
        return false;
      } else {
        this.addTest();
      }
    },
    async addTest() {
      this.newTestFinal.id_empresa_user = this.id.empresa_user;
      this.newTestFinal.id_empresa = this.id.empresa;
      this.newTestFinal.nm_nome_user = this.id.nome;
      this.$set(this.newTestFinal.json_questoes, 'questions', this.newTest);
      if(this.newTestFinal.id_teste === ""){
        if (this.salvaTeste()) {
          this.modal.novoteste = false;
          this.loading = true;
          setTimeout(() => {
            this.carregaTestesInt();
            this.resetNewTestFinal();
            this.notificacao('Teste gravado com sucesso!', 'success');
            this.loading = false;
          }, 500);
        } else {
          this.notificacao('Ops! Não foi possível gravar o teste.', 'danger');
          this.modal.novoteste = false;
        }
      } else {
        if (this.salvaTeste()) {
          this.modal.novoteste = false;
          this.loading = true;
          setTimeout(() => {
            this.carregaTestesInt();
            this.resetNewTestFinal();
            this.notificacao('Teste atualizado com sucesso!', 'success');
            this.loading = false;
          }, 500);
        } else {
          this.modal.novoteste = false;
          this.notificacao('Ops! Não foi possível gravar o teste.', 'danger');
        }
      }
    },
    async salvaTeste() {
      await this.$http
        .post('testes/salvar', this.newTestFinal)
        .then(() => {
          return true;
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    /*
    * Inicio das função de deleção de testes no Firebase
    *
    * ## handleDelete() ##
    * Função chamada pelo botão 'Deletar'
    * Emite um alerta (swal) e chama a função para deletar o teste no firestore
    *
    * ## apagarTeste() ##
    * Deleta o teste no Firestore
    */
    handleDelete(row) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Você está prestes a excluir o teste:<br> ${row.nm_nome}`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, excluir!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.apagarTeste(row.id_teste)){
            this.loading = true;
            setTimeout(() => {
              this.alertShow('Excluido!', `O teste: ${row.nm_nome} foi excluído!`, 'success');
              this.carregaTestesInt();
              this.loading = false;
            }, 500);
          } else {
            this.alertShow('Ops!', 'Desculpe! Houve uma falha no servidor.', 'error');
          }
        }
      });
    },
    async apagarTeste(id){
      const data = {
        id_empresa: this.id.empresa,
        id_teste: id
      }
      var result = await this.$http
        .post('testes/apagarteste', data)
        .then(res => {
          if(res.status === 200) {
            return true;
          } else {
            console.log(res)
            return false;
          }
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
      return result;
    },
    /*
    * Funções de geração de URL amigável para o teste
    *
    * ## checkUrlInt() ##
    * Verifica se a URL gerada já existe
    *
    * ## makeUrl() ##
    * Transforma o nome do teste em URL amigável
    *
    * ## defineUrl() ##
    * Chama a função de verificação de existencia e define a URL
    */
    async checkUrlInt(url) {
      const data = {
        id_empresa: this.id.empresa,
        hl_url: url
      }
      var result = await this.$http
        .post('testes/checkurl', data)
        .then(res => {
          if (res.data.length > 0) {
            if (this.newTestFinal.id_teste === res.data[0].id_teste) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
      return result;
    },
    makeUrl(valor) {
      var str = valor.toLowerCase();
      var str1 = str.replace(/[áàãâä]/gi, 'a');
      var str2 = str1.replace(/[éèêë]/gi, 'e');
      var str3 = str2.replace(/[íìîï]/gi, 'i');
      var str4 = str3.replace(/[óòõôö]/gi, 'o');
      var str5 = str4.replace(/[úùûü]/gi, 'u');
      var str6 = str5.replace(/[ç]/gi, 'c');
      var result = str6.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '');
      return result;
    },
    async defineUrl() {
      // Define a URL com base no nome do teste
      var url = this.makeUrl(this.newTestFinal.nm_nome);
      if (await this.checkUrlInt(url) === true) {
        this.newTestFinal.hl_url = url;
      } else {
        // Gera ID aleatório para a URL
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 16; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        var chance1 = url + '-' + text;
        if (await this.checkUrlInt(chance1) === true) {
          this.newTestFinal.hl_url = chance1;
        } else {
          this.newTestFinal.hl_url = url + '-' + text + '-' + this.makeUrl(this.nomeFantasia);
        }
      }
    },
    /*
    * Funções de Edição de testes existente
    *
    * ## editTest(id) ##
    * Carrega o teste selecionado nos arrays 'newTestFinal' e 'newTest'
    * Abre a modal de edição do teste
    */
    editTest(id){
      const edit = this.testes.find(item => item.id_teste === id)
      this.newTestFinal.id_teste = edit.id_teste;
      this.newTestFinal.id_empresa = edit.id_empresa;
      this.newTestFinal.id_empresa_user = edit.id_empresa_user;
      this.newTestFinal.nm_nome_user = edit.nm_nome_user;
      this.newTestFinal.nm_tipo = edit.nm_tipo;
      this.newTestFinal.nu_tempo = edit.nu_tempo;
      this.newTestFinal.nm_nome = edit.nm_nome;
      this.newTestFinal.nm_categoria = edit.nm_categoria;
      this.newTestFinal.nm_descricao = edit.nm_descricao;
      this.newTestFinal.hl_url = edit.hl_url;
      this.newTest = edit.json_questoes.questions;
      this.newTestFinal.json_tags.tags = edit.json_tags.tags;
      this.modal.novoteste = true;
      this.selectTipo(edit.tipo);
    },
    /*
    * Inicio das funções de perguntas
    *
    * ## validateQuestion() ##
    * Valida os campos das perguntas e retorna 'true' ou 'false'
    *
    * ## adjustNewQuestion() ##
    * Ajusta o array tempRes conforme o tipo da pergunta para ser incluido no array de perguntas 'newTest'
    *
    * ## addQuestion() ##
    * Função chamada pelos botões 'Adicionar' das perguntas
    * Define o ID da pergunta
    * Chama a função adjustNewQuestion()
    * Faz um 'push' no array de perguntas 'newTest'
    *
    * ## editQuestion(id) ##
    * Ajusta a pergunta para ser editada
    *
    * ## reAddQuestion() ##
    * Readiciona a pergunta na mesma posição anterior (ID original)
    *
    * ## resetNewQuestion() ##
    * Realiza um reset nos arrays utilizados na inserção/edição de perguntas
    *
    * ## removeQuestion(id) ##
    * Remove a pergunta do array 'newTest'
    *
    * ## addRuTemp() ##
    * Adiciona opções para perguntas Resposta única e Múltipla escolha
    *
    * ## delRuTemp(id) ##
    * Remove opções de perguntas Resposta única e Múltipla escolha
    */
    validateQuestion() {
      if (this.flCorrect === true && this.newQuestion.defined === '') {
        this.alertShow("Ops!", "Você precisa definir a resposta correta!", "error");
        return false;
      } else if (this.newQuestion.id !== null) {
        this.reAddQuestion();
        return false;
      } else if (this.newTest.findIndex( item => item.value === this.newQuestion.value ) > -1) {
        this.alertShow("Ops!", "Esta pergunta já foi incluída!", "error");
        return false;
      } else if (this.newQuestion.value === '') {
        this.alertShow("Ops!", "O Campo da pergunta precisa ser preenchido!", "error");
        return false;
      } else {
        return true
      }
    },
    adjustNewQuestion() {
      let tempRes = new Object;
      let arrOptions = new Array;
      if (this.newQuestion.type === 'text'){
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'dico'){
        if (this.dicoSelectType < 4) {
          const arrSelected = this.dicoDefinedTypes.filter( item => item.id === this.dicoSelectType);
          arrOptions = [
            { text: arrSelected[0].textTrue, value: arrSelected[0].textTrue },
            { text: arrSelected[0].textFalse, value: arrSelected[0].textFalse }
          ]
        } else {
          arrOptions = [
            { text: this.dicoTemp.textTrue, value: this.dicoTemp.textTrue },
            { text: this.dicoTemp.textFalse, value: this.dicoTemp.textFalse }
          ]
        }
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: arrOptions,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'ru') {
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: this.ruTemp,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'multi') {
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: this.ruTemp,
          resp: [],
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'likert'){
        if (this.likertSelected === 'concordancia') {
          arrOptions = this.likertDefined.concordancia;
        } else if (this.likertSelected === 'avaliativa') {
          arrOptions = this.likertDefined.avaliativa;
        } else if (this.likertSelected === 'frenquencia') {
          arrOptions = this.likertDefined.frenquencia;
        } else if (this.likertSelected === 'probabilidade') {
          arrOptions = this.likertDefined.probabilidade;
        } else if (this.likertSelected === 'importancia') {
          arrOptions = this.likertDefined.importancia;
        } else if (this.likertSelected === 'satisfacao') {
          arrOptions = this.likertDefined.satisfacao;
        } else if (this.likertSelected === 'personalizado') {
          arrOptions = [
            { id: 1, value: this.likertDefined.userDefinied.muitonegativa, text: this.likertDefined.userDefinied.muitonegativa },
            { id: 2, value: this.likertDefined.userDefinied.negativa, text: this.likertDefined.userDefinied.negativa },
            { id: 3, value: this.likertDefined.userDefinied.neutra, text: this.likertDefined.userDefinied.neutra },
            { id: 4, value: this.likertDefined.userDefinied.positiva, text: this.likertDefined.userDefinied.positiva },
            { id: 5, value: this.likertDefined.userDefinied.muitopositiva, text: this.likertDefined.userDefinied.muitopositiva },
          ]
        }
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          defined: this.newQuestion.defined,
          options: arrOptions,
          resp: '',
          required: this.newQuestion.required,
        }
      } else if (this.newQuestion.type === 'nps'){
        tempRes = {
          id: this.newQuestion.id,
          type: this.newQuestion.type,
          value: this.newQuestion.value,
          resp: '',
          required: this.newQuestion.required,
        }
      }
      return tempRes;
    },
    addQuestion() {
      if (this.validateQuestion()) {
        if (this.newTest.length > 0) {
          const lastItem = this.newTest.pop();
          let id = lastItem.id;
          this.newTest.push(lastItem);
          this.newQuestion.id = ++id;
        } else {
          this.newQuestion.id = 1;
        }
        let tempRes = this.adjustNewQuestion();
        this.newTest.push(tempRes);
        this.resetNewQuestion();
      }
    },
    editQuestion(id){
      var res = this.newTest.find( item => item.id === id);
      this.newQuestion.id = res.id;
      this.newQuestion.defined = res.defined;
      this.newQuestion.resp = res.resp;
      this.newQuestion.type = res.type;
      this.newQuestion.value = res.value;
      this.ruTemp = res.options;
      this.goto('perguntas');
      //this.modal.novoteste = true;
      //console.log('posicao recebido ->> ', res)
    },
    reAddQuestion() {
      var pos = this.newTest.findIndex( item => item.id === this.newQuestion.id);
      let tempRes = this.adjustNewQuestion();
      this.newTest.splice(pos, 1, tempRes);
      this.resetNewQuestion();
      this.notificacao('Pergunta atualizada!', 'success')
    },
    resetNewQuestion() {
      this.newQuestion.id = null;
      this.newQuestion.type = "";
      this.newQuestion.value = "";
      this.newQuestion.defined = "";
      this.newQuestion.options = [];
      this.newQuestion.required = true,
      this.dicoTemp.textTrue = "";
      this.dicoTemp.textFalse = "";
      this.ruTemp = [];
      this.flCorrect = this.newTestFinal.tipo === 'auto' ||this.newTestFinal.tipo === 'tempo' ? true : false;
      this.likertSelected = "";
      this.likertDefined.userDefinied.muitonegativa = "";
      this.likertDefined.userDefinied.negativa = "";
      this.likertDefined.userDefinied.neutra = "";
      this.likertDefined.userDefinied.positiva = "";
      this.likertDefined.userDefinied.muitopositiva = "";
    },
    removeQuestion(id) {
      var pos = this.newTest.findIndex( item => item.id === id);
      this.newTest.splice(pos, 1);
    },
    addRuTemp() {
      if (this.ruAdd === '') {
        this.alertShow("Ops!", "Você precisa definir um valor!", "error");
        return false;
      } else if (this.ruTemp.findIndex( item => item.value === this.ruAdd ) > -1) {
        this.alertShow("Ops!", "Esta opção já foi incluída!", "error");
        return false;
      }
      var size = this.ruTemp.length;
      var arr = { id: ++size, text: this.ruAdd, value: this.ruAdd }
      this.ruTemp.push(arr);
      this.ruAdd = "";
    },
    delRuTemp(id) {
      var pos = this.ruTemp.findIndex( item => item.id === id);
      this.ruTemp.splice(pos, 1);
    },
    /*
    * Funções de visualização de testes
    *
    * ## visualizarTest(id) ##
    * Carrega o teste selecionado nos arrays 'newTestFinal' e 'newTest'
    * Abre a modal de visualização do teste conforme o tipo do teste
    *
    * ## fechaVisualizacao() ##
    * Chama a função de reset dos array 'newTestFinal' e 'newTest'
    * Fecha a modal de visualização
    *
    * ## resetNewTestFinal() ##
    * Realiza um reset nos arrays utilizados na inserção/edição/visualização de testes
    */
    visualizarTest(id){
      const edit = this.testes.find(item => item.id_teste === id)
      this.newTestFinal.id_teste = edit.id_teste;
      this.newTestFinal.id_empresa = edit.id_empresa;
      this.newTestFinal.id_empresa_user = edit.id_empresa_user;
      this.newTestFinal.nm_nome_user = edit.nm_nome_user;
      this.newTestFinal.nm_tipo = edit.nm_tipo;
      this.newTestFinal.nu_tempo = edit.nu_tempo;
      this.newTestFinal.nm_nome = edit.nm_nome;
      this.newTestFinal.nm_categoria = edit.nm_categoria;
      this.newTestFinal.nm_descricao = edit.nm_descricao;
      this.newTestFinal.hl_url = edit.hl_url;
      this.newTest = edit.json_questoes.questions;
      this.newTestFinal.json_tags.tags = edit.json_tags.tags;
      if (edit.nm_tipo === 'simples'){
        this.modal.visualizartesteSimples = true;
      } else {
        this.modal.visualizarteste = true;
      }
    },
    fechaVisualizacao() {
      this.resetNewTestFinal();
      this.modal.visualizarteste = false;
      this.modal.visualizartesteSimples = false;
    },
    resetNewTestFinal() {
      this.newTestFinal.id_teste = "";
      this.newTestFinal.nm_nome = "";
      this.newTestFinal.nm_descricao = "";
      this.newTestFinal.nm_tipo = "";
      this.newTestFinal.nm_categoria = "";
      this.newTestFinal.hl_url = "";
      this.newTestFinal.nu_tempo = "0";
      this.newTestFinal.json_tags.tags = [];
      this.$delete(this.newTestFinal.json_questoes, 'questions');
      this.newTest = [];
      this.modal.novoteste = false;
    },
     //Função do Autocomplete de Categorias de testes
    querySearchCategoria(queryCategoria, cb) {
      let categoria = this.categoriaTestes;
      var results = queryCategoria ? categoria.filter(this.createFilterCategoria(queryCategoria)) : categoria;
      cb(results)
    },
    createFilterCategoria(queryCategoria) {
      return (categoria) => {
        return (categoria.value.toLowerCase().indexOf(queryCategoria.toLowerCase()) === 0);
      };
    },
    //Define as perguntas liberadas por tipo de teste
    selectTipo(value){
      if (value === 'avaliacao') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: false },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: true },
          { value: 'nps', text: 'NPS', disabled: true }
        ];
      } else if (value === 'auto' || value === 'tempo') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: true },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: true },
          { value: 'nps', text: 'NPS', disabled: true }
        ];
        this.flCorrect = true;
      } else if (value === 'simples' || value === 'step') {
        this.questionTypes = [
          { value: 'text', text: 'Texto simples', disabled: false },
          { value: 'dico', text: 'Dicotômica', disabled: false },
          { value: 'ru', text: 'Resposta única - Múltiplas opções', disabled: false },
          { value: 'multi', text: 'Múltipla escolha', disabled: false },
          { value: 'likert', text: 'Matriz Likert', disabled: false },
          { value: 'nps', text: 'NPS', disabled: false }
        ];
      }
    },
    /*
    * Funções da visualização das perguntas no inserção/edição de testes
    *
    * ## butUp(id) ##
    * Verifica se existe pergunta anterior e mostra o botão para subir
    *
    * ## butDown(id) ##
    * Verifica se existe pergunta posterior e mostra o botão para descer
    *
    * ## moveDown(id) ##
    * Desce a posição da pergunta selecionada
    *
    * ## moveUp(id) ##
    * Sobe a posição da pergunta selecionada
    *
    * ## validaMulti(defined, resp) ##
    * Mostra se a resposta esstá correta em perguntas Múltipla escolha
    * -> Só apresenta se marcada a opção correta
    *
    * ## validaRU(defined, resp) ##
    * Mostra se a resposta esstá correta em perguntas Resposta Única
    * -> Só apresenta se marcada a opção correta
    */
    butUp(id){
      var pos = this.newTest.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      //var pos = this.newTest.findIndex( item => item.id === id);
      if (this.newTest.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.newTest.findIndex( item => item.id === idNow);
      var pos1 = this.newTest.findIndex( item => item.id === idNext);
      var arr0 = this.newTest.find( item => item.id === idNow);
      var arr1 = this.newTest.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.newTest.splice(pos0, 1, arr1);
      this.newTest.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.newTest.findIndex( item => item.id === idNow);
      var pos1 = this.newTest.findIndex( item => item.id === idNext);
      var arr0 = this.newTest.find( item => item.id === idNow);
      var arr1 = this.newTest.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.newTest.splice(pos0, 1, arr1);
      this.newTest.splice(pos1, 1, arr0);
    },
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
    /*
    * Funções utilizadas na lista (El-Table) de testes carregados
    *
    * defineTipoTabela() -> Mostra o nome do Tipo do Teste
    * handleCommand() -> Rebece o click dos botões da tabela (Visualizar, Editar e Excluir) e chama as respectivas funções
    */
    defineTipoTabela(row, column){
      var tipo = row[column.property];
      var res = this.tipoTestes.find(i => i.value === tipo);
      return res.text;
    },
    handleCommand(command) {
      if (command[0] === 'visualizar'){
        this.visualizarTest(command[1])
      } else if (command[0] === 'editar') {
        this.editTest(command[1]);
      } else if (command[0] === 'excluir') {
        this.handleDelete(command[1]);
      }
    },
    /*
    * Funções gerais sistêmicas
    *
    * ## alertShow(title, msgError, icon) ##
    * Emite um alerta SweetAlert (swal)
    *
    * ## notificacao(msg, tipo) ##
    * Apresenta uma notificação Toasted
    *
    * ## goto(refName) ##
    * Função de auto-scroll da tela passando a referência do ID da DIV
    */
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  filters: {
    /* formatDateUnixTime: function(value) {
      if (value) {
        return moment.unix(value.seconds).format("DD/MM/YYYY");
      }
    }, */
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
      }
    }
  },
  mounted() {
    //this.carregaTestesInt();
  },
};
</script>
<style>
  .inuptAutocomplete {
    width: 100%;
    height: 40px;
  }
  .removeButton {
    cursor: pointer;
  }
  .icone-help-bread-crumb {
    color: #FFF;
    font-size: 1.6rem;
    cursor: pointer;
  }
  .box-question {
    box-sizing: border-box;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    margin: 10px 5px;
    padding: 5px 5px 5px;
  }
</style>
