<template>
  <div>
    <!-- Ativo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco estará ativo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTimeConfig.ativo"
        ></base-switch>
      </div>
    </div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTimeConfig.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Título"
          v-model.lazy="propTimeConfig.titulo"
        />
      </div>
    </div>
    <!-- Subtitulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Subtítulo:</label>
        <input
          id="subtitulo"
          ref="subtitulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Subtítulo"
          placeholder="Subtítulo"
          v-model.lazy="propTimeConfig.subtitulo"
        />
      </div>
    </div>
    <!-- Tipo / ID do componente -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Modelo do bloco:</label>
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-4 align-self-end text-center">
        <el-tooltip effect="light" placement="top">
          <div slot="content">Funciona com fundo branco ou imagem<br>Basta remover ou definir uma imagem para testar</div>
          <img :src="`https://storagematchjobdefault.blob.core.windows.net/matchjob/equipe-component-1-bg-${propTimeConfig.background === '' ? 'white' : 'image'}.PNG`" class="img-fluid my-2" alt="Bloco 1">
        </el-tooltip>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="1"
            id="radioTipoEquipe1" name="flTipoEquipe"
            v-model="propTimeConfig.compId"
          />
          <label class="form-check-label" for="radioTipoEquipe1">Bloco 1</label>
        </div>
      </div>
      <div class="col-4 align-self-end text-center">
        <el-tooltip effect="light" placement="top">
          <div slot="content">Funciona apenas com imagem de fundo definida</div>
          <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/equipe-component-2-bg-image.PNG" class="img-fluid my-2" alt="Bloco 2">
        </el-tooltip>
        <template  v-if="propTimeConfig.background === ''">
          <el-tooltip effect="light" placement="bottom">
            <div slot="content">Defina uma imagem de fundo para habilitar este bloco</div>
            <div class="form-check">
              <input class="form-check-input" type="radio" value="2"
                id="radioTipoEquipe2" name="flTipoEquipe"
                v-model="propTimeConfig.compId"
                :disabled="propTimeConfig.background === ''"
              />
              <label class="form-check-label" for="radioTipoEquipe2">Bloco 2</label>
            </div>
          </el-tooltip>
        </template>
        <template v-else>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="2"
              id="radioTipoEquipe2" name="flTipoEquipe"
              v-model="propTimeConfig.compId"
              :disabled="propTimeConfig.background === ''"
            />
            <label class="form-check-label" for="radioTipoEquipe2">Bloco 2</label>
          </div>
        </template>
      </div>
      <div class="col-4 align-self-end text-center">
        <el-tooltip effect="light" placement="top">
          <div slot="content">Funciona com fundo branco ou imagem<br>Basta remover ou definir uma imagem para testar</div>
          <img :src="`https://storagematchjobdefault.blob.core.windows.net/matchjob/equipe-component-3-bg-${propTimeConfig.background === '' ? 'white' : 'image'}.PNG`" class="img-fluid my-2" alt="Bloco 3">
        </el-tooltip>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="3"
            id="radioTipoEquipe3" name="flTipoEquipe"
            v-model="propTimeConfig.compId"
          />
          <label class="form-check-label" for="radioTipoEquipe3">Bloco 3</label>
        </div>
      </div>
    </div>
    <!-- Pessoas / Equipe -->
    <!-- Botões de adicionar -->
    <div class="row justify-content-center mt-2 mb-3">
      <div class="col align-self-center">
        <label for="subtitulo">Pessoas da Equipe:</label><br>
        <base-button
          type="primary"
          size="sm"
          @click="handleImpotarUsuarios"
        >Importar dos Usuários</base-button>
        <base-button
            type="secondary"
            size="sm"
            @click="handleAddManual"
          >Adicionar Manualmente</base-button>
      </div>
    </div>
    <!-- Linhas de pessoas -->
    <div class="row justify-content-center my-2 box-pessoa" v-for="pessoa in propTimeConfig.time" :key="pessoa.id">
      <div class="col-2 align-self-center p-0">
        <img :src="pessoa.hl_foto" class="img-fluid rounded" alt="Foto de perfil">
      </div>
      <div class="col align-self-center">
        <p class="h5">
          <span class="text-muted">Nome:</span> {{pessoa.nome}}<br>
          <span class="text-muted">Cargo:</span> {{pessoa.cargo}}<br>
          <span class="text-muted">Descrição:</span> {{pessoa.descricao}}<br>
          <span class="text-muted">Redes Sociais: </span>
          <el-tooltip effect="light" placement="top" v-if="pessoa.site_pessoal !== ''">
            <div slot="content">Site Pessoal<br>{{pessoa.site_pessoal}}</div>
            <a :href="pessoa.site_pessoal" target="_blank"><i class="fas fa-globe mx-1"></i></a>
          </el-tooltip>
          <el-tooltip effect="light" placement="top" v-if="pessoa.twitter !== ''">
            <div slot="content">Twitter<br>{{pessoa.twitter}}</div>
            <a :href="pessoa.twitter" target="_blank"><i class="fab fa-twitter mx-1"></i></a>
          </el-tooltip>
          <el-tooltip effect="light" placement="top" v-if="pessoa.instagram !== ''">
            <div slot="content">Instagram<br>{{pessoa.instagram}}</div>
            <a :href="pessoa.instagram" target="_blank"><i class="fab fa-instagram mx-1"></i></a>
          </el-tooltip>
          <el-tooltip effect="light" placement="top" v-if="pessoa.facebook !== ''">
            <div slot="content">Facebook<br>{{pessoa.facebook}}</div>
            <a :href="pessoa.facebook" target="_blank"><i class="fab fa-facebook-square mx-1"></i></a>
          </el-tooltip>
          <el-tooltip effect="light" placement="top" v-if="pessoa.linkedin !== ''">
            <div slot="content">LinkedIn<br>{{pessoa.linkedin}}</div>
            <a :href="pessoa.linkedin" target="_blank"><i class="fab fa-linkedin mx-1"></i></a>
          </el-tooltip>
          <el-tooltip effect="light" placement="top" v-if="pessoa.youtube !== ''">
            <div slot="content">Youtube<br>{{pessoa.youtube}}</div>
            <a :href="pessoa.youtube" target="_blank"><i class="fab fa-youtube mx-1"></i></a>
          </el-tooltip>
        </p>
      </div>
      <!-- Coluna dos botões de ação -->
      <div class="col-2 align-self-center">
        <div class="row">
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butUp(pessoa.id)"
          @click="moveUp(pessoa.id)"
        ><i class="fas fa-arrow-up"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butDown(pessoa.id)"
          @click="moveDown(pessoa.id)"
        ><i class="fas fa-arrow-down"></i>
        </base-button>
        </div>
        <div class="row">
        <base-button
          @click="handleEditarPessoa(pessoa)"
          class="edit btn-link mr-1 my-1"
          type="info"
          size="sm"
          icon
        ><i class="text-white fas fa-pen"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="danger"
          size="sm"
          icon
          @click="handleDelete(pessoa)"
        ><i class="text-white fas fa-trash-alt"></i>
        </base-button>
        </div>

      </div>
    </div>
    <!-- Fundo do bloco -->
    <div class="row justify-content-center my-2" v-if="propTimeConfig.background !== ''">
      <div class="col align-self-center">
        <label for="subtitulo">Fundo do bloco:</label>
        <img :src="propTimeConfig.background" class="img-fluid img-thumbnail my-2" alt="Fundo do bloco">
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <base-button
          type="primary"
          size="sm"
          @click="modal.mudarFundo = true"
        >Alterar Fundo</base-button>
        <base-button
            type="warning"
            size="sm"
            v-if="propTimeConfig.compId !== '2'"
            @click="propTimeConfig.background = ''"
          >Fundo Branco</base-button>
      </div>
    </div>
    <!-- Modal do background -->
    <modal :show="modal.mudarFundo" :showClose="true" @close="modal.mudarFundo = false" size="xl" v-loading="imgChange">
      <h6 slot="header" class="modal-title">Alterar background</h6>
      <tabs pills tabNavWrapperClasses="mb-3">
        <tab-pane active id="galeria">
          <template slot="label">
            <i class="ni ni-collection"></i> Da Galeria
          </template>
          <div class="row justify-content-center my-2">
            <div class="col-2 align-self-center" v-if="propTimeConfig.background !== ''">
              <figure class="figure">
                <img :src="propTimeConfig.background" class="figure-img img-fluid my-2" alt="Fundo atual">
                <figcaption class="figure-caption text-center">
                  <a href="#void" class="stretched-link" @click="defineFundo(propTimeConfig.background)">Fundo atual</a>
                </figcaption>
              </figure>
            </div>
            <div class="col-2 align-self-center" v-for="item in galeriaFundos" :key="item.id">
              <figure class="figure">
                <img :src="item.url" class="figure-img img-fluid my-2" :alt="`Fundo ${item.id}`">
                <figcaption class="figure-caption text-center">
                  <a href="#void" class="stretched-link" @click="defineFundo(item.url)">Fundo {{item.id}}</a>
                </figcaption>
              </figure>
            </div>
          </div>
        </tab-pane>
        <tab-pane active id="personalizado">
          <template slot="label">
            <i class="ni ni-image"></i> Personalizado
          </template>
          <form class="needs-validation" enctype="multipart/form-data">
            <!-- Fundo Atual -->
            <div class="row justify-content-center mt-3">
              <div class="col-8">
                <label for="fundo">Fundo Atual</label><br>
                <img :src="novaFoto === '' ? propTimeConfig.background : novaFoto" class="img-fluid my-2" alt="Logo"><br>
              </div>
              <div class="col-4">
                <label for="fundo">Novo fundo</label><br>
                <template v-if="imageError !== ''">
                  <p><small class="text-danger">{{imageError}}</small></p>
                </template>
                <div class="custom-file mt-3">
                  <label class="custom-file-label" for="customFileLang">
                    {{logo}}
                  </label>
                  <input type="file" class="custom-file-input" accept="image/png, image/jpeg" @change="onChangeFundo($event)" />
                </div>
                <!-- <base-button type="primary" class="my-3">Atualizar</base-button> -->
              </div>
            </div>
          </form>
        </tab-pane>
      </tabs>
      <template slot="footer">
      <div class="row justify-content-center my-2">
        <div class="col align-self-center">
          <base-button
            type="default"
            @click="modal.mudarFundo = false"
          >Fechar</base-button>
        </div>
      </div>
      </template>
    </modal>
    <!-- Modal de edição / novo usuário -->
    <modal
      :show="modal.adicionarManual"
      :showClose="true"
      bodyClasses="py-1"
      @close="modal.adicionarManual = false"
      size="lg"
      v-loading="loading.adicionarManual">
      <h6 slot="header" class="modal-title">Editar</h6>
      <div class="row justify-content-center">
        <!-- Foto Atual -->
        <div class="col-4 align-self-center text-center">
          <img :src="novaFoto === '' ? editarPessoa.hl_foto : novaFoto" class="img-fluid rounded" alt="Fotode perfil">
        </div>
        <div class="col align-self-center">
          <!-- <div class="row">
            <div class="col">
              <form class="needs-validation" enctype="multipart/form-data">
                <label for="foto">Enviar nova foto</label><br>
                <template v-if="imageError !== ''">
                  <p><small class="text-danger">{{imageError}}</small></p>
                </template>
                <div class="custom-file">
                  <label class="custom-file-label" for="customFileLang">
                    {{logo}}
                  </label>
                  <input type="file" class="custom-file-input" accept="image/png, image/jpeg" @change="onChangeFotoPessoa($event)" />
                </div>
              </form>
            </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <span class="text-muted">ou</span>
            </div>
          </div> -->
          <div class="row">
            <div class="col">
              <label for="foto">Informe o link da foto:</label>
              <input
                id="foto"
                ref="foto"
                type="url"
                maxlength="100"
                class="form-control"
                name="Título"
                placeholder="Link da Foto"
                v-model.lazy="editarPessoa.hl_foto"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-2">
        <!-- Nome -->
        <div class="col-4 align-self-center my-1">
          <label for="nome">Nome:</label>
          <input
            id="nome"
            ref="nome"
            type="text"
            maxlength="100"
            class="form-control"
            name="Nome"
            placeholder="Nome da pessoa"
            v-model.lazy="editarPessoa.nome"
          />
        </div>
        <!-- Cargo -->
        <div class="col-4 align-self-center my-1">
          <label for="cargo">Cargo:</label>
          <input
            id="cargo"
            ref="cargo"
            type="text"
            maxlength="100"
            class="form-control"
            name="Cargo"
            placeholder="Cargo da pessoa"
            v-model.lazy="editarPessoa.cargo"
          />
        </div>
        <!-- Descrição -->
        <div class="col-4 align-self-center my-1">
          <label for="descricao">Descrição:</label>
          <input
            id="descricao"
            ref="descricao"
            type="text"
            maxlength="100"
            class="form-control"
            name="Descrição"
            placeholder="Breve descrição da pessoa"
            v-model.lazy="editarPessoa.descricao"
          />
        </div>
        <!-- Site pessoal -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fas fa-globe"></i> Site pessoal:</label>
          <input
            id="hl_site"
            ref="hl_site"
            type="url"
            maxlength="100"
            class="form-control"
            name="Site empresa"
            placeholder="https://...."
            v-model.lazy="editarPessoa.site_pessoal"
          />
        </div>
        <!-- Twitter -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fab fa-twitter"></i> Twitter:</label>
          <input
            id="twitter"
            ref="twitter"
            type="url"
            maxlength="100"
            class="form-control"
            name="Twitter"
            placeholder="https://...."
            v-model.lazy="editarPessoa.twitter"
          />
        </div>
        <!-- Instagram -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fab fa-instagram"></i> Instagram:</label>
          <input
            id="instagram"
            ref="instagram"
            type="url"
            maxlength="100"
            class="form-control"
            name="Instagram"
            placeholder="https://...."
            v-model.lazy="editarPessoa.instagram"
          />
        </div>
        <!-- Facebook -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fab fa-facebook-square"></i> Facebook:</label>
          <input
            id="facebook"
            ref="facebook"
            type="url"
            maxlength="100"
            class="form-control"
            name="Facebook"
            placeholder="https://...."
            v-model.lazy="editarPessoa.facebook"
          />
        </div>
        <!-- Linkedin -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fab fa-linkedin"></i> Linkedin:</label>
          <input
            id="linkedin"
            ref="linkedin"
            type="url"
            maxlength="100"
            class="form-control"
            name="Linkedin"
            placeholder="https://...."
            v-model.lazy="editarPessoa.linkedin"
          />
        </div>
        <!-- Youtube -->
        <div class="col-4 align-self-center my-1">
          <label for="hl_site"><i class="fab fa-youtube"></i> Youtube:</label>
          <input
            id="youtube"
            ref="youtube"
            type="url"
            maxlength="100"
            class="form-control"
            name="Youtube"
            placeholder="https://...."
            v-model.lazy="editarPessoa.youtube"
          />
        </div>
      </div>
      <div class="row justify-content-between my-3">
        <div class="col-2 align-self-center">
          <base-button
            type="default"
            @click="modal.adicionarManual = false"
          >Fechar</base-button>
        </div>
        <div class="col-2 align-self-center text-right">
          <base-button
            type="primary"
            @click="handleSalvarPessoa"
          >Salvar</base-button>
        </div>
      </div>
    </modal>
    <!-- Modal de importação usuário -->
    <modal
      :show="modal.importarUsuarios"
      :showClose="true"
      @close="modal.importarUsuarios = false"
      size="lg"
      v-loading="loading.importarUsuarios">
      <h6 slot="header" class="modal-title">Importar Usuários</h6>
      <div class="row justify-content-center">
        <div class="col align-self-center">
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="form-check form-check-inline">
              <base-input v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Procurar...">
              </base-input>
              <base-button
                class="ml-3 mb-4"
                type="primary"
                :disabled="usuarioImportados.length < 1"
                @click="importarUsuarios"
              >Importar ({{usuarioImportados.length}}) Usuário</base-button>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="queriedData"
            row-key="id_empresa_user"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @sort-change="sortChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column width="100" label="#" align="center">
              <div class="avatar rounded-circle" slot-scope="{ row }">
                <img :src="row.hl_foto" alt="Table image" />
              </div>
            </el-table-column>
            <el-table-column min-width="200" sortable prop="nm_nome" label="Nome">
            </el-table-column>
            <el-table-column min-width="200" sortable prop="nm_cargo" label="Cargo">
            </el-table-column>
          </el-table>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} de {{ to }} em {{ total }} usuarios.
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </div>
      </div>
      <template slot="footer">
      <div class="row justify-content-center my-2">
        <div class="col align-self-center">
          <base-button
            type="default"
            @click="modal.importarUsuarios = false"
          >Fechar</base-button>
        </div>
      </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import defaultMatchjob from '@/mixins/defaultMatchjob';
import clientPaginationMixin from '@/views/Usuarios/clientPaginationMixin';
import { Table, TableColumn, Select, Option, Checkbox, Loading } from 'element-ui';
import Tabs from '@/components/Tabs/Tabs.vue';
import TabPane from '@/components/Tabs/Tab.vue';
import { BasePagination } from '@/components';
import swal from 'sweetalert2';

Vue.use(Loading.directive);
export default {
  name: "TimeConfig",
  mixins: [defaultMatchjob, clientPaginationMixin],
  props:{
    propTimeConfig: Object
  },
  components:{
    Tabs,
    TabPane,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox
  },
  data() {
    return {
      imgChange: false,
      loading: {
        importarUsuarios: false,
        adicionarManual: false
      },
      modal: {
        mudarFundo: false,
        importarUsuarios: false,
        adicionarManual: false
      },
      galeriaFundos: [
        { id: "1", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/austin.jpg" },
        { id: "2", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg1.jpg" },
        { id: "3", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg24.jpg" },
        { id: "4", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg26.jpg" },
        { id: "5", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg27.jpg" },
        { id: "6", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg28.jpg" },
        { id: "7", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg29.jpg" },
        { id: "8", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg30.jpg" },
        { id: "9", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg37.jpg" },
        { id: "10", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg38.jpg" },
        { id: "11", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg39.jpg" },
        { id: "12", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/broofoac.jpg" },
        { id: "13", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/city-profile.jpg" },
        { id: "14", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/clark-street-merc.jpg" },
        { id: "15", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/clark-street-mercantile.jpg" },
        { id: "16", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/georgie.jpg" },
        { id: "17", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/markus-spiske-187777.jpg" },
        { id: "18", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/nasa.jpg" },
        { id: "19", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/office5.jpg" },
        { id: "20", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/photo-15.jpg" },
        { id: "21", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/profile_city.jpg" },
        { id: "22", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project6.jpg" },
        { id: "23", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project14.jpg" },
        { id: "24", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/unsplashs.jpg" }
      ],
      logo: "Selecione um arquivo...",
      novaFotoFile: "",
      novaFoto: "",
      imageError: "",
      novaPessoa: {
        id: "",
        nome: "",
        cargo: "",
        hl_foto: "https://storagematchjobdefault.blob.core.windows.net/matchjob/placeholder.jpg",
        twitter: "",
        youtube: "",
        facebook: "",
        linkedin: "",
        descricao: "",
        instagram: "",
        site_pessoal: ""
      },
      editarPessoa: {},
      usuarios: [],
      usuarioImportados: []
    }
  },
  methods: {
    defineFundo(url) {
      this.propTimeConfig.background = url;
      this.modal.mudarFundo = false;
    },
    async onChangeFundo(event) {
      this.imgChange = true;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.logo = "";
        this.novaFoto = "";
        this.novaFotoFile = "";
        this.imageError = "A imagem escolhida é maior que 1 MB. Ajuste ou troque a imagem"
      } else {
        this.logo = file.name;
        this.novaFotoFile = file;
        const form = new FormData();
        form.append("id_empresa", this.id.empresa);
        form.append("fundo", this.novaFotoFile);
        await this.$http.post('empresa/uploadfundoequipe', form)
          .then(res => {
            this.propTimeConfig.background = res.data;
          })
          .catch(err => {
            console.log(err);
          });
        this.imageError = "";
        this.novaFoto = URL.createObjectURL(file);
      }
      this.imgChange = false;
    },/*
    async onChangeFotoPessoa(event) {
      this.imgChange = true;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.logo = "";
        this.novaFoto = "";
        this.novaFotoFile = "";
        this.imageError = "A imagem escolhida é maior que 1 MB. Ajuste ou troque a imagem"
      } else {
        this.logo = file.name;
        this.novaFotoFile = file;
        const form = new FormData();
        const nome = this.defineUrl();
        form.append("id_empresa", this.id.empresa);
        form.append("fundo", this.novaFotoFile);
        form.append("nome", nome);
        var url = ""
        await this.$http.post('empresa/uploadfotopessoaequipe', form)
          .then(res => {
            this.propTimeConfig.background = res.data;
            url = res.data;
          })
          .catch(err => {
            console.log(err);
          });
        this.imageError = "";
        this.novaFoto = URL.createObjectURL(file);
      }
      this.imgChange = false;
      return url;
    }, */
    async carregaUsuarios() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin,
        fl_tipo_acesso: this.id.tipoAcesso
      }
      await this.$http.post('usuarios', data).then(res => {
        this.usuarios = res.data.usuarios;
      })
    },
    handleImpotarUsuarios() {
      this.modal.importarUsuarios = true;
      this.loading.importarUsuarios = true;
      if (this.usuarios.length < 1) {
        this.carregaUsuarios();
      }
      setTimeout(() => {
        this.loading.importarUsuarios = false;
      }, 1000);
    },
    importarUsuarios() {
      this.loading.importarUsuarios = true;
      this.usuarioImportados.forEach(element => {
        let ultimoId = this.propTimeConfig.time.length;
        let nextId = ++ultimoId;
        let novaPessoa = {
          id: `${nextId}`,
          nome: element.nm_nome,
          cargo: element.nm_cargo,
          hl_foto: element.hl_foto,
          twitter: "",
          youtube: "",
          facebook: "",
          linkedin: "",
          descricao: "",
          instagram: "",
          site_pessoal: ""
        }
        this.propTimeConfig.time.push(novaPessoa);
      });
      setTimeout(() => {
        this.$refs.multipleTable.clearSelection();
        this.modal.importarUsuarios = false;
        this.loading.importarUsuarios = false;
      }, 500);
    },
    handleSelectionChange(val) {
      this.usuarioImportados = val;
    },
    handleEditarPessoa(pessoa) {
      this.editarPessoa = {}
      _.merge(this.editarPessoa, pessoa);
      this.modal.adicionarManual = true;
    },
    handleAddManual() {
      this.editarPessoa = {}
      _.merge(this.editarPessoa, this.novaPessoa);
      this.modal.adicionarManual = true;
    },
    handleSalvarPessoa() {
      if (this.editarPessoa.id === '') {
        this.addManual();
      } else {
        this.atualizaDadosPessoa();
      }
    },
    atualizaDadosPessoa() {
      this.loading.adicionarManual = true;
      var pos = this.propTimeConfig.time.findIndex( item => item.id === this.editarPessoa.id);
      this.propTimeConfig.time.splice(pos, 1, this.editarPessoa);
      setTimeout(() => {
        this.modal.adicionarManual = false;
        this.loading.adicionarManual = false;
        this.editarPessoa = {}
      }, 300);
    },
    addManual() {
      if (this.propTimeConfig.time.length > 0) {
        const lastItem = this.propTimeConfig.time.pop();
        let id = lastItem.id;
        this.propTimeConfig.time.push(lastItem);
        this.editarPessoa.id = ++id;
        this.editarPessoa.id = this.editarPessoa.id.toString();
      } else {
        this.editarPessoa.id = "1";
      }
      this.propTimeConfig.time.push(this.editarPessoa);
      setTimeout(() => {
        this.modal.adicionarManual = false;
        this.loading.adicionarManual = false;
        this.editarPessoa = {}
      }, 300);
    },
    handleDelete(pessoa) {
      var pos = this.propTimeConfig.time.findIndex( item => item.id === pessoa.id);
      var tam = this.propTimeConfig.time.length;
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja remover esse usuário?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          if (tam === (pos + 1)){
            this.propTimeConfig.time.splice(pos, 1);
          } else {
            for (var i = pos+1; i < tam; i++){
              this.propTimeConfig.time[i].id = this.propTimeConfig.time[i].id-1;
              this.propTimeConfig.time[i].id = this.propTimeConfig.time[i].id.toString();
            }
            this.propTimeConfig.time.splice(pos, 1);
          }
        }
      });
    },
    butUp(id){
      var pos = this.propTimeConfig.time.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      id = parseInt(id, 10)
      if (this.propTimeConfig.time.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      idNow = idNow.toString();
      var idNext = ++id;
      idNext = idNext.toString();
      var pos0 = this.propTimeConfig.time.findIndex( item => item.id === idNow);
      var pos1 = this.propTimeConfig.time.findIndex( item => item.id === idNext);
      var arr0 = this.propTimeConfig.time.find( item => item.id === idNow);
      var arr1 = this.propTimeConfig.time.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propTimeConfig.time.splice(pos0, 1, arr1);
      this.propTimeConfig.time.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      idNow = idNow.toString();
      var idNext = --id;
      idNext = idNext.toString();
      var pos0 = this.propTimeConfig.time.findIndex( item => item.id === idNow);
      var pos1 = this.propTimeConfig.time.findIndex( item => item.id === idNext);
      var arr0 = this.propTimeConfig.time.find( item => item.id === idNow);
      var arr1 = this.propTimeConfig.time.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propTimeConfig.time.splice(pos0, 1, arr1);
      this.propTimeConfig.time.splice(pos1, 1, arr0);
    },
    makeUrl(valor) {
      var str = valor.toLowerCase();
      var str1 = str.replace(/[áàãâä]/gi, 'a');
      var str2 = str1.replace(/[éèêë]/gi, 'e');
      var str3 = str2.replace(/[íìîï]/gi, 'i');
      var str4 = str3.replace(/[óòõôö]/gi, 'o');
      var str5 = str4.replace(/[úùûü]/gi, 'u');
      var str6 = str5.replace(/[ç]/gi, 'c');
      var result = str6.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '');
      return result;
    },
    defineUrl() {
      if (this.editarPessoa.nome !== '') {
        var url = this.makeUrl(this.editarPessoa.nome);
        return url;
      } else {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 16; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
      }
    },
  }
}
</script>

<style>
.box-pessoa {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
