<template>
  <div>
    <div class="row justify-content-around">
      <div class="col-3"></div>
      <div class="col-3 pt-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Vale Transporte"
            v-model="vaga.beneficios"
            id="vt"
          />
          <label class="form-check-label" for="vt">Vale Transporte</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Vale Refeição"
            v-model="vaga.beneficios"
            id="vr"
          />
          <label class="form-check-label" for="vr">Vale Refeição</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Vale Alimentação"
            v-model="vaga.beneficios"
            id="va"
          />
          <label class="form-check-label" for="va">Vale Alimentação</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Seguro de Vida"
            v-model="vaga.beneficios"
            id="sv"
          />
          <label class="form-check-label" for="sv">Seguro de Vida</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Restaurante no Local"
            v-model="vaga.beneficios"
            id="rl"
          />
          <label class="form-check-label" for="rl">Restaurante no Local</label>
        </div>
      </div>
      <div class="col-3 pt-4">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Assistência Médica"
            v-model="vaga.beneficios"
            id="am"
          />
          <label class="form-check-label" for="am">Assistência Médica</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Assistência Odontológica"
            v-model="vaga.beneficios"
            id="ao"
          />
          <label class="form-check-label" for="ao">Assistência Odontológica</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Participação nos Lucros"
            v-model="vaga.beneficios"
            id="pl"
          />
          <label class="form-check-label" for="pl">Participação nos Lucros</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Auxílio Creche"
            v-model="vaga.beneficios"
            id="ac"
          />
          <label class="form-check-label" for="ac">Auxílio Creche</label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value="Estacionamento no local"
            v-model="vaga.beneficios"
            id="el"
          />
          <label class="form-check-label" for="el">Estacionamento no local</label>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-6 text-center">
        <div class="form-inline">
          <label class="mr-2">Adicione outros benefícios: </label>
          <input type="text" class="form-control mr-2" v-model.trim="addItem" />
          <base-button type="default" @click="addBeneficio" :disabled="addItem === ''">Add</base-button>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-4">
      <div class="col-6 text-center">
        <p>Benefícios adicionados: {{ vaga.beneficios.length }}</p>
        <div class="row">
          <div class="col-* my-2 mx-2" v-for="opt in vaga.beneficios" :key="opt">{{ opt }} -
            <base-button size="sm" type="secondary" @click="delBeneficio(opt)">
              <i class="fas fa-ban"></i>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeneficiosComp",
  props: {
    propVaga: { type: Array },
    propEmit: { type: Boolean }
  },
  data() {
    return {
      validated: false,
      addItem: "",
      vaga: {
        beneficios: []
      },
    }
  },
  methods: {
    firstFormSubmit() {
      this.$emit('resStep5', this.vaga);
    },
    initValues() {
      setTimeout(() => {
        this.vaga.beneficios = this.propVaga;
      }, 500)
    },
    addBeneficio() {
      if (this.addItem === '') {
        alert("Ops! Você precisa definir um benefício!");
        return false;
      } else if (this.vaga.beneficios.findIndex( item => item === this.addItem ) > -1) {
        alert("Ops! Esta opção já foi incluída!");
        return false;
      }
      this.vaga.beneficios.push(this.addItem);
      this.addItem = "";
    },
    delBeneficio(value) {
      var pos = this.vaga.beneficios.findIndex( item => item === value);
      this.vaga.beneficios.splice(pos, 1);
    }
  },
  mounted() {
    this.initValues();
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
