<template>
  <div>
    <!-- Ativo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco estará ativo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTextoSimples.ativo"
        ></base-switch>
      </div>
    </div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTextoSimples.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Nome da Empresa"
          v-model.lazy="propTextoSimples.titulo"
        />
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Texto:</label>
        <vue-editor
          v-model.lazy="propTextoSimples.conteudo" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "TextoSimples",
  props:{
    propTextoSimples: Object
  },
  components: {
    VueEditor
  },
  data() {
    return {
      conteudo: ""
    }
  },
  methods: {
    concluiEdicao() {
      console.log('saiu do Quill')
      this.propTextoSimples.conteudo = this.conteudo;
    },
    onSelectionChange(range) {
      console.log('onSelectionChange ->>>>', )
    }
  }
}
</script>

<style>

</style>
