<template>
  <div>
    <!-- Ativo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco estará ativo?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTestemunhosConfig.ativo"
        ></base-switch>
      </div>
    </div>
    <!-- Mostra no menu -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label>Este bloco deve ser um link na Barra de Menu?</label>
      </div>
      <div class="col-2 align-self-center">
        <base-switch
          class="ml-3"
          v-model="propTestemunhosConfig.menu"
        ></base-switch>
      </div>
    </div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Título"
          v-model.lazy="propTestemunhosConfig.titulo"
        />
      </div>
    </div>
    <!-- Tipo / ID do componente -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Modelo do bloco:</label>
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/testemunhos-component-1.PNG" class="img-fluid my-2" alt="Bloco 1">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="1"
            id="radioTipoTestemunhos1" name="flTipoTestemunho"
            v-model="propTestemunhosConfig.compId"
          />
          <label class="form-check-label" for="radioTipoTestemunhos1">Bloco 1</label>
        </div>
      </div>
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/testemunhos-component-2.PNG" class="img-fluid my-2" alt="Bloco 2">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="2"
            id="radioTipoTestemunhos2" name="flTipoTestemunho"
            v-model="propTestemunhosConfig.compId"
          />
          <label class="form-check-label" for="radioTipoTestemunhos2">Bloco 1</label>
        </div>
      </div>
    </div>
    <!-- Botao de adicionar -->
    <div class="row justify-content-center my-4">
      <div class="col align-self-center">
        <label for="subtitulo">Testemunhos:</label><br>
        <base-button
          type="primary"
          size="sm"
          @click="handleAddTestemunho"
        >Adicionar Testemunho</base-button>
      </div>
    </div>
    <!-- Linhas dos testemunhos -->
    <div class="row justify-content-center mt-3 mb-2 border-bottom" v-for="testemunho in propTestemunhosConfig.testemunhos" :key="testemunho.id">
      <div class="col-2 align-self-center">
        <img :src="testemunho.hl_foto" class="img-fluid" alt="Foto de perfil">
      </div>
      <div class="col align-self-center">
        <h5>
          <span class="text-muted">Nome:</span> {{testemunho.nome}}<br>
          <span class="text-muted">Link: </span> {{testemunho.cargo}}<br>
          <span class="text-muted">Texto:</span> <div v-html="testemunho.testemunho.substring(0, 100)"></div>
        </h5>
      </div>
      <!-- Coluna dos botões de ação -->
      <div class="col-2 align-self-center">
        <div class="row">
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butUp(testemunho.id)"
          @click="moveUp(testemunho.id)"
        ><i class="fas fa-arrow-up"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="secondary"
          size="sm"
          icon
          v-if="butDown(testemunho.id)"
          @click="moveDown(testemunho.id)"
        ><i class="fas fa-arrow-down"></i>
        </base-button>
        </div>
        <div class="row">
        <base-button
          @click="handleEditarTestemunho(testemunho)"
          class="edit btn-link mr-1 my-1"
          type="info"
          size="sm"
          icon
        ><i class="text-white fas fa-pen"></i>
        </base-button>
        <base-button
          class="remove btn-link mr-1 my-1"
          type="danger"
          size="sm"
          icon
          @click="handleDelete(testemunho)"
        ><i class="text-white fas fa-trash-alt"></i>
        </base-button>
        </div>
      </div>
    </div>
    <!-- Modal de edição / novo usuário -->
    <modal
      :show="modal.testemunho"
      :showClose="true"
      bodyClasses="py-1"
      @close="modal.testemunho = false"
      size="lg"
      v-loading="loading">
      <h6 slot="header" class="modal-title">Adicionar / Editar testemunho</h6>
      <div class="row justify-content-center">
        <!-- Foto Atual -->
        <div class="col-4 align-self-center text-center">
          <img :src="editarTestemunho.hl_foto" class="img-fluid" alt="Foto de perfil">
        </div>
        <div class="col align-self-center">
          <label for="foto">Informe o link da imagem do colaborador:</label>
          <input
            id="foto"
            ref="foto"
            type="url"
            maxlength="100"
            class="form-control"
            name="Link da Imagem"
            placeholder="http://..."
            v-model.lazy="editarTestemunho.hl_foto"
          />
        </div>
      </div>
      <!-- nome -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <label for="nome">Nome:</label>
          <input
            id="nome"
            ref="nome"
            type="text"
            maxlength="100"
            class="form-control"
            name="nome"
            placeholder="Nome do colaborador"
            v-model.lazy="editarTestemunho.nome"
          />
        </div>
      </div>
      <!-- cargo -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <label for="cargo">Cargo:</label>
          <input
            id="cargo"
            ref="cargo"
            type="text"
            maxlength="100"
            class="form-control"
            name="Cargo"
            placeholder="Cargo do colaborador"
            v-model.lazy="editarTestemunho.cargo"
          />
        </div>
      </div>
      <!-- Conteúdo -->
      <div class="row justify-content-center my-2">
        <div class="col align-self-center my-1">
          <vue-editor v-model.lazy="editarTestemunho.testemunho"/>
        </div>
      </div>
      <!-- Botões -->
      <div class="row justify-content-between my-3">
        <div class="col-2 align-self-center">
          <base-button
            type="default"
            @click="modal.testemunho = false"
          >Fechar</base-button>
        </div>
        <div class="col-2 align-self-center text-right">
          <base-button
            type="primary"
            @click="handleSalvarTestemunho"
          >Salvar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import { Loading } from 'element-ui';
import swal from 'sweetalert2';
import { VueEditor } from "vue2-editor";
Vue.use(Loading.directive);
export default {
  name: "TestemunhosConfig",
  props: {
    propTestemunhosConfig: Object
  },
  components: {
    VueEditor
  },
  data() {
    return {
      loading: false,
      modal: {
        testemunho: false
      },
      editarTestemunho: {},
      novoTestemunho: {
        id: "",
        nome: "",
        cargo: "",
        hl_foto: "",
        testemunho: ""
      }
    }
  },
  methods: {
    handleDelete(testemunho) {
      var pos = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === testemunho.id);
      var tam = this.propTestemunhosConfig.testemunhos.length;
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja remover esse testemunho?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          if (tam === (pos + 1)){
            this.propTestemunhosConfig.testemunhos.splice(pos, 1);
          } else {
            for (var i = pos+1; i < tam; i++){
              this.propTestemunhosConfig.testemunhos[i].id = this.propTestemunhosConfig.testemunhos[i].id-1;
              this.propTestemunhosConfig.testemunhos[i].id = this.propTestemunhosConfig.testemunhos[i].id.toString();
            }
            this.propTestemunhosConfig.testemunhos.splice(pos, 1);
          }
        }
      });
    },
    handleEditarTestemunho(testemunho) {
      this.editarTestemunho = {}
      _.merge(this.editarTestemunho, testemunho);
      this.modal.testemunho = true;
    },
    handleAddTestemunho() {
      this.editarTestemunho = {}
      _.merge(this.editarTestemunho, this.novoTestemunho);
      this.modal.testemunho = true;
    },
    handleSalvarTestemunho() {
      if (this.editarTestemunho.id === '') {
        this.addTestemunho();
      } else {
        this.atualizaTestemunho();
      }
    },
    atualizaTestemunho() {
      this.loading = true;
      var pos = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === this.editarTestemunho.id);
      this.propTestemunhosConfig.testemunhos.splice(pos, 1, this.editarTestemunho);
      setTimeout(() => {
        this.modal.testemunho = false;
        this.loading = false;
        this.editarTestemunho = {}
      }, 300);
    },
    addTestemunho() {
      this.loading = true;
      if (this.propTestemunhosConfig.testemunhos.length > 0) {
        const lastItem = this.propTestemunhosConfig.testemunhos.pop();
        let id = lastItem.id;
        this.propTestemunhosConfig.testemunhos.push(lastItem);
        this.editarTestemunho.id = ++id;
        this.editarTestemunho.id = this.editarTestemunho.id.toString();
      } else {
        this.editarTestemunho.id = "1";
      }
      this.propTestemunhosConfig.testemunhos.push(this.editarTestemunho);
      setTimeout(() => {
        this.modal.testemunho = false;
        this.loading = false;
        this.editarTestemunho = {}
      }, 300);
    },
    butUp(id){
      var pos = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      id = parseInt(id, 10)
      if (this.propTestemunhosConfig.testemunhos.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      idNext = idNext.toString();
      var pos0 = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === idNow);
      var pos1 = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === idNext);
      var arr0 = this.propTestemunhosConfig.testemunhos.find( item => item.id === idNow);
      var arr1 = this.propTestemunhosConfig.testemunhos.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propTestemunhosConfig.testemunhos.splice(pos0, 1, arr1);
      this.propTestemunhosConfig.testemunhos.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      idNext = idNext.toString();
      var pos0 = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === idNow);
      var pos1 = this.propTestemunhosConfig.testemunhos.findIndex( item => item.id === idNext);
      var arr0 = this.propTestemunhosConfig.testemunhos.find( item => item.id === idNow);
      var arr1 = this.propTestemunhosConfig.testemunhos.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propTestemunhosConfig.testemunhos.splice(pos0, 1, arr1);
      this.propTestemunhosConfig.testemunhos.splice(pos1, 1, arr0);
    },
  }
}
</script>

<style>

</style>
