<template>
  <ul class="list-group list-group-flush list my--3">
    <template v-for="(user, index) in usuarios">
    <li class="list-group-item px-0"
      :key="user.id_empresa_user"
      v-if="index < 5"
    >
      <div class="row align-items-center">
        <div class="col-auto">
          <!-- Avatar -->
          <a href="#" class="avatar rounded-circle">
            <img alt="Image placeholder" :src="user.hl_foto">
          </a>
        </div>
        <div class="col ml--2">
          <h4 class="mb-0">
            <el-tooltip
              content="Usuário Administrador"
              placement="top"
            >
              <i class="text-info fas fa-certificate" v-if="user.fl_admin === '1'"> </i>
            </el-tooltip>
            <a href="#"> {{user.nm_nome}}</a><br><small class="text-muted">{{ user.nm_cargo}}</small>
          </h4>
          <i class="ni ni-email-83"></i>
          <small> {{user.hl_email}}</small>
        </div>
      </div>
    </li>
    </template>
  </ul>
</template>
<script>

export default {
  name: 'user-list',
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    }
  }
};
</script>
<style></style>
