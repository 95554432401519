var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card',{staticClass:"no-border-card"},[_c('template',{slot:"header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Lista de Usuários")]),_c('p',{staticClass:"text-sm mb-0"},[_vm._v(" "+_vm._s(_vm.total)+" usuários cadastrados na MatchJob Empregos. ")])]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),_c('div',{staticClass:"form-check form-check-inline"},[_c('base-input',{attrs:{"prepend-icon":"fas fa-search","placeholder":"Procurar..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('el-table',{attrs:{"data":_vm.queriedData,"row-key":"id_empresa_user","header-row-class-name":"thead-light"},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"width":"100","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"avatar rounded-circle"},[_c('img',{attrs:{"src":row.hl_foto,"alt":"Table image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"200","sortable":"","prop":"nm_nome","label":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('strong',[_vm._v(_vm._s(row.nm_nome))]),_c('br'),_vm._v(" "+_vm._s(row.hl_email)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"200","sortable":"","prop":"nm_depto","label":"Departamento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.nm_cargo)),_c('br'),_vm._v(" "+_vm._s(row.nm_depto)+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"fl_tipo_acesso","label":"Tipo de Acesso","header-align":"center","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('badge',{staticClass:"text-dark",attrs:{"rounded":"","type":_vm.badgeStatusType(row.fl_tipo_acesso),"size":"lg"}},[_vm._v(" "+_vm._s(_vm.badgeStatus(row.fl_tipo_acesso)))])],1)}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"fl_status","label":"Status","header-align":"center","align":"center","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.fl_status > '2')?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Usuário Desabilitado!","placement":"top"}},[_c('i',{staticClass:"icone-ativo fas fa-ban"})]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Usuário Ativo!","placement":"top"}},[_c('i',{staticClass:"icone-desabilitado far fa-check-circle"})])],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Mostrando "+_vm._s(_vm.from + 1)+" de "+_vm._s(_vm.to)+" em "+_vm._s(_vm.total)+" usuarios.")])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }