<template>
  <div>
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
        @submit.prevent="handleSubmit(firstFormSubmit)">
        <div class="row justify-content-center">
          <div class="col-4">
            <label for="empresa">Empresa Contratante <span class="text-danger">*</span></label>
            <input
              ref="empresa"
              id="empresa"
              name="Empresa Contratante"
              placeholder="Empresa Contratante"
              class="form-control"
              v-model.trim="vaga.nm_empresa"
              @blur="validaOnBlur('empresa')"
            />
            <small class="invalid-feedback" style="display: block;" v-if="validate.empresa">O campo "Empresa Contratante" é obrigatório.</small>
          </div>
          <div class="col-4 pt-4">
            <label for="areaatuacao">Deseja ocultar o nome da empresa?</label><br>
            <base-switch
              v-model="vaga.fl_oculta_empresa"
            ></base-switch>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="form-group">
            <label for="local">Local da Vaga <span class="text-danger">*</span></label>
            <el-select
              ref="local"
              name="Local da Vaga"
              class="inuptAutocomplete"
              id="local"
              v-model="vaga.id_empresa_ender"
              @input="handleSelect"
              @blur="validaOnBlur('local')"
            >
              <el-option
                v-for="ender in enderecos"
                :key="ender.id_empresa_ender"
                :value="ender.id_empresa_ender"
                :label="ender.nm_nome_endereco"
              ></el-option>
            </el-select>
            <small class="invalid-feedback" style="display: block;" v-if="validate.local">O campo "Local da Vaga" é obrigatório.</small>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <!-- Input Cidade -> Desabilitado -->
          <div class="col-4">
            <label for="nivel">Cidade <span class="text-danger">*</span></label>
            <base-input
              name="Cidade"
              placeholder="Cidade"
              rules="required"
              v-model.trim="vaga.nm_cidade"
              disabled>
            </base-input>
          </div>
          <div class="col-4">
            <div class="form-group">
            <label for="modo">Modo de trabalho <span class="text-danger">*</span></label>
            <el-select
              ref="modo"
              name="Modo de trabalho"
              class="inuptAutocomplete"
              id="modo"
              v-model="vaga.fl_tipo_trabalho"
              @blur="validaOnBlur('modo')"
            >
              <el-option
                v-for="modo in modoTrabalho"
                :key="modo.value"
                :value="modo.value"
                :label="modo.text"
              ></el-option>
            </el-select>
            <small class="invalid-feedback" style="display: block;" v-if="validate.modo">O campo "Local da Vaga" é obrigatório.</small>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { Select, Option, Autocomplete } from 'element-ui';

export default {
  name: "LocalFormaTrabalho",
  props: {
    propVaga: { type: Object },
    propEnderecos: { type: Array },
    propEmit: { type: Boolean }
  },
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      validated: false,
      vaga: {
        fl_oculta_empresa: false,
        nm_empresa: '',
        fl_tipo_trabalho: '',
        id_empresa_ender: '',
        nm_cidade: '',
        nm_endereco: '',
        enderecos: [],
      },
      enderecoSelecionado: '',
      enderecos: [],
      modoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Hibrido" },
        { value: "3", text: "HomeOffice" },
      ],
      validate: {
        empresa: false,
        local: false,
        modo: false
      }
    }
  },
  methods: {
    firstFormSubmit() {
      if (this.validaCampos()){
        this.$emit('resStep4', this.vaga);
      } else {
        this.$emit('resStep4', false);
      }
    },
    validaCampos() {
      if ( this.vaga.nm_empresa === '') {
        this.validate.empresa = true;
        this.$refs.empresa.focus();
        return false;
      } else if ( this.vaga.id_empresa_ender === '') {
        this.validate.local = true;
        this.$refs.local.focus();
        return false;
      } else if ( this.vaga.fl_tipo_trabalho === '') {
        this.validate.modo = true;
        this.$refs.modo.focus();
        return false;
      } else {
        return true;
      }
    },
    initValues() {
      setTimeout(() => {
        this.vaga.fl_oculta_empresa = this.propVaga.json_vaga.fl_oculta_empresa;
        this.vaga.nm_empresa = this.propVaga.json_vaga.nm_empresa;
        this.vaga.fl_tipo_trabalho = this.propVaga.json_vaga.fl_tipo_trabalho;
        this.vaga.id_empresa_ender = this.propVaga.json_vaga.id_empresa_ender;
        this.vaga.nm_cidade = this.propVaga.json_vaga.nm_cidade;
        this.vaga.nm_endereco = this.propVaga.json_vaga.nm_endereco;
        this.enderecos = this.propEnderecos;
      }, 500);
    },
    handleSelect(value) {
      //console.log(value);
      if (value === 'novo'){
        console.log('novo endereco -> ')
      } else {
        let tipo = this.enderecos.find(i => i.id_empresa_ender === value);
        this.vaga.id_empresa_ender = tipo.id_empresa_ender;
        this.vaga.nm_cidade = tipo.nm_cidade + " - " + tipo.nm_estado;
        var str = tipo.nm_endereco + ", " + tipo.nu_numero + " - " + tipo.nm_bairro + ", " + tipo.nm_cidade + " - " + tipo.nm_estado + ", " + tipo.nu_cep;
        this.vaga.nm_endereco = str.replace(/\s+/g, '+').toLowerCase();
      }
    },
    validaOnBlur(refName){
      setTimeout(() => {
        if (this.$refs[refName].value === '') {
          this.$set(this.validate, refName, true);
          return false;
        } else {
          this.$set(this.validate, refName, false);
          return true;
        }
      }, 300);
    },
  },
  mounted() {
    this.initValues();
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
