<template>
  <div v-loading="loading">
    <div class="row" ref="topo" >
      <div class="col">
        Nome do teste: <strong>{{ propNewTesteFinal.nm_nome }}</strong><br>
        <small>Descrição: <em>{{ propNewTesteFinal.nm_descricao }}</em></small>
      </div>
      <div class="col mr-auto">
        Tipo do teste: <strong>{{ retornaNomeTipoTeste(propNewTesteFinal.nm_tipo) }}</strong><br>
        <template class="col" v-if="propNewTesteFinal.nm_tipo === 'tempo'">
          Tempo de teste: <small><strong>{{ propNewTesteFinal.nu_tempo }} minuto{{ parseInt(propNewTesteFinal.nu_tempo) > 1 ? 's' : '' }}</strong></small><br>
        </template>
        <small>Categoria: <em>{{ propNewTesteFinal.nm_categoria }}</em></small>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><hr></div>
    </div>
    <div v-if="!flRespondido">
      <div v-loading="loadingPergunta">
        <div v-if="propNewTesteFinal.nm_tipo === 'tempo'">
          <div class="row align-items-center box-tempo" v-show="perguntaAtual < 1">
            <div class="col-12 text-center">
              <h1 class="display-3">Este teste é temporizado.</h1>
              <h1 class="display-5">Você terá "{{propNewTesteFinal.nu_tempo}}" minutos para realizar o teste.</h1>
              <base-button type="success" @click="initTemporizado">Iniciar</base-button>
            </div>
          </div>
          <div class="row" v-show="perguntaAtual > 0">
            <div class="col-12 text-center">
              <h1 class="display-6">{{ temporizador | formatTemporizador }}</h1>
            </div>
          </div>
        </div>
        <div v-for="test in propNewTest" :key="test.id" :ref="test.id">
          <div v-show="test.id === perguntaAtual">
            <template v-if="test.type === 'text'">
              <TextoSimples
                :propValue="test.value"
                :propRequired="test.required"
                @resp="test.resp = $event"
              />
            </template>
            <template v-if="test.type === 'dico' || test.type === 'ru'">
              <RespostaUnica
                :propValue="test.value"
                :propRequired="test.required"
                :propOptions="test.options"
                @resp="test.resp = $event"
              />
            </template>
            <template v-if="test.type === 'multi'">
              <MultiplaEscolha
                :propValue="test.value"
                :propRequired="test.required"
                :propOptions="test.options"
                @resp="test.resp = $event"
              />
            </template>
            <template v-if="test.type === 'likert'">
              <MatrizLikertNps
                :propValue="test.value"
                :propRequired="test.required"
                :propOptions="test.options"
                @resp="test.resp = $event"
              />
            </template>
            <template v-if="test.type === 'nps'">
              <MatrizLikertNps
                :propValue="test.value"
                :propRequired="test.required"
                :propOptions="nps"
                @resp="test.resp = $event"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-5" v-show="perguntaAtual > 0">
        <div class="col text-center">
          <base-button type="secondary" class="mr-3" v-if="perguntaAtual > 1" @click="voltarPergunta">Voltar</base-button>
          <base-button type="primary" @click="submitQuestionario">Enviar</base-button>
        </div>
      </div>
    </div>
    <div v-else>
      <ApresentaResultados
        v-if="!resultadoSimples"
        :propPerguntas="propNewTest"
      />
      <ApresentaResultadosSimples
        v-if="resultadoSimples"
        :propPerguntas="propNewTest"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'element-ui';
import moment from "moment";
import TextoSimples from '../../components/Testes/TextoSimples.vue';
import RespostaUnica from '../../components/Testes/RespostaUnica.vue';
import MultiplaEscolha from '../../components/Testes/MultiplaEscolha.vue';
import MatrizLikertNps from '../../components/Testes/MatrizLikertNps.vue';
import ApresentaResultados from '../../components/Testes/ApresentaResultados.vue';
import ApresentaResultadosSimples from '../../components/Testes/ApresentaResultadosSimples.vue';

Vue.use(Loading.directive);

export default {
  name: "VisualizaTeste",
  components: {
    TextoSimples,
    RespostaUnica,
    MultiplaEscolha,
    MatrizLikertNps,
    ApresentaResultados,
    ApresentaResultadosSimples,
  },
  props: {
    propNewTesteFinal: { type: Object },
    propNewTest: { type: Array }
  },
  data() {
    return {
      loading: false,
      loadingPergunta: false,
      flRespondido: false,
      perguntaAtual: 1,
      resultadoSimples: false,
      temporizador: 0,
      tipoTestes: [
        { value: 'simples', text: 'Simples' },
        { value: 'step', text: 'Simples - Step-by-Step' },
        { value: 'avaliacao', text: 'Avaliação Técnica' },
        { value: 'auto', text: 'Avaliação Técnica - Autocorreção' },
        { value: 'tempo', text: 'Aval. Téc. - Autocorreção - Temporizada' },
      ],
      nps: [
        { id: 1, value: 1, text: '1' },
        { id: 2, value: 2, text: '2' },
        { id: 3, value: 3, text: '3' },
        { id: 4, value: 4, text: '4' },
        { id: 5, value: 5, text: '5' },
        { id: 6, value: 6, text: '6' },
        { id: 7, value: 7, text: '7' },
        { id: 8, value: 8, text: '8' },
        { id: 9, value: 9, text: '9' },
        { id: 10, value: 10, text: '10' },
      ],
    }
  },
  methods: {
    initPage(){
      this.loading = true;
      if (this.propNewTesteFinal.nm_tipo === 'tempo') {
        this.perguntaAtual = 0;
      }
      setTimeout(() => {
        this.goto('topo');
        this.loading = false;
      }, 500);
    },
    initTemporizado() {
      this.loading = true;
      setTimeout(() => {
        this.perguntaAtual = 1;
        this.temporizador = (this.propNewTesteFinal.nu_tempo * 60) * 1000;
        this.loading = false;
      }, 500);
    },
    retornaNomeTipoTeste(value){
      var res = this.tipoTestes.find(i => i.value === value);
      return res.text;
    },
    validaRespostas() {
      const res = this.propNewTest.every(teste => {
        if (teste.required){
          if (teste.type === 'multi'){
            if (teste.resp.length < 1) {
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              this.goto(teste.id);
              return false;
            } else {
              return true;
            }
          } else {
            if (teste.resp === '') {
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              this.goto(teste.id);
              return false;
            } else {
              return true;
            }
          }
        } else {
          return true;
        }
      });
      return res;
    },
    voltarPergunta() {
      this.loadingPergunta = true;
      setTimeout(() => {
        this.perguntaAtual -= 1;
        this.loadingPergunta = false;
      }, 500);
    },
    submitQuestionario(){
      if (this.propNewTest.length > this.perguntaAtual) {
        this.loadingPergunta = true;
        var teste = this.propNewTest.find( item => item.id === this.perguntaAtual);
        if (teste.required){
          if (teste.type === 'multi'){
            if (teste.resp.length < 1) {
              this.loadingPergunta = false;
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              return false;
            } else {
              this.loadingPergunta = true;
              setTimeout(() => {
                this.perguntaAtual += 1;
                this.loadingPergunta = false;
              }, 500);
            }
          } else {
            if (teste.resp === '') {
              this.loadingPergunta = false;
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              return false;
            } else {
              this.loadingPergunta = true;
              setTimeout(() => {
                this.perguntaAtual += 1;
                this.loadingPergunta = false;
              }, 500);
            }
          }
        } else {
          this.loadingPergunta = true;
          setTimeout(() => {
            this.perguntaAtual += 1;
            this.loadingPergunta = false;
          }, 500);
        }
      } else {
        if (this.validaRespostas()){
          this.loading = true;
          if (this.propNewTesteFinal.tipo === 'step'){
            this.resultadoSimples = true
          }
          setTimeout(() => {
            this.goto('topo');
            this.loading = false;
            this.flRespondido = true;
          }, 1000);
        }
      }
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  filters: {
    formatDateUnixTime: function(value) {
      if (value) {
        return moment.unix(value.seconds).format("DD/MM/YYYY");
      }
    },
    formatTemporizador: function(value) {
      if (value) {
        return moment(value).format("mm:ss");
      }
    }
  },
  mounted() {
    this.initPage();
  },
  watch: {
    temporizador: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.temporizador -= 1000;
          }, 1000);
        } else {
          this.loading = true;
          setTimeout(() => {
            this.goto('topo');
            this.loading = false;
            this.flRespondido = true;
          }, 5000);
        }
      }
      //immediate: true // This ensures the watcher is triggered upon creation
    }
  }
}
</script>

<style>
.box-tempo {
  min-height: 300px;
}

</style>
