<template>
  <div v-if="dataLoaded">
    <div class="row align-items-start">
      <div class="col-6">
        <div class="row">
          <div class="col-9 align-self-end">
            <label>Selecione um teste para adicionar</label>
            <el-select
              class="inuptAutocomplete"
              v-model="addTeste"
              placeholder="Tipo de acesso"
            >
              <el-option
                v-for="teste in testes"
                :key="teste.id_teste"
                :label="teste.nm_nome"
                :value="teste.id_teste">
              </el-option>
            </el-select>
          </div>
          <div class="col-2 align-self-end">
            <base-button type="default" class="btn-icon-only" @click="addNovoTeste"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
      </div>
      <div class="col-6" v-if="faseTestes.testesPadrao.length > 0">
        <div class="row my-2 align-items-center border-bottom" v-for="(teste, index) in testesDefinidos" :key="index">
          <div class="col-10 align-middle">
            Nome do Teste: <strong>{{teste.nm_nome}}</strong> ({{teste.nm_categoria}})<br>
            Descrição: <em>{{teste.nm_descricao}}</em><br>
          </div>
          <div class="col-auto align-middle">
            <base-button
              type="danger"
              size="sm"
              class="btn-icon-only"
              @click="removerTeste(teste.id_teste)"
            ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
            </base-button>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="row">
      <div class="col">
        faseTestes -> <pre>{{faseTestes}}</pre>
      </div>
      <div class="col-6">
        testes -> <pre>{{testes}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "CompEnvioDeTestes",
  props: {
    propFase: { type: Object },
    propEmit: { type: Boolean }
  },
  data() {
    return {
      dataLoaded: false,
      addTeste: "",
      faseTestes: {},
      testesDefinidos: []
    }
  },
  computed: {
    testes() {
      return this.$store.state.testes.testes;
    }
  },
  methods: {
    removerTeste(value) {
      var pos = this.faseTestes.testesPadrao.findIndex(i => i.id_teste === value);
      this.faseTestes.testesPadrao.splice(pos, 1);//Remove o Objeto do Array
      var pos1 = this.testesDefinidos.findIndex(i => i.id_teste === value);
      this.testesDefinidos.splice(pos1, 1);//Remove o Objeto do Array
    },
    addNovoTeste() {
      if(this.addTeste !== '') {
        if(this.validaTesteJaAdicionado()) {
          var teste = this.testes.find(t => t.id_teste === this.addTeste);
          let tmpArr = { id_teste: teste.id_teste }
          this.faseTestes.testesPadrao.push(tmpArr);
          this.testesDefinidos.push(teste);
          setTimeout(() => {
            this.addTeste = ""
          }, 500);
        } else {
          alert("Esse teste já está adicionado!")
        }
      } else {
        alert("Você precisa selecionar um teste para adicionar.")
      }
    },
    validaTesteJaAdicionado() {
      if(this.faseTestes.testesPadrao.length > 0) {
        var existe = this.faseTestes.testesPadrao.find(t => t.id_teste === this.addTeste);
        if(existe !== undefined) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    firstFormSubmit() {
      this.$emit('emitEnviaTestes', this.faseTestes);
    }
  },
  mounted() {
    this.faseTestes = structuredClone(this.propFase);
    if(this.faseTestes.testesPadrao.length > 0) {
      this.faseTestes.testesPadrao.forEach(element => {
        var teste = this.testes.find(t => t.id_teste === element.id_teste)
        this.testesDefinidos.push(teste);
      });
    }
    setTimeout(() => {
      this.dataLoaded = true
    }, 500);
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
