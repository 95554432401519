<template>
  <div class="content">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div v-loading.fullsccreen.lock="loading">
        <card>
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Configurações Gerais</h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
              </div>
            </div>
          </template>
          <div class="card-body px-0 py-0">
            <tabs pills fill tabNavWrapperClasses="mb-3">
              <tab-pane active id="principal">
                <template slot="label">
                  <i class="ni ni-settings"></i> Dados de Cadastro
                </template>
                <ConfiguracoesGerais
                  v-if="Object.keys(empresa).length > 0"
                  :propConfiguracoesGerais="empresa"
                />
              </tab-pane>
              <tab-pane id="plano">
                <template slot="label">
                  <i class="ni ni-settings-gear-65"></i> Plano & Financeiro
                </template>
                <PlanoFinanceiro
                  v-if="Object.keys(plano).length > 0"
                  :propPlano="plano"
                />
              </tab-pane>
              <tab-pane id="fluxos">
                <template slot="label">
                  <i class="ni ni-vector"></i> Processo Seletivo
                </template>
                <ProcessoSelecao
                  v-if="Object.keys(empresa).length > 0"
                  :propUsuarios="usuarios"
                  :propPlano="plano"
                  :propConfigFases="empresa.json_config_fase_ps"
                  :propProcessoSeletivo="empresa.json_processo_selecao"
                />
              </tab-pane>
              <tab-pane id="usuarios">
                <template slot="label">
                  <i class="fas fa-users"></i> Funções de Usuários
                </template>
                <FuncoesUsuarios />
              </tab-pane>
              <tab-pane id="web">
                <template slot="label">
                  <i class="ni ni-world-2"></i> Web
                </template>
                <card>
                  <tabs pills tabNavWrapperClasses="mb-3">
                    <tab-pane active id="webconfig">
                      <template slot="label">
                        <i class="ni ni-settings-gear-65"></i> Configurações
                      </template>
                      <WebConfig
                        v-if="Object.keys(empresa).length > 0"
                        :propWebConfig="empresa"
                      />
                    </tab-pane>
                    <tab-pane active id="pg_vagas">
                      <template slot="label">
                        <i class="fas fa-cogs"></i> Página de Carreiras
                      </template>
                      <PaginaCarreiras
                        v-if="Object.keys(plano).length > 0"
                        :propPgVagas="empresa"
                        :propPlano="plano"
                      />
                    </tab-pane>
                  </tabs>
                </card>
              </tab-pane>
              <tab-pane id="integracoes">
                <template slot="label">
                  <i class="ni ni-ui-04"></i> Integrações
                </template>
                <template v-for="(integracao, index) in integracoes">
                  <EmailUtilizado
                    :key="index"
                    v-if="integracao.tipo === 'emailUtilizado'"
                    :propIntegracoes="integracao"
                    @resEmailUtilizado="emailUtilizado = $event"
                  />
                </template>
                <!-- {{integracoes}} -->
              </tab-pane>
            </tabs>
          </div>
          <!-- <div slot="footer">
            <div class="row"><div class="col"><h1>Configuracoes.vue</h1></div></div>
            <div class="row">
              <div class="col-6">
                empresa db -> <pre>{{Object.keys(empresa)}}</pre>
                empresa db -> <pre>{{empresa}}</pre>
              </div>
              <div class="col-6">
                empresa.json_processo_selecao db -> <pre>{{empresa.json_processo_selecao}}</pre>
              </div>
            </div>
          </div> -->
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import defaultMatchjob from '../../mixins/defaultMatchjob';

import {
  Loading,
} from 'element-ui';
import Tabs from '../../components/Tabs/Tabs.vue';
import TabPane from '../../components/Tabs/Tab.vue';
import FuncoesUsuarios from './FuncoesUsuarios.vue';
import ConfiguracoesGerais from './Empresa/ConfiguracoesGerais.vue';
import PlanoFinanceiro from './PlanoFinanceiro.vue';
import ProcessoSelecao from './ProcessoSeletivo/ProcessoSelecao.vue';
import WebConfig from './PaginaCarreiras/WebConfig.vue';
import PaginaCarreiras from './PaginaCarreiras/PaginaCarreiras.vue';

import EmailUtilizado from '../../components/Integracoes/EmailUtilizado.vue';

Vue.use(Loading.directive);

export default {
  name: "Configuracoes",
  mixins: [defaultMatchjob],
  components: {
    Tabs,
    TabPane,
    ConfiguracoesGerais,
    FuncoesUsuarios,
    PlanoFinanceiro,
    ProcessoSelecao,
    WebConfig,
    PaginaCarreiras,
    EmailUtilizado
  },
  data() {
    return {
      loading: false,
      empresaLoaded: false,
      usuariosLoaded: false,
      dataLoaded: false,
      empresa: [],
      plano: {},
      usuarios: [],
      emailUtilizado: ''
    };
  },
  computed: {
    planoState() {
      return this.$store.state.empresa.plano;
    },
    empresaState() {
      return this.$store.state.empresa.empresa;
    },
    integracoes() {
      return this.$store.state.empresa.empresa.json_integracoes;
    }
  },
  methods: {
    async carregaEmpresa() {
      this.loading = true;
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      await this.$http.post('empresa/detalhe', data).then(res => {
        this.empresa = res.data.empresa;
        this.plano = res.data.plano.json_detalhes;
        this.loading = false;
        this.empresaLoaded = true;
        this.liberaWatcher();
      })
    },
    async carregaUsuarios() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin,
        fl_tipo_acesso: this.id.tipoAcesso
      }
      await this.$http.post('usuarios', data).then(res => {
        this.usuarios = res.data.usuarios;
        this.usuariosLoaded = true;
        this.liberaWatcher();
      })
    },
    liberaWatcher() {
      if (this.empresaLoaded && this.usuariosLoaded){
        setTimeout(() => {
          this.dataLoaded = true;
          this.loading = false;
        }, 1000);
      }
    },
    async atualizaEmpresa() {
      await this.$http
        .post('empresa/atualizaempresa', this.empresa)
        .then(() => {
          this.notificacao("Configurações atualizadas!", "success")
          //console.log('resposta ->> ', res)
        }
        ).catch(err => {
          console.log('Erro na API -> ', err);
          return false;
        });
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  async created() {
    //this.debouncedAtualizaDados = _.debounce(() => {this.atualizaEmpresa()}, 3000)
    this.debouncedAtualizaDados = _.throttle (() => {this.atualizaEmpresa()}, 15000)
    const data = {
      id_empresa: this.id.empresa,
      id_empresa_user: this.id.empresa_user,
      hl_email: this.id.usuario,
      fl_admin: this.id.admin,
      fl_tipo_acesso: this.id.tipoAcesso
    }
    await this.$http.post('empresa/detalhe', data).then(res => {
      this.empresa = res.data.empresa;
      this.plano = res.data.plano.json_detalhes;
      //this.loading = false;
      this.empresaLoaded = true;
      this.liberaWatcher();
    })
    await this.$http.post('usuarios', data).then(res => {
      this.usuarios = res.data.usuarios;
      this.usuariosLoaded = true;
      this.liberaWatcher();
    })
  },
  beforeUnmount() {
    this.debouncedAtualizaDados.cancel();
  },
  filters: {
    },
  mounted() {
    //this.carregaEmpresa();
    //this.carregaUsuarios();
  },
  watch: {
    empresa: {
      handler(value){
        if (this.dataLoaded){
          this.debouncedAtualizaDados();
        }
      },
      deep: true
    }
  }
};
</script>
<style>
.icone-help-bread-crumb {
  color: #FFF;
  font-size: 1.6rem;
  cursor: pointer;
}
</style>
