<template>
  <div v-loading="loading">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
        @submit.prevent="handleSubmit(firstFormSubmit)">
        <div class="row justify-content-center">
          <div class="col-6 text-right">
            <label for="areaatuacao">Deseja incluir um teste no ato da candidatura desta vaga?</label>
          </div>
          <div class="col-6">
            <base-switch
              v-model="fl_teste"
            ></base-switch>
          </div>
        </div>
        <div class="row justify-content-center mt-4" v-if="fl_teste">
          <div class="col-4">
            <select class="form-control" v-model="vaga.selectedTeste">
              <option v-if="propTestes.length < 1">Não existem templates salvos</option>
              <option v-else value="">Escolha o teste</option>
              <option v-for="temp in propTestes" :key="temp.id_teste" :value="temp.id_teste">
                {{ temp.nm_nome}}
              </option>
            </select>
          </div>
        </div>
        <div class="row justify-content-center mt-5 mb-2">
          <div class="col-2">
            <base-button type="secondary" @click="voltarStep6">Voltar</base-button>
          </div>
          <div class="col-1">
            <base-button type="primary" native-type="submit">Proximo</base-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'element-ui';
import swal from 'sweetalert2';

Vue.use(Loading.directive);

export default {
  name: "Step6",
  components: {},
  props: {
    concluiStep6: { type: Function },
    voltarStep6: { type: Function },
    propIdEmpresa: { type: Object },
    propIdTeste: { type: String },
    propTestes: { type: Array }
  },
  data() {
    return {
      fl_teste: false,
      vaga: {
        selectedTeste: ""
      },
      loading: false
    }
  },
  methods: {
    async firstFormSubmit() {
      if (this.fl_teste && this.vaga.selectedTeste === '') {
        swal.fire({
          title: 'Ops!',
          html: 'Você não selecionou nenhum teste!<br>Escolha o teste ou desmarque a opção para incluir um teste.',
          icon: 'info'
        });
      } else {
        this.loading = true;
        setTimeout(() => {
          this.$emit('resStep6', this.vaga);
          this.concluiStep6();
        }, 500);
      }
    },
    initValues() {
      this.loading = true;
      setTimeout(() => {
        if (this.propIdTeste !== "") {
          this.vaga.selectedTeste = this.propIdTeste;
          this.fl_teste = true;
        }
        this.loading = false;
      }, 500)
    },
    showTestes() {
      this.loading = false;
      if (this.propTestes.length < 1) {
        swal.fire({
          title: 'Ops!',
          html: "Você não possui nenhum teste apto para vagas!<br><br>Apenas testes do tipo 'Simples' podem ser aplicados em vagas.",
          icon: 'info'
        });
        this.fl_teste = false;
      }
    }
  },
  mounted() {
    this.initValues();
  },
  watch: {
    fl_teste: {
      handler(value) {
        if (value) this.showTestes();
      }
    }
  }
}
</script>

<style>

</style>
