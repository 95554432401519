<template>
  <div v-loading="loading">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form class="needs-validation"
        @submit.prevent="handleSubmit(firstFormSubmit)">
        <div class="row justify-content-center mb-3">
          <div class="col-8">
            <p>Esta sessão deve utilizada para ampliar o nível de filtragem de candidatos.</p>
          </div>
        </div>
        <!-- Níveis de experiência / Cargo -->
        <div class="row justify-content-center mb-5" v-if="propPlano.matchAi.tempoExperiencia">
          <div class="col-8">
            <p><span v-html="formataExperiencia(vaga.nivelexperiencia.nivelCargo)"></span> no Cargo de <strong>{{propCargo}}</strong></p>
            <el-slider
              v-model="vaga.nivelexperiencia.nivelCargo"
              range
              :step="5"
              :max="20"
              :format-tooltip="formatTooltipCargo"
              :marks="marks"
              show-stops>
            </el-slider>
          </div>
        </div>
        <!-- Níveis de experiência / Area -->
        <div class="row justify-content-center my-5" v-if="propPlano.matchAi.tempoExperiencia">
          <div class="col-8">
            <p><span v-html="formataExperiencia(vaga.nivelexperiencia.nivelArea)"></span> na Área <strong>{{retornaAreaAtuacao(propArea)}}</strong></p>
            <el-slider
              v-model="vaga.nivelexperiencia.nivelArea"
              range
              :step="5"
              :max="20"
              :format-tooltip="formatTooltipCargo"
              :marks="marks"
              show-stops>
            </el-slider>
          </div>
        </div>
        <!-- Níveis de experiência / Nivel Hierarquico -->
        <div class="row justify-content-center my-5" v-if="propPlano.matchAi.tempoExperiencia">
          <div class="col-8">
            <p><span v-html="formataExperiencia(vaga.nivelexperiencia.nivelHierarquico)"></span> na posição de <strong>{{retornaNivelHierarquico(propNivel)}}</strong></p>
            <el-slider
              v-model="vaga.nivelexperiencia.nivelHierarquico"
              range
              :step="5"
              :max="20"
              :format-tooltip="formatTooltipCargo"
              :marks="marks"
              show-stops>
            </el-slider>
          </div>
        </div>
        <!-- Add de Idiomas -->
        <div class="row justify-content-center mt-5" v-if="propPlano.matchAi.idiomas">
          <div class="col-5">
            <label for="idioma">Idioma:</label>
            <el-autocomplete
              id="idioma"
              class="inuptAutocomplete"
              v-model="addIdioma"
              :fetch-suggestions="querySearchIdioma"
              placeholder="Idioma"
              :trigger-on-focus="false"
            >
            </el-autocomplete>
          </div>
          <div class="col-2">
            <label for="nivelidioma">Nível</label>
            <el-select
              name="Nível"
              class="inuptAutocomplete"
              label="Nível"
              id="nivelidioma"
              v-model="addIdiomaNivel"
            >
              <el-option
                v-for="nivel in nivelIdioma"
                :key="nivel.value"
                :value="nivel.value"
                :label="nivel.text"
              ></el-option>
            </el-select>
          </div>
          <div class="col-1 pt-3">
            <base-button class="mt-4" type="default" size="sm" @click="addIdiomaFunc"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Apresentação dos idiomas adicionados -->
        <div class="row justify-content-center mt-2 mt-2" v-if="vaga.idiomas.length > 0">
          <div class="col-8">
            <ul>
              <li class="mt-1" v-for="i in vaga.idiomas" :key="i.id">
                {{ i.value }} - {{ retornaNivelIdioma(i.nivel) }} <base-button round size="sm" type="secondary" class="btn-icon-only" @click="delIdioma(i.id)">
                  <i class="fas fa-ban"></i>
                </base-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-2" v-if="propPlano.matchAi.competencias">
          <div class="col-5">
            <label for="competencia">Competências:</label>
            <el-autocomplete
              id="competencia"
              class="inuptAutocomplete"
              v-model="addCompetencia"
              :fetch-suggestions="querySearchCompetencias"
              placeholder="Competência"
              :trigger-on-focus="false"
            >
            </el-autocomplete>
          </div>
          <div class="col-2">
            <label for="nivelidioma">Nível</label>
            <el-select
              name="Nível"
              class="inuptAutocomplete"
              label="Nível"
              id="nivelidioma"
              v-model="addCompetenciaNivel"
            >
              <el-option
                v-for="nivel in nivelCompetencia"
                :key="nivel.value"
                :value="nivel.value"
                :label="nivel.text"
              ></el-option>
            </el-select>
          </div>
          <div class="col-1 pt-3">
            <base-button class="mt-4" type="default" size="sm" @click="addCompetenciaFunc"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Apresenta das competencias adicionadas -->
        <div class="row justify-content-center mt-2" v-if="vaga.competencias.length > 0">
          <div class="col-8">
            <ul>
              <li class="mt-1" v-for="i in vaga.competencias" :key="i.id">
                {{ i.value }} - {{ retornaNivelCompetencia(i.nivel) }} <base-button round size="sm" type="secondary" class="btn-icon-only" @click="delCompetencia(i.id)">
                  <i class="fas fa-ban"></i>
                </base-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-2" v-if="propPlano.matchAi.certificacoes">
          <div class="col-7">
            <label for="certificacoes">Certificações:</label>
            <el-autocomplete
              id="certificacoes"
              class="inuptAutocomplete"
              v-model="addCertificacoes"
              :fetch-suggestions="querySearchCertificacoes"
              placeholder="Competência"
              :trigger-on-focus="false"
            >
            </el-autocomplete>
          </div>
          <div class="col-1 pt-3">
            <base-button class="mt-4" type="default" size="sm" @click="addCertificacoesFunc"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Apresenta as certificações adicionadas -->
        <div class="row justify-content-center mt-2" v-if="vaga.certificacoes.length > 0">
          <div class="col-8">
            <ul>
              <li class="mt-1" v-for="i in vaga.certificacoes" :key="i.id">
                {{ i.value }} <base-button round size="sm" type="secondary" class="btn-icon-only" @click="delCertificacao(i.id)">
                  <i class="fas fa-ban"></i>
                </base-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-2" v-if="propPlano.matchAi.formacao">
          <div class="col-2">
            <label for="tipoformacao">Tipo da Formação</label>
            <el-select
              name="Tipo da Formação"
              class="inuptAutocomplete"
              label="Tipo da Formação"
              id="tipoformacao"
              v-model="addFormacoes.tipoFormacao"
            >
              <el-option
                v-for="nivel in sysformacoes"
                :key="nivel.id"
                :value="nivel.id"
                :label="nivel.text"
              ></el-option>
            </el-select>
          </div>
          <div class="col-4">
            <label for="formacoes">Formação:</label>
            <el-autocomplete
              id="formacoes"
              class="inuptAutocomplete"
              v-model="addFormacoes.curso"
              :fetch-suggestions="querySearchFormacoes"
              placeholder="Formações"
              :trigger-on-focus="false"
            >
            </el-autocomplete>
          </div>
          <div class="col-1">
            <label>Concluído?</label><br>
            <base-switch
              v-model="addFormacoes.status"
            ></base-switch>
          </div>
          <div class="col-1 pt-3">
            <base-button class="mt-4" type="default" size="sm" @click="addFormacoesFunc"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Apresenta as formações adicionadas -->
        <div class="row justify-content-center mt-3" v-if="vaga.formacoes.length > 0">
          <div class="col-8">
            <ul>
              <li class="mt-1" v-for="i in vaga.formacoes" :key="i.id">
                {{retornaTipoFormacao(i.tipoFormacao)}} - {{ i.value }} - Concluído: {{i.status ? 'Sim' : 'Não'}} <base-button round size="sm" type="secondary" class="btn-icon-only" @click="delFormacao(i.id)">
                  <i class="fas fa-ban"></i>
                </base-button>
              </li>
            </ul>
          </div>
        </div>
        <!-- Botões Proximo/Voltar -->
        <div class="row justify-content-center mb-2 mt-5">
          <div class="col-2">
            <base-button type="secondary" @click="voltarStep7">Voltar</base-button>
          </div>
          <div class="col-1">
            <base-button type="primary" native-type="submit">Proximo</base-button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import Vue from 'vue';
import { Select, Option, Loading, Autocomplete, Slider } from 'element-ui';
import swal from 'sweetalert2';

Vue.use(Loading.directive);

export default {
  name: "Step7",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
    [Slider.name]: Slider
  },
  props: {
    concluiStep7: { type: Function },
    voltarStep7: { type: Function },
    propCargo: { type: String },
    propArea: { type: String },
    propNivel: { type: String },
    propIdiomas: { type: Array },
    propNivelexperiencia: { type: Object },
    propCompetencias: { type: Array },
    propCertificacoes: { type: Array },
    propFormacoes: { type: Array },
    propSysInfos: { type: Object },
    propPlano: { type: Object }
  },
  data() {
    return {
      marks: {
          0: '0',
          5: '5 anos',
          10: '10 anos',
          15: '15 anos',
          20: '20 anos'
      },
      fl_teste: false,
      addIdioma: "",
      addIdiomaNivel: "",
      addCompetencia: "",
      addCompetenciaNivel: "",
      addCertificacoes: "",
      addFormacoes: {
        curso: "",
        tipoFormacao: 0,
        status: true
      },
      vaga: {
        idiomas: [],
        competencias: [],
        certificacoes: [],
        formacoes: [],
        nivelexperiencia: {
          nivelCargo: [0, 5],
          nivelArea: [0, 5],
          nivelHierarquico: [0, 5],
        }
      },
      loading: false,
      idiomas: [],
      competencias: [],
      certificacoes: [],
      formacoes: [],
      nivelIdioma: [
        { value: "1", text: "Básico" },
        { value: "2", text: "Intermediário" },
        { value: "3", text: "Avançado" },
        { value: "4", text: "Fluente" }
      ],
      nivelCompetencia: [
        { value: "1", text: "Básico / Júnior" },
        { value: "2", text: "Intermediário / Pleno" },
        { value: "3", text: "Avançado / Sênior" },
        { value: "4", text: "Especialista" }
      ],
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "0", text: "Nível Hierárquico" },
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
    }
  },
  methods: {
    async firstFormSubmit() {
      if (this.validaCampos()) {
        this.loading = true;
        setTimeout(() => {
          this.$emit('resStep7', this.vaga);
          this.concluiStep7();
        }, 500);
      }
    },
    validaCampos() {
      if (this.addIdioma !== '') {
        this.alertShow('Ops!', "Você tem um idioma ainda não gravado!<br>Clique no botão <strong>'+'</strong> para gravar.", 'info');
        return false;
      } else if (this.addCompetencia !== '') {
        this.alertShow('Ops!', "Você tem uma competência ainda não gravada!<br>Clique no botão <strong>'+'</strong> para gravar.", 'info');
        return false;
      } else if (this.addCertificacoes !== '') {
        this.alertShow('Ops!', "Você tem uma certificação ainda não gravada!<br>Clique no botão <strong>'+'</strong> para gravar.", 'info');
        return false;
      } else if (this.addFormacoes.curso !== '') {
        this.alertShow('Ops!', "Você tem uma formação ainda não gravada!<br>Clique no botão <strong>'+'</strong> para gravar.", 'info');
        return false;
      } else {
        return true;
      }
    },
    initValues() {
      this.loading = true;
      setTimeout(() => {
        this.vaga.idiomas = this.propIdiomas;
        this.vaga.nivelexperiencia = this.propNivelexperiencia;
        this.vaga.competencias = this.propCompetencias;
        this.vaga.certificacoes = this.propCertificacoes;
        this.vaga.formacoes = this.propFormacoes;
        this.competencias = this.propSysInfos.competencias;
        this.idiomas = this.propSysInfos.idiomas;
        this.certificacoes = this.propSysInfos.certificacoes;
        this.formacoes = this.propSysInfos.formacoes;
        this.loading = false;
      }, 500)
    },
    //Função do Autocomplete de Idiomas
    querySearchIdioma(queryString, cb) {
      let idiomas = this.idiomas;
      var results = queryString ? idiomas.filter(this.createFilterIdiomas(queryString)) : idiomas;
      cb(results)
    },
    createFilterIdiomas(queryString) {
      return (idioma) => {
        return (idioma.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Competencias
    querySearchCompetencias(queryString, cb) {
      let competencias = this.competencias;
      var results = queryString ? competencias.filter(this.createFiltercompetencias(queryString)) : competencias;
      cb(results)
    },
    createFiltercompetencias(queryString) {
      return (comp) => {
        return (comp.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Certificacoes
    querySearchCertificacoes(queryString, cb) {
      let certificacoes = this.certificacoes;
      var results = queryString ? certificacoes.filter(this.createFilterCertificacoes(queryString)) : certificacoes;
      cb(results)
    },
    createFilterCertificacoes(queryString) {
      return (certificacao) => {
        return (certificacao.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Certificacoes
    querySearchFormacoes(queryString, cb) {
      let formacoes = this.formacoes;
      var results = queryString ? formacoes.filter(this.createFilterFormacoes(queryString)) : formacoes;
      cb(results)
    },
    createFilterFormacoes(queryString) {
      return (formacao) => {
        return (formacao.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    addIdiomaFunc() {
      if (this.addIdioma === '') {
        this.alertShow('Ops!', 'Você precisa definir um idioma!', 'info');
        return false;
      } else if (this.addIdiomaNivel === '') {
        this.alertShow('Ops!', 'Você precisa definir o nível de experiência para o idioma selecionado!', 'info');
        return false;
      } else if (this.vaga.idiomas.findIndex( item => item.value === this.addIdioma ) > -1) {
        this.alertShow('Ops!', 'Este idioma já foi incluído!', 'info');
        return false;
      }
      var size = this.vaga.idiomas.length;
      var arr = { id: ++size, value: this.addIdioma, nivel: this.addIdiomaNivel }
      this.vaga.idiomas.push(arr);
      this.addIdioma = "";
      this.addIdiomaNivel = "";
    },
    delIdioma(value) {
      var pos = this.vaga.idiomas.findIndex( item => item.id === value);
      this.vaga.idiomas.splice(pos, 1);
    },
    addCompetenciaFunc() {
      if (this.addCompetencia === '') {
        this.alertShow('Ops!', 'Você precisa definir uma competência!', 'info');
        return false;
      } else if (this.addCompetenciaNivel === '') {
        this.alertShow('Ops!', 'Você precisa definir o nível de experiência para a competência!', 'info');
        return false;
      } else if (this.vaga.competencias.findIndex( item => item.value === this.addCompetencia ) > -1) {
        this.alertShow('Ops!', 'Esta competência já foi incluída!!', 'info');
        return false;
      }
      var size = this.vaga.competencias.length;
      var arr = { id: ++size, value: this.addCompetencia, nivel: this.addCompetenciaNivel }
      this.vaga.competencias.push(arr);
      this.addCompetencia = "";
      this.addCompetenciaNivel = "";
    },
    delCompetencia(value) {
      var pos = this.vaga.competencias.findIndex( item => item.id === value);
      this.vaga.competencias.splice(pos, 1);
    },
    addCertificacoesFunc() {
      if (this.addCertificacoes === '') {
        this.alertShow('Ops!', 'Você precisa definir uma certificação!', 'info');
        return false;
      } else if (this.vaga.certificacoes.findIndex( item => item.value === this.addCertificacoes ) > -1) {
        this.alertShow('Ops!', 'Esta certificação já foi incluída!', 'info');
        return false;
      }
      var size = this.vaga.certificacoes.length;
      var arr = { id: ++size, value: this.addCertificacoes }
      this.vaga.certificacoes.push(arr);
      this.addCertificacoes = "";
    },
    delCertificacao(value) {
      var pos = this.vaga.certificacoes.findIndex( item => item.id === value);
      this.vaga.certificacoes.splice(pos, 1);
    },
    addFormacoesFunc() {
       if (this.addFormacoes.curso === '') {
        this.alertShow('Ops!', 'Você precisa definir uma formação!', 'info');
        return false;
      } else if (this.vaga.formacoes.findIndex( item => item.value === this.addFormacoes.curso ) > -1) {
        this.alertShow('Ops!', 'Esta formação já foi incluída!', 'info');
        return false;
      } else if(this.addFormacoes.tipoFormacao < 1) {
        this.alertShow('Ops!', 'Você precisa definir o tipo da formação!', 'info');
        return false;
      }
      var size = this.vaga.formacoes.length;
      var arr = {
        id: ++size,
        tipoFormacao: this.addFormacoes.tipoFormacao,
        value: this.addFormacoes.curso,
        status: this.addFormacoes.status
      }
      this.vaga.formacoes.push(arr);
      this.addFormacoes.curso = "";
      this.addFormacoes.tipoFormacao = 0;
      this.addFormacoes.status = true;
    },
    delFormacao(value) {
      var pos = this.vaga.formacoes.findIndex( item => item.id === value);
      this.vaga.formacoes.splice(pos, 1);
    },
    retornaNivelIdioma(value) {
      var res = this.nivelIdioma.find(i => i.value === value);
      return res.text;
    },
    retornaNivelCompetencia(value) {
      var res = this.nivelCompetencia.find(i => i.value === value);
      return res.text;
    },
    retornaTipoFormacao(value) {
      var res = this.sysformacoes.find(i => i.id === value);
      return res.text;
    },
    formatTooltipCargo(val) {
      if (val < 1) {
        return "Nenhuma experiência";
      } else if (val > 0 && val <= 5) {
        return "Júnior";
      } else if (val > 5 && val <= 10) {
        return "Pleno";
      } else if (val > 10 && val <= 15) {
        return "Sênior";
      } else if (val > 15) {
        return "Especialista";
      }
    },
    retornaAreaAtuacao(value) {
      var res = this.areasatuacao.find(i => i.value === value);
      return res.text;
    },
    retornaNivelHierarquico(value) {
      var res = this.niveishierarquicos.find(i => i.value === value);
      return res.text;
    },
    formataExperiencia(arr) {
      if (arr[1] === 0) {
        return "<strong>Nenhuma experiência requerida</strong>";
      } else if(arr[0] === arr[1]) {
        return `Experiência de <strong>${arr[0]} anos</strong>`;
      } else {
        return `Experiência de <strong>${arr[0]} a ${arr[1]} anos</strong>`;
      }
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        html: msgError,
        icon: icon
      });
    },
  },
  mounted() {
    this.initValues();
  },
  watch: {}
}
</script>

<style>

</style>
