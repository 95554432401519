<template>
  <div>
  <base-button size="sm" type="primary" @click="flNewNote = true">Add</base-button>
  <template v-if="flNewNote">
    <div class="box-new">
      <base-input
        maxlength="50"
        name="Título"
        placeholder="Título da nota"
        input-classes="form-control-sm"
        v-model="newNote.title">
      </base-input>
      <base-input
        maxlength="100"
        name="Descrição"
        placeholder="Descrição da nota"
        input-classes="form-control-sm"
        v-model="newNote.description">
      </base-input>
      <div class="form-inline">
        <select class="form-control form-control-sm mx-2" v-model="newNote.checkType">
          <option v-for="c in color" :key="c.value" :value="c.value">{{ c.text }}</option>
        </select>
        <i class="fas fa-square-full" :class="`icon-box text-${newNote.checkType}`"></i>
        <base-button size="sm" type="primary" class="mx-2" @click="butSave()">Salvar</base-button>
      </div>
    </div>
  </template>
  <ul class="list-group list-group-flush" data-toggle="checklist">
    <li class="list-group-item px-0"
        v-for="item in items"
        :key="item.title"
    >
      <div class="checklist-item"
           :class="{'checklist-item-checked': item.done, [`checklist-item-${item.checkType}`]: item.checkType}">
        <div class="checklist-info">
          <h5 class="checklist-title mb-0">{{item.title}}<a
            v-if="!item.done"
            class="removeButton"
            :class="`text-${item.checkType}`"
            @click="editNote(item)"
            > - <el-tooltip class="item" effect="light" content="Editar" placement="top"><i class="fas fa-edit"></i></el-tooltip></a></h5>
          <small class="text-muted">{{item.description}}</small><br>
          <small><em>{{ item.created_at.seconds | formatDateTime }}</em></small>
        </div>
        <div>
          <base-checkbox
            v-model="item.done"
            :type="item.checkType"
            @input="changeStatus(item.id, item.done)"
          />
            <!-- <a
              class="text-info removeButton">
              <i class="fas fa-edit"></i></a> -->
            <a
              v-if="item.done"
              class="text-danger removeButton">
              <i class="fas fa-trash-alt" @click="handleDelete(item.id)"></i></a>
        </div>
      </div>

    </li>
  </ul>
  </div>
</template>
<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { db } from "../../firebase/config";
import { addDoc, doc, Timestamp, collection, getDocs, updateDoc, deleteDoc, query, orderBy, limit } from "firebase/firestore";
import { Loading } from 'element-ui';
import swal from 'sweetalert2';
import moment from "moment";

Vue.use(Loading.directive);

export default {
  mixins: [defaultMatchjob],
  data() {
    return {
      loading: false,
      flNewNote: false,
      newNote: {
        id: '',
        title: '',
        description: '',
        done: false,
        checkType: 'primary',
        created_at: '',
      },
      color: [
        { value: 'success', text: 'Verde'},
        { value: 'primary', text: 'Azul'},
        { value: 'info', text: 'Verde-Azulado'},
        { value: 'danger', text: 'Vermelo'},
        { value: 'warning', text: 'Laranja'},
        { value: 'attention', text: 'Amarelo'},
      ],
      items: [
        {
          title: 'Call with Dave',
          description: '10:30 AM',
          done: true,
          checkType: 'success',
          created_at: [
            { seconds: ''}
          ]
        }
      ]
    };
  },
  methods: {
    async loadUserNotes() {
      const colRefNotas =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notas";
      const resNotas = collection(db, colRefNotas);
      const q = query(resNotas, orderBy('created_at', 'desc'), limit(4));
      await getDocs(q).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        if (docs.length > 0){
          this.items = [];
          this.items = docs;
          //console.log('notas carregadas do FS ->> ', this.items);
        }
      });
    },
    async changeStatus(id, status){
      const colRefNotas =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notas";
      const docRef = doc(db, colRefNotas, id);
      console.log('status recebido -> ', status)
      await updateDoc(docRef, {
        done: status
      })
        .then(() => {
          if(status){
            this.notificacao('Nota marcada como <strong>concluída</strong>!', 'success');
          } else {
            this.notificacao('Nota marcada como <strong>ativa</strong>!', 'info');
          }
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          this.notificacao('Não foi possível atualizar o banco de dados!', 'warning');
        });
    },
    async saveNewNote() {
      const colRefNotas =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notas";
      this.newNote.created_at = Timestamp.now();
      const resNotas = collection(db, colRefNotas);
      await addDoc(resNotas, this.newNote)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        });
    },
    async updateNote(){
      const colRefNotas =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notas";
      this.newNote.created_at = Timestamp.now();
      const docRef = doc(db, colRefNotas, this.newNote.id);
      await updateDoc(docRef, this.newNote)
        .then(() => {
          this.notificacao('Nota <strong>atualizada</strong>!', 'success');
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          this.notificacao('Não foi possível atualizar o banco de dados!', 'warning');
        });
    },
    async deleteNote(id) {
      const colRefNotas =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notas";
      const docRef = doc(db, colRefNotas, id);
      const res = await deleteDoc(docRef)
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.log("erro addDoc -> ", error);
          return false;
        })
      return res;
    },
    butSave() {
      this.loading = true;
      if (this.newNote.title === '') {
        this.notificacao('Preencha o título da nota!', 'warning');
        this.loading = false;
        return false;
      } else if (this.newNote.description === '') {
        this.notificacao('Preencha a descrição da nota!', 'warning');
        this.loading = false;
        return false;
      } else {
        if (this.newNote.id === '') {
          this.saveNewNote();
        } else {
          this.updateNote();
        }
        this.newNote.title = '';
        this.newNote.description = '';
        this.newNote.checkType = 'primary';
        this.newNote.created_at = '';
        this.flNewNote = false;
        this.loadUserNotes();
        setTimeout(() => {
          this.loading = false;
        }, 200);
      }
    },
    editNote(item) {
      this.newNote.id = item.id;
      this.newNote.title = item.title;
      this.newNote.description = item.description;
      this.newNote.checkType = item.checkType;
      this.flNewNote = true;
    },
    handleDelete(id) {
      swal.fire({
        title: 'ATENÇÃO!',
        html: `Tem certeza que deseja excluír essa nota?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Sim, excluir!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          if (this.deleteNote(id)){
            this.notificacao('Nota excluída!', 'success');
            this.loadUserNotes();
          } else {
            this.notificacao('Ops! Não foi possível excluir', 'danger');
          }
        }
      });
    },
    notificacao(msg, tipo){
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment.unix(value).fromNow();
      }
    }
  },
  mounted() {
    this.$nextTick(() =>{
      this.loadUserNotes();
    });
  }
};
</script>

<style>
.removeButton {
  cursor: pointer;
}
.box-new {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
.option-primary {
  background-color: #5e72e4;
}
.option-success {
  background-color: #2dce89;
}
.option-info {
  background-color: #11cdef;
}
.option-danger {
  background-color: #f5365c;
}
.option-warning {
  background-color: #fb6340;
}
.option-attention {
  background-color: #f1d302;
}
.icon-box {
  font-size: 1.8rem;
}
</style>
