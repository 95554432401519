<template>
  <div>
    <!-- Titulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="titulo">Título:</label>
        <input
          id="titulo"
          ref="titulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Título"
          placeholder="Nome da Empresa"
          v-model.lazy="propCabecalho.titulo"
        />
      </div>
    </div>
    <!-- Subtitulo -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Subtítulo:</label>
        <input
          id="subtitulo"
          ref="subtitulo"
          type="text"
          maxlength="100"
          class="form-control"
          name="Subtítulo"
          placeholder="Subtítulo"
          v-model.lazy="propCabecalho.subtitulo"
        />
      </div>
    </div>
    <!-- Tipo / ID do componente -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Modelo do cabeçalho:</label>
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/header-banner.PNG" class="img-fluid my-2" alt="Header Banner">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="1"
            id="radioTipoHeader1" name="flTipoHeader"
            v-model="propCabecalho.compId"
          />
          <label class="form-check-label" for="radioTipoHeader1">Banner</label>
        </div>
      </div>
      <div class="col-6 align-self-end text-center">
        <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/header-jumbotron.PNG" class="img-fluid my-2" alt="Header Jumbotron">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="2"
            id="radioTipoHeader2" name="flTipoHeader"
            v-model="propCabecalho.compId"
          />
          <label class="form-check-label" for="radioTipoHeader2">Jumbotron</label>
        </div>
      </div>
    </div>
    <!-- Fundo do Cabeçalho -->
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <label for="subtitulo">Fundo do cabeçalho:</label>
        <img :src="propCabecalho.background" class="img-fluid img-thumbnail my-2" alt="Fundo do Cabeçalho">
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col align-self-center">
        <base-button
          type="primary"
          size="sm"
          @click="modal.mudarFundo = true"
        >Alterar Fundo</base-button>
      </div>
    </div>
    <!-- Modal do background -->
    <modal :show="modal.mudarFundo" :showClose="true" size="xl" v-loading="imgChange">
      <h6 slot="header" class="modal-title">Alterar Fundo do Cabeçalho</h6>
      <tabs pills tabNavWrapperClasses="mb-3">
        <tab-pane active id="galeria">
          <template slot="label">
            <i class="ni ni-collection"></i> Da Galeria
          </template>
          <div class="row justify-content-center my-2">
            <div class="col-2 align-self-center" v-if="propCabecalho.background !== ''">
              <figure class="figure">
                <img :src="propCabecalho.background" class="figure-img img-fluid my-2" alt="Fundo atual">
                <figcaption class="figure-caption text-center">
                  <a href="#void" class="stretched-link" @click="defineFundo(propCabecalho.background)">Fundo atual</a>
                </figcaption>
              </figure>
            </div>
            <div class="col-2 align-self-center" v-for="item in galeriaFundos" :key="item.id">
              <figure class="figure">
                <img :src="item.url" class="figure-img img-fluid my-2" :alt="`Fundo ${item.id}`">
                <figcaption class="figure-caption text-center">
                  <a href="#void" class="stretched-link" @click="defineFundo(item.url)">Fundo {{item.id}}</a>
                </figcaption>
              </figure>
            </div>
          </div>
        </tab-pane>
        <tab-pane active id="personalizado">
          <template slot="label">
            <i class="ni ni-image"></i> Personalizado
          </template>
          <form class="needs-validation" enctype="multipart/form-data">
            <!-- Fundo Atual -->
            <div class="row justify-content-center mt-3">
              <div class="col-8">
                <label for="fundo">Fundo Atual</label><br>
                <img :src="novaFoto === '' ? propCabecalho.background : novaFoto" class="img-fluid my-2" alt="Logo"><br>
              </div>
              <div class="col-4">
                <label for="fundo">Novo fundo</label><br>
                <template v-if="imageError !== ''">
                  <p><small class="text-danger">{{imageError}}</small></p>
                </template>
                <div class="custom-file mt-3">
                  <label class="custom-file-label" for="customFileLang">
                    {{logo}}
                  </label>
                  <input type="file" class="custom-file-input" accept="image/png, image/jpeg" @change="onChangeFundo($event)" />
                </div>
                <!-- <base-button type="primary" class="my-3">Atualizar</base-button> -->
              </div>
            </div>
          </form>
        </tab-pane>
      </tabs>
      <div class="row justify-content-center my-2">
        <div class="col align-self-center">
          <base-button
            type="secondary"
            size="sm"
            @click="modal.mudarFundo = false"
          >Fechar</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '@/mixins/defaultMatchjob';
import { Loading } from 'element-ui';
import Tabs from '@/components/Tabs/Tabs.vue';
import TabPane from '@/components/Tabs/Tab.vue';
Vue.use(Loading.directive);
export default {
  name: "Cabecalho",
  mixins: [defaultMatchjob],
  props: {
    propCabecalho: Object
  },
  components:{
    Tabs,
    TabPane
  },
  data() {
    return {
      imgChange: false,
      tipoFundo: "1",
      modal: {
        mudarFundo: false
      },
      galeriaFundos: [
        { id: "1", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/austin.jpg" },
        { id: "2", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg1.jpg" },
        { id: "3", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg24.jpg" },
        { id: "4", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg26.jpg" },
        { id: "5", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg27.jpg" },
        { id: "6", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg28.jpg" },
        { id: "7", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg29.jpg" },
        { id: "8", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg30.jpg" },
        { id: "9", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg37.jpg" },
        { id: "10", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg38.jpg" },
        { id: "11", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/bg39.jpg" },
        { id: "12", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/broofoac.jpg" },
        { id: "13", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/city-profile.jpg" },
        { id: "14", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/clark-street-merc.jpg" },
        { id: "15", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/clark-street-mercantile.jpg" },
        { id: "16", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/georgie.jpg" },
        { id: "17", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/markus-spiske-187777.jpg" },
        { id: "18", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/nasa.jpg" },
        { id: "19", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/office5.jpg" },
        { id: "20", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/photo-15.jpg" },
        { id: "21", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/profile_city.jpg" },
        { id: "22", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project6.jpg" },
        { id: "23", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project14.jpg" },
        { id: "24", url: "https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/unsplashs.jpg" }
      ],
      logo: "Selecione um arquivo...",
      novaFotoFile: "",
      novaFoto: "",
      imageError: ""
    }
  },
  methods: {
    defineFundo(url) {
      this.propCabecalho.background = url;
      this.modal.mudarFundo = false;
    },
    async onChangeFundo(event) {
      this.imgChange = true;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.logo = "";
        this.novaFoto = "";
        this.novaFotoFile = "";
        this.imageError = "A imagem escolhida é maior que 1 MB. Ajuste ou troque a imagem"
      } else {
        this.logo = file.name;
        this.novaFotoFile = file;
        const form = new FormData();
        form.append("id_empresa", this.id.empresa);
        form.append("fundo", this.novaFotoFile);
        await this.$http.post('empresa/uploadfundoheader', form)
          .then(res => {
            this.propCabecalho.background = res.data;
          })
          .catch(err => {
            console.log(err);
          });
        this.imageError = "";
        this.novaFoto = URL.createObjectURL(file);
      }
      this.imgChange = false;
    },
  }
}
</script>

<style>

</style>
