<template>
  <div class="content">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6">
      <div><!--  v-loading="loading" -->
        <card class="no-border-card" body-classes="px-0 pb-0" footer-classes="pb-2">
          <template slot="header">
            <div class="row justify-content-between">
              <div class="col-11 align-self-center">
                <h3 class="mb-0">Template de Mensagens</h3>
              </div>
              <div class="col-1 align-self-center text-right">
                <i class="icone-help-bread-crumb text-default fas fa-question-circle" @click="drawer = !drawer"></i>
              </div>
            </div>
          </template>
          <div class="card-body pt-0" v-if="mensagens.length > 0">
            <h1>Mensagens</h1>
            <template v-for="(msg, index) in mensagens">
              <div class="row" :key="index">
                <div class="col-auto">
                  <p>
                    <strong>{{msg.nm_titulo}}</strong> - <small>{{retornaTipoMsg(msg.fl_tipo_mensagem)}} - Atualizado em: {{msg.updated_at | formatDate}}</small><br>
                    {{msg.nm_descricao}}<br>
                    <base-button size="sm" type="info" @click="verMensagem(msg.id_empresa_msg_templates)">Visualizar</base-button>
                    <base-button size="sm" type="secondary" @click="editarMensagem(msg.id_empresa_msg_templates)">Editar</base-button>
                    <!-- <span v-html="msg.tx_mensagem"></span> -->
                  </p>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col">
                <base-button type="primary" @click="flNovaMsg = true">Adicionar Novo</base-button>
              </div>
            </div>
            <!-- <pre>{{mensagem}}</pre>
            <span v-html="mensagem" />
            <pre>{{novaMensagem}}</pre> -->
            <!-- <pre>{{mensagens}}</pre> -->
          </div>
          <div class="card-body pt-0" v-else>
            <h1>Mensagens</h1>
            <div class="row">
              <div class="col">Não há mensagens cadastradas.</div>
            </div>
            <div class="row">
              <div class="col">
                <base-button type="primary" @click="flNovaMsg = true">Adicionar Novo</base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <modal :show="modal.ver" :showClose="false" size="lg">
      <h6 slot="header" class="modal-title">Visualização de Mensagem</h6>
      <div v-html="mensagem"></div>
      <div slot="footer">
        <base-button type="secondary" class="ml-3" @click="modal.ver = false">Fechar</base-button>
      </div>
    </modal>
    <modal :show="flNovaMsg" :showClose="false" size="lg">
      <h6 slot="header" class="modal-title">Adicionar Novo Template de Mensagem</h6>
      <div class="row">
        <div class="col">
          <label for="selectTipoNovaMensagem">Selecione o Tipo da Mensagem<span class="text-danger">*</span></label>
          <el-select
            ref="selectTipoNovaMensagem"
            name="Selecionar o tipo da Mensagem"
            class="inuptAutocomplete"
            label="Selecionar o tipo da Mensagem"
            id="selectTipoNovaMensagem"
            v-model="novaMensagem.fl_tipo_mensagem"
          >
          <el-option
            v-for="(msg, index) in tipoMensagem"
            :key="index"
            :value="msg.value"
            :label="msg.text"
          >
          </el-option>
        </el-select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <label for="tituloTemplate">Título: <span class="text-danger">*</span></label>
          <input
            id="tituloTemplate"
            ref="tituloTemplate"
            type="text"
            class="form-control"
            name="Título"
            placeholder="Título"
            v-model="novaMensagem.nm_titulo"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <label for="descricaoTemplate">Descrição: <span class="text-danger">*</span></label>
          <input
            id="descricaoTemplate"
            ref="descricaoTemplate"
            type="text"
            class="form-control"
            name="Descrição"
            placeholder="Descrição"
            v-model="novaMensagem.nm_descricao"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <label for="descricaoTemplate">Mensagem: <span class="text-danger">*</span></label>
          <trumbowyg v-model="novaMensagem.tx_mensagem" ref="editorNovaMensagem" :config="config" class="form-control" name="novaMensagem"></trumbowyg>
        </div>
      </div>
      <div slot="footer">
        <base-button type="secondary" class="ml-3" @click="cancelarEdicao">Cancelar</base-button>
        <base-button type="primary" class="mr-3 ml-auto" @click="salvarNovaMensagem">Salvar</base-button>
      </div>
    </modal>
    <el-drawer
      title="Ajuda da MatchJob"
      :visible.sync="drawer"
      direction="rtl"
      size="50%">
      <h3 class="mx-2"><span class="text-muted">Perguntas frequêntes -</span> Mensagens</h3>
      <collapse>
        <collapse-item name="1">
          <h4 class="m-0" slot="title">Para que servem as Mensagens</h4>
          <p class="font-weight-normal">A sessão "Mensagens" é utilizada para gerenciar os templates de mensagens enviadas pelo sistema durante a sua execução.</p>
          <p class="font-weight-normal">Sempre que uma mensagem for enviada para um candidato, será utilizado um template aqui definido.</p>
          <p class="font-weight-normal">Templates são definidos como o formato ou esqueleto da mensagema ser enviada.</p>
        </collapse-item>
        <collapse-item name="2">
          <h4 class="m-0" slot="title">Quais são os templates de Mensagens existentes?</h4>
          <p class="font-weight-normal">Os templates atualmente existentes são:</p>
          <ol>
            <li>Convite de Entrevista</li>
            <li>Teste Técnico</li>
            <li>Teste de Perfil</li>
            <li>Carta Proposta</li>
            <li>Desclassidficação de Candidato</li>
            <li>Pesquisa de Satisfação</li>
          </ol>
        </collapse-item>
        <collapse-item name="3">
          <h4 class="m-0" slot="title">O que são as variáveis?</h4>
          <p class="font-weight-normal">Variável é o nome utilizado para definir um ou mais valores que são manipulados pelo sistema durante a sua operação. O nome “variável” é utilizado por ser um tipo de conteúdo que pode apresentar diferentes valores enquanto o sistema está em execução.</p>
          <p class="font-weight-normal">Ou seja, o MatchJob Empresas irá incluir o valor definido correspondente à variavel no ato da execução da função.</p>
          <p class="font-weight-normal">Exemplo: A variável <strong>%NomeEmpresa%</strong> será convertida para <strong>{{empresa.nm_fantasia}}</strong> quando a mensagem for enviada.</p>
        </collapse-item>
        <collapse-item name="4">
          <h4 class="m-0" slot="title">Quais são as variáveis existentes?</h4>
          <p class="font-weight-normal">As variáveis existente são:</p>
          <ol>
            <li><strong>%CandidatoNome%</strong> - Será alterada para o nome do Candidato que a mensagem for enviada</li>
            <li><strong>%NomeEmpresa%</strong> - Será alterada para <strong>{{empresa.nm_fantasia}}</strong></li>
            <li><strong>%TituloVaga%</strong> - Será alterada para o título da Vaga que a mensagem for enviada</li>
            <li><strong>%EmpresaSite%</strong> - Será alterada para <strong>{{empresa.hl_site}}</strong></li>
            <li><strong>%TipoEntrevista%</strong> - Será alterada para o tipo que foi definido para a Entrevista (Remoto ou Presencial)</li>
            <li><strong>%DataEntrevista%</strong> - Será alterada para a data que foi definida a Entrevista</li>
            <li><strong>%EntrevistadorNome%</strong> - Será alterada para o nome do Entrevistador que foi definido para Entrevista</li>
            <li><strong>%LocalEntrevista%</strong> - Conterá o link da entrevista remota ou o endereço do local da entrevista, conforme for definido</li>
            <li><strong>%MensagemAdicional%</strong> - Adiciona uma mensagem ao template</li>
            <li><strong>%LinkArquivo%</strong> - Link de Arquivo para enviar na mensagem</li>
            <li><strong>%LinkPortalAceiteProposta%</strong> - Link de Portal de Aceite de Propostas</li>
          </ol>
        </collapse-item>
      </collapse>
      <span class="text-center">
        <p class="font-weight-lighter">
          <small>
            Este tópico foi útil?<br>
            <a href="#">Clique aqui</a> se precisar de mais informações.
          </small>
        </p>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from "moment";
import { Select, Option, Drawer, Loading } from 'element-ui';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
//Editor
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
// Plugins are optional
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/langs/pt_br.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/mention/trumbowyg.mention.js'

Vue.use(
  Loading.directive
);

export default {
  name: "Mensagens",
  components: {
    Collapse,
    CollapseItem,
    [Drawer.name]: Drawer,
    Trumbowyg,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      modal: {
        ver: false,
        editar: false
      },
      config: {
        lang: 'pt-br',
        autogrow: true,
        removeformatPasted: true,
        tagsToRemove: ['script', 'link'],
        linkTargets: ['_blank'],
        // Adding color plugin button
        btnsAdd: ['foreColor', 'backColor'],
        // Limit toolbar buttons
        plugins: {
          mention: {
            source :[
              { value: '%CandidatoNome%', name: 'Nome do Candidato' },
              { value: '%NomeEmpresa%', name: 'Nome da Empresa' },
              { value: '%TituloVaga%', name: 'Título da Vaga' },
              { value: '%EmpresaSite%', name: 'URL do Site da Empresa' },
              { value: '%TipoEntrevista%', name: 'Tipo da Entrevista Remoto/Presencial' },
              { value: '%DataEntrevista%', name: 'Data da Entrevista' },
              { value: '%EntrevistadorNome%', name: 'Nome do Entrevistados' },
              { value: '%LocalEntrevista%', name: 'Local da Entrevista Link/Endereço' },
              { value: '%MensagemAdicional%', name: 'Mensagem Adicional no Template' },
              { value: '%LinkArquivo%', name: 'Link de Arquivo para enviar' },
              { value: '%LinkPortalAceiteProposta%', name: 'Link do Portal de Aceite de Propostas' }
            ],
            formatDropdownItem: function (item) {
                return item.name + ' (' + item.value + ')';
            },
            formatResult: function (item) {
                return item.value;
            }
          }
        },
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['bold', 'italic'], ['formatting'], ['link'],
          ['foreColor'], ['backColor'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
          ['mention']
        ]
      },
      flNovaMsg: false,
      tipoMensagem: [
        { value: 'negociacao', text: "Carta Proposta - Fase de Negociação"},
        { value: 'desclassificacaoVaga', text: "Mensagem de desclassificação"},
        { value: 'entrevista', text: "Convite de Entrevista - Fases de Entrevistas"},
        { value: 'testePerfil', text: "Envio de Testes de Perfil - Fases de Testes de Perfil"},
        { value: 'enviaTestes', text: "Envio de Testes de Técnicos - Fases de Testes de Testes"},
        { value: 'posAvaliativo', text: "Envio de Pesquisa de satisfação"},
      ],
      novaMensagem: {
        id_empresa_msg_templates: "",
        id_empresa: "",
        fl_tipo_mensagem: "",
        nm_titulo: "",
        nm_descricao: "",
        tx_mensagem: ""
      },
      drawer: false,
      mensagem: ""
    }
  },
  computed: {
    plano() {
      return this.$store.state.empresa.plano;
    },
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    mensagens() {
      return this.$store.state.empresa.mensagens;
    },
  },
  methods: {
    retornaTipoMsg(value) {
      var msg = this.tipoMensagem.find(t => t.value === value);
      return msg.text
    },
    verMensagem(id) {
      var msg = this.mensagens.find(m => m.id_empresa_msg_templates === id)
      this.mensagem = msg.tx_mensagem;
      this.modal.ver = true;
    },
    editarMensagem(id) {
      var msg = this.mensagens.find(m => m.id_empresa_msg_templates === id)
      this.mensagem = msg.tx_mensagem;
      this.novaMensagem.id_empresa_msg_templates = msg.id_empresa_msg_templates;
      this.novaMensagem.fl_tipo_mensagem = msg.fl_tipo_mensagem;
      this.novaMensagem.nm_titulo = msg.nm_titulo;
      this.novaMensagem.nm_descricao = msg.nm_descricao;
      this.novaMensagem.tx_mensagem = msg.tx_mensagem;
      this.flNovaMsg = true;
    },
    async salvarNovaMensagem() {
      this.novaMensagem.id_empresa = this.empresa.id_empresa;
      await this.$http
        .post('mensagens/salvar', this.novaMensagem)
        .then(res => {
          console.log('res -> ', res)
          if(res.status === 201) {
            this.flNovaMsg = false;
            this.notificacao('Mensagem gravada com sucesso!', 'success');
            const data = { id_empresa: this.empresa.id_empresa }
            this.$store.dispatch('loadMensagens', data);
          } else {
            this.flNovaMsg = false;
            this.notificacao('Ops! Houve uma falha não foi possível gravar!', 'warning');
          }
        })
        .catch(err => {
          console.log('Erro na API -> ', err);
          this.flNovaMsg = false;
          this.notificacao('Ops! Houve uma falha não foi possível gravar!', 'warning');
        });
    },
    cancelarEdicao() {
      this.novaMensagem.id_empresa_msg_templates = "";
      this.novaMensagem.id_empresa = "";
      this.novaMensagem.fl_tipo_mensagem = "";
      this.novaMensagem.nm_titulo = "";
      this.novaMensagem.nm_descricao = "";
      this.novaMensagem.tx_mensagem = "";
      this.flNovaMsg = false;
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
  }
}
</script>

<style>
.icone-help-bread-crumb {
  color: #FFF;
  font-size: 1.6rem;
  cursor: pointer;
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
