<template>
  <div v-if="dataLoaded">
    <!-- Pretensão Salarial -->
    <div class="row my-2" v-if="plano.pretensao">
      <div class="col-auto align-self-center">
        <base-switch
          id="pretensao"
          class="ml-2"
          v-model="triagem.configs.pretensaoSalarial"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar pretensão salarial?</label>
      </div>
      <div class="col-auto align-self-center">
        <label>Faixa de Aceitação:</label> <el-select
          required
          class="inuptAutocomplete"
          v-model="triagem.configs.pretensaoSalarialFaixa"
          placeholder="Faixa de Aceitação"
          :disabled="!triagem.configs.pretensaoSalarial"
        >
          <el-option
            v-for="tipo in faixaSalarial"
            :key="tipo.value"
            :label="tipo.label"
            :value="tipo.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- Competencias -->
    <div class="row my-2" v-if="plano.competencias">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.competencias"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar Competências?</label>
      </div>
      <div class="col-auto align-self-center">
        <small
            v-if="!triagem.configs.competencias"
            class="text-muted">Qualquer configuração de requisito de competência incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Idiomas -->
    <div class="row my-2" v-if="plano.idiomas">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.idiomas"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar Idiomas?</label>
      </div>
      <div class="col-auto align-self-center">
        <small
            v-if="!triagem.configs.idiomas"
            class="text-muted">Qualquer configuração de requisito de idioma incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Formação -->
    <div class="row my-2" v-if="plano.formacao">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.formacao"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar Formações?</label>
      </div>
      <div class="col-auto align-self-center">
        <small
            v-if="!triagem.configs.formacao"
            class="text-muted"
        >Qualquer configuração de requisito de formação incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Certificações -->
    <div class="row my-2" v-if="plano.certificacoes">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.certificacao"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar Certificações?</label>
      </div>
      <div class="col-auto align-self-center">
        <small
            v-if="!triagem.configs.certificacao"
            class="text-muted"
        >Qualquer configuração de requisito de certificação incluído nas vagas serão ignorados.</small>
      </div>
    </div>
    <!-- Localização -->
    <div class="row my-2">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.localizacao"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar a Distância?</label>
      </div>
      <div class="col-auto align-self-center">
        <template v-if="triagem.configs.localizacao">
          <template v-if="plano.localizacao === '1'">
            <p>Verifica se o candidato reside na mesma Cidade/Estado da localização vaga.</p>
          </template>
          <template v-else-if="plano.localizacao === '2'">
            <p>Verifica se o candidato reside proximo ao bairro da localização vaga.</p>
          </template>
          <template v-else-if="plano.localizacao === '3'">
            <p class="h5">Calcula o tempo do trajeto entre a residencia do candidato e a localização da vaga.<br>
            <small>O calculo é estimado considerando dias úteis e o horário de pico da manhã.</small>
            </p>
          </template>
        </template>
      </div>
    </div>
    <!-- Tempo de Experiência -->
    <div class="row my-2" v-if="plano.tempoExperiencia">
      <div class="col-auto align-self-center">
        <base-switch
          class="ml-2"
          v-model="triagem.configs.tempoExperiencia"
        ></base-switch>
      </div>
      <div class="col-3 align-self-center">
        <label>Avaliar Tempo de Experiência?</label>
      </div>
      <div class="col-auto align-self-center">
        <small
            v-if="!triagem.configs.tempoExperiencia"
            class="text-muted"
        >Qualquer configuração de requisito de tempo de experiência incluído nas vagas serão ignorados.</small>
      </div>
    </div>

   <!--  <div class="row">
      <div class="col">
        triagem -> <pre>{{triagem}}</pre>
      </div>
      <div class="col">
        plano -> <pre>{{plano}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "CompTriagemVaga",
  props: {
    propFase: { type: Object },
    propEmit: { type: Boolean }
  },
  data() {
    return {
      dataLoaded: false,
      triagem: {},
      faixaSalarial: [
        { value: "1", label: "Exatamente Igual" },
        { value: "2", label: "5% (a mais ou a menos)" },
        { value: "3", label: "10% (a mais ou a menos)" },
        { value: "4", label: "15% (a mais ou a menos)" },
        { value: "5", label: "20% (a mais ou a menos)" },
        { value: "6", label: "25% (a mais ou a menos)" },
      ]
    }
  },
  computed: {
    plano() {
      return this.$store.state.empresa.plano.json_detalhes.matchAi;
    }
  },
  methods: {
    firstFormSubmit() {
      this.$emit('emitTriagem', this.triagem);
    }
  },
  mounted() {
    this.triagem = structuredClone(this.propFase);
    setTimeout(() => {
      this.dataLoaded = true
    }, 500);
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style>

</style>
