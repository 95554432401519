<template>
  <div>
    <div class="row justify-content-start align-items-start mt-3">
      <div class="col-12">
        <h5>Certificações</h5>
      </div>
    </div>
    <template v-if="propCertificacoesVaga.length < 1">
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12">
          <small>A vaga não possui certificações definidas.</small>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="propCertificacoes.length > 0">
        <template v-for="(certificacao, index) in propCertificacoesVaga">
          <template v-if="verificaCertificacaoMatch(certificacao.value, index)">
            <div :key="index">
              <div class="row justify-content-start align-items-start mt-2">
                <div class="col-12">
                  Resultado do match: <strong>{{certificacao.value}}</strong> - <strong><span class="text-success">Positivo</span></strong> <span
                  class="text-success"
                  >
                  <i class="fas fa-check-circle"></i></span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="row justify-content-start align-items-start mt-3" :key="index">
              <div class="col-12">
                Resultado do match: <strong>{{certificacao.value}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
                class="text-danger"
                >
                <i class="fas fa-times-circle"></i></span>
              </div>
            </div>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-for="(certificacao, index) in propCertificacoesVaga">
          <div class="row justify-content-start align-items-start mt-3" :key="index">
            <div class="col-12">
              Resultado do match: <strong>{{certificacao.value}}</strong> - <strong><span class="text-danger">Negativo</span></strong> <span
              class="text-danger"
              >
              <i class="fas fa-times-circle"></i></span>
            </div>
          </div>
        </template>
      </template>
    </template>
    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        <hr class="my-3">
      </div>
    </div>
    <!-- <div class="row justify-content-start align-items-start">
      <div class="col-6">
        propCertificacoes -> <pre>{{propCertificacoes}}</pre>
      </div>
      <div class="col-6">
        propCertificacoesVaga -> <pre>{{propCertificacoesVaga}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "CertificacoesComp",
  props: {
    propCertificacoes: { type: Array },
    propCertificacoesVaga: { type: Array }
  },
  data() {
    return {
      flDetalhes: false,
      arrCerts: []
    }
  },
  methods: {
    verificaCertificacaoMatch(value, index) {
      var cert = this.propCertificacoes.findIndex(i => i.candidato_certificacao === value);
      if (cert > -1){
        this.arrCerts[index] = cert;
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>
.icone-comp-match {
  font-size: 1.6rem;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
