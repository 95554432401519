<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <label class="form-control-label">{{propValue}}</label><span v-if="propRequired" class="text-danger"> *</span>
      </div>
    </div>
    <div class="row mb-3 mt-1 mx-1">
      <div class="col">
        <div class="form-check" v-for="opt in propOptions" :key="opt.value">
          <input
            class="form-check-input"
            type="checkbox"
            :value="opt.value"
            v-model="resp"
            :id="cbId + opt.value"
            @blur="emitData"
          />
          <label class="form-check-label" :for="cbId + opt.value">{{ opt.text }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiplaEscolha",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean },
    propOptions: { type: Array }
  },
  data() {
    return {
      resp: [],
      cbId: ""
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
}
</script>

<style>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
