<template>
  <div v-loading="loading">
    <div class="row justify-content-center mb-3">
      <div class="col-6 align-self-center">
        <label for="nomefase">Nome da fase</label>
        <input
          id="nomefase"
          ref="nomefase"
          type="text"
          class="form-control"
          name="Nome da Fase"
          v-model.trim.lazy="propEnviaTestes.value"
          @blur="validaOnBlur()"
        />
      </div>
    </div>
    <!-- Flag - Testes Internos ou Externos -->
    <div class="row justify-content-center mt-3" v-if="propEnviaTestes.testesExternos">
      <div class="col-6 align-self-center">
        <label>Qual será o tipo do teste utilizado?</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="interno"
            id="radioTipoTeste1" name="flTipoTeste"
            v-model="propEnviaTestes.tipoTeste"
            @input="ativaLoading('interno')"
          />
          <label class="form-check-label" for="radioTipoTeste1">Testes da MatchJob</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="externo"
            id="radioTipoTeste2" name="flTipoTeste"
            v-model="propEnviaTestes.tipoTeste"
            @input="ativaLoading('externo')"
          />
          <label class="form-check-label" for="radioTipoTeste2">Fornecedor Externo</label>
        </div>
      </div>
    </div>
    <template v-if="propEnviaTestes.tipoTeste === 'interno'">
      <template v-if="propTestes.length > 0">
        <!-- Coluna de Adição de Testes -->
        <div class="row justify-content-center mt-2">
          <div class="col-12 align-self-center text-center">
            <label>Selecione um ou mais testes que serão aplicados nesta fase.</label>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-12 align-self-center text-center">
            <el-select
              class="mr-2"
              v-model="addTeste"
              placeholder="Escolha o Teste"
            >
              <el-option
                v-for="teste in propTestes"
                :key="teste.id_teste"
                :label="teste.nm_nome"
                :value="teste.id_teste">
              </el-option>
            </el-select>
            <base-button type="default" class="btn-icon-only" @click="addTesteProcesso"><i class="fas fa-plus"></i></base-button>
          </div>
        </div>
        <!-- Apresentação dos testes adicionados -->
        <div class="row justify-content-center mt-5">
          <div class="col-8 align-self-center">
            <label v-if="propEnviaTestes.testesPadrao.length === 1">
              Existe {{propEnviaTestes.testesPadrao.length}} teste definido para esta fase.</label>
            <label v-else-if="propEnviaTestes.testesPadrao.length > 1">
              Existes {{propEnviaTestes.testesPadrao.length}} testes definidos para esta fase.</label>
            <table v-if="propEnviaTestes.testesPadrao.length > 0">
              <tr class="border-bottom" v-for="teste in propEnviaTestes.testesPadrao" :key="teste.id">
                <td class="w-100 pt-3">
                  <span class="h4">{{teste.nome}}</span><br>
                  <span class="text-muted">{{teste.descricao}}</span>
                </td>
                <td class="pt-3">
                  <!-- botao excluir -->
                  <base-button
                    type="danger"
                    size="sm"
                    class="btn-icon-only"
                    @click="removeTesteProcesso(teste.id)"
                  ><span class="btn-inner--icon"><i class="fas fa-trash-alt"></i></span>
                  </base-button>
                </td>
              </tr>
            </table>
            <p class="font-italic text-center mt-3">Você sempre poderá selecionar qualquer teste nesta fase,<br>mesmo que defina testes como 'padrão'.</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row justify-content-center mt-3">
          <div class="col-5 align-self-center text-center">
            <p class="h4">Você ainda não possui nenhum teste criado.</p>
            <p>A fase está ativada e você poderá escolher um ou mais testes durante a criação do processo de seleção.</p>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../../mixins/defaultMatchjob';
import {
  Loading,
  Select,
  Option
} from 'element-ui';

Vue.use(Loading.directive);
export default {
  name: "EnvioTestesConfig",
  mixins: [defaultMatchjob],
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    propEnviaTestes: { type: Object },
    propTestes: { type: Array }
  },
  data() {
    return {
      loading: false,
      tipoTeste: "interno",
      addTeste: "",
      enviaTestes: {}
    }
  },
  methods: {
    addTesteProcesso() {
      this.loading = true;
      if (this.addTeste === '') {
        this.notificacao('Você precisa escolher um teste antes!', 'warning');
        this.loading = false;
      } else if (this.propEnviaTestes.testesPadrao.findIndex(i => i.id === this.addTeste) > -1) {
        setTimeout(() => {
          this.addTeste = "";
          this.loading = false;
          this.notificacao('Você já adicionou esse teste!', 'warning');
        }, 300);
      }else {
        var teste = this.propTestes.find(i => i.id_teste === this.addTeste);
        let tempTeste = {
          id: teste.id_teste,
          nome: teste.nm_nome,
          descricao: teste.nm_descricao
        };
        setTimeout(() => {
          this.propEnviaTestes.testesPadrao.push(tempTeste);
          this.addTeste = "";
          this.loading = false;
        }, 500);
      }
    },
    removeTesteProcesso(id) {
      this.loading = true;
      var pos = this.propEnviaTestes.testesPadrao.findIndex( item => item.id === id);
      setTimeout(() => {
        this.propEnviaTestes.testesPadrao.splice(pos, 1);
        this.loading = false;
      }, 500);
    },
    validaOnBlur() {
      if (this.propEnviaTestes.value === '') {
        this.propEnviaTestes.value = 'Envio de Testes'
      }
    },
    ativaLoading(value) {
      this.loading = true;
      if (value === 'externo') {
        this.propEnviaTestes.testesPadrao = [];
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.select-testes {
  width: 100%;
}
</style>
