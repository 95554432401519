<template>
  <modal :show="propShow" :showClose="false" size="lg">
    <h6 slot="header" class="modal-title">Enviar um teste</h6>
    <div class="row">
      <div class="col">
        <label for="selectTeste">Selecione o Teste para enviar<span class="text-danger">*</span></label>
        <el-select
          ref="selectTeste"
          name="Selecionar Teste"
          class="inuptAutocomplete"
          label="Selecionar Teste"
          id="selectTeste"
          v-model="enviaTeste.id_teste"
          @change="retornaTesteSelecionadoModal"
        >
        <el-option
          v-for="(teste, index) in testes"
          :key="index"
          :value="teste.id_teste"
          :label="teste.nm_nome"
        >
          <span style="float: left">{{ teste.nm_nome }} </span> <span style="color: #8492a6; font-size: 11px"> - {{ teste.nm_categoria }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ teste.nm_descricao }}</span>
        </el-option>
      </el-select>
      </div>
    </div>
    <div class="row mt-4" v-if="enviaTeste.id_teste !== ''">
      <div class="col">
        Nome do Teste: <strong>{{enviaTeste.testeSelecionado.nm_nome}}</strong><br>
        Descrição: {{enviaTeste.testeSelecionado.nm_descricao}}<br>
        Categoria: <strong>{{enviaTeste.testeSelecionado.nm_categoria}}</strong> - Tipo do Teste: <strong>{{enviaTeste.testeSelecionado.nm_tipo}}</strong><br>
        Perguntas: <span class="linha-clique" @click="enviaTeste.flDetalhes = !enviaTeste.flDetalhes">
          <small class="text-primary">Ver</small> <i class="text-primary fas" :class="enviaTeste.flDetalhes ? 'fa-times' : 'fa-plus'"></i>
        </span><br>
        <transition name="fade" mode="out-in">
        <ol v-show="enviaTeste.flDetalhes">
          <li class="list-perguntas-teste mt-2" v-for="(pergunta, index) in enviaTeste.testeSelecionado.json_questoes.questions" :key="index">
            <strong>{{pergunta.value}}</strong><br>
            Tipo: {{retornaTipoPerguntaSelecionadoModal(pergunta.type)}} - Obrigatória: {{pergunta.required ? 'Sim' : 'Não'}}{{retornaDefinedPergunta(pergunta)}}<br>
            <template v-if="pergunta.type !== 'text'">
              Opções: <ol><li v-for="(opt, index) in pergunta.options" :key="index">{{opt.text}}</li></ol>
            </template>
          </li>
        </ol>
        </transition>
      </div>
    </div>
    <div slot="footer">
      <base-button type="secondary" class="ml-3" @click="closeModal">Cancelar</base-button>
      <base-button type="primary" class="mr-3 ml-auto" @click="emitEnviarTeste">Enviar</base-button>
    </div>
  </modal>
</template>

<script>
import { Select, Option } from 'element-ui';
export default {
  name: "EnviarTeste",
  props: {
    //propCandidato: { type: Object }, //Conteudo da timeline do candidato
    //propVaga: { type: String },// String com o flag do tipo da entrevista
    //propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    propShow: { type: Boolean },
    propIndex: { type: Number },
    closeModal: { type: Function },
    concluiRegistro: { type: Function },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    testes() {
      return this.$store.state.testes.testes;
    },
  },
  data() {
    return {
      enviaTeste: {
        index: null,
        id_teste: "",
        testeSelecionado: null,
        flDetalhes: false
      },
    }
  },
  methods: {
    retornaTesteSelecionadoModal(id) {
      this.enviaTeste.testeSelecionado = this.testes.find(a => a.id_teste === id);
    },
    retornaTipoPerguntaSelecionadoModal(tipo) {
      if(tipo === 'text') {
        return "Texto"
      } else if(tipo === 'dico') {
        return "Dicotômica"
      } else if(tipo === 'ru') {
        return "Resposta Única"
      } else if(tipo === 'multi') {
        return "Multipla Escolha"
      } else if(tipo === 'likert') {
        return "Escala Likert"
      } else if(tipo === 'nps') {
        return "NPS"
      }
    },
    retornaDefinedPergunta(pergunta) {
      if(pergunta.type !== 'text' && pergunta.type !== 'nps' && pergunta.type !== 'likert') {
        if(pergunta.type === 'multi') {
          if(typeof pergunta.defined !== 'object') {
            if(pergunta.defined !== '') {
              return " - Resposta Definida: " + pergunta.defined
            }
          } else {
            let resposta = " - Respostas Definidas: "
            pergunta.defined.forEach(element => {
              resposta = resposta + "'" + element + "' "
            });
            return resposta;
          }
        } else {
          if(pergunta.defined !== '') {
            let resp = pergunta.options.find(p => p.value === pergunta.defined)
            return " - Resposta Definida: " + resp.text
          }
        }
      }
    },
    emitEnviarTeste() {
      this.enviaTeste.index = this.propIndex;
      this.$emit('resReferencia', this.enviaTeste);
      this.concluiRegistro()
    }
  }
}
</script>

<style>

</style>
