<template>
  <div class="content">
    <base-header class="pb-6">
    </base-header>
    <div class="container-fluid mt--6" >
      <card>
        <template slot="header">
          <div class="row justify-content-between">
            <div class="col-11 align-self-center">
              <h3 class="mb-0">Meu Perfil</h3>
            </div>
            <div class="col-1 align-self-center text-right">
              <i class="icone-help-bread-crumb text-default fas fa-question-circle"></i>
            </div>
          </div>
        </template>
        <div class="card-body px-0 py-0">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="row">
                <div class="col">
                  <p class="font-weight-normal">
                    Utilize o formulário abaixo para atualizar as suas informações.
                  </p>
                </div>
              </div>
              <!-- Divisor -->
              <div class="row">
                <div class="col-12">
                  <hr class="my-1">
                </div>
              </div>
              <!-- Divisor -->
              <div class="row">
                <div class="col-12">
                  <h3 class="text-muted">Dados de cadastro</h3>
                </div>
              </div>
              <!-- Nome / E-mail -->
              <div class="row">
                <div class="col-6">
                  <base-input label="Nome"
                    name="Nome"
                    placeholder="Nome"
                    success-message="Ok!"
                    rules="required"
                    v-model="editUser.nm_nome">
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input label="E-mail"
                    name="email"
                    placeholder="E-mail"
                    success-message="Ok!"
                    rules="required"
                    v-model="editUser.hl_email"
                    disabled>
                  </base-input>
                </div>
              </div>
              <!-- Departamento e Cargo -->
              <div class="row">
                <div class="col-6">
                  <base-input label="Departamento" name="Departamento">
                  <el-autocomplete
                    class="inuptAutocomplete"
                    v-model="editUser.nm_depto"
                    :fetch-suggestions="querySearchDepto"
                    placeholder="Departamento"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input label="Cargo" name="Cargo">
                    <el-autocomplete
                      class="inuptAutocomplete"
                      v-model="editUser.nm_cargo"
                      :fetch-suggestions="querySearchCargo"
                      placeholder="Cargo"
                      :trigger-on-focus="false"
                    ></el-autocomplete>
                  </base-input>
                </div>
              </div>
              <!-- CPF / Sexo / Cargo -->
              <div class="row">
                <div class="col-4">
                    <base-input label="CPF"
                      name="CPF"
                      placeholder="CPF"
                      v-model.lazy.trim="editUser.nu_cpf">
                    </base-input>
                </div>
                <div class="col">
                  <label for="sexo">Sexo</label>
                  <el-select
                    ref="sexo"
                    name="Sexo"
                    class="inuptAutocomplete"
                    id="sexo"
                    v-model="editUser.fl_sexo"
                  >
                    <el-option value="1" label="Masculino"></el-option>
                    <el-option value="2" label="Feminino"></el-option>
                    <el-option value="3" label="Não informado"></el-option>
                  </el-select>
                </div>
                <div class="col">
                  <label for="datanasc">Data de Nascimento</label>
                  <input
                    id="datanasc"
                    ref="datanasc"
                    type="date"
                    class="form-control"
                    name="Data de Nascimento"
                    placeholder="Data de Nascimento"
                    v-model.lazy.trim="editUser.dt_nasc"
                  />
                </div>
              </div>
              <!-- Telefone / Celular -->
              <div class="row">
                <div class="col-6">
                  <base-input label="Telefone"
                    name="telefone"
                    placeholder="Telefone"
                    v-model.lazy.trim="editUser.nu_telefone"
                  >
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input label="Celular"
                    name="Numero do Celular"
                    placeholder="Numero do Celular"
                    v-model.lazy.trim="editUser.nu_celular">
                  </base-input>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12 text-center">
                  <base-button type="primary" @click="atualizaPerfil" :disabled="!dadosAlterados">Atualizar</base-button>
                </div>
              </div>
              <!-- Divisor -->
              <div class="row">
                <div class="col-12">
                  <hr class="my-1">
                </div>
              </div>
              <!-- Titulo do bloco -->
              <div class="row">
                <div class="col-12">
                  <h3 class="text-muted">Foto de perfil</h3>
                </div>
              </div>
              <!-- Foto de perfil -->
              <div class="row mb-4">
                <div class="col-3" v-loading="imgChange">
                  <!-- <label for="hl_logo">Foto de perfil</label><br> -->
                  <img :src="novaFoto === '' ? editUser.hl_foto : novaFoto" class="img-fluid my-2" alt="Foto de Perfil"><br>
                  <span class="my-2">
                    <base-button type="warning" size="sm" @click="removerFoto">Remover</base-button>
                    <!-- <base-button type="success" size="sm" native-type="submit">Salvar</base-button> -->
                  </span>
                </div>
                <div class="col-9">
                  <form class="needs-validation" enctype="multipart/form-data">
                    <label for="hl_logo">Alterar a foto de perfil</label>
                    <div class="custom-file mt-3">
                      <label class="custom-file-label" for="customFileLang">
                        {{logo}}
                      </label>
                      <input type="file" class="custom-file-input" accept="image/png, image/jpeg" @change="onChangeLogo($event)" />
                    </div>
                    <template v-if="imageError !== ''">
                      <p><small class="text-danger">{{imageError}}</small></p>
                    </template>
                  </form>
                </div>
              </div>
              <!-- Divisor -->
              <div class="row">
                <div class="col-12">
                  <hr class="my-1">
                </div>
              </div>
              <!-- Titulo do bloco -->
              <div class="row">
                <div class="col-12">
                  <h3 class="text-muted">Alterar senha de acesso</h3>
                </div>
              </div>
              <!-- Nova senha -->
              <div class="row">
                <div class="col-6">
                  <base-input label="Nova Senha"
                    type="password"
                    name="Nova Senha"
                    minlength="6"
                    maxlength="16"
                    placeholder="Nova Senha"
                    v-model="novaSenha1">
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input label="Confirmar a senha"
                    type="password"
                    name="Confirmar senha"
                    minlength="6"
                    maxlength="16"
                    placeholder="Confirmar a senha"
                    v-model="novaSenha2">
                  </base-input>
                </div>
              </div>
              <!-- Linha de alerta de erro na senha -->
              <div class="row" v-if="erroSenha">
                <div class="col-12">
                  <p class="text-danger"><small>As senhas não conferem!</small></p>
                </div>
              </div>
              <!-- Botão de Atualizar senha -->
              <div class="row mb-4">
                <div class="col-12 text-center">
                  <base-button type="primary" :disabled="novaSenha1 !== novaSenha2" @click="atualizaSenha">Atualizar Senha</base-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import defaultMatchjob from '../../mixins/defaultMatchjob';
import {
  Loading,
  Select,
  Option,
  Autocomplete
} from 'element-ui';

Vue.use(Loading.directive);
export default {
  name: "PerfilUsuario",
  mixins: [defaultMatchjob],
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      imgChange: false,
      editUser: {},
      cargos: [],
      deptos: [],
      novaFoto: "",
      logo: "Selecione um arquivo...",
      imageError: "",
      novaFotoFile: "",
      novaSenha1: "",
      novaSenha2: "",
      erroSenha: false,
      dataLoaded: false,
      dadosAlterados: false
    }
  },
  methods: {
    async carregaUsuarios() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user
      }
      await this.$http.get('usuarios/meu-perfil', { params: data
      }).then(res => {
        this.editUser = res.data;
      })
      setTimeout(() => {
        this.dataLoaded = true;
      }, 500);
    },
    async loadSysInfos() {
      await this.$http.get('sysinfos/cargos', )
      .then( res => {
        this.cargos = res.data
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
      await this.$http.get('sysinfos/deptos', )
      .then( res => {
        this.deptos = res.data
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
    },
    //Função do Autocomplete de Departamentos
    querySearchDepto(queryString, cb) {
      let deptos = this.deptos;
      var results = queryString ? deptos.filter(this.createFilterDepto(queryString)) : deptos;
      cb(results)
    },
    createFilterDepto(queryString) {
      return (depto) => {
        return (depto.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    //Função do Autocomplete de Cargos
    querySearchCargo(queryString, cb) {
      let cargos = this.cargos;
      var results = queryString ? cargos.filter(this.createFilterCargo(queryString)) : cargos;
      cb(results)
    },
    createFilterCargo(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    async atualizaPerfil() {
      if (this.editUser.nm_nome !== '') {
        await this.$http.post('usuarios/atualizaperfil', this.editUser).then(() => {
          this.notificacao("Dados atualizados com sucesso!", 'success');
          this.dadosAlterados = false;
        }).catch(err => {
          this.notificacao("Falha na atualização dos dados!", 'warning');
          console.log(err);
        })
      }
    },
    async atualizaSenha() {
      if (this.novaSenha1 === this.novaSenha2) {
        const data = {
          id_empresa: this.id.empresa,
          id_empresa_user: this.id.empresa_user,
          senha: this.novaSenha1
        }
        await this.$http.post('usuarios/atualizasenha', data).then(() => {
          this.notificacao("Senha atualizada com sucesso!", 'success');
          this.erroSenha = false;
        }).catch(err => {
          this.notificacao("Falha na atualização da senha!", 'warning');
          console.log(err);
        })
      } else {
        this.novaSenha1 = "";
        this.novaSenha2 = "";
        this.erroSenha = true;
        this.notificacao("Não foi possível atualizar a senha!", 'warning');
      }
    },
    async onChangeLogo(event) {
      this.imgChange = true;
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.logo = "";
        this.novaFoto = "";
        this.novaFotoFile = "";
        this.imageError = "A imagem escolhida é maior que 1 MB. Ajuste ou troque a imagem"
      } else {
        this.logo = file.name;
        this.novaFotoFile = file;
        const form = new FormData();
        form.append("id_empresa_user", this.id.empresa_user);
        form.append("id_empresa", this.id.empresa);
        form.append("hl_foto", this.novaFotoFile);
        await this.$http.post('usuarios/atualizafoto', form)
          .then(res => {
            this.notificacao("Foto atualizada com sucesso!", 'success');
            this.editUser.hl_foto = res.data;
            this.dadosAlterados = false;
          })
          .catch(err => {
            this.notificacao("Não foi possível atualizar a Foto.", 'warning');
            console.log(err);
          });
        this.imageError = "";
        this.novaFoto = URL.createObjectURL(file);
      }
      this.imgChange = false;
    },
    async removerFoto() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user
      }
      await this.$http.post('usuarios/removefotoperfil', data).then(() => {
        this.notificacao("Foto atualizada com sucesso!", 'success');
        this.editUser.hl_foto = "https://storagematchjobdefault.blob.core.windows.net/matchjob/placeholder.jpg";
        this.dadosAlterados = false;
      }).catch(err => {
        this.notificacao("Não foi possível atualizar a Foto.", 'warning');
        console.log(err);
      })
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
  },
  mounted() {
    this.carregaUsuarios();
    this.loadSysInfos();
  },
  watch: {
    editUser: {
      handler(value){
        if (this.dataLoaded){
          this.dadosAlterados = true;
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
.icone-help-bread-crumb {
  font-size: 1.6rem;
  cursor: pointer;
}
</style>
