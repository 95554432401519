<template>
  <div>
    <div class="row justify-content-start align-items-start">
      <div class="col-12">
        Resposta de Teste -
        {{propItem.fl_tipo === 'enviaTestes' ? 'Teste Avaliativo' : 'Teste de Perfil '}} ({{retornaTipoTeste(propItem.nm_tipo)}})<br>
        Teste: <strong>{{retornaNomeTeste(propItem.fl_tipo, propItem.id_teste)}}</strong><br>
        Respondido: {{propItem.date | formatDateTime}}
        <template v-if="propItem.fl_tipo === 'testePerfil'">
          <span v-html="propItem.json_analise" />
        </template>
        <div class="linha-clique" @click="flDetalhes = !flDetalhes">
          <small class="text-primary">Ver Respostas</small> <i class="text-primary fas" :class="flDetalhes ? 'fa-times' : 'fa-plus'"></i>
        </div>
        <template v-if="flDetalhes">
        <p class="font-weight-normal">Respostas do Teste:</p>
        <ul>
          <template v-for="(pergunta, index) in propItem.json_resposta">
            <li :key="index">{{pergunta.value}}
              <ul v-if="pergunta.type === 'text'">
                <li>
                  <strong>{{pergunta.resp}}</strong>
                </li>
              </ul>
              <ul v-else-if="pergunta.type === 'dico'">
                <li><strong>{{pergunta.resp ? 'Sim' : 'Não'}}</strong> <template v-if="pergunta.defined !== ''">
                    <i v-if="pergunta.defined === pergunta.resp" class="text-success far fa-check-circle"></i>
                    <i v-else class="text-danger far fa-times-circle"></i>
                  </template>
                </li>
              </ul>
              <ul v-else-if="pergunta.type === 'ru'">
                <li><strong>{{pergunta.resp}}</strong> <template v-if="pergunta.defined !== ''">
                    <i v-if="validaRU(pergunta.defined, pergunta.resp)" class="text-success far fa-check-circle"></i>
                    <i v-else class="text-danger far fa-times-circle"></i>
                  </template>
                </li>
              </ul>
              <ul v-else-if="pergunta.type === 'multi'">
                <li v-for="(resp, index) in pergunta.resp" :key="index">
                  <strong>{{resp}}</strong> <template v-if="pergunta.defined !== ''">
                    <i v-if="validaMultiPorRestosta(pergunta.defined, resp)" class="text-success far fa-check-circle"></i>
                    <i v-else class="text-danger far fa-times-circle"></i>
                  </template>
                </li>
              </ul>
              <ul v-else-if="pergunta.type === 'nps'">
                <li>
                  <strong>{{pergunta.resp}} - <span v-html="validaNps(pergunta.resp)" /></strong>
                </li>
              </ul>
              <ul v-else-if="pergunta.type === 'likert'">
                <li>
                  <strong>{{pergunta.resp}}</strong>
                </li>
              </ul>
            </li>
          </template>
        </ul>
        </template>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import moment from "moment";

export default {
  name: "CompResultadoTeste",
  mixins: [defaultMatchjob],
  props: {
    propItem: { type: Object }
  },
  components: {},
  data(){
    return {
      usuarios: [],
      flDetalhes: false
    }
  },
  computed: {
    usuariosState() {
      return this.$store.state.usuarios.usuarios
    },
    testesPerfil() {
      return this.$store.state.testes.testesPerfil;
    },
    testes() {
      return this.$store.state.testes.testes;
    },
  },
  methods: {
    retornaUsuario(value) {
      if(this.usuariosState.length > 0) {
        var user = this.usuariosState.find(u => u.id_empresa_user === this.propItem.id_empresa_user)
        if(value === 'nome'){
          return user.nm_nome;
        } else if(value === 'foto'){
          return user.hl_foto;
        }
      }
    },
    retornaCategoriaTeste(tipo, id) {
      if(tipo === 'enviaTestes') {
        var teste = this.testes.find(t => t.id_teste === id)
      } else {
        var teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_categoria
    },
    retornaDescricaoTeste(tipo, id) {
      if(tipo === 'enviaTestes') {
        var teste = this.testes.find(t => t.id_teste === id)
      } else {
        var teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_descricao
    },
    retornaNomeTeste(tipo, id) {
      var teste = []
      if(tipo === 'enviaTestes') {
        teste = this.testes.find(t => t.id_teste === id)
      } else {
        teste = this.testesPerfil.find(t => t.id_teste === id)
      }
      return teste.nm_nome;
    },
    validaMultiPorRestosta(defined, resp) {
      var indexOfDefined = defined.findIndex(i => i === resp);
      if (indexOfDefined > -1) {
        return true;
      } else {
        return false;
      }
    },
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
    validaNps(resp) {
      if(resp < 7) {
        return '<span class=\"text-danger\">Detrator</span>'
      } else if(resp > 6 && resp < 9) {
        return '<span class=\"text-warning\">Passivo</span>'
      } else if(resp > 8) {
        return '<span class=\"text-success\">Promotor</span>'
      }
    },
    retornaTipoTeste(tipo) {
      if(tipo === 'simples') {
        return 'Simples'
      } else if(tipo === 'step') {
        return 'Simples Passo-a-passo'
      } else if(tipo === 'avaliacao') {
        return 'Avaliação técnica - Validação manual'
      } else if(tipo === 'auto') {
        return 'Avaliação técnica - Correção Automática'
      } else if(tipo === 'tempo') {
        return 'Avaliação técnica temporizada - Correção Automática'
      } else {
        return 'Teste de perfil Comportamental padrão'
      }
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        moment.locale('pt-br');
        return moment(String(value)).format("LLLL");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.usuarios = this.$store.state.usuarios.usuarios;
  }
}
</script>

<style>
.icones-caracteristicas-inativo {
  font-size: 1.6rem;
  cursor: pointer;
  color: darkgray;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.linha-clique {
  cursor: pointer;
}
</style>
