<template>
  <div v-loading="loading">
    <!-- Flag Aprovação do RH -->
    <div class="row">
      <div class="col-auto align-middle">
        <base-switch
          class="ml-2"
          v-model="publicacaoVaga.requerAprovacao"
        ></base-switch>
      </div>
      <div class="col align-middle">
        Precisa de Aprovação do RH?
      </div>
    </div>
    <!-- Flag Aprovação do Solicitante -->
    <div class="row">
      <div class="col-auto align-middle">
        <base-switch
          class="ml-2"
          v-model="publicacaoVaga.solicitanteAprova"
          @input="onChangeFlagSolicitante"
        ></base-switch>
      </div>
      <div class="col align-middle">
        Precisa de Aprovação do Solicitante?
      </div>
    </div>
    <!-- Flag Outros Aprovadores -->
    <div class="row">
      <div class="col-auto align-middle">
        <base-switch
          class="ml-2"
          v-model="publicacaoVaga.flOutrosAprovadores"
          @input="onChangeFlagOutrosAprovadores"
        ></base-switch>
      </div>
      <div class="col align-middle">
        Precisa de Outras Aprovações?
      </div>
    </div>
    <!-- Se aprovação RH -->
    <template v-if="publicacaoVaga.requerAprovacao">
      <div class="row my-2">
        <div class="col-auto">
          <label>Como será a aprovação de RH?</label>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="area"
              id="radioAprovacaoRhArea1" name="flTipoAprovacao"
              v-model="publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh"
            />
            <label class="form-check-label" for="radioAprovacaoRhArea1">Por Tipo de Acesso</label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" value="usuario"
              id="radioAprovacaoRhArea2" name="flTipoAprovacao"
              v-model="publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh"
            />
            <label class="form-check-label" for="radioAprovacaoRhArea2">Por usuário definido</label>
          </div>
        </div>
        <div class="col-4" v-if="publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh === 'area'">
          <label>Tipo de Acesso <i class="fas fa-question-circle"></i>
          </label>
          <el-select
            class="inuptAutocomplete"
            v-model="publicacaoVaga.aprovacoes.aprovacaoRh.tipoAcesso"
            placeholder="Tipo de acesso"
            @change="onChangeAreaRh"
          >
            <el-option
              v-for="tipo in tipoAcessoRh"
              :key="tipo.value"
              :label="tipo.text"
              :value="tipo.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-4" v-else-if="publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh === 'usuario'">
          <label for="areaatuacao">Usuários
              <i class="fas fa-question-circle"></i>
            </label>
            <el-select
              required
              filterable
              class="inuptAutocomplete"
              v-model="publicacaoVaga.aprovacoes.aprovacaoRh.usuario"
              placeholder="Usuários"
              @change="onChangeUsuarioRH"
            >
              <el-option
                v-for="user in usuariosRh"
                :key="user.id_empresa_user"
                :label="user.nm_nome"
                :value="user.id_empresa_user">
                <span><strong>{{user.nm_nome}}</strong> -
                <small>{{user.nm_cargo}}</small></span>
              </el-option>
            </el-select>
        </div>
      </div>
    </template>
    <!-- Se aprovação do Solicitante -->
    <template v-if="publicacaoVaga.solicitanteAprova">
      <div class="row my-3">
        <div class="col-auto">
          <h5>Solicitante da Vaga</h5>
          <p class="mb-0">Nome: <strong>{{publicacaoVaga.aprovacoes.solicitante.nm_nome}}</strong> - {{publicacaoVaga.aprovacoes.solicitante.hl_email}}</p>
        </div>
      </div>
    </template>
    <!-- Se aprovação de Outros -->
    <template v-if="publicacaoVaga.flOutrosAprovadores">
      <div class="row my-3">
        <div class="col-auto">
          <h5>Outros Aprovadores</h5>
          <div class="row">
            <div class="col-auto align-items-end align-self-end">
              <label for="outrosAprovadores">Selecione o usuário <span class="text-danger">*</span></label>
              <el-select
                ref="outrosAprovadores"
                name="outrosAprovadores"
                class="inuptAutocomplete"
                id="outrosAprovadores"
                :disabled="!publicacaoVaga.flOutrosAprovadores"
                v-model="addAprovadores"
              >
                <el-option
                  v-for="user in usuarios"
                  :key="user.id_empresa_user"
                  :value="user.id_empresa_user"
                  :label="user.nm_nome"
                ></el-option>
              </el-select>
            </div>
            <div class="col-auto align-items-end align-self-end">
              <base-button
                type="default"
                :disabled="addAprovadores === ''"
                size="sm"
                @click="addOutroAprovador"><i class="fas fa-plus"></i></base-button>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <p>Aprovadores Incluídos:</p>
          <ul v-if="this.publicacaoVaga.aprovacoes.outros.length > 0">
            <li v-for="(user, index) in this.publicacaoVaga.aprovacoes.outros" :key="index">
              <strong>{{user.nm_nome}}</strong> <span class="but-drop-outro" @click="removerOutroAprovador(user.id_empresa_user)"><i class="text-danger far fa-times-circle"></i></span>
            </li>
          </ul>
          <p v-else><em>Adicione um aprovador</em></p>
        </div>
      </div>
    </template>

    <!-- <div class="row">
      <div class="col">
        propFase -> <pre>{{propFase}}</pre>
      </div>
      <div class="col">
        publicacaoVaga -> <pre>{{publicacaoVaga}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading, Select, Option } from 'element-ui';

Vue.use(Loading.directive);

export default {
  name: "CompPublicacaoVaga",
  props: {
    propFase: { type: Object },
    propVaga: { type: Object },
    propEmit: { type: Boolean }
  },
  components:{
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      loading: false,
      addAprovadores: "",
      publicacaoVaga: {
        text: "Publicação da Vaga",
        aprovacoes: {
          outros: [],
          aprovacaoRh: {
            tipoRh: "",
            usuario: "",
            aprovado: false,
            aprovador: [],
            tipoAcesso: ""
          },
          solicitante: {
            nm_nome: "",
            aprovado: false,
            hl_email: "",
            id_empresa_user: ""
          }
        },
        requerAprovacao: false,
        solicitanteAprova: false,
        flOutrosAprovadores: false
      },
      tipoAcessoRh: [
        { value: '2', text: "Gerência de RH" },
        { value: '3', text: "Coordenação de RH" },
        { value: '4', text: "Recrutadora de RH" }
      ],
    }
  },
  computed: {
    usuarios() {
      return this.$store.state.usuarios.usuarios;
    },
    usuariosRh() {
      return this.$store.state.usuarios.usuarios.filter(r => r.fl_tipo_acesso > 0 && r.fl_tipo_acesso < 5);
    },
  },
  methods: {
    onChangeUsuarioRH(value) {
      var usuario = this.usuariosRh.find(u => u.id_empresa_user === value)
      if(usuario !== undefined) {
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador = [];
        var tempArr = {
          nm_nome: usuario.nm_nome,
          hl_email: usuario.hl_email,
          id_empresa_user: usuario.id_empresa_user
        }
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador.push(tempArr);
      } else {
        alert('Ops! Houve um erro ao selecionar esse usuário.')
      }
    },
    onChangeAreaRh(value) {
      var usuarios = this.usuariosRh.filter(u => u.fl_tipo_acesso === value)
      if(usuarios !== undefined) {
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador = [];
        usuarios.forEach(element => {
          let tempArr = {
            nm_nome: element.nm_nome,
            hl_email: element.hl_email,
            id_empresa_user: element.id_empresa_user
          }
          this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador.push(tempArr);
        });
      } else {
        alert('Nenhum usuário cadastrado nessa área do RH.')
      }
      //console.log('onChangeUsuarioRH -> ', usuarios)
    },
    onChangeFlagSolicitante() {
      if(this.publicacaoVaga.solicitanteAprova) {
        var solicitante = this.usuarios.find(u => u.nm_nome === this.propVaga.nm_solicitante);
        //console.log('solicitante -> ', solicitante)
        if(solicitante !== undefined) {
          if(this.validaAprovadorExistenteOutros(solicitante.id_empresa_user)) {
            this.$set(this.publicacaoVaga.aprovacoes, 'solicitante', {
              nm_nome: solicitante.nm_nome,
              aprovado: false,
              hl_email: solicitante.hl_email,
              id_empresa_user: solicitante.id_empresa_user
            })
          } else {
            this.removerOutroAprovador(solicitante.id_empresa_user)
            this.$set(this.publicacaoVaga.aprovacoes, 'solicitante', {
              nm_nome: solicitante.nm_nome,
              aprovado: false,
              hl_email: solicitante.hl_email,
              id_empresa_user: solicitante.id_empresa_user
            })
            alert('Este usuário removido como um outro aprovador e adicionado como Aprovador Solicitante.')
          }
        }
      } else {
        if(this.publicacaoVaga.aprovacoes.solicitante) {
          this.$delete(this.publicacaoVaga.aprovacoes, 'solicitante')
        }
      }
    },
    onChangeFlagOutrosAprovadores() {
      if(this.publicacaoVaga.flOutrosAprovadores) {
        this.$set(this.publicacaoVaga.aprovacoes, 'outros', [])
      } else {
        this.$delete(this.publicacaoVaga.aprovacoes, 'outros')
      }
    },
    addOutroAprovador() {
      if(this.validaAprovadorExistenteRh(this.addAprovadores)) {
        if(this.validaAprovadorExistenteSolicitante(this.addAprovadores)) {
          if(this.publicacaoVaga.aprovacoes.outros.length > 0) {
            var existente = this.publicacaoVaga.aprovacoes.outros.find(e => e.id_empresa_user === this.addAprovadores)
            if(existente === undefined) {
              var usuario = this.usuarios.find(u => u.id_empresa_user === this.addAprovadores)
              var tempArr = {
                nm_nome: usuario.nm_nome,
                aprovado: false,
                hl_email: usuario.hl_email,
                id_empresa_user: usuario.id_empresa_user
              }
              this.publicacaoVaga.aprovacoes.outros.push(tempArr)
            } else {
              alert('Este usuário já está definido como um outro aprovador.')
            }
          } else {
            var usuario = this.usuarios.find(u => u.id_empresa_user === this.addAprovadores)
            var tempArr = {
              nm_nome: usuario.nm_nome,
              aprovado: false,
              hl_email: usuario.hl_email,
              id_empresa_user: usuario.id_empresa_user
            }
            this.publicacaoVaga.aprovacoes.outros.push(tempArr)
          }
        } else {
          alert('Este usuário já está definido como um aprovador como Solicitante.')
        }
      } else {
        alert('Este usuário já está definido como um aprovador do RH.')
      }
      //console.log('usuario -> ', usuario)
    },
    validaAprovadorExistenteSolicitante(value) {
      var count = 0
      if(this.publicacaoVaga.solicitanteAprova) {
        if(this.publicacaoVaga.aprovacoes.solicitante.id_empresa_user === value) {
          count = count + 1
        }
      }
      if(count > 0) {
        return false;
      } else {
        return true;
      }
    },
    validaAprovadorExistenteRh(value) {
      var count = 0
      if(this.publicacaoVaga.requerAprovacao) {
        if(this.publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh === 'usuario') {
          var existe = this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador.find(e => e.id_empresa_user === value)
          if(existe !== undefined) {
            count = count + 1
          }
        } else if(this.publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh ===  'area') {
          var existe = this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador.find(e => e.id_empresa_user === value)
          if(existe !== undefined) {
            count = count + 1
          }
        }
      }
      if(count > 0) {
        return false;
      } else {
        return true;
      }
    },
    validaAprovadorExistenteOutros(value) {
      //console.log('entrou...')
      if(this.publicacaoVaga.flOutrosAprovadores) {
        if(this.publicacaoVaga.aprovacoes.outros.length > 0) {
          var existente = this.publicacaoVaga.aprovacoes.outros.find(e => e.id_empresa_user === value)
          //console.log('existente -> ', existente)
          if(existente !== undefined) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    removerOutroAprovador(value) {
      var pos = this.publicacaoVaga.aprovacoes.outros.findIndex(i => i.id_empresa_user === value);
      this.publicacaoVaga.aprovacoes.outros.splice(pos, 1);//Remove o Objeto do Array
      var tam = this.publicacaoVaga.aprovacoes.outros.length;//Verifica o tamanho do array após a remoção
      if (tam < 1) {//valida se é o último do array
        this.publicacaoVaga.flOutrosAprovadores = false;//Desativa o flag de outros aprovadores > Último usuário do array
        this.$delete(this.publicacaoVaga.aprovacoes, 'outros')
      }
    },
    firstFormSubmit() {
      this.$emit('emitPublicacao', this.publicacaoVaga);
    }
  },
  mounted() {
    this.publicacaoVaga = structuredClone(this.propFase);
    /* this.publicacaoVaga.text = this.propFase.text;
    this.publicacaoVaga.flOutrosAprovadores = this.propFase.flOutrosAprovadores;
    if(this.propFase.flOutrosAprovadores) {
      this.publicacaoVaga.aprovacoes.outros = this.propFase.aprovacoes.outros;
    }
    this.publicacaoVaga.solicitanteAprova = this.propFase.solicitanteAprova;
    if(this.propFase.solicitanteAprova) {
      this.publicacaoVaga.aprovacoes.solicitante.nm_nome = this.propFase.aprovacoes.solicitante.nm_nome;
      this.publicacaoVaga.aprovacoes.solicitante.aprovado = this.propFase.aprovacoes.solicitante.aprovado;
      this.publicacaoVaga.aprovacoes.solicitante.hl_email = this.propFase.aprovacoes.solicitante.hl_email;
      this.publicacaoVaga.aprovacoes.solicitante.id_empresa_user = this.propFase.aprovacoes.solicitante.id_empresa_user;
    }
    this.publicacaoVaga.requerAprovacao = this.propFase.requerAprovacao;
    if(this.propFase.requerAprovacao) {
      this.publicacaoVaga.aprovacoes.aprovacaoRh.tipoRh = this.propFase.aprovacoes.aprovacaoRh.tipoRh;
      if(this.propFase.aprovacoes.aprovacaoRh.tipoRh === 'usuario') {
        this.publicacaoVaga.aprovacoes.aprovacaoRh.usuario = this.propFase.aprovacoes.aprovacaoRh.usuario;
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovado = this.propFase.aprovacoes.aprovacaoRh.aprovado;
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador = this.propFase.aprovacoes.aprovacaoRh.aprovador;
      } else if (this.propFase.aprovacoes.aprovacaoRh.tipoRh ===  'area') {
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovado = this.propFase.aprovacoes.aprovacaoRh.aprovado;
        //this.$set(this.publicacaoVaga.aprovacoes.aprovacaoRh, 'tipoAcesso', this.propFase.aprovacoes.aprovacaoRh.tipoAcesso)
        this.publicacaoVaga.aprovacoes.aprovacaoRh.tipoAcesso = this.propFase.aprovacoes.aprovacaoRh.tipoAcesso;
        this.publicacaoVaga.aprovacoes.aprovacaoRh.aprovador = this.propFase.aprovacoes.aprovacaoRh.aprovador;
      }
    } */
  },
  watch: {
    propEmit: {
      handler(value){
        this.firstFormSubmit();
      }
    }
  }
}
</script>

<style scoped>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
.but-drop-outro {
  font-size: 0.9rem;
  cursor: pointer;
}
</style>
