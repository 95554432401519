<template>
  <div>
    <div class="row mt-3">
      <div class="col-12">
        <h5>{{propTitulo}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        {{propTexto}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumoInfoExtra",
  props: {
    propTexto: { type: String },
    propTitulo: { type: String }
  }
}
</script>

<style>

</style>
