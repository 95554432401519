<template>
  <div>
    <!-- <div class="row justify-content-center mt-2">
      <div class="col text-center">
        <h1 class="display-2">Fases do Processo Seletivo</h1>
      </div>
    </div> -->
    <div class="row justify-content-center mt-2">
      <div class="col-8 text-center">
        <h1 class="display-4">Defina as fases que compõe esse processo seletivo.</h1>
      </div>
    </div>
    <!-- Linha de adição de Fases -->
    <div class="row justify-content-center mt-2 mb-4">
      <div class="col-3 align-self-center pr-1">
        <label>Adicionar nova fase:</label>
        <el-select
          class="inuptAutocomplete"
          v-model="addFase.tipo"
          placeholder="Escolha o tipo da fase"
        >
          <el-option
            v-for="fase in tipoFases"
            :key="fase.id"
            :label="fase.text"
            :value="fase.tipo"
            :disabled="fase.disabled">
          </el-option>
        </el-select>
      </div>
      <!-- Input do Nome da Fase -->
      <div class="col-3 align-self-center pr-1">
        <label>Adicionar nova fase:</label>
        <el-input
          id="nomeNovaFase"
          ref="nomeNovaFase"
          type="text"
          class="inuptAutocomplete"
          maxlength="100"
          name="Noma da Fase"
          placeholder="Noma da Fase"
          v-model="addFase.value"
        />
      </div>
      <div class="col-1 align-self-end px-1">
        <base-button type="default" class="btn-icon-only" @click="addNovaFase"><i class="fas fa-plus"></i></base-button>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-10">
        <collapse>
        <template v-for="fase in propFases">
          <collapse-item-custom :id="fase.id" :ref="`tab${fase.id}`" :key="fase.id" :active="fase.tab" @active="fase.tab = $event">
            <div slot="title">
              <h3><span class="text-muted">{{fase.id}}ª Fase - </span>{{fase.value}} <template v-if="fase.required">
                <el-tooltip
                  content="Fase Obrigatória"
                  placement="top"
                ><i class="text-red fas fa-certificate"> </i>
                </el-tooltip></template>
                <template  v-else>
                  <small class="text-muted" v-if="fase.tipo === 'enviaTestes'"> - Envio de Teste</small>
                  <small class="text-muted" v-if="fase.tipo === 'testePerfil'"> - Teste de Perfil</small>
                  <small class="text-muted" v-else-if="fase.tipo === 'entrevistas'"> - Entrevista</small>
                  <small class="text-muted" v-else-if="fase.tipo === 'dinamica'"> - Dinâmica</small>
                  <small class="text-muted" v-else-if="fase.tipo === 'validacaoDados'"> - Validação de Dados</small>
                  <small class="text-muted" v-else-if="fase.tipo === 'negociacao'"> - Negociação</small>
                </template>
              </h3>
            </div>
            <div slot="buts">
              <template v-if="!fase.required">
                <!-- botao subir -->
                <el-tooltip content="Subir" placement="top" effect="light">
                  <base-button
                    type="secondary"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    v-if="butUp(fase.id)"
                    @click="moveUp(fase.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-arrow-up"></i>
                    </span>
                  </base-button>
                </el-tooltip>
                <!-- botao descer -->
                <el-tooltip content="Descer" placement="top" effect="light">
                  <base-button
                    type="secondary"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    v-if="butDown(fase.id)"
                    @click="moveDown(fase.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-arrow-down"></i>
                    </span>
                  </base-button>
                </el-tooltip>
                <!-- botao excluir -->
                <el-tooltip content="Remover" placement="top" effect="light">
                  <base-button
                    type="danger"
                    size="sm"
                    class="btn-icon-only rounded-circle"
                    @click="removeFase(fase.id)"
                  >
                    <span class="btn-inner--icon">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </base-button>
                </el-tooltip>
              </template>
              <!-- botao Abrir -->
              <el-tooltip :content="!fase.tab ? 'Abrir' : 'Fechar'" placement="top" effect="light">
                <base-button
                  :type="!fase.tab ? 'info' : 'default'"
                  size="sm"
                  tag="a"
                  class="btn-icon-only rounded-circle"
                  @click="fase.tab = !fase.tab"
                >
                  <span class="btn-inner--icon">
                    <i :class="!fase.tab ? 'fas fa-folder-open' : 'fas fa-folder'"></i>
                  </span>
                </base-button>
              </el-tooltip>
            </div>
            <!-- Componentes das fases -->
            <div class="row justify-content-center mt-2">
              <div class="col">
                <PublicacaoVagaConfig
                  v-if="fase.tipo === 'publicacaoVaga'"
                  :propPublicacaoVaga="fase.configs"
                />
                <TriagemConfig
                  v-if="fase.tipo === 'triagem'"
                  :propTriagem="fase.configs"
                />
                <EnvioTestesConfig
                  v-if="fase.tipo === 'enviaTestes'"
                  :propEnviaTestes="fase"
                  :propTestes="propTestes"
                />
                <EntrevistaConfig
                  v-if="fase.tipo === 'entrevistas'"
                  :propEntrevistaConfig="fase"
                />
                <TestesPerfil
                  v-if="fase.tipo === 'testePerfil'"
                  :propTestesPerfil="fase"
                  :propTestes="propTestesPerfil"
                />
                <template v-if="fase.tipo === 'dinamica' || fase.tipo === 'validacaoDados' || fase.tipo === 'negociacao'">
                  <!-- Linha do nome da fase -->
                  <div class="row justify-content-center mb-3">
                    <div class="col-6 align-self-center">
                      <label :for="`faseId${fase.id}`">Nome da fase</label>
                      <input
                        :id="`faseId${fase.id}`"
                        :ref="`faseId${fase.id}`"
                        type="text"
                        class="form-control"
                        name="Nome da Fase"
                        v-model.trim.lazy="fase.value"
                        @blur="validaOnBlur(fase.tipo, fase.id)"
                      />
                    </div>
                  </div>
                  <div class="row justify-content-center mt-3">
                    <div class="col-8 align-self-center text-center">
                      <p>A fase está ativada e não possui nenhuma configuração específica.</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </collapse-item-custom>
        </template>
        </collapse>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col text-center">
        <base-button type="secondary" class="mr-3 ml-auto" @click="voltarStep3">Voltar</base-button>
        <base-button type="primary" class="mr-3 ml-auto" @click="concluiStep3">Proximo</base-button>
      </div>
    </div>
    <!-- <hr>
    <pre>addFase -> {{addFase}}</pre>
    <hr>
    tipoFases -> <pre>{{tipoFases}}</pre>
    <hr>
    <div class="row">
      <div class="col-6">
        propFases -> <pre>{{propFases}}</pre>
      </div>
      <div class="col-6">
        propFasesConfig -> <pre>{{propFasesConfig}}</pre>
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue';
import {
  Loading,
  Input,
  Select,
  Option
} from 'element-ui';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItemCustom from '@/components/Collapse/CollapseItemCustom.vue';
import swal from 'sweetalert2';
import PublicacaoVagaConfig from '../PublicacaoVagaConfig.vue';
import TriagemConfig from '../TriagemConfig.vue';
import EnvioTestesConfig from '../EnvioTestesConfig.vue';
import EntrevistaConfig from '../EntrevistaConfig.vue';
import TestesPerfil from '../TestesPerfil.vue';

Vue.use(Loading.directive);

export default {
  name: "FasesProcesso",
  props: {
    propFases: { type: Array },
    propFasesConfig: { type: Object },
    propUsuarios: { type: Array },
    propTestes: { type: Array },
    propTestesPerfil: { type: Array },
    concluiStep3: { type: Function },
    voltarStep3: { type: Function }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    Collapse,
    CollapseItemCustom,
    PublicacaoVagaConfig,
    TriagemConfig,
    EnvioTestesConfig,
    EntrevistaConfig,
    TestesPerfil
  },
  data() {
    return {
      fasesDefinidas: [ //REMOVER
        /* { id: 1, tipo: "publicacaoVaga", value: "Publicação da Vaga", required: true, tab: false },
        { id: 2, tipo: "triagem", value: "Triagem de Currículos", required: true, tab: false },
        { id: 3, tipo: "contratacao", value: "Contratação", required: true, tab: false }, */
        { id: 1, tipo: "publicacaoVaga", value: "Publicação da Vaga", required: true, tab: false },
        { id: 2, tipo: "triagem", value: "Triagem de Currículos", required: true, tab: false },
        { id: 3, tipo: "testePerfil", value: "Teste de Perfil", required: false, tab: false },
        { id: 4, tipo: "enviaTestes", value: "Avaliação Técnica", required: false, tab: false },
        { id: 5, tipo: "entrevistas", value: "Entrevista com o RH", required: false, tab: false },
        { id: 6, tipo: "dinamica", value: "Dinâmica", required: false, tab: false },
        { id: 7, tipo: "entrevistas", value: "Entrevista com o Gestor", required: false, tab: false },
        { id: 8, tipo: "validacaoDados", value: "Validação dos Dados", required: false, tab: false },
        { id: 9, tipo: "negociacao", value: "Negociação Salarial", required: false, tab: false },
        { id: 10, tipo: "contratacao", value: "Contratação", required: true, tab: false }
      ],
      addFase: {
        tipo: "",
        value: ""
      },
      tipoFases: [],
      tempFases: {},
      faseAtiva: []
    }
  },
  methods: {
    initValues() {
      this.tempFases = this.propFasesConfig;
      setTimeout(() => {
        let num = 0
        Object.keys(this.tempFases).forEach(element => {
          if (this.tempFases[element].ativo && !this.tempFases[element].required){
            var temp = {
              id: num++, tipo: element, text: this.tempFases[element].text, disabled: false
            }
            this.tipoFases.push(temp);
          }
        });
        var itemProp = this.propFases.find( item => item.tipo === 'testePerfil');
        if (itemProp) {
          var itemFases = this.tipoFases.findIndex( item => item.tipo === 'testePerfil');
          if (itemFases) {
            this.tipoFases[itemFases].disabled = true;
          }
        }
      }, 500);
    },
    editFormSubmit() {
      this.concluiStep3();
    },
    filtraUsuariosRh() {
      this.usuariosRh = this.propUsuarios.filter((item) => {
        if (item.fl_tipo_acesso > 0 && item.fl_tipo_acesso < 5) {
          return true;
        } else {
          return false;
        }
      });
    },
    addNovaFase() {
      if (this.addFase.tipo === '' || this.addFase.value === '') {
        this.notificacao('Preencha corretamente os campos da nova fase!', 'warning');
      } else {
        let tam = Object.keys(this.propFases).length;
        let newItem = this.ajustaAddFase(tam);
        const lastItem = this.propFases.pop();
        lastItem.id = tam + 1;
        this.propFases.push(newItem);
        this.propFases.push(lastItem);
        this.notificacao('Fase adicionada!', 'success');
        this.resetAddFase();
      }
    },
    ajustaAddFase(tam) {
      let newItem = new Object;
      newItem = {
        id: tam,
        tipo: this.addFase.tipo,
        value: this.addFase.value,
        required: false,
        tab: false
      };
      if (this.addFase.tipo === 'enviaTestes') {
        this.$set(newItem, 'testesPadrao', []);
        this.$set(newItem, 'testesExternos', '');
        this.$set(newItem, 'tipoTeste', 'interno');
        newItem.testesPadrao = structuredClone(this.propFasesConfig.enviaTestes.testesPadrao);
        newItem.testesExternos = structuredClone(this.propFasesConfig.enviaTestes.testesExternos);
      } else if (this.addFase.tipo === 'testePerfil') {
        this.$set(newItem, 'configs', {});
        newItem.configs = structuredClone(this.propFasesConfig.testePerfil);
        this.alteraTipoFases();
      } else if (this.addFase.tipo === 'entrevistas') {
        this.$set(newItem, 'configs', {entrevistaRH: false, entrevistaSolicitante: false})
      }
      return newItem;
    },
    alteraTipoFases() {
      var pos = this.tipoFases.findIndex( item => item.tipo === 'testePerfil');
      this.tipoFases[pos].disabled = !this.tipoFases[pos].disabled;
      //console.log('pos -> ', this.tipoFases[pos])
    },
    resetAddFase() {
      this.addFase.tipo = "";
      this.addFase.value = "";
    },
    butUp(id){
      var pos = this.propFases.findIndex( item => item.id === id);
      if (pos <= 2) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      var pos = 2
      pos += this.propFases.findIndex( item => item.id === id);
      if (this.propFases.length === pos){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.propFases.findIndex( item => item.id === idNow);
      var pos1 = this.propFases.findIndex( item => item.id === idNext);
      var arr0 = this.propFases.find( item => item.id === idNow);
      var arr1 = this.propFases.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propFases.splice(pos0, 1, arr1);
      this.propFases.splice(pos1, 1, arr0);
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.propFases.findIndex( item => item.id === idNow);
      var pos1 = this.propFases.findIndex( item => item.id === idNext);
      var arr0 = this.propFases.find( item => item.id === idNow);
      var arr1 = this.propFases.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.propFases.splice(pos0, 1, arr1);
      this.propFases.splice(pos1, 1, arr0);
    },
    removeFase(id) {
      var pos = this.propFases.findIndex( item => item.id === id);
      //console.log('pos inicial -> ', pos)
      var tam = this.propFases.length;
      //console.log('tam inicial -> ', tam)
      swal.fire({
       title: 'ATENÇÃO!',
        html: 'Você realmente deseja excluir essa fase?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
      }).then(result => {
        if (result.value) {
          var item = this.propFases.find( item => item.id === id);
          if (item.tipo === 'testePerfil') {
            this.alteraTipoFases();
          }
          if (tam === (pos + 2)){//valida se é o ÚLTIMO possivel
            //console.log('pos depois do if -> ', pos)
            let num = pos+1;
            //console.log('num depois do if -> ', num)
            this.propFases[num].id = this.propFases[num].id-1;
            this.propFases.splice(pos, 1);
          } else {
            //console.log('retornou falso -->>>>')
            for (var i = pos+1; i < tam; i++){
              this.propFases[i].id = this.propFases[i].id-1;
            }
            this.propFases.splice(pos, 1);
          }
        }
      });
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 8000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    alertShow(title, msgError, icon) {
      swal.fire({
        title: title,
        text: msgError,
        icon: icon
      });
    },
    validaOnBlur(value, id){
      var pos = this.propFases.findIndex(i => i.id === id)
      if (this.propFases[pos].value === '') {
        if (value === 'dinamica') {
          this.propFases[pos].value = 'Dinâmica'
        } else if (value === 'validacaoDados') {
          this.propFases[pos].value = 'Validação de Dados'
        } else if (value === 'negociacao') {
          this.propFases[pos].value = 'Negociação'
        }
      }
    },
  },
  mounted() {
    this.initValues();
    this.filtraUsuariosRh();
  }
}
</script>

<style scoped>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
