<template>
  <div>
    <div class="row my-3" v-for="question in propPerguntas" :key="question.id">
      <div class="col">
        Pergunta: <em>{{ question.value }}</em><br>
        <template v-if="question.type === 'text'">
          Resposta: <strong><em>{{ question.resp }}</em></strong>
        </template>
        <template v-else-if="question.type === 'dico' || question.type === 'ru' || question.type === 'likert'">
          Resposta: <strong><em>{{ retornaNomeOpcao(question.type, question.options, question.resp) }}</em></strong>
        </template>
        <template v-else-if="question.type === 'multi'">
          Resposta<template v-if="question.resp.length > 1">s</template>: <strong v-for=" resp in question.resp" :key="resp"><em>[{{ resp }}] </em></strong>
        </template>
        <template v-if="question.type === 'nps'">
          Resposta: <strong><em>Nota: {{ question.resp }}</em></strong>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "ApresentaResultados",
  props: {
    propPerguntas: { type: Array }
  },
  data() {
    return {
      corretas: "",
      total: ""
    }
  },
  methods: {
    validaMulti(defined, resp) {
      if (defined === '') {
        return false;
      } else if (defined.length !== resp.length) {
        return false;
      } else {
        if(typeof defined !== 'object') {
          return false;
        }
        const uniqueValues = new Set([...defined, ...resp]);
        for (const v of uniqueValues) {
          const a = defined.filter(e => e === v).length;
          const b = resp.filter(e => e === v).length;
          if ( a !== b ) return false;
        }
        return true;
      }
    },
    validaRU(defined, resp){
      if (typeof defined === 'string' && typeof resp === 'string'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      } else if (typeof defined === 'boolean' && typeof resp === 'boolean'){
        if (defined === '') {
          return false;
        } else if (defined === resp) {
          return true;
        } else {
          return false;
        }
      }
    },
    retornaNomeOpcao(tipo, options, value){
      if (tipo === 'dico' || tipo === 'ru') {
        var res = options.find(i => i.value === value);
        return res.text;
      } else if (tipo === 'likert') {
        var res = options.find(i => i.id === value);
        return res.text;
      }
    }
  },
  mounted() {}
}
</script>

<style>

</style>
