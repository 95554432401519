<template>
  <div>
    <modal :show="propShow" :showClose="false" size="lg">
      <h6 slot="header" class="modal-title">Enviar Proposta de Emprego</h6>
      <div class="row">
        <div class="col">
          <label for="apenasRegistrar">Deseja apenas registrar o envio da Proposta de Emprego?</label><br>
          <base-switch
            ref="apenasRegistrar"
            v-model="novaProposta.apenasRegistro"
          ></base-switch>
        </div>
      </div>
      <template v-if="plano.nm_plano === '09300df44b9d20cc219b25abddc3346e'">
        <div class="row">
          <div class="col">
            <label for="apenasRegistrar">Deseja anexar um arquivo de Carta Proposta?</label><br>
            <base-switch
              ref="apenasRegistrar"
              v-model="fl_anexar"
            ></base-switch>
          </div>
        </div>
        <template v-if="fl_anexar">
          <div class="row" v-if="novaProposta.linkArquivoEnviado === ''">
            <div class="col">
              <form class="needs-validation" enctype="multipart/form-data">
                <div class="custom-file my-3">
                  <label class="custom-file-label" for="customFileLang">
                    {{nomeArquivoAnexado}}
                  </label>
                  <input type="file" class="custom-file-input" accept="application/pdf" @change="onChangeLogo($event)" />
                </div>
                <base-button type="primary" class="mr-3 ml-auto" v-if="flButEnviar" @click="uploadArquivo">Gravar Arquivo</base-button>
              </form>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              Link da Carta Proposta: <a :href="novaProposta.linkArquivoEnviado" target="_blank">{{novaProposta.linkArquivoEnviado}}</a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col">
              <label for="linkCartaProposta">Link da Carta Proposta: <span class="text-danger">*</span></label>
              <input
                id="linkCartaProposta"
                ref="linkCartaProposta"
                type="text"
                class="form-control"
                name="Link da Carta Proposta"
                placeholder="Link da Carta Proposta"
                v-model="novaProposta.linkArquivoEnviado"
                @change="onChangeLinkCarta"
              />
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="row">
          <div class="col">
            <label for="linkCartaProposta">Link da Carta Proposta: <span class="text-danger">*</span></label>
            <input
              id="linkCartaProposta"
              ref="linkCartaProposta"
              type="text"
              class="form-control"
              name="Link da Carta Proposta"
              placeholder="Link da Carta Proposta"
              v-model="novaProposta.linkArquivoEnviado"
              @change="onChangeLinkCarta"
            />
          </div>
        </div>
      </template>
      <div class="row" v-if="this.mensagens.length > 1">
        <div class="col">
          <label for="selectMensagemCandidato">Selecione o Template da Mensagem<span class="text-danger">*</span></label>
          <el-select
            ref="selectMensagemCandidato"
            name="Selecionar Mensagem"
            class="inuptAutocomplete"
            label="Selecionar Mensagem"
            id="selectMensagemCandidato"
            v-model="msgtemplate"
            @change="selecionaMensagem"
          >
          <el-option
            v-for="(msg, index) in mensagens"
            :key="index"
            :value="msg.id_empresa_msg_templates"
            :label="msg.nm_titulo"
          >
            <span style="float: left">{{ msg.nm_titulo }}</span>
            <span style="float: right; color: #8492a6; font-size: 11px">{{ msg.nm_descricao }}</span>
          </el-option>
        </el-select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <base-button :outline="fl_editor" size="sm" type="primary" @click="mudaEditor('visual')">Editor Visual</base-button>
          <base-button :outline="fl_mostraMensagem" size="sm" type="primary" @click="mudaEditor('ver')">Visualizar Mensagem</base-button>
        </div>
      </div>
      <div class="row justify-content-center my-3">
        <div class="col" v-show="fl_editor">
          <label for="textoProposta">Texto da Proposta</label>
          <trumbowyg v-model="novaProposta.tx_proposta" :config="config" class="form-control" name="content"></trumbowyg>
        </div>
        <div class="col" v-show="fl_mostraMensagem">
          <label for="textoPropostahtml">Texto da Proposta</label>
          <div class="box-mensagem-candidato">
            <span v-html="novaProposta.tx_proposta" />
          </div>
        </div>
      </div>
      <!-- {{novaProposta}} -->
      <!-- {{Object.keys(propCandidato)}} -->
      <div slot="footer">
        <base-button type="secondary" class="ml-3" @click="closeModal">Cancelar</base-button>
        <base-button type="primary" class="mr-3 ml-auto" @click="emitNegociacao">Enviar</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';
import { Select, Option } from 'element-ui';
import { add, format } from 'date-fns';

export default {
  name: "CompNegociacao",
  components: {
    Trumbowyg,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    propCandidato: { type: Object }, //Conteudo da timeline do candidato
    propVaga: { type: Object },// String com o flag do tipo da entrevista
    //propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    concluiRegistro: { type: Function },
    closeModal: { type: Function },
    propShow: { type: Boolean }
  },
  data() {
    return {
      fl_editor: false,
      fl_mostraMensagem: true,
      fl_anexar: true,
      msgtemplate: "",
      novaProposta: {
        apenasRegistro: false,
        tx_proposta: "",
        linkArquivoEnviado: "",
        url: "",
        dataLimite: ""
      },
      config: {
        lang: 'pt',
        autogrow: true,
        removeformatPasted: true,
        // Adding color plugin button
        btnsAdd: ['foreColor', 'backColor'],
        // Limit toolbar buttons
        btns: [
          ['viewHTML'],
          ['undo', 'redo'],
          ['bold', 'italic'], ['formatting'], ['link'],
          ['foreColor'], ['backColor'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
        ]
      },
      flButEnviar: false,
      nomeArquivoAnexado: "Anexar Carta Proposta",
      arquivoAnexado: null,
    }
  },
  computed: {
    empresa() {
      return this.$store.state.empresa.empresa;
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
    mensagens() {
      var tempMsg = this.$store.state.empresa.mensagens.filter(stMsg => stMsg.fl_tipo_mensagem === 'negociacao');
      return tempMsg
    },
  },
  methods: {
    onChangeLogo(event) {
      const file = event.target.files[0];
      this.nomeArquivoAnexado = file.name;
      this.arquivoAnexado = file;
      this.flButEnviar = true;
    },
    async uploadArquivo() {
      const form = new FormData();
      form.append("arquivo", this.arquivoAnexado);
      form.append("id_empresa", this.empresa.id_empresa);
      form.append("tipoArquivo", 'Carta Proposta');
      form.append("nomeArquivo", this.makeName(this.propCandidato.nm_nome));
      form.append("nomeEmpresa", this.makeName(this.empresa.nm_fantasia));
      form.append("urlAmigavel", this.empresa.hl_url_amigavel);
      form.append("id_processo_vaga", this.propVaga.id_processo_vaga);
      form.append("id_candidato", this.propCandidato.id_candidato);
      form.append("hl_url", this.propCandidato.id_candidato);
      await this.$http.post('vagas/enviararquivo', form)
        .then(res => {
          if(res.data.status){
            this.novaProposta.linkArquivoEnviado = res.data.url
            this.notificacao("Arquivo enviado com sucesso!", 'success');
            if(this.mensagens.length < 2) {
              this.selecionaMensagem(this.mensagens[0].id_empresa_msg_templates)
            }
          } else {
            this.notificacao("Falha no envio do arquivo!", 'danger');
          }
        })
        .catch(err => {
          this.notificacao("Não foi possível enviar o arquivo.", 'warning');
          console.log(err);
        });
    },
    mudaEditor(editor) {
      if(editor === 'visual') {
        this.fl_editor = true;
        this.fl_mostraMensagem = false;
      } else if(editor === 'ver'){
        this.fl_editor = false;
        this.fl_mostraMensagem = true;
      }
    },
    fecharModal() {
      this.closeModal();
    },
    selecionaMensagem(id) {
      var mensagem = this.mensagens.find(m => m.id_empresa_msg_templates === id);
      this.novaProposta.tx_proposta = this.trocaPalavras(mensagem.tx_mensagem);
    },
    trocaPalavras(texto) {
      var listaVariaveis = [
        { nome: "CandidatoNome", valor: this.propCandidato.nm_nome },
        { nome: "TituloVaga",  valor: this.propVaga.nm_cargo },
        { nome: "NomeEmpresa", valor: this.empresa.nm_fantasia },
        { nome: "EmpresaSite", valor: this.empresa.hl_site },
        { nome: "LinkArquivo", valor: this.novaProposta.linkArquivoEnviado },
        { nome: "LinkPortalAceiteProposta", valor: 'https://vagas.matchjob.com.br/' + this.empresa.hl_url_amigavel + '/aceite-de-proposta/' + this.novaProposta.url }
      ];
      // Expressão regular para identificar variáveis
      const regex = /%(.+?)%/g;
      // Loop para iterar sobre as variáveis no texto
      while (true) {
        // Encontrar a próxima variável
        const match = regex.exec(texto);
        // Se não encontrar mais variáveis, sair do loop
        if (!match) {
          break;
        }
        // Obter o nome da variável
        const nomeVariavel = match[1];
        // Encontrar a variável na lista
        const variavelEncontrada = listaVariaveis.find(
          (variavel) => variavel.nome === nomeVariavel
        );
        // Se a variável não for encontrada, pular para a próxima
        if (!variavelEncontrada) {
          continue;
        }
        // Substituir a variável pelo valor
        texto = texto.replace(match[0], variavelEncontrada.valor);
      }
      // Retornar o texto com as variáveis substituídas
      return texto;
    },
    makeName(valor) {
      var result = valor.replace(/[^a-z0-9_]+/gi, '').replace(/^-|-$/g, '');
      return result;
    },
    notificacao(msg, tipo) {
      this.$notify({
        message: msg,
        timeout: 4000,
        icon: 'ni ni-bell-55',
        type: tipo
      });
    },
    onChangeLinkCarta() {
      if(this.mensagens.length < 2) {
        this.selecionaMensagem(this.mensagens[0].id_empresa_msg_templates)
      }
    },
    makeUrl() {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 50; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    emitNegociacao() {
      this.$emit('resNegociacao', this.novaProposta);
      this.concluiRegistro()
    }
  },
  mounted() {
    this.novaProposta.url = this.makeUrl();
    var dataHoje = add(Date.now(), {days: 15});
    this.novaProposta.dataLimite = format(dataHoje, 'yyyy-MM-dd');
  }
}
</script>

<style>
.box-mensagem-candidato {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
