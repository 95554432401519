<template>
  <!-- <div>
    <card bodyClasses="px-3 py-3"> -->
    <base-nav class="navbar-top border-bottom navbar-expand mt--4" container-classes="container" type="quase-branco">
      <div class="row">
        <div class="col-auto" v-if="flbutAcoesRapidas.editarVaga">
          <el-tooltip placement="top" content="Editar a Vaga" effect="light">
            <base-button type="secondary" size="sm" @click="emitFuncao('editarVaga')">Editar a Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.editarProcesso">
          <el-tooltip placement="top" content="Editar o Processo Seletivo" effect="light">
            <base-button type="secondary" size="sm" @click="emitFuncao('editarProcesso')">Editar o PS</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.goto">
          <el-tooltip placement="top" content="Direciona para o Processo Seletivo" effect="light">
            <base-button type="success" size="sm" @click="goToVaga()">Ver Candidatos</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.alteraData">
          <el-tooltip placement="top" content="Aterar a previsão de fechamento da vaga" effect="light">
            <base-button type="primary" size="sm" @click="flbutAcoesRapidas.alterarData = !flbutAcoesRapidas.alterarData">Estender Prazo</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.linkedin">
          <el-tooltip placement="top" content="Publicação no LinkedIn" effect="light">
            <!-- <base-button type="info" size="sm" @click="emitFuncao('linkedin')"><i class="fab fa-linkedin"></i> LinkedIn</base-button> -->
            <base-button type="info" size="sm" @click="emitFuncao('linkedin')"><i class="fas fa-share-alt"></i> Compartilhar</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.publicar">
          <el-tooltip placement="top" content="Realiza a publicação da vaga" effect="light">
            <base-button type="success" size="sm" @click="emitFuncao('publicarVaga')">Publicar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.reprovar">
          <el-tooltip placement="top" content="Reprovar a vaga" effect="light">
            <base-button type="warning" size="sm" @click="emitFuncao('reprovarVaga')">Reprovar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.reativar">
          <el-tooltip placement="top" content="Reativa a vaga" effect="light">
            <base-button type="success" size="sm" @click="emitFuncao('reativar')">Reativar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.encerrar">
          <el-tooltip placement="top" content="Encerrar Vaga" effect="light">
            <base-button type="default" size="sm" @click="emitFuncao('encerrar')">Encerrar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.cancelar">
          <el-tooltip placement="top" content="Cancelar a vaga" effect="light">
            <base-button type="danger" size="sm" :disabled="parseInt(propVaga.fl_status) >= 7" @click="emitFuncao('cancelar')">Cancelar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.excluir">
          <el-tooltip placement="top" content="Excluir a vaga" effect="light">
            <base-button type="danger" size="sm" @click="emitFuncao('excluir')"><i class="fas fa-trash-alt"></i> Excluir</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto" v-if="flbutAcoesRapidas.congelar">
          <el-tooltip placement="top" content="Congelar a vaga" effect="light">
            <base-button type="secondary" size="sm" :disabled="parseInt(propVaga.fl_status) > 5" @click="emitFuncao('congelar')">Congelar Vaga</base-button>
          </el-tooltip>
        </div>
        <div class="col-auto"  v-if="flbutAcoesRapidas.template">
          <el-tooltip placement="top" content="Cria um novo template de vaga a partir dessa vaga" effect="light">
            <base-button type="primary" size="sm" @click="emitFuncao('prepTemplate')">Criar Template</base-button>
          </el-tooltip>
        </div>
        <template v-if="flbutAcoesRapidas.alterarData">
          <div class="col-auto mt-2">
            <label for="datafechamento">Nova data de Fechamento <span class="text-danger">*</span></label>
            <input
              id="datafechamento"
              ref="dataprevfechamento"
              type="date"
              class="form-control"
              name="Previsão de Fechamento"
              placeholder="Previsão de Fechamento"
              :min="propVaga.dt_abertura"
              v-model="dt_prev_fechamento"
            />
          </div>
          <div class="col-1 align-self-end mt-2">
            <base-button type="primary" @click="emitFuncao('handleAlteraData')">Confirmar</base-button>
          </div>
        </template>
      </div>
      <!-- Alterar Data de Fechamento -->
      <!-- <template v-if="flbutAcoesRapidas.alterarData">
        <div class="row mt-3">
          <div class="col-3">
            <label for="datafechamento">Previsão de Fechamento <span class="text-danger">*</span></label>
            <input
              id="datafechamento"
              ref="dataprevfechamento"
              type="date"
              class="form-control"
              name="Previsão de Fechamento"
              placeholder="Previsão de Fechamento"
              :min="propVaga.dt_abertura"
              v-model="dt_prev_fechamento"
            />
          </div>
          <div class="col-1 align-self-end">
            <base-button type="primary" @click="emitFuncao('handleAlteraData')">Confirmar</base-button>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <small>Ajustar a data de Previsão de Fechamento</small>
          </div>
        </div>
      </template> -->
      <!-- <pre>{{id}}</pre> -->
    </base-nav>
    <!-- </card>
  </div> -->
</template>

<script>
import defaultMatchjob from '@/mixins/defaultMatchjob';
export default {
  name: "AcoesRapidas",
  mixins: [defaultMatchjob],
  components: {
  },
  props: {
    propVaga: { type: Object },
    propEmitido: { type: Function }
  },
  data() {
    return {
      flbutAcoesRapidas: {
        editarVaga: false,
        editarProcesso: false,
        goto: false,
        aprovar: false,
        template: false,
        linkedin: false,
        reprovar: false,
        publicar: false,
        reativar: false,
        encerrar: false,
        cancelar: false,
        congelar: false,
        alteraData: false,
        alterarData: false,
        boxEdicao: false,
        excluir: false
      },
      dt_prev_fechamento: ""
    }
  },
  methods: {
    verificaStatusVaga() {
      if(this.id.tipoAcesso > 0 && this.id.tipoAcesso < 5) {
        this.flbutAcoesRapidas.template = true;
        if (parseInt(this.propVaga.fl_status) === 1) {
          this.flbutAcoesRapidas.editarProcesso = true;
          this.flbutAcoesRapidas.editarVaga = true;
          this.flbutAcoesRapidas.cancelar = true;
          //this.verificaAprovador();
        } else if (parseInt(this.propVaga.fl_status) === 2) {
          this.flbutAcoesRapidas.editarProcesso = true;
          this.flbutAcoesRapidas.editarVaga = true;
          this.flbutAcoesRapidas.cancelar = true;
          this.flbutAcoesRapidas.congelar = true;
        } else if (parseInt(this.propVaga.fl_status) === 3) {
          this.flbutAcoesRapidas.editarProcesso = true;
          this.flbutAcoesRapidas.editarVaga = true;
          this.flbutAcoesRapidas.publicar = true;
          this.flbutAcoesRapidas.cancelar = true;
          this.flbutAcoesRapidas.congelar = true;
        } else if (parseInt(this.propVaga.fl_status) === 4) {
          this.flbutAcoesRapidas.goto = true;
          this.flbutAcoesRapidas.linkedin = true;
          this.flbutAcoesRapidas.encerrar = true;
          this.flbutAcoesRapidas.cancelar = true;
          this.flbutAcoesRapidas.congelar = true;
          this.flbutAcoesRapidas.alteraData = true;
        } else if (parseInt(this.propVaga.fl_status) === 5) {
          this.flbutAcoesRapidas.editarProcesso = true;
          this.flbutAcoesRapidas.editarVaga = true;
          this.flbutAcoesRapidas.goto = true;
          this.flbutAcoesRapidas.excluir = true;
        } else if (parseInt(this.propVaga.fl_status) === 6) {
          this.flbutAcoesRapidas.goto = true;
          this.flbutAcoesRapidas.excluir = true;
        } else if (parseInt(this.propVaga.fl_status) === 7) {
          this.flbutAcoesRapidas.goto = true;
          this.flbutAcoesRapidas.reativar = true;
          this.flbutAcoesRapidas.excluir = true;
        } else if (parseInt(this.propVaga.fl_status) === 8) {
          this.flbutAcoesRapidas.goto = true;
          this.flbutAcoesRapidas.reativar = true;
          this.flbutAcoesRapidas.excluir = true;
        } else if (parseInt(this.propVaga.fl_status) === 9) {
          this.flbutAcoesRapidas.editarProcesso = true;
          this.flbutAcoesRapidas.editarVaga = true;
          this.flbutAcoesRapidas.excluir = true;
        }
      } else {
        if (parseInt(this.propVaga.fl_status) === 4) {
           this.flbutAcoesRapidas.goto = true;
           this.flbutAcoesRapidas.linkedin = true;
        }
      }
    },
    verificaAprovador() {
      if (this.id.admin) {
        this.flbutAcoesRapidas.aprovar = true;
        this.flbutAcoesRapidas.reprovar = true;
        this.flTipoAprovador = 'admin';
        return true;
      } else {
        if (this.propVaga.json_fases[0].requerAprovacao) {
          if (!this.propVaga.json_fases[0].aprovacoes.aprovacaoRh.aprovado) {
            if (this.propVaga.json_fases[0].aprovacoes.aprovacaoRh.tipoRh === 'area') {
              this.propVaga.json_fases[0].aprovacoes.aprovacaoRh.aprovador.forEach(e => {
                if (this.id.empresa_user === e.id_empresa_user) {
                  this.flbutAcoesRapidas.aprovar = true;
                  this.flbutAcoesRapidas.reprovar = true;
                  this.flTipoAprovador = 'aprovacaoRh';
                  return true;
                } else {
                  this.flbutAcoesRapidas.aprovar = false;
                  this.flbutAcoesRapidas.reprovar = false;
                  return false;
                }
              })
            } else {
              this.propVaga.json_fases[0].aprovacoes.aprovacaoRh.aprovador.forEach(e => {
                if (this.id.empresa_user === e.id_empresa_user) {
                  this.flbutAcoesRapidas.aprovar = true;
                  this.flbutAcoesRapidas.reprovar = true;
                  this.flTipoAprovador = 'aprovacaoRh';
                  return true;
                } else {
                  this.flbutAcoesRapidas.aprovar = false;
                  this.flbutAcoesRapidas.reprovar = false;
                  return false;
                }
              })
            }
          }
        }
        if (this.propVaga.json_fases[0].solicitanteAprova) {
          if(!this.propVaga.json_fases[0].aprovacoes.solicitante.aprovado) {
            if (this.id.empresa_user === this.propVaga.json_fases[0].aprovacoes.solicitante.id_empresa_user) {
              this.flbutAcoesRapidas.aprovar = true;
              this.flbutAcoesRapidas.reprovar = true;
              this.flTipoAprovador = 'solicitante';
              return true;
            } else {
              this.flbutAcoesRapidas.aprovar = false;
              this.flbutAcoesRapidas.reprovar = false;
              return false;
            }
          }
        }
        if (this.propVaga.json_fases[0].flOutrosAprovadores) {
          this.propVaga.json_fases[0].aprovacoes.outros.forEach(o => {
            if (this.id.empresa_user === this.user.id_empresa_user) {
              this.flbutAcoesRapidas.aprovar = true;
              this.flbutAcoesRapidas.reprovar = true;
              this.flTipoAprovador = 'outros';
              return true;
            } else {
              this.flbutAcoesRapidas.aprovar = false;
              this.flbutAcoesRapidas.reprovar = false;
              return false;
            }
          })
        }
      }
    },
    emitFuncao(value) {
      //console.log('valor emitido -> ', value)
      if(value === 'handleAlteraData') {
        var data = {
          evento: 'handleAlteraData',
          value: this.dt_prev_fechamento
        }
        this.$emit('emitAcoesRapidas', data);
      } else {
        this.$emit('emitAcoesRapidas', value);
      }
      this.propEmitido();
    },
    goToVaga() {
      this.$router.push({ name: 'visualizarvaga', params: { url: this.propVaga.hl_link } });
    },
  },
  mounted() {
    this.verificaStatusVaga();
  }
}
</script>

<style>

</style>
