<template>
  <div>
    <div class="row">
      <!-- <div class="col">
        <button type="button" class="btn btn-primary mx-1" @click="porcentagem">Porcentagem</button>
        <button type="button" class="btn btn-primary mx-1" @click="quantidade">Quantidade</button>
        <button type="button" class="btn btn-info btn-sm mx-1" @click="alteraTipo('pie')"><span class="material-symbols-sharp">pie_chart</span></button>
        <button type="button" class="btn btn-info btn-sm mx-1" @click="alteraTipo('donut')"><span class="material-symbols-outlined">donut_large</span></button>
      </div> -->
      <div class="col-1">
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-auto text-center" v-if="loadData">
        <apexchart :type="propType" :width="propWidth" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts  from "vue-apexcharts";

export default {
  name: "GraficoBarrasPieDonutQtd",
  components: {
    apexchart: VueApexCharts
  },
  props: {
    propSeries: { type: Object },
    propType: { type: String },
    propHeight: { type: [Number, String], default: '100%' },
    propWidth:  { type: [Number, String], default: '100%' },
    propLegendas: { type: Boolean, default: true }
  },
  data() {
    return {
      loadData: false,
      series: [],
      chartOptions: {
        chart: {
          width: "100%",
          height: "100%",
          type: 'donut',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              show: false,
              //position: 'bottom'
            }
          }
        }],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            const valor = opts.w.globals.series[opts.seriesIndex]
            return [name, valor]
          }
          /* formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            return [name, val.toFixed() + '%']
          } */
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          offsetY: 0,
          formatter: function(seriesName, opts) {
            return [seriesName + ": (" + opts.w.globals.series[opts.seriesIndex] + ")"]
          }
        },
        grid: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        colors: []
      },
      chartType: "donut"
    }
  },
  methods: {
    porcentagem() {
      this.series = this.propSeries.series;
      this.chartOptions = {
        chart: {
          width: 600,
          //type: 'pie',
          type: this.chartType,
        },
        labels: this.propSeries.labels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function(seriesName, opts) {
            return [opts.w.globals.labels[opts.seriesIndex], seriesName + '%']
          }
        },
        legend: {
          show: false,
          position: 'right',
          offsetY: 0,
          height: 230,
          formatter(seriesName, opts) {
            const percent = opts.w.globals.previousPaths[opts.seriesIndex]
            return [seriesName, " - ", percent]
          }
        }
      }
    },
    quantidade() {
      //this.series = structuredClone(this.propSeries.seriesQtd);
      this.series = this.propSeries.seriesQtd;
      this.chartOptions = {
        chart: {
          width: 600,
          //type: 'pie',
          type: this.chartType,
        },
        labels: this.propSeries.labels,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex]
            const valor = opts.w.globals.series[opts.seriesIndex]
            return [name, valor]
          }
        },
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
          formatter: function(seriesName, opts) {
            return [seriesName, " - ", opts.w.globals.seriesPercent[opts.seriesIndex] + '%']
          }
        }
      }
    },
    alteraTipo(valor) {
      this.loadData = false;
      this.chartType = valor;
      this.chartOptions.chart.type = valor;
      setTimeout(() => {
        this.loadData = true;
      }, 500);
    }
  },
  mounted() {
    setTimeout(() => {
      this.series = this.propSeries.seriesQtd;
      this.chartOptions.labels = this.propSeries.labels;
      this.chartOptions.colors = this.propSeries.colors;
      this.chartOptions.legend.show = this.propLegendas;
      //console.log(this.series)
      //this.chartOptions.colors = this.propSeries.colors;
      //this.chartOptions.xaxis.categories = this.propSeries.labels;
      //this.chartOptions.title.text = this.propSeries.titulo;
      this.loadData = true;
      //console.log(this.chartOptions)
    }, 2000);
  }
}
</script>

<style scoped>
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' -25,
  'opsz' 20
}
.material-symbols-sharp {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' -25,
  'opsz' 20
}
</style>
