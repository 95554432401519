<template>
  <div v-loading="loading">
    <h1>Funções de Usuários</h1>
    <el-table
      :data="dataTable"
      style="width: 100%"
    >
      <el-table-column
        align="left"
        prop="name"
        label="Função"
        min-width="150"
      ></el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="admin"
        label="Admin"
        min-width="70"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="gerenciaRh"
        label="Ger. RH"
        min-width="80"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="coordRh"
        label="Coord. RH"
        min-width="90"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="recrutadoraRh"
        label="Oper. RH"
        min-width="80"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="gerenciaArea"
        label="Ger. Área"
        min-width="90"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="coordArea"
        label="Coord. Área"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="avaliadorTecnico"
        label="Aval. Téc"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="noAccess"
        label="N/A"
      >
        <div slot-scope="{ row, column }">
          <span v-html="retornaFlag(row, column)"></span>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from 'vue';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import {
  Loading,
  Table,
  TableColumn
} from 'element-ui';

Vue.use(Loading.directive);
export default {
  name: "FuncoesUsuarios",
  mixins: [defaultMatchjob],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loading: false,
      dataTable: [
        {
          id: 1,
          name: 'Controle Total',
          admin: true,
          gerenciaRh: false,
          coordRh: false,
          recrutadoraRh: false,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 2,
          name: 'Abertura de Vagas',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: true,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 3,
          name: 'Aprova Vagas para Publicação',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: false,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 4,
          name: 'Aprovação de candidatos',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: false,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 5,
          name: 'Busca Candidatos',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: true,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 6,
          name: 'Gerencia Usuários',
          admin: true,
          gerenciaRh: true,
          coordRh: false,
          recrutadoraRh: false,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 7,
          name: 'Gerencia Endereços',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: true,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 8,
          name: 'Gerencia Testes',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: true,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 9,
          name: 'Gerencia Templates de E-mail',
          admin: true,
          gerenciaRh: true,
          coordRh: true,
          recrutadoraRh: false,
          gerenciaArea: null,
          coordArea: null,
          avaliadorTecnico: null,
          noAccess: null
        },
        {
          id: 10,
          name: 'Solicita abertura de Vagas',
          admin: null,
          gerenciaRh: null,
          coordRh: null,
          recrutadoraRh: null,
          gerenciaArea: true,
          coordArea: false,
          avaliadorTecnico: false,
          noAccess: false
        },
        {
          id: 11,
          name: 'Aprovação técnica de vagas',
          admin: null,
          gerenciaRh: null,
          coordRh: null,
          recrutadoraRh: null,
          gerenciaArea: true,
          coordArea: true,
          avaliadorTecnico: false,
          noAccess: false
        },
        {
          id: 12,
          name: 'Aprovação de candidatos',
          admin: null,
          gerenciaRh: null,
          coordRh: null,
          recrutadoraRh: null,
          gerenciaArea: true,
          coordArea: true,
          avaliadorTecnico: true,
          noAccess: false
        },
        {
          id: 13,
          name: 'Cria teste técnico',
          admin: null,
          gerenciaRh: null,
          coordRh: null,
          recrutadoraRh: null,
          gerenciaArea: true,
          coordArea: true,
          avaliadorTecnico: true,
          noAccess: false
        },
      ]
    };
  },
  methods: {
    retornaFlag(row, column) {
      var tipo = row[column.property];
      if (tipo) {
        return "<i class='text-success icone-ativo fas fa-check-circle'></i>"
      } else if (tipo === null) {
        return "*"
      } else {
        return "<i class='icone-desabilitado fas fa-ban'></i>"
      }
    }
  },
  filters: {
  },
  mounted() {
  },
}
</script>

<style>
  .icone-ativo {
    font-size: 1.2rem;
  }
  .icone-desabilitado {
    font-size: 1.2rem;
    color: red;
  }
</style>
