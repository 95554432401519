<template>
  <modal :show="propShow" :showClose="false" size="lg">
    <h6 slot="header" class="modal-title">Adicionar Referência Profisssional do Candidato</h6>
    <div class="row">
      <div class="col-6">
        <label for="referenciaNome">Indicado por: <span class="text-danger">*</span></label>
        <input
          id="referenciaNome"
          ref="referenciaNome"
          type="text"
          class="form-control"
          name="Nome"
          placeholder="Nome"
          v-model="novaReferencia.nome"
        />
      </div>
      <div class="col-6">
        <label for="referenciaEmail">E-mail: <span class="text-danger">*</span></label>
        <input
          id="referenciaEmail"
          ref="referenciaEmail"
          type="text"
          class="form-control"
          name="E-mail"
          placeholder="E-mail"
          v-model="novaReferencia.email"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label for="referenciaTelefone">Telefone: <span class="text-danger">*</span></label>
        <input
          id="referenciaTelefone"
          ref="referenciaTelefone"
          type="text"
          class="form-control"
          name="Telefone"
          placeholder="Telefone"
          v-model="novaReferencia.telefone"
        />
      </div>
      <div class="col-6">
        <label for="referenciaCargo">Cargo:</label>
        <input
          id="referenciaCargo"
          ref="referenciaCargo"
          type="text"
          class="form-control"
          name="Cargo"
          placeholder="Cargo"
          v-model="novaReferencia.cargo"
        />
      </div>
    </div>
    <div class="row mt-1" v-if="propIndex !== null">
      <div class="col">
        <label for="referenciaExperiencia">Experiência do Candidato relacionada à indicação<span class="text-danger">*</span></label>
        <el-select
          ref="referenciaExperiencia"
          name="Selecionar Experiência"
          class="inuptAutocomplete"
          label="Selecionar Experiência"
          id="referenciaExperiencia"
          v-model="novaReferencia.refExperiencia"
        >
          <el-option
            v-for="(exp, index) in propDetalhes.curriculo.json_experiencias"
            :key="index"
            :value="exp.id"
            :label="exp.nm_cargo"
          >
            <span style="float: left">{{ exp.nm_cargo }} - {{ exp.nm_empresa }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">Período: de {{ formatDateFuncao(exp.dt_inicio) }} até {{ exp.fl_atual ? 'hoje' : formatDateFuncao(exp.dt_fim) }}</span>
          </el-option>
        </el-select>
        <!-- {{novaReferencia}} -->
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <label for="indicadoPorCargo">Observacões:</label>
        <textarea class="form-control" id="indicadoPorCargo" rows="3" v-model="novaReferencia.obs"></textarea>
      </div>
    </div>
    <div slot="footer">
      <base-button type="secondary" class="ml-3" @click="closeModal">Cancelar</base-button>
      <base-button type="primary" class="mr-3 ml-auto" @click="emitReferencia">Adicionar</base-button>
    </div>
  </modal>
</template>

<script>
import { Select, Option } from 'element-ui';
import moment from "moment";

export default {
  name: "RegistroReferenciaProfissional",
  props: {
    //propCandidato: { type: Object }, //Conteudo da timeline do candidato
    //propVaga: { type: String },// String com o flag do tipo da entrevista
    //propCompetencias: { type: Array }, //Competencias incluídas no processo seletivo
    propShow: { type: Boolean },
    propIndex: { type: Number },
    propDetalhes: { type: Object },
    closeModal: { type: Function },
    concluiRegistro: { type: Function },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      novaReferencia: {
        index: null,
        nome: "",
        email: "",
        telefone: "",
        cargoReferencia: "",
        refExperiencia: "",
        obs: ""
      },
    }
  },
  methods: {
    formatDateFuncao: function(value) {
      if (value) {
        return moment(String(value)).format("MMM/YYYY");
        //return moment(String(value)).format("L");
      }
    },
    emitReferencia() {
      this.novaReferencia.index = this.propIndex;
      this.$emit('resReferencia', this.novaReferencia);
      this.concluiRegistro()
    }
  }
}
</script>

<style>

</style>
