<template>
  <div>
    <!-- <div class="row">
      <div class="col">
        <button type="button" class="btn btn-primary btn-sm mx-1" @click="porcentagem">Porcentagem</button>
        <button type="button" class="btn btn-primary btn-sm mx-1" @click="quantidade">Quantidade</button>
      </div>
      <div class="col-1">
      </div>
    </div> -->
    <div>
      <template v-if="loadData">
        <apexchart type="bar" height="250" :options="chartOptions" :series="series"></apexchart>
      </template>
    </div>
  </div>
</template>

<script>
import VueApexCharts  from "vue-apexcharts";

export default {
  name: "GraficoLinhas",
  components: {
    apexchart: VueApexCharts
  },
  props: {
    propSeries: { type: Object }
  },
  data() {
    return {
      loadData: false,
      series: [{
        name: 'Candidato por Cidades',
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
      }],
      chartOptions: {
        chart: {
          height: 100,
          type: 'bar',
        },
        colors: [],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            /* dataLabels: {
              position: 'top', // top, center, bottom
            },
            distributed: true, */
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            show: false
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }

        },
        title: {
          text: '',
          floating: true,
          offsetY: 0,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        legend: {
          show: true,
        }
      },
    }
  },
  methods: {
    porcentagem() {
      this.series = this.propSeries.series;
      this.chartOptions = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: this.propSeries.colors,
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            distributed: true,
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: this.propSeries.labels,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            show: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }

        },
        title: {
          text: this.propSeries.titulo,
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        legend: {
          show: false,
        }
      }
    },
    quantidade() {
      this.series = this.propSeries.seriesQtd;
      this.chartOptions = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: this.propSeries.colors,
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
            distributed: true,
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: this.propSeries.labels,
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            show: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + " Votos";
            }
          }

        },
        title: {
          text: this.propSeries.titulo,
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        },
        legend: {
          show: false,
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.series = this.propSeries.series;
      this.chartOptions.colors = this.propSeries.colors;
      this.chartOptions.xaxis.categories = this.propSeries.labels;
      this.chartOptions.title.text = this.propSeries.titulo;
      this.loadData = true;
    }, 2000);
  }
}
</script>

<style>

</style>
