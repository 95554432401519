<template>
  <div>
    <div class="row justify-content-center my-5">
      <div class="col-12 text-center">
        <h1 class="display-3">Vaga cadastrada com sucesso!</h1>
      </div>
    </div>
    <div class="row justify-content-center my-5">
      <div class="col-12 text-center">
        <!-- <i class="icone-vaga-finalizada text-success fas fa-check-circle"></i> -->
        <i class="icone-vaga-finalizada text-success far fa-check-circle"></i>
      </div>
    </div>
    <div class="row justify-content-center my-5">
      <div class="col-3 text-center">
        <base-button type="primary" @click="goListaVagas">Ir para Lista de Vagas</base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Finalizado",
  methods: {
    goListaVagas() {
      this.$router.push({ name: 'Vagas' });
    },
  }
}
</script>

<style>
.icone-vaga-finalizada {
  font-size: 150px;
}
</style>
